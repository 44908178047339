import { RefreshCw } from "lucide-react";

export const LoadingNullContentsWrapper = ({
  loading,
  objectIsNull,
  children
}: {
  loading: boolean;
  objectIsNull: boolean;
  children: React.ReactNode;
}) => {
  if (loading && objectIsNull) {
    return (
      <div className="flex flex-row items-center justify-center text-colors-text-text-secondary-(700) text-sm">
        <RefreshCw size={20} className="mr-4 animate-spin" />
        <div>Loading...</div>
      </div>
    );
  } else {
    if (objectIsNull) {
      return <div>Not enough data</div>;
    }
    return children;
  }
};
