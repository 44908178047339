/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CompLandscapeInputs,
  CompLandscapeOutputs,
  CompLandscapeV7Workflow,
  FindCompaniesOutputsV2,
  FindCompaniesV2InitialInputs,
  FindCompaniesV2Inputs,
  FindCompaniesWorkflowV2,
  FindCompaniesWorkflowV2InputGenerationStateEnum,
  CompanyType,
  GenericWorkflow,
  MarketMap,
  MarketMapInputs,
  MarketMapOutputs,
  MarketMapWorkflow,
  SourceCompaniesInputs,
  SourceCompaniesWorkflow,
  SourceCompaniesWorkflowInputGenerationStateEnum,
  TaskStatus,
  WorkflowFeedbackRatingEnum,
  WorkflowStub,
  WorkflowType
} from "@/services/autogen";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  deleteGenericWorkflow,
  runGenericWorkflow,
  refineGenericWorkflow,
  fetchGenericWorkflow,
  fetchAllWorkflowStubs,
  fetchAllGenericWorkflows,
  giveGenericWorkflowFeedback,
  updateGenericWorkflow,
  addSimilarCompaniesToFindCompaniesWorkflow,
  findCompaniesInitialInputsToInputs,
  sourceCompaniesInitialInputsToInputs
} from "@/services/brain-api.service";
import { getDomainFromUrl } from "@/lib/utils";

export type GenericWorkflowsStoreState = {
  workflows: GenericWorkflow[];
  stubs: WorkflowStub[];
  activeWorkflowId?: string;
  ttmToggle: boolean;
};

const initialState: GenericWorkflowsStoreState = {
  workflows: [],
  stubs: [],
  ttmToggle: true
};

export const fetchAllGenericWorkflowsThunk = createAsyncThunk("fetchAllGenericWorkflows", async (token: string, thunkAPI) => {
  fetchAllGenericWorkflows(token).then((response) => {
    if (response.status === 200) {
      const workflows = response.data;
      workflows.forEach((workflow) => {
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow }));
      });
    }
  });
});

export const fetchAllWorkflowStubsThunk = createAsyncThunk("fetchAllWorkflowStubs", async (token: string, thunkAPI) => {
  fetchAllWorkflowStubs(token).then((response) => {
    if (response.status === 200) {
      const stubs = response.data;
      stubs.forEach((stub) => {
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadWorkflowStub({ stub }));
      });
    }
  });
});

export const fetchGenericWorkflowThunk = createAsyncThunk(
  "fetchGenericWorkflow",
  async (thunkPayload: { workflow_id: string }, thunkAPI) => {
    const { workflow_id } = thunkPayload;
    fetchGenericWorkflow(workflow_id).then((response) => {
      if (response.status === 200) {
        const workflow = response.data;
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow }));
      }
    });
  }
);

function createCompLandscapeV7WorkflowFromInputs(inputs: CompLandscapeInputs): GenericWorkflow {
  const workflowId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
  const workflow: CompLandscapeV7Workflow = {
    inputs: inputs,
    parse_helper: "comp_landscape_v7"
  };
  return {
    workflow_id: workflowId,
    workflow_type: WorkflowType.CompLandscapeV7,
    title: getDomainFromUrl(workflow.inputs.company_website),
    workflow: workflow,
    status: "created",
    created_at: Date.now()
  };
}

export const createAndRunCompLandscapeV7WorkflowThunk = createAsyncThunk(
  "runCompLandscapeV7Workflow",
  async (thunkPayload: { token: string; inputs: CompLandscapeInputs }, thunkAPI) => {
    const { token, inputs } = thunkPayload;
    const workflow = createCompLandscapeV7WorkflowFromInputs(inputs);
    return runGenericWorkflow(token, workflow).then((response) => {
      if (response.status === 200) {
        const workflow = response.data;
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow }));
        thunkAPI.dispatch(genericWorkflowsSlice.actions.setActiveWorkflowId(workflow.workflow_id));
        return workflow.workflow_id; // Ensure this value is returned from the promise chain
      }
    });
  }
);

function createFindCompaniesV2WorkflowFromInputs(inputs: FindCompaniesV2Inputs): GenericWorkflow {
  const workflowId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
  const workflow: FindCompaniesWorkflowV2 = {
    inputs: inputs,
    parse_helper: "find_companies_v2"
  };
  // if empty, make the inputs undefined
  if (inputs.business_model == "") {
    inputs.business_model = undefined;
  }
  if (inputs.main_offerings == "") {
    inputs.main_offerings = undefined;
  }
  if (inputs.customer_segments == "") {
    inputs.customer_segments = undefined;
  }
  if (inputs.geographies == "") {
    inputs.geographies = undefined;
  }
  if (inputs.technologies == "") {
    inputs.technologies = undefined;
  }
  return {
    workflow_id: workflowId,
    workflow_type: WorkflowType.FindCompaniesV2,
    title: workflow.inputs.category_name ?? "Find Companies",
    workflow: workflow,
    status: "created",
    created_at: Date.now()
  };
}

export const createAndRunFindCompaniesV2WorkflowThunk = createAsyncThunk(
  "runFindCompaniesWorkflow",
  async (thunkPayload: { token: string; inputs: FindCompaniesV2Inputs }, thunkAPI) => {
    const { token, inputs } = thunkPayload;
    const workflow = createFindCompaniesV2WorkflowFromInputs(inputs);
    return runGenericWorkflow(token, workflow).then((response) => {
      if (response.status === 200) {
        const workflow = response.data;
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow }));
        thunkAPI.dispatch(genericWorkflowsSlice.actions.setActiveWorkflowId(workflow.workflow_id));
        return workflow.workflow_id;
      }
    });
  }
);

export const refineGenericWorkflowThunk = createAsyncThunk(
  "refineGenericWorkflow",
  async (thunkPayload: { token: string; workflow: GenericWorkflow }, thunkAPI) => {
    const { token, workflow } = thunkPayload;
    refineGenericWorkflow(token, workflow).then((response) => {
      if (response.status === 200) {
        const responseWorkflow = response.data;
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow: responseWorkflow }));
        thunkAPI.dispatch(genericWorkflowsSlice.actions.setActiveWorkflowId(workflow.workflow_id));
      }
    });
  }
);

export const deleteGenericWorkflowThunk = createAsyncThunk(
  "deleteGenericWorkflow",
  async (thunkPayload: { token: string; workflowId: string }, thunkAPI) => {
    const { token, workflowId } = thunkPayload;
    thunkAPI.dispatch(genericWorkflowsSlice.actions.removeGenericWorkflow(workflowId));
    deleteGenericWorkflow(token, workflowId);
  }
);

export const updateGenericWorkflowThunk = createAsyncThunk(
  "updateGenericWorkflow",
  async (thunkPayload: { token: string; workflowId: string }, thunkAPI) => {
    const { token, workflowId } = thunkPayload;
    const state = thunkAPI.getState() as { genericWorkflows: GenericWorkflowsStoreState };
    const workflow = state.genericWorkflows.workflows.find((w) => w.workflow_id === workflowId);
    if (workflow) {
      updateGenericWorkflow(token, workflow);
    }
  }
);

export const runGenericWorkflowThunk = createAsyncThunk(
  "runGenericWorkflow",
  async (thunkPayload: { token: string; workflow: GenericWorkflow }, thunkAPI) => {
    const { token, workflow } = thunkPayload;
    runGenericWorkflow(token, workflow).then((response) => {
      if (response.status === 200) {
        const responseWorkflow = response.data;
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow: responseWorkflow }));
        thunkAPI.dispatch(genericWorkflowsSlice.actions.setActiveWorkflowId(workflow.workflow_id));
      }
    });
  }
);

export const addSimilarCompaniesToFindCompaniesWorkflowThunk = createAsyncThunk(
  "addSimilarCompaniesToFindCompaniesWorkflow",
  async (thunkPayload: { token: string; workflowId: string; companyDomain: string }, thunkAPI) => {
    const { token, workflowId, companyDomain } = thunkPayload;
    addSimilarCompaniesToFindCompaniesWorkflow(token, workflowId, companyDomain).then((response) => {
      if (response.status === 200) {
        const responseWorkflow = response.data;
        thunkAPI.dispatch(genericWorkflowsSlice.actions.loadGenericWorkflow({ workflow: responseWorkflow }));
        // thunkAPI.dispatch(genericWorkflowsSlice.actions.setActiveWorkflowId(workflowId));
      }
    });
  }
);

export const rateWorkflowThunk = createAsyncThunk(
  "rateWorkflow",
  async (thunkPayload: { token: string; workflowId: string; rating?: WorkflowFeedbackRatingEnum }, thunkAPI) => {
    const { token, workflowId, rating } = thunkPayload;
    thunkAPI.dispatch(genericWorkflowsSlice.actions.updateFeedback({ workflowId, rating }));
    giveGenericWorkflowFeedback(token, workflowId, { rating });
  }
);

// Deprecated as of Jan 16, 2025
// export const markWorkflowViewedThunk = createAsyncThunk(
//   "markWorkflowViewed",
//   async (thunkPayload: { token: string; workflowId: string }, thunkAPI) => {
//     const { token, workflowId } = thunkPayload;
//     thunkAPI.dispatch(genericWorkflowsSlice.actions.markWorkflowViewed(workflowId));
//     markWorkflowViewed(token, workflowId);
//   }
// );

export const findCompaniesInitialInputsToInputsThunk = createAsyncThunk(
  "findCompaniesInitialInputsToInputs",
  async (thunkPayload: { token: string; workflowId: string }, thunkAPI) => {
    const { token, workflowId } = thunkPayload;
    const state = thunkAPI.getState() as { genericWorkflows: GenericWorkflowsStoreState };
    const workflow = state.genericWorkflows.workflows.find((w) => w.workflow_id === workflowId);
    if (workflow && workflow.workflow_type == WorkflowType.FindCompaniesV2) {
      const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
      if (findCompaniesWorkflow.frontend_initial_inputs != null) {
        thunkAPI.dispatch(
          genericWorkflowsSlice.actions.setInputGenerationState({
            workflowId,
            inputGenerationState: "loading"
          })
        );
        findCompaniesInitialInputsToInputs(token, findCompaniesWorkflow.frontend_initial_inputs).then((response) => {
          if (response.status === 200) {
            thunkAPI.dispatch(genericWorkflowsSlice.actions.setFinalInputs({ workflowId, inputs: response.data.final_inputs }));
            thunkAPI.dispatch(
              genericWorkflowsSlice.actions.setWorkflowErrorExplanation({
                workflowId,
                errorExplanation: response.data.error_explanation
              })
            );
            thunkAPI.dispatch(
              genericWorkflowsSlice.actions.setInputGenerationState({
                workflowId,
                inputGenerationState: "final"
              })
            );
          }
        });
      }
    }
  }
);

export const sourceCompaniesInitialInputsToInputsThunk = createAsyncThunk(
  "sourceCompaniesInitialInputsToInputs",
  async (thunkPayload: { token: string; workflowId: string }, thunkAPI) => {
    const { token, workflowId } = thunkPayload;
    const state = thunkAPI.getState() as { genericWorkflows: GenericWorkflowsStoreState };
    const workflow = state.genericWorkflows.workflows.find((w) => w.workflow_id === workflowId);
    if (workflow && workflow.workflow_type == WorkflowType.SourceCompanies) {
      const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
      if (sourceCompaniesWorkflow.frontend_initial_inputs != null) {
        thunkAPI.dispatch(
          genericWorkflowsSlice.actions.setInputGenerationState({
            workflowId,
            inputGenerationState: "loading"
          })
        );
        sourceCompaniesInitialInputsToInputs(token, sourceCompaniesWorkflow.frontend_initial_inputs).then((response) => {
          if (response.status === 200) {
            thunkAPI.dispatch(genericWorkflowsSlice.actions.setFinalInputs({ workflowId, inputs: response.data.final_inputs }));
            thunkAPI.dispatch(
              genericWorkflowsSlice.actions.setWorkflowErrorExplanation({
                workflowId,
                errorExplanation: response.data.error_explanation
              })
            );
            thunkAPI.dispatch(
              genericWorkflowsSlice.actions.setInputGenerationState({
                workflowId,
                inputGenerationState: "final"
              })
            );
          }
        });
      }
    }
  }
);

export const genericWorkflowsSlice = createSlice({
  name: "genericWorkflows",
  initialState,
  reducers: {
    loadGenericWorkflow: (state, action: PayloadAction<{ workflow: GenericWorkflow }>) => {
      const { workflow } = action.payload;
      const existingWorkflowIndex = state.workflows.findIndex((w) => w.workflow_id === workflow.workflow_id);
      if (existingWorkflowIndex >= 0) {
        state.workflows[existingWorkflowIndex] = workflow;
      } else {
        state.workflows.push(workflow);
      }
      const stub: WorkflowStub = {
        workflow_id: workflow.workflow_id,
        status: workflow.status,
        title: workflow.title,
        workflow_type: workflow.workflow_type,
        created_at: workflow.created_at,
        viewed: workflow.viewed
      };
      genericWorkflowsSlice.caseReducers.loadWorkflowStub(state, { payload: { stub }, type: "genericWorkflows/loadWorkflowStub" });
    },
    loadWorkflowStub: (state, action: PayloadAction<{ stub: WorkflowStub }>) => {
      const { stub } = action.payload;
      const existingWorkflowIndex = state.stubs.findIndex((w) => w.workflow_id === stub.workflow_id);
      if (existingWorkflowIndex >= 0) {
        state.stubs[existingWorkflowIndex] = stub;
      } else {
        state.stubs.push(stub);
      }
    },
    setActiveWorkflowId: (state, action: PayloadAction<string | undefined>) => {
      state.activeWorkflowId = action.payload;
    },
    removeGenericWorkflow: (state, action: PayloadAction<string>) => {
      if (state.activeWorkflowId === action.payload) {
        state.activeWorkflowId = undefined;
      }
      state.workflows = state.workflows.filter((w) => w.workflow_id !== action.payload);
      state.stubs = state.stubs.filter((w) => w.workflow_id !== action.payload);
    },
    markWorkflowViewed: (state, action: PayloadAction<string>) => {
      const workflow = state.workflows.find((w) => w.workflow_id === action.payload);
      if (workflow) {
        workflow.viewed = true;
      }
      const stub = state.stubs.find((w) => w.workflow_id === action.payload);
      if (stub) {
        stub.viewed = true;
      }
    },
    updateInputMarketMap: (state, action: PayloadAction<{ workflowId: string; marketMap: MarketMap }>) => {
      const { workflowId, marketMap } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow && (workflow.workflow_type == WorkflowType.CompLandscapeV7 || workflow.workflow_type == WorkflowType.MarketMapV2)) {
        const inputs = workflow.workflow.inputs as CompLandscapeInputs | MarketMapInputs;
        inputs.market_map = marketMap;
      }
    },
    removeBusinessFromOutputMarketMap: (
      state,
      action: PayloadAction<{ businessId: string; categoryName: string; subCategoryName?: string; workflowId: string }>
    ) => {
      const { businessId, categoryName, subCategoryName, workflowId } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (
        workflow &&
        (workflow.workflow_type == WorkflowType.MarketMapV2 || workflow.workflow_type == WorkflowType.CompLandscapeV7) &&
        workflow.workflow?.outputs
      ) {
        const outputs = workflow.workflow.outputs as MarketMapOutputs | CompLandscapeOutputs;
        const categoryIndex = outputs.market_map.categories.findIndex((c) => c.name === categoryName);
        if (categoryIndex >= 0) {
          if (subCategoryName != null) {
            // subcategory delete
            const subCategoryIndex = outputs.market_map.categories[categoryIndex].subcategories.findIndex(
              (sc) => sc.name === subCategoryName
            );
            if (subCategoryIndex >= 0) {
              const businessIndex = outputs.market_map.categories[categoryIndex].subcategories[subCategoryIndex].businesses.findIndex(
                (b) => b === businessId
              );
              if (businessIndex >= 0) {
                outputs.market_map.categories[categoryIndex].subcategories[subCategoryIndex].businesses.splice(businessIndex, 1);
              }
            }
          } else {
            // direct category delete
            const businessIndex = outputs.market_map.categories[categoryIndex].businesses.findIndex((b) => b === businessId);
            if (businessIndex >= 0) {
              outputs.market_map.categories[categoryIndex].businesses.splice(businessIndex, 1);
            }
          }
        }
      }
    },
    createInitialMarketMapWorkflow: (state, _: PayloadAction<{}>) => {
      // should generate a random 24-char hex string
      const workflowId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
      const compFinderWorkflow: MarketMapWorkflow = {
        inputs: {
          market_map: { categories: [] }
        },
        parse_helper: "market_map_v2"
      };
      const genericWorkflow: GenericWorkflow = {
        workflow_id: workflowId,
        workflow_type: WorkflowType.MarketMapV2,
        workflow: compFinderWorkflow,
        status: TaskStatus.Created,
        title: "Market Map",
        created_at: Date.now()
      };
      const stub: WorkflowStub = {
        workflow_id: workflowId,
        status: genericWorkflow.status,
        title: genericWorkflow.title,
        workflow_type: genericWorkflow.workflow_type,
        created_at: genericWorkflow.created_at
      };
      state.stubs.push(stub);
      state.workflows.push(genericWorkflow);
      state.activeWorkflowId = workflowId;
    },
    createFindCompaniesV2InitialWorkflow: (state, action: PayloadAction<{ upload_list?: boolean; find_similar?: boolean }>) => {
      const { upload_list, find_similar } = action.payload;
      // should generate a random 24-char hex string
      const workflowId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
      let inputGenerationState: FindCompaniesWorkflowV2InputGenerationStateEnum = FindCompaniesWorkflowV2InputGenerationStateEnum.Initial;
      if (upload_list) {
        inputGenerationState = FindCompaniesWorkflowV2InputGenerationStateEnum.UploadList;
      } else if (find_similar) {
        inputGenerationState = FindCompaniesWorkflowV2InputGenerationStateEnum.FindSimilar;
      }
      const findCompaniesWorkflow: FindCompaniesWorkflowV2 = {
        input_generation_state: inputGenerationState,
        frontend_initial_inputs: {
          category_name: "",
          company_type_filter: [
            CompanyType.Public,
            CompanyType.EarlyStageStartup,
            CompanyType.Subsidiary,
            CompanyType.GrowthStageStartup,
            CompanyType.SmallPrivate,
            CompanyType.EstablishedPrivate,
            CompanyType.NotForProfit,
            CompanyType.InvestmentFund
          ]
        },
        inputs: {
          category_name: "",
          company_type_filter: [
            CompanyType.Public,
            CompanyType.EarlyStageStartup,
            CompanyType.Subsidiary,
            CompanyType.GrowthStageStartup,
            CompanyType.SmallPrivate,
            CompanyType.EstablishedPrivate,
            CompanyType.NotForProfit,
            CompanyType.InvestmentFund
          ]
        },
        parse_helper: "find_companies_v2"
      };
      const genericWorkflow: GenericWorkflow = {
        workflow_id: workflowId,
        workflow_type: WorkflowType.FindCompaniesV2,
        workflow: findCompaniesWorkflow,
        status: TaskStatus.Created,
        title: "Find Companies",
        created_at: Date.now()
      };
      const stub: WorkflowStub = {
        workflow_id: workflowId,
        status: genericWorkflow.status,
        title: genericWorkflow.title,
        workflow_type: genericWorkflow.workflow_type,
        created_at: genericWorkflow.created_at
      };
      state.stubs.push(stub);
      state.workflows.push(genericWorkflow);
      state.activeWorkflowId = workflowId;
    },
    createSourceCompaniesInitialWorkflow: (state, _: PayloadAction<{}>) => {
      // should generate a random 24-char hex string
      const workflowId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
      const sourceCompaniesWorkflow: SourceCompaniesWorkflow = {
        input_generation_state: SourceCompaniesWorkflowInputGenerationStateEnum.Initial,
        frontend_initial_inputs: {
          category_name: "",
          company_type_filter: [
            CompanyType.Public,
            CompanyType.EarlyStageStartup,
            CompanyType.Subsidiary,
            CompanyType.GrowthStageStartup,
            CompanyType.SmallPrivate,
            CompanyType.EstablishedPrivate,
            CompanyType.NotForProfit,
            CompanyType.InvestmentFund
          ]
        },
        inputs: {
          category_name: "",
          company_type_filter: [
            CompanyType.Public,
            CompanyType.EarlyStageStartup,
            CompanyType.Subsidiary,
            CompanyType.GrowthStageStartup,
            CompanyType.SmallPrivate,
            CompanyType.EstablishedPrivate,
            CompanyType.NotForProfit,
            CompanyType.InvestmentFund
          ]
        },
        parse_helper: "source_companies"
      };
      const genericWorkflow: GenericWorkflow = {
        workflow_id: workflowId,
        workflow_type: WorkflowType.SourceCompanies,
        workflow: sourceCompaniesWorkflow,
        status: TaskStatus.Created,
        title: "Source Companies",
        created_at: Date.now()
      };
      const stub: WorkflowStub = {
        workflow_id: workflowId,
        status: genericWorkflow.status,
        title: genericWorkflow.title,
        workflow_type: genericWorkflow.workflow_type,
        created_at: genericWorkflow.created_at
      };
      state.stubs.push(stub);
      state.workflows.push(genericWorkflow);
      state.activeWorkflowId = workflowId;
    },
    updateFeedback: (state, action: PayloadAction<{ workflowId: string; rating?: WorkflowFeedbackRatingEnum }>) => {
      const { workflowId, rating } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow) {
        workflow.feedback = { rating };
      }
    },
    removeFindCompaniesV2Company: (state, action: PayloadAction<{ workflowId: string; companyDomain: string }>) => {
      const { workflowId, companyDomain } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow && workflow.workflow_type == WorkflowType.FindCompaniesV2 && workflow.workflow?.outputs) {
        const outputs = workflow.workflow.outputs as FindCompaniesOutputsV2;
        outputs.companies = outputs.companies.filter((c) => c.domain !== companyDomain);
        if (outputs.removed_company_domains) {
          outputs.removed_company_domains.push(companyDomain);
        } else {
          outputs.removed_company_domains = [companyDomain];
        }
      }
    },
    setTTMToggle: (state, action: PayloadAction<boolean>) => {
      state.ttmToggle = action.payload;
    },
    setInitialInputs: (
      state,
      action: PayloadAction<{ workflowId: string; initialInputs: FindCompaniesV2InitialInputs | SourceCompaniesInputs }>
    ) => {
      const { workflowId, initialInputs } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow) {
        if (workflow.workflow_type == WorkflowType.FindCompaniesV2) {
          const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
          findCompaniesWorkflow.frontend_initial_inputs = initialInputs as FindCompaniesV2InitialInputs;
        } else if (workflow.workflow_type == WorkflowType.SourceCompanies) {
          const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
          sourceCompaniesWorkflow.frontend_initial_inputs = initialInputs as SourceCompaniesInputs;
        }
      }
    },
    setFinalInputs: (state, action: PayloadAction<{ workflowId: string; inputs: FindCompaniesV2Inputs | SourceCompaniesInputs }>) => {
      const { workflowId, inputs } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow) {
        if (workflow.workflow_type == WorkflowType.FindCompaniesV2) {
          const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
          findCompaniesWorkflow.inputs = inputs;
        } else if (workflow.workflow_type == WorkflowType.SourceCompanies) {
          const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
          sourceCompaniesWorkflow.inputs = inputs;
        }
      }
    },
    setWorkflowErrorExplanation: (state, action: PayloadAction<{ workflowId: string; errorExplanation?: string }>) => {
      const { workflowId, errorExplanation } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow) {
        if (workflow.workflow_type == WorkflowType.FindCompaniesV2) {
          const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
          findCompaniesWorkflow.error_explanation = errorExplanation;
        } else if (workflow.workflow_type == WorkflowType.SourceCompanies) {
          const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
          sourceCompaniesWorkflow.error_explanation = errorExplanation;
        }
      }
    },
    setInputGenerationState: (
      state,
      action: PayloadAction<{
        workflowId: string;
        inputGenerationState: FindCompaniesWorkflowV2InputGenerationStateEnum | SourceCompaniesWorkflowInputGenerationStateEnum;
      }>
    ) => {
      const { workflowId, inputGenerationState } = action.payload;
      const workflow = state.workflows.find((w) => w.workflow_id === workflowId);
      if (workflow) {
        if (workflow.workflow_type == WorkflowType.FindCompaniesV2) {
          const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
          findCompaniesWorkflow.input_generation_state = inputGenerationState as FindCompaniesWorkflowV2InputGenerationStateEnum;
        } else if (workflow.workflow_type == WorkflowType.SourceCompanies) {
          const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
          sourceCompaniesWorkflow.input_generation_state = inputGenerationState as SourceCompaniesWorkflowInputGenerationStateEnum;
        }
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  loadGenericWorkflow,
  loadWorkflowStub,
  setActiveWorkflowId,
  removeGenericWorkflow,
  setTTMToggle,
  removeFindCompaniesV2Company,
  createFindCompaniesV2InitialWorkflow,
  createSourceCompaniesInitialWorkflow,
  setInitialInputs,
  setFinalInputs,
  setWorkflowErrorExplanation,
  setInputGenerationState,
  createInitialMarketMapWorkflow,
  updateInputMarketMap,
  removeBusinessFromOutputMarketMap
} = genericWorkflowsSlice.actions;

export default genericWorkflowsSlice.reducer;
