/* eslint-disable @typescript-eslint/no-empty-function */
import { GenericWorkflow, WorkflowType } from "@/services/autogen";
import { useLoaderData } from "react-router-dom";
import { useAppDispatch } from "@/store/hooks";
import Header from "./Header";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../ui/resizable";
import { WorkflowViewer } from "./WorkflowViewer";
import { ImperativePanelHandle } from "react-resizable-panels";
import { useEffect, useRef } from "react";
import { RightSection } from "./RightSection";
import { updateLeftbar, updateRightbar } from "@/store/sidebars";
import { Toaster } from "sonner";
import { loadGenericWorkflow, setActiveWorkflowId } from "@/store/genericWorkflows";

export function StaticWorkflowViewer() {
  const dispatch = useAppDispatch();

  const workflow = useLoaderData() as GenericWorkflow | null;

  // const activeWorkflow = useAppSelector((state) => state.workflows.workflows.find((w) => w.id === workflow?.id));

  if (workflow == null) {
    return <div>404</div>;
  }
  const ref = useRef<ImperativePanelHandle>(null);
  const collapsePanel = () => {
    const panel = ref.current;
    if (panel) {
      panel.collapse();
    }
    dispatch(updateRightbar({ setIsOpen: false }));
  };
  const expandPanel = () => {
    const panel = ref.current;
    if (panel) {
      panel.expand();
    }
    dispatch(updateRightbar({ setIsOpen: true }));
  };
  dispatch(loadGenericWorkflow({ workflow }));
  dispatch(setActiveWorkflowId(workflow.workflow_id));
  dispatch(updateLeftbar({ setIsOpen: false }));

  useEffect(() => {
    if (workflow.workflow_type === WorkflowType.TearSheet) {
      collapsePanel();
    }
  }, []);
  return (
    <div className="flex app-background">
      <div className="flex flex-col w-full h-screen">
        <Header showLogo runYourOwnButton />
        <div className="flex flex-row w-full" style={{ height: "calc(100vh - 64px)" }}>
          <ResizablePanelGroup direction="horizontal" className={`h-full w-full`}>
            <ResizablePanel defaultSize={70}>
              <div className={`flex flex-col px-10 items-center gap-5 justify-start h-full py-8 overflow-y-scroll w-full`}>
                <WorkflowViewer hideRefine hideFeedback />
              </div>
            </ResizablePanel>
            <ResizableHandle withHandle className="z-[100]" />
            <ResizablePanel
              defaultSize={30}
              ref={ref}
              collapsible
              collapsedSize={3}
              minSize={10}
              onCollapse={() => dispatch(updateRightbar({ setIsOpen: false }))}
              onExpand={() => dispatch(updateRightbar({ setIsOpen: true }))}>
              <RightSection onClose={collapsePanel} onExpand={expandPanel} />
            </ResizablePanel>
          </ResizablePanelGroup>
        </div>
      </div>
      <Toaster richColors position="top-center" />
    </div>
  );
}
