/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { Notepad } from "@phosphor-icons/react";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { ExternalLink, MoveDown, MoveUp, Plus, Trash2 } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl, formatDollarValueMillionsBillions, truncateText } from "@/lib/utils";
import { FrontendTaxonomy, FrontendTaxonomyEntry } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { useMemo, useRef } from "react";
import { CompanyTypeChip, HypeFlameSizeIcon } from "@/components/company-table/common/CellComponents";
import { useScoredTaxonomy } from "@/components/scored-taxonomy/ScoredTaxonomyContext";
import { calculateWeightedScore } from "@/components/scored-taxonomy/ScoredTaxonomyTreeViewer";
import { ScoreChip } from "@/components/SourceCompanies/GiantTable/SourceCompaniesTable";

export type ScoredTaxonomyEntryRow = FrontendTaxonomyEntry & {
  beta_score?: number;
  node_title?: string;
  alpha_score?: number;
  overall_score?: number;
};

// TODO: remove all of these repeated codes.
const SortingDirectionIndicator = ({ column }: { column: Column<ScoredTaxonomyEntryRow> }) => {
  if (column.getIsSorted() === "asc") {
    return <MoveDown size={14} />;
  } else if (column.getIsSorted() === "desc") {
    return <MoveUp size={14} />;
  } else {
    return null;
  }
};

const alphaScoreSorting = (rowA: Row<ScoredTaxonomyEntryRow>, rowB: Row<ScoredTaxonomyEntryRow>): number => {
  const a = rowA.original.alpha_score;
  const b = rowB.original.alpha_score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

const betaScoreSorting = (rowA: Row<ScoredTaxonomyEntryRow>, rowB: Row<ScoredTaxonomyEntryRow>): number => {
  const a = rowA.original.beta_score;
  const b = rowB.original.beta_score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

const overallScoreSorting = (rowA: Row<ScoredTaxonomyEntryRow>, rowB: Row<ScoredTaxonomyEntryRow>): number => {
  const a = rowA.original.overall_score;
  const b = rowB.original.overall_score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

const topicScoreSorting = (rowA: Row<ScoredTaxonomyEntryRow>, rowB: Row<ScoredTaxonomyEntryRow>, topic: string): number => {
  const scoreA = rowA.original.scoring_result?.topic_results.find((result) => result.topic === topic)?.score ?? 0;
  const scoreB = rowB.original.scoring_result?.topic_results.find((result) => result.topic === topic)?.score ?? 0;
  return scoreB - scoreA;
};

export const ScoredTaxonomyCompanyTable = ({ taxonomy }: { taxonomy: FrontendTaxonomy }) => {
  const { excludedCategoryIds, betaWeights, alphaWeights, overallScoreBetaWeight, overallScoreAlphaWeight } = useScoredTaxonomy();
  const tableWindowRef = useRef<HTMLDivElement>(null);

  // then go through the entries and add them as subrows to the appropriate rows

  // Memoize the table rows
  const tableRows = useMemo(() => {
    const rows: ScoredTaxonomyEntryRow[] = [];
    taxonomy.entries.forEach((entry) => {
      if (entry.traversal_path.length === 0) {
        console.log("entry has no traversal path", entry.company.domain);
      } else {
        let pathIndex = 0;
        let subNodeIndex: number | undefined;
        let currentNode = taxonomy.taxonomy_node;
        let currentNodeIsExcluded = false;
        // console.log(entry.company.domain, " currentNode ", currentNode.node_title);
        while (pathIndex < entry.traversal_path.length) {
          // console.log(entry.company.domain, " currentNode ", currentNode.node_title);
          subNodeIndex = entry.traversal_path[pathIndex].choice_number;
          if (subNodeIndex == null || subNodeIndex >= currentNode.subnodes.length) {
            break;
          } else {
            currentNode = currentNode.subnodes[subNodeIndex];
          }
          pathIndex += 1;
          if (excludedCategoryIds.includes(currentNode.node_id)) {
            currentNodeIsExcluded = true;
          }
        }
        // console.log(tableRows);
        // console.log(subNodeIndex);
        // console.log(currentRow);
        // console.log("adding entry to row", currentRow, entry.company.domain);
        if (currentNode == null) {
          console.log("currentNode is null", entry.company.domain);
        } else {
          if (!currentNodeIsExcluded) {
            const betaScore = currentNode.category_scoring?.topic_results
              ? calculateWeightedScore(currentNode.category_scoring.topic_results, betaWeights)
              : 0;
            const alphaScore = entry.scoring_result?.topic_results
              ? calculateWeightedScore(entry.scoring_result.topic_results, alphaWeights)
              : 0;
            const overallScore = betaScore * overallScoreBetaWeight + alphaScore * overallScoreAlphaWeight;
            rows.push({
              ...entry,
              beta_score: currentNode.category_scoring?.topic_results
                ? calculateWeightedScore(currentNode.category_scoring.topic_results, betaWeights)
                : 0,
              alpha_score: entry.scoring_result?.topic_results
                ? calculateWeightedScore(entry.scoring_result.topic_results, alphaWeights)
                : 0,
              node_title: currentNode.node_title,
              overall_score: overallScore
            });
          }
        }
      }
    });
    return rows;
  }, [taxonomy, excludedCategoryIds, betaWeights, alphaWeights, overallScoreBetaWeight, overallScoreAlphaWeight]);
  // console.log(tableRows);

  const columns = useMemo(() => {
    const basicColumns: ColumnDef<ScoredTaxonomyEntryRow>[] = [
      {
        accessorKey: "name",
        // size: 100,
        header: () => (
          <div className="flex flex-row items-center">
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex flex-col items-start py-2 pl-2 z-40">
            <div className="flex flex-row items-center">
              <CompanyLogo
                name={row.original.company.name}
                website={row.original.company.domain}
                logo={row.original.company.logo_url}
                className="w-8 h-8 mr-2 opacity-100"
              />
              <div className="flex flex-col">
                <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                  {row.original.company.name}
                </h1>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
                    {row.original.company.stock_ticker ?? ""}
                  </span>
                  <a
                    href={ensureAbsoluteUrl(row.original.company.domain ?? "")}
                    className="flex flex-row hover:cursor-pointer font-medium underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ExternalLink size={14} color="grey" />
                  </a>
                  {row.original.company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
                    </a>
                  )}
                  <HypeFlameSizeIcon hype={row.original.company.hype_rating} />
                </div>
              </div>
            </div>
          </div>
        ),
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "overall_score",
        header: (header) => (
          <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Score</h1>
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }) => <ScoreChip numberOfDecimals={1} score={row.original.overall_score} maxScore={10} minScore={0} />,
        sortingFn: overallScoreSorting
      },
      {
        accessorKey: "alpha_score",
        header: (header) => (
          <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Alpha Score</h1>
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }) => <ScoreChip numberOfDecimals={1} score={row.original.alpha_score} maxScore={2} minScore={-2} />,
        sortingFn: alphaScoreSorting
      },
      {
        accessorKey: "beta_score",
        header: (header) => (
          <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Beta Score</h1>
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }) => <ScoreChip numberOfDecimals={1} score={row.original.beta_score} maxScore={10} minScore={0} />,
        sortingFn: betaScoreSorting
      },
      {
        accessorKey: "title",
        size: 35,
        header: () => (
          <div className="flex flex-row items-center">
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Category</h1>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center py-2 overflow-visible whitespace-nowrap ">
            {/* {row.original.subRows.length} */}
            {/* <div style={{ width: `${row.original.depth * 20}px` }}></div> */}
            {row.original.node_title != null && (
              <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
                <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
                  {/* <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible w-0"> */}
                  {/* <span className="text-colors-text-text-tertiary-(600) mr-1">({row.original.totalEntries})</span> */}
                  {truncateText(row.original.node_title, 150)}
                  {/* </h1> */}
                </span>
              </div>
            )}
          </div>
          // <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible whitespace-nowrap">{row.getValue("category")}</h1>
        ),
        meta: {
          group: "meta"
        }
      },

      {
        accessorKey: "companyType",
        header: () => (
          <div className="flex flex-row items-center">
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
          </div>
        ),
        size: 160,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
            {/* <CompanyTypeIcon type={row.original.company_type} /> */}
            <CompanyTypeChip type={row.original.company.company_type} />
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Company type"
        }
      },
      {
        accessorKey: "employee_count",
        // size: 25,
        size: 80,
        header: () => (
          <div>
            <span>Employees</span>
          </div>
        ),
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-center">
            {/* TODO: show the range */}
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {employeeRangeText(row.original.company.employee_min, row.original.company.employee_max, row.original.company.employee_guess)}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Employee Count"
        }
        // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
        // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
      },
      {
        accessorKey: "overview",
        header: () => "Overview",
        size: 180,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.overview.text}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Overview"
        }
      },
      {
        accessorKey: "business_model",
        header: () => "Business model",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.business_model.text}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Business Model"
        }
      },
      {
        accessorKey: "main_offerings",
        header: () => "Main offerings",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.main_offerings.text}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Main Offerings"
        }
      },
      {
        accessorKey: "customer_groups",
        header: () => "Customer groups",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.customer_segments.text}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Customer groups"
        }
      },
      {
        accessorKey: "geographies",
        header: () => "Geographies",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.geographies.text}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Geographies"
        }
      },
      {
        accessorKey: "headquarters",
        size: 100,
        header: () => <div className="w-auto">HQ</div>,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.company.hq_str}</span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Headquarters"
        }
      },
      // DROPPING THIS FROM TABLE BECAUSE DATA IS SUPER SPARSE
      // {
      //   accessorKey: "investors",
      //   header: () => <div className="w-auto">Investors</div>,
      //   cell: ({ row }) => (
      //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
      //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.list_of_investors}</span>
      //     </div>
      //   ),
      //   meta: {
      //     group: "basic_info",
      //     displayName: "Investors"
      //   }
      // },
      {
        accessorKey: "funds_raised",
        size: 100,
        header: () => <div className="w-auto justify-end text-right">Known fundraising</div>,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {/* TODO: format this properly */}
              {row.original.company.total_known_fundraising?.amount
                ? formatDollarValueMillionsBillions(row.original.company.total_known_fundraising.amount)
                : "-"}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Known Fundraising"
        }
      },
      {
        accessorKey: "founded",
        size: 80,
        header: () => <div className="justify-end text-right">Founded</div>,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.company.year_founded}</span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Founding year"
        }
      },
      {
        accessorKey: "web_traffic",
        size: 80,
        header: () => <div className="justify-end text-right">Web traffic</div>,
        cell: ({ row }) => {
          const visitsLastMonth = row.original.company.web_traffic?.visits_last_month;
          const visitGrowthMonthly = row.original.company.web_traffic?.visit_growth_monthly;
          const formattedVisits = visitsLastMonth
            ? visitsLastMonth >= 1000000
              ? (visitsLastMonth / 1000000).toFixed(1) + "M"
              : (visitsLastMonth / 1000).toFixed(0) + "k"
            : visitsLastMonth;
          const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
          const formattedGrowth =
            visitGrowthMonthly !== undefined
              ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%`
              : "";

          return (
            <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
              <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
                {formattedVisits}
                <br />
                <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
              </span>
            </div>
          );
        },
        meta: {
          group: "basic_info",
          displayName: "Web traffic"
        }
      }
    ];
    const newColumns = [...basicColumns];

    const getAllColumns = () => {
      Object.entries(alphaWeights).forEach(([topic, _]) => {
        newColumns.push({
          accessorKey: topic,
          header: (header) => (
            <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
              <h1 className="text-colors-text-text-tertiary-(600) pl-2">{topic}</h1>
              <SortingDirectionIndicator column={header.column} />
            </div>
          ),
          cell: ({ row }) => (
            <ScoreChip
              // numberOfDecimals={1}
              score={row.original.scoring_result?.topic_results.find((result) => result.topic === topic)?.score ?? 0}
              maxScore={2}
              minScore={-2}
            />
          ),
          sortingFn: (rowA, rowB) => topicScoreSorting(rowA, rowB, topic)
        });
      });
      newColumns.push({
        accessorKey: "trash",
        size: 50,
        header: ({ table }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                  <Plus size={20} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
                <span className="flex items-center justify-start my-1 p-1">
                  <Notepad className="mr-1" size={22} />
                  <h1 className="font-semi">Basic info</h1>
                </span>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                  .map((column: any) => {
                    // TODO: stop using any
                    return (
                      <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                        <Checkbox
                          key={column.id}
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) => {
                            column.toggleVisibility(!!value);
                            setTimeout(() => {
                              if (tableWindowRef.current) {
                                tableWindowRef.current.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                              }
                            }, 50);
                          }}>
                          {column.id}
                        </Checkbox>

                        <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                      </div>
                    );
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
        enableHiding: false,
        cell: ({ row, table }) => {
          const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
          return isRowHovered ? (
            <div className="flex justify-center items-center">
              <Button
                className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
                onClick={(e) => {
                  // if (row.original.id && row.original._category) {
                  //   onBusinessDelete(row.original.id, row.original._category, row.original._subcategory);
                  // }
                  e.stopPropagation();
                }}>
                <Trash2 size={16} className="trash-icon" strokeWidth={2} />
              </Button>
            </div>
          ) : (
            <div className="min-w-[22px] min-h-[39px]"></div>
          );
        }
      });
    };

    getAllColumns();
    return newColumns;
  }, []);

  return <DataTable columns={columns} data={tableRows} ref={tableWindowRef} />;
};
