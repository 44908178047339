import { ensureAbsoluteUrl } from "@/lib/utils";
import { FrontendCompany } from "@/services/autogen";
import { CompanyLogo } from "../ui/avatar";
import { ExternalLink, Mail, Merge, Plus } from "lucide-react";
import { Button } from "../ui/button";
import { Money, TrendUp, UserList } from "@phosphor-icons/react";

enum UpdateType {
  Team = "Team",
  MA = "M&A",
  Fundraise = "Fundraise",
  Commercial = "Commercial"
}

type Update = {
  message: string;
  updateType: UpdateType;
};

const possibleUpdates: Update[] = [
  { message: "New partnership", updateType: UpdateType.Commercial },
  { message: "Round raised", updateType: UpdateType.Fundraise },
  { message: "Exec team change", updateType: UpdateType.Team },
  { message: "Acquired a company", updateType: UpdateType.MA },
  { message: "Product launch", updateType: UpdateType.Commercial },
  { message: "Spike in web traffic", updateType: UpdateType.Commercial },
  { message: "Increase in fit score", updateType: UpdateType.Commercial }
];

export const UpdateTypeIcon = ({ updateType }: { updateType: UpdateType }) => {
  if (updateType === UpdateType.Team) {
    return <UserList size={16} />;
  } else if (updateType === UpdateType.MA) {
    return <Merge size={16} />;
  } else if (updateType === UpdateType.Fundraise) {
    return <Money size={16} />;
  } else if (updateType === UpdateType.Commercial) {
    return <TrendUp size={16} />;
  }
};

export const SpoofedWeeklyDigest = ({ companies }: { companies: FrontendCompany[] }) => {
  let randomCompanies: FrontendCompany[] = [];

  if (companies.length > 0) {
    const indices = new Set<number>();
    while (indices.size < Math.min(10, companies.length)) {
      indices.add(Math.floor(Math.random() * companies.length));
    }
    const selectedCompanies = Array.from(indices).map((index) => companies[index]);
    randomCompanies = selectedCompanies;
  }

  return (
    <div className="flex flex-col space-y-8">
      {randomCompanies.map((company, i) => {
        const randomUpdate = possibleUpdates[Math.floor(Math.random() * possibleUpdates.length)];
        const randomWeeks = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
        return (
          <div
            key={i}
            className="flex flex-row items-center border border-colors-border-border-primary rounded-xl p-5 bg-gray-50 justify-between">
            <div className="flex flex-row py-2 items-center h-full flex-1">
              <CompanyLogo name={company.name} website={company.domain} logo={company.logo_url} className="mr-2" />
              <div className="flex flex-col">
                <div className="text-base text-colors-brand-900 font-semibold">{company.name}</div>
                <div className="flex flex-row items-center gap-2 mt-1">
                  <div className="text-colors-text-text-tertiary-(600) font-semibold">{company.stock_ticker}</div>
                  {company.domain && (
                    <a
                      href={ensureAbsoluteUrl(company.domain)}
                      className="flex flex-row hover:cursor-pointer underline text-blue-800"
                      target="_blank"
                      rel="noopener noreferrer">
                      <ExternalLink size={16} />
                    </a>
                  )}
                  {company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-4 w-4" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col  text-sm rounded-md p-2 w-48 items-start">
              <div className="flex flex-row items-center gap-2 text-sm font-medium text-colors-brand-700 bg-colors-brand-100 border border-colors-brand-300 p-1 px-2 mb-2 rounded-md">
                <UpdateTypeIcon updateType={randomUpdate.updateType} />
                {randomUpdate.updateType}
              </div>
              <div className="text-colors-gray-modern-700 text-sm font-medium">{randomUpdate.message}</div>
              <div className="text-colors-gray-modern-700 text-xs">{randomWeeks} weeks ago</div>
            </div>
            <div className="flex flex-col gap-2 flex-1 justify-end items-end">
              <Button className="gap-2" size="sm">
                <Mail size={16} /> Draft message
              </Button>
              <Button variant="outline" className="gap-2" size="sm">
                <Plus size={16} /> Add to CRM
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
