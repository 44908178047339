/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CompanyTearsheet,
  CompanyTearsheetStreamingMessage,
  FrontendCompany,
  TaskStatus,
  TearsheetEnrichment,
  TearsheetEnrichmentType
} from "@/services/autogen";
import { getLastViewedCompanyIds, getTearsheetEnrichment, updateLastViewedCompanyIds } from "@/services/brain-api.service";
import { createAsyncThunk, createSlice, Middleware } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { EventSourcePolyfill } from "event-source-polyfill";

export type CompanyProfileStub = {
  name: string;
  domain: string;
  logo_url?: string;
};

export type CompanyProfilesState = {
  activeCompanyDomain?: string;
  deprCompanyProfiles: FrontendCompany[];
  deprLastViewedProfiles: CompanyProfileStub[];
  profilesLoading: string[];
  tearsheets: CompanyTearsheet[];
  lastViewedDomains: string[];
};

const initialState: CompanyProfilesState = {
  deprCompanyProfiles: [],
  deprLastViewedProfiles: [],
  profilesLoading: [],
  tearsheets: [],
  lastViewedDomains: []
};

export const deprFetchCompanyProfileStreamingThunk = createAsyncThunk(
  "fetchCompanyProfileStreaming",
  async (thunkPayload: { token: string; company_domain: string }, thunkAPI) => {
    const { company_domain } = thunkPayload;
    thunkAPI.dispatch(companyProfilesSlice.actions.setDomainLoading({ domain: company_domain, loading: true }));
    const baseUrl = import.meta.env.VITE_BRAIN_SERVER_PATH;
    // TODO: add headers to this request. look at  "polyfill" like eventsource or eventsource-polyfill
    const eventSource = new EventSource(`${baseUrl}/brain/v1/get_company_profile_streaming?company_domain=${company_domain}`);
    eventSource.onmessage = (event) => {
      const company: FrontendCompany = JSON.parse(event.data);
      thunkAPI.dispatch(companyProfilesSlice.actions.deprLoadCompanyProfile({ company }));
      thunkAPI.dispatch(companyProfilesSlice.actions.setActiveCompanyProfile({ companyProfileId: company.domain }));
    };

    eventSource.onerror = () => {
      eventSource.close();
      // TODO: this probably doesn't trigger when connection is closed properly
      thunkAPI.dispatch(companyProfilesSlice.actions.setDomainLoading({ domain: company_domain, loading: false }));
    };
  }
);

export const fetchCompanyTearsheetStreamingThunk = createAsyncThunk(
  "fetchCompanyTearsheetStreaming",
  async (thunkPayload: { token: string; company_domain: string }, thunkAPI) => {
    const { token, company_domain } = thunkPayload;
    thunkAPI.dispatch(companyProfilesSlice.actions.setDomainLoading({ domain: company_domain, loading: true }));
    const baseUrl = import.meta.env.VITE_BRAIN_SERVER_PATH;
    const eventSource = new EventSourcePolyfill(`${baseUrl}/brain/v1/get_company_tearsheet_streaming?company_domain=${company_domain}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      heartbeatTimeout: 240000
    });
    eventSource.onmessage = (event) => {
      const tearsheetMessage: CompanyTearsheetStreamingMessage = JSON.parse(event.data);
      const tearsheet = tearsheetMessage.tearsheet;
      if (tearsheet) {
        thunkAPI.dispatch(companyProfilesSlice.actions.removeDomainFromLastViewed({ domain: company_domain }));
        thunkAPI.dispatch(companyProfilesSlice.actions.loadTearsheet({ tearsheet }));
        thunkAPI.dispatch(companyProfilesSlice.actions.setActiveCompanyProfile({ 
            companyProfileId: tearsheet.company.domain,
          useTearsheet: true 
        }));
        thunkAPI.dispatch(companyProfilesSlice.actions.setDomainLoading({ domain: tearsheet.company.domain, loading: true }));
      }
      if (tearsheetMessage.status === TaskStatus.Incomplete) {
        thunkAPI.dispatch(companyProfilesSlice.actions.setDomainLoading({ domain: company_domain, loading: false }));
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
      thunkAPI.dispatch(companyProfilesSlice.actions.setDomainLoading({ domain: company_domain, loading: false }));
    };
  }
);

export const fetchLastViewedCompanyIdsThunk = createAsyncThunk(
  "fetchLastViewedCompanyIds",
  async (thunkPayload: { token: string }, thunkAPI) => {
    const { token } = thunkPayload;
    getLastViewedCompanyIds(token).then((response) => {
      const companyIds = response.data;
      // companyIds.forEach((companyId) => {
      //   thunkAPI.dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: companyId }));
      // });
      thunkAPI.dispatch(companyProfilesSlice.actions.setLastViewedCompanyIds({ company_ids: companyIds }));
    });
  }
);

export const fetchTearsheetEnrichmentThunk = createAsyncThunk(
  "fetchTearsheetEnrichment",
  async (thunkPayload: { token: string; companyDomain: string; enrichmentType: TearsheetEnrichmentType }, thunkAPI) => {
    const { token, companyDomain, enrichmentType } = thunkPayload;
    getTearsheetEnrichment(token, companyDomain, enrichmentType).then((response) => {
      thunkAPI.dispatch(companyProfilesSlice.actions.loadTearsheetEnrichment({ company_domain: companyDomain, enrichment: response.data }));
    });
  }
);

export const companyProfilesSlice = createSlice({
  name: "companyProfiles",
  initialState,
  reducers: {
    setActiveCompanyProfile: (state, action: PayloadAction<{ companyProfileId: string; useTearsheet?: boolean }>) => {
      // TODO: useTearsheet==False isn't supported anymore
      const { companyProfileId, useTearsheet } = action.payload;
      state.activeCompanyDomain = companyProfileId;
      if (useTearsheet) {
        const tearsheet = state.tearsheets.find((tearsheet) => tearsheet.company.domain === companyProfileId);
        if (tearsheet) {
        } else {
          return;
        }
      }
      // Remove any existing entry for this company
      state.lastViewedDomains = state.lastViewedDomains.filter((domain) => domain !== companyProfileId);
      // Add the stub to the top of lastViewedProfiles
      state.lastViewedDomains.unshift(companyProfileId);
      if (state.lastViewedDomains.length > 5) {
        state.lastViewedDomains = state.lastViewedDomains.slice(0, 5);
      }
    },
    deprLoadCompanyProfile: (state, action: PayloadAction<{ company: FrontendCompany }>) => {
      const { company } = action.payload;
      const existingCompanyIndex = state.deprCompanyProfiles.findIndex((profile) => profile.domain === company.domain);
      if (existingCompanyIndex === -1) {
        // Company not found, add it to the array
        state.deprCompanyProfiles.push(company);
      } else {
        // Company found, update it
        state.deprCompanyProfiles[existingCompanyIndex] = company;
      }
    },
    setDomainLoading: (state, action: PayloadAction<{ domain: string; loading: boolean }>) => {
      const { domain, loading } = action.payload;
      if (loading) {
        if (!state.profilesLoading.includes(domain)) {
          state.profilesLoading.push(domain);
        }
      } else {
        state.profilesLoading = state.profilesLoading.filter((d) => d !== domain);
      }
    },
    loadTearsheet: (state, action: PayloadAction<{ tearsheet: CompanyTearsheet }>) => {
      const { tearsheet } = action.payload;
      const existingTearsheetIndex = state.tearsheets.findIndex((t) => t.company.domain === tearsheet.company.domain);
      if (existingTearsheetIndex === -1) {
        // Company not found, add it to the array
        state.tearsheets.push(tearsheet);
      } else {
        // Company found, update it
        state.tearsheets[existingTearsheetIndex] = tearsheet;
      }
    },
    setLastViewedCompanyIds: (state, action: PayloadAction<{ company_ids: string[] }>) => {
      const { company_ids } = action.payload;
      state.lastViewedDomains = company_ids;
    },
    loadTearsheetEnrichment: (state, action: PayloadAction<{ company_domain: string; enrichment: TearsheetEnrichment }>) => {
      const { company_domain, enrichment } = action.payload;
      const tearsheet = state.tearsheets.find((tearsheet) => tearsheet.company.domain === company_domain);
      if (tearsheet) {
        if (tearsheet.enrichments == null) {
          tearsheet.enrichments = [];
        }
        tearsheet.enrichments.push(enrichment);
      }
    },
    removeDomainFromLastViewed: (state, action: PayloadAction<{ domain: string }>) => {
      const { domain } = action.payload;
      state.lastViewedDomains = state.lastViewedDomains.filter((d) => d !== domain);
    }
  }
});

export const companyProfilesUpdateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action); // Dispatch action to reducers, state is updated here
  // List of actions that should trigger a backend update
  const actionsToSync = ["companyProfiles/setActiveCompanyProfile"];

  if (actionsToSync.includes(action.type)) {
    const state = storeAPI.getState() as RootState;
    const lastViewedDomains = state.companyProfiles.lastViewedDomains;
    if (state.user.token) {
      console.log("syncing", lastViewedDomains);
      updateLastViewedCompanyIds(state.user.token, lastViewedDomains);
    }
  }
  return result;
};

// Action creators are generated for each case reducer function
export const { setActiveCompanyProfile, deprLoadCompanyProfile: loadCompanyProfile, loadTearsheet, setLastViewedCompanyIds } = companyProfilesSlice.actions;

export default companyProfilesSlice.reducer;
