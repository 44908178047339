import { Info, Trash2 } from "lucide-react";
import { SimpleTooltip } from "../ui/tooltip";
import { Plus } from "lucide-react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";

export const FilterHeader = ({
  title,
  infoText,
  description,
  onDelete,
  onAdd,
  isActive,
  viewOnly,
  disableAdd,
  icon
}: {
  title: string;
  infoText?: string;
  description?: string;
  onDelete: () => void;
  onAdd: () => void;
  isActive: boolean;
  viewOnly?: boolean;
  disableAdd?: boolean;
  icon?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row gap-2 items-center mb-1 text-colors-text-text-secondary-(700)">
          {icon && <div>{icon}</div>}
          <Label className="text-sm font-semibold">{title}</Label>
          {infoText && (
            <SimpleTooltip text={infoText}>
              <Info size={14} />
            </SimpleTooltip>
          )}
        </div>
        {!viewOnly && (
          <div className="flex items-center">
            {isActive ? (
              <Button onClick={onDelete} variant="destructive" size="icon" className="h-6 w-6">
                <Trash2 size={14} />
              </Button>
            ) : (
              <Button onClick={onAdd} variant="ghost" size="icon" className="h-6 w-6 p-0" disabled={disableAdd}>
                <Plus size={14} />
              </Button>
            )}
          </div>
        )}
      </div>
      {isActive && description && <div className="mt-1 text-sm text-colors-gray-modern-600">{description}</div>}
    </div>
  );
};

// TODO: this
export const SearchBoxHeader = ({
  title,
  infoText,
  description,
  onDelete,
  onAdd,
  isActive,
  viewOnly,
  disableAdd,
  icon
}: {
  title: string;
  infoText?: string;
  description?: string;
  onDelete: () => void;
  onAdd: () => void;
  isActive: boolean;
  viewOnly?: boolean;
  disableAdd?: boolean;
  icon?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row gap-2 items-center mb-1 text-colors-text-text-secondary-(700)">
          {icon && <div>{icon}</div>}
          <Label className="text-sm font-semibold">{title}</Label>
          {infoText && (
            <SimpleTooltip text={infoText}>
              <Info size={14} />
            </SimpleTooltip>
          )}
        </div>
        {!viewOnly && (
          <div className="flex items-center">
            {isActive ? (
              <Button onClick={onDelete} variant="destructive" size="icon" className="h-6 w-6">
                <Trash2 size={14} />
              </Button>
            ) : (
              <Button onClick={onAdd} variant="ghost" size="icon" className="h-6 w-6 p-0" disabled={disableAdd}>
                <Plus size={14} />
              </Button>
            )}
          </div>
        )}
      </div>
      {isActive && description && <div className="mt-1 text-sm text-colors-gray-modern-600">{description}</div>}
    </div>
  );
};
