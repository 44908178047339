import { Plus, X } from "lucide-react";
import {
  FrontendCompanySearchInstructions,
  FrontendCompanySearchScoringQuestion,
  FrontendDerivativeScoring,
  FrontendInformationLevel,
  FrontendScoringDirection,
  ScoringWeightMapping
} from "@/services/autogen";

import { Button } from "../../ui/button";
import { Ranking } from "@phosphor-icons/react";

import { Label } from "../../ui/label";
import { AutosizedTextarea } from "../../ui/textarea";

import { FilterHeader } from "../../workflow-inputs/FilterHeader";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";

const MeticulateDerivativeScoringWeightEditor = ({
  title,
  scoring,
  setScoring,
  viewOnly
}: {
  title: string;
  scoring?: FrontendDerivativeScoring;
  setScoring: (scoring?: FrontendDerivativeScoring) => void;
  viewOnly?: boolean;
}) => {
  return (
    <div>
      <FilterHeader
        title={title}
        onDelete={() => {
          setScoring(undefined);
        }}
        onAdd={() => {
          setScoring({ weight: 1, scoring_direction: FrontendScoringDirection.HigherIsBetter });
        }}
        isActive={scoring != null}
        viewOnly={viewOnly}
      />
      {scoring != null && (
        <ScoringQuestionWeightInput
          weight={scoring.weight}
          setWeight={(weight) => {
            setScoring({ ...scoring, weight });
          }}
          viewOnly={viewOnly}
        />
      )}
    </div>
  );
};

const ScoringQuestionWeightInput = ({
  weight,
  setWeight,
  viewOnly
}: {
  weight: number;
  setWeight: (weight: number) => void;
  viewOnly?: boolean;
}) => {
  const getClosestWeight = (weight: number) => {
    return Object.keys(ScoringWeightMapping).reduce(
      (prev, curr) =>
        Math.abs(weight - ScoringWeightMapping[curr as keyof typeof ScoringWeightMapping]) <
        Math.abs(weight - ScoringWeightMapping[prev as keyof typeof ScoringWeightMapping])
          ? curr
          : prev,
      "none"
    );
  };
  return (
    <div className="flex flex-row gap-2 items-center">
      <Label className="text-xs text-colors-text-text-secondary-(700)">Importance</Label>
      <ToggleGroup
        type="single"
        size="sm"
        variant="outline"
        className="gap-2"
        value={getClosestWeight(weight)}
        onValueChange={(value) => {
          if (value) setWeight(ScoringWeightMapping[value as keyof typeof ScoringWeightMapping]);
        }}
        disabled={viewOnly}>
        {/* <ToggleGroupItem value="none">None</ToggleGroupItem> */}
        <ToggleGroupItem value="low">Low</ToggleGroupItem>
        <ToggleGroupItem value="medium">Medium</ToggleGroupItem>
        <ToggleGroupItem value="high">High</ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};

const CompanySearchScoringQuestionEditor = ({
  question,
  setQuestion,
  viewOnly,
  index
}: {
  question?: FrontendCompanySearchScoringQuestion;
  setQuestion: (question?: FrontendCompanySearchScoringQuestion) => void;
  viewOnly?: boolean;
  index: number;
}) => {
  return (
    <div className="bg-colors-green-25 p-4 rounded-lg">
      <FilterHeader
        title={`Scoring question #${index + 1}`}
        // infoText="A question that will be used to score companies"
        onDelete={() => {
          setQuestion(undefined);
        }}
        onAdd={() => {
          setQuestion({
            question: "",
            information_level: FrontendInformationLevel.StandardCorpus,
            low_score_standard: "",
            high_score_standard: "",
            weight: 1
          });
        }}
        isActive={question != null}
        viewOnly={viewOnly}
        disableAdd={true}
      />
      {question != null && (
        <div className="flex flex-col gap-2 items-start">
          <Label className="text-xs text-gray-550">Question</Label>
          <AutosizedTextarea
            placeholder="e.g. number of metro areas served"
            value={question.question}
            onChange={(e) => {
              setQuestion({ ...question, question: e.target.value });
            }}
            disabled={viewOnly}
          />
          <Label className="text-xs text-gray-550">A high score means...</Label>
          <AutosizedTextarea
            placeholder="e.g. more than 100 metro areas"
            value={question.high_score_standard}
            onChange={(e) => {
              setQuestion({ ...question, high_score_standard: e.target.value });
            }}
            disabled={viewOnly}
          />
          <Label className="text-xs text-gray-550">A low score means...</Label>
          <AutosizedTextarea
            placeholder="e.g. less than 10 metro areas"
            value={question.low_score_standard}
            onChange={(e) => {
              setQuestion({ ...question, low_score_standard: e.target.value });
            }}
            disabled={viewOnly}
          />
          {/* <Label className="text-xs text-colors-text-text-secondary-(700)">Importance</Label> */}
          <ScoringQuestionWeightInput
            weight={question.weight}
            setWeight={(weight) => {
              setQuestion({ ...question, weight });
            }}
            viewOnly={viewOnly}
          />
        </div>
      )}
    </div>
  );
};

export const CompanySearchScoringBox = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendCompanySearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendCompanySearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  const scoringInstructions = companySearchInstructions.scoring_instructions;
  return (
    <div className="flex flex-col gap-2 border-colors-green-200 border p-2 rounded-lg w-[400px]">
      <div className="text-lg font-medium flex flex-row items-center gap-2 w-full justify-between">
        <div className="flex flex-row items-center gap-2">
          <Ranking size={24} />
          <span>Scoring</span>
        </div>
        {scoringInstructions && !viewOnly && (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: undefined
              });
            }}>
            <span className="text-red-500">Remove scoring</span>
            <X size={16} className="text-red-500" />
          </Button>
        )}
      </div>
      {scoringInstructions == null && (
        <Button
          variant="ghost"
          size="sm"
          disabled={viewOnly}
          onClick={() => {
            setCompanySearchInstructions({
              ...companySearchInstructions,
              scoring_instructions: { scoring_questions: [] }
            });
          }}>
          <Plus size={16} /> Enable scoring
        </Button>
      )}
      {scoringInstructions != null && (
        <>
          <MeticulateDerivativeScoringWeightEditor
            title="Hype level"
            scoring={scoringInstructions.hype_scoring}
            setScoring={(scoring) => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  hype_scoring: scoring
                }
              });
            }}
            viewOnly={viewOnly}
          />
          <MeticulateDerivativeScoringWeightEditor
            title="Growth level"
            scoring={scoringInstructions.growth_scoring}
            setScoring={(scoring) => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  growth_scoring: scoring
                }
              });
            }}
            viewOnly={viewOnly}
          />
          <FilterHeader
            title="Tech stack"
            description="The company ideally uses..."
            infoText="Describe technologies the companies should have"
            viewOnly={viewOnly}
            onDelete={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  tech_stack_scoring: undefined
                }
              });
            }}
            onAdd={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  tech_stack_scoring: { question: "", weight: ScoringWeightMapping.medium }
                }
              });
            }}
            isActive={scoringInstructions.tech_stack_scoring != null}
          />
          {scoringInstructions.tech_stack_scoring != null && (
            <>
              <AutosizedTextarea
                value={scoringInstructions.tech_stack_scoring.question ?? ""}
                disabled={viewOnly}
                placeholder="e.g. 'data warehouse', 'Google analytics', or 'Webflow' "
                onChange={(e) => {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...scoringInstructions,
                      tech_stack_scoring: { ...scoringInstructions.tech_stack_scoring!, question: e.target.value }
                    }
                  });
                }}
              />
              <ScoringQuestionWeightInput
                weight={scoringInstructions.tech_stack_scoring.weight}
                viewOnly={viewOnly}
                setWeight={(weight) => {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...scoringInstructions,
                      tech_stack_scoring: { ...scoringInstructions.tech_stack_scoring!, weight }
                    }
                  });
                }}
              />
            </>
          )}
          <FilterHeader
            title="Employee roles"
            description="The company ideally has..."
            infoText="Describe the employee roles the companies should have"
            viewOnly={viewOnly}
            onDelete={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  employee_roles_scoring: undefined
                }
              });
            }}
            onAdd={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  employee_roles_scoring: { question: "", weight: ScoringWeightMapping.medium }
                }
              });
            }}
            isActive={scoringInstructions.employee_roles_scoring != null}
          />
          {scoringInstructions.employee_roles_scoring != null && (
            <>
              <AutosizedTextarea
                value={scoringInstructions.employee_roles_scoring.question ?? ""}
                disabled={viewOnly}
                placeholder="e.g. 'a Head of Operations' or 'Node.js developers'"
                onChange={(e) => {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...scoringInstructions,
                      employee_roles_scoring: { ...scoringInstructions.employee_roles_scoring!, question: e.target.value }
                    }
                  });
                }}
              />
              <ScoringQuestionWeightInput
                weight={scoringInstructions.employee_roles_scoring.weight}
                viewOnly={viewOnly}
                setWeight={(weight) => {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...scoringInstructions,
                      employee_roles_scoring: { ...scoringInstructions.employee_roles_scoring!, weight }
                    }
                  });
                }}
              />
            </>
          )}
          {/* TODO: abstract these 2 out into a component */}
          <FilterHeader
            title="Hiring activity"
            description="The company is ideally hiring..."
            infoText="Describe the roles the companies should be hiring for"
            viewOnly={viewOnly}
            onDelete={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  hiring_activity_scoring: undefined
                }
              });
            }}
            onAdd={() => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_instructions: {
                  ...scoringInstructions,
                  hiring_activity_scoring: { question: "", weight: ScoringWeightMapping.medium }
                }
              });
            }}
            isActive={scoringInstructions.hiring_activity_scoring != null}
          />
          {scoringInstructions.hiring_activity_scoring != null && (
            <>
              <AutosizedTextarea
                value={scoringInstructions.hiring_activity_scoring.question ?? ""}
                placeholder="e.g. 'entry level devops engineers' or 'AEs in Germany'"
                disabled={viewOnly}
                onChange={(e) => {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...scoringInstructions,
                      hiring_activity_scoring: { ...scoringInstructions.hiring_activity_scoring!, question: e.target.value }
                    }
                  });
                }}
              />
              <ScoringQuestionWeightInput
                weight={scoringInstructions.hiring_activity_scoring.weight}
                viewOnly={viewOnly}
                setWeight={(weight) => {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...scoringInstructions,
                      hiring_activity_scoring: { ...scoringInstructions.hiring_activity_scoring!, weight }
                    }
                  });
                }}
              />
            </>
          )}
          {companySearchInstructions.scoring_instructions?.scoring_questions.map((question, idx) => (
            <CompanySearchScoringQuestionEditor
              key={idx}
              index={idx}
              question={question}
              viewOnly={viewOnly}
              setQuestion={(question) => {
                if (question == null) {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...companySearchInstructions.scoring_instructions!,
                      scoring_questions: companySearchInstructions.scoring_instructions!.scoring_questions.filter((_, i) => i !== idx)
                    }
                  });
                } else {
                  setCompanySearchInstructions({
                    ...companySearchInstructions,
                    scoring_instructions: {
                      ...companySearchInstructions.scoring_instructions!,
                      scoring_questions: companySearchInstructions.scoring_instructions!.scoring_questions.map((q, i) =>
                        i === idx ? question : q
                      )
                    }
                  });
                }
              }}
            />
          ))}
        </>
      )}

      {scoringInstructions != null && scoringInstructions.scoring_questions.length < 2 && (
        <Button
          variant="ghost"
          size="sm"
          disabled={viewOnly}
          onClick={() => {
            setCompanySearchInstructions({
              ...companySearchInstructions,
              scoring_instructions: {
                ...scoringInstructions,
                scoring_questions: [
                  ...scoringInstructions.scoring_questions,
                  {
                    question: "",
                    information_level: FrontendInformationLevel.Search,
                    low_score_standard: "",
                    high_score_standard: "",
                    weight: ScoringWeightMapping.medium
                  }
                ]
              }
            });
          }}>
          <Plus size={16} /> Add custom scoring question
        </Button>
      )}
    </div>
  );
};
