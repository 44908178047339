import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Info, Play, Undo2, Clipboard, Copy } from "lucide-react";
import { FrontendCompanySearchInstructions, FrontendCompanySearchParameters } from "@/services/autogen";

import { Button } from "../../ui/button";
import { demoFeaturesOn } from "@/utils/config";

import posthog from "posthog-js";
import {
  CompanySearchCreationStatus,
  deprRunCompanySearchThunk,
  deprSetCompanySearchCreationStatus,
  deprUpdateGlobalSearchInstructions
} from "@/store/deprCompanySearches";

import { CompanySearchQualificationBox } from "./Qualification";
import { CompanySearchScoringBox } from "./Scoring";
import { CompanySearchSemanticSearchBox } from "./SemanticSearch";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { VolumeRequestedToggleGroup } from "./InitialInputs";
export const CompanySearchInstructionsViewAndEdit = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendCompanySearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendCompanySearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-4 w-full max-h-[80vh]">
      {!viewOnly && demoFeaturesOn && (
        <div className="flex flex-row gap-2 justify-end">
          <SimpleTooltip text="Load instructions from JSON in clipboard">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                navigator.clipboard.readText().then((text) => {
                  try {
                    const jsonData = JSON.parse(text);
                    const instructions = jsonData as FrontendCompanySearchInstructions;
                    setCompanySearchInstructions(instructions);
                  } catch (e) {
                    console.error("Failed to parse JSON:", e);
                  }
                });
              }}>
              <Clipboard size={16} />
              Load from JSON
            </Button>
          </SimpleTooltip>
          <SimpleTooltip text="Copy search instructions as JSON">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                const jsonStr = JSON.stringify(companySearchInstructions);
                navigator.clipboard.writeText(jsonStr);
              }}>
              <Copy size={16} />
              Copy as JSON
            </Button>
          </SimpleTooltip>
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-4 items-start w-full">
        <CompanySearchSemanticSearchBox
          companySearchInstructions={companySearchInstructions}
          setCompanySearchInstructions={setCompanySearchInstructions}
          viewOnly={viewOnly}
        />
        <CompanySearchQualificationBox
          companySearchInstructions={companySearchInstructions}
          setCompanySearchInstructions={setCompanySearchInstructions}
          viewOnly={viewOnly}
        />
        <CompanySearchScoringBox
          companySearchInstructions={companySearchInstructions}
          setCompanySearchInstructions={setCompanySearchInstructions}
          viewOnly={viewOnly}
        />
      </div>
    </div>
  );
};

export const CompanySearchInstructionsEditor = ({ closeDialog }: { closeDialog: () => void }) => {
  const companySearchInstructions = useAppSelector((state) => state.deprCompanySearches.searchInstructions);
  const dispatch = useAppDispatch();
  const setCompanySearchInstructions = (instructions: FrontendCompanySearchInstructions) => {
    dispatch(deprUpdateGlobalSearchInstructions({ instructions }));
  };
  if (companySearchInstructions == null) return null;

  const token = useAppSelector((state) => state.user.token);
  return (
    <div className="flex flex-col gap-2 text-xs w-auto mb-4">
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center gap-2 w-full justify-end mb-2">
          <SimpleTooltip text="Target number of companies to bring back. Searches with more volume can take much longer (~15 min)">
            <div className="text-xs flex flex-row items-center gap-1 text-colors-gray-modern-400">
              Volume <Info size={12} />
            </div>
          </SimpleTooltip>
          <VolumeRequestedToggleGroup
            volumeRequested={companySearchInstructions.parameters?.initial_volume_requested ?? 50}
            setVolumeRequested={(volumeRequested) => {
              const existingParameters = companySearchInstructions.parameters;
              const newParameters: FrontendCompanySearchParameters = {
                ...existingParameters,
                initial_volume_requested: volumeRequested
              };
              setCompanySearchInstructions({ ...companySearchInstructions, parameters: newParameters });
            }}
          />
        </div>

        <div className="flex flex-row w-full justify-between gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => dispatch(deprSetCompanySearchCreationStatus(CompanySearchCreationStatus.Inputs))}>
            <Undo2 size={20} />
            Back
          </Button>
          <Button
            size="sm"
            // variant="outline"
            onClick={() => {
              posthog.capture("user_ran_company_search", { instructions: companySearchInstructions });
              if (token != null) {
                dispatch(deprRunCompanySearchThunk({ token: token, instructions: companySearchInstructions }));
                closeDialog();
                dispatch(deprSetCompanySearchCreationStatus(CompanySearchCreationStatus.Entrypoint));
              }
            }}>
            Run search
            <Play size={20} />
          </Button>
        </div>
      </div>
      <CompanySearchInstructionsViewAndEdit
        companySearchInstructions={companySearchInstructions}
        setCompanySearchInstructions={setCompanySearchInstructions}
      />
    </div>
  );
};
