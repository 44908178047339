import {
  FindCompaniesV2Inputs,
  FindCompaniesWorkflowV2,
  FrontendCompany,
  GenericWorkflow,
  HardFilter,
  HardMetric,
  TaskStatus,
  WorkflowType
} from "@/services/autogen";
import {
  addSimilarCompaniesToFindCompaniesWorkflowThunk,
  refineGenericWorkflowThunk,
  removeFindCompaniesV2Company
} from "@/store/genericWorkflows";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { WorkflowViewerFeedbackAndExport } from "@/components/GenericWorkflows/WorkflowViewer";

import { Binoculars, Eye, EyeSlash, Funnel, PaperPlaneRight, PlusSquare, Sparkle, Spinner } from "@phosphor-icons/react";
import { DeprCompanyTypeFilterAddButton, CompanyTypeFilterInput } from "@/components/workflow-inputs/CompanyType";
import { HqCountryFilterInput } from "@/components/workflow-inputs/HqCountry";
import { DeprHardMetricFilterAddButton, HardMetricFilterInput } from "@/components/workflow-inputs/HardFilters";
import { CompanyLogo } from "../ui/avatar";
import { Slider } from "@mui/material";
import { toast } from "sonner";
import posthog from "posthog-js";
import { useAuth } from "@clerk/clerk-react";
import React, { useCallback } from "react";
import { SimpleTooltip } from "../ui/tooltip";
import { FindCompaniesV2WorkflowCreator } from "./FindCompaniesV2WorkflowCreator";
import { hardMetricDisplayText } from "@/lib/utils";
import { COUNTRY_OPTIONS } from "@/components/workflow-inputs/CountryOptions";
import { Badge } from "../ui/badge";
import { addCompaniesToList, createCompanyList } from "@/store/companyLists";
import { demoFeaturesOn } from "@/utils/config";
import { UnderlinedTabs, UnderlinedTabsContent, UnderlinedTabsList, UnderlinedTabsTrigger } from "../ui/tabs";
import { SpoofedWeeklyDigest } from "./WeeklyDigest";
import { Bell } from "lucide-react";
import { DeprCompanyTable } from "@/components/company-table/common/CompanyTable";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";

const RefineWorkflowBox = ({ workflow }: { workflow: GenericWorkflow }) => {
  const dispatch = useAppDispatch();
  const [employeeCountFilter, setEmployeeCountFilter] = useState<HardFilter>();
  const [foundedYearFilter, setFoundedYearFilter] = useState<HardFilter>();
  const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
  const [workflowInputs, setWorkflowInputs] = useState(findCompaniesWorkflow.inputs);
  const { getToken } = useAuth();
  const existingRequestedNumberOfCompanies = findCompaniesWorkflow.inputs.requested_number_of_companies ?? 40;
  const getNextMultipleOf20 = (num: number): number => {
    return Math.ceil(num / 20) * 20;
  };
  const numberToReturnMinValue = getNextMultipleOf20(
    Math.max(findCompaniesWorkflow.outputs?.companies.length ?? 0, existingRequestedNumberOfCompanies, 40)
  );

  useEffect(() => {
    setWorkflowInputs(findCompaniesWorkflow.inputs);
    if (findCompaniesWorkflow.inputs.filters) {
      const employeeCountHardFilter = findCompaniesWorkflow.inputs.filters.find(
        (filter) => filter.hard_metric === HardMetric.EmployeeCount
      );
      if (employeeCountHardFilter) {
        setEmployeeCountFilter(employeeCountHardFilter);
      } else {
        setEmployeeCountFilter(undefined);
      }
      const foundedYearHardFilter = findCompaniesWorkflow.inputs.filters.find((filter) => filter.hard_metric === HardMetric.FoundedYear);
      if (foundedYearHardFilter) {
        setFoundedYearFilter(foundedYearHardFilter);
      } else {
        setFoundedYearFilter(undefined);
      }
    } else {
      setEmployeeCountFilter(undefined);
      setFoundedYearFilter(undefined);
    }
  }, [findCompaniesWorkflow]);

  const updateFilters = () => {
    const filters = [employeeCountFilter, foundedYearFilter].filter((f) => f != null) as HardFilter[];
    setWorkflowInputs({
      ...workflowInputs,
      filters: filters
    });
  };

  useEffect(() => {
    updateFilters();
  }, [employeeCountFilter, foundedYearFilter]);

  const onRefine = async () => {
    const token = await getToken();
    if (token) {
      const newFindCompaniesWorkflow = { ...findCompaniesWorkflow, inputs: workflowInputs };
      const newGenericWorkflow = { ...workflow, workflow: newFindCompaniesWorkflow };
      dispatch(refineGenericWorkflowThunk({ token, workflow: newGenericWorkflow })).then(() => {
        posthog.capture("user_refined_workflow", {
          type: WorkflowType.FindCompaniesV2,
          inputs: workflowInputs,
          workflow_id: workflow.workflow_id
        });
      });
    } else {
      toast.error("Could not get user session. Try refreshing the page");
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-colors-gray-modern-50 border-colors-brand-600 border rounded-lg p-4">
      {existingRequestedNumberOfCompanies != null && existingRequestedNumberOfCompanies < 100 && (
        <>
          <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg">
            <PlusSquare size={20} /> Add more companies
          </div>
          <div className="flex flex-row items-center gap-2 text-colors-text-text-secondary-(700)">
            <div className="text-sm ">Total number of companies to bring back: </div>
            <div className="flex flex-row justify-between w-40 items-center">
              <div className="mr-3 text-sm font-semibold">{workflowInputs.requested_number_of_companies ?? numberToReturnMinValue}</div>
              <Slider
                value={workflowInputs.requested_number_of_companies ?? numberToReturnMinValue}
                max={100}
                min={numberToReturnMinValue}
                step={20}
                onChange={(_, newValue) => {
                  setWorkflowInputs({
                    ...workflowInputs,
                    requested_number_of_companies: newValue as number
                  });
                }}
                valueLabelDisplay="auto"
                sx={{
                  "& .MuiSlider-thumb": {
                    color: "#9f78ed"
                  },
                  "& .MuiSlider-track": {
                    color: "#9f78ed"
                  },
                  "& .MuiSlider-rail": {
                    color: "#9f78ed"
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <p className="text-colors-text-text-secondary-(700) text-sm">
              You can find similar companies to any of the companies in the table by clicking on the
            </p>
            <Sparkle className="text-colors-brand-600" />
          </div>
        </>
      )}

      <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg mt-4">
        <Funnel size={20} /> Filters
      </div>
      <div className="p-2 gap-2 flex flex-col">
        <div className="flex flex-col flex-wrap gap-2 w-80">
          <CompanyTypeFilterInput
            filter={workflowInputs.company_type_filter}
            setFilter={(filter) => {
              console.log("setFilter", filter);
              setWorkflowInputs({ ...workflowInputs, company_type_filter: filter });
            }}
          />
          <HardMetricFilterInput
            metric={HardMetric.EmployeeCount}
            filter={employeeCountFilter}
            setFilter={(filter) => {
              setEmployeeCountFilter(filter);
            }}
          />
          <HardMetricFilterInput
            metric={HardMetric.FoundedYear}
            filter={foundedYearFilter}
            setFilter={(filter) => {
              setFoundedYearFilter(filter);
            }}
          />
          <HqCountryFilterInput
            hqCountryCodes={workflowInputs.hq_country_filter}
            setHqCountryCodes={(hqCountryCodes) => {
              setWorkflowInputs({ ...workflowInputs, hq_country_filter: hqCountryCodes });
            }}
          />
        </div>
        <div className="flex flex-col flex-wrap gap-2 w-60">
          <DeprCompanyTypeFilterAddButton
            filter={workflowInputs.company_type_filter}
            setFilter={(filter) => {
              setWorkflowInputs({ ...workflowInputs, company_type_filter: filter });
            }}
          />
          <DeprHardMetricFilterAddButton
            metric={HardMetric.EmployeeCount}
            filter={employeeCountFilter}
            setFilter={(filter) => {
              setEmployeeCountFilter(filter);
            }}
          />
          <DeprHardMetricFilterAddButton
            metric={HardMetric.FoundedYear}
            filter={foundedYearFilter}
            setFilter={(filter) => {
              setFoundedYearFilter(filter);
            }}
          />
        </div>
      </div>

      {/* <p className="text-sm text-colors-text-text-secondary-(700)">Removed companies</p>
      <div className="flex flex-row items-center gap-2">
        {findCompaniesWorkflow.outputs?.removed_company_domains?.map((domain) => <CompanyLogo name={domain} website={domain} />)}
      </div> */}
      <Button
        variant="default"
        className="w-40"
        // className="rounded-lg border border-colors-brand-500 px-4 py-2 mr-2 bg-colors-brand-100 hover:bg-colors-brand-300"
        onClick={onRefine}>
        <PaperPlaneRight size={22} className="mr-2 " />
        <p>Refine</p>
      </Button>
    </div>
  );
};

const FindCompaniesV2InputsViewer = ({ inputs }: { inputs: FindCompaniesV2Inputs }) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg text-sm text-colors-text-text-secondary-(700) mb-2 p-2 border border-colors-brand-400">
      <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg">
        <Binoculars size={20} /> Search request
      </div>
      {inputs.business_model != null && <div>Business model: {inputs.business_model}</div>}
      {inputs.main_offerings != null && <div>Main offerings: {inputs.main_offerings}</div>}
      {inputs.customer_segments != null && <div>Customer segments: {inputs.customer_segments}</div>}
      {inputs.geographies != null && <div>Geographies: {inputs.geographies}</div>}
      {inputs.technologies != null && <div>Technologies: {inputs.technologies}</div>}
      {inputs.exemplar_company_domains && inputs.exemplar_company_domains.length > 0 && (
        <div className="flex flex-row gap-2 items-center">
          <div>Exemplar companies</div>
          <div className="flex flex-row items-center gap-2">
            {inputs.exemplar_company_domains.map((domain) => (
              <SimpleTooltip text={domain}>
                <CompanyLogo name={domain} website={domain} className="w-6 h-6" />
              </SimpleTooltip>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg">
        <Funnel size={20} /> Filters
      </div>
      {inputs.company_type_filter && <CompanyTypeFilterInput filter={inputs.company_type_filter} setFilter={() => {}} viewOnly={true} />}
      {inputs.hq_country_filter && (
        <div>
          <div className="flex flex-row items-center gap-2 flex-wrap">
            HQ Countries
            {inputs.hq_country_filter.map((country) => {
              return <Badge>{COUNTRY_OPTIONS.find((c) => c.value === country)?.label}</Badge>;
            })}
          </div>
        </div>
      )}
      {inputs.filters &&
        inputs.filters.map((filter) => (
          <div className="flex flex-row items-center space-x-2 text-sm text-colors-text-text-secondary-(700)">
            <div>{hardMetricDisplayText(filter.hard_metric) + ":"}</div>
            <div>
              {filter.min ?? "Any"} - {filter.max ?? "Any"}
            </div>
          </div>
        ))}
    </div>
  );
};

const AddCompaniesToListDropdown = ({
  domainsSelected,
  findCompaniesWorkflow,
  workflowTitle
}: {
  domainsSelected: string[];
  findCompaniesWorkflow: FindCompaniesWorkflowV2;
  workflowTitle: string;
}) => {
  const companyLists = useAppSelector((state) => state.companyLists.companyLists);
  const dispatch = useAppDispatch();
  const companyDomainsSelected = domainsSelected;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button>Add companies to list</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className="flex flex-col gap-2 p-4">
          <div className="text-sm text-gray-500">Add to list</div>
          {companyLists.map((list) => (
            <DropdownMenuItem asChild>
              <Button
                variant="outline"
                key={list.id}
                onClick={async () => {
                  if (companyDomainsSelected.length == 0) {
                    toast.error("Please select at least one company");
                    return;
                  }
                  // Typescript doesn't recognize that the companies can't be null
                  const companies = companyDomainsSelected
                    .map((domain) => findCompaniesWorkflow.outputs?.companies.find((c) => c.domain === domain))
                    .filter((c) => c != null) as FrontendCompany[];
                  dispatch(addCompaniesToList({ companies, companyListId: list.id }));
                  toast.success(`Added ${companyDomainsSelected.length} companies to ${list.title}`);
                }}>
                {list.title}
              </Button>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem asChild>
            <Button
              variant="secondary"
              // variant=""
              onClick={async () => {
                if (companyDomainsSelected.length == 0) {
                  toast.error("Please select at least one company");
                  return;
                }
                // Typescript doesn't recognize that the companies can't be null
                const companies = companyDomainsSelected
                  .map((domain) => findCompaniesWorkflow.outputs?.companies.find((c) => c.domain === domain))
                  .filter((c) => c != null) as FrontendCompany[];
                dispatch(createCompanyList({ title: `Companies from ${workflowTitle}`, companies: companies }));
                toast.success(`Added ${companyDomainsSelected.length} companies to New list`);
              }}>
              New list
            </Button>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const FindCompaniesV2WorkflowViewer = React.memo(function FindCompaniesV2WorkflowViewer({
  workflow,
  onOpenRightSection,
  hideRefine
}: {
  workflow: GenericWorkflow;
  onOpenRightSection?: () => void;
  hideRefine?: boolean;
}) {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const [companyDomainsToAddToList, setCompanyDomainsToAddToList] = useState<string[]>([]);
  const workflowId = workflow.workflow_id;
  const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
  const onBusinessDelete = useCallback(
    (companyDomain: string) => {
      dispatch(removeFindCompaniesV2Company({ workflowId, companyDomain }));
    },
    [dispatch, workflowId]
  );

  const findSimilarBusinessesCallback = async (businessDomain: string) => {
    const token = await getToken();
    posthog.capture("user_clicked_find_similar_companies", {
      workflow_id: workflowId,
      company_domain: businessDomain
    });
    if (token) {
      dispatch(
        addSimilarCompaniesToFindCompaniesWorkflowThunk({
          token: token,
          workflowId: workflowId,
          companyDomain: businessDomain
        })
      );
    }
  };

  useEffect(() => {
    onOpenRightSection && onOpenRightSection();
  }, []);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [inputsOpen, setInputsOpen] = useState(false);
  if (workflow.status === TaskStatus.Created) {
    return (
      <div className="flex flex-col h-full w-full gap-4">
        <FindCompaniesV2WorkflowCreator workflow={workflow} />
      </div>
    );
  }
  return (
    <div className="flex flex-col h-full w-full gap-4">
      {workflow.status === TaskStatus.Running && (
        <div className="w-full bg-colors-blue-100 border border-colors-blue-600 p-2 rounded-lg text-colors-blue-800 flex flex-row items-center">
          <Spinner size={24} className="mr-2 animate-spin" />
          <p>
            This workflow is still running but initial results will show up here within ~30 seconds. More companies will continue to appear
            as the search continues. You can export or refine the search after it's finished.
          </p>
        </div>
      )}
      <UnderlinedTabs defaultValue="workflow" className="flex flex-col h-full w-full">
        <UnderlinedTabsList>
          <UnderlinedTabsTrigger value="workflow">All companies</UnderlinedTabsTrigger>
          {demoFeaturesOn && (
            <UnderlinedTabsTrigger value="weekly_digest">
              Weekly Digest
              <span className="text-colors-error-600 ml-2 flex flex-row items-center">
                <Bell size={16} />
                10
              </span>
            </UnderlinedTabsTrigger>
          )}
        </UnderlinedTabsList>
        <UnderlinedTabsContent value="workflow" className="flex flex-col h-full w-full gap-4">
          <WorkflowViewerFeedbackAndExport workflow={workflow} disableCopy disableExport={workflow.status === TaskStatus.Running}>
            <AddCompaniesToListDropdown
              domainsSelected={companyDomainsToAddToList}
              findCompaniesWorkflow={findCompaniesWorkflow}
              workflowTitle={workflow.title}
            />
            {!hideRefine ? (
              <Button
                variant="outline"
                onClick={() => {
                  setFiltersOpen(!filtersOpen);
                  if (inputsOpen && !filtersOpen) {
                    setInputsOpen(false);
                  }
                }}>
                <Sparkle size={20} className="mr-2" />
                {filtersOpen ? "Hide" : "Refine this search"}
              </Button>
            ) : (
              <></>
            )}
            <Button
              variant="outline"
              onClick={() => {
                setInputsOpen(!inputsOpen);
                if (filtersOpen && !inputsOpen) {
                  setFiltersOpen(false);
                }
              }}>
              {inputsOpen ? <EyeSlash size={20} className="mr-2" /> : <Eye size={20} className="mr-2" />}
              {inputsOpen ? "Hide request" : "Show search request"}
            </Button>
          </WorkflowViewerFeedbackAndExport>
          {inputsOpen && <FindCompaniesV2InputsViewer inputs={findCompaniesWorkflow.inputs} />}
          {filtersOpen && <RefineWorkflowBox workflow={workflow} />}
          <DeprCompanyTable
            companies={findCompaniesWorkflow.outputs?.companies ?? []}
            onRowDelete={onBusinessDelete}
            findSimilarCallback={findSimilarBusinessesCallback}
            isRunning={workflow.status === TaskStatus.Running}
            selectedDomains={companyDomainsToAddToList}
            onSelectedDomainsChange={setCompanyDomainsToAddToList}
          />
        </UnderlinedTabsContent>
        <UnderlinedTabsContent value="weekly_digest">
          <SpoofedWeeklyDigest companies={findCompaniesWorkflow.outputs?.companies ?? []} />
        </UnderlinedTabsContent>
      </UnderlinedTabs>
    </div>
  );
});
