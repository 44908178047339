import { RelatedCompanies } from "@/services/autogen";
import { CompanyLogo } from "../ui/avatar";
import { ensureAbsoluteUrl } from "@/lib/utils";
import { ExternalLink, SquareStack } from "lucide-react";
import _ from "lodash";
import { useAuth } from "@clerk/clerk-react";
import { useAppDispatch } from "@/store/hooks";
import { fetchCompanyTearsheetStreamingThunk, setActiveCompanyProfile } from "@/store/companyProfiles";
import { LoadingNullContentsWrapper } from "./LoadingNullContentsWrapper";

export const RelatedCompaniesContents = ({ relatedCompanies }: { relatedCompanies?: RelatedCompanies }) => {
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();
  if (relatedCompanies == null || relatedCompanies.similar == null || relatedCompanies.similar.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-row flex-wrap gap-2 items-start">
      {relatedCompanies.similar.map((company, index) => {
        return (
          <div
            className="flex flex-row text-colors-text-text-tertiary-(600) font-light text-sm flex-wrap gap-2 bg-colors-gray-modern-50 rounded-xl p-2 border cursor-pointer items-center"
            key={index}
            onClick={async () => {
              const token = await getToken();
              if (token !== null) {
                // dispatch(fetchCompanyProfileThunk({ token, domain: value }));
                dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: company.domain }));
                dispatch(setActiveCompanyProfile({ companyProfileId: company.domain }));
              }
            }}>
            {/* TODO: if you click on this, should load the company profile */}
            <CompanyLogo name={company.name} website={company.domain} logo={company.logo_url} className="w-8 h-8" />
            <div className="flex flex-col">
              <div className="text-sm text-colors-brand-900 font-bold">{_.truncate(company.name, { length: 20 })}</div>
              <div className="flex flex-row items-center gap-2 mt-[2px]">
                {company.domain && (
                  <a
                    href={ensureAbsoluteUrl(company.domain)}
                    className="flex flex-row hover:cursor-pointer underline text-blue-800"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}>
                    <ExternalLink size={12} />
                  </a>
                )}
                {company.linkedin_aid && (
                  <a
                    href={ensureAbsoluteUrl("linkedin.com/company/" + company.linkedin_aid)}
                    className="self-center"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}>
                    <img src="/linkedin.png" className="h-3 w-3" />
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RelatedCompaniesDisplay = ({ relatedCompanies, loading }: { relatedCompanies?: RelatedCompanies; loading: boolean }) => {
  return (
    <div className="flex flex-col border border-colors-border-border-primary bg-white rounded-xl p-3 w-full  min-w-60">
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <SquareStack size={20} />
        <div>Related companies</div>
      </div>
      <LoadingNullContentsWrapper
        loading={loading}
        objectIsNull={relatedCompanies == null || relatedCompanies.similar == null || relatedCompanies.similar.length === 0}>
        <RelatedCompaniesContents relatedCompanies={relatedCompanies} />
      </LoadingNullContentsWrapper>
    </div>
  );
};
