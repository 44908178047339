/* eslint-disable @typescript-eslint/no-empty-function */
import { FrontendFullSearch } from "@/services/autogen";
import { useLoaderData } from "react-router-dom";
import { useAppDispatch } from "@/store/hooks";
import { useEffect } from "react";
import { Toaster } from "sonner";
import { CompanySearchHeader } from "./CompanySearchHeader";
import { CompanySearchViewer } from "./CompanySearchViewer";
import { loadSearch, setActiveCompanySearch } from "@/store/companySearches";
import { SearchRunningStatus } from "@/services/autogen";

export function ShareableCompanySearchViewer() {
  const dispatch = useAppDispatch();

  const companySearch = useLoaderData() as FrontendFullSearch | null;
  const isSearchComplete =
    companySearch?.running_status === SearchRunningStatus.Completed || companySearch?.running_status === SearchRunningStatus.Failed;

  useEffect(() => {
    if (companySearch) {
      dispatch(loadSearch({ search: companySearch }));
      dispatch(setActiveCompanySearch(companySearch.search_id));
    }
  }, [companySearch]);
  if (companySearch == null) {
    return <div>404</div>;
  }

  return (
    <>
      <div className="flex app-background">
        <div className="flex flex-col w-full h-screen">
          <div className="py-1">
            <CompanySearchHeader shareableHeader disableAddToAccount={!isSearchComplete} />
          </div>
          <div className="flex flex-col flex-grow w-full p-10 overflow-hidden">
            <CompanySearchViewer viewOnly />
          </div>
        </div>
        <Toaster richColors position="top-center" />
      </div>
    </>
  );
}
