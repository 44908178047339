/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { RightSection } from "@/components/GenericWorkflows/RightSection";
import Header from "@/components/GenericWorkflows/Header";
import { WorkflowViewer } from "@/components/GenericWorkflows/WorkflowViewer";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../ui/resizable";
import { useCallback, useRef } from "react";
import { ImperativePanelHandle } from "react-resizable-panels";
import { AlertTriangle } from "lucide-react";
import { Dashboard } from "@/components/dashboard/Dashboard";
import { updateLeftbar, updateRightbar } from "@/store/sidebars";
import { CompanyProfileViewer } from "../company-profile-v2/CompanyProfileViewer";
import { View } from "@/store/views";
import { CompanyListHeader, CompanyListViewer } from "../company-lists/CompanyListViewer";
import { CompanyProfileHeader } from "../company-profile-v2/CompanyProfileHeader";
import { DocumentsViewer } from "../documents/DocumentsViewer";
import { CompanyInboxViewer } from "../CompanyInbox/CompanyInboxViewer";
import { TaxonomyViewer } from "../taxonomy/TaxonomyViewer";
import { EnrichmentsAdminView } from "../dashboard/EnrichmentsAdminView";
import { RubricScoringViewer } from "../rubric-scoring/RubricScoringViewer";
import { ScoredTaxonomyViewer } from "../scored-taxonomy/ScoredTaxonomyViewer";
import { PeopleListHeader, PeopleListViewer } from "../people-lists/PeopleListViewer";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../ui/sidebar";
import { AppSidebar } from "../sidebar/app-sidebar";
import { DeprCompanySearchViewer } from "../depr-company-searches/CompanySearchViewer";
import { DeprCompanySearchHeader } from "../depr-company-searches/CompanySearchHeader";
import { CompanySearchViewer } from "../company-searches/CompanySearchViewer";
import { CompanySearchHeader } from "../company-searches/CompanySearchHeader";

export const Workspace = () => {
  const activeView = useAppSelector((state) => state.views.activeView);

  const dispatch = useAppDispatch();
  const ref = useRef<ImperativePanelHandle>(null);

  const collapsePanel = useCallback(() => {
    const panel = ref.current;
    if (panel) {
      panel.collapse();
    }
    dispatch(updateRightbar({ setIsOpen: false }));
  }, [dispatch, ref]);

  const expandPanel = useCallback(() => {
    const panel = ref.current;
    if (panel) {
      panel.expand();
    }
    dispatch(updateRightbar({ setIsOpen: true }));
  }, [dispatch, ref]);

  const openaiErrorBannerOn = false;
  const bandwidthIssueBannerOn = false;
  let rightSectionEnabled = true;
  // TODO: pull out everything that is not workflow dependent into a higher level component so the refreshes and things are in a workflow specific component
  let viewer: React.ReactNode;
  let header: React.ReactNode;
  if (activeView === View.Dashboard) {
    viewer = <Dashboard onCloseRightSection={collapsePanel} />;
    header = null;
    rightSectionEnabled = false;
  }
  if (activeView === View.Company) {
    viewer = <CompanyProfileViewer onCloseRightSection={collapsePanel} />;
    header = <CompanyProfileHeader />;
    rightSectionEnabled = false;
  } else if (activeView === View.CompanyList) {
    viewer = <CompanyListViewer onOpenRightSection={expandPanel} />;
    header = <CompanyListHeader />;
    rightSectionEnabled = false;
  } else if (activeView === View.Workflow) {
    viewer = <WorkflowViewer onCloseRightSection={collapsePanel} onOpenRightSection={expandPanel} />;
    header = <Header />;
  } else if (activeView === View.Documents) {
    viewer = <DocumentsViewer />;
    header = null;
    rightSectionEnabled = false;
  } else if (activeView === View.IngestedCompanies) {
    viewer = <CompanyInboxViewer />;
    header = null;
  } else if (activeView === View.Taxonomy) {
    viewer = <TaxonomyViewer />;
    header = null;
  } else if (activeView === View.EnrichmentsAdmin) {
    viewer = <EnrichmentsAdminView />;
    header = null;
    rightSectionEnabled = false;
  } else if (activeView === View.RubricScoring) {
    viewer = <RubricScoringViewer onOpenRightSection={expandPanel} />;
    header = null;
    rightSectionEnabled = true;
  } else if (activeView === View.ScoredTaxonomy) {
    viewer = <ScoredTaxonomyViewer onOpenRightSection={expandPanel} onCloseRightSection={collapsePanel} />;
    header = null;
    rightSectionEnabled = true;
  } else if (activeView === View.PeopleList) {
    viewer = <PeopleListViewer />;
    header = <PeopleListHeader />;
    rightSectionEnabled = false;
  } else if (activeView === View.DeprCompanySearch) {
    viewer = <DeprCompanySearchViewer />;
    header = <DeprCompanySearchHeader />;
    rightSectionEnabled = false;
  } else if (activeView === View.CompanySearch) {
    viewer = <CompanySearchViewer />;
    header = <CompanySearchHeader />;
    rightSectionEnabled = false;
  }

  const leftBarOpen = useAppSelector((state) => state.sidebars.leftBarOpen);

  return (
    <SidebarProvider open={leftBarOpen} onOpenChange={(open) => dispatch(updateLeftbar({ setIsOpen: open }))}>
      <AppSidebar />
      <SidebarInset>
        <div className={`flex flex-col h-screen relative w-full max-w-full overflow-x-hidden`}>
          {!leftBarOpen && <SidebarTrigger className="absolute top-4 left-2 z-50" />}
          {header && <div className={`z-40 w-full`}>{header}</div>}
          <div className={`flex flex-col w-full`} style={{ height: header ? "calc(100vh - 64px)" : "100vh" }}>
            {openaiErrorBannerOn && (
              <div className="w-full min-h-10 h-auto bg-colors-background-bg-error-secondary p-2 items-center justify-center flex text-colors-error-700 flex-col">
                <div className="flex flex-row items-center">
                  <AlertTriangle size={20} className="mr-2" />
                  <div className="text-colors-error-700">
                    We are currently affected by OpenAI&apos;s{" "}
                    <a
                      href="https://status.openai.com"
                      className="text-blue-500 hover:text-blue-600"
                      target="_blank"
                      rel="noopener noreferrer">
                      API outage
                    </a>
                    . Results may be slow or might not come back at all. We&apos;ll get your results to you within 24 hours.
                  </div>
                </div>
              </div>
            )}
            {bandwidthIssueBannerOn && (
              <div className="w-full min-h-10 h-auto bg-colors-background-bg-error-secondary p-2 items-center justify-center flex text-colors-error-700 flex-col">
                <div className="flex flex-row items-center">
                  <AlertTriangle size={20} className="mr-2" />
                  <div className="text-colors-error-700">
                    We are currently facing bandwidth issues due to high volume of requests. Results may be slower than normal or fail to
                    run. We&apos;re working on scaling up and will get results to you as soon as possible.
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-row h-full w-full">
              <ResizablePanelGroup direction="horizontal" className={`h-full `}>
                <ResizablePanel id="main-section" defaultSize={70}>
                  <div className={`flex flex-col px-6 items-center gap-5 justify-start h-full py-4 overflow-y-scroll w-full`}>{viewer}</div>
                </ResizablePanel>
                {rightSectionEnabled && (
                  <>
                    <ResizableHandle withHandle className="z-[100]" />
                    <ResizablePanel
                      id="right-section"
                      defaultSize={30}
                      ref={ref}
                      collapsible
                      collapsedSize={3}
                      minSize={10}
                      onCollapse={() => dispatch(updateRightbar({ setIsOpen: false }))}
                      onExpand={() => dispatch(updateRightbar({ setIsOpen: true }))}>
                      <RightSection onClose={collapsePanel} onExpand={expandPanel} />
                    </ResizablePanel>
                  </>
                )}
              </ResizablePanelGroup>
            </div>
          </div>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};
