import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { CaretDown, Funnel, PaperPlaneRight, Spinner, WarningOctagon } from "@phosphor-icons/react";
import {
  FindCompaniesV2InitialInputs,
  FindCompaniesV2Inputs,
  FindCompaniesWorkflowV2,
  FindCompaniesWorkflowV2InputGenerationStateEnum,
  GenericWorkflow,
  HardFilter,
  HardMetric,
  WorkflowType
} from "@/services/autogen";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import posthog from "posthog-js";
import { toast } from "sonner";
import _ from "lodash";
import "animate.css";
import { isValidUrl } from "@/lib/utils";
import {
  findCompaniesInitialInputsToInputsThunk,
  runGenericWorkflowThunk,
  setFinalInputs,
  setInitialInputs,
  setInputGenerationState
} from "@/store/genericWorkflows";
import { AlertTriangle, Plus, RotateCcw, Trash } from "lucide-react";
import { DeprHardMetricFilterAddButton, HardMetricFilterInput } from "@/components/workflow-inputs/HardFilters";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { Input } from "@/components/ui/input";
import { ExemplarChip } from "@/components/workflow-inputs/ExemplarChip";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { CollapsibleTrigger } from "@radix-ui/react-collapsible";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { Slider } from "@mui/material";

import { CompanyDomainAutocomplete } from "@/components/workflow-inputs/companyWebsiteAutocomplete";
import { DeprCompanyTypeFilterAddButton, CompanyTypeFilterInput } from "../workflow-inputs/CompanyType";
import { HqCountryFilterAddButton, HqCountryFilterInput } from "../workflow-inputs/HqCountry";
import { fetchUserRunsAvailableThunk } from "@/store/user";

export const FindCompaniesV2InitialInputsEdit = ({
  initialInputs,
  setInitialInputs
}: {
  initialInputs: FindCompaniesV2InitialInputs;
  setInitialInputs: (inputs: FindCompaniesV2InitialInputs) => void;
}) => {
  const [invalidDomain, setInvalidDomain] = useState<boolean>(false);
  const [exemplarInput, setExemplarInput] = useState<string>("");
  const [employeeCountFilter, setEmployeeCountFilter] = useState<HardFilter>();
  const [foundedYearFilter, setFoundedYearFilter] = useState<HardFilter>();
  // const [noFundraisingFilter, _] = useState<HardFilter>();
  const defaultScope = useAppSelector((state) => state.user.deprDefaultScope);

  const updateFilters = () => {
    const filters = [employeeCountFilter, foundedYearFilter].filter((f) => f != null) as HardFilter[];
    setInitialInputs({
      ...initialInputs,
      filters: filters
    });
  };
  useEffect(() => {
    updateFilters();
  }, [employeeCountFilter, foundedYearFilter]);
  useEffect(() => {
    const employeeCountFilterFromInputs = initialInputs.filters?.find((filter) => filter.hard_metric === HardMetric.EmployeeCount);
    if (employeeCountFilterFromInputs) {
      setEmployeeCountFilter(employeeCountFilterFromInputs);
    }
    const foundedYearFilterFromInputs = initialInputs.filters?.find((filter) => filter.hard_metric === HardMetric.FoundedYear);
    if (foundedYearFilterFromInputs) {
      setFoundedYearFilter(foundedYearFilterFromInputs);
    }
  }, [initialInputs]);
  return (
    <>
      <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Describe the companies you want to find</div>
      <input
        className="border border-colors-border-border-primary bg-transparent rounded-lg px-4 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full overflow-y-auto"
        placeholder="e.g. 'peer-to-peer furniture marketplaces in Europe'"
        value={initialInputs.category_name}
        onChange={(e) => {
          setInitialInputs({ ...initialInputs, category_name: e.target.value });
        }}
      />
      <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Example companies</div>
      <div className="flex flex-row flex-wrap gap-2">
        {initialInputs.exemplar_company_domains?.map((business, businessIndex) => {
          return (
            <div key={businessIndex}>
              <ExemplarChip
                domain={business}
                onDelete={() =>
                  setInitialInputs({
                    ...initialInputs,
                    exemplar_company_domains: initialInputs.exemplar_company_domains?.filter((b) => b !== business)
                  })
                }
              />
            </div>
          );
        })}
      </div>
      <Popover>
        <SimpleTooltip text="Add an example company by domain">
          <PopoverTrigger asChild>
            <Button variant="ghost" className="p-2">
              <Plus className="mr-2" /> Add example company
            </Button>
          </PopoverTrigger>
        </SimpleTooltip>
        <PopoverContent>
          <div className={`w-72 flex flex-row ${invalidDomain ? "animate__animated animate__headShake" : ""}`}>
            <Input
              placeholder="Company domain"
              value={exemplarInput}
              onChange={(e) => setExemplarInput(e.target.value)}
              className="w-40 mr-5"
            />
            <Button
              onClick={() => {
                if (!isValidUrl(exemplarInput)) {
                  setInvalidDomain(true);
                  toast.error("Not a valid company domain (website). Please enter a domain like 'google.com' or 'facebook.com'");
                  setTimeout(() => {
                    setInvalidDomain(false);
                  }, 1000);
                  return;
                }
                setInitialInputs({
                  ...initialInputs,
                  exemplar_company_domains: [...(initialInputs.exemplar_company_domains || []), exemplarInput]
                });
              }}>
              Add
            </Button>
          </div>
        </PopoverContent>
      </Popover>
      <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Number of companies to bring back</div>
      <div className="flex flex-row justify-between w-full">
        <div className="mr-3 font-semibold">{initialInputs.requested_number_of_companies ?? 40}</div>
        <Slider
          value={initialInputs.requested_number_of_companies ?? 40}
          max={100}
          min={20}
          step={20}
          onChange={(_, newValue) => {
            setInitialInputs({
              ...initialInputs,
              requested_number_of_companies: newValue as number
            });
          }}
          valueLabelDisplay="auto"
          sx={{
            "& .MuiSlider-thumb": {
              color: "#9f78ed"
            },
            "& .MuiSlider-track": {
              color: "#9f78ed"
            },
            "& .MuiSlider-rail": {
              color: "#9f78ed"
            }
          }}
        />
      </div>
      <Collapsible className="w-full">
        <CollapsibleTrigger asChild>
          <Button className="w-full my-2">
            <Funnel size={20} className="mr-2" />
            <span>Narrow your search with filters</span>
            <CaretDown size={20} className="ml-2" />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col gap-2 border-colors-brand-200 border p-2 rounded-lg">
            <Button onClick={() => setInitialInputs({ ...initialInputs, ...defaultScope })}>Use default scope</Button>
            {/* <DesiredCountriesFilterInput
              filter={initialInputs.company_type_filter}
              setFilter={(filter) => {
                setInitialInputs({ ...initialInputs, company_type_filter: filter });
              }}
            /> */}
            <CompanyTypeFilterInput
              filter={initialInputs.company_type_filter}
              setFilter={(filter) => {
                setInitialInputs({ ...initialInputs, company_type_filter: filter });
              }}
            />
            <HqCountryFilterInput
              hqCountryCodes={initialInputs.hq_country_filter}
              setHqCountryCodes={(filter) => {
                setInitialInputs({ ...initialInputs, hq_country_filter: filter });
              }}
            />
            <HardMetricFilterInput
              metric={HardMetric.EmployeeCount}
              filter={employeeCountFilter}
              setFilter={(filter) => {
                setEmployeeCountFilter(filter);
              }}
            />
            <HardMetricFilterInput
              metric={HardMetric.FoundedYear}
              filter={foundedYearFilter}
              setFilter={(filter) => {
                setFoundedYearFilter(filter);
              }}
            />
            {/* <HardMetricFilterInput
              metric={HardMetric.NoFundraisingWindow}
              filter={noFundraisingFilter}
              setFilter={(filter) => {
                setNoFundraisingFilter(filter);
              }}
            /> */}
            <div className="flex flex-row flex-wrap gap-2">
              <DeprCompanyTypeFilterAddButton
                filter={initialInputs.company_type_filter}
                setFilter={(filter) => {
                  setInitialInputs({ ...initialInputs, company_type_filter: filter });
                }}
              />
              <HqCountryFilterAddButton
                hqCountryCodes={initialInputs.hq_country_filter}
                setHqCountryCodes={(hqCountryCodes) => {
                  setInitialInputs({ ...initialInputs, hq_country_filter: hqCountryCodes });
                }}
              />
              <DeprHardMetricFilterAddButton
                metric={HardMetric.EmployeeCount}
                filter={employeeCountFilter}
                setFilter={(filter) => {
                  setEmployeeCountFilter(filter);
                }}
              />
              <DeprHardMetricFilterAddButton
                metric={HardMetric.FoundedYear}
                filter={foundedYearFilter}
                setFilter={(filter) => {
                  setFoundedYearFilter(filter);
                }}
              />
              {/* <HardMetricFilterAddButton
                metric={HardMetric.NoFundraisingWindow}
                filter={noFundraisingFilter}
                setFilter={() => {
                  setNoFundraisingFilter({ hard_metric: HardMetric.NoFundraisingWindow, min: -3, max: 6 });
                }}
              /> */}
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </>
  );
};

export const FindCompaniesV2FinalInputsEdit = ({
  finalInputs,
  setFinalInputs
}: {
  finalInputs: FindCompaniesV2Inputs;
  setFinalInputs: (inputs: FindCompaniesV2Inputs) => void;
}) => {
  const [invalidDomain, setInvalidDomain] = useState<boolean>(false);
  const [exemplarInput, setExemplarInput] = useState<string>("");
  const defaultScope = useAppSelector((state) => state.user.deprDefaultScope);

  const [employeeCountFilter, setEmployeeCountFilter] = useState<HardFilter | undefined>(
    finalInputs.filters?.find((f) => f.hard_metric == HardMetric.EmployeeCount)
  );

  const [foundedYearFilter, setFoundedYearFilter] = useState<HardFilter | undefined>(
    finalInputs.filters?.find((f) => f.hard_metric == HardMetric.FoundedYear)
  );

  // const [noFundraisingFilter, _] = useState<HardFilter | undefined>(
  //   finalInputs.filters?.find((f) => f.hard_metric == HardMetric.NoFundraisingWindow)
  // );

  // Count non-null properties
  const nonNullCount = [
    finalInputs.business_model,
    finalInputs.main_offerings,
    finalInputs.geographies,
    finalInputs.customer_segments,
    finalInputs.technologies
  ].filter((input) => input != null).length;

  const updateFilters = () => {
    const filters = [employeeCountFilter, foundedYearFilter].filter((f) => f != null) as HardFilter[];
    setFinalInputs({
      ...finalInputs,
      filters: filters
    });
  };
  useEffect(() => {
    updateFilters();
  }, [employeeCountFilter, foundedYearFilter]);
  return (
    <>
      <div className="font-semibold my-3 text-colors-brand-800">
        Description: <span>{finalInputs.category_name}</span>
      </div>
      <div className="text-md mb-1 text-colors-text-text-primary-(900) self-start">Search criteria (only 3 allowed at a time)</div>
      {finalInputs.business_model != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Business model</div>
            <Button onClick={() => setFinalInputs({ ...finalInputs, business_model: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>
          <AutosizedTextarea
            // className="border border-colors-border-border-primary bg-transparent rounded-lg px-4 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full overflow-y-auto"
            placeholder="e.g. 'peer-to-peer used furniture marketplace'"
            value={finalInputs.business_model}
            onChange={(e) => {
              setFinalInputs({ ...finalInputs, business_model: e.target.value });
            }}
          />
        </>
      )}
      {finalInputs.main_offerings != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Main offerings</div>
            <Button onClick={() => setFinalInputs({ ...finalInputs, main_offerings: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            // className="border border-black"
            placeholder="e.g. 'lightweight CRM software', 'voice AI API'"
            value={finalInputs.main_offerings}
            onChange={(e) => {
              setFinalInputs({ ...finalInputs, main_offerings: e.target.value });
            }}
          />
        </>
      )}
      {finalInputs.customer_segments != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Customer segments</div>
            <Button onClick={() => setFinalInputs({ ...finalInputs, customer_segments: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            placeholder="e.g. 'financial services firms', or 'e-commerce companies'"
            value={finalInputs.customer_segments}
            onChange={(e) => {
              setFinalInputs({ ...finalInputs, customer_segments: e.target.value });
            }}
          />
        </>
      )}
      {finalInputs.geographies != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Geographies</div>
            <Button onClick={() => setFinalInputs({ ...finalInputs, geographies: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            placeholder="e.g. 'focus in Americas and Europe'"
            value={finalInputs.geographies}
            onChange={(e) => {
              setFinalInputs({ ...finalInputs, geographies: e.target.value });
            }}
          />
        </>
      )}
      {finalInputs.technologies != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Technologies</div>
            <Button onClick={() => setFinalInputs({ ...finalInputs, technologies: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            placeholder="e.g. 'generative AI', 'flow batteries', or 'real-time collaboration'"
            value={finalInputs.technologies}
            onChange={(e) => {
              setFinalInputs({ ...finalInputs, technologies: e.target.value });
            }}
          />
        </>
      )}

      {nonNullCount < 3 && (
        <div className="w-full">
          {finalInputs.business_model == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setFinalInputs({ ...finalInputs, business_model: "" });
              }}>
              <Plus className="mr-2" /> Business model
            </Button>
          )}
          {finalInputs.main_offerings == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setFinalInputs({ ...finalInputs, main_offerings: "" });
              }}>
              <Plus className="mr-2" /> Main offerings
            </Button>
          )}
          {finalInputs.customer_segments == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setFinalInputs({ ...finalInputs, customer_segments: "" });
              }}>
              <Plus className="mr-2" /> Customer segments
            </Button>
          )}
          {finalInputs.geographies == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setFinalInputs({ ...finalInputs, geographies: "" });
              }}>
              <Plus className="mr-2" /> Geographies
            </Button>
          )}
          {finalInputs.technologies == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setFinalInputs({ ...finalInputs, technologies: "" });
              }}>
              <Plus className="mr-2" /> Technologies
            </Button>
          )}
        </div>
      )}

      <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Example companies</div>
      {
        <div className="flex flex-row flex-wrap gap-2">
          {finalInputs.exemplar_company_domains?.map((business, businessIndex) => {
            return (
              <div key={businessIndex}>
                <ExemplarChip
                  domain={business}
                  onDelete={() =>
                    setFinalInputs({
                      ...finalInputs,
                      exemplar_company_domains: finalInputs.exemplar_company_domains?.filter((b) => b !== business)
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      }

      <Popover>
        <SimpleTooltip text="Add an example company by domain">
          <PopoverTrigger asChild>
            <Button variant="ghost" className="p-2">
              <Plus className="mr-2" /> Add example company
            </Button>
          </PopoverTrigger>
        </SimpleTooltip>
        <PopoverContent>
          <div className={`w-72 flex flex-row ${invalidDomain ? "animate__animated animate__headShake" : ""}`}>
            <Input
              placeholder="Company domain"
              value={exemplarInput}
              onChange={(e) => setExemplarInput(e.target.value)}
              className="w-40 mr-5"
            />
            <Button
              onClick={() => {
                if (!isValidUrl(exemplarInput)) {
                  setInvalidDomain(true);
                  toast.error("Not a valid company domain (website). Please enter a domain like 'google.com' or 'facebook.com'");
                  setTimeout(() => {
                    setInvalidDomain(false);
                  }, 1000);
                  return;
                }
                setFinalInputs({
                  ...finalInputs,
                  exemplar_company_domains: [...(finalInputs.exemplar_company_domains || []), exemplarInput]
                });
              }}>
              Add
            </Button>
          </div>
        </PopoverContent>
      </Popover>
      <Collapsible className="w-full">
        <CollapsibleTrigger asChild>
          <Button className="w-full my-2">
            <Funnel size={20} className="mr-2" />
            <span>Narrow your search with filters</span>
            <CaretDown size={20} className="ml-2" />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col gap-2 border-colors-brand-200 border p-2 rounded-lg">
            <Button onClick={() => setFinalInputs({ ...finalInputs, ...defaultScope })}>Use default scope</Button>
            <CompanyTypeFilterInput
              filter={finalInputs.company_type_filter}
              setFilter={(filter) => {
                setFinalInputs({ ...finalInputs, company_type_filter: filter });
              }}
            />
            <HqCountryFilterInput
              hqCountryCodes={finalInputs.hq_country_filter}
              setHqCountryCodes={(hqCountryCodes) => {
                setFinalInputs({ ...finalInputs, hq_country_filter: hqCountryCodes });
              }}
            />
            <HardMetricFilterInput
              metric={HardMetric.EmployeeCount}
              filter={employeeCountFilter}
              setFilter={(filter) => {
                setEmployeeCountFilter(filter);
              }}
            />
            <HardMetricFilterInput
              metric={HardMetric.FoundedYear}
              filter={foundedYearFilter}
              setFilter={(filter) => {
                setFoundedYearFilter(filter);
              }}
            />
            {/* <HardMetricFilterInput
              metric={HardMetric.NoFundraisingWindow}
              filter={noFundraisingFilter}
              setFilter={(filter) => {
                setNoFundraisingFilter(filter);
              }}
            /> */}
            <div className="flex flex-row flex-wrap gap-2">
              <DeprCompanyTypeFilterAddButton
                filter={finalInputs.company_type_filter}
                setFilter={(filter) => {
                  setFinalInputs({ ...finalInputs, company_type_filter: filter });
                }}
              />
              <HqCountryFilterAddButton
                hqCountryCodes={finalInputs.hq_country_filter}
                setHqCountryCodes={(hqCountryCodes) => {
                  setFinalInputs({ ...finalInputs, hq_country_filter: hqCountryCodes });
                }}
              />
              <DeprHardMetricFilterAddButton
                metric={HardMetric.EmployeeCount}
                filter={employeeCountFilter}
                setFilter={(filter) => {
                  setEmployeeCountFilter(filter);
                }}
              />
              <DeprHardMetricFilterAddButton
                metric={HardMetric.FoundedYear}
                filter={foundedYearFilter}
                setFilter={(filter) => {
                  setFoundedYearFilter(filter);
                }}
              />
              {/* <HardMetricFilterAddButton
                metric={HardMetric.NoFundraisingWindow}
                filter={noFundraisingFilter}
                setFilter={() => {
                  setNoFundraisingFilter({ hard_metric: HardMetric.NoFundraisingWindow, min: -3, max: 6 });
                }}
              /> // not supported until the date filter works */}
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </>
  );
};

export const FindCompaniesV2FindSimilarInputsEdit = ({
  initialInputs,
  setInitialInputs
}: {
  initialInputs: FindCompaniesV2InitialInputs;
  setInitialInputs: (inputs: FindCompaniesV2InitialInputs) => void;
}) => {
  const [employeeCountFilter, setEmployeeCountFilter] = useState<HardFilter>();
  const [foundedYearFilter, setFoundedYearFilter] = useState<HardFilter>();
  // const [noFundraisingFilter, _] = useState<HardFilter>();
  const defaultScope = useAppSelector((state) => state.user.deprDefaultScope);

  const updateFilters = () => {
    const filters = [employeeCountFilter, foundedYearFilter].filter((f) => f != null) as HardFilter[];
    setInitialInputs({
      ...initialInputs,
      filters: filters
    });
  };
  useEffect(() => {
    updateFilters();
  }, [employeeCountFilter, foundedYearFilter]);
  useEffect(() => {
    const employeeCountFilterFromInputs = initialInputs.filters?.find((filter) => filter.hard_metric === HardMetric.EmployeeCount);
    if (employeeCountFilterFromInputs) {
      setEmployeeCountFilter(employeeCountFilterFromInputs);
    }
    const foundedYearFilterFromInputs = initialInputs.filters?.find((filter) => filter.hard_metric === HardMetric.FoundedYear);
    if (foundedYearFilterFromInputs) {
      setFoundedYearFilter(foundedYearFilterFromInputs);
    }
  }, [initialInputs]);
  return (
    // this preserve-3d is a hack for the autocomplete dropdown to be positioned properly
    <div className="relative w-full" style={{ transformStyle: "preserve-3d" }}>
      <CompanyDomainAutocomplete
        onChange={(e) => {
          setInitialInputs({ ...initialInputs, exemplar_company_domains: [e] });
        }}
        onSubmit={({ value }) => {
          setInitialInputs({ ...initialInputs, exemplar_company_domains: [value] });
        }}
        placeholder="e.g. apple.com"
      />
      <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Number of companies to bring back</div>
      <div className="flex flex-row justify-between w-full">
        <div className="mr-3 font-semibold">{initialInputs.requested_number_of_companies ?? 40}</div>
        <Slider
          value={initialInputs.requested_number_of_companies ?? 40}
          max={100}
          min={20}
          step={20}
          onChange={(_, newValue) => {
            setInitialInputs({
              ...initialInputs,
              requested_number_of_companies: newValue as number
            });
          }}
          valueLabelDisplay="auto"
          sx={{
            "& .MuiSlider-thumb": {
              color: "#9f78ed"
            },
            "& .MuiSlider-track": {
              color: "#9f78ed"
            },
            "& .MuiSlider-rail": {
              color: "#9f78ed"
            }
          }}
        />
      </div>
      <Collapsible className="w-full">
        <CollapsibleTrigger asChild>
          <Button className="w-full my-2">
            <Funnel size={20} className="mr-2" />
            <span>Narrow your search with filters</span>
            <CaretDown size={20} className="ml-2" />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col gap-2 border-colors-brand-200 border p-2 rounded-lg">
            <Button onClick={() => setInitialInputs({ ...initialInputs, ...defaultScope })}>Use default scope</Button>
            {/* <DesiredCountriesFilterInput
              filter={initialInputs.company_type_filter}
              setFilter={(filter) => {
                setInitialInputs({ ...initialInputs, company_type_filter: filter });
              }}
            /> */}
            <CompanyTypeFilterInput
              filter={initialInputs.company_type_filter}
              setFilter={(filter) => {
                setInitialInputs({ ...initialInputs, company_type_filter: filter });
              }}
            />
            <HqCountryFilterInput
              hqCountryCodes={initialInputs.hq_country_filter}
              setHqCountryCodes={(filter) => {
                setInitialInputs({ ...initialInputs, hq_country_filter: filter });
              }}
            />
            <HardMetricFilterInput
              metric={HardMetric.EmployeeCount}
              filter={employeeCountFilter}
              setFilter={(filter) => {
                setEmployeeCountFilter(filter);
              }}
            />
            <HardMetricFilterInput
              metric={HardMetric.FoundedYear}
              filter={foundedYearFilter}
              setFilter={(filter) => {
                setFoundedYearFilter(filter);
              }}
            />
            {/* <HardMetricFilterInput
              metric={HardMetric.NoFundraisingWindow}
              filter={noFundraisingFilter}
              setFilter={(filter) => {
                setNoFundraisingFilter(filter);
              }}
            /> */}
            <div className="flex flex-row flex-wrap gap-2">
              <DeprCompanyTypeFilterAddButton
                filter={initialInputs.company_type_filter}
                setFilter={(filter) => {
                  setInitialInputs({ ...initialInputs, company_type_filter: filter });
                }}
              />
              <HqCountryFilterAddButton
                hqCountryCodes={initialInputs.hq_country_filter}
                setHqCountryCodes={(hqCountryCodes) => {
                  setInitialInputs({ ...initialInputs, hq_country_filter: hqCountryCodes });
                }}
              />
              <DeprHardMetricFilterAddButton
                metric={HardMetric.EmployeeCount}
                filter={employeeCountFilter}
                setFilter={(filter) => {
                  setEmployeeCountFilter(filter);
                }}
              />
              <DeprHardMetricFilterAddButton
                metric={HardMetric.FoundedYear}
                filter={foundedYearFilter}
                setFilter={(filter) => {
                  setFoundedYearFilter(filter);
                }}
              />
              {/* <HardMetricFilterAddButton
                metric={HardMetric.NoFundraisingWindow}
                filter={noFundraisingFilter}
                setFilter={() => {
                  setNoFundraisingFilter({ hard_metric: HardMetric.NoFundraisingWindow, min: -3, max: 6 });
                }}
              /> */}
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export const FindCompaniesV2WorkflowCreator = ({ workflow }: { workflow: GenericWorkflow }) => {
  const [missingInfo, setMissingInfo] = useState(false);
  const { getToken } = useAuth();
  const [errorExplanation, setErrorExplanation] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
  const stage = findCompaniesWorkflow.input_generation_state;
  const initialInputs =
    findCompaniesWorkflow.frontend_initial_inputs ??
    ({
      category_name: "",
      exemplar_company_domains: []
    } as FindCompaniesV2InitialInputs);
  const finalInputs = findCompaniesWorkflow.inputs;

  const setInitialInputsCallback = (inputs: FindCompaniesV2InitialInputs) => {
    dispatch(setInitialInputs({ workflowId: workflow.workflow_id, initialInputs: inputs }));
  };
  const setFinalInputsCallback = (inputs: FindCompaniesV2Inputs) => {
    dispatch(setFinalInputs({ workflowId: workflow.workflow_id, inputs: inputs }));
  };

  const setStage = (stage: FindCompaniesWorkflowV2InputGenerationStateEnum) => {
    dispatch(setInputGenerationState({ workflowId: workflow.workflow_id, inputGenerationState: stage }));
  };

  const onRun = async () => {
    if (stage === "initial") {
      if (initialInputs.category_name == null || initialInputs.category_name.split(" ").filter((word) => word.length > 0).length < 2) {
        setMissingInfo(true);
        toast.error("Category description (at least 2 words) is required");
        setTimeout(() => {
          setMissingInfo(false);
        }, 1000);
        return;
      }
      const token = await getToken();
      if (token) {
        dispatch(findCompaniesInitialInputsToInputsThunk({ token, workflowId: workflow.workflow_id }));
      } else {
        toast.error("Could not get user session. Try refreshing the page");
      }
    } else {
      if (
        (finalInputs.business_model === "" || finalInputs.business_model == null) &&
        (finalInputs.main_offerings === "" || finalInputs.main_offerings == null) &&
        (finalInputs.customer_segments === "" || finalInputs.customer_segments == null) &&
        (finalInputs.geographies === "" || finalInputs.geographies == null) &&
        (finalInputs.technologies === "" || finalInputs.technologies == null)
      ) {
        setMissingInfo(true);
        toast.error("At least one search criteria is required");
        setTimeout(() => {
          setMissingInfo(false);
        }, 1000);
        return;
      }
      const token = await getToken();

      if (token) {
        dispatch(runGenericWorkflowThunk({ token, workflow: workflow })).then((_) => {
          posthog.capture("user_ran_workflow", {
            type: WorkflowType.FindCompaniesV2,
            inputs: finalInputs,
            workflow_id: workflow.workflow_id
          });
          setStage("initial");
          setErrorExplanation(undefined);
          dispatch(fetchUserRunsAvailableThunk(token));
        });
      } else {
        toast.error("Could not get user session. Try refreshing the page");
      }
    }
  };

  if (stage === FindCompaniesWorkflowV2InputGenerationStateEnum.FindSimilar) {
    return <FindCompaniesV2FindSimilar workflow={workflow} />;
  }

  return (
    <div
      className={`flex flex-col bg-white border border-colors-border-border-secondary rounded-xl min-h-[302px] w-[500px] items-center ${
        missingInfo ? "animate__animated animate__headShake" : ""
      }`}>
      <div className="border-b border-b-colors-border-border-secondary w-full px-4 py-2">
        <div className="flex justify-between items-center w-full">
          <div className="font-semibold text-lg text-colors-text-text-primary-(900) ">
            Find companies
            <br />
            <span className="text-colors-text-text-secondary-(700) text-xs">
              {stage === "initial" ? "(Step 1 of 2)" : stage === "final" ? "(Step 2 of 2)" : ""}
            </span>
          </div>
          <div className="flex items-center">
            <SimpleTooltip text="Reset inputs">
              {stage === "final" && (
                <Button
                  className="rounded-lg border border-colors-gray-modern-400 px-4 py-2 mr-2 bg-colors-gray-modern-100 hover:bg-colors-gray-modern-300 text-xs"
                  // size="sm"
                  onClick={() => {
                    setStage("initial");
                    setErrorExplanation(undefined);
                    setInitialInputsCallback({
                      ...initialInputs,
                      category_name: ""
                    });
                  }}>
                  <RotateCcw size={16} className="text-colors-text-text-primary-(900)" />
                </Button>
              )}
            </SimpleTooltip>
            <Button
              className="rounded-lg border border-colors-brand-500 px-4 py-2 mr-2 bg-colors-brand-100 hover:bg-colors-brand-300"
              disabled={stage === "loading"}
              onClick={_.debounce(onRun, 400)}>
              <PaperPlaneRight size={22} className="mr-2 text-colors-text-text-primary-(900)" />
              <p className="text-colors-brand-800">{stage === "initial" ? "Next" : "Run"}</p>
            </Button>
          </div>
        </div>
        {/* <div className="text-sm text-colors-text-text-tertiary-(600)">Find companies that fit a description</div> */}
      </div>
      <div className="flex flex-col justify-around items-start flex-1 px-6 py-4 w-[500px]">
        <Collapsible className="w-full">
          <CollapsibleTrigger asChild>
            <div className="flex flex-row items-center cursor-pointer gap-2 bg-colors-blue-100 rounded-lg border-colors-blue-200 border px-2 mb-2 w-full justify-center">
              <WarningOctagon size={20} className="text-colors-blue-800 " />
              <span className="text-colors-text-text-secondary-(700)">Our search has limitations!</span>
              <CaretDown size={20} className="text-colors-blue-800" />
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="text-colors-text-text-secondary-(700) text-xs mt-2 border border-colors-blue-200 p-2 rounded-lg">
              We only support searches for tech companies right now. We can search over companies on the following attributes:
              <li>Products and services</li>
              <li>Business model</li>
              <li>Customer segments</li>
              <li>Geographic presence</li>
              <li>Technologies</li>
              <div className="mt-2 text-colors-success-700">
                Examples of good descriptions:
                <li>"AI-powered accounting firms in the UK"</li>
                <li>"Peer-to-peer furniture marketplaces in Europe"</li>
              </div>
              <div className="mt-2 text-colors-error-700">
                Examples of queries that aren't supported well:
                <li>Companies founded by MIT alumni</li>
                <li>YC startups in the most recent batch</li>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
        {errorExplanation && (
          <div className=" text-sm mt-2 border border-colors-error-500 bg-colors-error-200 text-black p-2 rounded-lg flex flex-row items-center">
            <AlertTriangle size={24} className="mr-2 text-colors-error-500" />
            <div className="w-full">
              We may not be able to find companies for that description. Here's why:
              <br />
              <span className="text-colors-error-900">{errorExplanation}</span>
              <br />
              You can still try to run the search anyway. This check is too aggressive sometimes.
            </div>
          </div>
        )}
        {stage === "initial" && (
          <FindCompaniesV2InitialInputsEdit initialInputs={initialInputs} setInitialInputs={setInitialInputsCallback} />
        )}
        {stage === "loading" && (
          <div className="flex flex-col justify-center items-center h-full self-center">
            <Spinner size={24} className="text-colors-brand-800 animate-spin" />
            {/* <RefreshCw size={24} className="mr-4 animate-spin" /> */}
            <div className="text-colors-text-text-secondary-(700) text-sm">
              Don't leave yet! Generating search criteria for your request. Takes ~30 seconds.
            </div>
          </div>
        )}
        {stage === "final" && <FindCompaniesV2FinalInputsEdit finalInputs={finalInputs} setFinalInputs={setFinalInputsCallback} />}
      </div>
    </div>
  );
};

export const FindCompaniesV2FindSimilar = ({ workflow }: { workflow: GenericWorkflow }) => {
  const [missingInfo, setMissingInfo] = useState(false);
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const findCompaniesWorkflow = workflow.workflow as FindCompaniesWorkflowV2;
  const stage = findCompaniesWorkflow.input_generation_state;
  const initialInputs =
    findCompaniesWorkflow.frontend_initial_inputs ??
    ({
      category_name: "",
      exemplar_company_domains: []
    } as FindCompaniesV2InitialInputs);
  const setInitialInputsCallback = (inputs: FindCompaniesV2InitialInputs) => {
    dispatch(setInitialInputs({ workflowId: workflow.workflow_id, initialInputs: inputs }));
  };

  const onRun = async () => {
    if (initialInputs.exemplar_company_domains == null || initialInputs.exemplar_company_domains.length < 1) {
      setMissingInfo(true);
      toast.error("A company is required");
      setTimeout(() => {
        setMissingInfo(false);
      }, 1000);
      return;
    }
    const token = await getToken();
    if (token) {
      dispatch(runGenericWorkflowThunk({ token, workflow: workflow })).then((_) => {
        posthog.capture("user_ran_workflow", {
          type: WorkflowType.FindCompaniesV2,
          find_companies_type: "find_similar",
          inputs: initialInputs,
          workflow_id: workflow.workflow_id
        });
      });
    } else {
      toast.error("Could not get user session. Try refreshing the page");
    }
  };

  return (
    <div
      className={`flex flex-col bg-white border border-colors-border-border-secondary rounded-xl min-h-[302px] w-[500px] items-center ${
        missingInfo ? "animate__animated animate__headShake" : ""
      }`}>
      <div className="border-b border-b-colors-border-border-secondary w-full px-4 py-2">
        <div className="flex justify-between items-center w-full">
          <div className="font-semibold text-lg text-colors-text-text-primary-(900) ">
            Find similar companies
            <br />
          </div>
          <div className="flex items-center">
            <Button
              className="rounded-lg border border-colors-brand-500 px-4 py-2 mr-2 bg-colors-brand-100 hover:bg-colors-brand-300"
              disabled={stage === "loading"}
              onClick={_.debounce(onRun, 400)}>
              <PaperPlaneRight size={22} className="mr-2 text-colors-text-text-primary-(900)" />
              <p className="text-colors-brand-800">{stage === "initial" ? "Next" : "Run"}</p>
            </Button>
          </div>
        </div>
        {/* <div className="text-sm text-colors-text-text-tertiary-(600)">Find companies that fit a description</div> */}
      </div>
      <div className="flex flex-col justify-around items-start flex-1 px-6 py-4 w-[500px]">
        <FindCompaniesV2FindSimilarInputsEdit initialInputs={initialInputs} setInitialInputs={setInitialInputsCallback} />
      </div>
    </div>
  );
};
