/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from "react";
import { ArrowCounterClockwise, NotePencil } from "@phosphor-icons/react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  CompLandscapeInputs,
  FindCompaniesV2Inputs,
  MarketMapInputs,
  SourceCompaniesInputs,
  TaskStatus,
  WorkflowEffort,
  WorkflowType
} from "@/services/autogen";
import { AlertTriangle, Check, LogIn, Share, Timer } from "lucide-react";
import { Progress } from "../ui/progress";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { setActiveWorkflowId } from "@/store/genericWorkflows";
import { truncateText } from "@/lib/utils";
import { TimeFrame } from "@/lib/constants";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { demoFeaturesOn } from "@/utils/config";

const WorkflowStatusInfo = ({ status, runStartTime, effort }: { status?: TaskStatus; runStartTime?: Date; effort?: WorkflowEffort }) => {
  switch (status) {
    case "running":
      if (runStartTime === undefined) {
        return (
          <div className="flex justify-between items-center w-auto border-colors-border-border-primary border-0 h-auto bg-colors-background-bg-brand-primary mr-2 p-2 rounded-lg">
            <ArrowCounterClockwise className="mr-2" size={18} />
            <div className="text-sm">Running</div>
          </div>
        );
      } else {
        return <TimedProgressBar startTime={runStartTime} totalSeconds={1200} />;
      }
    case "input_modified":
    case "output_modified":
      return (
        <div className="flex justify-between items-center w-auto border-colors-foreground-fg-warning-primary border-0 h-auto bg-colors-background-bg-warning-primary mr-2 p-2 rounded-lg">
          <NotePencil className="mr-2" size={18} />
          <div className="text-sm">Changes made</div>
        </div>
      );
    case "completed":
      if (effort) {
        return <EffortLevel effort={effort} />;
      } else {
        return (
          <div className="flex justify-between items-center w-auto border-colors-foreground-fg-success-primary border-0 h-auto bg-colors-background-bg-success-primary mr-2 p-2 rounded-lg">
            <Check className="mr-2" size={18} />
            <div className="text-sm">Complete</div>
          </div>
        );
      }

    case "incomplete":
      return (
        <div className="flex justify-between items-center w-auto border-colors-foreground-fg-error-primary border-0 h-auto bg-colors-background-bg-error-primary mr-2 p-2 rounded-lg">
          <AlertTriangle className="mr-2" size={18} />
          <div className="text-sm">Incomplete</div>
        </div>
      );
    default:
      return null;
  }
};

const TimedProgressBar = ({ startTime, totalSeconds }: { startTime: Date; totalSeconds: number }) => {
  const [progress, setProgress] = useState(0);
  // calculate progress based on current time, totalSeconds, and startTime and set progress every 100ms
  useEffect(() => {
    setProgress(0);
    const updateInterval = 100; // ms

    const interval = setInterval(() => {
      setProgress(() => {
        const now = Date.now();
        const newProgress = ((now - startTime.getTime()) / (totalSeconds * 1000)) * 100;
        if (newProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newProgress;
      });
    }, updateInterval);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Progress value={progress} />;
};

const EffortLevel = ({ effort }: { effort: WorkflowEffort }) => {
  return (
    <div className="flex flex-row gap-2 rounded-lg bg-colors-brand-100 items-center px-4 text-colors-text-text-quarterary-(500) text-sm w-full h-full justify-between">
      <div>
        <span className="font-bold text-colors-text-text-tertiary-(600)">{effort.number_of_articles_read}</span> articles read.
      </div>
      <div>
        <span className="font-bold text-colors-text-text-tertiary-(600)">{effort.number_of_companies_researched}</span> companies
        researched.
      </div>
      <div>
        <span className="font-bold text-colors-text-text-tertiary-(600)">{effort.number_of_llm_calls}</span> LLM calls.
      </div>
    </div>
  );
};

function getLinkToShare(workflow_id: string) {
  const answer = "https://meticulate.ai/workflow/" + workflow_id;
  navigator.clipboard.writeText(answer);
  toast.success("Copied shareable link to clipboard!");
}

function HeaderText({
  workflowType,
  workflowInputs,
  workflowTitle
}: {
  workflowType: WorkflowType;
  workflowInputs: FindCompaniesV2Inputs | CompLandscapeInputs | MarketMapInputs | SourceCompaniesInputs;
  workflowTitle: string;
}) {
  let typedInputs;
  switch (workflowType) {
    case WorkflowType.MarketMap:
      return (
        <span>
          <span className="text-colors-brand-600 font-semibold ">Market map</span>
        </span>
      );
    case WorkflowType.FindCompaniesV2:
      typedInputs = workflowInputs as FindCompaniesV2Inputs;
      return (
        <span>
          <span className="text-colors-brand-600 font-semibold ">{truncateText(typedInputs.category_name || "", 80)}</span>
        </span>
      );
    case WorkflowType.CompLandscapeV7:
      typedInputs = workflowInputs as CompLandscapeInputs;
      return (
        <span>
          Competitive landscape for <span className="text-colors-brand-600 font-semibold ">{workflowTitle}</span>
        </span>
      );
    case WorkflowType.MarketMapV2:
      typedInputs = workflowInputs as MarketMapInputs;
      return (
        <span>
          Market map for <span className="text-colors-brand-600 font-semibold ">{workflowTitle}</span>
        </span>
      );
    case WorkflowType.SourceCompanies:
      typedInputs = workflowInputs as SourceCompaniesInputs;
      return (
        <span>
          <span className="text-colors-brand-600 font-semibold ">{workflowTitle}</span>
        </span>
      );
  }
}

const MeticulateLogoRedirect = () => {
  const dispatch = useAppDispatch();
  return (
    <img
      src="/MeticulateIconSquare.svg"
      className="h-6 absolute top-4 left-4 cursor-pointer"
      onClick={async () => {
        if (window.location.href.includes("meticulate.ai/app")) {
          dispatch(setActiveWorkflowId(undefined));
        } else {
          window.location.href = "https://meticulate.ai";
        }
      }}
    />
  );
};

const Header = ({ showLogo, hideStatus, runYourOwnButton }: { showLogo?: boolean; hideStatus?: boolean; runYourOwnButton?: boolean }) => {
  const activeWorkflow = useAppSelector((state) =>
    state.genericWorkflows.workflows.find((w) => w.workflow_id === state.genericWorkflows.activeWorkflowId)
  );

  const runStartTime = activeWorkflow?.start_timestamp_seconds ? new Date(activeWorkflow.start_timestamp_seconds * 1000) : undefined;

  const [randomTimeFrame, setRandomTimeFrame] = useState<TimeFrame>(() => {
    const timeFrames = Object.values(TimeFrame);
    return timeFrames[Math.floor(Math.random() * timeFrames.length)];
  });
  if (activeWorkflow != null) {
    return (
      <div
        className={`relative px-10 py-2 inline-flex justify-between items-center bg-white border-b border-colors-border-border-secondary h-16 z-40 w-full`}>
        {showLogo && <MeticulateLogoRedirect />}
        <div className="text-sm md:text-2xl font-light ">
          <HeaderText
            workflowType={activeWorkflow.workflow_type}
            workflowInputs={
              // TODO: this is a hack while we migrate to starship. THis patterns should not be repeated.
              activeWorkflow.workflow.inputs as FindCompaniesV2Inputs | CompLandscapeInputs | MarketMapInputs | SourceCompaniesInputs
            }
            workflowTitle={activeWorkflow.title}
          />
        </div>
        {(hideStatus == null || hideStatus === false) && (
          <div className="flex w-[40%] items-center justify-center md:flex h-full">
            <WorkflowStatusInfo status={activeWorkflow.status} runStartTime={runStartTime} />
          </div>
        )}
        <div className="space-x-3 flex flex-row items-center">
          {demoFeaturesOn && activeWorkflow.workflow_type === WorkflowType.SourceCompanies && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="default">
                  <Timer size={16} className="mr-2" />
                  {randomTimeFrame}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <RadioGroup
                  value={randomTimeFrame}
                  onValueChange={(value) => setRandomTimeFrame(value as TimeFrame)}
                  className="text-black">
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value={TimeFrame.OneWeek} />
                    <label htmlFor="r1">{TimeFrame.OneWeek}</label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value={TimeFrame.TwoWeeks} />
                    <label htmlFor="r2">{TimeFrame.TwoWeeks}</label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value={TimeFrame.OneMonth} />
                    <label htmlFor="r3">{TimeFrame.OneMonth}</label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value={TimeFrame.Quarter} />
                    <label htmlFor="r4">{TimeFrame.Quarter}</label>
                  </div>
                </RadioGroup>
              </PopoverContent>
            </Popover>
          )}
          {activeWorkflow.workflow.outputs != null && (
            <Button variant="outline" onClick={() => getLinkToShare(activeWorkflow.workflow_id)}>
              <Share size={20} className="mr-2" />
              Share this
            </Button>
          )}
          {runYourOwnButton && (
            <Button onClick={() => window.open("https://meticulate.ai")}>
              <LogIn size={20} className="mr-2" />
              Run your own
            </Button>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Header;
