import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Button } from "../ui/button";
import { useState, useCallback } from "react";
import { useAuth } from "@clerk/clerk-react";
import { CloudUpload } from "lucide-react";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { toast } from "sonner";
import { Combobox } from "../ui/combobox";
import { posthog } from "posthog-js";
import { pushCompaniesToCrm } from "@/services/brain-api.service";

export const PushToCrmDialog = ({ domainsSelected, disabled }: { domainsSelected: string[]; disabled?: boolean }) => {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const companyCreditsAvailable = useAppSelector((state) => state.user.companyCreditsAvailable);
  const crmOwners = useAppSelector((state) => state.user.crmOwners);
  const [selectedOwnerId, setSelectedOwnerId] = useState<string | undefined>(undefined);

  const handlePushToCrm = useCallback(async () => {
    if (domainsSelected.length == 0) {
      toast.error("Please select at least one company");
      return;
    }

    const token = await getToken();
    if (token != null) {
      posthog.capture("user_pushed_companies_to_crm", {
        company_domains: domainsSelected,
        owner_id: selectedOwnerId
      });
      await pushCompaniesToCrm(token, domainsSelected, selectedOwnerId);
    }
  }, [domainsSelected, getToken, dispatch]);

  return (
    <Dialog>
      {domainsSelected.length > 0 && (
        <DialogTrigger asChild>
          <Button size="sm" disabled={disabled || (companyCreditsAvailable != null && companyCreditsAvailable == 0)}>
            <CloudUpload size={20} /> Push to CRM
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className="w-80">
        <DialogTitle>Push to CRM</DialogTitle>
        <Combobox
          placeholderText="Owner (optional)"
          options={crmOwners.map((owner) => ({ label: owner.name, value: owner.id }))}
          value={selectedOwnerId}
          setValue={setSelectedOwnerId}
          disableSearch={true}
          // disabling search because values are ids and labels are names but you'd want to search by name
        />
        <div className="flex flex-col gap-2">
          Push {domainsSelected.length} companies to CRM?{" "}
          <span className="text-xs text-gray-500">(Only new records will be created. Existing records will not be updated)</span>
        </div>
        <div className="flex flex-row gap-2 w-full">
          <DialogClose asChild>
            <Button className="w-1/2" variant="outline">
              No
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={handlePushToCrm} className="w-1/2">
              Yes
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
