import { HardMetric, HardFilter, FrontendSearchInstructions } from "@/services/autogen";

import { CompanyTypeFilterInput } from "@/components/workflow-inputs/CompanyType";
import { HqCountryFilterInput } from "@/components/workflow-inputs/HqCountry";
import { HardMetricFilterInput } from "@/components/workflow-inputs/HardFilters";
import { HardMetricFilterInputToggleGroup } from "@/components/workflow-inputs/DerivativeMetricPreference";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { useState } from "react";
import { demoFeaturesOn } from "@/utils/config";
import { CompanyTagFilterInput } from "@/components/workflow-inputs/CompanyTags";

export const CompanySearchQualificationBox = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendSearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  // const dispatch = useAppDispatch();
  // const token = useAppSelector((state) => state.user.token);
  // const defaultParameters = useAppSelector((state) => state.user.defaultCompanySearchParameters);
  return (
    <div className="flex flex-col gap-2 border-colors-blue-200 borde-0 p-0 rounded-lg w-[400px] h-auto group">
      <Collapsible className="w-full" open={isOpen} onOpenChange={setIsOpen}>
        {/* <div className="text-lg font-medium flex flex-row items-center gap-2 w-full justify-between">
          <div className="flex flex-row items-center gap-2 w-full">
            <Funnel size={24} />
            <span>Qualification criteria</span>
          </div>
          <div className="flex flex-row items-center gap-0 w-[100px]">
            {!viewOnly && (
              <>
                {defaultParameters != null && (
                  <SimpleTooltip text="Import default parameters">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        setCompanySearchInstructions({
                          ...companySearchInstructions,
                          parameters: defaultParameters
                        });
                      }}>
                      <Import size={12} />
                    </Button>
                  </SimpleTooltip>
                )}
                <SimpleTooltip text="Save these parameters as a default">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      if (token == null) return;
                      dispatch(
                        setUserDefaultCompanySearchParametersThunk({
                          token: token,
                          parameters: companySearchInstructions.parameters
                        })
                      );
                      toast.success("Saved parameters as default");
                    }}>
                    <Save size={12} />
                  </Button>
                </SimpleTooltip>
              </>
            )}
          </div>
          <CollapsibleTrigger asChild>
            <Button variant="ghost" size="sm">
              {isOpen ? <CaretUp size={20} className="ml-2" /> : <CaretDown size={20} className="ml-2" />}
            </Button>
          </CollapsibleTrigger>
        </div> */}

        <CollapsibleContent>
          <CompanySearchParametersEditor
            searchInstructions={companySearchInstructions}
            setSearchInstructions={setCompanySearchInstructions}
            viewOnly={viewOnly}
          />
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export const CompanySearchParametersEditor = ({
  searchInstructions,
  setSearchInstructions,
  viewOnly
}: {
  searchInstructions: FrontendSearchInstructions;
  setSearchInstructions: (searchInstructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  const handleNumericalFilterUpdate = (filterType: HardMetric, filter?: HardFilter) => {
    if (filter == null) {
      setSearchInstructions({
        ...searchInstructions,
        numerical_filters: searchInstructions.numerical_filters?.filter((f) => f.hard_metric !== filterType)
      });
    } else {
      const existingFilters = searchInstructions.numerical_filters || [];
      const updatedFilters = existingFilters.filter((f) => f.hard_metric !== filterType);
      setSearchInstructions({
        ...searchInstructions,
        numerical_filters: [...updatedFilters, filter]
      });
    }
  };
  return (
    <div className="flex flex-col gap-3 p-2 rounded-lg">
      <CompanyTypeFilterInput
        filter={searchInstructions.company_type_filter}
        setFilter={(filter) => {
          setSearchInstructions({
            ...searchInstructions,
            company_type_filter: filter
          });
        }}
        viewOnly={viewOnly}
      />
      <CompanyTagFilterInput
        filter_include={searchInstructions.company_tags_filter}
        filter_exclude={searchInstructions.company_tags_filter_exclude}
        setFilter={(filter_include, filter_exclude) => {
          setSearchInstructions({
            ...searchInstructions,
            company_tags_filter: filter_include,
            company_tags_filter_exclude: filter_exclude
          });
        }}
        viewOnly={viewOnly}
      />
      <HqCountryFilterInput
        hqCountryCodes={searchInstructions.hq_country_filter}
        setHqCountryCodes={(hqCountryCodes) => {
          setSearchInstructions({
            ...searchInstructions,
            hq_country_filter: hqCountryCodes
          });
        }}
        viewOnly={viewOnly}
      />
      <HardMetricFilterInput
        metric={HardMetric.EmployeeCount}
        filter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.EmployeeCount)}
        setFilter={(filter) => {
          handleNumericalFilterUpdate(HardMetric.EmployeeCount, filter);
        }}
        viewOnly={viewOnly}
      />
      {demoFeaturesOn && (
        <HardMetricFilterInput
          metric={HardMetric.Revenue}
          filter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.Revenue)}
          setFilter={(filter) => {
            handleNumericalFilterUpdate(HardMetric.Revenue, filter);
          }}
          viewOnly={viewOnly}
        />
      )}
      <HardMetricFilterInput
        metric={HardMetric.FoundedYear}
        filter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.FoundedYear)}
        setFilter={(filter) => {
          handleNumericalFilterUpdate(HardMetric.FoundedYear, filter);
        }}
        viewOnly={viewOnly}
      />
      <HardMetricFilterInput
        metric={HardMetric.LastFundraiseDate}
        filter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.LastFundraiseDate)}
        setFilter={(filter) => {
          handleNumericalFilterUpdate(HardMetric.LastFundraiseDate, filter);
        }}
        nonesAllowedOptionVisible={true}
        nonesAllowedCheckboxText="Include companies with no known fundraise"
        viewOnly={viewOnly}
      />
      <HardMetricFilterInputToggleGroup
        metric={HardMetric.HypeScore}
        hardFilter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.HypeScore)}
        setHardFilter={(hardFilter) => {
          handleNumericalFilterUpdate(HardMetric.HypeScore, hardFilter);
        }}
        text="Hype level"
        viewOnly={viewOnly}
      />
      <HardMetricFilterInputToggleGroup
        metric={HardMetric.GrowthScore}
        hardFilter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.GrowthScore)}
        setHardFilter={(hardFilter) => {
          handleNumericalFilterUpdate(HardMetric.GrowthScore, hardFilter);
        }}
        text="Growth level"
        viewOnly={viewOnly}
        lowText="Shrinking"
        mediumText="Some growth"
        highText="Fast growth"
      />
      <HardMetricFilterInputToggleGroup
        metric={HardMetric.ProductScore}
        hardFilter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.ProductScore)}
        setHardFilter={(hardFilter) => {
          handleNumericalFilterUpdate(HardMetric.ProductScore, hardFilter);
        }}
        text="Product vs services"
        viewOnly={viewOnly}
        lowText="More services"
        mediumText="Mixed"
        highText="More products"
      />
      {demoFeaturesOn && (
        <HardMetricFilterInputToggleGroup
          metric={HardMetric.TechScore}
          hardFilter={searchInstructions.numerical_filters?.find((f) => f.hard_metric === HardMetric.TechScore)}
          setHardFilter={(hardFilter) => {
            handleNumericalFilterUpdate(HardMetric.TechScore, hardFilter);
          }}
          text="DEMO: Tech sophistication level"
          viewOnly={viewOnly}
        />
      )}
    </div>
  );
};
