import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { ScoredCompaniesRequest } from "@/services/autogen";
import { ScoredCompaniesListTable } from "./ScoredCompaniesListTable";
import { Button } from "../ui/button";
import { useState, useEffect } from "react";
import { EyeSlash } from "@phosphor-icons/react";
import { Eye } from "lucide-react";
import { useAuth } from "@clerk/clerk-react";
import { fetchRubricThunk, fetchScoredCompaniesThunk } from "@/store/rubricScoring";
import { Input } from "../ui/input";

export const RubricScoringViewer = ({ onOpenRightSection }: { onOpenRightSection?: () => void }) => {
  // const ingestedCompanies = useAppSelector((state) => state.rubricScoring.ingestedCompanies);
  const rubric = useAppSelector((state) => state.rubricScoring.rubric);
  const scoredCompanies = useAppSelector((state) => state.rubricScoring.scoredCompanies);
  const [rubricOpen, setRubricOpen] = useState(false);
  const [request, setRequest] = useState<ScoredCompaniesRequest>({
    // TODO: shoudl not need this placeholder
    rubric: rubric ?? { rubric_id: "placeholder", dimensions: [] }
  });

  const { getToken } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getToken().then((token) => {
      if (token) {
        dispatch(fetchRubricThunk({ token }));
        dispatch(fetchScoredCompaniesThunk({ token, request }));
      } else {
        console.error("No token found");
      }
    });
  }, []);
  useEffect(() => {
    onOpenRightSection && onOpenRightSection();
  }, []);
  return (
    <div className="flex flex-col w-full h-full space-y-4">
      <div className="flex flex-row w-full space-x-4 items-center">
        <div className="text-2xl font-semibold text-colors-brand-8000">Rubric scoring</div>
        <div className="flex flex-row h-full">
          <Button
            variant="outline"
            onClick={() => {
              setRubricOpen(!rubricOpen);
            }}>
            {rubricOpen ? <EyeSlash size={20} className="mr-2" /> : <Eye size={20} className="mr-2" />}
            {rubricOpen ? "Hide rubric" : "Show rubric"}
          </Button>
        </div>
      </div>
      <div>
        {rubricOpen && (
          <div className="text-colors-brand-8000">
            <table className="rounded-lg overflow-hidden text-xs">
              <thead>
                <tr className="bg-colors-brand-700 text-colors-text-text-white">
                  <th className="px-4 py-2 text-left">Dimension</th>
                  <th className="px-4 py-2 text-left">Weight</th>
                  <th className="px-4 py-2 text-left">Background</th>
                  <th className="px-4 py-2 text-left">Positive guidance</th>
                  <th className="px-4 py-2 text-left">Negative guidance</th>
                </tr>
              </thead>
              <tbody>
                {rubric?.dimensions.map((dimension, index) => (
                  <tr key={index} className="border-t border-gray-300 bg-colors-brand-50">
                    <td className="px-4 py-2">
                      {dimension.type.charAt(0).toUpperCase() + dimension.type.slice(1).toLowerCase().replace(/_/g, " ")}
                    </td>
                    <td className="px-4 py-2">{dimension.weight}</td>
                    <td className="px-4 py-2">{dimension.guidance?.background}</td>
                    <td className="px-4 py-2">{dimension.guidance?.positive_guidance}</td>
                    <td className="px-4 py-2">{dimension.guidance?.negative_guidance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div>
        <div className="flex flex-row space-x-4 mb-4 items-end">
          <div className="flex flex-col">
            <label htmlFor="individualFilter" className="text-sm font-medium text-colors-brand-8000 mb-1">
              Individual Filter
            </label>
            <Input
              id="individualFilter"
              type="text"
              placeholder="Enter individual name"
              value={request.individual_filter || ""}
              onChange={(e) => {
                const newRequest = { ...request, individual_filter: e.target.value };
                setRequest(newRequest);
                // dispatch(fetchScoredCompaniesThunk({ token: getToken(), request: newRequest }));
              }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="podFilter" className="text-sm font-medium text-colors-brand-8000 mb-1">
              Pod Filter
            </label>
            <Input
              id="podFilter"
              type="text"
              placeholder="Enter pod name"
              value={request.pod_filter || ""}
              onChange={(e) => {
                const newRequest = { ...request, pod_filter: e.target.value };
                setRequest(newRequest);
                // dispatch(fetchScoredCompaniesThunk({ token: getToken(), request: newRequest }));
              }}
            />
          </div>
          <Button
            onClick={async () => {
              const token = await getToken();
              if (token) {
                dispatch(fetchScoredCompaniesThunk({ token, request }));
              } else {
                console.error("No token found");
              }
            }}>
            {" "}
            Fetch companies{" "}
          </Button>
        </div>
      </div>
      {rubric && (
        <div className="flex flex-row w-full h-full">
          <div className="overflow-y-scroll pr-10">
            <ScoredCompaniesListTable rubric={rubric} scoredCompanies={scoredCompanies} />
          </div>
        </div>
      )}
    </div>
  );
};
