/* eslint-disable @typescript-eslint/no-empty-function */
import { CompanyList } from "@/services/autogen";
import { useLoaderData } from "react-router-dom";
import { useAppDispatch } from "@/store/hooks";

import { useEffect } from "react";
import { Toaster } from "sonner";
import { CompanyListViewer, CompanyListHeader } from "./CompanyListViewer";
import { loadCompanyList, setActiveCompanyList } from "@/store/companyLists";

export function ShareableCompanyListViewer() {
  const dispatch = useAppDispatch();

  const companyList = useLoaderData() as CompanyList | null;
  useEffect(() => {
    if (companyList) {
      dispatch(loadCompanyList({ companyList }));
      dispatch(setActiveCompanyList({ companyListId: companyList.id }));
    }
  }, [companyList]);
  if (companyList == null) {
    return <div>404</div>;
  }

  return (
    <div className="flex app-background">
      <div className="flex flex-col w-full h-screen">
        {/*  TODO: show Logo and runYourOwnButton */}
        <div className="py-1">
          <CompanyListHeader shareableHeader />
        </div>
        <div className="flex flex-col flex-grow w-full p-10 overflow-hidden">
          <CompanyListViewer viewOnly />
        </div>
      </div>
      <Toaster richColors position="top-center" />
    </div>
  );
}
