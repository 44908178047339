import { WebTrafficSnapshot } from "@/services/autogen";

export const WebTrafficDisplay = ({ webTraffic }: { webTraffic?: WebTrafficSnapshot }) => {
  const visitsLastMonth = webTraffic?.visits_last_month;
  const visitGrowthMonthly = webTraffic?.visit_growth_monthly;
  const formattedVisits = visitsLastMonth
    ? visitsLastMonth >= 1000000
      ? (visitsLastMonth / 1000000).toFixed(1) + "M"
      : (visitsLastMonth / 1000).toFixed(0) + "k"
    : visitsLastMonth;
  const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
  const formattedGrowth =
    visitGrowthMonthly !== undefined ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%` : "";

  return (
    <div className="flex flex-row gap-2">
      <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{formattedVisits}</span>
      {webTraffic?.visit_growth_monthly && <span style={{ color: growthColor }}>{formattedGrowth}</span>}
    </div>
  );
};

export const WebTrafficFull = ({ webTraffic }: { webTraffic?: WebTrafficSnapshot }) => {
  const visitsLastMonth = webTraffic?.visits_last_month;
  const visitGrowthMonthly = webTraffic?.visit_growth_monthly;
  const formattedVisits = visitsLastMonth
    ? visitsLastMonth >= 1000000
      ? (visitsLastMonth / 1000000).toFixed(1) + "M"
      : (visitsLastMonth / 1000).toFixed(0) + "k"
    : visitsLastMonth;
  const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
  const formattedGrowth =
    visitGrowthMonthly !== undefined ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%` : "";

  return (
    <div className="flex flex-col gap-1 text-sm font-light">
      <div className="flex flex-row gap-2">
        <div className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">Monthly visits: </div>
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{formattedVisits}</span>
      </div>
      {webTraffic?.visit_growth_monthly && (
        <div className="flex flex-row gap-2">
          <div className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">Monthly growth: </div>
          <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
        </div>
      )}
    </div>
  );
};
