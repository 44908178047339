import { TearsheetEnrichmentType } from "@/services/autogen";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { getEnrichmentsAdminInfoThunk } from "@/store/user";
import { useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";
import { CompanyLogo } from "../ui/avatar";

function EnrichmentTypeIcon({ type }: { type: TearsheetEnrichmentType }) {
  if (type === TearsheetEnrichmentType.Pitchbook) {
    return <img src="/pitchbook_logo.png" className="w-24"></img>;
  }
}

export function EnrichmentsAdminView() {
  const enrichmentsAdminInfo = useAppSelector((state) => state.user.enrichmentsAdminInfo);
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getToken().then((token) => {
      if (token) {
        dispatch(getEnrichmentsAdminInfoThunk(token));
      }
    });
  }, [dispatch]);

  if (enrichmentsAdminInfo == null) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="text-xl font-semibold text-colors-brand-800 ">Enrichments Admin</div>
      {/* <div className="text-sm text-gray-500">{JSON.stringify(enrichmentsAdminInfo)}</div> */}
      <div className="flex flex-row items-start gap-10">
        <div className="flex flex-col gap-2 border bg-white p-4">
          {/* <div className="text-lg font-bold">Enrichments by Type</div> */}
          <table>
            <thead>
              <tr className="text-left text-colors-gray-modern-500">
                <th className="pr-10 font-semibold">Type</th>
                <th className="font-semibold">Count</th>
              </tr>
            </thead>
            <tbody>
              {enrichmentsAdminInfo.enrichment_counts_by_type.map((count, index) => (
                <tr key={index}>
                  <td className="pr-10">
                    <EnrichmentTypeIcon type={count.enrichment_type as TearsheetEnrichmentType} />
                  </td>
                  <td>{count.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col gap-2 border bg-white p-4">
          {/* <div className="text-lg font-bold">Enrichments by User</div> */}
          <table>
            <thead>
              <tr className="text-left text-colors-gray-modern-500">
                <th className="pr-10 font-semibold">User</th>
                <th className="font-semibold">Count</th>
              </tr>
            </thead>
            <tbody>
              {enrichmentsAdminInfo.enrichment_counts_by_user.map((count, index) => (
                <tr key={index}>
                  <td className="pr-10">{count.user_id}</td>
                  <td>{count.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col gap-2 border bg-white p-4">
          <div className="text-lg font-bold">Latest Enrichments</div>
          {enrichmentsAdminInfo.last_pulled_enrichments.map((enrichment, index) => (
            <div key={index} className="text-colors-gray-modern-500 flex flex-row gap-4 items-center">
              <div className="flex flex-col text-xs">
                <EnrichmentTypeIcon type={enrichment.enrichment_type as TearsheetEnrichmentType} />
                <div className="text-xs">{new Date(enrichment.enrichment_value.pull_timestamp * 1000).toLocaleString().split(",")[0]}</div>
              </div>

              <div className="flex flex-row gap-1 items-center">
                <CompanyLogo name={enrichment.company_domain} website={enrichment.company_domain} className="w-6 h-6" />
                <div className="flex flex-col text-base">{enrichment.company_domain} </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
