import { useEffect } from "react";
import { FilterHeader } from "@/components/workflow-inputs/FilterHeader";
import { SEMANTIC_FIELD_TYPE_DISPLAY_TEXT, SEMANTIC_FIELD_TYPE_PLACEHOLDER_TEXT } from "@/lib/constants";
import { FrontendSearchInstructions, SemanticFieldType } from "@/services/autogen";
import { SemanticFieldSearchQuery } from "@/services/autogen";
import { useState } from "react";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Blend, Plus, Trash2 } from "lucide-react";
import { Switch } from "@/components/ui/switch";

const CompanySearchSemanticQueryEditor = ({
  fieldType,
  semanticQuery,
  setSemanticQuery,
  disableAdd,
  viewOnly
}: {
  fieldType: SemanticFieldType;
  semanticQuery?: SemanticFieldSearchQuery;
  setSemanticQuery: (query?: SemanticFieldSearchQuery) => void;
  disableAdd?: boolean;
  viewOnly?: boolean;
}) => {
  const [localQuery, setLocalQuery] = useState(semanticQuery?.query ?? "");
  useEffect(() => {
    setLocalQuery(semanticQuery?.query ?? "");
  }, [semanticQuery]);
  return (
    <>
      <FilterHeader
        title={SEMANTIC_FIELD_TYPE_DISPLAY_TEXT[fieldType]}
        onDelete={() => {
          setSemanticQuery(undefined);
        }}
        onAdd={() => {
          setSemanticQuery({ field: fieldType, query: "" });
        }}
        isActive={semanticQuery != null}
        viewOnly={viewOnly || fieldType === SemanticFieldType.BusinessModel}
        disableAdd={disableAdd}
      />
      {semanticQuery != null && (
        <AutosizedTextarea
          // className="border border-black"
          // TODO: placeholder map for each field type
          placeholder={SEMANTIC_FIELD_TYPE_PLACEHOLDER_TEXT[fieldType]}
          value={localQuery}
          disabled={viewOnly}
          onChange={(e) => {
            setLocalQuery(e.target.value);
          }}
          onBlur={() => {
            setSemanticQuery({
              field: fieldType,
              query: localQuery
            });
          }}
        />
      )}
    </>
  );
};

export const OtherSearchCriteriaBox = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendSearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2 border-colors-green-200 border-0 p-2 rounded-lg w-[400px]">
      {/* <div className="text-lg font-medium flex flex-row items-center gap-2 w-full justify-between">
        <div className="flex flex-row items-center gap-2">
          <Ranking size={24} />
          <span>Scoring</span>
        </div>
      </div> */}
      <FilterHeader
        title="Tech stack"
        infoText="Describe technologies the companies should have"
        viewOnly={viewOnly}
        onDelete={() => {
          setCompanySearchInstructions({
            ...companySearchInstructions,
            tech_detections: undefined
          });
        }}
        onAdd={() => {
          setCompanySearchInstructions({
            ...companySearchInstructions,
            tech_detections: {
              high_standard: "",
              is_required: false,
              weight: 4
            }
          });
        }}
        isActive={companySearchInstructions.tech_detections != null}
      />
      {companySearchInstructions.tech_detections != null && (
        <>
          <AutosizedTextarea
            value={companySearchInstructions.tech_detections.high_standard ?? ""}
            disabled={viewOnly}
            placeholder="e.g. 'data warehouse', 'Google analytics', or 'Webflow' "
            onChange={(e) => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                tech_detections: { ...companySearchInstructions.tech_detections!, high_standard: e.target.value }
              });
            }}
          />
          <div className="flex flex-row items-center gap-2">
            <span>Preferred</span>
            <Switch
              checked={companySearchInstructions.tech_detections.is_required}
              onCheckedChange={(e) => {
                setCompanySearchInstructions({
                  ...companySearchInstructions,
                  tech_detections: { ...companySearchInstructions.tech_detections!, is_required: e }
                });
              }}
            />
            <span>Required</span>
          </div>
        </>
      )}
      <FilterHeader
        title="Employee roles"
        infoText="Describe the employee roles the companies should have"
        viewOnly={viewOnly}
        onDelete={() => {
          setCompanySearchInstructions({
            ...companySearchInstructions,
            employee_roles: undefined
          });
        }}
        onAdd={() => {
          setCompanySearchInstructions({
            ...companySearchInstructions,
            employee_roles: {
              high_standard: "",
              is_required: false,
              weight: 4
            }
          });
        }}
        isActive={companySearchInstructions.employee_roles != null}
      />
      {companySearchInstructions.employee_roles != null && (
        <>
          <AutosizedTextarea
            value={companySearchInstructions.employee_roles.high_standard ?? ""}
            disabled={viewOnly}
            placeholder="e.g. 'Head of delivery operations' or 'Node.js developers'"
            onChange={(e) => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                employee_roles: { ...companySearchInstructions.employee_roles!, high_standard: e.target.value }
              });
            }}
          />
          <div className="flex flex-row items-center gap-2">
            <span>Preferred</span>
            <Switch
              checked={companySearchInstructions.employee_roles.is_required}
              onCheckedChange={(e) => {
                setCompanySearchInstructions({
                  ...companySearchInstructions,
                  employee_roles: { ...companySearchInstructions.employee_roles!, is_required: e }
                });
              }}
            />
            <span>Required</span>
          </div>
        </>
      )}
      <FilterHeader
        title="Job listings"
        infoText="Describe job listings the companies should have"
        viewOnly={viewOnly}
        onDelete={() => {
          setCompanySearchInstructions({
            ...companySearchInstructions,
            job_listings: undefined
          });
        }}
        onAdd={() => {
          setCompanySearchInstructions({
            ...companySearchInstructions,
            job_listings: {
              high_standard: "",
              is_required: false,
              weight: 4
            }
          });
        }}
        isActive={companySearchInstructions.job_listings != null}
      />
      {companySearchInstructions.job_listings != null && (
        <>
          <AutosizedTextarea
            value={companySearchInstructions.job_listings.high_standard ?? ""}
            disabled={viewOnly}
            placeholder="e.g. 'entry level devops engineers' or 'sales' "
            onChange={(e) => {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                job_listings: { ...companySearchInstructions.job_listings!, high_standard: e.target.value }
              });
            }}
          />
          <div className="flex flex-row items-center gap-2">
            <span>Preferred</span>
            <Switch
              checked={companySearchInstructions.job_listings.is_required}
              onCheckedChange={(e) => {
                setCompanySearchInstructions({
                  ...companySearchInstructions,
                  job_listings: { ...companySearchInstructions.job_listings!, is_required: e }
                });
              }}
            />
            <span>Required</span>
          </div>
        </>
      )}
    </div>
  );
};

export const CompanySearchSemanticSearchBox = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendSearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  // Count non-null properties
  const nonNullCount = companySearchInstructions.semantic_items.filter((query) => query != null).length;

  const semanticFieldTypes = [
    SemanticFieldType.BusinessModel,
    SemanticFieldType.MainOfferings,
    SemanticFieldType.CustomerSegments,
    // FrontendSemanticFieldType.Geographies,
    SemanticFieldType.Technologies
  ];
  const handleSemanticQueryUpdate = (fieldType: SemanticFieldType, semanticQuery?: SemanticFieldSearchQuery) => {
    if (semanticQuery == null) {
      setCompanySearchInstructions({
        ...companySearchInstructions,
        semantic_items: companySearchInstructions.semantic_items.filter((q) => q.field !== fieldType)
      });
    } else {
      const existingIndex = companySearchInstructions.semantic_items.findIndex((q) => q.field === fieldType);
      const updatedInstructions = [...companySearchInstructions.semantic_items];

      if (existingIndex >= 0) {
        updatedInstructions[existingIndex] = semanticQuery;
      } else {
        updatedInstructions.push(semanticQuery);
      }

      setCompanySearchInstructions({
        ...companySearchInstructions,
        semantic_items: updatedInstructions
      });
    }
  };
  return (
    <div className="flex flex-col gap-2 bg-colors-gray-modern-50 p-2 rounded-lg w-[400px]">
      <div className="text-lg font-medium flex flex-row items-center gap-2 w-full">
        <Blend size={16} />
        <span>Semantic Search</span>
        <div className="flex grow" />
        {!viewOnly &&
          (companySearchInstructions.semantic_items.length === 0 ? (
            <Button
              variant="ghost"
              size="sm"
              onClick={() =>
                handleSemanticQueryUpdate(SemanticFieldType.BusinessModel, {
                  field: SemanticFieldType.BusinessModel,
                  query: ""
                })
              }>
              <Plus size={16} />
            </Button>
          ) : (
            <Button
              variant="destructive"
              size="sm"
              onClick={() => setCompanySearchInstructions({ ...companySearchInstructions, semantic_items: [] })}>
              <Trash2 size={16} />
            </Button>
          ))}
      </div>
      {companySearchInstructions.semantic_items.length > 0 && (
        <>
          <div className="text-md mb-1 text-colors-text-text-primary-(900) self-start">Search criteria (only 3 allowed at a time)</div>
          {semanticFieldTypes.map((fieldType) => (
            <CompanySearchSemanticQueryEditor
              key={fieldType}
              fieldType={fieldType}
              semanticQuery={companySearchInstructions.semantic_items.find((q) => q.field === fieldType)}
              setSemanticQuery={(query) => handleSemanticQueryUpdate(fieldType, query)}
              disableAdd={nonNullCount >= 3}
              viewOnly={viewOnly}
            />
          ))}
        </>
      )}
    </div>
  );
};

export const InstructionsFirstColumn = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendSearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  return (
    <div className="flex flex-col">
      <CompanySearchSemanticSearchBox
        companySearchInstructions={companySearchInstructions}
        setCompanySearchInstructions={setCompanySearchInstructions}
        viewOnly={viewOnly}
      />
      <OtherSearchCriteriaBox
        companySearchInstructions={companySearchInstructions}
        setCompanySearchInstructions={setCompanySearchInstructions}
        viewOnly={viewOnly}
      />
    </div>
  );
};
