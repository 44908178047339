import { Rubric, ScoredCompaniesRequest, ScoredDealcloudCompany } from "@/services/autogen";
import { getRubric, getScoredCompanies, updateRubric } from "@/services/brain-api.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export type RubricScoringState = {
  rubric?: Rubric;
  activeRubricId?: string;
  scoredCompanies: ScoredDealcloudCompany[];
};

const initialState: RubricScoringState = {
  scoredCompanies: []
};

export const fetchRubricThunk = createAsyncThunk("fetchRubric", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getRubric(token).then((response) => {
    const rubricData = response.data;
    thunkAPI.dispatch(rubricScoringSlice.actions.loadRubric({ rubric: rubricData }));
  });
});

export const updateRubricThunk = createAsyncThunk("updateRubric", async (thunkPayload: { token: string; rubric: Rubric }, thunkAPI) => {
  const { token, rubric } = thunkPayload;
  thunkAPI.dispatch(rubricScoringSlice.actions.setRubric({ rubric: rubric }));
  await updateRubric(token, rubric);
});

export const fetchScoredCompaniesThunk = createAsyncThunk(
  "fetchScoredCompanies",
  async (thunkPayload: { token: string; request: ScoredCompaniesRequest }, thunkAPI) => {
    const { token, request } = thunkPayload;
    // Clean up empty or whitespace-only filters
    if (request.individual_filter?.trim() === "") {
      request.individual_filter = undefined;
    }
    if (request.pod_filter?.trim() === "") {
      request.pod_filter = undefined;
    }
    const response = await getScoredCompanies(token, request);
    thunkAPI.dispatch(rubricScoringSlice.actions.setScoredCompanies({ scoredCompanies: response.data }));
  }
);

export const rubricScoringSlice = createSlice({
  name: "rubricScoring",
  initialState,
  reducers: {
    loadRubric: (state, action: PayloadAction<{ rubric: Rubric }>) => {
      const { rubric } = action.payload;
      state.rubric = rubric;
    },
    setRubric: (state, action: PayloadAction<{ rubric: Rubric }>) => {
      const { rubric } = action.payload;
      state.rubric = rubric;
    },
    setActiveRubricId: (state, action: PayloadAction<{ rubric_id: string }>) => {
      state.activeRubricId = action.payload.rubric_id;
    },
    setScoredCompanies: (state, action: PayloadAction<{ scoredCompanies: ScoredDealcloudCompany[] }>) => {
      const { scoredCompanies } = action.payload;
      state.scoredCompanies = scoredCompanies;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setActiveRubricId } = rubricScoringSlice.actions;

export default rubricScoringSlice.reducer;
