/* eslint-disable @typescript-eslint/no-empty-function */
import { useAppDispatch } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState, useCallback } from "react";
import { Dialog, DialogContent, DialogTitle, DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import debounce from "lodash/debounce";
import { duplicateCompanySearchForUser, duplicatePeopleList } from "@/services/brain-api.service";
import { CopyPlus } from "lucide-react";

export const AddToMyAccountDialog = ({ disabled, type }: { disabled?: boolean; type: "company_search" | "people_list" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();

  const oldId = location.pathname.split(`/${type}/`)[1].replace(/\/$/, "");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleAddToAccount = useCallback(
    debounce(
      async () => {
        try {
          const token = await getToken();

          if (!token) {
            setIsDialogOpen(true);
            return;
          }
          let newId;
          if (type === "company_search") {
            newId = (await duplicateCompanySearchForUser(token, oldId)).data.search_id;
          } else if (type === "people_list") {
            newId = (await duplicatePeopleList(token, oldId)).data.people_list_id;
          }
          window.open(`/?${type}=${newId}`, "_blank");
        } catch (error) {
          console.error("Error duplicating company search:", error);
        }
      },
      2000,
      { leading: true, trailing: false }
    ),
    [getToken, dispatch, oldId]
  );

  useEffect(() => {
    return () => {
      handleAddToAccount.cancel();
    };
  }, [handleAddToAccount]);

  const handleSignIn = () => {
    const returnUrl = encodeURIComponent(`/${type}/${oldId}`);
    navigate(`/sign-in?redirect_url=${returnUrl}`);
    setIsDialogOpen(false);
  };
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent className="w-[400px]">
        <DialogTitle>Please sign in to add this search.</DialogTitle>
        <div className="flex flex-row gap-4">
          <DialogClose asChild>
            <Button onClick={handleSignIn}>Sign in</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
      <Button size="sm" onClick={handleAddToAccount} disabled={disabled}>
        <CopyPlus size={20} className="mr-2" />
        Add to my account
      </Button>
    </Dialog>
  );
};
