import { useState } from "react";

export const ImageUploader = ({ onImageUpload }: { onImageUpload: (imageSrc: string) => void }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  // Handle image file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result as string);
        onImageUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle image paste
  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const items = e.clipboardData.items;
    for (let item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result as string);
          onImageUpload(reader.result as string);
        };
        reader.readAsDataURL(file as Blob);
      }
    }
  };

  return (
    <div onPaste={handlePaste}>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      {imageSrc && <img src={imageSrc} alt="Uploaded" style={{ width: "300px", height: "auto" }} />}
    </div>
  );
};
