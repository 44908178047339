import { PersonRow } from "./PersonRow";
import { CompanyStubCell, PersonNameCell, SortingDirectionIndicator } from "@/components/person-table/common/CellComponents";
import { Plus, Trash2 } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Notepad } from "@phosphor-icons/react";

import { ColumnDef, Row, VisibilityState } from "@tanstack/react-table";
import { ScoreChip } from "@/components/SourceCompanies/GiantTable/SourceCompaniesTable";
import { CrmStatusChip } from "@/components/company-table/common/CellComponents";
export function createSelectColumn<T extends PersonRow>(): ColumnDef<T> {
  let lastSelectedId = "";

  return {
    accessorKey: "tableRank",
    id: "select",
    header: ({ column, table }) => (
      <div className="flex flex-row hover:cursor-pointer px-2 gap-2 justify-between">
        <div className="flex flex-row hover:cursor-pointer gap-2" onClick={column.getToggleSortingHandler()}>
          #
          <SortingDirectionIndicator column={column} />
        </div>
        <Checkbox checked={table.getIsAllRowsSelected()} onClick={table.getToggleAllRowsSelectedHandler()} />
      </div>
    ),
    cell: ({ row, table }) => (
      <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center gap-2 justify-between">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.index + 1}</span>
        <Checkbox
          id={`select-row-${row.id}`}
          checked={row.getIsSelected()}
          onClick={(e) => {
            if (e.shiftKey) {
              const { rows, rowsById } = table.getRowModel();
              const isLastSelected = rowsById[lastSelectedId].getIsSelected();
              const rowsToToggle = getRowRange(rows, row.id, lastSelectedId);

              const currentSelectionState = table.getState().rowSelection;
              // Create an object mapping row IDs to selection state
              const selectionUpdate = rowsToToggle.reduce(
                (acc, row) => {
                  acc[row.id] = isLastSelected;
                  return acc;
                },
                { ...currentSelectionState }
              );

              // Update all rows at once
              table.setRowSelection(selectionUpdate);
            } else {
              row.toggleSelected(!row.getIsSelected());
            }

            lastSelectedId = row.id;
            e.stopPropagation();
          }}
        />
      </div>
    ),
    maxSize: 50,
    meta: {
      group: "meta",
      displayName: "Select"
    }
  };
}

function getRowRange<T>(rows: Array<Row<T>>, idA: string, idB: string) {
  const range: Array<Row<T>> = [];
  let foundStart = false;
  let foundEnd = false;
  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index];
    if (row.id === idA || row.id === idB) {
      if (foundStart) {
        foundEnd = true;
      }
      if (!foundStart) {
        foundStart = true;
      }
    }

    if (foundStart) {
      range.push(row);
    }

    if (foundEnd) {
      break;
    }
  }

  return range;
}

export const basicColumns: ColumnDef<PersonRow>[] = [
  createSelectColumn(),
  {
    accessorKey: "name",
    // size: 100,
    header: () => {
      return (
        // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Person</h1>
        // <CaretUpDown className="ml-2 h-4 w-4" />
        // </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-col items-start py-2 pl-2 z-40">
        <PersonNameCell person={row.original} />
      </div>
    ),
    meta: {
      group: "meta",
      displayName: "Person"
    }
  },
  {
    accessorKey: "company",
    // size: 100,
    header: () => {
      return (
        // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
        // <CaretUpDown className="ml-2 h-4 w-4" />
        // </Button>
      );
    },
    cell: ({ row }) => <div>{row.original.company_stub && <CompanyStubCell companyStub={row.original.company_stub} />}</div>,
    meta: {
      group: "meta"
    }
  },
  {
    accessorKey: "title",
    header: () => (
      <div className="flex flex-row items-center">
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Title</h1>
      </div>
    ),
    size: 140,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.title}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Title"
    }
  },
  {
    accessorKey: "location",
    header: () => (
      <div className="flex flex-row items-center">
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Location</h1>
      </div>
    ),
    size: 100,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.location}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Location"
    }
  },
  {
    accessorKey: "persona_fit",
    header: () => (
      <div className="flex flex-row items-center">
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Persona Fit</h1>
      </div>
    ),
    size: 60,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
        <ScoreChip score={row.original.persona_fit} maxScore={5} />
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Persona Fit"
    }
  },
  {
    accessorKey: "persona_requested",
    header: () => (
      <div className="flex flex-row items-center">
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Requested persona</h1>
      </div>
    ),
    size: 120,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.persona_requested}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Persona Description"
    }
  },
  {
    accessorKey: "email",
    size: 80,
    header: () => (
      <div>
        <span>Email</span>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px]">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.email}</span>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Email"
    }
  },
  {
    accessorKey: "phone_number",
    size: 100,
    header: () => (
      <div>
        <span>Phone Number</span>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px]">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
          {row.original.phone_number !== "not_found" ? row.original.phone_number : <span className="text-red-500">Not found</span>}
        </span>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Phone Number"
    }
  }
];

export const finalColumn = (
  tableRef: React.RefObject<HTMLDivElement>,
  onRowDelete?: (id: string) => void,
  additionalColumnGroups?: string[]
): ColumnDef<PersonRow> => {
  const columnGroups = ["basic_info", ...(additionalColumnGroups ?? [])];
  return {
    accessorKey: "trash",
    size: 50,
    header: ({ table }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
              <Plus size={20} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
            <span className="flex items-center justify-start my-1 p-1">
              <Notepad className="mr-1" size={22} />
              <h1 className="font-semi">Basic info</h1>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  const columns = table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .filter((column) => columnGroups.includes(column?.columnDef?.meta?.group ?? "Unknown"));

                  // Check if all columns are visible
                  const allVisible = columns.every((col) => col.getIsVisible());

                  // Toggle all columns
                  columns.forEach((column) => {
                    column.toggleVisibility(!allVisible);
                  });

                  // Scroll to the right after a brief delay
                  setTimeout(() => {
                    tableRef.current?.scrollTo({ left: tableRef.current.scrollWidth, behavior: "smooth" });
                  }, 100);
                }}>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column) => columnGroups.includes(column?.columnDef?.meta?.group ?? "Unknown"))
                  .every((col) => col.getIsVisible())
                  ? "Deselect All"
                  : "Select All"}
              </Button>
            </span>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .filter((column) => columnGroups.includes(column?.columnDef?.meta?.group ?? "Unknown"))
              .map((column) => {
                return (
                  <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                    <Checkbox
                      key={column.id}
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => {
                        column.toggleVisibility(!!value);

                        // sleep for 1 sec
                        setTimeout(() => {
                          console.log("trying to scroll");
                          // scroll to the right
                          tableRef.current?.scrollTo({ left: tableRef.current.scrollWidth, behavior: "smooth" });
                        }, 100);
                      }}>
                      {column.id}
                    </Checkbox>

                    <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                  </div>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
    enableHiding: false,
    cell: ({ row, table }) => {
      const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
      return isRowHovered && onRowDelete ? (
        <div className="flex justify-center items-center">
          <Button
            className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
            onClick={(e) => {
              onRowDelete(row.id);
              e.stopPropagation();
            }}>
            <Trash2 size={16} className="trash-icon" strokeWidth={2} />
          </Button>
        </div>
      ) : (
        <div className="min-w-[22px] min-h-[39px]"></div>
      );
    }
  };
};

export function crmStatusColumn<T extends PersonRow>(): ColumnDef<T> {
  return {
    accessorKey: "crmStatus",
    id: "crmStatus",
    header: ({ column }) => (
      <div className="flex flex-row hover:cursor-pointer px-2 gap-2 justify-between">
        <div className="flex flex-row hover:cursor-pointer gap-2" onClick={column.getToggleSortingHandler()}>
          CRM status
        </div>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center gap-2 justify-between">
        <CrmStatusChip status={row.original.crm_status} />
      </div>
    ),
    maxSize: 50,
    meta: {
      group: "meta",
      displayName: "CRM status"
    }
  };
}

export const columnDefaultVisibility: VisibilityState = {
  first_name: true,
  last_name: true,
  email: true,
  title: true,
  company: true,
  phone_number: true
};
