import { Column } from "@tanstack/react-table";
import { ExternalLink, MoveDown, MoveUp } from "lucide-react";
import { ensureAbsoluteUrl } from "@/lib/utils";
import { FrontendPerson } from "@/services/autogen";
import { PersonRow } from "./PersonRow";
import { CompanyProfileStub } from "@/store/companyProfiles";
import { CompanyLogo } from "@/components/ui/avatar";

export const SortingDirectionIndicator = <T extends PersonRow>({ column }: { column: Column<T> }) => {
  if (column.getIsSorted() === "desc") {
    return <MoveDown size={14} className="shrink-0" />;
  } else if (column.getIsSorted() === "asc") {
    return <MoveUp size={14} className="shrink-0" />;
  } else {
    return null;
  }
};

export const PersonNameCell = ({ person }: { person: FrontendPerson }) => {
  return (
    <div className="flex flex-col items-start py-2 pl-2 z-40">
      <div className="flex flex-row items-center">
        {/* <CompanyLogo
        name={row.original.company_stub.name}
        website={row.original.company_stub.domain}
        logo={row.original.company_stub.logo_url}
        className="w-8 h-8 mr-2 opacity-100"
      /> */}
        <div className="flex flex-col">
          <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
            {person.first_name + " " + person.last_name}
          </h1>
          <div className="flex flex-row items-center gap-2">
            {person.linkedin_url && (
              <a href={ensureAbsoluteUrl(person.linkedin_url)} className="self-center" target="_blank" rel="noopener noreferrer">
                <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CompanyStubCell = ({ companyStub }: { companyStub: CompanyProfileStub }) => {
  return (
    <div className="flex flex-row items-center">
      <CompanyLogo name={companyStub.name} website={companyStub.domain} logo={companyStub.logo_url} className="w-8 h-8 mr-2 opacity-100" />
      <div className="flex flex-col">
        <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
          {companyStub.name.length > 15 ? `${companyStub.name.slice(0, 15)}...` : companyStub.name}
        </h1>
        <div className="flex flex-row items-center gap-2">
          <a
            href={ensureAbsoluteUrl(companyStub.domain ?? "")}
            className="flex flex-row hover:cursor-pointer font-medium underline"
            target="_blank"
            rel="noopener noreferrer">
            <ExternalLink size={14} color="grey" />
          </a>
        </div>
      </div>
    </div>
  );
};
