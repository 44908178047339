/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { ArrowRightFromLine, ExternalLink } from "lucide-react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { CompanyLogo } from "../ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl } from "@/lib/utils";
import { CompanyTypeChip } from "@/components/company-table/common/CellComponents";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { addCompanyToList } from "@/store/companyLists";
import { WebTrafficDisplay } from "./WebTraffic";
import { demoFeaturesOn } from "@/utils/config";
import { useAuth } from "@clerk/clerk-react";
import { ingestCompany } from "@/services/brain-api.service";
import { FindContactsDropdown } from "../company-lists/FindContactsDropdown";

export const CompanyProfileHeader = () => {
  const companyLists = useAppSelector((state) => state.companyLists.companyLists);
  const tearsheet = useAppSelector((state) =>
    state.companyProfiles.tearsheets.find((c) => c.company.domain === state.companyProfiles.activeCompanyDomain)
  );
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  if (tearsheet == null) {
    return null;
  }
  const company = tearsheet.company;
  const name = company.name;
  const domain = company.domain;
  const logo = company.logo_url;

  const employeeGrowthMonthly = company.employee_count_90day_growth;
  const employeeGrowthColor = employeeGrowthMonthly !== undefined && employeeGrowthMonthly > 0 ? "green" : "red";
  const formattedEmployeeGrowth =
    employeeGrowthMonthly !== undefined
      ? `${employeeGrowthMonthly > 0 ? "+" : "-"}${Math.abs(employeeGrowthMonthly * 100).toFixed(1)}%`
      : "";

  return (
    <div
      className={`relative py-2 mb-4 inline-flex justify-between items-center bg-white border-b border-colors-border-border-secondary h-16 z-40 w-full px-10`}>
      <div className="flex flex-row items-center gap-3 overflow-hidden">
        <div className="flex flex-row py-2 items-center h-full">
          <CompanyLogo name={name} website={domain} logo={logo} className="mr-2" />
          <div className="flex flex-col">
            <div className="text-2xl text-colors-brand-900 font-semibold whitespace-nowrap overflow-hidden text-ellipsis">{name}</div>
            <div className="flex flex-row items-center gap-2 mt-1">
              <div className="text-colors-text-text-tertiary-(600) font-semibold">{company.stock_ticker}</div>
              {domain && (
                <a
                  href={ensureAbsoluteUrl(domain)}
                  className="flex flex-row hover:cursor-pointer underline text-blue-800"
                  target="_blank"
                  rel="noopener noreferrer">
                  <ExternalLink size={16} />
                </a>
              )}
              {company.linkedin_aid && (
                <a
                  href={ensureAbsoluteUrl("linkedin.com/company/" + company.linkedin_aid)}
                  className="self-center"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="/linkedin.png" className="h-4 w-4" />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-6 text-colors-text-text-tertiary-(600) overflow-hidden">
          <div>
            <div className="font-thin">Employees</div>
            <div className="flex flex-row space-x-2 items-baseline whitespace-nowrap overflow-hidden">
              <span className="truncate">{employeeRangeText(company.employee_min, company.employee_max, company.employee_guess)}</span>
              {company.linkedin_followers_90day_growth && (
                <div className="flex flex-row space-x-1 items-baseline flex-shrink-0">
                  <span className="flex items-baseline" style={{ color: employeeGrowthColor }}>
                    {formattedEmployeeGrowth}
                  </span>
                  <span className="text-sm">(90-day)</span>
                </div>
              )}
            </div>
          </div>

          {company.year_founded && (
            <div>
              <div className="font-thin">Founded</div>
              <div className="">{company.year_founded}</div>
            </div>
          )}
          {company.hq_str && (
            <div className="min-w-fit overflow-hidden">
              <div className="font-thin">Headquarters</div>
              <div className="">{company.hq_str}</div>
            </div>
          )}
          {company.company_type && (
            <div className="min-w-fit">
              <div className="font-thin">Ownership</div>
              <div className="text-xs">
                <CompanyTypeChip type={company.company_type} />
              </div>
            </div>
          )}
          {company.web_traffic && (
            <div className="min-w-fit">
              <div className="font-thin">Web Traffic</div>
              <div>
                <WebTrafficDisplay webTraffic={company.web_traffic} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <FindContactsDropdown domainsSelected={[domain]} disabled={false} />
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline">Add to list</Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className="flex flex-col gap-2">
              {companyLists.map((list) => (
                <Button
                  variant="outline"
                  key={list.id}
                  onClick={() => {
                    dispatch(addCompanyToList({ company, companyListId: list.id }));
                    toast.success(`Added ${company.name} to ${list.title}`);
                  }}>
                  {list.title}
                </Button>
              ))}
            </div>
          </PopoverContent>
        </Popover>
        {/* {demoFeaturesOn && (
          <Popover>
            <PopoverTrigger asChild>
              <Button className="flex flex-row gap-2">
                <Sparkles size={16} /> Enrich
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="flex flex-col gap-2">
                <Button
                  variant="outline"
                  disabled={tearsheet.enrichments?.find((e) => e.enrichment_type === TearsheetEnrichmentType.Pitchbook) != null}
                  onClick={async () => {
                    const token = await getToken();
                    if (token == null) {
                      toast.error("Please login to use this feature");
                      return;
                    } else {
                      dispatch(
                        fetchTearsheetEnrichmentThunk({ token, companyDomain: domain, enrichmentType: TearsheetEnrichmentType.Pitchbook })
                      );
                      toast.success("Enrichment started");
                    }
                  }}>
                  <img src="/pitchbook_logo.png" className="w-32" />
                </Button>
                <Button variant="outline" disabled>
                  <img src="/preqin_logo.png" className="w-32" />
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        )} */}
        {demoFeaturesOn && (
          <Button
            // variant="outline"
            onClick={async () => {
              const token = await getToken();
              if (token == null) {
                toast.error("Please login to use this feature");
                return;
              }
              ingestCompany(token, domain);
              toast.success("Added to inbox");
            }}>
            <ArrowRightFromLine size={16} className="mr-2" />
            Add to inbox
          </Button>
        )}
      </div>
    </div>
  );
};
