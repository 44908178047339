/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { Notepad } from "@phosphor-icons/react";
import { Column, ColumnDef } from "@tanstack/react-table";
import { Button } from "../ui/button";
import { ExternalLink, MoveDown, MoveUp, Plus } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl } from "@/lib/utils";
import { IngestedCompany } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import React, { useRef } from "react";
import { CompanyTypeChip, HypeFlameSizeIcon } from "../company-table/common/CellComponents";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";

export interface IngestedCompanyRow extends IngestedCompany {
  tableRank: number;
  checked: boolean;
}

const SortingDirectionIndicator = ({ column }: { column: Column<IngestedCompanyRow> }) => {
  if (column.getIsSorted() === "asc") {
    return <MoveDown size={14} />;
  } else if (column.getIsSorted() === "desc") {
    return <MoveUp size={14} />;
  } else {
    return null;
  }
};

export const IngestedCompanyListTable = React.memo(({ ingestedCompanies }: { ingestedCompanies: IngestedCompany[] }) => {
  const tableWindowRef = useRef<HTMLDivElement>(null);
  // TODO: type this properly
  const tableRows: IngestedCompanyRow[] = [];
  ingestedCompanies.forEach((ingestedCompany, index) => {
    tableRows.push({ ...ingestedCompany, tableRank: index, checked: false });
  });

  const basicColumns: ColumnDef<IngestedCompanyRow>[] = [
    {
      accessorKey: "tableRank",
      // header: () => "#",
      header: (header) => (
        <div className="flex flex-row hover:cursor-pointer px-2 w-12" onClick={header.column.getToggleSortingHandler()}>
          #
          <SortingDirectionIndicator column={header.column} />
        </div>
      ),
      size: 15,
      cell: ({ row }) => {
        return (
          <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.tableRank + 1}</span>
          </div>
        );
      },
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "name",
      // size: 100,
      header: ({}) => {
        return (
          // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
          // <CaretUpDown className="ml-2 h-4 w-4" />
          // </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex flex-col items-start py-2 pl-2 z-40">
          {row.original.company.name != null && (
            <div className="flex flex-row items-center">
              <CompanyLogo
                name={row.original.company.name}
                website={row.original.company.domain}
                logo={row.original.company.logo_url}
                className="w-8 h-8 mr-2 opacity-100"
              />
              <div className="flex flex-col">
                <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                  {row.original.company.name}
                </h1>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
                    {row.original.company.stock_ticker ?? ""}
                  </span>
                  <a
                    href={ensureAbsoluteUrl(row.original.company.domain ?? "")}
                    className="flex flex-row hover:cursor-pointer font-medium underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ExternalLink size={14} color="grey" />
                  </a>
                  {row.original.company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
                    </a>
                  )}
                  <HypeFlameSizeIcon hype={row.original.company.hype_rating} />
                </div>
              </div>
            </div>
          )}
        </div>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "companyType",
      header: () => (
        <div className="flex flex-row items-center">
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
        </div>
      ),
      size: 160,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
          {/* <CompanyTypeIcon type={row.original.company_type} /> */}
          <CompanyTypeChip type={row.original.company.company_type} />
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "employee_count",
      // size: 25,
      size: 80,
      header: () => (
        <div>
          <span>Employees</span>
        </div>
      ),
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-center">
          {/* TODO: show the range */}
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {employeeRangeText(row.original.company.employee_min, row.original.company.employee_max, row.original.company.employee_guess)}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Employee Count"
      }
      // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
      // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
    },
    {
      accessorKey: "added_by",
      // size: 25,
      size: 90,
      header: () => (
        <div>
          <span>Added by</span>
        </div>
      ),
      cell: () => (
        <span className="bg-colors-blue-100 rounded-lg px-2 py-1 text-colors-blue-dark-900 flex-row items-center gap-1 text-xs font-semibold w-auto">
          Tim Drager
        </span>
      ),
      meta: {
        group: "basic_info",
        displayName: "Employee Count"
      }
      // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
      // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
    },
    {
      accessorKey: "added_at",
      // size: 25,
      size: 90,
      header: () => (
        <div>
          <span>Added on</span>
        </div>
      ),
      cell: ({ row }) => (
        <span>
          {row.original.added_at ? new Date(row.original.added_at).toLocaleDateString("en-US", { month: "short", day: "numeric" }) : "-"}
        </span>
      ),
      meta: {
        group: "basic_info",
        displayName: "Employee Count"
      }
      // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
      // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
    },
    {
      accessorKey: "overview",
      header: () => "Overview",
      size: 180,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.company.summary.overview.text}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "in_scope",
      header: () => "In scope?",
      size: 80,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.in_scope ? "Yes" : "No"}</span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "out_of_scope_reason",
      header: () => "Out of scope reason",
      size: 100,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.out_of_scope_reason ?? "-"}</span>
        </div>
      )
    },
    {
      accessorKey: "evaluation_score",
      header: () => "Auto score",
      size: 80,
      cell: ({ row }) => (
        <div className="bg-colors-gray-modern-4000 rounded-sm text-center self-center px-1">
          <ScoreChip score={row.original.evaluation_score} numberOfDecimals={1} />
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Eval score"
      }
    }
  ];
  const newColumns = [...basicColumns];

  const getAllColumns = () => {
    newColumns.push({
      accessorKey: "trash",
      size: 50,
      header: ({ table }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                <Plus size={20} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
              <span className="flex items-center justify-start my-1 p-1">
                <Notepad className="mr-1" size={22} />
                <h1 className="font-semi">Basic info</h1>
              </span>
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                .map((column: any) => {
                  // TODO: stop using any
                  return (
                    <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                      <Checkbox
                        key={column.id}
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) => {
                          column.toggleVisibility(!!value);

                          // sleep for 1 sec
                          setTimeout(() => {
                            console.log("trying to scroll");
                            // scroll to the right
                            tableWindowRef.current?.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                          }, 100);
                        }}>
                        {column.id}
                      </Checkbox>

                      <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                    </div>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableHiding: false,
      cell: ({}) => {
        return <div className="min-w-[22px] min-h-[39px]"></div>;
      }
    });
  };

  getAllColumns();

  return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} isRunning={false} />;
});
