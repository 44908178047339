import { GenericWorkflow, HardMetric, TextWithSources, WorkflowType } from "@/services/autogen";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import numeral from "numeral";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const truncateText = (str: string, n = 40) => {
  return str.length > n ? str.substring(0, n - 1) + "..." : str;
};

export const stripNewlines = (str?: string) => {
  return str?.replace(/(\r\n|\n|\r)/gm, "");
};

export function ensureAbsoluteUrl(url: string) {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return "http://" + url; // Default to http if no protocol is specified
  }
}

export function getTextFromTextWithSources(textWithSourcesList: TextWithSources[] | undefined) {
  if (textWithSourcesList == null) {
    return "";
  }
  return textWithSourcesList.map((textWithSources) => textWithSources.text).join(", ");
}

export function formatDollarValue(value: number | undefined | null) {
  if (value == null) return "-";
  return numeral(value).format("$0.0 a");
}

export function formatDollarValueRound(value: number | undefined | null) {
  if (value == null) return "-";
  return numeral(value).format("$0 a");
}

export function formatPercentValue(value: number | undefined | null, includePlusSign?: boolean) {
  if (value == null) return "-";
  const formattedValue = numeral(value).format("0.0%");
  if (includePlusSign && value > 0) {
    return "+" + formattedValue;
  }
  return formattedValue;
}

export function formatAbsoluteMagnitude(value: number | undefined | null) {
  if (value == null) return "-";
  if (value > 1000000000) {
    return (value / 1000000000).toFixed(1) + "B";
  } else if (value > 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value > 1000) {
    return (value / 1000).toFixed(0) + "k";
  }
  return value.toFixed(0);
}

export function formatDollarValueMillionsBillions(value: number | undefined | null) {
  // TODO: add $M to the header and get rid of the M here.
  if (value == null) return "-";
  if (Math.abs(value) >= 1000000000) {
    // If absolute value is greater than 1B display as $XXX B with no decimals
    const formattedValue = (Math.abs(value) / 1000000000).toFixed(1);
    if (value < 0) {
      return `-$${formattedValue}B`;
    } else {
      return `$${formattedValue}B`;
    }
  } else if (Math.abs(value) >= 1000000) {
    // If absolute value is greater than 1M display as $XXX M with no decimals
    const formattedValue = (Math.abs(value) / 1000000).toFixed(1);
    if (value < 0) {
      return `-$${formattedValue}M`;
    } else {
      return `$${formattedValue}M`;
    }
  } else {
    // If absolute value is less than 1M display as $0.XX M with 2 sig figs
    // if value is less than 0 display as -$XXX M
    if (value < 0) {
      return `-$${Number((-value / 1000000).toFixed(2))} M`;
    } else {
      return `$${Number((value / 1000000).toFixed(2))} M`;
    }
  }
}

export function formatMultipleValue(value: number | undefined | null) {
  if (value == null) return "-";
  return numeral(value).format("0.0") + "x";
}

export const headerTextFYorTTM = (ttmToggle: boolean) => {
  return ttmToggle ? "(TTM)" : "(FY)";
};

export function hardMetricDisplayText(metric: HardMetric) {
  switch (metric) {
    case HardMetric.Revenue:
      return "Revenue";
    case HardMetric.Ebitda:
      return "EBITDA";
    case HardMetric.EnterpriseValue:
      return "Enterprise value";
    case HardMetric.EmployeeCount:
      return "Employee count";
    case HardMetric.MarketCap:
      return "Market cap";
    case HardMetric.RevenueGrowth:
      return "Revenue growth";
    case HardMetric.Earnings:
      return "Earnings";
    case HardMetric.GrossMargin:
      return "Gross margin";
    case HardMetric.FreeCashFlow:
      return "Free cash flow";
    case HardMetric.FoundedYear:
      return "Founded year";
    case HardMetric.LastFundraiseDate:
      return "Last fundraise";
  }
}

export function maturityLevelText(maturityLevel?: number) {
  switch (maturityLevel) {
    case 0:
      return "Defunct, company ceased operations";
    case 1:
      return "SMB";
    case 2:
      return "Medium enterprise";
    case 3:
      return "Large corporation";
    default:
      return "Unknown";
  }
}

export function getDomainFromUrl(url: string): string {
  // Remove the 'http://' or 'https://' prefix from the URL using a regular expression
  const strippedUrl = url.replace(/^(https?:\/\/)(www\.)?/, "");

  // Extract the domain part of the URL
  // This splits the URL at slashes and takes the first part, which is the domain
  const domain = strippedUrl.split("/")[0];

  return domain;
}

export function isValidUrl(url: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
}

export function copyWorkflowOutputsToClipboard(workflow: GenericWorkflow) {
  console.error("Not implemented");
  if (workflow.workflow_type === WorkflowType.CompFinder || workflow.workflow_type === WorkflowType.MarketMap) {
    // TODO: Implement
  }
}

export function employeeRangeText(min?: number, max?: number, guess?: number) {
  // return "48";
  if (min == null && max == null && guess == null) {
    return "";
  }
  if (min == null && max == null) {
    return `~${guess}`;
  }
  if (min == null) {
    return `< ${max}`;
  }
  if (max == null) {
    return `${min}+`;
  }
  if (min === max) {
    return `~${min}`;
  }
  return `${min}-${max}`;
}
