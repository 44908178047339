/* eslint-disable @typescript-eslint/no-unused-vars */
import { FrontendCompany, FrontendPerson, PeopleList, PeopleListStatus, PeopleListStub } from "@/services/autogen";
import {
  getPeopleLists,
  deletePeopleList,
  updatePeopleList,
  addPeopleToPeopleListByLinkedinUrls,
  enrichPhoneNumbers as enrichPhoneNumbers,
  createPeopleListWithCompanyContacts,
  addToPeopleListWithCompanyContacts,
  getPeopleListsStubs,
  getPeopleList
} from "@/services/brain-api.service";
import { createAsyncThunk, createSlice, Middleware } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { toast } from "sonner";

export type PeopleListsState = {
  activePeopleListId?: string;
  peopleLists: PeopleList[];
  stubs: PeopleListStub[];
};

const initialState: PeopleListsState = {
  peopleLists: [],
  stubs: []
};

export const addToPeopleListThunkByLinkedinUrls = createAsyncThunk(
  "addToPeopleListThunkByLinkedinUrls",
  async (thunkPayload: { token: string; people_list_id: string; linkedin_urls: string[] }, thunkAPI) => {
    const { token, people_list_id, linkedin_urls } = thunkPayload;
    addPeopleToPeopleListByLinkedinUrls(token, people_list_id, linkedin_urls).then((response) => {
      if (response.status === 200) {
        const peopleList = response.data;
        thunkAPI.dispatch(peopleListsSlice.actions.loadPeopleList({ peopleList }));
      }
    });
  }
);

export const fetchPeopleListsThunk = createAsyncThunk("fetchPeopleLists", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getPeopleLists(token).then((response) => {
    if (response.status === 200) {
      const peopleLists = response.data;
      thunkAPI.dispatch(peopleListsSlice.actions.loadPeopleLists({ peopleLists }));
    }
  });
});

export const fetchPeopleListsStubsThunk = createAsyncThunk("fetchPeopleListsStubs", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getPeopleListsStubs(token).then((response) => {
    if (response.status === 200) {
      const stubs = response.data;
      thunkAPI.dispatch(peopleListsSlice.actions.loadPeopleListsStubs({ stubs }));
    }
  });
});

export const fetchPeopleListThunk = createAsyncThunk(
  "fetchPeopleList",
  async (thunkPayload: { token: string; peopleListId: string }, thunkAPI) => {
    const { token, peopleListId } = thunkPayload;
    getPeopleList(token, peopleListId).then((response) => {
      if (response.status === 200) {
        const peopleList = response.data;
        thunkAPI.dispatch(peopleListsSlice.actions.loadPeopleList({ peopleList }));
      }
    });
  }
);

export const createPeopleListWithCompanyContactsThunk = createAsyncThunk(
  "createPeopleListWithCompanyContacts",
  async (
    thunkPayload: { token: string; companyDomains: string[]; personaDescription: string; limitContactsPerCompany?: number },
    thunkAPI
  ) => {
    const { token, companyDomains, personaDescription, limitContactsPerCompany } = thunkPayload;
    createPeopleListWithCompanyContacts(token, companyDomains, personaDescription, limitContactsPerCompany).then((response) => {
      if (response.status === 200) {
        const peopleList = response.data;
        thunkAPI.dispatch(peopleListsSlice.actions.loadPeopleList({ peopleList }));
        thunkAPI.dispatch(peopleListsSlice.actions.setActivePeopleList({ peopleListId: peopleList.id }));
      }
    });
  }
);

export const addToPeopleListWithCompanyContactsThunk = createAsyncThunk(
  "addToPeopleListWithCompanyContacts",
  async (
    thunkPayload: {
      token: string;
      peopleListId: string;
      companyDomains: string[];
      personaDescription: string;
      limitContactsPerCompany?: number;
    },
    thunkAPI
  ) => {
    const { token, peopleListId, companyDomains, personaDescription, limitContactsPerCompany } = thunkPayload;
    addToPeopleListWithCompanyContacts(token, peopleListId, companyDomains, personaDescription, limitContactsPerCompany).then(
      (response) => {
        if (response.status === 200) {
          thunkAPI.dispatch(peopleListsSlice.actions.setPeopleListStatus({ peopleListId, status: PeopleListStatus.AddingContacts }));
        }
      }
    );
  }
);

// If peopleListId is not provided, create a new people list and add the contacts to it
export const getPhoneNumbersAndAddToPeopleListThunk = createAsyncThunk(
  "getPhoneNumbersAndAddToPeopleList",
  async (
    thunkPayload: {
      token: string;
      peopleListId: string;
      people: FrontendPerson[];
    },
    thunkAPI
  ) => {
    const { token, peopleListId, people } = thunkPayload;
    enrichPhoneNumbers(token, peopleListId, people)
      .then((response) => {
        if (response.status === 200) {
          const people = response.data.people;
          console.log("Updating people's phone numbers with people list", peopleListId, people);
          thunkAPI.dispatch(peopleListsSlice.actions.loadPeopleList({ peopleList: response.data }));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough contact credits to enrich phone numbers");
        } else {
          toast.error("Failed to enrich phone numbers");
        }
      });
  }
);

export const peopleListsSlice = createSlice({
  name: "peopleLists",
  initialState,
  reducers: {
    setActivePeopleList: (state, action: PayloadAction<{ peopleListId: string }>) => {
      const { peopleListId } = action.payload;
      state.activePeopleListId = peopleListId;
    },
    loadPeopleList: (state, action: PayloadAction<{ peopleList: PeopleList }>) => {
      const { peopleList } = action.payload;
      const existingPeopleIndex = state.peopleLists.findIndex((list) => list.id === peopleList.id);
      if (existingPeopleIndex === -1) {
        // People list not found, add it to the array
        state.peopleLists.push(peopleList);
      } else {
        // People list found, update it
        state.peopleLists[existingPeopleIndex] = peopleList;
      }
      // update stubs too
      const stub = {
        id: peopleList.id,
        title: peopleList.title,
        status: peopleList.status
      };
      const existingStubIndex = state.stubs.findIndex((s) => s.id === peopleList.id);
      if (existingStubIndex === -1) {
        state.stubs.push(stub);
      } else {
        state.stubs[existingStubIndex] = stub;
      }
    },
    loadPeopleLists: (state, action: PayloadAction<{ peopleLists: PeopleList[] }>) => {
      const { peopleLists } = action.payload;
      state.peopleLists = peopleLists;
    },
    loadPeopleListsStubs: (state, action: PayloadAction<{ stubs: PeopleListStub[] }>) => {
      const { stubs } = action.payload;
      state.stubs = stubs;
    },
    setPeopleListStatus: (state, action: PayloadAction<{ peopleListId: string; status: PeopleListStatus }>) => {
      const { peopleListId, status } = action.payload;
      const peopleList = state.peopleLists.find((list) => list.id === peopleListId);
      if (peopleList) {
        peopleList.status = status;
      }
    },
    addPeopleAndCompaniesToList: (
      state,
      action: PayloadAction<{
        peopleListId: string;
        people: FrontendPerson[];
        companiesFound: FrontendCompany[];
        companiesNotFound: FrontendCompany[];
      }>
    ) => {
      const { peopleListId, people, companiesFound, companiesNotFound } = action.payload;
      const peopleList = state.peopleLists.find((list) => list.id === peopleListId);
      if (peopleList) {
        const uniquePeople = people.filter((person) => !peopleList.people.some((existingPerson) => existingPerson.email === person.email));
        peopleList.people.push(...uniquePeople);

        const uniqueCompaniesFound = companiesFound.filter(
          (company) => !peopleList.companies_found.some((existingCompany) => existingCompany.domain === company.domain)
        );
        peopleList.companies_found.push(...uniqueCompaniesFound);

        const uniqueCompaniesNotFound = companiesNotFound.filter(
          (company) => !peopleList.companies_not_found.some((existingCompany) => existingCompany.domain === company.domain)
        );
        peopleList.companies_not_found.push(...uniqueCompaniesNotFound);

        // console.log("People List", peopleList.id, "now has", peopleList.people.length, "people and", peopleList.companies_found.length, "companies found and", peopleList.companies_not_found.length, "companies not found");
      }
    },
    createPeopleList: (
      state,
      action: PayloadAction<{
        title: string;
        people?: FrontendPerson[];
        companiesFound?: FrontendCompany[];
        companiesNotFound?: FrontendCompany[];
      }>
    ) => {
      const { title, people, companiesFound, companiesNotFound } = action.payload;
      // should generate a random 24-char hex string
      const peopleListId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
      const newPeopleList: PeopleList = {
        id: peopleListId,
        title,
        people: people || [],
        companies_found: companiesFound || [],
        companies_not_found: companiesNotFound || [],
        status: PeopleListStatus.Stable
      };
      const newStub: PeopleListStub = {
        id: peopleListId,
        title,
        status: PeopleListStatus.Stable
      };
      state.peopleLists.push(newPeopleList);
      state.stubs.push(newStub);
      state.activePeopleListId = peopleListId;
    },
    removePeopleList: (state, action: PayloadAction<{ peopleListId: string }>) => {
      const { peopleListId } = action.payload;
      state.peopleLists = state.peopleLists.filter((list) => list.id !== peopleListId);
      state.stubs = state.stubs.filter((stub) => stub.id !== peopleListId);
    },
    removePersonFromList: (state, action: PayloadAction<{ peopleListId: string; index: number }>) => {
      const { peopleListId, index } = action.payload;
      const peopleList = state.peopleLists.find((list) => list.id === peopleListId);
      if (peopleList) {
        peopleList.people.splice(index, 1);
      }
    },
    editPeopleListTitle: (state, action: PayloadAction<{ peopleListId: string; title: string }>) => {
      const { peopleListId, title } = action.payload;
      const peopleList = state.peopleLists.find((list) => list.id === peopleListId);
      if (peopleList) {
        peopleList.title = title;
      }
      const stub = state.stubs.find((stub) => stub.id === peopleListId);
      if (stub) {
        stub.title = title;
      }
    }
  }
});

export const peopleListsUpdateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);
  const actionsToSync = ["peopleLists/removePersonFromList", "peopleLists/editPeopleListTitle", "peopleLists/addPeopleAndCompaniesToList"];

  if (actionsToSync.includes(action.type)) {
    const state = storeAPI.getState() as RootState;
    const { peopleListId } = action.payload;
    const peopleList = state.peopleLists.peopleLists.find((list) => list.id === peopleListId);
    console.log("peopleList", action.payload);
    if (peopleList && state.user.token) {
      console.log("Syncing People List:", peopleListId);
      updatePeopleList(state.user.token, peopleList);
    }
  } else if (action.type === "peopleLists/createPeopleList") {
    const state = storeAPI.getState() as RootState;
    // HACK: assume the newly created list is at the end of the list
    const peopleList = state.peopleLists.peopleLists[state.peopleLists.peopleLists.length - 1];
    if (peopleList && state.user.token) {
      console.log("Syncing People List:", peopleList.id);
      updatePeopleList(state.user.token, peopleList);
    }
  } else if (action.type === "peopleLists/removePeopleList") {
    const state = storeAPI.getState() as RootState;
    const { peopleListId } = action.payload;
    if (state.user.token) {
      deletePeopleList(state.user.token, peopleListId);
    }
  }
  return result;
};

// Action creators are generated for each case reducer function
export const { setActivePeopleList, loadPeopleList, createPeopleList, removePeopleList, removePersonFromList, editPeopleListTitle } =
  peopleListsSlice.actions;

export default peopleListsSlice.reducer;
