import React, { createContext, useState, useContext, ReactNode } from "react";

interface ScoredTaxonomyContextType {
  minimizedCategoryIds: string[];
  excludedCategoryIds: string[];
  betaWeights: Record<string, number>;
  alphaWeights: Record<string, number>;
  overallScoreBetaWeight: number;
  overallScoreAlphaWeight: number;
  setMinimized: (categoryId: string, minimized: boolean) => void;
  setExcluded: (categoryId: string, excluded: boolean) => void;
  setBetaWeight: (dimension: string, weight: number) => void;
  setAlphaWeight: (dimension: string, weight: number) => void;
  setOverallScoreBetaWeight: (weight: number) => void;
  setOverallScoreAlphaWeight: (weight: number) => void;
}

const ScoredTaxonomyContext = createContext<ScoredTaxonomyContextType | undefined>(undefined);

export const ScoredTaxonomyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [minimizedCategoryIds, setMinimizedCategoryIds] = useState<string[]>([]);
  const [excludedCategoryIds, setExcludedCategoryIds] = useState<string[]>([]);

  const [overallScoreBetaWeight, setOverallScoreBetaWeight] = useState<number>(1);
  const [overallScoreAlphaWeight, setOverallScoreAlphaWeight] = useState<number>(3);

  const [betaWeights, setBetaWeights] = useState<Record<string, number>>({
    "Market size": 1,
    "Ease of gaining market share": 1,
    "Moat (Ease of sustaining/defending market position)": 1,
    "Revenue stability": 1
  });

  // TODO: new alpha dimensions
  const [alphaWeights, setAlphaWeights] = useState<Record<string, number>>({
    customer_sentiment: 1,
    notable_customers: 1,
    web_and_social_presence: 1,
    partnerships_and_sales_channels: 1,
    product_offering_differentiation: 1,
    management_team_strengths_and_experience: 1,
    investor_strength: 1,
    signs_of_recent_growth: 1
  });

  const setMinimized = (categoryId: string, minimized: boolean) => {
    setMinimizedCategoryIds((prev) => (minimized ? [...prev, categoryId] : prev.filter((id) => id !== categoryId)));
  };

  const setExcluded = (categoryId: string, excluded: boolean) => {
    setExcludedCategoryIds((prev) => (excluded ? [...prev, categoryId] : prev.filter((id) => id !== categoryId)));
  };

  const setBetaWeight = (dimension: string, weight: number) => {
    setBetaWeights((prev) => ({ ...prev, [dimension]: weight }));
  };

  const setAlphaWeight = (dimension: string, weight: number) => {
    setAlphaWeights((prev) => ({ ...prev, [dimension]: weight }));
  };

  return (
    <ScoredTaxonomyContext.Provider
      value={{
        minimizedCategoryIds,
        excludedCategoryIds,
        betaWeights,
        alphaWeights,
        overallScoreBetaWeight,
        overallScoreAlphaWeight,
        setMinimized,
        setExcluded,
        setBetaWeight,
        setAlphaWeight,
        setOverallScoreBetaWeight,
        setOverallScoreAlphaWeight
      }}>
      {children}
    </ScoredTaxonomyContext.Provider>
  );
};

export const useScoredTaxonomy = () => {
  const context = useContext(ScoredTaxonomyContext);
  if (context === undefined) {
    throw new Error("useScoredTaxonomy must be used within a ScoredTaxonomyProvider");
  }
  return context;
};
