import React, { useState } from "react";
import { AppWindow, Building2, Plane, Store } from "lucide-react";
import { House } from "@phosphor-icons/react";
import { FilterHeader } from "./FilterHeader";
import { CompanyTag } from "@/services/autogen/api";

const allTags: CompanyTag[] = ["b2b", "b2c", "marketplace", "software", "hardware"];

// Helper for each tag's icon, label, and styling
export function getTagProps(tag: CompanyTag) {
  switch (tag) {
    case "b2b":
      return {
        icon: <Building2 size={14} />,
        label: "B2B",
        styling: "bg-colors-yellow-100 text-colors-yellow-800 border-colors-yellow-300"
      };
    case "b2c":
      return {
        icon: <House size={14} />,
        label: "B2C",
        styling: "bg-colors-brand-100 text-colors-brand-800 border-colors-brand-300"
      };
    case "marketplace":
      return {
        icon: <Store size={14} />,
        label: "Marketplace",
        styling: "bg-colors-success-100 text-colors-success-800 border-colors-success-300"
      };
    case "software":
      return {
        icon: <AppWindow size={14} />,
        label: "Software",
        styling: "bg-colors-blue-100 text-colors-blue-800 border-colors-blue-300"
      };
    case "hardware":
      return {
        icon: <Plane size={14} />,
        label: "Hardware",
        styling: "bg-colors-gray-modern-100 text-colors-gray-modern-800 border-colors-gray-modern-300"
      };
    default:
      return {
        icon: <Building2 size={14} />,
        label: "Unknown",
        styling: "bg-colors-gray-100 text-colors-gray-800 border-colors-gray-300"
      };
  }
}

function DraggableTag({ tag, onDragStart, viewOnly }: { tag: CompanyTag; onDragStart: (tag: CompanyTag) => void; viewOnly?: boolean }) {
  const { icon, label, styling } = getTagProps(tag);

  return (
    <div
      draggable={!viewOnly}
      onDragStart={() => !viewOnly && onDragStart(tag)}
      className={`${!viewOnly ? "cursor-move" : ""} rounded-full px-2 py-1 flex flex-row items-center gap-1 border text-xs ${styling}`}>
      {icon} {label}
    </div>
  );
}

export function CompanyTagFilterInput({
  filter_include,
  filter_exclude,
  setFilter,
  viewOnly
}: {
  filter_include?: CompanyTag[] | null;
  filter_exclude?: CompanyTag[] | null;
  setFilter: (filter_include: CompanyTag[] | null, filter_exclude: CompanyTag[] | null) => void;
  viewOnly?: boolean;
}) {
  // Track which tags are included, excluded, and which one's being dragged
  const [draggingTag, setDraggingTag] = useState<CompanyTag | null>(null);

  // Update parent filter only when include changes
  const updateParentFilter = (newInclude: CompanyTag[] | null, newExclude: CompanyTag[] | null) => {
    setFilter(newInclude, newExclude);
  };

  // "Available" = any tag not in include or exclude
  const assigned = [...(filter_include || []), ...(filter_exclude || [])];
  const available = allTags.filter((tag) => !assigned.includes(tag));

  // Drag event handlers
  const onDragStart = (tag: CompanyTag) => {
    setDraggingTag(tag);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // Drop handlers
  const onDropAvailable = () => {
    if (draggingTag) {
      updateParentFilter(
        (filter_include || []).filter((t) => t !== draggingTag),
        (filter_exclude || []).filter((t) => t !== draggingTag)
      );
      setDraggingTag(null);
    }
  };

  const onDropInclude = () => {
    if (draggingTag) {
      const newInclude = Array.from(new Set([...(filter_include || []), draggingTag]));
      const newExclude = (filter_exclude || []).filter((t) => t !== draggingTag);
      updateParentFilter(newInclude, newExclude);
      setDraggingTag(null);
    }
  };

  const onDropExclude = () => {
    if (draggingTag) {
      const newExclude = Array.from(new Set([...(filter_exclude || []), draggingTag]));
      const newInclude = (filter_include || []).filter((t) => t !== draggingTag);
      updateParentFilter(newInclude, newExclude);
      setDraggingTag(null);
    }
  };

  return (
    <div className="flex flex-col">
      <FilterHeader
        title="Company tags"
        onDelete={() => {
          setFilter(null, null);
        }}
        onAdd={() => {
          // Add all available tags to the include filter
          updateParentFilter([], []);
        }}
        isActive={filter_include !== null || filter_exclude !== null}
        viewOnly={viewOnly}
      />

      {/* Only show content when filter is defined */}
      {(filter_include !== null || filter_exclude !== null) && (
        <div className="flex flex-col gap-4">
          {/* Available Tags (Top Row) - now with dotted border */}
          <div
            onDragOver={!viewOnly ? onDragOver : undefined}
            onDrop={!viewOnly ? onDropAvailable : undefined}
            className="border-dashed border p-2 rounded-md min-h-[40px]">
            <div className="flex flex-wrap gap-2">
              {available.map((tag) => (
                <DraggableTag key={tag} tag={tag} onDragStart={onDragStart} viewOnly={viewOnly} />
              ))}
            </div>
          </div>

          {/* Two columns: Include (left), Exclude (right) */}
          <div className="flex flex-row gap-2">
            <div
              onDragOver={!viewOnly ? onDragOver : undefined}
              onDrop={!viewOnly ? onDropInclude : undefined}
              className="border p-2 rounded-lg w-1/2 min-h-[80px] bg-white shadow-sm">
              <h2 className="font-medium mb-2 text-sm text-gray-700 flex items-center gap-2">
                <span className="inline-block w-2 h-2 rounded-full bg-green-500"></span>
                Include any of these
              </h2>
              <div className="flex flex-wrap gap-2">
                {(filter_include || []).length > 0 ? (
                  (filter_include || []).map((tag) => <DraggableTag key={tag} tag={tag} onDragStart={onDragStart} viewOnly={viewOnly} />)
                ) : (
                  <span className="text-gray-400 text-sm">Drag tags here</span>
                )}
              </div>
            </div>

            <div
              onDragOver={!viewOnly ? onDragOver : undefined}
              onDrop={!viewOnly ? onDropExclude : undefined}
              className="border p-2 rounded-lg w-1/2 min-h-[80px] bg-white shadow-sm">
              <h2 className="font-medium mb-2 text-sm text-gray-700 flex items-center gap-2">
                <span className="inline-block w-2 h-2 rounded-full bg-red-500"></span>
                Exclude all of these
              </h2>
              <div className="flex flex-wrap gap-2">
                {(filter_exclude || []).length > 0 ? (
                  (filter_exclude || []).map((tag) => <DraggableTag key={tag} tag={tag} onDragStart={onDragStart} viewOnly={viewOnly} />)
                ) : (
                  <span className="text-gray-400 text-sm">Drag tags here</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
