import { createAsyncThunk, createSlice, Middleware, PayloadAction } from "@reduxjs/toolkit";
import {
  FrontendCompanySearchInputs,
  FrontendCompanySearchInstructions,
  FrontendCompanySearchParameters,
  FrontendCompanySearchScoringInstructions,
  FrontendFullCompanySearch,
  FrontendFullCompanySearchStub
} from "@/services/autogen";
import {
  deprCompanySearchInputsToInstructions,
  deprDeleteCompanySearch,
  deprExpandCompanySearch,
  deprGetCompanySearch,
  deprGetCompanySearches,
  deprGetCompanySearchStubs,
  deprRerunCompanySearch,
  deprRunCompanySearch,
  deprRunCompanySearchFromInputs,
  deprUpdateCompanySearchTitle
} from "@/services/brain-api.service";
import { setActiveView, View } from "./views";
import { RootState } from "./store";
import { toast } from "sonner";

export enum CompanySearchCreationStatus {
  Entrypoint = "entrypoint",
  Inputs = "inputs",
  Instructions = "instructions",
  Loading = "loading"
}

interface DeprCompanySearchesState {
  searchInputs?: FrontendCompanySearchInputs;
  searchInstructions?: FrontendCompanySearchInstructions;
  dialogOpen: boolean;
  searches: FrontendFullCompanySearch[];
  stubs: FrontendFullCompanySearchStub[];
  activeSearchId: string | null;
  creationStatus: CompanySearchCreationStatus;
  searchesStartingToRunHack: string[]; // HACK: because expand/rerun takes so long to return first new workflow and we want immediate feedback for the user
}

const initialState: DeprCompanySearchesState = {
  searches: [],
  stubs: [],
  activeSearchId: null,
  creationStatus: CompanySearchCreationStatus.Entrypoint,
  dialogOpen: false,
  searchesStartingToRunHack: []
};

export const deprCompanySearchInputsToInstructionsThunk = createAsyncThunk(
  "companySearches/inputsToInstructions",
  async (thunkPayload: { token: string; inputs: FrontendCompanySearchInputs }, thunkAPI) => {
    const { token, inputs } = thunkPayload;
    thunkAPI.dispatch(deprSetCompanySearchCreationStatus(CompanySearchCreationStatus.Loading));
    deprCompanySearchInputsToInstructions(token, inputs).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(deprCompanySearchesSlice.actions.updateGlobalSearchInstructions({ instructions: response.data.instructions }));
        thunkAPI.dispatch(deprSetCompanySearchCreationStatus(CompanySearchCreationStatus.Instructions));
      }
    });
  }
);

export const deprRunCompanySearchThunk = createAsyncThunk(
  "companySearches/runCompanySearch",
  async (thunkPayload: { token: string; instructions: FrontendCompanySearchInstructions }, thunkAPI) => {
    const { token, instructions } = thunkPayload;
    deprRunCompanySearch(token, instructions)
      .then((response) => {
        if (response.status === 200) {
          const search = response.data;
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadSearch({ search }));
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.deprSetActiveCompanySearch(search.search_id));
          thunkAPI.dispatch(setActiveView(View.DeprCompanySearch));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough search credits");
        } else {
          toast.error("Failed to run search");
        }
      });
  }
);

export const deprRunCompanySearchFromInputsThunk = createAsyncThunk(
  "companySearches/runCompanySearchFromInputs",
  async (thunkPayload: { token: string; inputs: FrontendCompanySearchInputs }, thunkAPI) => {
    const { token, inputs } = thunkPayload;
    toast.info("Running search...");
    deprRunCompanySearchFromInputs(token, inputs)
      .then((response) => {
        if (response.status === 200) {
          const search = response.data;
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadSearch({ search }));
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.deprSetActiveCompanySearch(search.search_id));
          thunkAPI.dispatch(setActiveView(View.DeprCompanySearch));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough search credits");
        } else {
          toast.error("Failed to run search");
        }
      });
  }
);

export const deprRerunCompanySearchThunk = createAsyncThunk(
  "companySearches/rerunCompanySearch",
  async (thunkPayload: { token: string; search_id: string; instructions: FrontendCompanySearchInstructions }, thunkAPI) => {
    const { token, search_id, instructions } = thunkPayload;
    thunkAPI.dispatch(deprAddSearchStartingToRunHack(search_id));
    deprRerunCompanySearch(token, search_id, instructions)
      .then((response) => {
        if (response.status === 200) {
          const search = response.data;
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadSearch({ search }));
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.removeSearchStartingToRunHack(search_id));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough search credits");
        } else {
          toast.error("Failed to rerun search");
        }
      });
  }
);

export const deprExpandCompanySearchThunk = createAsyncThunk(
  "companySearches/expandCompanySearch",
  async (thunkPayload: { token: string; search_id: string; expand_by: number }, thunkAPI) => {
    const { token, search_id, expand_by } = thunkPayload;
    thunkAPI.dispatch(deprAddSearchStartingToRunHack(search_id));
    deprExpandCompanySearch(token, search_id, expand_by)
      .then((response) => {
        if (response.status === 200) {
          const search = response.data;
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadSearch({ search }));
          thunkAPI.dispatch(deprCompanySearchesSlice.actions.removeSearchStartingToRunHack(search_id));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough search credits");
        } else {
          toast.error("Failed to run search");
        }
      });
  }
);

export const deprGetAllCompanySearchesThunk = createAsyncThunk(
  "companySearches/getAllSearches",
  async (thunkPayload: { token: string }, thunkAPI) => {
    const { token } = thunkPayload;
    deprGetCompanySearches(token).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadSearches({ searches: response.data }));
      }
    });
  }
);

export const deprGetAllCompanySearchStubsThunk = createAsyncThunk(
  "companySearches/getAllSearchStubs",
  async (thunkPayload: { token: string }, thunkAPI) => {
    const { token } = thunkPayload;
    deprGetCompanySearchStubs(token).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadStubs({ stubs: response.data }));
      }
    });
  }
);

export const deprGetCompanySearchThunk = createAsyncThunk(
  "companySearches/getCompanySearch",
  async (thunkPayload: { token: string; search_id: string }, thunkAPI) => {
    const { token, search_id } = thunkPayload;
    deprGetCompanySearch(token, search_id).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(deprCompanySearchesSlice.actions.loadSearch({ search: response.data }));
      }
    });
  }
);

export const deprDeleteCompanySearchThunk = createAsyncThunk(
  "companySearches/deleteCompanySearch",
  async (thunkPayload: { token: string; search_id: string }, thunkAPI) => {
    const { token, search_id } = thunkPayload;
    deprDeleteCompanySearch(token, search_id).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(deprCompanySearchesSlice.actions.deleteSearch(search_id));
      }
    });
  }
);

const deprCompanySearchesSlice = createSlice({
  name: "deprCompanySearches",
  initialState,
  reducers: {
    deprSetActiveCompanySearch: (state, action: PayloadAction<string>) => {
      state.activeSearchId = action.payload;
    },
    deprSetCompanySearchCreationStatus: (state, action: PayloadAction<CompanySearchCreationStatus>) => {
      state.creationStatus = action.payload;
    },
    deprUpdateGlobalSearchInputs: (state, action: PayloadAction<{ inputs?: FrontendCompanySearchInputs }>) => {
      const { inputs } = action.payload;
      state.searchInputs = inputs;
    },
    updateGlobalSearchInstructions: (state, action: PayloadAction<{ instructions?: FrontendCompanySearchInstructions }>) => {
      const { instructions } = action.payload;
      state.searchInstructions = instructions;
    },
    setCompanySearchDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.dialogOpen = action.payload;
    },
    // not used. we're just overwriting all of the parameters
    updateGlobalSearchInstructionsWithDefaultParameters: (
      state,
      action: PayloadAction<{ parameters: FrontendCompanySearchParameters }>
    ) => {
      const { parameters } = action.payload;
      if (state.searchInstructions == null) {
        return;
      }
      if (parameters.company_type_filter != null) {
        state.searchInstructions.parameters.company_type_filter = parameters.company_type_filter;
      }
      if (parameters.hq_country_filter != null) {
        state.searchInstructions.parameters.hq_country_filter = parameters.hq_country_filter;
      }
      if (parameters.numerical_filters.length > 0) {
        // TODO: merge numerical filters
        state.searchInstructions.parameters.numerical_filters = parameters.numerical_filters;
      }
      if (parameters.hype_score_preference != null) {
        state.searchInstructions.parameters.hype_score_preference = parameters.hype_score_preference;
      }
      if (parameters.product_score_preference != null) {
        state.searchInstructions.parameters.product_score_preference = parameters.product_score_preference;
      }
      if (parameters.tech_score_preference != null) {
        state.searchInstructions.parameters.tech_score_preference = parameters.tech_score_preference;
      }
      if (parameters.growth_score_preference != null) {
        state.searchInstructions.parameters.growth_score_preference = parameters.growth_score_preference;
      }
    },
    loadSearch: (state, action: PayloadAction<{ search: FrontendFullCompanySearch }>) => {
      const { search } = action.payload;
      const stub = {
        search_id: search.search_id,
        title: search.title,
        created_at_timestamp: search.created_at_timestamp,
        running_status: search.running_status,
        viewed: search.viewed
      };
      const existingSearchIndex = state.searches.findIndex((s) => s.search_id === search.search_id);
      if (existingSearchIndex === -1) {
        // Search not found, add it to the array
        state.searches.push(search);
      } else {
        // Search found, update it
        state.searches[existingSearchIndex] = search;
      }
      const existingStubIndex = state.stubs.findIndex((s) => s.search_id === search.search_id);
      if (existingStubIndex === -1) {
        state.stubs.push(stub);
      } else {
        state.stubs[existingStubIndex] = stub;
      }
    },
    loadSearches: (state, action: PayloadAction<{ searches: FrontendFullCompanySearch[] }>) => {
      state.searches = action.payload.searches;
    },
    loadStubs: (state, action: PayloadAction<{ stubs: FrontendFullCompanySearchStub[] }>) => {
      state.stubs = action.payload.stubs;
    },
    deleteSearch: (state, action: PayloadAction<string>) => {
      state.searches = state.searches.filter((search) => search.search_id !== action.payload);
      state.stubs = state.stubs.filter((stub) => stub.search_id !== action.payload);
      if (state.activeSearchId === action.payload) {
        state.activeSearchId = null;
      }
    },
    editCompanySearchTitle: (state, action: PayloadAction<{ searchId: string; title: string }>) => {
      const { searchId, title } = action.payload;
      const search = state.searches.find((s) => s.search_id === searchId);
      if (search) {
        search.title = title;
      }
      const stub = state.stubs.find((s) => s.search_id === searchId);
      if (stub) {
        stub.title = title;
      }
    },
    updateCompanySearchScoringInstructions: (
      state,
      action: PayloadAction<{ search_id: string; scoring_instructions: FrontendCompanySearchScoringInstructions }>
    ) => {
      // TODO: this needs to be persisted to the backend
      const { search_id, scoring_instructions } = action.payload;
      const search = state.searches.find((s) => s.search_id === search_id);
      if (search) {
        search.instructions.scoring_instructions = scoring_instructions;
      }
    },
    addSearchStartingToRunHack: (state, action: PayloadAction<string>) => {
      state.searchesStartingToRunHack.push(action.payload);
    },
    removeSearchStartingToRunHack: (state, action: PayloadAction<string>) => {
      state.searchesStartingToRunHack = state.searchesStartingToRunHack.filter((id) => id !== action.payload);
    }
  }
});

export const deprCompanySearchesUpdateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (action.type === "companySearches/editCompanySearchTitle") {
    const state = storeAPI.getState() as RootState;
    const { searchId, title } = action.payload;
    const search = state.deprCompanySearches.searches.find((s) => s.search_id === searchId);

    if (search && state.user.token) {
      deprUpdateCompanySearchTitle(state.user.token, searchId, title);
    }
  }

  return result;
};

export const {
  deprSetActiveCompanySearch: deprSetActiveCompanySearch,
  loadSearch: deprLoadSearch,
  loadSearches: deprloadSearches,
  deleteSearch: deprdeleteSearch,
  editCompanySearchTitle: depreditCompanySearchTitle,
  deprUpdateGlobalSearchInputs: deprUpdateGlobalSearchInputs,
  updateGlobalSearchInstructions: deprUpdateGlobalSearchInstructions,
  deprSetCompanySearchCreationStatus: deprSetCompanySearchCreationStatus,
  setCompanySearchDialogOpen: deprSetCompanySearchDialogOpen,
  updateCompanySearchScoringInstructions: deprUpdateCompanySearchScoringInstructions,
  addSearchStartingToRunHack: deprAddSearchStartingToRunHack,
  removeSearchStartingToRunHack: deprRemoveSearchStartingToRunHack
} = deprCompanySearchesSlice.actions;

export default deprCompanySearchesSlice.reducer;
