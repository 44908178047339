import { FrontendCompany } from "@/services/autogen";
import { Box, Cpu, ExternalLink, Flame } from "lucide-react";
import { CompanyLogo } from "../../ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl, formatAbsoluteMagnitude } from "@/lib/utils";
import { WebTrafficDisplay } from "../../company-profile/WebTraffic";
import { CompanyTypeChip } from "../../company-table/common/CellComponents";
import { SummaryBox } from "./SummaryBox";
import { PercentValueText } from "@/lib/utilComponents";
import { Button } from "@/components/ui/button";
import { ChartLineUp, IdentificationCard } from "@phosphor-icons/react";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { useAppDispatch } from "@/store/hooks";
import { setActiveView, View } from "@/store/views";
import { setActiveCompanyProfile } from "@/store/companyProfiles";
import { fetchUserRunsAvailableThunk } from "@/store/user";
import posthog from "posthog-js";
import { fetchCompanyTearsheetStreamingThunk } from "@/store/companyProfiles";
import { useAuth } from "@clerk/clerk-react";
import { ScoreChip } from "@/components/SourceCompanies/GiantTable/SourceCompaniesTable";
import { getTagProps } from "@/components/workflow-inputs/CompanyTags";
import { CompanyTag } from "@/services/autogen/api";

const DerivativeMetricBox = ({ company }: { company: FrontendCompany }) => {
  return (
    <div className="flex flex-col gap-2 border border-colors-border-border-primary bg-white rounded-xl p-3 text-colors-text-text-secondary-(700)">
      {/* <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">Meticulate Scores</div> */}
      <div className="flex flex-row items-start gap-2 justify-between w-full">
        <SimpleTooltip text="How much hype is there for this company (1 to 5)">
          <div className="flex flex-row gap-2 items-center">
            <Flame size={20} />
            <ScoreChip score={company.hype_rating} minScore={0} maxScore={5} />
          </div>
        </SimpleTooltip>
        <SimpleTooltip text="How fast is this company growing (-3 to 3)">
          <div className="flex flex-row gap-2 items-center">
            <ChartLineUp size={20} />
            <ScoreChip score={company.growth_score} minScore={-3} maxScore={3} />
          </div>
        </SimpleTooltip>
        <SimpleTooltip text="How sophisticated is this company's tech (1 to 10)">
          <div className="flex flex-row gap-2 items-center">
            <Cpu size={20} />
            <ScoreChip score={company.technology_score} minScore={0} maxScore={10} />
          </div>
        </SimpleTooltip>
        <SimpleTooltip text="Is the company focused on products (10) or services (1)">
          <div className="flex flex-row gap-2 items-center">
            <Box size={20} />
            <ScoreChip score={company.product_score} minScore={0} maxScore={10} />
          </div>
        </SimpleTooltip>
      </div>
    </div>
  );
};

export const ExtensibleVerticalCompanyProfile = ({
  company,
  shareableView,
  children
}: {
  company?: FrontendCompany;
  shareableView?: boolean;
  children?: React.ReactNode;
}) => {
  if (company === undefined) {
    return (
      <div className="text-lg self-center text-colors-brand-900 animate__animated animate__pulse animate__infinite animate__delay-5s">
        Click on a company to see more details!
      </div>
    );
  }
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col items-start w-full">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-center w-full justify-between">
            <div className="flex flex-row py-2 items-center">
              <CompanyLogo name={company.name} website={company.domain} logo={company.logo_url} className="mr-2" />
              <div className="flex flex-col">
                <div className="text-2xl text-colors-brand-900 font-semibold">{company.name}</div>
                <div className="flex flex-row items-center gap-2 mt-1">
                  <div className="text-colors-text-text-tertiary-(600) font-semibold">{company.stock_ticker}</div>
                  {company.domain && (
                    <a
                      href={ensureAbsoluteUrl(company.domain)}
                      className="flex flex-row hover:cursor-pointer underline text-blue-800"
                      target="_blank"
                      rel="noopener noreferrer">
                      <ExternalLink size={16} />
                    </a>
                  )}
                  {company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-4 w-4" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <SimpleTooltip text={`${shareableView ? "Log in to view full company profile" : "View full company profile"}`}>
              {/* Wrapper div to allow tooltip to show up even when button is disabled */}
              <div className="inline-block">
                <Button
                  variant="outline"
                  disabled={shareableView}
                  size="sm"
                  onClick={async () => {
                    const token = await getToken();
                    if (token !== null) {
                      posthog.capture("user_fetched_company_tearsheet", { domain: company.domain });
                      dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: company.domain }));
                      dispatch(setActiveCompanyProfile({ companyProfileId: company.domain }));
                      dispatch(setActiveView(View.Company));
                      dispatch(fetchUserRunsAvailableThunk(token));
                    }
                  }}>
                  <IdentificationCard size={16} />
                </Button>
              </div>
            </SimpleTooltip>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          {/* First Row - 4 columns */}
          <div className="grid grid-cols-4 text-colors-text-text-tertiary-(600) w-full">
            <div className="flex flex-col">
              <div className="font-thin">Founded</div>
              <div>{company.year_founded ?? "?"}</div>
            </div>

            <div className="flex flex-col">
              <div className="font-thin">Employees</div>
              <div className="text-colors-text-text-secondary-(700) flex flex-row gap-2 items-center">
                {employeeRangeText(company.employee_min, company.employee_max, company.employee_guess)}
                <span className="text-xs">
                  <PercentValueText value={company.employee_count_90day_growth} positiveGreen includePlusSign />
                </span>
              </div>
            </div>
            {company.web_traffic && (
              <div className="flex flex-col">
                <div className="font-thin">Web Traffic</div>
                <div>
                  <WebTrafficDisplay webTraffic={company.web_traffic} />
                </div>
              </div>
            )}
            <div className="flex flex-col">
              <div className="font-thin flex flex-row gap-1 items-center">
                <img src="/linkedin.png" className="h-4 w-4 grayscale" /> Followers
              </div>
              <div className="text-colors-text-text-secondary-(700) flex flex-row gap-2 items-center">
                {formatAbsoluteMagnitude(company.linkedin_followers)}
                <span className="text-xs">
                  <PercentValueText value={company.linkedin_followers_90day_growth} positiveGreen includePlusSign />
                </span>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="text-colors-text-text-tertiary-(600) grid grid-cols-4 w-full">
            <div className="flex flex-col col-span-1">
              <div className="font-thin">Ownership</div>
              <div className="text-xs">
                <CompanyTypeChip type={company.company_type} />
              </div>
            </div>

            <div className="flex-col inline-block w-auto col-span-2">
              <div className="font-thin">Headquarters</div>
              <div className="text-colors-text-text-secondary-(700) text-sm inline-block">{company.hq_str ?? "?"}</div>
            </div>

            <div className="flex flex-col flex-grow col-span-1">
              <div className="font-thin">Tags</div>
              <div className="flex flex-wrap gap-1">
                {company.tags && company.tags.length > 0 ? (
                  company.tags.map((tag: CompanyTag, index: number) => {
                    const { icon, label, styling } = getTagProps(tag);
                    return (
                      <div key={index} className={`flex items-center ${styling} rounded-full px-2 py-1 mr-1`}>
                        {company.tags && company.tags.length === 1 ? (
                          <>
                            {icon}
                            <span className="ml-1 text-xs">{label}</span>
                          </>
                        ) : (
                          <SimpleTooltip text={label}>
                            <div className="flex items-center">{icon}</div>
                          </SimpleTooltip>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <span>No Tags</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DerivativeMetricBox company={company} />
      <SummaryBox company={company} />
      {children}
    </div>
  );
};
