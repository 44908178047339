import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { ArrowRight, Import, Info, Save, Undo2 } from "lucide-react";

import { Button } from "../../ui/button";
import { Funnel, Ranking, Sparkle } from "@phosphor-icons/react";
import { useState } from "react";

import { toast } from "sonner";
import posthog from "posthog-js";
import {
  CompanySearchCreationStatus,
  deprCompanySearchInputsToInstructionsThunk,
  deprRunCompanySearchFromInputsThunk,
  deprSetCompanySearchCreationStatus,
  deprUpdateGlobalSearchInputs
} from "@/store/deprCompanySearches";
import { Label } from "../../ui/label";
import { CompanyDomainAutocomplete } from "../../workflow-inputs/companyWebsiteAutocomplete";
import { AutosizedTextarea } from "../../ui/textarea";

import { isValidUrl } from "@/lib/utils";
import { SimpleTooltip } from "../../ui/tooltip";
import { setUserDefaultCompanySearchParametersThunk } from "@/store/user";
import * as Accordion from "@radix-ui/react-accordion";
import { CustomAccordionContent, CustomAccordionTrigger } from "../../ui/accordion";
import { CompanySearchParametersEditor } from "./Qualification";
import { Switch } from "@/components/ui/switch";
import { FilterHeader } from "@/components/workflow-inputs/FilterHeader";
import { ToggleGroupItem } from "@/components/ui/toggle-group";
import { ToggleGroup } from "@/components/ui/toggle-group";

const SingleQuerySearchInputsEditor = () => {
  const companySearchInputs = useAppSelector((state) => state.deprCompanySearches.searchInputs);
  const dispatch = useAppDispatch();
  if (companySearchInputs == null) return null;
  return (
    <div className="flex flex-col gap-2 mt-4 text-xs">
      <Label>Describe the types of companies you want to find</Label>
      <AutosizedTextarea
        className="max-h-[500px] w-full"
        placeholder="e.g. 'peer-to-peer furniture marketplaces in Europe' or 'AI agents for accounting'"
        value={companySearchInputs.search_query ?? ""}
        onChange={(e) => {
          dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, search_query: e.target.value } }));
        }}
      />
    </div>
  );
};

const FindSimilarSearchInputsEditor = () => {
  const companySearchInputs = useAppSelector((state) => state.deprCompanySearches.searchInputs);
  const dispatch = useAppDispatch();
  if (companySearchInputs == null) return null;
  return (
    <div className="flex flex-col gap-2 mt-4 text-xs">
      <Label>Pick a target company by domain</Label>
      <CompanyDomainAutocomplete
        disableLabel
        placeholder="e.g. apple.com"
        textValue={companySearchInputs.exemplars[0]}
        onChange={(e) => {
          dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, exemplars: [e] } }));
        }}
        onSubmit={({ value }) => {
          dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, exemplars: [value] } }));
        }}
      />
    </div>
  );
};

export const VolumeRequestedToggleGroup = ({
  volumeRequested,
  setVolumeRequested
}: {
  volumeRequested: number;
  setVolumeRequested: (volumeRequested: number) => void;
}) => {
  return (
    <ToggleGroup
      variant="outline"
      type="single"
      size="sm"
      value={volumeRequested.toString()}
      className="gap-2"
      onValueChange={(value) => setVolumeRequested(parseInt(value))}>
      <ToggleGroupItem value="10">10</ToggleGroupItem>
      <ToggleGroupItem value="50">50</ToggleGroupItem>
      <ToggleGroupItem value="200">200</ToggleGroupItem>
    </ToggleGroup>
  );
};

export const CompanySearchInputsEditor = ({ closeDialog }: { closeDialog: () => void }) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.token);
  const companySearchInputs = useAppSelector((state) => state.deprCompanySearches.searchInputs);
  const showControlSwitch = useAppSelector((state) => state.deprCompanySearches.stubs.length) > 1;
  if (companySearchInputs == null) return null;
  const entrypointEditor =
    companySearchInputs.entrypoint === "single_query" ? <SingleQuerySearchInputsEditor /> : <FindSimilarSearchInputsEditor />;
  const checkIfSingleQueryInputsValid = () => {
    const searchQuery = companySearchInputs.search_query;
    if (!searchQuery || searchQuery.length === 0) {
      toast.error("Please enter a search query");
      return false;
    }
    return true;
  };
  const checkIfFindSimilarInputsValid = () => {
    const companyDomain = companySearchInputs.exemplars[0];
    if (!companyDomain || !isValidUrl(companyDomain)) {
      toast.error("Please enter a valid company domain");
      return false;
    }
    return true;
  };
  const [skipInstructions, setSkipInstructions] = useState(true);
  const defaultParameters = useAppSelector((state) => state.user.defaultCompanySearchParameters);

  const checkIfInputsValid = () => {
    return companySearchInputs.entrypoint === "single_query" ? checkIfSingleQueryInputsValid() : checkIfFindSimilarInputsValid();
  };
  return (
    <div className="flex flex-col gap-2 md:w-[400px] w-[200px]">
      <div className="flex flex-col gap-2 items-end justify-end w-full">
        {showControlSwitch && (
          <div className="flex flex-row items-center gap-2">
            <SimpleTooltip text="If on, you'll be able to see the full search instructions and modify them before running the search">
              <div className="text-xs flex flex-row items-center gap-1 text-colors-gray-modern-400">
                More control? <Info size={12} />
              </div>
            </SimpleTooltip>
            <Switch
              checked={!skipInstructions}
              onCheckedChange={(checked) => {
                if (checked === true) {
                  setSkipInstructions(false);
                } else {
                  setSkipInstructions(true);
                }
              }}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row items-center gap-2 w-full justify-end">
        <SimpleTooltip text="Target number of companies to bring back. Searches with more volume can take much longer (~15 min)">
          <div className="text-xs flex flex-row items-center gap-1 text-colors-gray-modern-400">
            Volume <Info size={12} />
          </div>
        </SimpleTooltip>
        <VolumeRequestedToggleGroup
          volumeRequested={companySearchInputs.parameters?.initial_volume_requested ?? 50}
          setVolumeRequested={(volumeRequested) => {
            dispatch(
              deprUpdateGlobalSearchInputs({
                inputs: {
                  ...companySearchInputs,
                  parameters: {
                    ...(companySearchInputs.parameters ?? { numerical_filters: [] }),
                    initial_volume_requested: volumeRequested
                  }
                }
              })
            );
          }}
        />
      </div>
      <div className="flex flex-row  w-full justify-between gap-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => dispatch(deprSetCompanySearchCreationStatus(CompanySearchCreationStatus.Entrypoint))}>
          <Undo2 size={20} />
          Back
        </Button>
        {skipInstructions ? (
          <Button
            size="sm"
            // variant="outline"
            onClick={() => {
              if (token == null) return;
              if (!checkIfInputsValid()) return;
              posthog.capture("user_ran_company_search_from_inputs", {
                inputs: companySearchInputs
              });
              dispatch(deprRunCompanySearchFromInputsThunk({ token: token, inputs: companySearchInputs }));
              closeDialog();
              dispatch(deprSetCompanySearchCreationStatus(CompanySearchCreationStatus.Entrypoint));
            }}>
            Run Search
            <ArrowRight size={20} />
          </Button>
        ) : (
          <Button
            size="sm"
            // variant="outline"
            onClick={() => {
              if (token == null) return;
              if (!checkIfInputsValid()) return;
              posthog.capture("user_advanced_to_company_search_instructions", {
                inputs: companySearchInputs
              });
              dispatch(deprCompanySearchInputsToInstructionsThunk({ token: token, inputs: companySearchInputs }));
            }}>
            Next
            <ArrowRight size={20} />
          </Button>
        )}
      </div>

      <Accordion.Root type="single" className="mt-4" defaultValue="search" collapsible>
        <Accordion.Item value="search" className="p-2 border-colors-brand-200 border border-b-colors-gray-modern-200 rounded-t-lg">
          <CustomAccordionTrigger>
            <div className="text-lg font-medium flex flex-row items-center gap-2">
              <Sparkle size={24} />
              <span>Search</span>
            </div>
          </CustomAccordionTrigger>
          <CustomAccordionContent>{entrypointEditor}</CustomAccordionContent>
        </Accordion.Item>
        <Accordion.Item value="qualification" className="p-2 border-colors-blue-200 border border-y-0 group">
          <CustomAccordionTrigger>
            <div className="text-lg font-medium flex flex-row items-center gap-2">
              <Funnel size={24} />
              <span>Qualification</span>
              <div className="flex flex-row items-center gap-0 w-[100px] group-hover:visible invisible">
                {defaultParameters != null && (
                  <SimpleTooltip text="Import default parameters">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, parameters: defaultParameters } }));
                      }}>
                      <Import size={12} />
                    </Button>
                  </SimpleTooltip>
                )}
                <SimpleTooltip text="Save these parameters as a default">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (token == null || companySearchInputs.parameters == null) return;
                      dispatch(
                        setUserDefaultCompanySearchParametersThunk({
                          token: token,
                          parameters: companySearchInputs.parameters
                        })
                      );
                      toast.success("Saved parameters as default");
                    }}>
                    <Save size={12} />
                  </Button>
                </SimpleTooltip>
              </div>
            </div>
          </CustomAccordionTrigger>
          <CustomAccordionContent>
            <CompanySearchParametersEditor
              parameters={companySearchInputs.parameters ?? { numerical_filters: [] }}
              setParameters={(parameters) => {
                dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, parameters } }));
              }}
              qualificationCriteria={[]}
              setQualificationCriteria={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
              disableQualificationCriteria
            />
          </CustomAccordionContent>
        </Accordion.Item>
        <Accordion.Item value="scoring" className="p-2 border-colors-green-200 border border-t-colors-gray-modern-200 rounded-b-lg">
          <CustomAccordionTrigger>
            <div className="text-lg font-medium flex flex-row items-center gap-2">
              <Ranking size={24} />
              <span>Scoring</span>
            </div>
          </CustomAccordionTrigger>
          <CustomAccordionContent>
            <div className="flex flex-col items-start gap-2 mb-2">
              <FilterHeader
                title="Tech stack"
                infoText="Describe technologies the companies should have"
                onDelete={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, tech_stack_desired: undefined } }));
                }}
                onAdd={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, tech_stack_desired: "" } }));
                }}
                isActive={companySearchInputs.tech_stack_desired != null}
              />
              {companySearchInputs.tech_stack_desired != null && (
                <AutosizedTextarea
                  value={companySearchInputs.tech_stack_desired ?? ""}
                  placeholder="e.g. 'data warehouse', 'Google analytics', or 'Webflow' "
                  onChange={(e) => {
                    dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, tech_stack_desired: e.target.value } }));
                  }}
                />
              )}
              <FilterHeader
                title="Employee roles"
                infoText="Describe the employee roles the companies should have"
                onDelete={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, employee_roles_desired: undefined } }));
                }}
                onAdd={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, employee_roles_desired: "" } }));
                }}
                isActive={companySearchInputs.employee_roles_desired != null}
              />
              {companySearchInputs.employee_roles_desired != null && (
                <AutosizedTextarea
                  value={companySearchInputs.employee_roles_desired ?? ""}
                  placeholder="e.g. 'a Head of Delivery Operations' or 'Node.js developers'"
                  onChange={(e) => {
                    dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, employee_roles_desired: e.target.value } }));
                  }}
                />
              )}
              <FilterHeader
                title="Hiring activity"
                infoText="Describe the roles the companies should be hiring for"
                onDelete={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, hiring_activity_desired: undefined } }));
                }}
                onAdd={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, hiring_activity_desired: "" } }));
                }}
                isActive={companySearchInputs.hiring_activity_desired != null}
              />
              {companySearchInputs.hiring_activity_desired != null && (
                <AutosizedTextarea
                  value={companySearchInputs.hiring_activity_desired ?? ""}
                  placeholder="e.g. 'entry level devops engineers' or 'AEs in Germany'"
                  onChange={(e) => {
                    dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, hiring_activity_desired: e.target.value } }));
                  }}
                />
              )}
              <FilterHeader
                title="Custom scoring question"
                // infoText="WHat "
                onDelete={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, scoring_query: undefined } }));
                }}
                onAdd={() => {
                  dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, scoring_query: "" } }));
                }}
                isActive={companySearchInputs.scoring_query != null}
              />
              {companySearchInputs.scoring_query != null && (
                <AutosizedTextarea
                  value={companySearchInputs.scoring_query ?? ""}
                  placeholder="e.g. 'number of metro areas that they operate in'"
                  onChange={(e) => {
                    dispatch(deprUpdateGlobalSearchInputs({ inputs: { ...companySearchInputs, scoring_query: e.target.value } }));
                  }}
                />
              )}
            </div>
          </CustomAccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};
