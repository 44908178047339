/* eslint-disable @typescript-eslint/no-empty-function */
import { FrontendTaxonomy } from "@/services/autogen";
import { useLoaderData } from "react-router-dom";
import { useAppDispatch } from "@/store/hooks";
import Header from "../GenericWorkflows/Header";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../ui/resizable";
import { ImperativePanelHandle } from "react-resizable-panels";
import { useRef } from "react";
import { RightSection } from "../GenericWorkflows/RightSection";
import { updateLeftbar, updateRightbar } from "@/store/sidebars";
import { Toaster } from "sonner";
import { loadTaxonomy, setActiveTaxonomyId } from "@/store/taxonomies";
import { TaxonomyViewer } from "./TaxonomyViewer";
import { setActiveView, View } from "@/store/views";

export function StaticTaxonomyViewer() {
  const dispatch = useAppDispatch();

  const taxonomy = useLoaderData() as FrontendTaxonomy | null;

  // const activeWorkflow = useAppSelector((state) => state.workflows.workflows.find((w) => w.id === workflow?.id));

  if (taxonomy == null) {
    return <div>404</div>;
  }
  const ref = useRef<ImperativePanelHandle>(null);
  const collapsePanel = () => {
    const panel = ref.current;
    if (panel) {
      panel.collapse();
    }
    dispatch(updateRightbar({ setIsOpen: false }));
  };
  const expandPanel = () => {
    const panel = ref.current;
    if (panel) {
      panel.expand();
    }
    dispatch(updateRightbar({ setIsOpen: true }));
  };
  dispatch(loadTaxonomy({ taxonomy }));
  dispatch(setActiveTaxonomyId({ taxonomy_id: taxonomy.taxonomy_id }));
  dispatch(setActiveView(View.Taxonomy));
  dispatch(updateLeftbar({ setIsOpen: false }));

  return (
    <div className="flex app-background">
      <div className="flex flex-col w-full h-screen">
        <Header showLogo runYourOwnButton />
        <div className="flex flex-row w-full" style={{ height: "calc(100vh - 64px)" }}>
          <ResizablePanelGroup direction="horizontal" className={`h-full w-full`}>
            <ResizablePanel defaultSize={70}>
              <div className={`flex flex-col px-10 items-center gap-5 justify-start h-full py-8 overflow-y-scroll w-full`}>
                <TaxonomyViewer />
              </div>
            </ResizablePanel>
            <ResizableHandle withHandle className="z-[100]" />
            <ResizablePanel
              defaultSize={30}
              ref={ref}
              collapsible
              collapsedSize={3}
              minSize={10}
              onCollapse={() => dispatch(updateRightbar({ setIsOpen: false }))}
              onExpand={() => dispatch(updateRightbar({ setIsOpen: true }))}>
              <RightSection onClose={collapsePanel} onExpand={expandPanel} />
            </ResizablePanel>
          </ResizablePanelGroup>
        </div>
      </div>
      <Toaster richColors position="top-center" />
    </div>
  );
}
