import { useAppSelector } from "@/store/hooks";
import { Tabs } from "../ui/tabs";
// import { removeBusinessFromOutputMarketMap } from "@/store/genericWorkflows";
import { useEffect, useState } from "react";
import { TaxonomyTable } from "./GiantTable/TaxonomyTable";
import { Slider } from "../ui/slider";

// TODO: just pass a generic workflow instead???
export const TaxonomyViewer = ({
  onOpenRightSection
}: {
  hideRefine?: boolean;
  hideFeedback?: boolean;
  onOpenRightSection?: () => void;
}) => {
  // const dispatch = useAppDispatch();
  const activeTaxonomy = useAppSelector((state) =>
    state.taxonomies.taxonomies.find((t) => t.taxonomy_id === state.taxonomies.activeTaxonomyId)
  );

  const [hypeMinimum, setHypeMinimum] = useState(0);

  useEffect(() => {
    onOpenRightSection && onOpenRightSection();
  }, [activeTaxonomy]);

  if (activeTaxonomy == null) {
    return <div>No active taxonomy</div>;
  }
  return (
    <Tabs defaultValue="table" className="w-full h-full">
      <div className="h-full flex-col flex">
        <div className="flex flex-col mb-10 w-40 gap-2">
          <div className="text-left mt-2 text-colors-brand-800">
            Hype minimum: <span className="font-bold">{hypeMinimum}</span>
          </div>
          <Slider
            value={[hypeMinimum]}
            onValueChange={(values) => {
              setHypeMinimum(values[0]);
            }}
            max={5}
            step={1}
          />
          <div className="flex justify-between text-colors-gray-modern-400 font-medium">
            <span>0</span>
            {/* <span>Hype Threshold</span> */}
            <span>5</span>
          </div>
        </div>
        {/* <TabsContent value="table" className="grow overflow-hidden"> */}
        <TaxonomyTable taxonomy={activeTaxonomy} hypeMinimum={hypeMinimum} />
        {/* </TabsContent> */}
        {/* <TabsContent value="graphic">
          <MarketMapSimpleViewer marketMap={compFinderWorkflow.outputs.market_map} businesses={compFinderWorkflow.outputs.companies} />
        </TabsContent>
        <TabsContent value="refine">
          <MarketMapRefine />
        </TabsContent> */}
      </div>
    </Tabs>
  );
};
