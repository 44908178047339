import { useState } from "react";

export const DocumentUpload = ({ onDocumentUpload }: { onDocumentUpload: (documentSrc: string) => void }) => {
  const [_, setDocumentSrc] = useState<string | null>(null);

  // Handle image file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setDocumentSrc(reader.result as string);
        onDocumentUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle image paste
  const handlePaste = (_: React.ClipboardEvent<HTMLDivElement>) => {
    // TODO: Implement paste handling
    // const items = e.clipboardData.items;
    // for (let item of items) {
    //   if (item.type.startsWith("image/")) {
    //     const file = item.getAsFile();
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       setDocumentSrc(reader.result as string);
    //       onDocumentUpload(reader.result as string);
    //     };
    //     reader.readAsDataURL(file as Blob);
    //   }
    // }
  };

  return (
    <div onPaste={handlePaste}>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      {/* TODO: preview the uploaded document */}
    </div>
  );
};
