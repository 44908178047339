/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { CategoryDetails, rightbarContent } from "@/store/sidebars";
import { Button } from "@/components/ui/button";
import { ArrowLeftSquare, Plus } from "lucide-react";
import {
  CompLandscapeV7Workflow,
  FindCompaniesWorkflowV2,
  FrontendCompany,
  MarketMapWorkflow,
  SourceCompaniesCompany,
  SourceCompaniesWorkflow,
  TaskStatus,
  WorkflowType
} from "@/services/autogen";
import { CompanyProfile } from "./RightSectionCompanyProfile";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import { addSimilarCompaniesToFindCompaniesWorkflowThunk } from "@/store/genericWorkflows";
import posthog from "posthog-js";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";
import { setActiveView, View } from "@/store/views";
import { TaxonomyEntryCompanyProfile } from "../taxonomy/TaxonomyEntryCompanyProfile";
import { fetchCompanyTearsheetStreamingThunk, setActiveCompanyProfile } from "@/store/companyProfiles";

const CategoryDetailsViewer = ({ categoryDetails }: { categoryDetails: CategoryDetails }) => {
  return (
    <div>
      <div className="text-colors-gray-modern-400 text-md mb-2">Category</div>
      <div className="text-colors-brand-700 font-semibold text-lg">{categoryDetails.categoryName}</div>
      {categoryDetails.categoryDescription && (
        <div className="text-colors-brand-500 font-light text-md pl-2 pt-2">{categoryDetails.categoryDescription}</div>
      )}
      {categoryDetails.subcategoryName && (
        <>
          <div className="text-colors-gray-modern-400 text-md mt-8 mb-2">Subcategory</div>
          <div className="text-colors-brand-700 font-semibold text-lg">{categoryDetails.subcategoryName}</div>
          {categoryDetails.subcategoryDescription && (
            <div className="text-colors-brand-500 font-light text-md pl-2 pt-2">{categoryDetails.subcategoryDescription}</div>
          )}
        </>
      )}
    </div>
  );
};

function getTextForEmptyRightbar(workflowType: WorkflowType) {
  if (workflowType === WorkflowType.CompLandscapeV7 || workflowType === WorkflowType.MarketMapV2) {
    return "Click on a company or category to see more details!";
  } else if (workflowType === WorkflowType.FindCompaniesV2 || workflowType === WorkflowType.SourceCompanies) {
    return "Click on a company to see more details!";
  } else {
    return "";
  }
}

const RightBarContentV2 = ({ rightbarContent, onClose }: { rightbarContent: rightbarContent; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();
  const { categoryDetails, companyDomain } = rightbarContent;
  const activeWorkflow = useAppSelector((state: RootState) =>
    state.genericWorkflows.workflows.find((w) => w.workflow_id === state.genericWorkflows.activeWorkflowId)
  );
  const activeView = useAppSelector((state) => state.views.activeView);
  const activeCompanyList = useAppSelector((state) =>
    state.companyLists.companyLists.find((c) => c.id === state.companyLists.activeCompanyListId)
  );
  const activeTaxonomy = useAppSelector((state) =>
    state.taxonomies.taxonomies.find((t) => t.taxonomy_id === state.taxonomies.activeTaxonomyId)
  );
  const activeScoredTaxonomy = useAppSelector((state) =>
    state.taxonomies.scoredTaxonomies.find((t) => t.taxonomy_id === state.taxonomies.activeScoredTaxonomyId)
  );
  const scoredCompany = useAppSelector((state) => state.rubricScoring.scoredCompanies.find((c) => c.company_domain === companyDomain));
  if (activeView === View.CompanyList) {
    const company = activeCompanyList?.companies.find((c) => c.domain === companyDomain);
    if (company) {
      return <CompanyProfile company={company} onClose={onClose} />;
    }
  }
  if (activeView === View.Taxonomy && activeTaxonomy) {
    const entry = activeTaxonomy?.entries.find((c) => c.company.domain === companyDomain);
    if (entry) {
      return <TaxonomyEntryCompanyProfile entry={entry} rootTaxonomyNode={activeTaxonomy?.taxonomy_node} onClose={onClose} />;
    }
  }
  if (activeView === View.ScoredTaxonomy) {
    const entry = activeScoredTaxonomy?.entries.find((c) => c.company.domain === companyDomain);
    if (entry) {
      return <CompanyProfile company={entry.company} onClose={onClose} />;
    }
  }
  if (activeView === View.RubricScoring && scoredCompany) {
    return (
      <div>
        <div className="flex flex-col gap-2">
          <Button
            onClick={async () => {
              const token = await getToken();
              if (token && companyDomain) {
                dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: companyDomain }));
                dispatch(setActiveCompanyProfile({ companyProfileId: companyDomain }));
                dispatch(setActiveView(View.Company));
              }
            }}>
            Go to company profile
          </Button>
          {scoredCompany.scored_dimensions.map((dimension, index) => {
            return (
              <div key={index}>
                <div className="text-colors-brand-700 font-semibold flex flex-row gap-2 items-center">
                  <ScoreChip score={dimension.score} numberOfDecimals={0} maxScore={5} />{" "}
                  <div>{dimension.type.charAt(0).toUpperCase() + dimension.type.slice(1).toLowerCase().replace(/_/g, " ")}</div>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <div className="text-colors-gray-modern-500 text-sm pl-2 pt-2">{dimension.explanation}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  // TODO: check on workflow types
  if (!activeWorkflow || activeWorkflow.workflow.outputs == null) {
    return <div />;
  }

  if (companyDomain) {
    let sourcedCompany: SourceCompaniesCompany | undefined;
    let company: FrontendCompany | undefined;
    const showFindMore = activeWorkflow.workflow_type === WorkflowType.FindCompaniesV2 && activeWorkflow.status !== TaskStatus.Running;
    if (activeWorkflow.workflow_type === WorkflowType.FindCompaniesV2) {
      const findCompaniesV2Workflow = activeWorkflow.workflow as FindCompaniesWorkflowV2;
      if (findCompaniesV2Workflow.outputs == null) {
        return <div />;
      }
      let companies = findCompaniesV2Workflow.outputs.companies;
      company = companies.find((c) => c.domain === companyDomain);
    } else if (activeWorkflow.workflow_type === WorkflowType.MarketMapV2 || activeWorkflow.workflow_type === WorkflowType.CompLandscapeV7) {
      const marketMapWorkflow = activeWorkflow.workflow as MarketMapWorkflow | CompLandscapeV7Workflow;
      if (marketMapWorkflow.outputs == null) {
        return <div />;
      }
      let companies = marketMapWorkflow.outputs.companies;
      company = companies.find((c) => c.company.domain === companyDomain)?.company;
    } else if (activeWorkflow.workflow_type === WorkflowType.SourceCompanies) {
      const sourceCompaniesWorkflow = activeWorkflow.workflow as SourceCompaniesWorkflow;
      if (sourceCompaniesWorkflow.outputs == null) {
        return <div />;
      }
      let companies = sourceCompaniesWorkflow.outputs.companies;
      sourcedCompany = companies.find((c) => c.company.domain === companyDomain);
      company = sourcedCompany?.company;
    } else {
      return <div />;
    }
    return (
      <div className="flex flex-col gap-4 h-full pt-4 pb-6 w-full ">
        {showFindMore && (
          <div>
            <Button
              onClick={async () => {
                const token = await getToken();
                if (token) {
                  posthog.capture("user_clicked_find_similar_companies", {
                    workflow_id: activeWorkflow.workflow_id,
                    company_domain: companyDomain
                  });
                  dispatch(
                    addSimilarCompaniesToFindCompaniesWorkflowThunk({
                      token: token,
                      workflowId: activeWorkflow.workflow_id,
                      companyDomain: companyDomain
                    })
                  );
                }
              }}>
              <Plus size={24} />
              Add companies like this one
            </Button>
          </div>
        )}
        <CompanyProfile company={company} onClose={onClose}>
          {sourcedCompany &&
            sourcedCompany.scoring_answers?.map((answer, index) => {
              return (
                <div key={index}>
                  <div className="text-colors-brand-700 font-semibold">{answer.question}</div>
                  <div className="flex flex-row gap-2 items-center">
                    <ScoreChip score={answer.score} numberOfDecimals={0} />
                    <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{answer.explanation?.text}</div>
                  </div>
                </div>
              );
            })}
        </CompanyProfile>
      </div>
    );
  } else if (categoryDetails) {
    return <CategoryDetailsViewer categoryDetails={categoryDetails} />;
  } else {
    return <div className="text-center text-colors-brand-700 mt-8 text-xl">{getTextForEmptyRightbar(activeWorkflow.workflow_type)}</div>;
  }
};

export const RightSection = ({ onClose, onExpand }: { onClose: () => void; onExpand: () => void }) => {
  const rightbarContent = useSelector((state: RootState) => state.sidebars.rightbarContent);
  const isOpen = useSelector((state: RootState) => state.sidebars.rightBarOpen);

  return (
    <>
      {!isOpen ? (
        <>
          <div className={`z-50 bg-colors-brand-50  relative w-full h-full flex flex-col items-center pt-2`}>
            <Button onClick={onExpand} variant="ghost">
              <ArrowLeftSquare size={24} />
            </Button>
          </div>
        </>
      ) : (
        <div className={`z-50 bg-colors-brand-50 overflow-y-auto ease-in-out duration-300 relative w-full h-full p-5`}>
          {rightbarContent !== undefined && <RightBarContentV2 rightbarContent={rightbarContent} onClose={onClose} />}
        </div>
      )}
    </>
  );
};
