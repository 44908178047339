import { useMemo, useRef } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { CompanyRow } from "./common/CompanyRow";
import { FrontendCompany } from "@/services/autogen";
import { basicColumns, crmStatusColumn, finalColumn } from "./common/Columns";
import { AbstractCompanyTable } from "./common/AbstractCompanyTable";

export function SimpleCompanyTable({
  companies,
  selectedDomains,
  onSelectedDomainsChange,
  onCompanyClick,
  onRowDelete,
  disableRowSelection,
  enableCrmStatusColumn
}: {
  companies: FrontendCompany[];
  selectedDomains: string[];
  onSelectedDomainsChange: (selectedDomains: string[]) => void;
  onCompanyClick: (domain: string) => void;
  onRowDelete?: (domain: string) => void;
  disableRowSelection?: boolean;
  enableCrmStatusColumn?: boolean;
}) {
  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useMemo<ColumnDef<CompanyRow>[]>(() => {
    const cols = [...basicColumns];
    if (disableRowSelection) {
      cols.shift();
    }
    if (enableCrmStatusColumn) {
      cols.push(crmStatusColumn());
    }
    return [...cols, finalColumn(tableRef, onRowDelete)];
  }, [disableRowSelection, enableCrmStatusColumn]);

  const onRowClick = (row: CompanyRow) => {
    onCompanyClick(row.domain);
  };

  const rows = companies.map((company, index) => ({ ...company, tableRank: index }));
  return (
    <AbstractCompanyTable
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      tableRef={tableRef}
      selectedDomains={selectedDomains}
      onSelectedDomainsChange={onSelectedDomainsChange}
    />
  );
}
