import { FrontendFullSearch } from "@/services/autogen";

import { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { toast } from "sonner";
import { addCompaniesToList, createCompanyList } from "@/store/companyLists";
import { Rows4 } from "lucide-react";

export const AddCompaniesToListDropdown = ({
  domainsSelected,
  companySearch,
  workflowTitle,
  disabled
}: {
  domainsSelected: string[];
  companySearch: FrontendFullSearch;
  workflowTitle: string;
  disabled?: boolean;
}) => {
  const companyLists = useAppSelector((state) => state.companyLists.companyLists);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const companiesToUse = companySearch.qualified_companies ?? [];
  const metaCompanies = domainsSelected.map((domain) => companiesToUse.find((c) => c.company.domain === domain)).filter((c) => c != null);
  const companies = metaCompanies.map((c) => c.company);

  const handleAddToList = async (listId?: string, listTitle?: string) => {
    if (domainsSelected.length === 0) {
      toast.error("Please select at least one company");
      return;
    }

    if (listId) {
      // Add to existing list
      dispatch(addCompaniesToList({ companies, companyListId: listId }));
      toast.success(`Added ${domainsSelected.length} companies to ${listTitle}`);
    } else {
      // Create new list
      dispatch(createCompanyList({ title: `Companies from ${workflowTitle}`, companies }));
      toast.success(`Added ${domainsSelected.length} companies to new list`);
    }
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {domainsSelected.length > 0 && (
        <PopoverTrigger asChild>
          <Button size="sm" disabled={disabled}>
            <Rows4 size={20} /> Add to list
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent className="w-80 max-h-[400px] overflow-y-auto">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-500">Add to list</div>
          {[...companyLists].reverse().map((list) => (
            <Button key={list.id} variant="outline" className="w-full justify-start" onClick={() => handleAddToList(list.id, list.title)}>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap block">{list.title}</span>
            </Button>
          ))}
          <Button variant="default" className="w-full justify-start" onClick={() => handleAddToList()}>
            <div>New list</div>
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
