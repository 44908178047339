/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { Notepad, Sparkle } from "@phosphor-icons/react";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { Check, ExternalLink, MoveDown, MoveUp, Plus, Trash2 } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl, formatDollarValueMillionsBillions } from "@/lib/utils";
import { ScoringCriterion, SourceCompaniesCompany } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import React, { useRef } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { HypeFlameSizeIcon } from "@/components/company-table/common/CellComponents";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { CompanyTypeIcon } from "@/components/company-table/common/CellComponents";

export interface BusinessRow extends SourceCompaniesCompany {
  tableRank: number;
  checked: boolean;
}

// TODO: remove all of these
const SortingDirectionIndicator = ({ column }: { column: Column<BusinessRow> }) => {
  if (column.getIsSorted() === "asc") {
    return <MoveDown size={14} />;
  } else if (column.getIsSorted() === "desc") {
    return <MoveUp size={14} />;
  } else {
    return null;
  }
};

export const ScoreChip = ({
  score,
  numberOfDecimals,
  minScore = 0,
  maxScore = 10
}: {
  score?: number;
  numberOfDecimals?: number;
  minScore?: number;
  maxScore?: number;
}) => {
  if (score == null) {
    return null;
  }
  // let scoreStyle = "bg-colors-gray-modern-100 border border-colors-gray-modern-300 text-colors-gray-modern-800";
  // if (score >= 7) {
  //   scoreStyle = "bg-colors-success-100 border border-colors-success-300 text-colors-success-800";
  // } else if (score >= 4) {
  //   scoreStyle = "bg-colors-warning-100 border border-colors-warning-300 text-colors-warning-800";
  // } else {
  //   scoreStyle = "bg-colors-error-100 border border-colors-error-300 text-colors-error-800";
  // }

  // Calculate color based on score
  const intensity = 0.7; // Reduce this value to make colors more subdued (0.0 - 1.0)
  const red = score <= (maxScore + minScore) / 2 ? 255 : Math.round((255 * (maxScore - score)) / ((maxScore - minScore) / 2));
  const green = score >= (maxScore + minScore) / 2 ? 255 : Math.round((255 * (score - minScore)) / ((maxScore - minScore) / 2));
  const blue = 0;

  // Mix with white to subdue the colors
  const mixedRed = Math.round(red * intensity + 255 * (1 - intensity));
  const mixedGreen = Math.round(green * intensity + 255 * (1 - intensity));
  const mixedBlue = Math.round(blue * intensity + 255 * (1 - intensity));

  const backgroundColor = `rgb(${mixedRed}, ${mixedGreen}, ${mixedBlue})`;

  // Calculate text color (black for light backgrounds, white for dark backgrounds)
  const brightness = (mixedRed * 299 + mixedGreen * 587 + mixedBlue * 114) / 1000;
  const textColor = brightness > 128 ? "black" : "white";
  return (
    <div className={`rounded-sm px-[6px] py-[2px] inline-flex`} style={{ backgroundColor, color: textColor }}>
      <span>{score.toFixed(numberOfDecimals ?? 0)}</span>
    </div>
  );
};

const scoringCriterionSorting = (rowA: Row<BusinessRow>, rowB: Row<BusinessRow>, scoringQuestion: string): number => {
  const a = rowA.original.scoring_answers?.find((answer) => answer.question === scoringQuestion)?.score;
  const b = rowB.original.scoring_answers?.find((answer) => answer.question === scoringQuestion)?.score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

const overallScoreSorting = (rowA: Row<BusinessRow>, rowB: Row<BusinessRow>): number => {
  const a = rowA.original.overall_score;
  const b = rowB.original.overall_score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

export const SourceCompaniesTable = React.memo(
  ({
    businesses,
    onBusinessDelete,
    findSimilarBusinessesCallback,
    isRunning,
    scoringCriteria,
    checkedDomains,
    onDomainCheckedChange
  }: {
    businesses: SourceCompaniesCompany[];
    onBusinessDelete: (businessId: string) => void;
    findSimilarBusinessesCallback: (businessId: string) => void;
    isRunning: boolean;
    scoringCriteria: ScoringCriterion[];
    checkedDomains: string[];
    onDomainCheckedChange: (domain: string, checked: boolean) => void;
  }) => {
    const tableWindowRef = useRef<HTMLDivElement>(null);
    // TODO: type this properly
    const tableRows: BusinessRow[] = [];
    businesses.forEach((business, index) => {
      tableRows.push({ ...business, tableRank: index, checked: checkedDomains.includes(business.company.domain) });
    });

    const basicColumns: ColumnDef<BusinessRow>[] = [
      {
        accessorKey: "tableRank",
        // header: () => "#",
        header: (header) => (
          <div className="flex flex-row hover:cursor-pointer px-2 w-12" onClick={header.column.getToggleSortingHandler()}>
            #
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        size: 15,
        cell: ({ row, table }) => {
          const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
          return (
            <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center">
              <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.tableRank + 1}</span>
              {isRowHovered && !isRunning && (
                <Popover>
                  <PopoverTrigger asChild>
                    <Sparkle className="hover:cursor-pointer text-colors-brand-600 hover:text-colors-brand-800 ml-2" size={16} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="flex flex-row items-center gap-2">
                      <div className="text-colors-text-text-secondary-(700) text-sm mb-2">Add more companies like this one?</div>
                      <Button
                        onClick={() => {
                          findSimilarBusinessesCallback(row.original.company.domain);
                        }}>
                        Yes
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              )}
            </div>
          );
        },
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "checked",
        // header: () => "#",
        header: (header) => (
          <div
            className="flex flex-row hover:cursor-pointer px-2 overflow-hidden  items-center justify-center"
            onClick={header.column.getToggleSortingHandler()}>
            <SimpleTooltip text="Add to list?">
              <Check />
            </SimpleTooltip>
          </div>
        ),
        size: 15,
        maxSize: 15,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex items-center justify-center">
            <Checkbox
              checked={row.original.checked}
              onCheckedChange={(value) => {
                onDomainCheckedChange(row.original.company.domain, !!value);
              }}
            />
          </div>
        ),
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "name",
        // size: 100,
        header: ({}) => {
          return (
            // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
            // <CaretUpDown className="ml-2 h-4 w-4" />
            // </Button>
          );
        },
        cell: ({ row }) => (
          <div className="flex flex-col items-start py-2 pl-2 z-40">
            <div className="flex flex-row items-center">
              <CompanyLogo
                name={row.original.company.name}
                website={row.original.company.domain}
                logo={row.original.company.logo_url}
                className="w-8 h-8 mr-2 opacity-100"
              />
              <div className="flex flex-col">
                <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                  {row.original.company.name.length > 15 ? `${row.original.company.name.slice(0, 15)}...` : row.original.company.name}
                </h1>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
                    {row.original.company.stock_ticker ?? ""}
                  </span>
                  <a
                    href={ensureAbsoluteUrl(row.original.company.domain ?? "")}
                    className="flex flex-row hover:cursor-pointer font-medium underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ExternalLink size={14} color="grey" />
                  </a>
                  {row.original.company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
                    </a>
                  )}
                  <HypeFlameSizeIcon hype={row.original.company.hype_rating} />
                </div>
              </div>
            </div>
          </div>
        ),
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "companyType",
        header: () => (
          <div className="flex flex-row items-center">
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
          </div>
        ),
        size: 15,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
            <CompanyTypeIcon type={row.original.company.company_type} />
            {/* <CompanyTypeChip type={row.original.company.company_type} /> */}
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Overview"
        }
      },
      {
        accessorKey: "employee_count",
        // size: 25,
        size: 80,
        header: () => (
          <div>
            <span>Employees</span>
          </div>
        ),
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-center">
            {/* TODO: show the range */}
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {employeeRangeText(row.original.company.employee_min, row.original.company.employee_max, row.original.company.employee_guess)}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Employee Count"
        }
        // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
        // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
      },
      {
        accessorKey: "overview",
        header: () => "Overview",
        size: 180,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.overview.text}
            </span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Overview"
        }
      },
      {
        accessorKey: "business_model",
        header: () => "Business model",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.business_model.text}
            </span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Business Model"
        }
      },
      {
        accessorKey: "main_offerings",
        header: () => "Main offerings",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.main_offerings.text}
            </span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Main Offerings"
        }
      },
      {
        accessorKey: "customer_groups",
        header: () => "Customer groups",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.customer_segments.text}
            </span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Customer groups"
        }
      },
      {
        accessorKey: "geographies",
        header: () => "Geographies",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.geographies.text}
            </span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Geographies"
        }
      },
      {
        accessorKey: "technologies",
        header: () => "Technologies",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.company.summary.technologies.text}
            </span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Technologies"
        }
      },
      {
        accessorKey: "headquarters",
        size: 100,
        header: () => <div className="w-auto">HQ</div>,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.company.hq_str}</span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Headquarters"
        }
      },
      // DROPPING THIS FROM TABLE BECAUSE DATA IS SUPER SPARSE
      // {
      //   accessorKey: "investors",
      //   header: () => <div className="w-auto">Investors</div>,
      //   cell: ({ row }) => (
      //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
      //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.company.list_of_investors}</span>
      //     </div>
      //   ),
      //   meta: {
      //     group: "basic_info",
      //     displayName: "Investors"
      //   }
      // },
      {
        accessorKey: "funds_raised",
        size: 100,
        header: () => <div className="w-auto justify-end text-right">Known fundraising</div>,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {/* TODO: format this properly */}
              {row.original.company.total_known_fundraising?.amount
                ? formatDollarValueMillionsBillions(row.original.company.total_known_fundraising.amount)
                : "-"}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Known Fundraising"
        }
      },
      {
        accessorKey: "founded",
        size: 80,
        header: () => <div className="justify-end text-right">Founded</div>,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.company.year_founded}</span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Founding year"
        }
      },
      {
        accessorKey: "web_traffic",
        size: 80,
        header: () => <div className="justify-end text-right">Web traffic</div>,
        cell: ({ row }) => {
          const visitsLastMonth = row.original.company.web_traffic?.visits_last_month;
          const visitGrowthMonthly = row.original.company.web_traffic?.visit_growth_monthly;
          const formattedVisits = visitsLastMonth
            ? visitsLastMonth >= 1000000
              ? (visitsLastMonth / 1000000).toFixed(1) + "M"
              : (visitsLastMonth / 1000).toFixed(0) + "k"
            : visitsLastMonth;
          const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
          const formattedGrowth =
            visitGrowthMonthly !== undefined
              ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%`
              : "";

          return (
            <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
              <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
                {formattedVisits}
                <br />
                <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
              </span>
            </div>
          );
        },
        meta: {
          group: "basic_info",
          displayName: "Web traffic"
        }
      },
      {
        accessorKey: "overall_score",
        size: 80,
        header: (header) => (
          <div className="justify-end text-right cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            Overall score
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex flex-col items-end h-full max-h-[64px] justify-center">
            <ScoreChip score={row.original.overall_score} numberOfDecimals={1} />
          </div>
        ),
        sortingFn: (rowA, rowB, _): number => {
          return overallScoreSorting(rowA, rowB);
        },
        meta: {
          group: "scoring",
          displayName: "Overall score"
        }
      }
    ];
    const newColumns = [...basicColumns];

    const getAllColumns = () => {
      for (const scoringCriterion of scoringCriteria) {
        newColumns.push({
          accessorKey: scoringCriterion.question,
          header: (header) => (
            <div className="w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
              {scoringCriterion.question}
              <SortingDirectionIndicator column={header.column} />
            </div>
          ),
          cell: ({ row }) => {
            return (
              <div className="overflow-hidden ellipsis w-auto text-right flex flex-row gap-2 p-2">
                <div className="bg-colors-gray-modern-4000 rounded-sm text-center self-center px-1">
                  <ScoreChip score={row.original.scoring_answers?.find((answer) => answer.question === scoringCriterion.question)?.score} />
                </div>
                <span className="text-colors-text-text-secondary-(700) max-h-10">
                  {row.original.scoring_answers?.find((answer) => answer.question === scoringCriterion.question)?.explanation?.text}
                </span>
                <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
              </div>
            );
          },
          sortingFn: (rowA, rowB, _): number => {
            return scoringCriterionSorting(rowA, rowB, scoringCriterion.question);
          },
          meta: {
            group: "scoring",
            displayName: scoringCriterion.question.slice(0, 20)
          }
        });
      }
      newColumns.push({
        accessorKey: "trash",
        size: 50,
        header: ({ table }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                  <Plus size={20} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
                <span className="flex items-center justify-start my-1 p-1">
                  <Notepad className="mr-1" size={22} />
                  <h1 className="font-semi">Basic info</h1>
                </span>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column: any) => column?.columnDef?.meta?.group === "basic_info" || column?.columnDef?.meta?.group === "scoring")
                  .map((column: any) => {
                    // TODO: stop using any
                    return (
                      <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                        <Checkbox
                          key={column.id}
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) => {
                            column.toggleVisibility(!!value);

                            // sleep for 1 sec
                            setTimeout(() => {
                              console.log("trying to scroll");
                              // scroll to the right
                              tableWindowRef.current?.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                            }, 100);
                          }}>
                          {column.id}
                        </Checkbox>

                        <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                      </div>
                    );
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
        enableHiding: false,
        cell: ({ row, table }) => {
          const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
          return isRowHovered ? (
            <div className="flex justify-center items-center">
              <Button
                className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
                onClick={(e) => {
                  onBusinessDelete(row.original.company.domain);
                  e.stopPropagation();
                }}>
                <Trash2 size={16} className="trash-icon" strokeWidth={2} />
              </Button>
            </div>
          ) : (
            <div className="min-w-[22px] min-h-[39px]"></div>
          );
        }
      });
    };

    getAllColumns();

    return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} isRunning={isRunning} />;
  }
);
