import { Info, Search, X } from "lucide-react";
import { ChangeEvent, useCallback, FormEvent, useEffect, useState } from "react";
import { getDomainFromUrl } from "@/lib/utils";
import { truncate } from "lodash";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { KeyReturn } from "@phosphor-icons/react";

type TQuery = {
  name: string;
  domain: string;
  icon: string;
};

export type TAutocomplete = {
  value: string;
  query?: TQuery;
  queries: TQuery[];
};

interface IAutocomplete {
  onChange: (value: string) => void;
  onSubmit: ({ value, queries, query }: TAutocomplete) => void;
  placeholder: string;
  disableLabel?: boolean;
}

export const CompanyDomainAutocomplete = ({
  onChange,
  onSubmit,
  placeholder,
  disableLabel,
  textValue
}: IAutocomplete & { textValue?: string }) => {
  const [value, setValue] = useState({ text: textValue ?? "", active: false });
  const [queries, setQueries] = useState<TQuery[]>([]);

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();

    const text = value.text;
    onSubmit({ value: text.toLowerCase(), query: undefined, queries });
    setValue({ text, active: false });
    setQueries([]);
  };

  const handleClick = (query: TQuery) => {
    setValue({ text: query.domain, active: false });
    onSubmit({ value: query.domain, query, queries });
  };

  const reset = () => {
    setQueries([]);
    setValue({ text: "", active: false });
    onSubmit({ value: "", queries: [], query: undefined });
  };

  const getQueries = useCallback(async (searchValue: string) => {
    if (searchValue !== "") {
      try {
        const lowercaseSearchValue = searchValue.toLowerCase();
        const url = `https://api.brandfetch.io/v2/search/${lowercaseSearchValue}`;

        const res = await fetch(url);
        if (res.ok) {
          const data = await res.json();
          setQueries(data);
        }
      } catch {
        console.log("Something went wrong, try again later.");
      }
      return;
    }

    setQueries([]);
  }, []);

  useEffect(() => {
    getQueries(value.text);
  }, [getQueries, value.text]);

  return (
    <div className="relative w-full">
      {!disableLabel && (
        <div className="flex flex-row justify-between items-end text-colors-text-text-secondary-(700) mb-3">
          <div className="text-sm mr-3">Company website</div>
          <div className="text-xs text-right text-colors-text-text-quarterary-(500)">
            {"("}Logos provided by{" "}
            <a href="https://brandfetch.com/" rel="noreferrer" target="_blank">
              Brandfetch
            </a>
            {")"}
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="w-full relative">
        <label className="absolute left-2 top-3 text-colors-gray-modern-600">
          <Search size={16} />
        </label>

        <input
          className="border border-colors-gray-modern-400 bg-transparent rounded-lg pl-8 pr-8 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full text-sm placeholder:text-xs"
          placeholder={placeholder}
          value={value.text}
          // TODO: handle pastes with https:// prefixes and suffixes by truncating
          onPaste={(e) => {
            e.preventDefault();
            const domain = getDomainFromUrl(e.clipboardData.getData("text"));
            setValue({ text: domain, active: true });
            onChange(domain);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue({ text: e.target.value, active: true });
            onChange(e.target.value);
          }}
        />

        {value.text !== "" && (
          <label className="absolute right-1 top-2 cursor-pointer" onClick={() => reset()}>
            <X />
          </label>
        )}
      </form>

      {value.active && value.text !== "" && (
        <div className="fixed mt-2 left-0 right-0 bg-colors-brand-50 opacity-100 rounded-md grid p-5 border border-colors-border-border-secondary z-[9999]">
          {queries.length ? (
            <div className="flex flex-col gap-2 text-colors-text-text-secondary-(700)">
              {queries
                .filter((query) => query.domain.includes(value.text.toLowerCase()) && (query.domain.match(/\./g) || []).length < 2)
                .map((query, i) => (
                  <div
                    key={i}
                    className="flex gap-2 hover:bg-colors-brand-300 p-2 rounded-md cursor-pointer flex-row items-center w-full justify-between"
                    onClick={() => handleClick(query)}>
                    <div className="flex flex-row items-center gap-2">
                      <div className="h-5 w-5 relative overflow-hidden shadow-sm z-50">
                        <img src={query.icon} alt={query.name} className="w-full h-full top-0 left-0 right-0 bottom-0" />
                      </div>

                      <p>{truncate(query.name || query.domain, { length: 15 })}</p>
                    </div>

                    <p className="self-end place-self-end">{query.domain}</p>
                  </div>
                ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2 text-center">
              <p className="bold">Couldn&apos;t auto-recognize this company. You can run the workflow anyway</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const CompanyDomainAutocompleteForLeftbar = ({ onChange, onSubmit, placeholder }: IAutocomplete) => {
  const [value, setValue] = useState({ text: "", active: false });
  const [queries, setQueries] = useState<TQuery[]>([]);

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();

    const text = value.text;
    const lowercaseText = text.toLowerCase();
    onSubmit({ value: lowercaseText, query: undefined, queries });
    setValue({ text, active: false });
    setQueries([]);
  };

  const handleClick = (query: TQuery) => {
    setValue({ text: query.domain, active: false });
    onSubmit({ value: query.domain, query, queries });
  };

  const getQueries = useCallback(async (searchValue: string) => {
    if (searchValue !== "") {
      try {
        const lowercaseSearchValue = searchValue.toLowerCase();
        const url = `https://api.brandfetch.io/v2/search/${lowercaseSearchValue}`;

        const res = await fetch(url);
        if (res.ok) {
          const data = await res.json();
          setQueries(data);
        }
      } catch {
        console.log("Something went wrong, try again later.");
      }
      return;
    }

    setQueries([]);
  }, []);

  useEffect(() => {
    getQueries(value.text);
  }, [getQueries, value.text]);

  const filteredQueries = queries.filter(
    (query) => query.domain.includes(value.text.toLowerCase()) && (query.domain.match(/\./g) || []).length < 2
  );

  return (
    <div className="relative w-full">
      <form onSubmit={handleSubmit} className="w-full relative">
        <label className="absolute left-2 top-3 text-colors-gray-modern-600">
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info size={16} />
              </TooltipTrigger>
              <TooltipContent className="bg-gray-300 border-gray-300 text-[#0E343E]">
                Lookup a company by domain <br /> (Costs 1 credit)
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </label>

        <input
          className="border border-colors-border-border-primary bg-transparent rounded-lg pl-8 pr-8 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full text-sm placeholder:text-xs"
          placeholder={placeholder}
          value={value.text}
          // TODO: handle pastes with https:// prefixes and suffixes by truncating
          onPaste={(e) => {
            e.preventDefault();
            const domain = getDomainFromUrl(e.clipboardData.getData("text"));
            setValue({ text: domain, active: true });
            onChange(domain);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue({ text: e.target.value, active: true });
            onChange(e.target.value);
          }}
        />

        {value.text !== "" && (
          <label className="absolute right-1 top-2 cursor-pointer text-colors-gray-modern-600" onClick={handleSubmit}>
            <KeyReturn size={22} />
          </label>
        )}
      </form>

      {value.active && value.text !== "" && (
        <div className="fixed mt-2 left-0 right-0  bg-colors-brand-50 rounded-md grid z-[9999] px-2 py-3 border border-colors-border-border-secondary">
          {filteredQueries.length ? (
            <div className="flex flex-col gap-2 text-colors-text-text-secondary-(700) text-xs">
              <div className="flex p-0 text-center rounded-md flex-row items-center w-full justify-between text-sm text-colors-brand-900">
                <p>Suggestions:</p>
              </div>
              {filteredQueries.map((query, i) => (
                <div
                  key={i}
                  className="flex gap-2 hover:bg-colors-brand-300 p-2 rounded-md cursor-pointer flex-row items-center w-full justify-between"
                  onClick={() => handleClick(query)}>
                  <div className="flex flex-row items-center gap-2">
                    <div className="h-5 w-5 relative overflow-hidden shadow-sm z-50">
                      <img src={query.icon} alt={query.name} className="w-full h-full top-0 left-0 right-0 bottom-0" />
                    </div>

                    <p>{truncate(query.name || query.domain, { length: 15 })}</p>
                  </div>

                  <p className="self-end place-self-end">{truncate(query.domain, { length: 15 })}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2 text-center text-sm">
              <p className="bold">Couldn&apos;t auto-recognize this company.</p>{" "}
              <Button size="sm" onClick={() => handleSubmit()}>
                Try it anyway
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
