import React, { CSSProperties, useEffect, useState } from "react";

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  VisibilityState
} from "@tanstack/react-table";
import { PersonRow } from "./PersonRow";
import { columnDefaultVisibility } from "./Columns";

type TableRowProps<T extends PersonRow> = {
  row: Row<T>;
  setHoveredRow: (rowId: string) => void;
  onRowClick: (row: T) => void;
  // Note: these are all helpers to force renders
  rowSelection: boolean;
  isRowHovered: boolean;
  visibilityKey: number;
};

const getCommonPinningStyles = <T extends PersonRow>(column: Column<T, unknown>, header = false): CSSProperties => {
  const colIsPinned = column.id === "name";
  let z;
  if (colIsPinned) {
    if (header) {
      z = 50;
    } else {
      z = 40;
    }
  } else {
    if (header) {
      z = 40;
    } else {
      z = 0;
    }
  }
  return {
    left: colIsPinned ? 0 : undefined,
    position: colIsPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: z,
    backgroundColor: "white",
    borderWidth: colIsPinned ? "0px 2px 0px 0px" : undefined
  };
};
// NOTE: we are passing rowSelection and isRowHovered as props to avoid re-rendering the row when it changes even though it's not used in the render...
const TableRow = React.memo(function TableRowComponent<T extends PersonRow>({ row, setHoveredRow, onRowClick }: TableRowProps<T>) {
  // TODO: add hover background change. complexity is the borders
  return (
    <tr
      className={`border-b border-colors-border-border-primary hover:cursor-pointer`}
      key={row.id}
      data-state={row.getIsSelected() && "selected"}
      onClick={() => {
        onRowClick(row.original);
      }}
      onMouseEnter={() => {
        setHoveredRow(row.id);
      }}
      onMouseLeave={() => {
        setHoveredRow("");
      }}>
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          style={{
            ...getCommonPinningStyles(cell.column),
            width: cell.column.getSize()
          }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}) as <T extends PersonRow>(props: TableRowProps<T>) => React.ReactElement;

// NOTE: helper for dev work without row memoization
export const RawTableRow = <T extends PersonRow>({ row, setHoveredRow, onRowClick }: TableRowProps<T>) => {
  console.log(`Rendering raw row ${row.id} ${row.original.tableRank}`);
  // TODO: add hover background change. complexity is the borders
  return (
    <tr
      className={`border-b border-colors-border-border-primary hover:cursor-pointer`}
      key={row.id}
      data-state={row.getIsSelected() && "selected"}
      onClick={() => {
        onRowClick(row.original);
      }}
      onMouseEnter={() => setHoveredRow(row.id)}
      onMouseLeave={() => setHoveredRow("")}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column), width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

// TODO: option to disable selection
export function AbstractPersonTable<T extends PersonRow>({
  rows,
  columns,
  selectedPeople,
  onSelectedPeopleChange,
  onRowClick,
  tableRef: externalTableRef
}: {
  rows: T[];
  columns: ColumnDef<T>[];
  selectedPeople: number[];
  onSelectedPeopleChange: (selectedPeople: number[]) => void;
  onRowClick: (row: T) => void;
  tableRef?: React.RefObject<HTMLDivElement>;
}) {
  // TODO: do we need this useState + useEffect?
  const [tableRows, setTableRows] = useState(rows);
  useEffect(() => {
    setTableRows(rows);
  }, [rows]);
  const internalTableRef = React.useRef<HTMLDivElement>(null);
  const tableRef = externalTableRef || internalTableRef;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [hoveredRow, setHoveredRow] = React.useState<string>("");
  const rowSelection = React.useMemo(() => {
    return selectedPeople.reduce((acc, idx) => ({ ...acc, [idx]: true }), {});
  }, [selectedPeople]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(columnDefaultVisibility);
  const [visibilityKey, setVisibilityKey] = useState(0);

  const table = useReactTable({
    data: tableRows,
    columns,
    enableRowSelection: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: (updater) => {
      setColumnVisibility(updater);
      // Increment the visibilityKey to force re-render
      setVisibilityKey((prev) => prev + 1);
    },
    state: {
      sorting,
      columnVisibility,
      rowSelection
    },
    meta: {
      hoveredRow
    },
    // getRowId: (row) => row.email,
    // TODO: this is cursor auto-gen'd code, seems to work
    onRowSelectionChange: (updaterOrValue) => {
      if (typeof updaterOrValue === "function") {
        const currentSelection = selectedPeople;
        const newSelection = updaterOrValue(currentSelection.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}));
        const newSelectedPeople = Object.keys(newSelection)
          .filter((key) => newSelection[key])
          .map(Number);
        onSelectedPeopleChange(newSelectedPeople);
      } else {
        const newSelectedPeople = Object.keys(updaterOrValue)
          .filter((key) => updaterOrValue[key])
          .map(Number);
        onSelectedPeopleChange(newSelectedPeople);
      }
    }
  });

  return (
    <div className="w-full flex min-h-1">
      <div ref={tableRef} className="rounded-md border border-colors-border-border-primary bg-white overflow-auto ">
        <table className="border-separate w-max">
          <thead className="bg-white sticky top-0 shadow group z-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border-b border-colors-border-border-primary text-left font-medium text-colors-text-text-tertiary-(600) items-start text-xs bg-colors-gray-modern-50">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      className="font-medium group pr-1"
                      colSpan={header.colSpan}
                      style={{ ...getCommonPinningStyles(header.column) }}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.id !== "name" &&
                        header.id !== "trash" &&
                        header.id !== "tableRank" &&
                        header.id !== "includedInBenchmark" && (
                          <div
                            {...{
                              onDoubleClick: () => header.column.resetSize(),
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `w-1 h-full group-hover:bg-colors-gray-modern-300 absolute top-0 right-0 cursor-col-resize ${
                                table.options.columnResizeDirection
                              } ${header.column.getIsResizing() ? "bg-colors-gray-modern-300" : ""}`
                            }}
                          />
                        )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="text-xs">
            {table.getRowModel().rows?.length ? (
              table
                .getRowModel()
                .rows.map((row) => (
                  <TableRow<T>
                    key={row.id}
                    row={row}
                    setHoveredRow={setHoveredRow}
                    onRowClick={onRowClick}
                    rowSelection={row.getIsSelected()}
                    isRowHovered={table?.options?.meta?.hoveredRow === row.id}
                    visibilityKey={visibilityKey}
                  />
                ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="h-24 text-center">
                  {"No people..."}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
