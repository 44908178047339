import { configureStore } from "@reduxjs/toolkit";
import sidebarsReducer from "./sidebars";
import genericWorkflows from "./genericWorkflows";
import userReducer from "./user";
import companyProfilesReducer, { companyProfilesUpdateMiddleware } from "./companyProfiles";
import companyListsReducer from "./companyLists";
import viewsReducer from "./views";
import { companyListsUpdateMiddleware } from "./companyLists";
import documentsReducer from "./documents";
import ingestedCompaniesReducer from "./ingestedCompanies";
import taxonomies from "./taxonomies";
import rubricScoringReducer from "./rubricScoring";
import peopleListsReducer, { peopleListsUpdateMiddleware } from "./peopleLists";
import deprCompanySearchesReducer from "./deprCompanySearches";
import { deprCompanySearchesUpdateMiddleware } from "./deprCompanySearches";
import companySearchesReducer from "./companySearches";
import { companySearchesUpdateMiddleware } from "./companySearches";

export const store = configureStore({
  reducer: {
    sidebars: sidebarsReducer,
    genericWorkflows: genericWorkflows,
    user: userReducer,
    companyProfiles: companyProfilesReducer,
    companyLists: companyListsReducer,
    views: viewsReducer,
    documents: documentsReducer,
    ingestedCompanies: ingestedCompaniesReducer,
    taxonomies: taxonomies,
    rubricScoring: rubricScoringReducer,
    peopleLists: peopleListsReducer,
    deprCompanySearches: deprCompanySearchesReducer,
    companySearches: companySearchesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      companyListsUpdateMiddleware,
      companyProfilesUpdateMiddleware,
      peopleListsUpdateMiddleware,
      deprCompanySearchesUpdateMiddleware,
      companySearchesUpdateMiddleware
    )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
