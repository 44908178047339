/* eslint-disable @typescript-eslint/no-unused-vars */
import { FrontendCompany } from "@/services/autogen";
import {
  addCompaniesFromMarketMapToCompanyList,
  addCompaniesToCompanyList,
  deleteCompanyList,
  getCompanyLists,
  updateCompanyList
} from "@/services/brain-api.service";
import { createAsyncThunk, createSlice, Middleware } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export type CompanyList = {
  id: string;
  title: string;
  companies: FrontendCompany[];
};

export type CompanyListsState = {
  activeCompanyListId?: string;
  companyLists: CompanyList[];
};

const initialState: CompanyListsState = {
  companyLists: []
};

export const fetchCompanyListsThunk = createAsyncThunk("fetchCompanyLists", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getCompanyLists(token).then((response) => {
    if (response.status === 200) {
      const companyLists = response.data;
      thunkAPI.dispatch(companyListsSlice.actions.loadCompanyLists({ companyLists }));
    }
  });
});

export const addCompaniesFromMarketMapToCompanyListThunk = createAsyncThunk(
  "addCompaniesFromMarketMapToCompanyList",
  async (thunkPayload: { token: string; company_list_id: string; market_map_image_base64_encoded: string }, thunkAPI) => {
    const { token, company_list_id, market_map_image_base64_encoded } = thunkPayload;
    addCompaniesFromMarketMapToCompanyList(token, company_list_id, market_map_image_base64_encoded).then((response) => {
      if (response.status === 200) {
        const companyList = response.data;
        thunkAPI.dispatch(companyListsSlice.actions.loadCompanyList({ companyList }));
      }
    });
  }
);

export const addCompaniesToCompanyListThunk = createAsyncThunk(
  "addCompaniesToCompanyList",
  async (thunkPayload: { token: string; company_list_id: string; company_domains: string[] }, thunkAPI) => {
    const { token, company_list_id, company_domains } = thunkPayload;
    addCompaniesToCompanyList(token, company_list_id, company_domains).then((response) => {
      if (response.status === 200) {
        const companyList = response.data;
        thunkAPI.dispatch(companyListsSlice.actions.loadCompanyList({ companyList }));
      }
    });
  }
);

export const companyListsSlice = createSlice({
  name: "companyLists",
  initialState,
  reducers: {
    setActiveCompanyList: (state, action: PayloadAction<{ companyListId: string }>) => {
      const { companyListId } = action.payload;
      state.activeCompanyListId = companyListId;
    },
    loadCompanyList: (state, action: PayloadAction<{ companyList: CompanyList }>) => {
      const { companyList } = action.payload;
      const existingCompanyIndex = state.companyLists.findIndex((list) => list.id === companyList.id);
      if (existingCompanyIndex === -1) {
        // Company list not found, add it to the array
        state.companyLists.push(companyList);
      } else {
        // Company list found, update it
        state.companyLists[existingCompanyIndex] = companyList;
      }
    },
    loadCompanyLists: (state, action: PayloadAction<{ companyLists: CompanyList[] }>) => {
      const { companyLists } = action.payload;
      state.companyLists = companyLists;
    },
    addCompanyToList: (state, action: PayloadAction<{ companyListId: string; company: FrontendCompany }>) => {
      const { companyListId, company } = action.payload;
      const companyList = state.companyLists.find((list) => list.id === companyListId);
      if (companyList) {
        const existingCompany = companyList.companies.find((c) => c.domain === company.domain);
        if (!existingCompany) {
          companyList.companies.push(company);
        }
      }
    },
    addCompaniesToList: (state, action: PayloadAction<{ companyListId: string; companies: FrontendCompany[] }>) => {
      const { companyListId, companies } = action.payload;
      const companyList = state.companyLists.find((list) => list.id === companyListId);
      if (companyList) {
        const uniqueCompanies = companies.filter(
          (company) => !companyList.companies.some((existingCompany) => existingCompany.domain === company.domain)
        );
        companyList.companies.push(...uniqueCompanies);
      }
    },
    createCompanyList: (state, action: PayloadAction<{ title: string; companies?: FrontendCompany[] }>) => {
      const { title, companies } = action.payload;
      // should generate a random 24-char hex string
      const companyListId = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
      const newCompanyList: CompanyList = {
        id: companyListId,
        title,
        companies: companies || []
      };
      state.companyLists.push(newCompanyList);
      state.activeCompanyListId = companyListId;
    },
    removeCompanyList: (state, action: PayloadAction<{ companyListId: string }>) => {
      const { companyListId } = action.payload;
      state.companyLists = state.companyLists.filter((list) => list.id !== companyListId);
    },
    removeCompanyFromList: (state, action: PayloadAction<{ companyListId: string; companyDomain: string }>) => {
      const { companyListId, companyDomain } = action.payload;
      const companyList = state.companyLists.find((list) => list.id === companyListId);
      if (companyList) {
        companyList.companies = companyList.companies.filter((c) => c.domain !== companyDomain);
      }
    },
    editCompanyListTitle: (state, action: PayloadAction<{ companyListId: string; title: string }>) => {
      const { companyListId, title } = action.payload;
      const companyList = state.companyLists.find((list) => list.id === companyListId);
      if (companyList) {
        companyList.title = title;
      }
    }
  }
});

export const companyListsUpdateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action); // Dispatch action to reducers, state is updated here
  // List of actions that should trigger a backend update
  const actionsToSync = [
    "companyLists/addCompanyToList",
    "companyLists/addCompaniesToList",
    "companyLists/removeCompanyFromList",
    "companyLists/editCompanyListTitle"
  ];

  if (actionsToSync.includes(action.type)) {
    console.log("syncing");
    const state = storeAPI.getState() as RootState;
    const { companyListId } = action.payload;
    const companyList = state.companyLists.companyLists.find((list) => list.id === companyListId);
    console.log("companyList", action.payload);
    if (companyList && state.user.token) {
      console.log("syncing", companyListId);
      updateCompanyList(state.user.token, companyList);
    }
  } else if (action.type === "companyLists/createCompanyList") {
    console.log("creating company list");
    const state = storeAPI.getState() as RootState;
    // HACK: assume the newly created list is at the end of the list
    const companyList = state.companyLists.companyLists[state.companyLists.companyLists.length - 1];
    if (companyList && state.user.token) {
      console.log("syncing", companyList.id);
      updateCompanyList(state.user.token, companyList);
    }
  } else if (action.type === "companyLists/removeCompanyList") {
    console.log("removing company list");
    const state = storeAPI.getState() as RootState;
    const { companyListId } = action.payload;
    if (state.user.token) {
      deleteCompanyList(state.user.token, companyListId);
    }
  }
  return result;
};

// Action creators are generated for each case reducer function
export const {
  setActiveCompanyList,
  loadCompanyList,
  addCompanyToList,
  addCompaniesToList,
  createCompanyList,
  removeCompanyList,
  removeCompanyFromList,
  editCompanyListTitle
} = companyListsSlice.actions;

export default companyListsSlice.reducer;
