/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FrontendDocument } from "@/services/autogen";
import { getInternalDocuments, ingestDocument } from "@/services/brain-api.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export type DocumentsState = {
  documents: FrontendDocument[];
};

const initialState: DocumentsState = {
  documents: []
};

export const fetchInternalDocumentsThunk = createAsyncThunk("fetchInternalDocuments", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getInternalDocuments(token).then((response) => {
    const documents = response.data;
    thunkAPI.dispatch(documentsSlice.actions.loadDocuments({ documents }));
  });
});

export const ingestDocumentThunk = createAsyncThunk(
  "ingestDocument",
  async (thunkPayload: { token: string; document_src: string }, thunkAPI) => {
    const { token, document_src } = thunkPayload;
    ingestDocument(token, document_src).then((response) => {
      const document = response.data;
      thunkAPI.dispatch(documentsSlice.actions.loadDocument({ document }));
    });
  }
);

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    loadDocuments: (state, action: PayloadAction<{ documents: FrontendDocument[] }>) => {
      const { documents } = action.payload;

      state.documents = documents;
    },
    loadDocument: (state, action: PayloadAction<{ document: FrontendDocument }>) => {
      const { document } = action.payload;
      state.documents = [document, ...state.documents];
    }
  }
});

// Action creators are generated for each case reducer function
export const { loadDocuments, loadDocument } = documentsSlice.actions;

export default documentsSlice.reducer;
