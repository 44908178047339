import {
  checkActiveSubscription,
  fetchUserRunsAvailable,
  getAllCreditsRemaining,
  getCrmOwners,
  getEnrichmentsAdminInfo,
  getUserContactCreditsAvailable,
  getUserDefaultCompanySearchParameters,
  setUserDefaultCompanySearchParameters
} from "@/services/brain-api.service";
import {
  CrmOwner,
  EnrichmentsAdminInfo,
  FrontendCompanySearchParameters,
  ImportableSearchScope,
  MeticulateSubscriptionType
} from "@/services/autogen";
import { getUserDefaultScope } from "@/services/brain-api.service";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type UserState = {
  deprDefaultScope?: ImportableSearchScope;
  defaultCompanySearchParameters?: FrontendCompanySearchParameters;
  token?: string;
  runsAvailable?: number; // search credits
  deprActiveSubscription: boolean;
  subscriptionType: MeticulateSubscriptionType;
  enrichmentsAdminInfo?: EnrichmentsAdminInfo;
  contactCreditsAvailable?: number;
  companyCreditsAvailable?: number;
  // TODO: move this somewhere else?
  crmOwners: CrmOwner[];
};

const initialState: UserState = {
  runsAvailable: 0,
  deprActiveSubscription: false,
  subscriptionType: MeticulateSubscriptionType.Free,
  contactCreditsAvailable: 0,
  companyCreditsAvailable: 0,
  crmOwners: []
};

export const deprLoadUserDefaultScope = createAsyncThunk("loadUserDefaultScope", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getUserDefaultScope(token).then((response) => {
    if (response.status === 200) {
      const responseScope = response.data;
      thunkAPI.dispatch(userSlice.actions.setDefaultScope(responseScope));
    }
  });
});

export const loadUserDefaultCompanySearchParametersThunk = createAsyncThunk(
  "loadUserDefaultCompanySearchParameters",
  async (thunkPayload: { token: string }, thunkAPI) => {
    const { token } = thunkPayload;
    getUserDefaultCompanySearchParameters(token).then((response) => {
      if (response.status === 200) {
        const responseParameters = response.data;
        thunkAPI.dispatch(userSlice.actions.setDefaultCompanySearchParameters(responseParameters));
      }
    });
  }
);

export const setUserDefaultCompanySearchParametersThunk = createAsyncThunk(
  "setUserDefaultCompanySearchParameters",
  async (thunkPayload: { token: string; parameters: FrontendCompanySearchParameters }, thunkAPI) => {
    const { token, parameters } = thunkPayload;
    thunkAPI.dispatch(userSlice.actions.setDefaultCompanySearchParameters(parameters));
    await setUserDefaultCompanySearchParameters(token, parameters);
  }
);

export const fetchUserRunsAvailableThunk = createAsyncThunk("fetchUserRunsAvailable", async (token: string, thunkAPI) => {
  fetchUserRunsAvailable(token).then((response) => {
    if (response.status === 200) {
      const runsAvailable = response.data;
      thunkAPI.dispatch(userSlice.actions.updateUserRunsAvailable(runsAvailable));
    }
  });
});

export const checkActiveSubscriptionThunk = createAsyncThunk("checkActiveSubscription", async (token: string, thunkAPI) => {
  checkActiveSubscription(token).then((response) => {
    if (response.status === 200) {
      const subscriptionType = response.data;
      thunkAPI.dispatch(userSlice.actions.updateSubscriptionType(subscriptionType));
    }
  });
});

export const getEnrichmentsAdminInfoThunk = createAsyncThunk("getEnrichmentsAdminInfo", async (token: string, thunkAPI) => {
  getEnrichmentsAdminInfo(token).then((response) => {
    if (response.status === 200) {
      const enrichmentsAdminInfo = response.data;
      thunkAPI.dispatch(userSlice.actions.updateEnrichmentsAdminInfo(enrichmentsAdminInfo));
    }
  });
});

export const getUserContactCreditsAvailableThunk = createAsyncThunk("getUserContactCreditsAvailable", async (token: string, thunkAPI) => {
  getUserContactCreditsAvailable(token).then((response) => {
    if (response.status === 200) {
      const contactCreditsAvailable = response.data;
      thunkAPI.dispatch(userSlice.actions.updateUserContactCreditsAvailable(contactCreditsAvailable));
    }
  });
});

export const getAllCreditsRemainingThunk = createAsyncThunk("getAllCreditsRemaining", async (token: string, thunkAPI) => {
  getAllCreditsRemaining(token).then((response) => {
    if (response.status === 200) {
      const creditsLeft = response.data;
      thunkAPI.dispatch(userSlice.actions.updateUserCompanyCreditsAvailable(creditsLeft.company_credits_remaining));
      thunkAPI.dispatch(userSlice.actions.updateUserContactCreditsAvailable(creditsLeft.contact_credits_remaining));
      thunkAPI.dispatch(userSlice.actions.updateUserRunsAvailable(creditsLeft.search_credits_remaining));
    }
  });
});

export const getCrmOwnersThunk = createAsyncThunk("getCrmOwners", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getCrmOwners(token).then((response) => {
    if (response.status === 200) {
      const crmOwners = response.data;
      thunkAPI.dispatch(userSlice.actions.updateCrmOwners(crmOwners));
    }
  });
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDefaultScope: (state, action: PayloadAction<ImportableSearchScope>) => {
      state.deprDefaultScope = action.payload;
    },
    setDefaultCompanySearchParameters: (state, action: PayloadAction<FrontendCompanySearchParameters>) => {
      state.defaultCompanySearchParameters = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    updateUserRunsAvailable: (state, action: PayloadAction<number | undefined>) => {
      state.runsAvailable = action.payload;
    },
    updateUserContactCreditsAvailable: (state, action: PayloadAction<number | undefined>) => {
      state.contactCreditsAvailable = action.payload;
    },
    updateUserCompanyCreditsAvailable: (state, action: PayloadAction<number | undefined>) => {
      state.companyCreditsAvailable = action.payload;
    },
    updateActiveSubscription: (state, action: PayloadAction<boolean>) => {
      state.deprActiveSubscription = action.payload;
    },
    updateSubscriptionType: (state, action: PayloadAction<MeticulateSubscriptionType>) => {
      state.subscriptionType = action.payload;
    },
    updateEnrichmentsAdminInfo: (state, action: PayloadAction<EnrichmentsAdminInfo>) => {
      state.enrichmentsAdminInfo = action.payload;
    },
    updateCrmOwners: (state, action: PayloadAction<CrmOwner[]>) => {
      state.crmOwners = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDefaultScope, setUserToken } = userSlice.actions;

export default userSlice.reducer;
