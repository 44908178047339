import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useState } from "react";
import { DocumentType, FrontendDocument } from "@/services/autogen";
import {
  Browser,
  Cards,
  Chats,
  Globe,
  IdentificationCard,
  MegaphoneSimple,
  NewspaperClipping,
  Note,
  NumberSquareTwo
} from "@phosphor-icons/react";
import { BookLock, BookText, FileText, Phone, Upload } from "lucide-react";
import { CompanyLogo } from "../ui/avatar";
import { getDomainFromUrl } from "@/lib/utils";
import { getDocumentPdf } from "@/services/brain-api.service";
import { useAuth } from "@clerk/clerk-react";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "../ui/dialog";
import { DocumentUpload } from "./DocumentUpload";
import { ingestDocumentThunk } from "@/store/documents";
import { toast } from "sonner";
import { DocumentTable } from "./DocumentTable/DocumentTable";
import MultipleSelector from "../ui/multiselect";

export const DocumentTypeIcon = ({ type }: { type?: DocumentType }) => {
  switch (type) {
    case DocumentType.News:
      return <NewspaperClipping size={20} />;
    case DocumentType.PressRelease:
      return <MegaphoneSimple size={20} />;
    case DocumentType.BlogPost:
      return <Globe size={20} />;
    case DocumentType.Forum:
      return <Chats size={20} />;
    case DocumentType.CompanyProfile:
      return <IdentificationCard size={20} />;
    case DocumentType.CompanyPage:
      return <Browser size={20} />;
    case DocumentType.Other:
      return <FileText size={20} />;
    case DocumentType.Cim:
      return <BookLock size={20} />;
    case DocumentType.TermSheet:
      return <FileText size={20} />;
    case DocumentType.IcMemo:
      return <Note size={20} />;
    case DocumentType.CallNotes:
      return <Phone size={20} />;
    case DocumentType.ResearchReport:
      return <BookText size={20} />;
    case DocumentType.CompanyPresentation:
      return <Cards size={20} />;
    case DocumentType.TwoPager:
      return <NumberSquareTwo size={20} />;
    case DocumentType.Qoe:
      return <FileText size={20} />;
    default:
      return <FileText size={20} />;
  }
};

export const DocumentsScrollDisplay = ({
  documents,
  onClick
}: {
  documents: FrontendDocument[];
  onClick?: (document: FrontendDocument) => void;
}) => {
  return (
    <div className="flex flex-col space-y-8">
      {documents.map((document, i) => {
        // const source = development.sources[0];
        return (
          <div
            className="cursor-pointer border p-2 bg-white rounded-md"
            key={i}
            onClick={() => {
              onClick && onClick(document);
            }}>
            <div className="flex flex-row items-center mb-1 w-full gap-2">
              <DocumentTypeIcon type={document.page_type} />
              {document.url && (
                <CompanyLogo name={getDomainFromUrl(document.url)} website={getDomainFromUrl(document.url)} className="w-6 h-6" />
              )}
              <div>
                <div className="text-colors-text-text-secondary-(700) text-sm font-semibold w-full">{document.title}</div>
                <div className="flex flex-row gap-2 text-colors-text-text-tertiary-(600) font-light text-sm w-full">
                  {document.date_published && <div>{document.date_published.slice(0, 7)}</div>}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 text-xs">
              {document.credibility_level && <div>Credibility: {document.credibility_level}</div>}
              {document.bias_level && <div>Bias: {document.bias_level}</div>}
              {document.information_level && <div>Information level: {document.information_level}</div>}
            </div>
            {/* <div>{document.url}</div> */}
            <div className="text-colors-text-text-tertiary-(600) text-md">{document.summary}</div>

            {document.company_prominences && Object.entries(document.company_prominences).length > 0 && (
              <div className="flex flex-row gap-3 flex-wrap mt-2">
                <div className="text-sm font-bold">Companies mentioned: </div>
                {Object.entries(document.company_prominences).map(([company, prominence]) => (
                  <div key={company} className="flex items-center gap-1">
                    <div
                      className={`rounded-full ${
                        prominence === 5
                          ? "shadow-[0_0_10px_3px] shadow-green-600/90"
                          : prominence === 4
                          ? "shadow-[0_0_8px_2px] shadow-green-400/90"
                          : prominence === 3
                          ? "shadow-[0_0_6px_2px] shadow-green-400/90"
                          : prominence === 2
                          ? "shadow-[0_0_4px_1px] shadow-green-300/90"
                          : "shadow-[0_0_4px_1px] shadow-gray-300/90"
                      }`}
                      title={`Prominence: ${prominence}`}>
                      <CompanyLogo name={company} website={company} className="w-6 h-6" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const DOCUMENT_TYPE_OPTIONS = [
  { value: DocumentType.News, label: "News" },
  { value: DocumentType.PressRelease, label: "Press Release" },
  { value: DocumentType.BlogPost, label: "Blog Post" },
  { value: DocumentType.Forum, label: "Forum" },
  { value: DocumentType.CompanyProfile, label: "Company Profile" },
  { value: DocumentType.CompanyPage, label: "Company Page" },
  { value: DocumentType.Other, label: "Other" },
  { value: DocumentType.Cim, label: "CIM" },
  { value: DocumentType.TermSheet, label: "Term Sheet" },
  { value: DocumentType.IcMemo, label: "IC Memo" },
  { value: DocumentType.CallNotes, label: "Call Notes" },
  { value: DocumentType.ResearchReport, label: "Research Report" },
  { value: DocumentType.Qoe, label: "Quality of Earnings" },
  { value: DocumentType.CompanyPresentation, label: "Company Presentation" },
  { value: DocumentType.TwoPager, label: "Two Pager" }
];

export const DocumentsViewer = () => {
  const documents = useAppSelector((state) => state.documents.documents);
  const [pdfSrc, setPdfSrc] = useState<string>("");
  const [uploadingDocumentSrc, setUploadingDocumentSrc] = useState<string>("");
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const onClick = async (frontendDoc: FrontendDocument) => {
    const token = await getToken();
    if (!token) return;
    setPdfSrc("");
    getDocumentPdf(token, frontendDoc.url).then((res) => {
      if (res.status === 200) {
        const pdfFile = res.data;
        console.log("pdfFile", pdfFile);
        const blob = new Blob([pdfFile], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        console.log("pdfUrl", pdfUrl);
        setPdfSrc(pdfUrl);
      }
    });
  };
  const [docTypeFilters, setDocTypeFilters] = useState<DocumentType[]>([]);
  const [domainFilters, setDomainFilters] = useState<string[]>([]);

  const filteredDocuments = documents.filter((doc) => {
    if (docTypeFilters.length > 0 && doc.page_type != null && !docTypeFilters.includes(doc.page_type)) {
      return false;
    }
    if (domainFilters.length > 0) {
      const docCompanies = doc.company_domains ?? [];
      if (!domainFilters.every((domain) => docCompanies.includes(domain))) {
        return false;
      }
    }
    return true;
  });
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row mb-4 gap-4 justify-between w-full">
        <div className="flex flex-row gap-2 w-[500px]">
          <MultipleSelector
            // onSearch={HqOnSearchByCountryLabel}
            hidePlaceholderWhenSelected={true}
            value={domainFilters.map((domain) => ({ value: domain, label: domain }))}
            onChange={(options) => setDomainFilters(options.map((option) => option.value))}
            // defaultOptions={domainFilters}
            placeholder="Filter by company domain"
            emptyIndicator={<p className="text-center text-sm leading-10 text-gray-600 dark:text-gray-400">No results found.</p>}
            creatable={true}
          />
          <MultipleSelector
            // onSearch={HqOnSearchByCountryLabel}
            hidePlaceholderWhenSelected={true}
            value={docTypeFilters.map((domain) => ({ value: domain, label: domain }))}
            onChange={(options) => setDocTypeFilters(options.map((option) => option.value as DocumentType))}
            // defaultOptions={DOCUMENT_TYPE_OPTIONS}
            options={DOCUMENT_TYPE_OPTIONS}
            placeholder="Filter by document type"
            emptyIndicator={<p className="text-center text-sm leading-10 text-gray-600 dark:text-gray-400">No results found.</p>}
          />
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button className="gap-2">
              <Upload size={20} /> Upload
            </Button>
          </DialogTrigger>
          <DialogContent className="w-[400px]">
            <DocumentUpload onDocumentUpload={setUploadingDocumentSrc} />
            <DialogClose asChild>
              <Button
                onClick={async () => {
                  const token = await getToken();
                  if (token == null || uploadingDocumentSrc == null) {
                    return;
                  }
                  toast.info("Ingesting document...");
                  dispatch(
                    ingestDocumentThunk({
                      token: token,
                      document_src: uploadingDocumentSrc
                    })
                  );
                }}>
                Upload document
              </Button>
            </DialogClose>
          </DialogContent>
        </Dialog>
      </div>
      <div className="flex flex-row w-full h-full">
        <div className={`flex flex-col h-full pr-10 gap-2 ${pdfSrc !== "" ? "w-1/2" : "w-full"}`}>
          {/* <DocumentsScrollDisplay documents={documents} onClick={onClick} /> */}

          <DocumentTable internalDocuments={filteredDocuments} externalDocuments={[]} onDocumentClick={onClick} isRunning={false} />
        </div>
        <div className={`w-1/2 ${pdfSrc !== "" ? "flex" : "hidden"}`}>
          {pdfSrc != "" && (
            <div className="flex flex-col w-full h-full">
              <Button onClick={() => setPdfSrc("")} className="self-end w-6" variant="light_destructive">
                X
              </Button>
              <iframe src={pdfSrc} className="w-full h-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
