import "./App.css";
import { useEffect } from "react";
import { useAppDispatch } from "@/store/hooks";
import { useAuth, useOrganizationList } from "@clerk/clerk-react";
import { Toaster } from "sonner";
import { useUser } from "@clerk/clerk-react";
import posthog from "posthog-js";
import "./utils/typeAugmentations";
import {
  checkActiveSubscriptionThunk,
  getAllCreditsRemainingThunk,
  setUserToken,
  loadUserDefaultCompanySearchParametersThunk,
  getCrmOwnersThunk
} from "./store/user";
import { fetchCompanyListsThunk } from "./store/companyLists";
import { Workspace } from "./components/workspace/Workspace";
import { fetchCompanyTearsheetStreamingThunk, fetchLastViewedCompanyIdsThunk, setActiveCompanyProfile } from "./store/companyProfiles";
import { useSearchParams } from "react-router-dom";
import { setActiveView, View } from "./store/views";
import { fetchPeopleListsStubsThunk, fetchPeopleListsThunk, fetchPeopleListThunk, setActivePeopleList } from "./store/peopleLists";
import { deprGetAllCompanySearchesThunk, deprGetAllCompanySearchStubsThunk } from "./store/deprCompanySearches";
import {
  getAllCompanySearchesThunk,
  getAllCompanySearchStubsThunk,
  getCompanySearchThunk,
  setActiveCompanySearch
} from "./store/companySearches";

function App() {
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();
  const { userMemberships, isLoaded, setActive } = useOrganizationList({ userMemberships: true });
  const [searchParams] = useSearchParams();
  // console.log("userMemberships", userMemberships);

  const dispatchStandardFetches = () => {
    getToken().then((token) => {
      if (token !== null) {
        // Accounting calls
        dispatch(checkActiveSubscriptionThunk(token));
        dispatch(getAllCreditsRemainingThunk(token));

        dispatch(loadUserDefaultCompanySearchParametersThunk({ token }));

        // Stub calls
        dispatch(fetchLastViewedCompanyIdsThunk({ token }));
        dispatch(getAllCompanySearchStubsThunk({ token }));
        dispatch(fetchPeopleListsStubsThunk({ token }));
        // if (demoFeaturesOn) {
        //   dispatch(fetchInternalDocumentsThunk({ token }));
        //   dispatch(fetchIngestedCompaniesThunk({ token }));
        //   dispatch(getEnrichmentsAdminInfoThunk(token));
        //   dispatch(fetchRubricThunk({ token }));
        // }
      }
    });
  };
  const dispatchOrgDependentFetches = () => {
    // console.log("handleTokenAndDispatchFetches");
    // console.log("organization", organization);
    // console.log("userMemberships.count", userMemberships.count);
    // console.log("userMemberships.data", userMemberships.data);
    getToken().then((token) => {
      if (token !== null) {
        dispatch(setUserToken(token));
        // List calls
        dispatch(fetchCompanyListsThunk({ token }));
        dispatch(fetchPeopleListsThunk({ token }));

        // Search calls
        dispatch(getAllCompanySearchesThunk({ token }));

        // CRM calls
        dispatch(getCrmOwnersThunk({ token }));

        // Deprecated
        dispatch(deprGetAllCompanySearchStubsThunk({ token }));
        dispatch(deprGetAllCompanySearchesThunk({ token }));
      }
    });
  };

  // TODO(joseph): This fires twice for some reason (even if you put it in an else above). understand and then stop this nonsense
  // NOTE(joseph): I believe this firing twice problem only happens in dev. Something to do with react doing things twice to confirm consistency
  useEffect(() => {
    // Wait for userMemberships to finish loading before dispatching fetches
    // TODO: dispatch the fetches that don't depend on org here
    if (!isLoaded || userMemberships.isLoading || userMemberships.isFetching) {
      dispatchStandardFetches();
      return;
    }
    if (userMemberships.count > 0) {
      setActive({ organization: userMemberships.data[0].organization.id }).then(() => {
        dispatchOrgDependentFetches();
      });
    } else {
      dispatchOrgDependentFetches();
    }
  }, [isLoaded, userMemberships.isLoading, userMemberships.isFetching]);
  // the tokens expire every minute so refresh them every 30 seconds
  useEffect(() => {
    const fetchTokenAndDispatch = () => {
      // console.log("fetching token");
      getToken().then((token) => {
        if (token !== null) {
          dispatch(setUserToken(token));
        }
      });
    };
    fetchTokenAndDispatch();
    const intervalId = setInterval(fetchTokenAndDispatch, 3000); // Call every 30 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // if there is a tearsheet request, fetch it and set the view to the tearsheet
  useEffect(() => {
    const tearsheetParam = searchParams.get("tearsheet");
    if (tearsheetParam) {
      // Do something based on the query parameter
      getToken().then((token) => {
        // console.log("Tearsheet:", tearsheetParam);
        if (token !== null) {
          dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: tearsheetParam }));
          dispatch(setActiveCompanyProfile({ companyProfileId: tearsheetParam }));
          dispatch(setActiveView(View.Company));
        }
      });
    }
    const companySearchParam = searchParams.get("company_search");
    if (companySearchParam) {
      // console.log("Company search:", companySearchParam);
      getToken().then((token) => {
        if (token !== null) {
          // console.log("Redirecting to company search:", companySearchParam);
          dispatch(getCompanySearchThunk({ token, search_id: companySearchParam }));
          dispatch(setActiveCompanySearch(companySearchParam));
          dispatch(setActiveView(View.CompanySearch));
        }
      });
    }
    const peopleListParam = searchParams.get("people_list");
    if (peopleListParam) {
      getToken().then((token) => {
        if (token !== null) {
          dispatch(fetchPeopleListThunk({ token, peopleListId: peopleListParam }));
          dispatch(setActivePeopleList({ peopleListId: peopleListParam }));
          dispatch(setActiveView(View.PeopleList));
        }
      });
    }
  }, [searchParams]);

  // TODO(joseph): do the same as the above for company searches

  const user = useUser().user;
  const { id: userId, fullName: userName, primaryEmailAddress } = user || {};
  const userEmail = primaryEmailAddress?.emailAddress;
  useEffect(() => {
    posthog.identify(userId, {
      name: userName,
      email: userEmail
    });
  }, [userId]);

  return (
    <>
      <div className="flex app-background w-screen max-w-screen h-screen">
        <Workspace />
      </div>
      <Toaster richColors position="top-center" />
    </>
  );
}

export default App;
