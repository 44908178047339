/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IngestedCompany } from "@/services/autogen";
import { getIngestedCompanies } from "@/services/brain-api.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export type IngestedCompaniesState = {
  ingestedCompanies: IngestedCompany[];
  assessmentFramework: any;
};

const initialState: IngestedCompaniesState = {
  ingestedCompanies: [],
  assessmentFramework: {}
};

export const fetchIngestedCompaniesThunk = createAsyncThunk("fetchIngestedCompanies", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getIngestedCompanies(token).then((response) => {
    const ingestedCompaniesData = response.data;
    const ingestedCompanies = ingestedCompaniesData.ingested_companies || [];
    const assessmentFramework = ingestedCompaniesData.assessment_framework || {};
    thunkAPI.dispatch(ingestedCompaniesSlice.actions.loadIngestedCompanies({ ingestedCompanies, assessmentFramework }));
  });
});

export const ingestedCompaniesSlice = createSlice({
  name: "ingestedCompanies",
  initialState,
  reducers: {
    loadIngestedCompanies: (state, action: PayloadAction<{ ingestedCompanies: IngestedCompany[], assessmentFramework: any }>) => {
      const { ingestedCompanies, assessmentFramework } = action.payload;

      state.ingestedCompanies = ingestedCompanies;
      state.assessmentFramework = assessmentFramework;
    }
  }
});

// Action creators are generated for each case reducer function
export const { loadIngestedCompanies } = ingestedCompaniesSlice.actions;

export default ingestedCompaniesSlice.reducer;
