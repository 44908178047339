import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { CaretDown, Funnel, PaperPlaneRight, Spinner, WarningOctagon } from "@phosphor-icons/react";
import {
  GenericWorkflow,
  HardFilter,
  HardMetric,
  ScoringCriterion,
  SourceCompaniesInputs,
  SourceCompaniesWorkflow,
  SourceCompaniesWorkflowInputGenerationStateEnum,
  WorkflowType
} from "@/services/autogen";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import posthog from "posthog-js";
import { toast } from "sonner";
import _ from "lodash";
import "animate.css";
import { isValidUrl } from "@/lib/utils";
import {
  runGenericWorkflowThunk,
  setInitialInputs,
  setFinalInputs,
  setInputGenerationState,
  sourceCompaniesInitialInputsToInputsThunk
} from "@/store/genericWorkflows";
import { AlertTriangle, Plus, RotateCcw, Trash } from "lucide-react";
import { DeprHardMetricFilterAddButton, HardMetricFilterInput } from "@/components/workflow-inputs/HardFilters";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { Input } from "@/components/ui/input";
import { ExemplarChip } from "@/components/workflow-inputs/ExemplarChip";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { CollapsibleTrigger } from "@radix-ui/react-collapsible";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { Slider } from "@mui/material";
import { DeprCompanyTypeFilterAddButton, CompanyTypeFilterInput } from "@/components/workflow-inputs/CompanyType";
import { HqCountryFilterAddButton, HqCountryFilterInput } from "@/components/workflow-inputs/HqCountry";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group";

enum FrontendScoringWeight {
  High = "high",
  Medium = "medium",
  Low = "low"
}

const frontendScoringWeightToNumber = (weight: FrontendScoringWeight): number => {
  switch (weight) {
    case FrontendScoringWeight.High:
      return 3;
    case FrontendScoringWeight.Medium:
      return 2;
    case FrontendScoringWeight.Low:
      return 1;
    default:
      throw new Error("Invalid scoring weight");
  }
};

const numberToFrontendScoringWeight = (number: number): FrontendScoringWeight => {
  switch (number) {
    case 3:
      return FrontendScoringWeight.High;
    case 2:
      return FrontendScoringWeight.Medium;
    case 1:
      return FrontendScoringWeight.Low;
    default:
      throw new Error("Invalid scoring number");
  }
};

export const SourcingCriterionEditor = ({
  scoringCriterion,
  setScoringCriterion,
  showBadExample,
  title
}: {
  scoringCriterion: ScoringCriterion;
  setScoringCriterion: (scoringCriterion?: ScoringCriterion) => void;
  showBadExample: boolean;
  title: string;
}) => {
  return (
    <div className="flex flex-col gap-2 border-colors-border-border-secondary border rounded-lg p-2 bg-colors-gray-modern-25">
      <div className="flex flex-row justify-between">
        <div className="text-colors-text-text-secondary-(700) font-bold">{title}</div>
        <Button onClick={() => setScoringCriterion(undefined)} variant="destructive">
          <Trash size={16} />
        </Button>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <div className="text-sm text-colors-text-text-secondary-(700) w-20 ">Question</div>
        <AutosizedTextarea
          value={scoringCriterion.question}
          onChange={(e) => setScoringCriterion({ ...scoringCriterion, question: e.target.value })}
        />
      </div>
      <div className="flex flex-row gap-2 items-center">
        <div className="text-sm text-colors-text-text-secondary-(700) w-20 ">Detail</div>
        <AutosizedTextarea
          value={scoringCriterion.detail}
          onChange={(e) => setScoringCriterion({ ...scoringCriterion, detail: e.target.value })}
        />
      </div>
      <div className="flex flex-row gap-2 items-center">
        <div className="text-sm text-colors-text-text-secondary-(700) w-20">Weighting: </div>
        <ToggleGroup
          type="single"
          value={numberToFrontendScoringWeight(scoringCriterion.weight)}
          onValueChange={(value) =>
            setScoringCriterion({ ...scoringCriterion, weight: frontendScoringWeightToNumber(value as FrontendScoringWeight) })
          }>
          <ToggleGroupItem value={FrontendScoringWeight.High}>High</ToggleGroupItem>
          <ToggleGroupItem value={FrontendScoringWeight.Medium}>Medium</ToggleGroupItem>
          <ToggleGroupItem value={FrontendScoringWeight.Low}>Low</ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div className="flex flex-row gap-2">
        <div className="text-sm text-colors-text-text-secondary-(700) w-20">Ideal answer</div>
        <AutosizedTextarea
          value={scoringCriterion.high_score_standard}
          onChange={(e) => setScoringCriterion({ ...scoringCriterion, high_score_standard: e.target.value })}
        />
      </div>
      {showBadExample && (
        <div className="flex flex-row gap-2">
          <div className="text-sm text-colors-text-text-secondary-(700) w-20">Bad answer</div>
          <AutosizedTextarea
            value={scoringCriterion.low_score_standard}
            onChange={(e) => setScoringCriterion({ ...scoringCriterion, low_score_standard: e.target.value })}
          />
        </div>
      )}
    </div>
  );
};

const SourceCompaniesDimensionInputs = ({
  inputs,
  setInputs
}: {
  inputs: SourceCompaniesInputs;
  setInputs: (inputs: SourceCompaniesInputs) => void;
}) => {
  // Count non-null properties
  const nonNullCount = [
    inputs.business_model,
    inputs.main_offerings,
    inputs.geographies,
    inputs.customer_segments,
    inputs.technologies
  ].filter((input) => input != null).length;
  return (
    <>
      <div className="font-semibold my-3 text-colors-brand-800">
        Description: <span>{inputs.category_name}</span>
      </div>
      <div className="text-md mb-1 text-colors-text-text-primary-(900) self-start">Search criteria (only 3 allowed at a time)</div>
      {inputs.business_model != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Business model</div>
            <Button onClick={() => setInputs({ ...inputs, business_model: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>
          <AutosizedTextarea
            // className="border border-colors-border-border-primary bg-transparent rounded-lg px-4 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full overflow-y-auto"
            placeholder="e.g. 'peer-to-peer used furniture marketplace'"
            value={inputs.business_model}
            onChange={(e) => {
              setInputs({ ...inputs, business_model: e.target.value });
            }}
          />
        </>
      )}
      {inputs.main_offerings != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Main offerings</div>
            <Button onClick={() => setInputs({ ...inputs, main_offerings: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            // className="border border-black"
            placeholder="e.g. 'lightweight CRM software', 'voice AI API' '"
            value={inputs.main_offerings}
            onChange={(e) => {
              setInputs({ ...inputs, main_offerings: e.target.value });
            }}
          />
        </>
      )}
      {inputs.customer_segments != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Customer segments</div>
            <Button onClick={() => setInputs({ ...inputs, customer_segments: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            placeholder="e.g. 'financial services firms', or 'e-commerce companies''"
            value={inputs.customer_segments}
            onChange={(e) => {
              setInputs({ ...inputs, customer_segments: e.target.value });
            }}
          />
        </>
      )}
      {inputs.geographies != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Geographies</div>
            <Button onClick={() => setInputs({ ...inputs, geographies: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            placeholder="e.g. 'focus in Americas and Europe'"
            value={inputs.geographies}
            onChange={(e) => {
              setInputs({ ...inputs, geographies: e.target.value });
            }}
          />
        </>
      )}
      {inputs.technologies != null && (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Technologies</div>
            <Button onClick={() => setInputs({ ...inputs, technologies: undefined })} variant="destructive">
              <Trash size={16} />
            </Button>
          </div>{" "}
          <AutosizedTextarea
            placeholder="e.g. 'generative AI', 'flow batteries', or 'real-time collaboration'"
            value={inputs.technologies}
            onChange={(e) => {
              setInputs({ ...inputs, technologies: e.target.value });
            }}
          />
        </>
      )}

      {nonNullCount < 3 && (
        <div className="w-full">
          {inputs.business_model == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setInputs({ ...inputs, business_model: "" });
              }}>
              <Plus className="mr-2" /> Business model
            </Button>
          )}
          {inputs.main_offerings == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setInputs({ ...inputs, main_offerings: "" });
              }}>
              <Plus className="mr-2" /> Main offerings
            </Button>
          )}
          {inputs.customer_segments == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setInputs({ ...inputs, customer_segments: "" });
              }}>
              <Plus className="mr-2" /> Customer segments
            </Button>
          )}
          {inputs.geographies == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setInputs({ ...inputs, geographies: "" });
              }}>
              <Plus className="mr-2" /> Geographies
            </Button>
          )}
          {inputs.technologies == null && (
            <Button
              variant="ghost"
              className="p-2"
              onClick={() => {
                setInputs({ ...inputs, technologies: "" });
              }}>
              <Plus className="mr-2" /> Technologies
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export const SourceCompaniesInputsEdit = ({
  inputs,
  setInputs,
  inputsType
}: {
  inputs: SourceCompaniesInputs;
  setInputs: (inputs: SourceCompaniesInputs) => void;
  inputsType: "initial" | "final";
}) => {
  const defaultScope = useAppSelector((state) => state.user.deprDefaultScope);

  const [invalidDomain, setInvalidDomain] = useState<boolean>(false);
  const [exemplarInput, setExemplarInput] = useState<string>("");
  const [employeeCountFilter, setEmployeeCountFilter] = useState<HardFilter>();
  const [foundedYearFilter, setFoundedYearFilter] = useState<HardFilter>();
  const [noFundraisingFilter, _] = useState<HardFilter>();

  const updateFilters = () => {
    const filters = [employeeCountFilter, foundedYearFilter, noFundraisingFilter].filter((f) => f != null) as HardFilter[];
    setInputs({
      ...inputs,
      filters: filters
    });
  };
  useEffect(() => {
    updateFilters();
  }, [employeeCountFilter, foundedYearFilter, noFundraisingFilter]);
  useEffect(() => {
    const employeeCountFilterFromInputs = inputs.filters?.find((filter) => filter.hard_metric === HardMetric.EmployeeCount);
    if (employeeCountFilterFromInputs) {
      setEmployeeCountFilter(employeeCountFilterFromInputs);
    }
    const foundedYearFilterFromInputs = inputs.filters?.find((filter) => filter.hard_metric === HardMetric.FoundedYear);
    if (foundedYearFilterFromInputs) {
      setFoundedYearFilter(foundedYearFilterFromInputs);
    }
  }, [inputs]);
  return (
    <div className="flex flex-row w-full gap-4">
      <div className="w-1/2">
        {inputsType === "initial" ? (
          <>
            <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">
              Describe the companies you want to find
            </div>
            <input
              className="border border-colors-border-border-primary bg-transparent rounded-lg px-4 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full overflow-y-auto"
              placeholder="e.g. 'peer-to-peer furniture marketplaces in Europe'"
              value={inputs.category_name}
              onChange={(e) => {
                setInputs({ ...inputs, category_name: e.target.value });
              }}
            />
          </>
        ) : (
          <SourceCompaniesDimensionInputs inputs={inputs} setInputs={setInputs} />
        )}
        <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Example companies</div>
        <div className="flex flex-row flex-wrap gap-2">
          {inputs.exemplar_company_domains?.map((business, businessIndex) => {
            return (
              <div key={businessIndex}>
                <ExemplarChip
                  domain={business}
                  onDelete={() =>
                    setInputs({
                      ...inputs,
                      exemplar_company_domains: inputs.exemplar_company_domains?.filter((b) => b !== business)
                    })
                  }
                />
              </div>
            );
          })}
        </div>
        <Popover>
          <SimpleTooltip text="Add an example company by domain">
            <PopoverTrigger asChild>
              <Button variant="ghost" className="p-2">
                <Plus className="mr-2" /> Add example company
              </Button>
            </PopoverTrigger>
          </SimpleTooltip>
          <PopoverContent>
            <div className={`w-72 flex flex-row ${invalidDomain ? "animate__animated animate__headShake" : ""}`}>
              <Input
                placeholder="Company domain"
                value={exemplarInput}
                onChange={(e) => setExemplarInput(e.target.value)}
                className="w-40 mr-5"
              />
              <Button
                onClick={() => {
                  if (!isValidUrl(exemplarInput)) {
                    setInvalidDomain(true);
                    toast.error("Not a valid company domain (website). Please enter a domain like 'google.com' or 'facebook.com'");
                    setTimeout(() => {
                      setInvalidDomain(false);
                    }, 1000);
                    return;
                  }
                  setInputs({
                    ...inputs,
                    exemplar_company_domains: [...(inputs.exemplar_company_domains || []), exemplarInput]
                  });
                }}>
                Add
              </Button>
            </div>
          </PopoverContent>
        </Popover>
        <div className="text-sm mt-3 mb-1 text-colors-text-text-secondary-(700) self-start">Number of companies to bring back</div>
        <div className="flex flex-row justify-between w-full">
          <div className="mr-3 font-semibold">{inputs.requested_number_of_companies ?? 40}</div>
          <Slider
            value={inputs.requested_number_of_companies ?? 40}
            max={100}
            min={20}
            step={20}
            onChange={(_, newValue) => {
              setInputs({
                ...inputs,
                requested_number_of_companies: newValue as number
              });
            }}
            valueLabelDisplay="auto"
            sx={{
              "& .MuiSlider-thumb": {
                color: "#9f78ed"
              },
              "& .MuiSlider-track": {
                color: "#9f78ed"
              },
              "& .MuiSlider-rail": {
                color: "#9f78ed"
              }
            }}
          />
        </div>
        <Collapsible className="w-full">
          <CollapsibleTrigger asChild>
            <Button className="w-full my-2">
              <Funnel size={20} className="mr-2" />
              <span>Narrow your search with filters</span>
              <CaretDown size={20} className="ml-2" />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="flex flex-col gap-2 border-colors-brand-200 border p-2 rounded-lg">
              {/* <DesiredCountriesFilterInput
              filter={initialInputs.company_type_filter}
              setFilter={(filter) => {
                setInitialInputs({ ...initialInputs, company_type_filter: filter });
              }}
            /> */}
              <CompanyTypeFilterInput
                filter={inputs.company_type_filter}
                setFilter={(filter) => {
                  setInputs({ ...inputs, company_type_filter: filter });
                }}
              />
              <HqCountryFilterInput
                hqCountryCodes={inputs.hq_country_filter}
                setHqCountryCodes={(filter) => {
                  setInputs({ ...inputs, hq_country_filter: filter });
                }}
              />
              <HardMetricFilterInput
                metric={HardMetric.EmployeeCount}
                filter={employeeCountFilter}
                setFilter={(filter) => {
                  setEmployeeCountFilter(filter);
                }}
              />
              <HardMetricFilterInput
                metric={HardMetric.FoundedYear}
                filter={foundedYearFilter}
                setFilter={(filter) => {
                  setFoundedYearFilter(filter);
                }}
              />
              {/* <HardMetricFilterInput
              metric={HardMetric.NoFundraisingWindow}
              filter={noFundraisingFilter}
              setFilter={(filter) => {
                setNoFundraisingFilter(filter);
              }}
            /> */}
              <div className="flex flex-row flex-wrap gap-2">
                <DeprCompanyTypeFilterAddButton
                  filter={inputs.company_type_filter}
                  setFilter={(filter) => {
                    setInputs({ ...inputs, company_type_filter: filter });
                  }}
                />
                <HqCountryFilterAddButton
                  hqCountryCodes={inputs.hq_country_filter}
                  setHqCountryCodes={(hqCountryCodes) => {
                    setInputs({ ...inputs, hq_country_filter: hqCountryCodes });
                  }}
                />
                <DeprHardMetricFilterAddButton
                  metric={HardMetric.EmployeeCount}
                  filter={employeeCountFilter}
                  setFilter={(filter) => {
                    setEmployeeCountFilter(filter);
                  }}
                />
                <DeprHardMetricFilterAddButton
                  metric={HardMetric.FoundedYear}
                  filter={foundedYearFilter}
                  setFilter={(filter) => {
                    setFoundedYearFilter(filter);
                  }}
                />
                {/* <HardMetricFilterAddButton
                metric={HardMetric.NoFundraisingWindow}
                filter={noFundraisingFilter}
                setFilter={() => {
                  setNoFundraisingFilter({ hard_metric: HardMetric.NoFundraisingWindow, min: -3, max: 6 });
                }}
              /> */}
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>

      <div className="w-1/2 flex flex-col gap-2">
        <Button onClick={() => setInputs({ ...inputs, ...defaultScope })}>Use default scope</Button>
        {inputs.scoring_criteria &&
          inputs.scoring_criteria.map((scoringCriterion, index) => (
            <SourcingCriterionEditor
              title={`Scoring criteria ${index + 1}`}
              showBadExample={inputsType === "final"}
              scoringCriterion={scoringCriterion}
              setScoringCriterion={(scoringCriterion) => {
                if (scoringCriterion == null) {
                  setInputs({ ...inputs, scoring_criteria: inputs.scoring_criteria?.filter((_, i) => i !== index) });
                } else {
                  setInputs({
                    ...inputs,
                    scoring_criteria: inputs.scoring_criteria?.map((criterion, i) => (i === index ? scoringCriterion : criterion))
                  });
                }
              }}
            />
          ))}
        {(inputs.scoring_criteria == null || inputs.scoring_criteria.length < 3) && (
          <Button
            onClick={() => {
              setInputs({
                ...inputs,
                scoring_criteria: [
                  ...(inputs.scoring_criteria || []),
                  { question: "", low_score_standard: "", high_score_standard: "", weight: 2 }
                ]
              });
            }}>
            <Plus className="mr-2" /> Add scoring criteria
          </Button>
        )}
      </div>
    </div>
  );
};

export const SourceCompaniesWorkflowCreator = ({ workflow }: { workflow: GenericWorkflow }) => {
  const [missingInfo, setMissingInfo] = useState(false);
  const { getToken } = useAuth();
  const [errorExplanation, setErrorExplanation] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
  const stage = sourceCompaniesWorkflow.input_generation_state;
  const initialInputs =
    sourceCompaniesWorkflow.frontend_initial_inputs ??
    ({
      category_name: "",
      exemplar_company_domains: []
    } as SourceCompaniesInputs);
  const finalInputs = sourceCompaniesWorkflow.inputs;

  const setInitialInputsCallback = (inputs: SourceCompaniesInputs) => {
    dispatch(setInitialInputs({ workflowId: workflow.workflow_id, initialInputs: inputs }));
  };
  const setFinalInputsCallback = (inputs: SourceCompaniesInputs) => {
    dispatch(setFinalInputs({ workflowId: workflow.workflow_id, inputs: inputs }));
  };

  const setStage = (stage: SourceCompaniesWorkflowInputGenerationStateEnum) => {
    dispatch(setInputGenerationState({ workflowId: workflow.workflow_id, inputGenerationState: stage }));
  };

  const onRun = async () => {
    if (stage === "initial") {
      if (initialInputs.category_name.split(" ").filter((word) => word.length > 0).length < 2) {
        setMissingInfo(true);
        toast.error("Category description (at least 2 words) is required");
        setTimeout(() => {
          setMissingInfo(false);
        }, 1000);
        return;
      }

      const token = await getToken();
      if (token) {
        dispatch(sourceCompaniesInitialInputsToInputsThunk({ token, workflowId: workflow.workflow_id }));
      } else {
        toast.error("Could not get user session. Try refreshing the page");
      }
    } else {
      if (
        (finalInputs.business_model === "" || finalInputs.business_model == null) &&
        (finalInputs.main_offerings === "" || finalInputs.main_offerings == null) &&
        (finalInputs.customer_segments === "" || finalInputs.customer_segments == null) &&
        (finalInputs.geographies === "" || finalInputs.geographies == null) &&
        (finalInputs.technologies === "" || finalInputs.technologies == null)
      ) {
        setMissingInfo(true);
        toast.error("At least one search criteria is required");
        setTimeout(() => {
          setMissingInfo(false);
        }, 1000);
        return;
      }

      const token = await getToken();

      if (token) {
        dispatch(runGenericWorkflowThunk({ token, workflow: workflow })).then((_) => {
          posthog.capture("user_ran_workflow", {
            type: WorkflowType.FindCompaniesV2,
            inputs: finalInputs,
            workflow_id: workflow.workflow_id
          });
          setStage("initial");
          setErrorExplanation(undefined);
        });
      } else {
        toast.error("Could not get user session. Try refreshing the page");
      }
    }
  };

  return (
    <div
      className={`flex flex-col bg-white border border-colors-border-border-secondary rounded-xl min-h-[302px] w-[900px] items-center ${
        missingInfo ? "animate__animated animate__headShake" : ""
      }`}>
      <div className="border-b border-b-colors-border-border-secondary w-full px-4 py-2">
        <div className="flex justify-between items-center w-full">
          <div className="font-semibold text-lg text-colors-text-text-primary-(900) ">
            Source companies
            <br />
            <span className="text-colors-text-text-secondary-(700) text-xs">
              {stage === "initial" ? "(Step 1 of 2)" : stage === "final" ? "(Step 2 of 2)" : ""}
            </span>
          </div>
          <div className="flex items-center">
            <SimpleTooltip text="Reset inputs">
              {stage === "final" && (
                <Button
                  className="rounded-lg border border-colors-gray-modern-400 px-4 py-2 mr-2 bg-colors-gray-modern-100 hover:bg-colors-gray-modern-300 text-xs"
                  // size="sm"
                  onClick={() => {
                    setStage("initial");
                    setErrorExplanation(undefined);
                    setInitialInputsCallback({
                      ...initialInputs,
                      category_name: ""
                    });
                  }}>
                  <RotateCcw size={16} className="text-colors-text-text-primary-(900)" />
                </Button>
              )}
            </SimpleTooltip>
            <Button
              className="rounded-lg border border-colors-brand-500 px-4 py-2 mr-2 bg-colors-brand-100 hover:bg-colors-brand-300"
              disabled={stage === "loading"}
              onClick={_.debounce(onRun, 400)}>
              <PaperPlaneRight size={22} className="mr-2 text-colors-text-text-primary-(900)" />
              <p className="text-colors-brand-800">{stage === "initial" ? "Next" : "Run"}</p>
            </Button>
          </div>
        </div>
        {/* <div className="text-sm text-colors-text-text-tertiary-(600)">Find companies that fit a description</div> */}
      </div>
      <div className="flex flex-col justify-around items-start flex-1 px-6 py-4 w-[900px]">
        <Collapsible className="w-full">
          <CollapsibleTrigger asChild>
            <div className="flex flex-row items-center cursor-pointer gap-2 bg-colors-blue-100 rounded-lg border-colors-blue-200 border px-2 mb-2 w-full justify-center">
              <WarningOctagon size={20} className="text-colors-blue-800 " />
              <span className="text-colors-text-text-secondary-(700)">Our search has limitations!</span>
              <CaretDown size={20} className="text-colors-blue-800" />
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="text-colors-text-text-secondary-(700) text-xs mt-2 border border-colors-blue-200 p-2 rounded-lg">
              We only support searches for tech companies right now. We can search over companies on the following attributes:
              <li>Products and services</li>
              <li>Business model</li>
              <li>Customer segments</li>
              <li>Geographic presence</li>
              <li>Technologies</li>
              <div className="mt-2 text-colors-success-700">
                Examples of good descriptions:
                <li>"AI-powered accounting firms in the UK"</li>
                <li>"Peer-to-peer furniture marketplaces in Europe"</li>
              </div>
              <div className="mt-2 text-colors-error-700">
                Examples of queries that aren't supported well:
                <li>Companies founded by MIT alumni</li>
                <li>YC startups in the most recent batch</li>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
        {errorExplanation && (
          <div className=" text-sm mt-2 border border-colors-error-500 bg-colors-error-200 text-black p-2 rounded-lg flex flex-row items-center">
            <AlertTriangle size={24} className="mr-2 text-colors-error-500" />
            <div className="w-full">
              We may not be able to find companies for that description. Here's why:
              <br />
              <span className="text-colors-error-900">{errorExplanation}</span>
              <br />
              You can still try to run the search anyway. This check is too aggressive sometimes.
            </div>
          </div>
        )}
        {stage === "initial" && (
          <SourceCompaniesInputsEdit inputs={initialInputs} setInputs={setInitialInputsCallback} inputsType="initial" />
        )}
        {stage === "loading" && (
          <div className="flex flex-col justify-center items-center h-full self-center">
            <Spinner size={24} className="text-colors-brand-800 animate-spin" />
            {/* <RefreshCw size={24} className="mr-4 animate-spin" /> */}
            <div className="text-colors-text-text-secondary-(700) text-sm">
              Don't leave yet! Generating search criteria for your request. Takes ~30 seconds.
            </div>
          </div>
        )}
        {stage === "final" && <SourceCompaniesInputsEdit inputs={finalInputs} setInputs={setFinalInputsCallback} inputsType="final" />}
      </div>
    </div>
  );
};
