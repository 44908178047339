import { RecentDevelopments, Source } from "@/services/autogen";
import { NewspaperClipping } from "@phosphor-icons/react";
import { getDomainFromUrl } from "@/lib/utils";
import { LoadingNullContentsWrapper } from "./LoadingNullContentsWrapper";
import { ensureAbsoluteUrl } from "@/lib/utils";
import { CompanyLogo } from "../ui/avatar";

export const RecentDevelopmentsDisplay = ({ recentDevelopments }: { recentDevelopments: RecentDevelopments }) => {
  const developments = recentDevelopments.developments;
  return (
    <div className="flex flex-col border border-colors-border-border-primary bg-white rounded-xl p-3 w-full">
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <NewspaperClipping size={20} />
        <div>Recent Developments</div>
      </div>
      <div className="flex flex-col space-y-5">
        {developments.map((development, i) => {
          let source: Source | null = null;
          if (development.sources.length > 0) {
            source = development.sources[0];
          }
          return (
            <div key={i}>
              <div
                className="flex flex-row items-center mb-1 w-full cursor-pointer"
                onClick={() => {
                  if (source != null && source.link != null) {
                    window.open(ensureAbsoluteUrl(source.link), "_blank");
                  }
                }}>
                {source != null && source.link && (
                  <CompanyLogo name={getDomainFromUrl(source.link)} website={getDomainFromUrl(source.link)} className="mr-2 w-8 h-8" />
                )}
                <div>
                  <div className="text-colors-text-text-secondary-(700) text-sm font-semibold w-full">{development.title}</div>
                  <div className="flex flex-row gap-2 text-colors-text-text-tertiary-(600) font-light text-sm w-full">
                    {development.date && <div>{new Date(development.date).toISOString().slice(0, 7)}</div>}
                  </div>
                </div>
              </div>
              <div className="text-colors-text-text-tertiary-(600) text-sm mb-1">{development.description}</div>

              <div className="flex flex-row gap-10">
                {development.quotes.map((quote, index) => (
                  <div key={index} className="text-colors-gray-modern-700 text-xs italic rounded-md">
                    {`"${quote}"`}{" "}
                  </div>
                ))}
              </div>
              {/* <SourcesLogoViewer sources={development.sources} label={true} /> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RecentDevelopmentsSummaryContents = ({ recentDevelopments }: { recentDevelopments?: RecentDevelopments }) => {
  if (recentDevelopments == null) {
    return null;
  }
  const developments = recentDevelopments.developments;
  return (
    <div className="flex flex-col space-y-4">
      {developments.map((development, i) => {
        let source;
        if (development.sources.length > 0) {
          source = development.sources[0];
        }
        return (
          <div key={i}>
            <div className="flex flex-row items-center mb-1 w-full">
              {source != null && source.link && (
                <CompanyLogo name={getDomainFromUrl(source.link)} website={getDomainFromUrl(source.link)} className="mr-2 w-8 h-8" />
              )}
              <div>
                <div className="text-colors-text-text-secondary-(700) text-sm font-semibold w-full">{development.title}</div>
                <div className="flex flex-row gap-2 text-colors-text-text-tertiary-(600) font-light text-sm w-full">
                  <div className="flex items-left">
                    {development.date && (
                      <div className="whitespace-nowrap mr-2">{new Date(development.date).toISOString().slice(0, 7)}</div>
                    )}
                    {development.description && (
                      <>
                        <span className="mr-2">•</span>
                        <div className="flex-1 overflow-hidden">
                          <span className="line-clamp-1">{development.description}</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-colors-text-text-tertiary-(600) text-md">{development.description}</div>
              {development.quotes.map((quote, i) => {
                return <div key={i}>{quote}</div>;
              })} */}
            {/* <SourcesLogoViewer sources={development.sources} label={true} /> */}
          </div>
        );
      })}
    </div>
  );
};

export const RecentDevelopmentsSummaryDisplay = ({
  recentDevelopments,
  onDoubleClick,
  loading
}: {
  recentDevelopments?: RecentDevelopments;
  onDoubleClick?: () => void;
  loading: boolean;
}) => {
  return (
    <div
      className="flex flex-col border border-colors-border-border-primary bg-white rounded-xl p-3 w-full cursor-pointer"
      onDoubleClick={onDoubleClick}>
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <NewspaperClipping size={20} />
        <div>Recent Developments</div>
      </div>
      <LoadingNullContentsWrapper
        loading={loading}
        objectIsNull={recentDevelopments == null || recentDevelopments.developments.length === 0}>
        <RecentDevelopmentsSummaryContents recentDevelopments={recentDevelopments} />
      </LoadingNullContentsWrapper>
    </div>
  );
};
