import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { FileText, Inbox, FileSearch } from "lucide-react";

import { useAuth } from "@clerk/clerk-react";
import { setActiveView, View } from "@/store/views";
import { Button } from "../ui/button";

import { useEffect } from "react";

import { demoFeaturesOn } from "@/utils/config";
import { toast } from "sonner";

import { fetchScoredTaxonomyThunk, fetchTaxonomyThunk, setActiveScoredTaxonomyId, setActiveTaxonomyId } from "@/store/taxonomies";
import { Input } from "../ui/input";
import { CompanyDomainAutocompleteForLeftbar } from "../workflow-inputs/companyWebsiteAutocomplete";
import { fetchUserRunsAvailableThunk } from "@/store/user";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { isValidUrl } from "@/lib/utils";
import { Dialog } from "@radix-ui/react-dialog";
import { UpgradeSubscriptionDialogContent } from "./UpgradeSubscription";
import posthog from "posthog-js";
import { fetchCompanyTearsheetStreamingThunk, setActiveCompanyProfile } from "@/store/companyProfiles";
import {
  CompanySearchCreationDialogContent,
  CompanySearchCreationDialogTrigger
} from "../company-searches/instructions/CompanySearchCreationDialog";
import { setCompanySearchDialogOpen } from "@/store/companySearches";

export const Dashboard = ({ onCloseRightSection }: { onCloseRightSection: () => void }) => {
  const dispatch = useAppDispatch();
  const companyCreditsAvailable = useAppSelector((state) => state.user.companyCreditsAvailable);
  // TODO: get rid of this and put comp landscape input in the workflow view

  const { getToken } = useAuth();
  useEffect(() => {
    onCloseRightSection();
  }, []);
  const dialogOpen = useAppSelector((state) => state.companySearches.dialogOpen);

  return (
    <div className="flex flex-col gap-10 w-full items-center justify-center p-10 h-full">
      <div className="flex flex-row gap-10 flex-wrap items-center">
        {demoFeaturesOn && (
          <Button onClick={() => dispatch(setActiveView(View.Documents))}>
            <FileText size={24} className="mr-2" /> Internal documents
          </Button>
        )}
        {demoFeaturesOn && (
          <Button onClick={() => dispatch(setActiveView(View.IngestedCompanies))}>
            <Inbox size={24} className="mr-2" /> Company inbox
          </Button>
        )}
        {demoFeaturesOn && (
          <div className="flex flex-row items-center gap-2">
            <Input type="text" placeholder="Enter taxonomy id" className="border border-gray-300 rounded-md px-3 py-2" id="taxonomyInput" />
            <Button
              onClick={async () => {
                const taxonomyName = (document.getElementById("taxonomyInput") as HTMLInputElement).value;
                const token = await getToken();
                if (taxonomyName && token) {
                  dispatch(fetchTaxonomyThunk({ taxonomy_id: taxonomyName }));
                  dispatch(setActiveView(View.Taxonomy));
                  dispatch(setActiveTaxonomyId({ taxonomy_id: taxonomyName }));
                } else {
                  toast.error("Please enter a taxonomy name");
                }
              }}>
              <FileSearch size={24} className="mr-2" /> Load Taxonomy
            </Button>
          </div>
        )}
        {demoFeaturesOn && (
          <div className="flex flex-row items-center gap-2">
            <Input
              type="text"
              placeholder="Enter taxonomy id"
              className="border border-gray-300 rounded-md px-3 py-2"
              id="scoredTaxonomyInput"
            />
            <Button
              onClick={async () => {
                const taxonomyName = (document.getElementById("scoredTaxonomyInput") as HTMLInputElement).value;
                const token = await getToken();
                if (taxonomyName && token) {
                  dispatch(fetchScoredTaxonomyThunk({ taxonomy_id: taxonomyName }));
                  dispatch(setActiveView(View.ScoredTaxonomy));
                  dispatch(setActiveScoredTaxonomyId({ taxonomy_id: taxonomyName }));
                } else {
                  toast.error("Please enter a taxonomy name");
                }
              }}>
              <FileSearch size={24} className="mr-2" /> Load Scored Taxonomy
            </Button>
          </div>
        )}
        {demoFeaturesOn && (
          <Button onClick={() => dispatch(setActiveView(View.EnrichmentsAdmin))}>
            <FileSearch size={24} className="mr-2" /> Admin
          </Button>
        )}
        {demoFeaturesOn && (
          <Button onClick={() => dispatch(setActiveView(View.RubricScoring))}>
            <FileSearch size={24} className="mr-2" /> Rubric Scoring
          </Button>
        )}
      </div>
      <div className="flex flex-col items-center w-full gap-7">
        <div className="w-full text-center text-xl text-colors-gray-modern-400">New search</div>
        <CompanySearchCreationDialogTrigger onOpen={() => dispatch(setCompanySearchDialogOpen(true))} />
        {/* <CompanySearchEntrypoints extraOnClick={() => dispatch(setCompanySearchDialogOpen(true))} /> */}
      </div>
      <Dialog open={dialogOpen} onOpenChange={(open) => dispatch(setCompanySearchDialogOpen(open))}>
        <CompanySearchCreationDialogContent closeDialog={() => dispatch(setCompanySearchDialogOpen(false))} />
      </Dialog>

      {/* transform-style: preserve-3d creates a new stacking context that helps contain the width of the autocomplete dropdown */}
      <div className="flex flex-col items-center w-full max-w-[500px] mt-20 " style={{ transformStyle: "preserve-3d" }}>
        <div className="w-full mb-5 text-center text-xl text-colors-gray-modern-400">Company profiles</div>
        <CompanyDomainAutocompleteForLeftbar
          placeholder="Domain e.g. meticulate.ai"
          onChange={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
          onSubmit={async ({ value }) => {
            if (!isValidUrl(value)) {
              toast.error("Please enter a valid URL");
              return;
            }
            if (companyCreditsAvailable != null && companyCreditsAvailable <= 0) {
              toast.error(
                <div className="flex flex-row gap-4 items-center">
                  You have no credits left.
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button size="sm" onClick={() => posthog.capture("user_clicked_get_more_reports", { variant: "sidebar" })}>
                        Get more credits
                      </Button>
                    </DialogTrigger>
                    <UpgradeSubscriptionDialogContent />
                  </Dialog>
                </div>
              );
              return;
            }
            const token = await getToken();
            if (token !== null) {
              posthog.capture("user_fetched_company_tearsheet", { domain: value });
              dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: value }));
              dispatch(setActiveCompanyProfile({ companyProfileId: value }));
              dispatch(setActiveView(View.Company));
              dispatch(fetchUserRunsAvailableThunk(token));
            }
          }}
        />
      </div>
    </div>
  );
};
