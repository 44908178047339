import { GenericWorkflow, TaskStatus, WorkflowType, WorkflowFeedbackRatingEnum } from "@/services/autogen";
import { AlertTriangle, RefreshCw } from "lucide-react";

import { Copy, ThumbsDown, ThumbsUp } from "lucide-react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group";
import { useAuth } from "@clerk/clerk-react";
import { toast } from "sonner";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { MicrosoftExcelLogo, MicrosoftPowerpointLogo } from "@phosphor-icons/react";
import { Button } from "../ui/button";
import { exportWorkflowToExcel, exportWorkflowToPpt } from "@/services/brain-api.service";
import { copyWorkflowOutputsToClipboard } from "@/lib/utils";
import posthog from "posthog-js";
import { fetchGenericWorkflowThunk, rateWorkflowThunk } from "@/store/genericWorkflows";

import { FindCompaniesV2WorkflowViewer } from "../FindCompaniesV2/FindCompaniesV2WorkflowViewer";
import { CompLandscapeWorkflowViewer } from "../CompLandscapeV7/CompLandscapeWorkflowViewer";
import { MarketMapV2WorkflowViewer } from "../MarketMapV2/MarketMapV2WorkflowViewer";
import { SourceCompaniesWorkflowViewer } from "../SourceCompanies/SourceCompaniesWorkflowViewer";
import { useEffect } from "react";
import { clearRightbarContent } from "@/store/sidebars";

export const ExportWorkflowButton = ({
  workflow,
  disablePpt,
  disableExcel,
  disableCopy
}: {
  workflow: GenericWorkflow;
  disablePpt?: boolean;
  disableExcel?: boolean;
  disableCopy?: boolean;
}) => {
  const workflowId = workflow.workflow_id;
  const { getToken } = useAuth();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {!disableExcel && <MicrosoftExcelLogo size={20} className="text-colors-success-700" />}
          {!disablePpt && <MicrosoftPowerpointLogo size={20} className="mr-2 text-colors-orange-700" />}
          Export
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[9999]">
        {!disableExcel && (
          <DropdownMenuItem>
            <Button
              variant="outline"
              className="w-full"
              onClick={async () => {
                const token = await getToken();
                // copyCategoriesToClipboard(outputs.market_map.categories, outputs.businesses);
                if (token) {
                  toast.success("Your download is starting!");
                  exportWorkflowToExcel(token, workflow).then((response) => {
                    posthog.capture("user_exported_excel", { workflow_id: workflowId });
                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    const filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
                    const extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${filename}.${extension}`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  });
                } else {
                  const distinctId = posthog.get_distinct_id();
                  exportWorkflowToExcel(distinctId, workflow).then((response) => {
                    posthog.capture("user_exported_excel", { workflow_id: workflowId });
                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    const filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
                    const extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${filename}.${extension}`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  });
                }
              }}>
              <MicrosoftExcelLogo size={20} className="mr-2 text-colors-success-700" />
              Download Excel
            </Button>
          </DropdownMenuItem>
        )}
        {!disablePpt && (
          <DropdownMenuItem>
            <Button
              variant="outline"
              className="w-full"
              onClick={async () => {
                const token = await getToken();
                // copyCategoriesToClipboard(outputs.market_map.categories, outputs.businesses);
                if (token) {
                  toast.success("Your download is starting!");
                  exportWorkflowToPpt(token, workflow).then((response) => {
                    posthog.capture("user_exported_powerpoint", { workflow_id: workflowId });
                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    });
                    const filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
                    const extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${filename}.${extension}`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  });
                } else {
                  const distinctId = posthog.get_distinct_id();
                  exportWorkflowToPpt(distinctId, workflow).then((response) => {
                    posthog.capture("user_exported_powerpoint", { workflow_id: workflowId });
                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    });
                    const filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
                    const extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${filename}.${extension}`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  });
                }
              }}>
              <MicrosoftPowerpointLogo size={20} className="mr-2 text-colors-orange-700" />
              Download PPT
            </Button>
          </DropdownMenuItem>
        )}
        {!disableCopy && (
          <DropdownMenuItem>
            <Button
              className="w-full"
              variant="outline"
              onClick={() => {
                copyWorkflowOutputsToClipboard(workflow);
                toast.success("Copied to clipboard! Try pasting into a spreadsheet.");
              }}>
              <Copy size={20} className="mr-2" />
              Copy to clipboard
            </Button>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const WorkflowViewerFeedbackAndExport = ({
  workflow,
  children,
  hideFeedback,
  disableExport,
  disablePpt,
  disableExcel,
  disableCopy
}: {
  workflow: GenericWorkflow;
  children: React.ReactNode;
  hideFeedback?: boolean;
  disableExport?: boolean;
  disablePpt?: boolean;
  disableExcel?: boolean;
  disableCopy?: boolean;
}) => {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const workflowId = workflow.workflow_id;
  return (
    <div className="flex flex-row justify-between w-full">
      <div className="flex flex-row gap-3 flex-wrap">{children}</div>
      <div className="flex flex-row justify-end space-x-4 items-start">
        {!hideFeedback && (
          <ToggleGroup
            variant="outline"
            type="single"
            value={workflow.feedback?.rating ?? ""}
            onValueChange={async (rawVal) => {
              const rating = rawVal === "" ? undefined : (rawVal as WorkflowFeedbackRatingEnum);
              const token = await getToken();
              posthog.capture("user_rated_workflow", { workflow_id: workflowId, rating });
              if (token != null) {
                dispatch(rateWorkflowThunk({ token, workflowId: workflowId, rating })).then(() => toast.info("Thanks for the feedback!"));
              }
            }}>
            <ToggleGroupItem value="good">
              <ThumbsUp className="h-4 w-4 text-colors-success-600" />
            </ToggleGroupItem>
            <ToggleGroupItem value="bad">
              <ThumbsDown className="h-4 w-4 text-colors-error-600" />
            </ToggleGroupItem>
          </ToggleGroup>
        )}
        {!disableExport && (
          <ExportWorkflowButton workflow={workflow} disablePpt={disablePpt} disableExcel={disableExcel} disableCopy={disableCopy} />
        )}
      </div>
    </div>
  );
};

export const WorkflowViewer = ({
  hideRefine,
  onOpenRightSection
}: {
  hideRefine?: boolean;
  hideFeedback?: boolean;
  onCloseRightSection?: () => void;
  onOpenRightSection?: () => void;
}) => {
  const workflow = useAppSelector((state) =>
    state.genericWorkflows.workflows.find((w) => w.workflow_id === state.genericWorkflows.activeWorkflowId)
  );
  const activeWorkflowId = useAppSelector((state) => state.genericWorkflows.activeWorkflowId);
  const dispatch = useAppDispatch();
  const autoFetchWorkflow = async () => {
    if (workflow && workflow.status === TaskStatus.Running) {
      dispatch(fetchGenericWorkflowThunk({ workflow_id: workflow.workflow_id }));
    }
  };

  useEffect(() => {
    let refreshInterval = 30000;
    if (workflow?.workflow_type === WorkflowType.TearSheet || workflow?.workflow_type === WorkflowType.FindCompaniesV2) {
      refreshInterval = 1000;
    }
    // Set up an interval to call fetchWorkflow every {refreshInterval}
    const intervalId = setInterval(autoFetchWorkflow, refreshInterval);

    // Clear the interval if workflow_id or status changes
    return () => clearInterval(intervalId);
  }, [activeWorkflowId, workflow?.status, workflow?.workflow_type]);

  // clear right bar and fetch workflow on active workflow id change
  useEffect(() => {
    if (activeWorkflowId != null) {
      dispatch(clearRightbarContent());
    }
    // Call fetchWorkflow immediately on component mount
    if (activeWorkflowId != null && (workflow == null || (workflow && workflow.status === TaskStatus.Running)))
      dispatch(fetchGenericWorkflowThunk({ workflow_id: activeWorkflowId }));
  }, [activeWorkflowId]);

  if (workflow == null) {
    return <div>No workflow selected</div>;
  }
  let timeToRun;
  switch (workflow.workflow_type) {
    case WorkflowType.CompFinder:
    case WorkflowType.CompLandscapeV7:
    case WorkflowType.MarketMap:
    case WorkflowType.MarketMapV2:
      timeToRun = "10 minutes";
      break;
    case WorkflowType.PublicComps:
      timeToRun = "2 minutes";
      break;
    case WorkflowType.TearSheet:
      timeToRun = "2 minutes";
      break;
    case WorkflowType.PressSearch:
      timeToRun = "2 minutes";
      break;
    case WorkflowType.FindCompanies:
      timeToRun = "2 minutes";
      break;
    case WorkflowType.FindCompaniesV2:
      timeToRun = "4 minutes";
      break;
    case WorkflowType.OfferingDeepdive:
      timeToRun = "2 minutes";
      break;
  }
  if (workflow.status === TaskStatus.Created) {
    if (workflow.workflow_type === WorkflowType.FindCompaniesV2) {
      return <FindCompaniesV2WorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} />;
    } else if (workflow.workflow_type === WorkflowType.SourceCompanies) {
      return <SourceCompaniesWorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} />;
    } else {
      return <MarketMapV2WorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} />;
    }
  }
  if (workflow.status === TaskStatus.Running) {
    // Show the tearsheet while its running
    if (workflow.workflow_type === WorkflowType.FindCompaniesV2) {
      // hideRefine while running
      return <FindCompaniesV2WorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} hideRefine={true} />;
    } else {
      return (
        <div className="self-center border bg-colors-gray-modern-100 p-10 rounded-xl flex flex-row items-center text-colors-text-text-secondary-(700)">
          <RefreshCw size={24} className="mr-4 animate-spin" />
          <div className="flex flex-col">
            <div>This workflow can take up to {timeToRun} to run (it'll be worth it!).</div>
            <div>This page will refresh automatically when it's done, and we'll also send you an email.</div>
          </div>
        </div>
      );
    }
  } else if (workflow.status === TaskStatus.Incomplete) {
    return (
      <div className="self-center border bg-colors-background-bg-error-primary p-10 rounded-xl flex flex-row items-center text-colors-text-text-secondary-(700)">
        <AlertTriangle size={24} className="mr-4" />
        <div className="flex flex-col">
          <div>Uh-oh, we ran into an error trying to run this workflow! </div>
          <div>Try creating a new one on the left...</div>
          {workflow.error_message != null && (
            <div className=" mt-3 text-colors-text-text-tertiary-(600)">{"Details: " + workflow.error_message}</div>
          )}
        </div>
      </div>
    );
  } else if (workflow.status === TaskStatus.Completed) {
    if (workflow.workflow_type === WorkflowType.FindCompaniesV2) {
      return <FindCompaniesV2WorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} hideRefine={hideRefine} />;
    } else if (workflow.workflow_type === WorkflowType.CompLandscapeV7) {
      return <CompLandscapeWorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} />;
    } else if (workflow.workflow_type === WorkflowType.MarketMapV2) {
      return <MarketMapV2WorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} />;
    } else if (workflow.workflow_type === WorkflowType.SourceCompanies) {
      return <SourceCompaniesWorkflowViewer workflow={workflow} onOpenRightSection={onOpenRightSection} />;
    } else {
      return <div>Unknown workflow type</div>;
    }
  }
};
