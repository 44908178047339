import { CustomerClass, JobListing, SalesMotion, TeamDive, TeamMember } from "@/services/autogen";
import { SourcesLogoViewer } from "./SourceViewer";
import { Building2, Handshake, SquareChevronDown, SquareChevronUp, Store } from "lucide-react";
import { Acorn, IdentificationCard, TrendUp } from "@phosphor-icons/react";
import { ensureAbsoluteUrl } from "@/lib/utils";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import { LoadingNullContentsWrapper } from "./LoadingNullContentsWrapper";

export const CustomerClassChip = ({ customerClass }: { customerClass: CustomerClass }) => {
  // TODO: better icons
  switch (customerClass) {
    case CustomerClass.Consumer:
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-full px-2 py-1 text-colors-yellow-800 flex flex-row items-center gap-1 text-sm`}>
          <Store size={14} />
          Consumer
        </div>
      );
    case CustomerClass.Smb:
      return (
        <div
          className={`bg-colors-brand-100 border border-colors-brand-300 rounded-full px-2 py-1 text-colors-brand-800 flex flex-row items-center gap-1 text-sm`}>
          <Acorn size={14} /> SMB
        </div>
      );
    case CustomerClass.MidMarket:
      return (
        <div
          className={`bg-colors-success-100 border border-colors-success-300 rounded-full px-2 py-1 text-colors-success-800 flex flex-row items-center gap-1 text-sm`}>
          <TrendUp size={14} />
          Mid-market
        </div>
      );
    case CustomerClass.Marketplace:
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-full px-2 py-1 text-colors-blue-800 flex flex-row items-center gap-1 text-sm`}>
          <Store size={14} />
          Marketplace
        </div>
      );
    case CustomerClass.LargeEnterprise:
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1 text-sm`}>
          <Building2 size={14} />
          Large Enterprise
        </div>
      );
    case CustomerClass.GovernmentPublicSector:
      return (
        <div
          className={`bg-colors-pink-100 border border-colors-pink-300 rounded-full px-2 py-1 text-colors-pink-800 flex flex-row items-center gap-1 text-sm`}>
          <Handshake size={14} />
          Government/Public Sector
        </div>
      );
    // TODO: defunct and subsidiary
    default:
      return null;
  }
};

export const SalesMotionChip = ({ salesMotion }: { salesMotion: SalesMotion }) => {
  // TODO: better icons
  switch (salesMotion) {
    case SalesMotion.BottomUp:
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-full px-2 py-1 text-colors-yellow-800 flex flex-row items-center gap-1 text-sm`}>
          <Store size={14} />
          Bottom-up
        </div>
      );
    case SalesMotion.TopDown:
      return (
        <div
          className={`bg-colors-brand-100 border border-colors-brand-300 rounded-full px-2 py-1 text-colors-brand-800 flex flex-row items-center gap-1 text-sm`}>
          <Acorn size={14} />
          Top-down
        </div>
      );
    case SalesMotion.ChannelSales:
      return (
        <div
          className={`bg-colors-success-100 border border-colors-success-300 rounded-full px-2 py-1 text-colors-success-800 flex flex-row items-center gap-1 text-sm`}>
          <TrendUp size={14} />
          Channel Sales
        </div>
      );
    case SalesMotion.DistributorRetailer:
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-full px-2 py-1 text-colors-blue-800 flex flex-row items-center gap-1 text-sm`}>
          <Store size={14} />
          Distributor/Retailer
        </div>
      );

    default:
      return null;
  }
};

export const TeamMemberDisplay = ({ teamMember }: { teamMember: TeamMember }) => {
  return (
    <div className="flex flex-row gap-2 items-center w-full">
      <div className="w-full">
        <div className="flex flex-row justify-between">
          <div>
            <span className={`font-semibold ${teamMember.linkedin_url ? "text-colors-blue-dark-800" : "text-colors-gray-modern-600"}`}>
              {teamMember.name + " - "}
            </span>
            <span className="text-colors-text-text-tertiary-(600) font-light text-sm">{teamMember.title}</span>
          </div>
          {teamMember.linkedin_url && (
            <a href={ensureAbsoluteUrl(teamMember.linkedin_url)} target="_blank" rel="noopener noreferrer">
              <img src="/linkedin.png" className="h-6 w-6" />
            </a>
          )}
        </div>
        {teamMember.location && <div className="text-colors-text-text-tertiary-(600) text-xs">{teamMember.location}</div>}
        <div className="text-colors-text-text-tertiary-(600) text-sm">{teamMember.background}</div>
      </div>
    </div>
  );
};

export const JobListingDisplay = ({ linkedinJob: jobListing }: { linkedinJob: JobListing }) => {
  return (
    <div className="flex flex-row gap-2 items-center bg-colors-blue-dark-50 border-colors-blue-dark-400 text-sm p-1 border rounded-lg">
      <div>
        <div className="flex flex-row justify-between">
          <div>
            <span className="text-colors-blue-dark-800 font-semibold text-md">{jobListing.title + " - "}</span>
            <span className="text-colors-blue-dark-600 font-light text-sm">{jobListing.type}</span>
          </div>
        </div>
        <div className="flex flex-row gap-2 text-xs font-light">
          {jobListing.date_posted && (
            <div className="text-colors-text-text-tertiary-(600)">{new Date(jobListing.date_posted).toISOString().slice(0, 7)}</div>
          )}
          <div className="text-colors-text-text-tertiary-(600)">{jobListing.location}</div>
        </div>
        <div className="text-colors-text-text-tertiary-(600)">{jobListing.description}</div>
      </div>
    </div>
  );
};

export const ExpandableContent = ({ children }: { children: React.ReactNode }) => {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setShowButton(contentRef.current.scrollHeight > 300);
    }
  }, []);

  return (
    <div className="relative">
      <div
        ref={contentRef}
        className={`flex flex-col gap-8 transition-[max-height] duration-300 ease-in-out ${
          expanded ? "" : "max-h-[300px] overflow-hidden"
        }`}>
        {children}
      </div>
      {!expanded && showButton && (
        <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-white via-[rgba(255,255,255,0.95)] to-transparent pointer-events-none"></div>
      )}
      {showButton && (
        <Button variant="ghost" className="absolute bottom-0 left-1/2 transform -translate-x-1/2" onClick={() => setExpanded(!expanded)}>
          {expanded ? <SquareChevronUp size={24} /> : <SquareChevronDown size={24} />}
        </Button>
      )}
    </div>
  );
};

export const TeamDiveDisplay = ({ teamDive }: { teamDive: TeamDive }) => {
  return (
    <div className="flex flex-col bg-white border p-3 rounded-lg">
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <IdentificationCard size={20} />
        <div>Team</div>
      </div>
      <div className="flex flex-col gap-3 mb-4 w-full">
        {teamDive.team_members.map((teamMember, index) => (
          <TeamMemberDisplay key={index} teamMember={teamMember} />
        ))}
      </div>
      {teamDive.linkedin_jobs.length > 0 && (
        <>
          <div className="flex flex-col rounded-lg  text-sm font-semibold text-colors-blue-dark-900 mb-1">
            {teamDive.linkedin_jobs.length} job listings
          </div>
          <ExpandableContent>
            <div className="relative">
              <div className="flex flex-row gap-4 flex-wrap">
                {teamDive.linkedin_jobs.map((linkedinJob, index) => (
                  <JobListingDisplay key={index} linkedinJob={linkedinJob} />
                ))}
              </div>
            </div>
          </ExpandableContent>
        </>
      )}
      <SourcesLogoViewer sources={teamDive.sources} label={true} />
    </div>
  );
};

export const TeamDiveSummaryContents = ({ teamDive }: { teamDive?: TeamDive }) => {
  if (teamDive == null) {
    return null;
  }
  return (
    <>
      <div className="flex flex-col gap-3 mb-4">
        {teamDive.team_members.map((teamMember, index) => (
          <TeamMemberDisplay key={index} teamMember={teamMember} />
        ))}
      </div>
      {teamDive.linkedin_jobs.length > 0 && (
        <div className="flex flex-col border p-2 rounded-lg bg-colors-blue-dark-50 border-colors-blue-dark-400 text-sm font-semibold text-colors-blue-dark-800">
          {teamDive.linkedin_jobs.length} job listings
        </div>
      )}
    </>
  );
};

export const TeamDiveSummaryDisplay = ({
  teamDive,
  onDoubleClick,
  loading
}: {
  teamDive?: TeamDive;
  onDoubleClick?: () => void;
  loading: boolean;
}) => {
  return (
    <div className="flex flex-col bg-white border p-3 rounded-lg cursor-pointer" onDoubleClick={onDoubleClick}>
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <IdentificationCard size={20} />
        <div>Team</div>
      </div>
      <LoadingNullContentsWrapper loading={loading} objectIsNull={teamDive == null}>
        <TeamDiveSummaryContents teamDive={teamDive} />
      </LoadingNullContentsWrapper>
    </div>
  );
};
