import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Button } from "../ui/button";
import { useState, useCallback } from "react";
import { useAuth } from "@clerk/clerk-react";
import { Plus, Users } from "lucide-react";
import { toast } from "sonner";
import { Input } from "../ui/input";
import { posthog } from "posthog-js";
import { addToPeopleListWithCompanyContactsThunk, createPeopleListWithCompanyContactsThunk } from "@/store/peopleLists";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import _ from "lodash";
import { AutosizedTextarea } from "../ui/textarea";

export const FindContactsDropdown = ({ domainsSelected, disabled }: { domainsSelected: string[]; disabled?: boolean }) => {
  const { getToken } = useAuth();
  const peopleLists = useAppSelector((state) => state.peopleLists.peopleLists);
  const [buyerPersona, setBuyerPersona] = useState("");
  const [contactsPerCompany, setContactsPerCompany] = useState(1);
  const dispatch = useAppDispatch();
  const contactCreditsAvailable = useAppSelector((state) => state.user.contactCreditsAvailable);
  const [isOpen, setIsOpen] = useState(false);

  const handleGetContacts = useCallback(
    async (peopleListId?: string) => {
      if (domainsSelected.length == 0) {
        toast.error("Please select at least one company");
        return;
      }
      if (buyerPersona.trim() == "") {
        toast.error("Please fill out the buyer persona");
        return;
      }
      if (contactsPerCompany < 1 || contactsPerCompany > 20) {
        toast.error("Contacts per company must be between 1 and 20");
        return;
      }

      const token = await getToken();
      if (token != null) {
        const isNewList = !peopleListId;
        toast.info(
          isNewList
            ? `Getting contacts for ${domainsSelected.length} companies... It will take a few moments and pop up as a new list under People lists on the left bar.`
            : `Adding contacts for ${domainsSelected.length} companies to ${_.truncate(
                peopleLists.find((l) => l.id === peopleListId)?.title ?? "",
                { length: 20 }
              )}... It will take a few moments.`,
          {
            duration: 7000
          }
        );

        posthog.capture("user_added_contacts_to_people_list", {
          people_list_id: peopleListId ?? "NEW_LIST",
          company_domains: domainsSelected,
          persona_description: buyerPersona,
          limit_contacts_per_company: contactsPerCompany
        });
        if (peopleListId == null) {
          dispatch(
            createPeopleListWithCompanyContactsThunk({
              token,
              companyDomains: domainsSelected,
              personaDescription: buyerPersona,
              limitContactsPerCompany: contactsPerCompany
            })
          );
        } else {
          dispatch(
            addToPeopleListWithCompanyContactsThunk({
              token,
              peopleListId,
              companyDomains: domainsSelected,
              personaDescription: buyerPersona,
              limitContactsPerCompany: contactsPerCompany
            })
          );
        }

        setIsOpen(false);
      }
    },
    [domainsSelected, buyerPersona, contactsPerCompany, getToken, dispatch, peopleLists]
  );

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      {domainsSelected.length > 0 && (
        <PopoverTrigger asChild>
          <Button size="sm" disabled={disabled || (contactCreditsAvailable != null && contactCreditsAvailable == 0)}>
            <Users size={20} /> Get contacts
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent className="w-80 max-h-[400px] overflow-y-auto">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-500">Looking for:</div>
          <AutosizedTextarea
            placeholder="Persona, e.g. 'CTO' or 'marketing execs'"
            value={buyerPersona}
            onChange={(e) => setBuyerPersona(e.target.value)}
            onBlur={(e) => setBuyerPersona(e.target.value)}
          />
          <div className="flex flex-row items-center justify-between">
            <div className="text-sm text-gray-500">Contacts per company:</div>
            <div className="flex items-center">
              <Input
                type="number"
                value={contactsPerCompany}
                min={1}
                max={10}
                onChange={(e) => {
                  setContactsPerCompany(parseInt(e.target.value));
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
          <div className="text-sm text-gray-500 mt-2">Add to list</div>
          <div className="flex flex-col gap-2">
            {[...peopleLists].reverse().map((list) => (
              <Button key={list.id} className="w-full text-left" variant="outline" onClick={() => handleGetContacts(list.id)}>
                <span className="overflow-hidden text-ellipsis whitespace-nowrap block">{list.title}</span>
              </Button>
            ))}
            <Button variant="default" className="w-full" onClick={() => handleGetContacts()}>
              <Plus size={16} /> New list
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
