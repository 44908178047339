import { ChevronDown, Plus } from "lucide-react";
import { FrontendScoringQuestion, FrontendSearchInstructions, InfoLevel } from "@/services/autogen";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { FilterHeader } from "@/components/workflow-inputs/FilterHeader";
import { Switch } from "@/components/ui/switch";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

const CompanySearchScoringQuestionEditor = ({
  question,
  setQuestion,
  viewOnly,
  index
}: {
  question?: FrontendScoringQuestion;
  setQuestion: (question?: FrontendScoringQuestion) => void;
  viewOnly?: boolean;
  index: number;
}) => {
  return (
    <div className="bg-colors-green-25 p-4 rounded-lg">
      <FilterHeader
        title={`Scoring question #${index + 1}`}
        // infoText="A question that will be used to score companies"
        onDelete={() => {
          setQuestion(undefined);
        }}
        onAdd={() => {
          setQuestion({
            question: "",
            info_level: InfoLevel.Search,
            preference: {
              high_standard: "",
              // low_standard: "",
              is_required: false,
              weight: 4
            }
          });
        }}
        isActive={question != null}
        viewOnly={viewOnly}
        disableAdd={true}
      />
      {question != null && (
        <div className="flex flex-col gap-2 items-start">
          <div className="flex flex-row items-center gap-2">
            <span>Preferred</span>
            <Switch
              disabled={viewOnly}
              checked={question.preference.is_required}
              onCheckedChange={(e) => {
                setQuestion({ ...question, preference: { ...question.preference, is_required: e } });
              }}
            />
            <span>Required</span>
          </div>
          <Label className="text-xs text-gray-550">
            Question <span className="text-red-500">*</span>
          </Label>
          <AutosizedTextarea
            placeholder="e.g. number of metro areas served"
            value={question.question}
            onChange={(e) => {
              setQuestion({ ...question, question: e.target.value });
            }}
            disabled={viewOnly}
          />
          <Label className="text-xs text-gray-550">
            A high score means... <span className="text-red-500">*</span>
          </Label>
          <AutosizedTextarea
            placeholder="e.g. more than 100 metro areas"
            value={question.preference.high_standard}
            onChange={(e) => {
              setQuestion({ ...question, preference: { ...question.preference, high_standard: e.target.value } });
            }}
            disabled={viewOnly}
          />
          <Collapsible className="w-full" defaultOpen={question.preference.low_standard != null}>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm" className="w-full flex justify-between px-0">
                <Label className="text-xs text-gray-550">A low score means...</Label>
                <ChevronDown className="h-4 w-4" />
              </Button>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <AutosizedTextarea
                placeholder="e.g. less than 10 metro areas"
                value={question.preference.low_standard ?? ""}
                onChange={(e) => {
                  setQuestion({ ...question, preference: { ...question.preference, low_standard: e.target.value } });
                }}
                disabled={viewOnly}
              />
            </CollapsibleContent>
          </Collapsible>
        </div>
      )}
    </div>
  );
};

export const CompanySearchScoringBox = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendSearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2 border-colors-green-200 border-0 p-2 rounded-lg w-[400px]">
      {/* <div className="text-lg font-medium flex flex-row items-center gap-2 w-full justify-between">
        <div className="flex flex-row items-center gap-2">
          <Ranking size={24} />
          <span>Scoring</span>
        </div>
      </div> */}
      {companySearchInstructions.scoring_questions.map((question, idx) => (
        <CompanySearchScoringQuestionEditor
          key={idx}
          index={idx}
          question={question}
          viewOnly={viewOnly}
          setQuestion={(question) => {
            if (question == null) {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_questions: companySearchInstructions.scoring_questions.filter((_, i) => i !== idx)
              });
            } else {
              setCompanySearchInstructions({
                ...companySearchInstructions,
                scoring_questions: companySearchInstructions.scoring_questions.map((q, i) => (i === idx ? question : q))
              });
            }
          }}
        />
      ))}

      {companySearchInstructions.scoring_questions.length < 4 && (
        <Button
          variant="ghost"
          size="sm"
          disabled={viewOnly}
          onClick={() => {
            setCompanySearchInstructions({
              ...companySearchInstructions,
              scoring_questions: [
                ...companySearchInstructions.scoring_questions,
                {
                  question: "",
                  info_level: InfoLevel.Search,
                  preference: {
                    high_standard: "",
                    // low_standard: "",
                    is_required: false,
                    weight: 4
                  }
                }
              ]
            });
          }}>
          <Plus size={16} /> Add custom scoring question
        </Button>
      )}
    </div>
  );
};
