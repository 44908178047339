import { createAsyncThunk, createSlice, Middleware, PayloadAction } from "@reduxjs/toolkit";
import { FrontendSearchInstructions, FrontendFullSearch, FrontendFullSearchStub } from "@/services/autogen";
import {
  deleteCompanySearch,
  descriptionToInstructions,
  expandCompanySearch,
  findSimilarToInstructions,
  getCompanySearches,
  getCompanySearchStubs,
  runCompanySearch,
  updateCompanySearchTitle,
  getCompanySearch,
  markSearchViewed
} from "@/services/brain-api.service";
import { setActiveView, View } from "./views";
import { RootState } from "./store";
import { toast } from "sonner";

interface CompanySearchesState {
  searchInstructions: FrontendSearchInstructions;
  dialogOpen: boolean;
  searches: FrontendFullSearch[];
  stubs: FrontendFullSearchStub[];
  activeSearchId: string | null;
  searchesStartingToRunHack: string[]; // HACK: because expand/rerun takes so long to return first new workflow and we want immediate feedback for the user
  instructionsLoading: boolean;
}

const initialState: CompanySearchesState = {
  searches: [],
  stubs: [],
  activeSearchId: null,
  dialogOpen: false,
  searchesStartingToRunHack: [],
  searchInstructions: {
    numerical_filters: [],
    semantic_items: [],
    scoring_questions: [],
    title: "",
    initial_volume_requested: 50,
    enable_external_search: true,
    company_tags_filter: null,
    company_tags_filter_exclude: null
  },
  instructionsLoading: false
};

export const descriptionToInstructionsThunk = createAsyncThunk(
  "companySearches/descriptionToInstructions",
  async (thunkPayload: { token: string; description: string }, thunkAPI) => {
    const { token, description } = thunkPayload;
    thunkAPI.dispatch(companySearchesSlice.actions.setInstructionsLoading(true));
    descriptionToInstructions(token, description)
      .then((response) => {
        if (response.status === 200) {
          thunkAPI.dispatch(companySearchesSlice.actions.updateGlobalSearchInstructions({ instructions: response.data }));
          thunkAPI.dispatch(companySearchesSlice.actions.setInstructionsLoading(false));
        }
      })
      .catch(() => {
        toast.error("Failed to generate instructions");
        thunkAPI.dispatch(companySearchesSlice.actions.setInstructionsLoading(false));
      });
  }
);

export const findSimilarToInstructionsThunk = createAsyncThunk(
  "companySearches/findSimilarToInstructions",
  async (thunkPayload: { token: string; domain: string }, thunkAPI) => {
    const { token, domain } = thunkPayload;
    thunkAPI.dispatch(companySearchesSlice.actions.setInstructionsLoading(true));
    findSimilarToInstructions(token, domain)
      .then((response) => {
        if (response.status === 200) {
          thunkAPI.dispatch(companySearchesSlice.actions.updateGlobalSearchInstructions({ instructions: response.data }));
          thunkAPI.dispatch(companySearchesSlice.actions.setInstructionsLoading(false));
        }
      })
      .catch(() => {
        toast.error("Failed to generate instructions");
        thunkAPI.dispatch(companySearchesSlice.actions.setInstructionsLoading(false));
      });
  }
);

export const runCompanySearchThunk = createAsyncThunk(
  "companySearches/runCompanySearch",
  async (thunkPayload: { token: string; instructions: FrontendSearchInstructions }, thunkAPI) => {
    const { token, instructions } = thunkPayload;
    runCompanySearch(token, instructions)
      .then((response) => {
        if (response.status === 200) {
          const search = response.data;
          thunkAPI.dispatch(companySearchesSlice.actions.loadSearch({ search }));
          thunkAPI.dispatch(companySearchesSlice.actions.setActiveCompanySearch(search.search_id));
          thunkAPI.dispatch(setActiveView(View.CompanySearch));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough search credits");
        } else {
          toast.error("Failed to run search");
        }
      });
  }
);

// export const rerunCompanySearchThunk = createAsyncThunk(
//   "companySearches/rerunCompanySearch",
//   async (thunkPayload: { token: string; search_id: string; instructions: FrontendSearchInstructions }, thunkAPI) => {
//     const { token, search_id, instructions } = thunkPayload;
//     thunkAPI.dispatch(addSearchStartingToRunHack(search_id));
//     rerunCompanySearch(token, search_id, instructions)
//       .then((response) => {
//         if (response.status === 200) {
//           const search = response.data;
//           thunkAPI.dispatch(companySearchesSlice.actions.loadSearch({ search }));
//           thunkAPI.dispatch(companySearchesSlice.actions.removeSearchStartingToRunHack(search_id));
//         }
//       })
//       .catch((error) => {
//         if (error.response?.status === 402) {
//           toast.error("Not enough search credits");
//         } else {
//           toast.error("Failed to rerun search");
//         }
//       });
//   }
// );

export const expandCompanySearchThunk = createAsyncThunk(
  "companySearches/expandCompanySearch",
  async (thunkPayload: { token: string; search_id: string; expand_by: number }, thunkAPI) => {
    const { token, search_id, expand_by } = thunkPayload;
    thunkAPI.dispatch(addSearchStartingToRunHack(search_id));
    expandCompanySearch(token, search_id, expand_by)
      .then((response) => {
        if (response.status === 200) {
          const search = response.data;
          thunkAPI.dispatch(companySearchesSlice.actions.loadSearch({ search }));
          thunkAPI.dispatch(companySearchesSlice.actions.removeSearchStartingToRunHack(search_id));
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error("Not enough search credits");
        } else {
          toast.error("Failed to run search");
        }
      });
  }
);

export const getAllCompanySearchesThunk = createAsyncThunk(
  "companySearches/getAllSearches",
  async (thunkPayload: { token: string }, thunkAPI) => {
    const { token } = thunkPayload;
    getCompanySearches(token).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(companySearchesSlice.actions.loadSearches({ searches: response.data }));
      }
    });
  }
);

export const getAllCompanySearchStubsThunk = createAsyncThunk(
  "companySearches/getAllSearchStubs",
  async (thunkPayload: { token: string }, thunkAPI) => {
    const { token } = thunkPayload;
    getCompanySearchStubs(token).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(companySearchesSlice.actions.loadStubs({ stubs: response.data }));
      }
    });
  }
);

export const getCompanySearchThunk = createAsyncThunk(
  "companySearches/getCompanySearch",
  async (thunkPayload: { token: string; search_id: string }, thunkAPI) => {
    const { token, search_id } = thunkPayload;
    getCompanySearch(token, search_id).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(companySearchesSlice.actions.loadSearch({ search: response.data }));
      }
    });
  }
);

export const deleteCompanySearchThunk = createAsyncThunk(
  "companySearches/deleteCompanySearch",
  async (thunkPayload: { token: string; search_id: string }, thunkAPI) => {
    const { token, search_id } = thunkPayload;
    deleteCompanySearch(token, search_id).then((response) => {
      if (response.status === 200) {
        thunkAPI.dispatch(companySearchesSlice.actions.deleteSearch(search_id));
      }
    });
  }
);

export const markSearchViewedThunk = createAsyncThunk(
  "markSearchViewed",
  async (thunkPayload: { token: string; searchId: string }, thunkAPI) => {
    const { token, searchId } = thunkPayload;
    thunkAPI.dispatch(companySearchesSlice.actions.markSearchViewed(searchId));
    markSearchViewed(token, searchId);
  }
);

const companySearchesSlice = createSlice({
  name: "companySearches",
  initialState,
  reducers: {
    setActiveCompanySearch: (state, action: PayloadAction<string>) => {
      state.activeSearchId = action.payload;
    },
    updateGlobalSearchInstructions: (state, action: PayloadAction<{ instructions: FrontendSearchInstructions }>) => {
      const { instructions } = action.payload;
      state.searchInstructions = instructions;
    },
    supplementGlobalSearchInstructions: (state, action: PayloadAction<{ instructions: FrontendSearchInstructions }>) => {
      const { instructions } = action.payload;
      if (state.searchInstructions.semantic_items.length === 0) {
        state.searchInstructions.semantic_items = instructions.semantic_items;
      }
      state.searchInstructions.scoring_questions.push(...instructions.scoring_questions);
      if (state.searchInstructions.job_listings == null) {
        state.searchInstructions.job_listings = instructions.job_listings;
      }
      if (state.searchInstructions.employee_roles == null) {
        state.searchInstructions.employee_roles = instructions.employee_roles;
      }
      if (state.searchInstructions.tech_detections == null) {
        state.searchInstructions.tech_detections = instructions.tech_detections;
      }
      for (const filter of instructions.numerical_filters) {
        if (!state.searchInstructions.numerical_filters.some((f) => f.hard_metric === filter.hard_metric)) {
          state.searchInstructions.numerical_filters.push(filter);
        }
      }
      state.searchInstructions.title = instructions.title;
      state.searchInstructions.enable_external_search = instructions.enable_external_search;
      if (state.searchInstructions.hq_country_filter == null) {
        state.searchInstructions.hq_country_filter = instructions.hq_country_filter;
      }
      if (state.searchInstructions.company_type_filter == null) {
        state.searchInstructions.company_type_filter = instructions.company_type_filter;
      }
    },
    setCompanySearchDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.dialogOpen = action.payload;
    },
    loadSearch: (state, action: PayloadAction<{ search: FrontendFullSearch }>) => {
      const { search } = action.payload;
      const stub = {
        search_id: search.search_id,
        title: search.title,
        created_at_datetime: search.created_at_datetime,
        running_status: search.running_status,
        viewed: search.viewed
      };
      const existingSearchIndex = state.searches.findIndex((s) => s.search_id === search.search_id);
      if (existingSearchIndex === -1) {
        // Search not found, add it to the array
        state.searches.push(search);
      } else {
        // Search found, update it
        state.searches[existingSearchIndex] = search;
      }
      const existingStubIndex = state.stubs.findIndex((s) => s.search_id === search.search_id);
      if (existingStubIndex === -1) {
        state.stubs.push(stub);
      } else {
        if (state.stubs[existingStubIndex].viewed === true) {
          stub.viewed = true;
        }
        state.stubs[existingStubIndex] = stub;
      }
    },
    loadSearches: (state, action: PayloadAction<{ searches: FrontendFullSearch[] }>) => {
      state.searches = action.payload.searches;
    },
    loadStubs: (state, action: PayloadAction<{ stubs: FrontendFullSearchStub[] }>) => {
      state.stubs = action.payload.stubs;
    },
    deleteSearch: (state, action: PayloadAction<string>) => {
      state.searches = state.searches.filter((search) => search.search_id !== action.payload);
      state.stubs = state.stubs.filter((stub) => stub.search_id !== action.payload);
      if (state.activeSearchId === action.payload) {
        state.activeSearchId = null;
      }
    },
    markSearchViewed: (state, action: PayloadAction<string>) => {
      const search = state.searches.find((s) => s.search_id === action.payload);
      if (search) {
        search.viewed = true;
      }
      const stub = state.stubs.find((s) => s.search_id === action.payload);
      if (stub) {
        stub.viewed = true;
      }
    },
    editCompanySearchTitle: (state, action: PayloadAction<{ searchId: string; title: string }>) => {
      const { searchId, title } = action.payload;
      const search = state.searches.find((s) => s.search_id === searchId);
      if (search) {
        search.title = title;
      }
      const stub = state.stubs.find((s) => s.search_id === searchId);
      if (stub) {
        stub.title = title;
      }
    },
    // Used for updating weights
    updateCompanySearchInstructions: (state, action: PayloadAction<{ search_id: string; instructions: FrontendSearchInstructions }>) => {
      // TODO: this needs to be persisted to the backend
      const { search_id, instructions } = action.payload;
      const search = state.searches.find((s) => s.search_id === search_id);
      if (search) {
        search.instructions = instructions;
      }
    },
    addSearchStartingToRunHack: (state, action: PayloadAction<string>) => {
      state.searchesStartingToRunHack.push(action.payload);
    },
    removeSearchStartingToRunHack: (state, action: PayloadAction<string>) => {
      state.searchesStartingToRunHack = state.searchesStartingToRunHack.filter((id) => id !== action.payload);
    },
    resetSearchInstructions: (state) => {
      state.searchInstructions = initialState.searchInstructions;
    },
    setInstructionsLoading: (state, action: PayloadAction<boolean>) => {
      state.instructionsLoading = action.payload;
    }
  }
});

export const companySearchesUpdateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (action.type === "companySearches/editCompanySearchTitle") {
    const state = storeAPI.getState() as RootState;
    const { searchId, title } = action.payload;
    const search = state.companySearches.searches.find((s) => s.search_id === searchId);

    if (search && state.user.token) {
      updateCompanySearchTitle(state.user.token, searchId, title);
    }
  }

  return result;
};

export const {
  setActiveCompanySearch,
  loadSearch,
  loadSearches,
  deleteSearch,
  editCompanySearchTitle,
  updateGlobalSearchInstructions,
  setCompanySearchDialogOpen,
  addSearchStartingToRunHack,
  removeSearchStartingToRunHack,
  updateCompanySearchInstructions,
  resetSearchInstructions
} = companySearchesSlice.actions;

export default companySearchesSlice.reducer;
