/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { MeticulateLogoRedirect } from "@/lib/utilComponents";
import { LogIn, Share } from "lucide-react";
import { Button } from "../ui/button";
import { toast } from "sonner";
import { AddToMyAccountDialog } from "../company-searches/AddToMyAccountDialog";

function getLinkToShare(shareableLink: string) {
  navigator.clipboard.writeText(shareableLink);
  toast.success("Copied shareable link to clipboard!");
}

export const GenericHeader = ({
  shareableHeader,
  children,
  shareableLink,
  disableAddToAccount,
  type
}: {
  shareableHeader?: boolean;
  children: React.ReactNode;
  shareableLink?: string;
  disableAddToAccount?: boolean;
  type?: "company_search" | "people_list";
}) => {
  return (
    <div className="flex flex-row w-full justify-between bg-white border-b border-colors-border-border-secondary h-16 items-center px-10 gap-10">
      {shareableHeader && <MeticulateLogoRedirect forceHomepage />}
      {children}
      <div className="flex items-center gap-2">
        {!shareableHeader && shareableLink && (
          <Button size="sm" variant="outline" onClick={() => getLinkToShare(shareableLink)}>
            <Share size={20} className="mr-2" />
            Share
          </Button>
        )}
        {shareableHeader && type != null && <AddToMyAccountDialog disabled={disableAddToAccount} type={type} />}
        {shareableHeader && (
          <Button size="sm" onClick={() => window.open("https://app.meticulate.ai")}>
            <LogIn size={20} className="mr-2" />
            Run your own
          </Button>
        )}
      </div>
    </div>
  );
};
