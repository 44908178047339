import { MetricWithSources, Source, WorkflowType } from "@/services/autogen";
import { ensureAbsoluteUrl, formatDollarValueMillionsBillions, formatMultipleValue, formatPercentValue, getDomainFromUrl } from "./utils";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { setActiveView } from "@/store/views";
import { Boxes, Sparkles, Map, NotebookText, Search, Building2 } from "lucide-react";
import { Binoculars, Cards, Funnel, NewspaperClipping } from "@phosphor-icons/react";
import { useAuth } from "@clerk/clerk-react";
import { useAppDispatch } from "@/store/hooks";
import { setActiveWorkflowId } from "@/store/genericWorkflows";
import { View } from "@/store/views";

export const WorkflowIcon = ({ workflowType, size = 24 }: { workflowType: WorkflowType; size?: number }) => {
  switch (workflowType) {
    case WorkflowType.CompFinder:
    case WorkflowType.CompLandscapeV7:
      return <Search size={size} />;
    case WorkflowType.MarketMap:
    case WorkflowType.MarketMapV2:
      return <Map size={size} />;
    case WorkflowType.PublicComps:
      return <Building2 size={size} />;
    case WorkflowType.TearSheet:
      return <NotebookText size={size} />;
    case WorkflowType.FindCompanies:
    case WorkflowType.FindCompaniesV2:
      return <Binoculars size={size} />;
    case WorkflowType.PressSearch:
      return <NewspaperClipping size={size} />;
    case WorkflowType.OfferingDeepdive:
      return <Boxes size={size} />;
    case WorkflowType.CompanyCustomResearch:
      return <Cards size={24} />;
    case WorkflowType.SourceCompanies:
      return <Funnel size={size} />;
    default:
      return <Sparkles size={size} />;
  }
};

export const MeticulateLogoRedirect = ({ forceHomepage }: { forceHomepage?: boolean }) => {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  return (
    <img
      src="/MeticulateIconSquare.svg"
      className="h-6 cursor-pointer"
      onClick={async () => {
        const token = await getToken();
        if (token != null && !forceHomepage) {
          dispatch(setActiveWorkflowId(undefined));
          dispatch(setActiveView(View.Dashboard));
        } else {
          // go to login
          window.location.href = "https://meticulate.ai";
        }
      }}
    />
  );
};

export function SourceCitations({
  objectsWithSources,
  maxSources = 3,
  className
}: {
  objectsWithSources: { sources: Source[] }[];
  maxSources?: number;
  className?: string;
}) {
  const sources = objectsWithSources.flatMap((textWithSources) => textWithSources.sources);
  if (sources === undefined) {
    return <div />;
  }
  const uniqueSources: Source[] = [];
  for (const source of sources) {
    if (source.link != null && !uniqueSources.some((s) => s.link === source.link)) {
      uniqueSources.push(source);
    }
  }
  return (
    <div className={`flex flex-row flex-wrap gap-[2px] ${className!}`}>
      {uniqueSources.map((source, i) => (
        <div className="text-sm" key={i}>
          {i < maxSources &&
            (source.link ? (
              <SimpleTooltip text={getDomainFromUrl(source.link)}>
                <a
                  href={ensureAbsoluteUrl(source.link)}
                  className="flex flex-row hover:cursor-pointer text-colors-indigo-600 "
                  target="_blank"
                  rel="noopener noreferrer">
                  {"[" + (i + 1) + "]"}
                </a>
              </SimpleTooltip>
            ) : (
              "[" + (i + 1) + "]"
            ))}
        </div>
      ))}
    </div>
  );
}

export function ProviderSourceCitations({
  objectsWithSources,
  className
}: {
  objectsWithSources: { sources: Source[] }[];
  className?: string;
}) {
  const sources = objectsWithSources.flatMap((textWithSources) => textWithSources.sources);
  if (sources === undefined) {
    return <div />;
  }
  const uniqueSources: Source[] = [];
  for (const source of sources) {
    if (!uniqueSources.some((s) => s.title === source.title)) {
      uniqueSources.push(source);
    }
  }

  return (
    <div className={`flex flex-row flex-wrap gap-[2px] ${className!}`}>
      {uniqueSources.map((source, idx) => (
        <ProviderSourceLogo sourceTitle={source.title} key={idx} />
      ))}
    </div>
  );
}

export function ProviderSourceLogo({ sourceTitle }: { sourceTitle: string }) {
  if (sourceTitle === "LI image") {
    return <img src="/linkedin.png" className="h-6" />;
  } else if (sourceTitle === "CB Image") {
    return <img src="/crunchbase.webp" className="h-6" />;
  } else {
    return null;
  }
}

export function MetricWithSourcesCitation({ metricWithSources, className }: { metricWithSources: MetricWithSources; className?: string }) {
  return (
    <div className={`flex flex-row flex-wrap gap-[2px] text-xs ${className!}`}>
      {metricWithSources.sources &&
        metricWithSources.sources.map((source, idx) =>
          source.link != null ? (
            <a
              key={idx}
              href={ensureAbsoluteUrl(source.link)}
              className="flex flex-row hover:cursor-pointer text-colors-indigo-600 "
              target="_blank"
              rel="noopener noreferrer">
              {"[" + source.title + "]"}
            </a>
          ) : (
            <>{"[" + source.title + "]"}</>
          )
        )}
    </div>
  );
}

export function InlineTextAndSources({ title, text, sources }: { title: React.ReactNode; text: string; sources: React.ReactNode }) {
  return (
    <div>
      <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1"> {title} </div>
      <div className="flex flex-row items-center gap-4">
        <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {text} </div>
        <div> {sources} </div>
      </div>
    </div>
  );
}

export const DollarValueText = ({ value }: { value?: number }) => {
  return (
    <span className={`${value && value < 0 ? "text-colors-error-500" : "text-colors-text-text-secondary-(700)"}`}>
      {formatDollarValueMillionsBillions(value)}
    </span>
  );
};

export const PercentValueText = ({
  value,
  includePlusSign,
  positiveGreen
}: {
  value?: number;
  includePlusSign?: boolean;
  positiveGreen?: boolean;
}) => {
  let textColor = "text-colors-text-text-secondary-(700)";
  if (value && value < 0) {
    textColor = "text-colors-error-500";
  } else if (value && value > 0 && positiveGreen) {
    textColor = "text-colors-green-500";
  }
  return <span className={`${textColor}`}>{formatPercentValue(value, includePlusSign)}</span>;
};

export const MultipleValueText = ({ value }: { value?: number }) => {
  return (
    <span className={`${value && value < 0 ? "text-colors-error-500" : "text-colors-text-text-secondary-(700)"}`}>
      {formatMultipleValue(value)}
    </span>
  );
};
