/* eslint-disable @typescript-eslint/no-empty-function */
import { FrontendFullCompanySearch } from "@/services/autogen";
import { useLoaderData } from "react-router-dom";
import { useAppDispatch } from "@/store/hooks";

import { useEffect } from "react";
import { Toaster } from "sonner";
import { DeprCompanySearchHeader } from "./CompanySearchHeader";
import { DeprCompanySearchViewer } from "./CompanySearchViewer";
import { deprLoadSearch, deprSetActiveCompanySearch } from "@/store/deprCompanySearches";

export function DeprShareableCompanySearchViewer() {
  const dispatch = useAppDispatch();

  const companySearch = useLoaderData() as FrontendFullCompanySearch | null;
  useEffect(() => {
    if (companySearch) {
      dispatch(deprLoadSearch({ search: companySearch }));
      dispatch(deprSetActiveCompanySearch(companySearch.search_id));
    }
  }, [companySearch]);
  if (companySearch == null) {
    return <div>404</div>;
  }

  return (
    <div className="flex app-background">
      <div className="flex flex-col w-full h-screen">
        {/*  TODO: show Logo and runYourOwnButton */}
        <div className="py-1">
          <DeprCompanySearchHeader shareableHeader />
        </div>
        <div className="flex flex-col flex-grow w-full p-10 overflow-hidden">
          <DeprCompanySearchViewer viewOnly />
        </div>
      </div>
      <Toaster richColors position="top-center" />
    </div>
  );
}
