import { FrontendCompanySearchInstructions, FrontendCompanySearchParameters, SearchRunningStatus } from "@/services/autogen";
import { AlertTriangle, Info, Undo2 } from "lucide-react";

import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { useCallback, useEffect, useState } from "react";
import { deprGetCompanySearchThunk, deprRerunCompanySearchThunk, deprRunCompanySearchThunk } from "@/store/deprCompanySearches";
import { CompanySearchResultsViewer, DisqualifiedCompanyViewer } from "./CompanySearchResultsViewer";
import { UnderlinedTabs, UnderlinedTabsContent, UnderlinedTabsList, UnderlinedTabsTrigger } from "../ui/tabs";
import { CompanySearchInstructionsViewAndEdit } from "./instructions/Instructions";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Spinner } from "@phosphor-icons/react";
import posthog from "posthog-js";
import { COMPANY_SEARCH_RUNNING_STATUSES } from "@/lib/constants";
import { VolumeRequestedToggleGroup } from "./instructions/InitialInputs";
import { SimpleTooltip } from "../ui/tooltip";

const ExistingSearchInstructionsEditor = ({ searchId, viewOnly }: { searchId: string; viewOnly?: boolean }) => {
  const search = useAppSelector((state) => state.deprCompanySearches.searches.find((s) => s.search_id === searchId));
  const searchStartingToRunHack = useAppSelector((state) => state.deprCompanySearches.searchesStartingToRunHack.includes(searchId));
  if (search == null) return null;
  const isRunning = COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status) || searchStartingToRunHack;
  const originalCompanySearchInstructions = search.instructions;
  const [modifiedCompanySearchInstructions, setModifiedCompanySearchInstructions] = useState(originalCompanySearchInstructions);
  const [isModified, setIsModified] = useState(false);
  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setModifiedCompanySearchInstructions(originalCompanySearchInstructions);
    setIsModified(false);
  }, [originalCompanySearchInstructions, searchId]);
  const handleInstructionsChange = useCallback(
    (instructions: FrontendCompanySearchInstructions) => {
      setModifiedCompanySearchInstructions(instructions);
      // TODO: This is a hack. get rid of it
      // Good first task for new hire
      if (JSON.stringify(instructions) !== JSON.stringify(originalCompanySearchInstructions)) {
        setIsModified(true);
      }
    },
    [originalCompanySearchInstructions]
  );
  return (
    <div className="flex flex-col gap-2 text-xs w-auto">
      {!isRunning ? (
        <div>
          {isModified ? (
            <div className="flex flex-col ">
              <div className="flex flex-row w-full justify-start gap-4">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setIsModified(false);
                    setModifiedCompanySearchInstructions(originalCompanySearchInstructions);
                  }}>
                  <Undo2 size={20} />
                  Reset changes
                </Button>
                <div className="flex flex-row items-center gap-2 mb-2">
                  <SimpleTooltip text="Target number of companies to bring back. Searches with more volume can take much longer (~15 min)">
                    <div className="text-xs flex flex-row items-center gap-1 text-colors-gray-modern-400">
                      Volume <Info size={12} />
                    </div>
                  </SimpleTooltip>
                  <VolumeRequestedToggleGroup
                    volumeRequested={modifiedCompanySearchInstructions.parameters?.initial_volume_requested ?? 50}
                    setVolumeRequested={(volumeRequested) => {
                      const existingParameters = modifiedCompanySearchInstructions.parameters;
                      const newParameters: FrontendCompanySearchParameters = {
                        ...existingParameters,
                        initial_volume_requested: volumeRequested
                      };
                      setModifiedCompanySearchInstructions({ ...modifiedCompanySearchInstructions, parameters: newParameters });
                    }}
                  />
                </div>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button size="sm">Re-run with modified instructions</Button>
                  </DialogTrigger>
                  <DialogContent className="flex flex-col gap-4 w-auto">
                    <DialogTitle>Re-run search</DialogTitle>
                    <div>Are you sure you want to re-run this search? This will wipe existing results.</div>
                    <div className="flex flex-row gap-2">
                      <DialogClose asChild>
                        <Button
                          variant="outline"
                          onClick={() => {
                            posthog.capture("user_reran_company_search", { search_id: searchId, rerun_type: "replace" });
                            if (token != null) {
                              dispatch(
                                deprRerunCompanySearchThunk({ token, search_id: searchId, instructions: modifiedCompanySearchInstructions })
                              );
                            }
                          }}>
                          Replace existing
                        </Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          onClick={() => {
                            posthog.capture("user_reran_company_search", { search_id: searchId, rerun_type: "new" });
                            if (token != null) {
                              dispatch(deprRunCompanySearchThunk({ token, instructions: modifiedCompanySearchInstructions }));
                            }
                          }}>
                          Run as new
                        </Button>
                      </DialogClose>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          ) : (
            !viewOnly && (
              <div className="flex flex-row w-full text-lg justify-start gap-4 text-gray-600">
                Edit the instructions to re-run this search...
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex flex-row w-full text-lg justify-start gap-4 text-gray-600">Search is running, please wait...</div>
      )}
      <CompanySearchInstructionsViewAndEdit
        companySearchInstructions={modifiedCompanySearchInstructions}
        setCompanySearchInstructions={handleInstructionsChange}
        viewOnly={viewOnly || COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status)}
      />
    </div>
  );
};

export const DeprCompanySearchViewer = ({ viewOnly }: { viewOnly?: boolean }) => {
  const search = useAppSelector((state) =>
    state.deprCompanySearches.searches.find((s) => s.search_id === state.deprCompanySearches.activeSearchId)
  );
  const searchStartingToRunHack = useAppSelector((state) =>
    state.deprCompanySearches.searchesStartingToRunHack.includes(search?.search_id ?? "")
  );
  const activeSearchId = useAppSelector((state) => state.deprCompanySearches.activeSearchId);
  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const autoFetchSearch = async () => {
    if (search && COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status)) {
      if (token != null) {
        dispatch(deprGetCompanySearchThunk({ token, search_id: search.search_id }));
      }
    }
  };

  useEffect(() => {
    const refreshInterval = 3000;
    // Set up an interval to call fetchWorkflow every {refreshInterval}
    const intervalId = setInterval(autoFetchSearch, refreshInterval);

    // Clear the interval if workflow_id or status or tokenchanges
    return () => clearInterval(intervalId);
  }, [activeSearchId, search?.running_status, token]);

  //  fetch workflow on active workflow id change
  useEffect(() => {
    // Call fetchWorkflow immediately on component mount
    if (activeSearchId != null && (search == null || (search && COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status)))) {
      if (token != null) {
        dispatch(deprGetCompanySearchThunk({ token, search_id: activeSearchId }));
      }
    }
  }, [activeSearchId]);

  if (search == null) {
    return <div>No search selected</div>;
  }
  const isRunning = COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status) || searchStartingToRunHack;
  if (isRunning || search.running_status === SearchRunningStatus.Completed) {
    return (
      <div className="flex flex-col w-full h-full">
        {isRunning && (
          <div className="w-full bg-colors-blue-100 border border-colors-blue-600 p-2 rounded-lg text-colors-blue-800 flex flex-row items-center">
            <Spinner size={24} className="mr-2 animate-spin" />
            <p>This search is still running and can take up to 10-15 minutes. We&apos;ll send you an email when it&apos;s done.</p>
          </div>
        )}
        <UnderlinedTabs defaultValue="results" className="w-full h-full flex flex-col">
          <UnderlinedTabsList>
            <UnderlinedTabsTrigger value="instructions" className="text-base">
              Searching instructions
            </UnderlinedTabsTrigger>
            <UnderlinedTabsTrigger value="results" className="text-base">
              Scored
              {(search.results?.scored_companies || search.results?.qualified_companies) && (
                <span className="ml-2 bg-colors-gray-200 px-2 py-0.5 rounded-full text-sm">
                  {search.results.scored_companies?.length || search.results.qualified_companies?.length || 0}
                </span>
              )}
            </UnderlinedTabsTrigger>
            <UnderlinedTabsTrigger value="disqualified" className="text-base">
              Disqualified
              {search.results?.disqualified_companies && (
                <span className="ml-2 bg-colors-gray-200 px-2 py-0.5 rounded-full text-sm">
                  {search.results.disqualified_companies.length}
                </span>
              )}
            </UnderlinedTabsTrigger>
          </UnderlinedTabsList>
          <div className="flex-1 overflow-hidden">
            <UnderlinedTabsContent value="results" className="h-full">
              <CompanySearchResultsViewer search={search} viewOnly={viewOnly} />
            </UnderlinedTabsContent>
            <UnderlinedTabsContent value="instructions" className="h-full overflow-y-auto">
              <ExistingSearchInstructionsEditor searchId={search.search_id} viewOnly={true} />
            </UnderlinedTabsContent>
            <UnderlinedTabsContent value="disqualified" className="h-full overflow-y-auto">
              <div className="flex flex-col h-full">
                <DisqualifiedCompanyViewer
                  companies={search.results?.disqualified_companies ?? []}
                  qualificationCriteria={search.instructions.qualification_criteria ?? []}
                  viewOnly={viewOnly}
                />
              </div>
            </UnderlinedTabsContent>
          </div>
        </UnderlinedTabs>
      </div>
    );
  } else if (search.running_status === SearchRunningStatus.Failed) {
    return (
      <div className="self-center border bg-colors-background-bg-error-primary p-10 rounded-xl flex flex-row items-center text-colors-text-text-secondary-(700)">
        <AlertTriangle size={24} className="mr-4" />
        <div className="flex flex-col">
          <div>Uh-oh, we ran into an error trying to run this search! </div>
          <div>Try creating a new one on the left...</div>
          {/* TODO: Add error message */}
          {/* {search.error_message != null && (
            <div className=" mt-3 text-colors-text-text-tertiary-(600)">{"Details: " + search.error_message}</div>
          )} */}
        </div>
      </div>
    );
  }
};
