import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  VisibilityState,
  Column,
  getExpandedRowModel
} from "@tanstack/react-table";
import { CSSProperties, forwardRef, useState } from "react";
import { setRightbarCompanyDomain } from "@/store/sidebars";
import { useAppDispatch } from "@/store/hooks";
import { TaxonomyEntryRow } from "./TaxonomyTable";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

const getCommonPinningStylesRow = (column: Column<TaxonomyEntryRow>, row: TaxonomyEntryRow): CSSProperties => {
  const colIsPinned = column.id === "name";
  const rowIsMeta = row.entry == null;
  let z;
  if (column.id === "category") {
    z = 700;
  } else if (column.id === "subcategory") {
    z = 600;
  } else if (column.id === "name") {
    z = 500;
  } else {
    z = 0;
  }

  return {
    left: colIsPinned ? 0 : undefined,
    position: colIsPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: z,
    // borderWidth: colIsPinned && header ? "0px 1px 0px 0px" : undefined,
    // borderColor: "black",
    backgroundColor: !rowIsMeta ? "white" : undefined
    // borderWidth: "0px 0px 2px 0px"
    // borderWidth: colIsPinned && !rowIsMeta ? "0px 2px 0px 0px" : undefined
  };
};
const getCommonPinningStylesHeader = (column: Column<TaxonomyEntryRow>): CSSProperties => {
  const colIsPinned = column.id === "name";
  let z;
  if (colIsPinned) {
    z = 999;
  } else {
    z = 900;
  }
  return {
    left: colIsPinned ? 0 : undefined,
    position: colIsPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: z,
    backgroundColor: "white"
    // borderWidth: colIsPinned ? "0px 2px 0px 0px" : undefined
  };
};
// I don't think I'm handling these generics properly
export const DataTable = forwardRef<HTMLDivElement, DataTableProps<TaxonomyEntryRow, any>>(({ columns, data }, ref) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [hoveredRow, setHoveredRow] = useState<string>("");
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    employee_count: true,
    main_offerings: false,
    business_model: true,
    customer_groups: true,
    geographies: false,
    headquarters: false,
    maturity: false,
    status: false,
    founded: true,
    revenue: false,
    funds_raised: false,
    valuation: false,
    investors: false,
    market_cap: false,
    enterprise_value: false,
    earnings: false,
    ebitda: false,
    reported_revenue: false,
    revenue_growth: false,
    gross_margin: false,
    free_cash_flow: false
  });
  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnVisibility
    },
    meta: {
      hoveredRow
    }
  });
  const dispatch = useAppDispatch();

  return (
    <div className="w-full flex flex-grow min-h-1 max-h-full">
      <div ref={ref} className="max-w-full rounded-md border border-colors-border-border-primary bg-transparent max-h-full overflow-auto">
        <table className="max-h-full border-collapse w-max">
          <thead className="bg-white sticky top-0 z-[1000] shadow group">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border-b border-colors-border-border-primary text-left font-medium text-colors-text-text-tertiary-(600) items-start text-xs bg-colors-gray-modern-50">
                {headerGroup.headers.map((header, _) => {
                  return (
                    <th
                      key={header.id}
                      className="font-medium group pr-1 bg-white"
                      colSpan={header.colSpan}
                      style={{ ...getCommonPinningStylesHeader(header.column) }}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.id !== "name" && header.id !== "trash" && header.id !== "category" && header.id !== "subcategory" && (
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `w-1 h-full group-hover:bg-colors-gray-modern-300 absolute top-0 right-0 cursor-col-resize ${
                              table.options.columnResizeDirection
                            } ${header.column.getIsResizing() ? "bg-colors-gray-modern-300" : ""}`
                          }}
                        />
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="max-h-full text-xs">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    className={`max-h-10 border-b border-colors-border-border-primary hover:cursor-pointer ${
                      row.original.entry != null && " bg-white"
                    } ${row.original.title && " bg-colors-gray-modern-100 hover:bg-colors-gray-modern-200"}`}
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => {
                      if (row.original.title) {
                        row.toggleExpanded();
                      } else {
                        dispatch(setRightbarCompanyDomain({ companyDomain: row.original.entry?.company.domain }));
                      }
                    }}
                    onMouseEnter={() => setHoveredRow(row.id)}
                    onMouseLeave={() => setHoveredRow("")}>
                    {row.getVisibleCells().map((cell, _) => (
                      <td key={cell.id} style={{ ...getCommonPinningStylesRow(cell.column, row.original), width: cell.column.getSize() }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="h-24 text-center">
                  Your filters yielded no results.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});
