import { useAppSelector } from "@/store/hooks";
import { Plus } from "lucide-react";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { toast } from "sonner";
import { UpgradeSubscriptionDialogContent } from "../../dashboard/UpgradeSubscription";
import posthog from "posthog-js";
// import { CompanySearchCreationStatus, deprSetCompanySearchCreationStatus, deprUpdateGlobalSearchInputs } from "@/store/companySearches";

import { CompanySearchInstructionsEditor } from "./Instructions";
// import { CompanySearchInputsEditor } from "./InitialInputs";

export const CompanySearchCreationDialogTrigger = ({ onOpen }: { onOpen: () => void }) => {
  const runsAvailable = useAppSelector((state) => state.user.runsAvailable);
  // TODO: use this somewhere. maybe disable the new search button?
  const checkIfRunsAvailable = () => {
    if (runsAvailable != null && runsAvailable <= 0) {
      // setMissingInfo(true);
      toast.error(
        <div className="flex flex-row gap-4 items-center">
          You have no search credits left.
          <Dialog>
            <DialogTrigger asChild>
              <Button size="sm" onClick={() => posthog.capture("user_clicked_get_more_reports", { variant: "sidebar" })}>
                Get more credits
              </Button>
            </DialogTrigger>
            <UpgradeSubscriptionDialogContent />
          </Dialog>
        </div>
      );
      return false;
    }
    return true;
  };
  return (
    <div
      className="flex flex-col gap-2 items-center justify-center bg-colors-brand-50 border border-colors-brand-400 rounded-lg p-4 w-[250px] h-[140px] shadow-lg cursor-pointer hover:bg-colors-brand-200"
      onClick={() => {
        if (checkIfRunsAvailable()) {
          onOpen();
        }
      }}>
      <div className="flex flex-row gap-2 items-center justify-center text-colors-brand-600 font-medium text-md">
        <Plus size={24} />
        New search
      </div>
    </div>
  );
};

export const CompanySearchCreationDialogContent = ({ closeDialog }: { closeDialog: () => void }) => {
  return (
    <DialogContent
      className={`p-10 flex flex-col w-auto max-w-[95%] max-h-[95%] overflow-y-auto
        }`}>
      <DialogTitle className="text-lg font-medium text-colors-brand-600">New company search</DialogTitle>
      <CompanySearchInstructionsEditor closeDialog={closeDialog} />
    </DialogContent>
  );
};
