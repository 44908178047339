import { AlertTriangle, Building2, NotebookText, Plus, Search, Trash2, Users, type LucideIcon } from "lucide-react";
import React, { useEffect } from "react";
import { Spinner } from "@phosphor-icons/react";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem
} from "@/components/ui/sidebar";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { FrontendFullCompanySearchStub, FrontendFullSearchStub, SearchRunningStatus } from "@/services/autogen/api";
import { useAppDispatch } from "@/store/hooks";
import { useAppSelector } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import { setActiveCompanyList, removeCompanyList, createCompanyList } from "@/store/companyLists";
import { setActiveView, View } from "@/store/views";
import posthog from "posthog-js";
import { createPeopleList, removePeopleList, setActivePeopleList } from "@/store/peopleLists";
import { cn } from "@/lib/utils";
import { setActiveCompanyProfile } from "@/store/companyProfiles";
import { CompanyLogo } from "../ui/avatar";
import { deprDeleteCompanySearchThunk, deprSetActiveCompanySearch } from "@/store/deprCompanySearches";
import { Button } from "../ui/button";
import { deleteCompanySearchThunk, setActiveCompanySearch, setCompanySearchDialogOpen, markSearchViewedThunk, getAllCompanySearchStubsThunk } from "@/store/companySearches";
import { COMPANY_SEARCH_RUNNING_STATUSES } from "@/lib/constants";

export function NavLists() {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const activeView = useAppSelector((state) => state.views.activeView);

  const deprStubs = useAppSelector((state) => state.deprCompanySearches.stubs);
  const deprActiveSearchId = useAppSelector((state) => state.deprCompanySearches.activeSearchId);

  const stubs = useAppSelector((state) => state.companySearches.stubs);
  const activeSearchId = useAppSelector((state) => state.companySearches.activeSearchId);

  const companyLists = useAppSelector((state) => state.companyLists.companyLists);
  const activeCompanyListId = useAppSelector((state) => state.companyLists.activeCompanyListId);

  const peopleListStubs = useAppSelector((state) => state.peopleLists.stubs);
  const activePeopleListId = useAppSelector((state) => state.peopleLists.activePeopleListId);

  const lastViewedDomains = useAppSelector((state) => state.companyProfiles.lastViewedDomains);
  const tearsheets = useAppSelector((state) => state.companyProfiles.tearsheets);
  const activeCompanyDomain = useAppSelector((state) => state.companyProfiles.activeCompanyDomain);

  useEffect(() => {
    const pollInterval = setInterval(async () => {
      const hasRunningSearches = stubs.some(
        stub => COMPANY_SEARCH_RUNNING_STATUSES.includes(stub.running_status)
      );

      if (!hasRunningSearches) return;

      const token = await getToken();
      if (!token) return;

      dispatch(getAllCompanySearchStubsThunk({ token }));
    }, 10000);

    return () => clearInterval(pollInterval);
  }, [stubs, dispatch]);

  const deprHandleSearchSelection = async (id: string) => {
    dispatch(deprSetActiveCompanySearch(id));
    dispatch(setActiveView(View.DeprCompanySearch));
    const token = await getToken();
    if (token !== null) {
      dispatch(markSearchViewedThunk({ token, searchId: id }));
    }
  };

  const handleSearchSelection = async (id: string) => {
    dispatch(setActiveCompanySearch(id));
    dispatch(setActiveView(View.CompanySearch));
    const token = await getToken();
    if (token !== null) {
      dispatch(markSearchViewedThunk({ token, searchId: id }));
    }
  };

  const deprHandleSearchDelete = async (e: React.MouseEvent, search: FrontendFullCompanySearchStub) => {
    e.stopPropagation();

    const token = await getToken();
    if (token !== null) {
      dispatch(deprDeleteCompanySearchThunk({ search_id: search.search_id, token }));
    } else {
      const distinctId = posthog.get_distinct_id();
      dispatch(deprDeleteCompanySearchThunk({ search_id: search.search_id, token: distinctId }));
    }
  };

  const handleSearchDelete = async (e: React.MouseEvent, search: FrontendFullSearchStub) => {
    e.stopPropagation();

    const token = await getToken();
    if (token !== null) {
      dispatch(deleteCompanySearchThunk({ search_id: search.search_id, token }));
    } else {
      const distinctId = posthog.get_distinct_id();
      dispatch(deleteCompanySearchThunk({ search_id: search.search_id, token: distinctId }));
    }
  };

  const getSearchStatusIcon = (runningStatus: SearchRunningStatus, viewed: boolean) => {
    if (COMPANY_SEARCH_RUNNING_STATUSES.includes(runningStatus)) {
      return <Spinner className="mr-0 w-2.5 h-2.5 animate-spin" />;
    }
    if (runningStatus === "failed") {
      return (
        <div className="ml-0.5 mr-1.0 w-2.5 h-2.5">
          <AlertTriangle className="w-full h-full stroke-red-500" strokeWidth={3} />
        </div>
      );
    }
    if (runningStatus === "completed" && !viewed) {
      return <div className="ml-0.5 mr-1.0 w-2.5 h-2.5 rounded-full bg-green-500" />;
    }
    return undefined;
  };
  
  const items: {
    title: string;
    icon?: LucideIcon;
    isActive?: boolean;
    items?: {
      key: string;
      title: string;
      isActive?: boolean;
      companyLogo?: JSX.Element;
      onClick: () => void;
      onDelete?: (e: React.MouseEvent) => void;
    }[];
    onAdd?: () => void;
  }[] = [
    {
      title: "Company profiles",
      icon: NotebookText,
      isActive: true,
      items: lastViewedDomains.map((domain) => {
        const tearsheet = tearsheets.find((t) => t.company.domain === domain);
        return {
          title: tearsheet?.company.name ?? domain,
          key: domain,
          isActive: activeView === View.Company && activeCompanyDomain === domain,
          companyLogo: (
            <CompanyLogo name={tearsheet?.company.name ?? domain} website={domain} logo={tearsheet?.company.logo_url} className="w-4 h-4" />
          ),
          onClick: () => {
            dispatch(setActiveCompanyProfile({ companyProfileId: domain }));
            dispatch(setActiveView(View.Company));
          }
        };
      })
    },
    {
      title: "Searches",
      icon: Search,
      isActive: true,
      items: [...stubs]
        .reverse()
        .map((stub) => ({
          title: stub.title,
          key: stub.search_id,
          isActive: activeSearchId === stub.search_id && activeView === View.CompanySearch,
          onClick: async () => {
            const token = await getToken();
            if (token) {
              handleSearchSelection(stub.search_id);
            }
          },
          onDelete: (e: React.MouseEvent) => handleSearchDelete(e, stub),
          companyLogo: getSearchStatusIcon(stub.running_status, stub.viewed),
        }))
        .concat(
          [...deprStubs].reverse().map((stub) => ({
            title: stub.title,
            key: stub.search_id,
            isActive: deprActiveSearchId === stub.search_id && activeView === View.DeprCompanySearch,
            onClick: async () => {
              const token = await getToken();
              if (token) {
                deprHandleSearchSelection(stub.search_id);
              }
            },
            onDelete: (e: React.MouseEvent) => deprHandleSearchDelete(e, stub),
            companyLogo: getSearchStatusIcon(stub.running_status, stub.viewed),
          }))
        ),
      onAdd: () => {
        dispatch(setCompanySearchDialogOpen(true));
        dispatch(setActiveView(View.Dashboard));
      }
    },
    {
      title: "Company lists",
      icon: Building2,
      isActive: true,
      items: [...companyLists].reverse().map((list) => ({
        title: list.title,
        key: list.id,
        isActive: activeCompanyListId === list.id && activeView === View.CompanyList,
        onClick: () => {
          dispatch(setActiveView(View.CompanyList));
          dispatch(setActiveCompanyList({ companyListId: list.id }));
        },
        onDelete: (e) => {
          e.stopPropagation();
          dispatch(removeCompanyList({ companyListId: list.id }));
        }
      })),
      onAdd: () => {
        dispatch(createCompanyList({ title: "New List" }));
        dispatch(setActiveView(View.CompanyList));
      }
    },
    {
      title: "People lists",
      icon: Users,
      isActive: true,
      items: [...peopleListStubs].reverse().map((stub) => ({
        title: stub.title,
        key: stub.id,
        isActive: activePeopleListId === stub.id && activeView === View.PeopleList,
        onClick: () => {
          dispatch(setActiveView(View.PeopleList));
          dispatch(setActivePeopleList({ peopleListId: stub.id }));
        },
        onDelete: (e) => {
          e.stopPropagation();
          dispatch(removePeopleList({ peopleListId: stub.id }));
        }
      })),
      onAdd: () => {
        dispatch(createPeopleList({ title: "New List" }));
        dispatch(setActiveView(View.PeopleList));
      }
    }
  ];

  return (
    <SidebarGroup className="h-full overflow-y-auto">
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible key={item.title} asChild defaultOpen={item.isActive} className="group/collapsible">
            <SidebarMenuItem>
              <CollapsibleTrigger asChild>
                <SidebarMenuButton tooltip={item.title}>
                  {item.icon && <item.icon />}
                  <span>{item.title}</span>
                  {item.onAdd != null && (
                    <Button
                      variant="outline"
                      className="bg-colors-brand-200 hover:bg-colors-brand-300 rounded-sm ml-2 w-6 h-5 p-0 invisible group-hover/collapsible:visible"
                      onClick={(e) => {
                        e.stopPropagation();
                        item.onAdd!();
                      }}>
                      <Plus size={24} />
                    </Button>
                  )}
                  <ChevronRightIcon className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                </SidebarMenuButton>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <SidebarMenuSub>
                  {item.items?.map((subItem) => (
                    <SidebarMenuSubItem key={subItem.key}>
                      <SidebarMenuSubButton
                        onClick={subItem.onClick}
                        className={cn(
                          "cursor-pointer group/item relative flex items-center hover:bg-accent",
                          subItem.isActive ? "outline outline-1 outline-primary" : ""
                        )}>
                        {subItem.companyLogo != null && subItem.companyLogo}
                        <span className="truncate flex-1 min-w-0">{subItem.title}</span>
                        {subItem.onDelete && (
                          <Trash2
                            className="ml-2 w-4 h-4 flex-shrink-0 opacity-0 group-hover/item:opacity-100 transition-opacity hover:text-red-500"
                            onClick={(e) => subItem.onDelete!(e)}
                          />
                        )}
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              </CollapsibleContent>
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
