import { createBrowserRouter, useRouteError, isRouteErrorResponse, json } from "react-router-dom";
import { SignedIn, SignedOut, RedirectToSignIn, SignIn, SignUp } from "@clerk/clerk-react";
import App from "@/App";
import { StaticWorkflowViewer } from "@/components/GenericWorkflows/StaticWorkflowViewer";
import {
  fetchGenericWorkflow,
  getShareableCompanyList,
  getShareablePeopleList,
  getTaxonomy,
  getShareableCompanySearch,
  deprGetShareableCompanySearch
} from "@/services/brain-api.service";
import { signInRequired } from "@/utils/config";
import { StaticTaxonomyViewer } from "@/components/taxonomy/StaticTaxonomyViewer";
import { StaticCompanyTearsheetViewer } from "@/components/company-profile-v2/StaticCompanyTearsheetViewer";
import { ShareableCompanySearchViewer } from "@/components/company-searches/ShareableCompanySearchViewer";
import { ShareablePeopleListViewer } from "@/components/people-lists/ShareablePeopleListViewer";
import { ShareableCompanyListViewer } from "@/components/company-lists/ShareableCompanyListViewer";
import { DeprShareableCompanySearchViewer } from "@/components/depr-company-searches/ShareableCompanySearchViewer";

function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <h1>404 - Not Found</h1>;
    }
  }

  return <h1>Something went wrong</h1>;
}

export const MeticulateRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        {signInRequired ? (
          <>
            <SignedIn>
              <App />
            </SignedIn>
            <SignedOut>
              <div className="flex justify-center items-center m-auto p-[15%] bg-primary h-screen">
                <RedirectToSignIn />
              </div>
            </SignedOut>
          </>
        ) : (
          <App />
        )}
      </>
    ),
    errorElement: <ErrorBoundary />
  },
  {
    path: "/workflow/:workflowId",
    loader: async ({ params }) => {
      if (params.workflowId !== undefined) {
        // fetchSharedWorkflow(params.workflowId) and return it
        const workflow = await fetchGenericWorkflow(params.workflowId);
        return workflow.data;
      } else {
        console.error("No workflow ID provided");
        return null;
      }
    },
    element: <StaticWorkflowViewer />
  },
  {
    path: "/company_search/:companySearchId",
    loader: async ({ params }) => {
      if (params.companySearchId !== undefined) {
        const companySearch = await getShareableCompanySearch(params.companySearchId);
        return companySearch.data;
      } else {
        console.error("No company search ID provided");
        return null;
      }
    },
    element: <ShareableCompanySearchViewer />
  },
  {
    path: "/company_search_old/:companySearchId",
    loader: async ({ params }) => {
      if (params.companySearchId !== undefined) {
        const companySearch = await deprGetShareableCompanySearch(params.companySearchId);
        return companySearch.data;
      } else {
        console.error("No company search ID provided");
        return null;
      }
    },
    element: <DeprShareableCompanySearchViewer />
  },
  {
    path: "/people_list/:peopleListId",
    loader: async ({ params }) => {
      if (params.peopleListId !== undefined) {
        const peopleList = await getShareablePeopleList(params.peopleListId);
        return peopleList.data;
      }
    },
    element: <ShareablePeopleListViewer />
  },
  {
    path: "/company_list/:companyListId",
    loader: async ({ params }) => {
      if (params.companyListId !== undefined) {
        const companyList = await getShareableCompanyList(params.companyListId);
        return companyList.data;
      }
    },
    element: <ShareableCompanyListViewer />
  },
  {
    path: "/taxonomy/:taxonomyId",
    loader: async ({ params }) => {
      if (params.taxonomyId !== undefined) {
        // fetchSharedWorkflow(params.workflowId) and return it
        const taxonomy = await getTaxonomy(params.taxonomyId);
        return taxonomy.data;
      } else {
        console.error("No taxonomy ID provided");
        return null;
      }
    },
    element: <StaticTaxonomyViewer />
  },
  {
    path: "/tearsheet/:tearsheetId",
    loader: async ({ params }) => {
      if (params.tearsheetId !== undefined) {
        // fetchSharedWorkflow(params.workflowId) and return it
        return params.tearsheetId;
      } else {
        console.error("No tearsheet ID provided");
        return null;
      }
    },
    element: <StaticCompanyTearsheetViewer />
  },
  {
    path: "/sign-up/*",
    element: (
      <div className="flex flex-col justify-center items-center m-auto bg-primary min-h-screen py-10">
        <div className=" mb-5 text-center text-colors-warning-800 text-xl bg-colors-warning-200 p-5 rounded-xl border-2 border-colors-warning-400">
          Personal emails not supported <br /> <span className="text-sm">(e.g. @gmail.com, @yahoo.com, etc.)</span>
        </div>
        <SignUp routing="path" path="/sign-up" signInUrl="/sign-in" redirectUrl="/" />
      </div>
    )
  },
  {
    path: "/sign-in/*",
    element: (
      <div className="flex flex-col justify-center items-center m-auto py-10 bg-primary min-h-screen">
        <div className=" mb-5 text-center text-colors-warning-800 text-xl bg-colors-warning-200 p-5 rounded-xl border-2 border-colors-warning-400">
          Personal emails not supported <br /> <span className="text-sm">(e.g. @gmail.com, @yahoo.com, etc.)</span>
        </div>
        <SignIn routing="path" path="/sign-in" signUpUrl="/sign-up" redirectUrl="/" />
      </div>
    )
  },
  {
    path: "*", // Wildcard route for 404
    loader: () => {
      throw json({ message: "Not Found" }, { status: 404 });
    },
    element: <ErrorBoundary />,
    errorElement: <ErrorBoundary />
  }
]);
