/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { Input } from "../ui/input";
import { editCompanySearchTitle } from "@/store/companySearches";

import { GenericHeader } from "../workspace/GenericHeader";
import { COMPANY_SEARCH_RUNNING_STATUSES } from "@/lib/constants";

export const CompanySearchHeader = ({
  shareableHeader,
  disableAddToAccount
}: {
  shareableHeader?: boolean;
  disableAddToAccount?: boolean;
}) => {
  const searchId = useAppSelector((state) => state.companySearches.activeSearchId);
  const search = useAppSelector((state) => state.companySearches.searches.find((s) => s.search_id === searchId));
  const searchStartingToRunHack = useAppSelector((state) => state.companySearches.searchesStartingToRunHack.includes(searchId ?? ""));
  const isRunning = COMPANY_SEARCH_RUNNING_STATUSES.includes(search?.running_status) || searchStartingToRunHack;
  const [title, setTitle] = useState(search?.title ?? "");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (search != null) {
      setTitle(search.title);
    }
  }, [searchId, search?.title]);

  if (search == null) {
    // dispatch(setActiveView(View.Dashboard));
    return null;
  }

  return (
    <GenericHeader
      shareableHeader={shareableHeader}
      shareableLink={`https://app.meticulate.ai/company_search/${search.search_id}`}
      type="company_search"
      disableAddToAccount={disableAddToAccount}>
      {shareableHeader ? (
        <div className="w-full border-0 text-xl font-normal mr-2">{title}</div>
      ) : (
        <Input
          className="w-full border-0 text-2xl font-bold mr-2"
          type="text"
          disabled={isRunning}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={(e) => {
            if (e.target.value != null) {
              dispatch(editCompanySearchTitle({ searchId: search.search_id, title: e.target.value }));
            }
          }}

          // className=" px-2 py-1 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      )}
    </GenericHeader>
  );
};
