import { Acorn, Building, City, Handshake, TrendUp, TrendDown, Coins } from "@phosphor-icons/react";
import { Column, Row } from "@tanstack/react-table";
import { BadgeCheck, ExternalLink, Flame, MoveDown, MoveRight, MoveUp, Network, Store, Tag } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { ensureAbsoluteUrl } from "@/lib/utils";
import { CompanyType, CrmStatus } from "@/services/autogen";
import { CompanyRow } from "@/components/company-table/common/CompanyRow";

export const SortingDirectionIndicator = <T extends CompanyRow>({ column }: { column: Column<T> }) => {
  if (column.getIsSorted() === "desc") {
    return <MoveDown size={14} className="shrink-0" />;
  } else if (column.getIsSorted() === "asc") {
    return <MoveUp size={14} className="shrink-0" />;
  } else {
    return null;
  }
};

export const CompanyNameCell = ({ row }: { row: Row<CompanyRow> }) => {
  return (
    <div className="flex flex-row items-center">
      <CompanyLogo
        name={row.original.name}
        website={row.original.domain}
        logo={row.original.logo_url}
        className="w-8 h-8 mr-2 opacity-100"
      />
      <div className="flex flex-col">
        <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
          {row.original.name.length > 15 ? `${row.original.name.slice(0, 15)}...` : row.original.name}
        </h1>
        <div className="flex flex-row items-center gap-2">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
            {row.original.stock_ticker ?? ""}
          </span>
          <a
            href={ensureAbsoluteUrl(row.original.domain ?? "")}
            className="flex flex-row hover:cursor-pointer font-medium underline"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}>
            <ExternalLink size={14} color="grey" />
          </a>
          {row.original.linkedin_aid && (
            <a
              href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.linkedin_aid)}
              className="self-center"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}>
              <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
            </a>
          )}
          {/* <HypeFlameSizeIcon hype={row.original.hype_rating} /> */}
        </div>
      </div>
    </div>
  );
};

export const CompanyTypeChip = ({ type }: { type: CompanyType }) => {
  let icon, text, style;

  switch (type) {
    case "SmallPrivate":
      icon = <Store size={14} />;
      text = "Small private";
      style = "bg-colors-yellow-100 border-colors-yellow-300 text-colors-yellow-800";
      break;
    case "EarlyStageStartup":
      icon = <Acorn size={14} />;
      text = "Early startup";
      style = "bg-colors-brand-100 border-colors-brand-300 text-colors-brand-800";
      break;
    case "GrowthStageStartup":
      icon = <TrendUp size={14} />;
      text = "Growth startup";
      style = "bg-colors-success-100 border-colors-success-300 text-colors-success-800";
      break;
    case "EstablishedPrivate":
      icon = <Building size={14} />;
      text = "Established";
      style = "bg-colors-blue-100 border-colors-blue-300 text-colors-blue-800";
      break;
    case "Public":
      icon = <City size={14} />;
      text = "Public";
      style = "bg-colors-gray-modern-100 border-colors-gray-modern-300 text-colors-gray-modern-800";
      break;
    case "NotForProfit":
      icon = <Handshake size={14} />;
      text = "Not for profit";
      style = "bg-colors-pink-100 border-colors-pink-300 text-colors-pink-800";
      break;
    case "Defunct":
      icon = <TrendDown size={14} />;
      text = "Defunct";
      style = "bg-colors-error-100 border-colors-error-300 text-colors-error-800";
      break;
    case "Subsidiary":
      icon = <Network size={14} />;
      text = "Subsidiary";
      style = "bg-colors-orange-100 border-colors-orange-300 text-colors-orange-800";
      break;
    case "InvestmentFund":
      icon = <Coins size={14} />;
      text = "Investment fund";
      style = "bg-colors-gray-modern-100 border-colors-gray-modern-300 text-colors-gray-modern-800";
      break;
    default:
      return null;
  }

  return (
    <div className={`${style} border rounded-full px-2 py-1 inline-flex items-center gap-1 w-fit`}>
      {icon}
      {text}
    </div>
  );
};

export const CompanyTypeIcon = ({ type }: { type: CompanyType }) => {
  switch (type) {
    case "SmallPrivate":
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-full px-1 py-1 text-colors-yellow-800 flex flex-row items-center gap-1`}>
          <Store size={14} />
        </div>
      );
    case "EarlyStageStartup":
      return (
        <div
          className={`bg-colors-brand-100 border border-colors-brand-300 rounded-lg px-1 py-1 text-colors-brand-800 flex flex-row items-center gap-1`}>
          <Acorn size={14} />
        </div>
      );
    case "GrowthStageStartup":
      return (
        <div
          className={`bg-colors-success-100 border border-colors-success-300 rounded-lg px-1 py-1 text-colors-success-800 flex flex-row items-center gap-1`}>
          <TrendUp size={14} />
        </div>
      );
    case "EstablishedPrivate":
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-lg px-1 py-1 text-colors-blue-800 flex flex-row items-center gap-1`}>
          <Building size={14} />
        </div>
      );
    case "Public":
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-lg px-1 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          <City size={14} />
        </div>
      );
    case "NotForProfit":
      return (
        <div
          className={`bg-colors-pink-100 border border-colors-pink-300 rounded-lg px-1 py-1 text-colors-pink-800 flex flex-row items-center gap-1`}>
          <Handshake size={14} />
        </div>
      );
    case "Defunct":
      return (
        <div
          className={`bg-colors-error-100 border border-colors-error-300 rounded-lg px-1 py-1 text-colors-error-800 flex flex-row items-center gap-1`}>
          <TrendDown size={14} />
        </div>
      );
    case "Subsidiary":
      return (
        <div
          className={`bg-colors-orange-100 border border-colors-orange-300 rounded-lg px-1 py-1 text-colors-orange-800 flex flex-row items-center gap-1`}>
          <Network size={14} />
        </div>
      );
    case "InvestmentFund":
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-lg px-1 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          <Coins size={14} />
        </div>
      );
    // TODO: defunct and subsidiary
    default:
      return null;
  }
};

// TODO: change these to be on the 1-5 scale when hype is done
export const HypeFlameSizeIcon = ({ hype }: { hype: number }) => {
  if (hype <= 1) {
    return (
      <div className="flex flex-row items-end text-colors-error-300">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={12} className="" />
      </div>
    );
  } else if (hype <= 2) {
    return (
      <div className="flex flex-row items-end text-colors-error-400">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={15} className="" />
      </div>
    );
  } else if (hype <= 3) {
    return (
      <div className="flex flex-row items-end text-colors-error-500">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={18} className="" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-row items-end text-colors-error-600">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={20} className="" />
      </div>
    );
  }
};

export const HypeBubbleIcon = ({ hype }: { hype: number }) => {
  if (hype <= 3) {
    return <div className=" bg-colors-blue-200 rounded-full w-2 h-2" />;
  } else if (hype <= 5) {
    return <div className=" bg-colors-error-400 rounded-full w-3 h-3" />;
  } else if (hype <= 7) {
    return <div className=" bg-colors-error-500 rounded-full w-4 h-4" />;
  } else {
    return <div className=" bg-colors-error-600 rounded-full w-6 h-6" />;
  }
};
export const GrowthScoreIcon = ({ score }: { score?: number }) => {
  if (score == null) {
    return null;
  }
  // Calculate rotation angle: map score from [-3,3] to [-45,45] degrees
  const angle = (score * -45) / 3;

  // Calculate color: green for positive, grey for near-zero, red for negative
  const getColor = () => {
    if (score > 0.5) return "text-colors-success-500";
    if (score < -0.5) return "text-colors-error-500";
    return "text-colors-gray-modern-500";
  };

  return (
    <div className="w-4 h-4 flex items-center justify-center">
      <MoveRight size={16} className={`${getColor()}`} style={{ transform: `rotate(${angle}deg)` }} />
    </div>
  );
};

export const CrmStatusChip = ({ status }: { status?: CrmStatus }) => {
  if (status == null) {
    return null;
  }

  if (status === CrmStatus.New) {
    return (
      <div className="bg-colors-success-100 border border-colors-success-300 rounded-full px-2 py-1 text-colors-success-800 flex flex-row items-center gap-1">
        <Tag size={14} />
        New
      </div>
    );
  }

  return (
    <div className="bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1">
      <BadgeCheck size={14} />
      Tracked
    </div>
  );
};
