import { ensureAbsoluteUrl, getDomainFromUrl } from "@/lib/utils";
import { Source } from "@/services/autogen";
import { SimpleTooltip } from "../ui/tooltip";
import { CompanyLogo } from "../ui/avatar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

export const SourcesLogoViewer = ({ sources, label }: { sources: Source[]; label: boolean }) => {
  const domainSourceMap = new Map<string, Source[]>();

  sources.forEach((source) => {
    if (source.link) {
      const domain = getDomainFromUrl(source.link);
      if (domainSourceMap.has(domain)) {
        domainSourceMap.get(domain)?.push(source);
      } else {
        domainSourceMap.set(domain, [source]);
      }
    }
  });

  return (
    <div className="flex flex-row gap-2 text-sm mt-2 items-center">
      {label && <div className="text-colors-text-text-tertiary-(600)">Sources:</div>}
      <div className="flex flex-wrap gap-2">
        {Array.from(domainSourceMap.entries()).map(([domain, sources], index) => (
          <div key={index}>
            {sources.length === 1 ? (
              <SingleLinkLogoSourceViewer key={index} domain={domain} source={sources[0]} />
            ) : (
              <MultipleLinkLogoSourceViewer key={index} domain={domain} sources={sources} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const SingleLinkLogoSourceViewer = ({ domain, source }: { domain: string; source: Source }) => {
  return (
    <SimpleTooltip text={domain}>
      <div
        key={domain}
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => {
          window.open(ensureAbsoluteUrl(source.link ?? ""), "_blank");
        }}>
        <CompanyLogo name={domain} website={source.link} className="w-6 h-6" />
      </div>
    </SimpleTooltip>
  );
};

export const MultipleLinkLogoSourceViewer = ({ domain, sources }: { domain: string; sources: Source[] }) => {
  return (
    <Popover>
      <SimpleTooltip text={domain}>
        <PopoverTrigger asChild>
          <div className="flex items-center cursor-pointer">
            <CompanyLogo name={domain} website={sources[0].link} className="w-6 h-6" />
            {sources.length > 1 && <span className="text-colors-indigo-600">[{sources.length}]</span>}
          </div>
        </PopoverTrigger>
      </SimpleTooltip>
      <PopoverContent>
        {sources.map((source, index) => (
          <div
            key={index}
            className="p-2 hover:bg-gray-100 cursor-pointer truncate text-xs"
            onClick={() => window.open(ensureAbsoluteUrl(source.link ?? ""), "_blank")}>
            {source.link ?? domain}
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};
