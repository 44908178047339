import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  VisibilityState,
  Column
} from "@tanstack/react-table";
import React, { CSSProperties, forwardRef, useState } from "react";
import { DocumentRow } from "./DocumentTable";
import { FrontendDocument } from "@/services/autogen";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isRunning: boolean;
  onDocumentClick: (document: FrontendDocument) => void;
}

const getCommonPinningStyles = (column: Column<DocumentRow>, header: boolean = false): CSSProperties => {
  const colIsPinned = column.id === "name";
  let z;
  if (colIsPinned) {
    if (header) {
      z = 40;
    } else {
      z = 30;
    }
  } else {
    if (header) {
      z = 30;
    } else {
      z = 0;
    }
  }
  return {
    left: colIsPinned ? 0 : undefined,
    position: colIsPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: z,
    backgroundColor: "white",
    borderWidth: colIsPinned ? "0px 2px 0px 0px" : undefined
  };
};

// I don't think I'm handling these generics properly
export const DataTable = React.memo(
  forwardRef<HTMLDivElement, DataTableProps<DocumentRow, any>>(({ columns, data, isRunning, onDocumentClick }, ref) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [hoveredRow, setHoveredRow] = useState<string>("");
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
      // set the visibility of the columns here
    });
    const table = useReactTable({
      data,
      columns,
      enableColumnResizing: true,
      columnResizeMode: "onChange",
      getCoreRowModel: getCoreRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      onColumnVisibilityChange: setColumnVisibility,
      state: {
        sorting,
        columnVisibility
      },
      meta: {
        hoveredRow
      }
    });

    return (
      <div className="w-full flex flex-grow min-h-1">
        <div ref={ref} className="rounded-md border border-colors-border-border-primary bg-white h-full overflow-auto">
          <table className="h-full border-separate w-max">
            <thead className="bg-white sticky top-0 z-50 shadow group">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="border-b border-colors-border-border-primary text-left font-medium text-colors-text-text-tertiary-(600) items-start text-xs bg-colors-gray-modern-50">
                  {headerGroup.headers.map((header, _) => {
                    return (
                      <th
                        key={header.id}
                        className="font-medium group pr-1"
                        colSpan={header.colSpan}
                        style={{ ...getCommonPinningStyles(header.column, true) }}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.id !== "name" &&
                          header.id !== "trash" &&
                          header.id !== "tableRank" &&
                          header.id !== "includedInBenchmark" && (
                            <div
                              {...{
                                onDoubleClick: () => header.column.resetSize(),
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                                className: `w-1 h-full group-hover:bg-colors-gray-modern-300 absolute top-0 right-0 cursor-col-resize ${
                                  table.options.columnResizeDirection
                                } ${header.column.getIsResizing() ? "bg-colors-gray-modern-300" : ""}`
                              }}
                            />
                          )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="h-full text-xs">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      className={`border-b border-colors-border-border-primary hover:cursor-pointer`}
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      onClick={() => {
                        onDocumentClick(row.original);
                      }}
                      onMouseEnter={() => setHoveredRow(row.id)}
                      onMouseLeave={() => setHoveredRow("")}>
                      {row.getVisibleCells().map((cell, _) => (
                        <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column), width: cell.column.getSize() }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={columns.length} className="h-24 text-center">
                    {isRunning ? "Looking for companies..." : "No companies yet..."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  })
);
