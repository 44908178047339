import { ColumnDef, Row, VisibilityState } from "@tanstack/react-table";
import { CompanyRow } from "./CompanyRow";
import {
  CompanyTypeChip,
  CompanyNameCell,
  SortingDirectionIndicator,
  GrowthScoreIcon,
  HypeFlameSizeIcon,
  CrmStatusChip
} from "@/components/company-table/common/CellComponents";
import { Box, Cpu, Flame, Plus, Trash2 } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { employeeRangeText, formatAbsoluteMagnitude, formatDollarValueMillionsBillions } from "@/lib/utils";
import { PercentValueText } from "@/lib/utilComponents";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { ChartLineUp, Notepad, Sparkle } from "@phosphor-icons/react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { ScoreChip } from "@/components/SourceCompanies/GiantTable/SourceCompaniesTable";
import { getTagProps } from "@/components/workflow-inputs/CompanyTags";
import { CompanyTag } from "@/services/autogen/api";

export function createSelectColumn<T extends CompanyRow>(): ColumnDef<T> {
  let lastSelectedId = "";

  return {
    accessorKey: "tableRank",
    id: "select",
    header: ({ column, table }) => (
      <div className="flex flex-row hover:cursor-pointer px-2 gap-2 justify-between">
        <div className="flex flex-row hover:cursor-pointer gap-2" onClick={column.getToggleSortingHandler()}>
          #
          <SortingDirectionIndicator column={column} />
        </div>
        <Checkbox checked={table.getIsAllRowsSelected()} onClick={table.getToggleAllRowsSelectedHandler()} />
      </div>
    ),
    cell: ({ row, table }) => (
      <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center gap-2 justify-between">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.index + 1}</span>
        <Checkbox
          id={`select-row-${row.id}`}
          checked={row.getIsSelected()}
          onClick={(e) => {
            if (e.shiftKey) {
              const { rows, rowsById } = table.getRowModel();
              const isLastSelected = rowsById[lastSelectedId].getIsSelected();
              const rowsToToggle = getRowRange(rows, row.id, lastSelectedId);

              const currentSelectionState = table.getState().rowSelection;
              // Create an object mapping row IDs to selection state
              const selectionUpdate = rowsToToggle.reduce(
                (acc, row) => {
                  acc[row.id] = isLastSelected;
                  return acc;
                },
                { ...currentSelectionState }
              );

              // Update all rows at once
              table.setRowSelection(selectionUpdate);
            } else {
              row.toggleSelected(!row.getIsSelected());
            }

            lastSelectedId = row.id;
            e.stopPropagation();
          }}
        />
      </div>
    ),
    maxSize: 50,
    meta: {
      group: "meta",
      displayName: "Select"
    }
  };
}

function getRowRange<T>(rows: Array<Row<T>>, idA: string, idB: string) {
  const range: Array<Row<T>> = [];
  let foundStart = false;
  let foundEnd = false;
  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index];
    if (row.id === idA || row.id === idB) {
      if (foundStart) {
        foundEnd = true;
      }
      if (!foundStart) {
        foundStart = true;
      }
    }

    if (foundStart) {
      range.push(row);
    }

    if (foundEnd) {
      break;
    }
  }

  return range;
}

export const basicColumns: ColumnDef<CompanyRow>[] = [
  createSelectColumn(),
  {
    accessorKey: "name",
    // size: 100,
    header: () => {
      return (
        // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
        // <CaretUpDown className="ml-2 h-4 w-4" />
        // </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-col items-start py-2 pl-2 z-40">
        <CompanyNameCell row={row} />
      </div>
    ),
    meta: {
      group: "meta",
      displayName: "Company"
    }
  },
  {
    accessorKey: "hype_rating",
    size: 20,
    header: ({ column }) => (
      <SimpleTooltip text="How much hype is there for this company">
        <div className="flex flex-row gap-0 items-center cursor-pointer" onClick={column.getToggleSortingHandler()}>
          <Flame size={20} />
          <SortingDirectionIndicator column={column} />
        </div>
      </SimpleTooltip>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-center text-center">
        <HypeFlameSizeIcon hype={row.original.hype_rating} />
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Hype rating"
    }
  },
  {
    accessorKey: "growth_score",
    size: 20,
    header: ({ column }) => (
      <SimpleTooltip text="How fast is this company growing">
        <div className="flex flex-row gap-0 items-center cursor-pointer" onClick={column.getToggleSortingHandler()}>
          <ChartLineUp size={20} />
          <SortingDirectionIndicator column={column} />
        </div>
      </SimpleTooltip>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-center text-center">
        <GrowthScoreIcon score={row.original.growth_score} />
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Growth score"
    }
  },
  {
    accessorKey: "product_score",
    size: 80,
    header: ({ column }) => (
      <SimpleTooltip text="Is the company focused on products (10) or services (1)">
        <div className="flex flex-row gap-0 items-center cursor-pointer" onClick={column.getToggleSortingHandler()}>
          <Box size={20} />
          <SortingDirectionIndicator column={column} />
        </div>
      </SimpleTooltip>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-center text-center">
        <ScoreChip score={row.original.product_score} minScore={0} maxScore={10} />
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Product score"
    }
  },
  {
    accessorKey: "technology_score",
    size: 20,
    header: ({ column }) => (
      <SimpleTooltip text="How sophisticated is this company's tech (1 to 10)">
        <div className="flex flex-row gap-0 items-center cursor-pointer" onClick={column.getToggleSortingHandler()}>
          <Cpu size={20} />
          <SortingDirectionIndicator column={column} />
        </div>
      </SimpleTooltip>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-center text-center">
        <ScoreChip score={row.original.technology_score} minScore={0} maxScore={10} />
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Tech score"
    }
  },
  {
    accessorKey: "companyType",
    header: () => (
      <div className="flex flex-row items-center">
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
      </div>
    ),
    size: 160,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
        <CompanyTypeChip type={row.original.company_type} />
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Company type"
    }
  },
  // {
  //   accessorKey: "overview",
  //   header: () => "Overview",
  //   size: 180,
  //   cell: ({ row }) => (
  //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
  //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.overview.text}</span>
  //     </div>
  //   ),
  //   meta: {
  //     group: "basic_info",
  //     displayName: "Overview"
  //   }
  // },
  {
    accessorKey: "business_model",
    header: () => "Business model",
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.business_model.text}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Business Model"
    }
  },
  {
    accessorKey: "main_offerings",
    header: () => "Main offerings",
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.main_offerings.text}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Main Offerings"
    }
  },
  {
    accessorKey: "customer_groups",
    header: () => "Customer groups",
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
          {row.original.summary.customer_segments.text}
        </span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Customer groups"
    }
  },
  {
    accessorKey: "geographies",
    header: () => "Geographies",
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.geographies.text}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Geographies"
    }
  },
  {
    accessorKey: "technologies",
    header: () => "Technologies",
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.technologies.text}</span>
        <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Technologies"
    }
  },
  {
    accessorKey: "headquarters",
    size: 100,
    header: () => <div className="w-auto">HQ</div>,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.hq_str}</span>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Headquarters"
    }
  },
  // DROPPING THIS FROM TABLE BECAUSE DATA IS SUPER SPARSE
  // {
  //   accessorKey: "investors",
  //   header: () => <div className="w-auto">Investors</div>,
  //   cell: ({ row }) => (
  //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
  //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.list_of_investors}</span>
  //     </div>
  //   ),
  //   meta: {
  //     group: "basic_info",
  //     displayName: "Investors"
  //   }
  // },
  {
    accessorKey: "employee_count",
    // size: 25,
    size: 80,
    header: () => (
      <div>
        <span>
          Employees <span className="text-[10px]">(90-day)</span>
        </span>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] items-end text-center flex flex-col">
        <div className="text-colors-text-text-secondary-(700)">
          {employeeRangeText(row.original.employee_min, row.original.employee_max, row.original.employee_guess)}
        </div>
        <div className="text-[11.5px]">
          <PercentValueText value={row.original.employee_count_90day_growth} positiveGreen includePlusSign />
        </div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Employee Count"
    }
    // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
    // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
  },
  {
    accessorKey: "revenue_range",
    // size: 25,
    size: 80,
    header: () => (
      <div>
        <span>Revenue</span>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] items-end text-center flex flex-col">
        <div className="text-colors-text-text-secondary-(700)">
          {row.original.revenue_min && row.original.revenue_max
            ? formatDollarValueMillionsBillions(row.original.revenue_min) +
              " - " +
              formatDollarValueMillionsBillions(row.original.revenue_max)
            : "-"}
        </div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Revenue range"
    }
    // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
    // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
  },
  {
    accessorKey: "linkedin_followers",
    // size: 25,
    size: 80,
    header: () => (
      <div className="text-right">
        <span className="flex flex-row gap-x-1 items-center flex-wrap text-right justify-end">
          <span className="flex flex-row gap-1 items-start justify-end">
            <img src="/linkedin.png" className="h-4 w-4 grayscale" /> followers
          </span>
          <span className="text-[10px]">(90-day)</span>
        </span>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] items-end text-center flex flex-col">
        <div className="text-colors-text-text-secondary-(700)">{formatAbsoluteMagnitude(row.original.linkedin_followers)}</div>
        <div className="text-[11.5px]">
          <PercentValueText value={row.original.linkedin_followers_90day_growth} positiveGreen includePlusSign />
        </div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "LinkedIn Follower Growth"
    }
    // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
    // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
  },
  {
    accessorKey: "funds_raised",
    size: 100,
    header: () => (
      <div className="w-auto justify-end text-right">
        Total funding <span className="text-[10px]">(Last round date)</span>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
        <div className="text-colors-text-text-secondary-(700)">
          {row.original.total_known_fundraising?.amount
            ? formatDollarValueMillionsBillions(row.original.total_known_fundraising.amount)
            : "-"}
        </div>
        <div className="text-[11.5px] text-colors-blue-dark-800">{row.original.total_known_fundraising?.date || "-"}</div>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Known Fundraising"
    }
  },
  {
    accessorKey: "founded",
    size: 80,
    header: () => <div className="justify-end text-right">Founded</div>,
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.year_founded}</span>
      </div>
    ),
    meta: {
      group: "basic_info",
      displayName: "Founding year"
    }
  },
  {
    accessorKey: "web_traffic",
    size: 80,
    header: () => (
      <div className="justify-end text-right">
        Web traffic <span className="text-[10px]">(30-day)</span>
      </div>
    ),
    cell: ({ row }) => {
      const visitsLastMonth = row.original.web_traffic?.visits_last_month;
      const visitGrowthMonthly = row.original.web_traffic?.visit_growth_monthly;
      return (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {formatAbsoluteMagnitude(visitsLastMonth)}
            <br />
            <div className="text-[11.5px]">
              <PercentValueText value={visitGrowthMonthly} positiveGreen={true} includePlusSign={true} />
            </div>
          </span>
        </div>
      );
    },
    meta: {
      group: "basic_info",
      displayName: "Web traffic"
    }
  },
  {
    accessorKey: "tags",
    size: 100,
    header: () => (
      <div className="flex flex-row items-center">
        <h1 className="text-colors-text-text-tertiary-(600) pl-2">Tags</h1>
      </div>
    ),
    cell: ({ row }) => {
      const tags = (row.original.tags || []) as CompanyTag[];
      return (
        <div className="flex flex-wrap gap-1 pl-3">
          {tags.length > 0 ? (
            tags.map((tag, index) => {
              const { icon, label, styling } = getTagProps(tag);
              return (
                <div key={index} className={`flex items-center ${styling} rounded-full px-2 py-1 mr-1`}>
                  {tags.length === 1 ? (
                    <>
                      {icon}
                      <span className="ml-1">{label}</span>
                    </>
                  ) : (
                    <SimpleTooltip text={label}>
                      <div className="flex items-center">{icon}</div>
                    </SimpleTooltip>
                  )}
                </div>
              );
            })
          ) : (
            <span>No Tags</span>
          )}
        </div>
      );
    },
    meta: {
      group: "basic_info",
      displayName: "Tags"
    }
  }
];

export const findSimilarCompaniesColumn = (findSimilarCallback: (domain: string) => void): ColumnDef<CompanyRow> => {
  return {
    accessorKey: "tableRank",
    header: () => (
      <div className="flex flex-row hover:cursor-pointer px-2 w-8">
        <SimpleTooltip text="Find similar companies">
          <Sparkle size={16} />
        </SimpleTooltip>
      </div>
    ),
    size: 15,
    cell: ({ row, table }) => {
      const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
      return (
        <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center">
          {isRowHovered && !table?.options?.meta?.isRunning && (
            <Popover>
              <PopoverTrigger asChild>
                <Sparkle className="hover:cursor-pointer text-colors-brand-600 hover:text-colors-brand-800" size={16} />
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-row items-center gap-2">
                  <div className="text-colors-text-text-secondary-(700) text-sm mb-2">Add more companies like this one?</div>
                  <Button
                    onClick={() => {
                      findSimilarCallback(row.original.domain);
                    }}>
                    Yes
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
      );
    },
    meta: {
      group: "meta",
      displayName: "Find similar"
    }
  };
};

export const finalColumn = (
  tableRef: React.RefObject<HTMLDivElement>,
  onRowDelete?: (domain: string) => void,
  additionalColumnGroups?: string[]
): ColumnDef<CompanyRow> => {
  const columnGroups = ["basic_info", ...(additionalColumnGroups ?? [])];
  return {
    accessorKey: "trash",
    size: 50,
    header: ({ table }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
              <Plus size={20} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <span className="flex items-center justify-start my-1 p-1">
              {/* TODO: organize the columns into sections or something */}
              <Notepad className="mr-1" size={22} />
              <h1 className="font-semi">Columns</h1>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  const columns = table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .filter((column) => columnGroups.includes(column?.columnDef?.meta?.group ?? "Unknown"));

                  // Check if all columns are visible
                  const allVisible = columns.every((col) => col.getIsVisible());

                  // Toggle all columns
                  columns.forEach((column) => {
                    column.toggleVisibility(!allVisible);
                  });

                  // Scroll to the right after a brief delay
                  setTimeout(() => {
                    tableRef.current?.scrollTo({ left: tableRef.current.scrollWidth, behavior: "smooth" });
                  }, 100);
                }}>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column) => columnGroups.includes(column?.columnDef?.meta?.group ?? "Unknown"))
                  .every((col) => col.getIsVisible())
                  ? "Deselect All"
                  : "Select All"}
              </Button>
            </span>
            <div className="overflow-y-auto max-h-[300px]">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .filter((column) => columnGroups.includes(column?.columnDef?.meta?.group ?? "Unknown"))
                .map((column) => {
                  return (
                    <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                      <Checkbox
                        key={column.id}
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) => {
                          column.toggleVisibility(!!value);

                          // sleep for 1 sec
                          setTimeout(() => {
                            console.log("trying to scroll");
                            // scroll to the right
                            tableRef.current?.scrollTo({ left: tableRef.current.scrollWidth, behavior: "smooth" });
                          }, 100);
                        }}>
                        {column.id}
                      </Checkbox>

                      <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                    </div>
                  );
                })}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
    enableHiding: false,
    cell: ({ row, table }) => {
      const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
      return isRowHovered && onRowDelete ? (
        <div className="flex justify-center items-center">
          <Button
            className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
            onClick={(e) => {
              onRowDelete(row.original.domain);
              e.stopPropagation();
            }}>
            <Trash2 size={16} className="trash-icon" strokeWidth={2} />
          </Button>
        </div>
      ) : (
        <div className="min-w-[22px] min-h-[39px]"></div>
      );
    }
  };
};

export function crmStatusColumn<T extends CompanyRow>(): ColumnDef<T> {
  return {
    accessorKey: "crmStatus",
    id: "crmStatus",
    header: ({ column }) => (
      <div className="flex flex-row hover:cursor-pointer px-2 gap-2 justify-between">
        <div className="flex flex-row hover:cursor-pointer gap-2" onClick={column.getToggleSortingHandler()}>
          CRM status
        </div>
      </div>
    ),
    cell: ({ row }) => (
      <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center gap-2 justify-between">
        <CrmStatusChip status={row.original.crm_status} />
      </div>
    ),
    maxSize: 50,
    meta: {
      group: "meta",
      displayName: "CRM status"
    }
  };
}

export const columnDefaultVisibility: VisibilityState = {
  employee_count: true,
  linkedin_followers: true,
  overview: false,
  growth_score: true,
  hype_rating: true,
  business_model: true,
  main_offerings: true,
  customer_groups: false,
  geographies: false,
  technologies: false,
  headquarters: true,
  maturity: false,
  status: false,
  founded: true,
  revenue: false,
  funds_raised: false,
  valuation: false,
  investors: false,
  market_cap: false,
  enterprise_value: false,
  earnings: false,
  ebitda: false,
  reported_revenue: false,
  revenue_growth: false,
  gross_margin: false,
  free_cash_flow: false,
  web_traffic: false,
  checked: true,
  product_score: false,
  technology_score: false,
  tags: false
};
