import {
  DialogClose,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger
} from "../ui/dialog";
import { Button } from "../ui/button";
import posthog from "posthog-js";
import { Check } from "lucide-react";
import { createCheckoutSession } from "@/services/brain-api.service";
import { useAuth } from "@clerk/clerk-react";
import { useState } from "react";
import { Badge } from "../ui/badge";
import { MeticulateSubscriptionType } from "@/services/autogen/api";
import { toast } from "sonner";
import axios from "axios";
import { AutosizedTextarea } from "../ui/textarea";
import { Input } from "../ui/input";

export const CompanyIntelligenceRequestButton = ({ children }: { children?: React.ReactNode }) => {
  const lambdaBaseUrl = "https://cc23vqfjhgkrpnk2fvx3pxxtkq0grlhf.lambda-url.us-east-1.on.aws/";
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild>
        {children || (
          <Button
            variant="outline"
            className="w-30 bg-transparent"
            onClick={() => {
              posthog.capture("user_clicked_on_company_intelligence_platform");
            }}>
            Book demo
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Enterprise Demo</DialogTitle>
          <DialogDescription>
            <span>{`We'd love to show you Meticulate's enterprise offerings and work with you! We'll be in touch as soon as possible.`}</span>
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="email" className="text-right">
              Email
            </label>
            <Input
              type="email"
              id="email"
              placeholder="youremail@gmail.com"
              className="col-span-3"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="note" className="text-right">
              Note
            </label>
            <AutosizedTextarea
              id="note"
              placeholder="Any thing you want us to know"
              className="col-span-3"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              onClick={() => {
                posthog.capture("user_requested_access_to_company_intelligence_platform");
                axios
                  .get(lambdaBaseUrl + "?email=" + encodeURIComponent(email) + "&note=" + encodeURIComponent(note))
                  .then(() => {
                    toast.info("Thanks for requesting access! We'll be in touch!");
                    setEmail("");
                    setNote("");
                  })
                  .catch(() => {
                    toast.error("Something went wrong. Please try again.");
                  });
              }}>
              Submit
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface PricingTier {
  name: string;
  price?: number;
  description: string;
  features: string[];
  isPopular?: boolean;
  buttonVariant?: "outline" | "default";
}

const PricingCard = ({
  tier,
  yearlyDiscount,
  ctaCallback,
  ctaDisabled
}: {
  tier: PricingTier;
  yearlyDiscount: boolean;
  ctaCallback?: () => void;
  ctaDisabled: boolean;
}) => {
  const monthlyPrice = tier.price;

  return (
    <div
      className={`flex flex-col p-6 bg-white rounded-xl border min-w-[250px] ${
        tier.isPopular ? "border-2 border-[#7F5EFF]" : "border-light-border"
      } hover:shadow-lg transition-all relative`}>
      {tier.isPopular && (
        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
          <Badge className="bg-[#7F5EFF] text-white">Most Popular</Badge>
        </div>
      )}
      <div className="text-sm text-black mb-2">{tier.name}</div>
      <div className="text-3xl font-medium mb-4">
        {monthlyPrice != null ? `$${monthlyPrice}` : "Custom"}
        {monthlyPrice != null && (
          <>
            <span className="text-sm text-colors-gray-modern-700">/mo</span>
            <div className="text-sm text-colors-gray-modern-700" style={{ visibility: yearlyDiscount ? "visible" : "hidden" }}>
              billed annually
            </div>
          </>
        )}
      </div>
      <p className="text-colors-gray-modern-700 mb-6">{tier.description}</p>
      <ul className="space-y-3 mb-8">
        {tier.features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <Check size={16} className="flex-shrink-0" />
            <span className="flex-1">{feature}</span>
          </li>
        ))}
      </ul>
      {!ctaDisabled &&
        (monthlyPrice != null ? (
          <Button
            className={`mt-auto ${tier.buttonVariant === "outline" ? "" : "bg-[#7F5EFF] hover:bg-[#8F5EFF]"}`}
            variant={tier.buttonVariant || "default"}
            onClick={ctaCallback}>
            Upgrade
          </Button>
        ) : (
          <CompanyIntelligenceRequestButton />
        ))}
    </div>
  );
};

export const UpgradeSubscriptionDialogContent = () => {
  const { getToken } = useAuth();
  const [billingPeriod, setBillingPeriod] = useState<"monthly" | "yearly">("monthly");
  // Calculate the discounted price here
  const getYearlyPrice = (monthlyPrice: number) => {
    return monthlyPrice * 0.8; // 20% discount
  };
  const ctaCallback = async (isYearly: boolean, subscriptionType: MeticulateSubscriptionType) => {
    const token = await getToken();
    posthog.capture("user_clicked_upgrade_subscription", {
      subscriptionType,
      isYearly
    });
    if (token !== null) {
      const checkoutUrl = await createCheckoutSession(token, subscriptionType, isYearly);
      window.location.href = checkoutUrl.data;
    }
  };
  return (
    <DialogContent className="w-full max-w-[1000px] p-6 overflow-y-auto max-h-[90vh] space-y-2">
      <DialogHeader>
        <DialogTitle>Upgrade your subscription!</DialogTitle>
      </DialogHeader>

      {/* Billing Toggle */}
      <div className="flex items-center gap-4 mb-2">
        <button
          className={`px-4 py-2 rounded-lg transition-all ${
            billingPeriod === "monthly" ? "bg-[#7F5EFF] text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setBillingPeriod("monthly")}>
          Monthly
        </button>
        <button
          className={`px-4 py-2 rounded-lg transition-all ${
            billingPeriod === "yearly" ? "bg-[#7F5EFF] text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setBillingPeriod("yearly")}>
          Yearly (20% off)
        </button>
      </div>

      {/* Pricing Cards */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full max-w-[1200px] mx-auto">
        {/* Free Tier */}
        <PricingCard
          tier={{
            name: "Free",
            price: 0,
            description: "Perfect for trying out Meticulate",
            features: ["5 searches per month", "50 company credits/month", "50 contact credits/month"]
          }}
          yearlyDiscount={billingPeriod === "yearly"}
          ctaDisabled={true}
        />

        {/* Basic Plan - Most Popular */}
        <PricingCard
          tier={{
            name: "Basic",
            price: billingPeriod === "yearly" ? getYearlyPrice(75) : 75,
            description: "For individuals and small teams",
            features: ["Unlimited searches", "1000 company credits/month", "1000 contact credits/month"],
            isPopular: true
          }}
          yearlyDiscount={billingPeriod === "yearly"}
          ctaDisabled={false}
          ctaCallback={() => ctaCallback(billingPeriod === "yearly", MeticulateSubscriptionType.Basic)}
        />

        {/* Enterprise Plan */}
        <PricingCard
          tier={{
            name: "Enterprise",
            price: undefined,
            description: "Ideal for growing businesses",
            features: ["Everything in Basic", "CRM integrations", "Team collaboration", "Priority support", "Account tracking"],
            buttonVariant: "outline"
          }}
          yearlyDiscount={billingPeriod === "yearly"}
          ctaDisabled={false}
          ctaCallback={() => ctaCallback(billingPeriod === "yearly", MeticulateSubscriptionType.Pro)}
        />
      </div>
      {/* 
      <div className="flex flex-row gap-8">
        <div className="flex flex-col border border-colors-brand-400 w-1/2 rounded-xl p-4">
          <div className="text-2xl self-center mb-2 text-colors-brand-800">Free tier</div>
          <div className="self-center">
            <span className="text-4xl font-semibold text-colors-brand-700">$0</span>
            <span className="text-sm ml-1 tracking-wide">/month</span>
          </div>
          <div className="flex flex-col gap-1 mt-8 px-4">
            <div className="flex flex-row gap-1 items-center">
              <Check size={16} /> Start with 10 reports
            </div>
            <div className="flex flex-row gap-1 items-center">
              <Check size={16} /> Get 1 more report every week
            </div>
            <div className="flex flex-row gap-1 items-center">
              <Check size={16} /> Access to beta reports
            </div>
          </div>
        </div>
        <div className="flex flex-col border border-colors-brand-400 w-1/2 rounded-xl p-4">
          <div className="text-2xl self-center mb-2 text-colors-brand-800">Meticulate Pro</div>
          <div className="self-center">
            <span className="text-4xl font-semibold text-colors-brand-300 line-through mr-2">$50</span>
            <span className="text-4xl font-semibold text-colors-brand-700">$20</span>
            <span className="text-sm ml-1 tracking-wide">/month</span>
          </div>
          <div className="flex flex-col gap-1 mt-8 px-4">
            <div className="flex flex-row gap-1 items-center">
              <Check size={16} />
              Limited time discount for early adopters
            </div>
            <div className="flex flex-row gap-1 items-center">
              <Check size={16} />
              Everything in free tier
            </div>
            <div className="flex flex-row gap-1 items-center">
              <Check size={16} />
              Unlimited reports (no weekly limit)
            </div>
          </div>
          <Button
            className="mt-5 w-2/3 self-center"
            onClick={async () => {
              posthog.capture("user_clicked_sign_up_for_pro");
              const token = await getToken();
              if (token !== null) {
                const checkoutUrl = await createCheckoutSession(token);
                window.location.href = checkoutUrl.data;
              }
              // TODO: toast asking to refresh
            }}>
            Get unlimited reports hello
          </Button>
        </div>
      </div> */}
      <DialogFooter>
        <div className="text-sm text-colors-text-text-secondary-(700) flex flex-col w-full">
          ... or contact us at founders@meticulate.ai with any questions!
        </div>
      </DialogFooter>
    </DialogContent>
  );
};
