import { GenericWorkflow, MarketMapWorkflow, TaskStatus } from "@/services/autogen";
import { Sparkle } from "lucide-react";
import { useAppDispatch } from "@/store/hooks";
import { MarketMapSimpleViewer } from "./MarketMapSimpleViewer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { removeBusinessFromOutputMarketMap } from "@/store/genericWorkflows";
import { useEffect } from "react";
import { CompFinderTable } from "@/components/CompLandscapeV7/GiantTable/CompFinderTable";
import { MarketMapNew, MarketMapRefine } from "./MarketMapBuilder";
import { WorkflowViewerFeedbackAndExport } from "@/components/GenericWorkflows/WorkflowViewer";

export const MarketMapV2WorkflowViewer = ({
  workflow,
  hideFeedback,
  hideRefine,
  onOpenRightSection
}: {
  workflow: GenericWorkflow;
  hideRefine?: boolean;
  hideFeedback?: boolean;
  onOpenRightSection?: () => void;
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    onOpenRightSection && onOpenRightSection();
  }, [workflow]);
  const marketMapWorkflow = workflow.workflow as MarketMapWorkflow;
  if (workflow.status === TaskStatus.Created) {
    return <MarketMapNew />;
  }
  if (marketMapWorkflow.outputs == null) {
    return <div>This workflow is done running but there are no outputs. Something went wrong! Sorry!</div>;
  }
  return (
    <Tabs defaultValue="table" className="w-full h-full">
      <div className="h-full flex-col flex">
        <WorkflowViewerFeedbackAndExport workflow={workflow} hideFeedback={hideFeedback} disableCopy>
          <TabsList>
            <TabsTrigger value="table">Table</TabsTrigger>
            <TabsTrigger value="graphic">Graphic</TabsTrigger>
            {!hideRefine && (
              <TabsTrigger value="refine">
                <Sparkle className="mr-2" />
                Refine
              </TabsTrigger>
            )}
          </TabsList>
        </WorkflowViewerFeedbackAndExport>
        <TabsContent value="table" className="grow overflow-hidden">
          <CompFinderTable
            marketMap={marketMapWorkflow.outputs.market_map}
            compLandscapeCompanies={marketMapWorkflow.outputs.companies}
            onBusinessDelete={(businessId, categoryName, subCategoryName) => {
              dispatch(removeBusinessFromOutputMarketMap({ businessId, categoryName, subCategoryName, workflowId: workflow.workflow_id }));
            }}
          />
        </TabsContent>
        <TabsContent value="graphic">
          <MarketMapSimpleViewer marketMap={marketMapWorkflow.outputs.market_map} businesses={marketMapWorkflow.outputs.companies} />
        </TabsContent>
        <TabsContent value="refine">
          <MarketMapRefine />
        </TabsContent>
      </div>
    </Tabs>
  );
};
