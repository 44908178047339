import { Option } from "@/components/ui/multiselect";

// see here: https://learn.microsoft.com/en-us/linkedin/shared/references/reference-tables/country-codes
export const COUNTRY_OPTIONS: Option[] = [
  // North America
  { label: "🇺🇸 United States", value: "US", group: "North America" },
  { label: "🇨🇦 Canada", value: "CA", group: "North America" },

  // Western Europe
  { label: "🇬🇧 United Kingdom", value: "GB", group: "Western Europe" },
  { label: "🇫🇷 France", value: "FR", group: "Western Europe" },
  { label: "🇩🇪 Germany", value: "DE", group: "Western Europe" },
  { label: "🇮🇹 Italy", value: "IT", group: "Western Europe" },
  { label: "🇪🇸 Spain", value: "ES", group: "Western Europe" },
  { label: "🇦🇩 Andorra", value: "AD", group: "Western Europe" },
  { label: "🇦🇹 Austria", value: "AT", group: "Western Europe" },
  { label: "🇧🇪 Belgium", value: "BE", group: "Western Europe" },
  { label: "🇨🇭 Switzerland", value: "CH", group: "Western Europe" },
  { label: "🇩🇰 Denmark", value: "DK", group: "Western Europe" },
  { label: "🇫🇮 Finland", value: "FI", group: "Western Europe" },
  { label: "🇫🇷 France, Metropolitan", value: "FX", group: "Western Europe" },
  { label: "🇬🇮 Gibraltar", value: "GI", group: "Western Europe" },
  { label: "🇬🇷 Greece", value: "GR", group: "Western Europe" },
  { label: "🇮🇪 Ireland", value: "IE", group: "Western Europe" },
  { label: "🇮🇸 Iceland", value: "IS", group: "Western Europe" },
  { label: "🇱🇮 Liechtenstein", value: "LI", group: "Western Europe" },
  { label: "🇱🇺 Luxembourg", value: "LU", group: "Western Europe" },
  { label: "🇲🇨 Monaco", value: "MC", group: "Western Europe" },
  { label: "🇲🇹 Malta", value: "MT", group: "Western Europe" },
  { label: "🇳🇱 Netherlands", value: "NL", group: "Western Europe" },
  { label: "🇳🇴 Norway", value: "NO", group: "Western Europe" },
  { label: "🇵🇹 Portugal", value: "PT", group: "Western Europe" },
  { label: "🇸🇲 San Marino", value: "SM", group: "Western Europe" },
  { label: "🇸🇪 Sweden", value: "SE", group: "Western Europe" },
  { label: "🇻🇦 Vatican City State (Holy See)", value: "VA", group: "Western Europe" },

  // Eastern Europe
  { label: "🇦🇱 Albania", value: "AL", group: "Eastern Europe" },
  { label: "🇧🇦 Bosnia and Herzegovina", value: "BA", group: "Eastern Europe" },
  { label: "🇧🇬 Bulgaria", value: "BG", group: "Eastern Europe" },
  { label: "🇧🇾 Belarus", value: "BY", group: "Eastern Europe" },
  { label: "🇷🇸 Serbia", value: "CS", group: "Eastern Europe" },
  { label: "🇨🇾 Cyprus", value: "CY", group: "Eastern Europe" },
  { label: "🇨🇿 Czech Republic", value: "CZ", group: "Eastern Europe" },
  { label: "🇪🇪 Estonia", value: "EE", group: "Eastern Europe" },
  { label: "🇬🇪 Georgia", value: "GE", group: "Eastern Europe" },
  { label: "🇭🇷 Croatia", value: "HR", group: "Eastern Europe" },
  { label: "🇭🇺 Hungary", value: "HU", group: "Eastern Europe" },
  { label: "🇱🇹 Lithuania", value: "LT", group: "Eastern Europe" },
  { label: "🇱🇻 Latvia", value: "LV", group: "Eastern Europe" },
  { label: "🇲🇩 Moldova", value: "MD", group: "Eastern Europe" },
  { label: "🇲🇰 North Macedonia", value: "MK", group: "Eastern Europe" },
  { label: "🇵🇱 Poland", value: "PL", group: "Eastern Europe" },
  { label: "🇷🇴 Romania", value: "RO", group: "Eastern Europe" },
  { label: "🇷🇺 Russian Federation", value: "RU", group: "Eastern Europe" },
  { label: "🇸🇮 Slovenia", value: "SI", group: "Eastern Europe" },
  { label: "🇸🇰 Slovak Republic", value: "SK", group: "Eastern Europe" },
  { label: "🇺🇦 Ukraine", value: "UA", group: "Eastern Europe" },

  // Latin America
  { label: "🇲🇽 Mexico", value: "MX", group: "Latin America" },

  // Oceania
  { label: "🇦🇺 Australia", value: "AU", group: "Oceania" },
  { label: "🇳🇿 New Zealand", value: "NZ", group: "Oceania" },

  // Middle East
  { label: "🇮🇱 Israel", value: "IL", group: "Middle East" }
];
