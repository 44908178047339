import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Info, Play, Clipboard, Copy, Import, Eraser, X } from "lucide-react";
import { FrontendSearchInstructions, MeticulateSubscriptionType } from "@/services/autogen";

import { Button } from "../../ui/button";
import { demoFeaturesOn } from "@/utils/config";

import posthog from "posthog-js";
import { resetSearchInstructions, runCompanySearchThunk, updateGlobalSearchInstructions } from "@/store/companySearches";

import { CompanySearchQualificationBox } from "./Qualification";
import { CompanySearchScoringBox } from "./Scoring";
import { InstructionsFirstColumn } from "./SemanticSearch";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { CompanyDomainAutocomplete } from "@/components/workflow-inputs/companyWebsiteAutocomplete";
import { descriptionToInstructionsThunk } from "@/store/companySearches";
import { findSimilarToInstructionsThunk } from "@/store/companySearches";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Sparkle, Spinner } from "@phosphor-icons/react";

import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { toast } from "sonner";
import { isValidUrl } from "@/lib/utils";
import { useUser } from "@clerk/clerk-react";
import { Switch } from "@/components/ui/switch";

export const VolumeRequestedToggleGroup = ({
  volumeRequested,
  setVolumeRequested
}: {
  volumeRequested: number;
  setVolumeRequested: (volumeRequested: number) => void;
}) => {
  const userSubscriptionType = useAppSelector((state) => state.user.subscriptionType);
  return (
    <ToggleGroup
      variant="outline"
      type="single"
      size="sm"
      value={volumeRequested.toString()}
      className="gap-2"
      onValueChange={(value) => setVolumeRequested(parseInt(value))}>
      <ToggleGroupItem value="10">10</ToggleGroupItem>
      <ToggleGroupItem value="50">50</ToggleGroupItem>
      <ToggleGroupItem value="200" disabled={userSubscriptionType === MeticulateSubscriptionType.Free}>
        200
      </ToggleGroupItem>
    </ToggleGroup>
  );
};

export const CompanySearchInstructionsViewAndEdit = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendSearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendSearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-x-4 w-full ">
      {!viewOnly && (
        <div className="flex flex-row gap-2 justify-start items-center">
          {demoFeaturesOn && (
            <SimpleTooltip text="Load instructions from JSON in clipboard">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  navigator.clipboard.readText().then((text) => {
                    try {
                      const jsonData = JSON.parse(text);
                      const instructions = jsonData as FrontendSearchInstructions;
                      setCompanySearchInstructions(instructions);
                    } catch (e) {
                      console.error("Failed to parse JSON:", e);
                    }
                  });
                }}>
                <Clipboard size={16} />
                Load from JSON
              </Button>
            </SimpleTooltip>
          )}
          {demoFeaturesOn && (
            <SimpleTooltip text="Copy search instructions as JSON">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  const jsonStr = JSON.stringify(companySearchInstructions);
                  navigator.clipboard.writeText(jsonStr);
                }}>
                <Copy size={16} />
                Copy as JSON
              </Button>
            </SimpleTooltip>
          )}
        </div>
      )}
      <div className="flex flex-col md:flex-row gap-4 items-start w-full">
        <InstructionsFirstColumn
          companySearchInstructions={companySearchInstructions}
          setCompanySearchInstructions={setCompanySearchInstructions}
          viewOnly={viewOnly}
        />
        <CompanySearchQualificationBox
          companySearchInstructions={companySearchInstructions}
          setCompanySearchInstructions={setCompanySearchInstructions}
          viewOnly={viewOnly}
        />
        <CompanySearchScoringBox
          companySearchInstructions={companySearchInstructions}
          setCompanySearchInstructions={setCompanySearchInstructions}
          viewOnly={viewOnly}
        />
      </div>
    </div>
  );
};

export const InputsToInstructions = ({ onGenerate, onClose }: { onGenerate: () => void; onClose: () => void }) => {
  const [description, setDescription] = useState("");
  const [findSimilarDomain, setFindSimilarDomain] = useState("");
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.token);
  return (
    <div className=" z-50 flex flex-col gap-2 w-full items-center self-center bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 p-4 rounded-lg max-w-[800px]">
      <div className="self-end"></div>
      <Tabs defaultValue="description" className="w-full">
        <div className="flex flex-row items-center w-full">
          <div className="flex-1" />
          <TabsList className="bg-transparent">
            <TabsTrigger value="description">Description</TabsTrigger>
            <TabsTrigger value="find_similar">Find similar</TabsTrigger>
          </TabsList>
          <div className="flex-1 flex justify-end">
            <Button variant="destructive" size="sm" onClick={onClose}>
              <X size={16} />
            </Button>
          </div>
        </div>
        <TabsContent value="description">
          <div className="flex flex-row gap-2 items-center">
            <div className="flex flex-col gap-2 w-full">
              <Label>Describe the types of companies you want to find</Label>
              <AutosizedTextarea
                className="max-h-[500px] w-full border-colors-gray-modern-400"
                placeholder="e.g. 'peer-to-peer furniture marketplaces in Europe' or 'AI agents for accounting'"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <Button
              size="sm"
              className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600"
              onClick={() => {
                if (token == null) {
                  toast.error("Your session has expired. Try refreshing the page.");
                  return;
                }
                if (description.trim() === "") {
                  toast.error("Please enter a description");
                  return;
                }
                dispatch(descriptionToInstructionsThunk({ token: token, description: description }));
                onGenerate();
              }}>
              <Sparkle size={16} />
              Generate
            </Button>
          </div>
        </TabsContent>
        <TabsContent value="find_similar" className="w-full">
          <div className="flex flex-row gap-2 w-full items-center" style={{ transformStyle: "preserve-3d" }}>
            <div className="flex flex-col gap-2 w-full">
              <Label>Company website</Label>
              <CompanyDomainAutocomplete
                disableLabel
                placeholder="e.g. apple.com"
                textValue={findSimilarDomain}
                onChange={(e) => {
                  setFindSimilarDomain(e);
                }}
                onSubmit={({ value }) => {
                  setFindSimilarDomain(value);
                }}
              />
            </div>
            <Button
              size="sm"
              className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600"
              onClick={() => {
                if (token == null) {
                  toast.error("Your session has expired. Try refreshing the page.");
                  return;
                }
                if (!isValidUrl(findSimilarDomain)) {
                  toast.error("Please enter a valid URL");
                  return;
                }
                dispatch(findSimilarToInstructionsThunk({ token: token, domain: findSimilarDomain }));
                onGenerate();
              }}>
              <Sparkle size={16} />
              Generate
            </Button>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export const ImportPreviousSearch = ({ disabled }: { disabled: boolean }) => {
  const previousSearches = useAppSelector((state) => state.companySearches.searches);
  const dispatch = useAppDispatch();
  const setCompanySearchInstructions = (instructions: FrontendSearchInstructions) => {
    dispatch(updateGlobalSearchInstructions({ instructions }));
  };
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <Import size={16} className="mr-2" />
          Import from previous
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <div className="flex flex-col max-h-[300px] overflow-y-auto">
          {[...previousSearches]
            .reverse()
            .slice(0, 5)
            .map((search, index) => (
              <Button
                key={previousSearches.length - index - 1}
                variant="ghost"
                className="justify-start px-4 py-2 w-full"
                onClick={() => {
                  setCompanySearchInstructions(search.instructions);
                }}>
                {search.title || `Search ${previousSearches.length - index}`}
              </Button>
            ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const EstimatedTimeToRun = ({ volumeRequested }: { volumeRequested: number }) => {
  let minutes = 2;
  if (volumeRequested > 10) {
    minutes = 5;
  }
  if (volumeRequested > 50) {
    minutes = 10;
  }
  return <div className="text-xs text-colors-gray-modern-400">~{minutes} min to run</div>;
};

export const CompanySearchInstructionsEditor = ({ closeDialog }: { closeDialog: () => void }) => {
  const companySearchInstructions = useAppSelector((state) => state.companySearches.searchInstructions);
  const instructionsLoading = useAppSelector((state) => state.companySearches.instructionsLoading);
  const dispatch = useAppDispatch();
  const setCompanySearchInstructions = (instructions: FrontendSearchInstructions) => {
    dispatch(updateGlobalSearchInstructions({ instructions }));
  };

  const token = useAppSelector((state) => state.user.token);
  const { user } = useUser();
  const [inputPanelVisible, setInputPanelVisible] = useState(false);
  if (companySearchInstructions == null) return null;

  const InstructionsValid = (instructions: FrontendSearchInstructions) => {
    if (instructions.semantic_items.length > 0) {
      const businessModelIndex = instructions.semantic_items.findIndex((item) => item.field === "business_model");
      if (businessModelIndex === -1 || instructions.semantic_items[businessModelIndex].query == "") {
        return false;
      } else {
        return true;
      }
    }
    if (instructions.numerical_filters.length > 0) {
      return true;
    }
    if (instructions.scoring_questions.length > 0) {
      if (instructions.scoring_questions.some((question) => question.question != "" && question.preference.high_standard != "")) {
        return true;
      }
    }
    if (instructions.hq_country_filter != null && instructions.hq_country_filter.length > 0) {
      return true;
    }
    if (instructions.company_type_filter != null && instructions.company_type_filter.length > 0) {
      return true;
    }
    if (instructions.company_tags_filter != null && instructions.company_tags_filter.length > 0) {
      return true;
    }
    if (instructions.job_listings != null && instructions.job_listings.high_standard != "") {
      return true;
    }
    if (instructions.employee_roles != null && instructions.employee_roles.high_standard != "") {
      return true;
    }
    if (instructions.tech_detections != null && instructions.tech_detections.high_standard != "") {
      return true;
    }
    return false;
  };

  return (
    <div className="flex flex-col gap-2 text-xs w-auto mb-4">
      <div className="flex flex-col w-full gap-2 items-center">
        {instructionsLoading && (
          <div className="flex flex-col gap-2 w-full items-center justify-center">
            <div className="flex flex-row gap-2 items-center bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 p-4 rounded-lg">
              <Spinner className="animate-spin" />
              Generating search instructions...
            </div>
          </div>
        )}
        {!(inputPanelVisible || instructionsLoading) && (
          <Button
            size="sm"
            onClick={() => setInputPanelVisible(true)}
            className={`${"animate__animated animate__tada bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600"} text-white`}>
            <Sparkle size={16} />
            Generate instructions
          </Button>
        )}
        {inputPanelVisible && (
          <InputsToInstructions onGenerate={() => setInputPanelVisible(false)} onClose={() => setInputPanelVisible(false)} />
        )}
        <div
          className={`flex flex-col gap-2 border-colors-gray-modern-200 border p-2 rounded-lg h-auto ${
            inputPanelVisible ? "opacity-50" : ""
          }`}>
          {/* {inputPanelVisible && <div className="absolute inset-0 bg-white opacity-50 z-10" />} */}
          <div className="flex flex-row items-end w-full gap-2  p-2 rounded-lg">
            <Button
              size="sm"
              disabled={inputPanelVisible || instructionsLoading}
              variant="outline"
              onClick={() => dispatch(resetSearchInstructions())}>
              <Eraser size={16} />
              Clear
            </Button>
            <ImportPreviousSearch disabled={inputPanelVisible || instructionsLoading} />
            <div className="flex-grow" />
            <div className="flex flex-col gap-2 items-end">
              {user != null && user.organizationMemberships.length > 0 && (
                <div className="flex flex-row items-center gap-2 text-colors-gray-modern-400">
                  <div>Net new only</div>
                  <Switch
                    checked={companySearchInstructions.crm_net_new_only ?? false}
                    onCheckedChange={(checked) => {
                      setCompanySearchInstructions({ ...companySearchInstructions, crm_net_new_only: checked });
                    }}
                  />
                </div>
              )}
              <div className="flex flex-row items-center gap-2 w-full justify-end mb-2">
                <div className="flex flex-col gap-1 items-end">
                  <SimpleTooltip text="Rough number of companies to bring back">
                    <div className="text-xs flex flex-row items-center gap-1 text-colors-gray-modern-400">
                      Volume <Info size={12} />
                    </div>
                  </SimpleTooltip>
                </div>
                <VolumeRequestedToggleGroup
                  volumeRequested={companySearchInstructions.initial_volume_requested}
                  setVolumeRequested={(volumeRequested) => {
                    setCompanySearchInstructions({ ...companySearchInstructions, initial_volume_requested: volumeRequested });
                  }}
                />
              </div>

              <div className="flex flex-row items-center gap-2">
                <EstimatedTimeToRun volumeRequested={companySearchInstructions.initial_volume_requested} />
                <Button
                  size="sm"
                  disabled={!InstructionsValid(companySearchInstructions)}
                  // variant="outline"
                  onClick={() => {
                    posthog.capture("user_ran_company_search", { instructions: companySearchInstructions });
                    if (token != null) {
                      dispatch(runCompanySearchThunk({ token: token, instructions: companySearchInstructions }));
                      closeDialog();
                      dispatch(resetSearchInstructions());
                    }
                  }}>
                  Run search
                  <Play size={20} />
                </Button>
              </div>
            </div>
          </div>

          <CompanySearchInstructionsViewAndEdit
            companySearchInstructions={companySearchInstructions}
            setCompanySearchInstructions={setCompanySearchInstructions}
            viewOnly={instructionsLoading || inputPanelVisible}
          />
        </div>
      </div>
    </div>
  );
};
