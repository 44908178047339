import { FeaturedDocuments, FrontendDocument } from "@/services/autogen";
import { CompanyLogo } from "../ui/avatar";
import { getDomainFromUrl } from "@/lib/utils";
import { DocumentTypeIcon } from "../documents/DocumentsViewer";
import { ensureAbsoluteUrl } from "@/lib/utils";
import { DocumentTable } from "../documents/DocumentTable/DocumentTable";

export const DocumentsScrollDisplay = ({ documents }: { documents: FrontendDocument[] }) => {
  return (
    <div className="flex flex-col space-y-8">
      {documents.map((document, i) => {
        // const source = development.sources[0];
        return (
          <div key={i} className="border border-colors-border-border-primary rounded-xl p-5 bg-gray-50">
            <div
              className="flex flex-row items-center mb-1 w-full gap-2 cursor-pointer"
              onClick={() => {
                window.open(ensureAbsoluteUrl(document.url), "_blank");
              }}>
              {document.page_type && <DocumentTypeIcon type={document.page_type} />}
              {document.url && (
                <CompanyLogo name={getDomainFromUrl(document.url)} website={getDomainFromUrl(document.url)} className="w-6 h-6" />
              )}
              <div>
                <div className="text-colors-text-text-secondary-(700) text-sm font-semibold w-full">{document.title}</div>
                <div className="flex flex-row gap-2 text-colors-text-text-tertiary-(600) font-light text-sm w-full">
                  {document.date_published && <div>{document.date_published.slice(0, 7)}</div>}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              {document.credibility_level && <div>Credibility: {document.credibility_level}</div>}
              {document.bias_level && <div>Bias: {document.bias_level}</div>}
              {document.information_level && <div>Information level: {document.information_level}</div>}
            </div>
            <div className="text-colors-text-text-tertiary-(600) text-md">{document.summary}</div>
          </div>
        );
      })}
    </div>
  );
};

export const FeaturedDocumentsDisplay = ({ featuredDocuments }: { featuredDocuments: FeaturedDocuments }) => {
  if (featuredDocuments.common_mentioned == null || featuredDocuments.common_mentioned.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col bg-white rounded-xl p-5 w-full h-full">
      {/* {demoFeaturesOn && featuredDocuments.internal_documents && (
        <div className="flex flex-col space-y-4 mb-10">
          <div className="flex flex-row items-center text-colors-brand-500 text-md font-medium gap-2">
            <FileText size={20} />
            <div>Internal Documents</div>
          </div>
          <DocumentsScrollDisplay documents={featuredDocuments.internal_documents} />
        </div>
      )}
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <FileText size={20} />
        <div>External Documents</div>
      </div>
      <DocumentsScrollDisplay documents={featuredDocuments.common_mentioned} /> */}
      <DocumentTable
        internalDocuments={featuredDocuments.internal_documents || []}
        externalDocuments={featuredDocuments.common_mentioned || []}
        isRunning={false}
        onDocumentClick={() => {}}
        showInternalExternal={true}
      />
    </div>
  );
};
