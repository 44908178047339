import React, { CSSProperties, useEffect, useState } from "react";

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  VisibilityState
} from "@tanstack/react-table";
import { CompanyRow } from "./CompanyRow";
import { FrontendCompany } from "@/services/autogen";
import { basicColumns, columnDefaultVisibility, finalColumn, findSimilarCompaniesColumn } from "./Columns";
import { useAppDispatch } from "@/store/hooks";
import { setRightbarCompanyDomain } from "@/store/sidebars";

type TableRowProps = {
  row: Row<CompanyRow>;
  setHoveredRow: (rowId: string) => void;
  // Note: these are all helpers to force renders
  rowSelection: boolean;
  isRowHovered: boolean;
  visibilityKey: number;
};

const getCommonPinningStyles = (column: Column<CompanyRow>, header: boolean = false): CSSProperties => {
  const colIsPinned = column.id === "name";
  let z;
  if (colIsPinned) {
    if (header) {
      z = 50;
    } else {
      z = 40;
    }
  } else {
    if (header) {
      z = 40;
    } else {
      z = 0;
    }
  }
  return {
    left: colIsPinned ? 0 : undefined,
    position: colIsPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: z,
    backgroundColor: "white",
    borderWidth: colIsPinned ? "0px 2px 0px 0px" : undefined
  };
};
// NOTE: we are passing rowSelection and isRowHovered as props to avoid re-rendering the row when it changes even though it's not used in the render...
const TableRow = React.memo(({ row, setHoveredRow }: TableRowProps) => {
  const dispatch = useAppDispatch();
  // TODO: add hover background change. complexity is the borders
  return (
    <tr
      className={`border-b border-colors-border-border-primary hover:cursor-pointer`}
      key={row.id}
      data-state={row.getIsSelected() && "selected"}
      onClick={() => {
        dispatch(setRightbarCompanyDomain({ companyDomain: row.original.domain }));
      }}
      onMouseEnter={() => {
        setHoveredRow(row.id);
      }}
      onMouseLeave={() => {
        setHoveredRow("");
      }}>
      {row.getVisibleCells().map((cell, _) => (
        <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column), width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
});
TableRow.displayName = "TableRow";

// NOTE: helper for dev work without row memoization
export const RawTableRow = ({ row, setHoveredRow }: TableRowProps) => {
  const dispatch = useAppDispatch();
  console.log(`Rendering raw row ${row.id} ${row.original.tableRank}`);
  // TODO: add hover background change. complexity is the borders
  return (
    <tr
      className={`border-b border-colors-border-border-primary hover:cursor-pointer`}
      key={row.id}
      data-state={row.getIsSelected() && "selected"}
      onClick={() => {
        dispatch(setRightbarCompanyDomain({ companyDomain: row.original.domain }));
      }}
      onMouseEnter={() => setHoveredRow(row.id)}
      onMouseLeave={() => setHoveredRow("")}>
      {row.getVisibleCells().map((cell, _) => (
        <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column), width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

export function DeprCompanyTable({
  companies,
  selectedDomains,
  onSelectedDomainsChange,
  isRunning,
  onRowDelete,
  findSimilarCallback
}: {
  companies: FrontendCompany[];
  selectedDomains: string[];
  onSelectedDomainsChange: (selectedDomains: string[]) => void;
  isRunning: boolean;
  onRowDelete: (domain: string) => void;
  findSimilarCallback?: (domain: string) => void;
}) {
  const [tableRows, setTableRows] = useState(companies.map((company, index) => ({ ...company, tableRank: index })));
  const tableRef = React.useRef<HTMLDivElement>(null);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [hoveredRow, setHoveredRow] = React.useState<string>("");
  const columns = React.useMemo<ColumnDef<CompanyRow>[]>(() => {
    const cols = [...basicColumns];
    if (findSimilarCallback) {
      cols.splice(1, 0, findSimilarCompaniesColumn(findSimilarCallback));
    }
    return [...cols, finalColumn(tableRef, onRowDelete)];
  }, []);
  const rowSelection = React.useMemo(() => {
    return selectedDomains.reduce((acc, domain) => ({ ...acc, [domain]: true }), {});
  }, [selectedDomains]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(columnDefaultVisibility);
  const [visibilityKey, setVisibilityKey] = useState(0);

  const table = useReactTable({
    data: tableRows,
    columns,
    enableRowSelection: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: (updater) => {
      setColumnVisibility(updater);
      // Increment the visibilityKey to force re-render
      setVisibilityKey((prev) => prev + 1);
    },
    state: {
      sorting,
      columnVisibility,
      rowSelection
    },
    meta: {
      hoveredRow,
      isRunning
    },
    getRowId: (row) => row.domain,
    // TODO: this is cursor auto-gen'd code, seems to work
    onRowSelectionChange: (updaterOrValue) => {
      if (typeof updaterOrValue === "function") {
        const currentSelection = selectedDomains;
        const newSelection = updaterOrValue(currentSelection.reduce((acc, domain) => ({ ...acc, [domain]: true }), {}));
        const newSelectedDomains = Object.keys(newSelection).filter((key) => newSelection[key]);
        onSelectedDomainsChange(newSelectedDomains);
      } else {
        const newSelectedDomains = Object.keys(updaterOrValue).filter((key) => updaterOrValue[key]);
        onSelectedDomainsChange(newSelectedDomains);
      }
    }
  });
  useEffect(() => {
    setTableRows(companies.map((company, index) => ({ ...company, tableRank: index + 1 })));
  }, [companies]);

  return (
    <div className="w-full flex flex-grow min-h-1">
      <div ref={tableRef} className="rounded-md border border-colors-border-border-primary bg-white h-full overflow-auto ">
        <table className="h-full border-separate w-max">
          <thead className="bg-white sticky top-0 shadow group z-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border-b border-colors-border-border-primary text-left font-medium text-colors-text-text-tertiary-(600) items-start text-xs bg-colors-gray-modern-50">
                {headerGroup.headers.map((header, _) => {
                  return (
                    <th
                      key={header.id}
                      className="font-medium group pr-1"
                      colSpan={header.colSpan}
                      style={{ ...getCommonPinningStyles(header.column) }}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.id !== "name" &&
                        header.id !== "trash" &&
                        header.id !== "tableRank" &&
                        header.id !== "includedInBenchmark" && (
                          <div
                            {...{
                              onDoubleClick: () => header.column.resetSize(),
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `w-1 h-full group-hover:bg-colors-gray-modern-300 absolute top-0 right-0 cursor-col-resize ${
                                table.options.columnResizeDirection
                              } ${header.column.getIsResizing() ? "bg-colors-gray-modern-300" : ""}`
                            }}
                          />
                        )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="h-full text-xs">
            {table.getRowModel().rows?.length ? (
              table
                .getRowModel()
                .rows.map((row) => (
                  <TableRow
                    row={row}
                    setHoveredRow={setHoveredRow}
                    rowSelection={row.getIsSelected()}
                    isRowHovered={table?.options?.meta?.hoveredRow === row.original.domain}
                    visibilityKey={visibilityKey}
                  />
                ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="h-24 text-center">
                  {isRunning ? "Looking for companies..." : "No companies"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
