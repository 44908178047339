import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { FileCsv, MicrosoftExcelLogo } from "@phosphor-icons/react";
import { AlertTriangle } from "lucide-react";
import { ExportFormat } from "@/services/autogen/api";
import posthog from "posthog-js";
import { toast } from "sonner";
import { Dialog, DialogTrigger } from "../ui/dialog";
import { UpgradeSubscriptionDialogContent } from "../dashboard/UpgradeSubscription";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Switch } from "../ui/switch";

const exportFormatToIcon = {
  [ExportFormat.Excel]: <MicrosoftExcelLogo size={20} className="text-colors-success-700" />,
  [ExportFormat.Csv]: <FileCsv size={20} className="text-colors-brand-700" />
};

// maybe should just be string
export const ExportListButton = <T extends string>({
  selection,
  disabled,
  formatsEnabled,
  exportCallback
}: {
  selection?: T[];
  disabled?: boolean;
  formatsEnabled: ExportFormat[];
  exportCallback: (selection: T[] | undefined, exportFormat: ExportFormat) => Promise<AxiosResponse<File> | undefined>;
}) => {
  const [useSelection, setUseSelection] = useState<boolean>((selection !== undefined && selection.length > 0) ?? false);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  useEffect(() => {
    setUseSelection(selection !== undefined && selection.length > 0);
  }, [selection]);
  const handleExport = async (format: ExportFormat) => {
    exportCallback(useSelection ? selection : undefined, format)
      .then((response) => {
        if (response == null) {
          toast.error("Failed to export");
          return;
        }
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: response.headers["content-type"] });
          const filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
          const extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${filename}.${extension}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {
        if (error.response?.status === 402) {
          toast.error(
            <div className="flex flex-row gap-4 items-center">
              <AlertTriangle size={20} className="text-colors-error-700 shrink-0" />
              Not enough credits left.
              <Dialog>
                <DialogTrigger asChild>
                  <Button size="sm" onClick={() => posthog.capture("user_clicked_get_more_reports", { variant: "toast" })}>
                    Get more credits
                  </Button>
                </DialogTrigger>
                <UpgradeSubscriptionDialogContent />
              </Dialog>
            </div>
          );
        } else {
          toast.error("Failed to export");
        }
      });
    setPopoverOpen(false);
  };
  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <div className="flex items-center gap-0">
            {formatsEnabled.includes(ExportFormat.Excel) && <MicrosoftExcelLogo size={20} className="text-colors-success-700" />}
            {formatsEnabled.includes(ExportFormat.Csv) && <FileCsv size={20} className="text-colors-success-700" />}
          </div>
          Export
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-2">
        <div className="flex flex-row items-center justify-center gap-2 mb-2">
          <span className="text-sm">All</span>
          <Switch checked={useSelection} onCheckedChange={setUseSelection} disabled={selection == null || selection.length === 0} />
          <span className="text-sm">Selected</span>
        </div>
        <div className="flex flex-col gap-2">
          {formatsEnabled.map((format) => (
            <Button
              key={format}
              variant="outline"
              size="sm"
              className="w-full"
              onClick={() => {
                handleExport(format);
              }}>
              {exportFormatToIcon[format]}
              <span>Download {format === ExportFormat.Excel ? "Excel" : "CSV"}</span>
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
