import { FrontendTaxonomyEntry, FrontendTaxonomyNode } from "@/services/autogen";
import { CompanyProfile } from "../GenericWorkflows/RightSectionCompanyProfile";
import { Button } from "../ui/button";
import { IdentificationCard } from "@phosphor-icons/react";

export function TaxonomyEntryCompanyProfile({
  entry,
  rootTaxonomyNode,
  onClose
}: {
  entry: FrontendTaxonomyEntry;
  rootTaxonomyNode: FrontendTaxonomyNode;
  onClose: () => void;
}) {
  const company = entry.company;

  const alloacationAnswerElements: React.ReactNode[] = [];

  let currentNode = rootTaxonomyNode;
  entry.traversal_path.forEach((path, index) => {
    if (path.choice_number !== null && path.choice_number !== undefined) {
      const answer = path.answer || "N/A";
      const confidence = path.fit_level;
      const explanation = path.explanation || "No explanation provided";

      alloacationAnswerElements.push(
        <div key={index} className="mb-4 p-2 border rounded-md text-sm">
          {/* <h3 className="font-bold mb-2">{nodeDescription}</h3> */}
          <p className="line-clamp-2 text-sm">{answer}</p>
          {confidence && (
            <div className="flex flex-row gap-2 items-center mt-2">
              {/* <span className="font-medium">Confidence:</span> */}
              <div className="flex flex-row px-1 items-center bg-colors-blue-200  border  rounded-sm border-colors-blue-300 text-colors-blue-800">
                {/* <p className="text-xs">Fit: </p> */}
                {confidence}
              </div>
              <p className="line-clamp-2 text-xs">
                <span className="font-semibold">Explanation:</span> {explanation}
              </p>
            </div>
          )}
        </div>
      );

      if (currentNode.subnodes && currentNode.subnodes.length > path.choice_number) {
        currentNode = currentNode.subnodes[path.choice_number];
      } else {
        console.warn(`Invalid choice_number at index ${index}`);
      }
    } else {
      console.warn(`Missing choice_number at index ${index}`);
    }
  });

  return (
    <div>
      <Button
        variant="ghost"
        onClick={() => window.open(`https://app.meticulate.ai/tearsheet/${encodeURIComponent(company.domain)}/`, "_blank")}>
        <IdentificationCard size={20} />
      </Button>
      <CompanyProfile company={company} onClose={onClose} />
      <div className="flex flex-col">
        <div className="font-semibold text-colors-brand-800">Allocation path</div>
        {alloacationAnswerElements}
      </div>
    </div>
  );
}
