import { FrontendDerivativeScoreBucket, HardFilter, HardMetric } from "@/services/autogen";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group";
import { FilterHeader } from "./FilterHeader";

export const DerivativeMetricPreference = ({
  preference,
  setPreference,
  text,
  infoText,
  viewOnly,
  lowText,
  mediumText,
  highText
}: {
  preference?: FrontendDerivativeScoreBucket[];
  setPreference: (preference?: FrontendDerivativeScoreBucket[]) => void;
  text: string;
  infoText?: string;
  viewOnly?: boolean;
  lowText?: string;
  mediumText?: string;
  highText?: string;
}) => {
  const lowLabel = lowText || "Low";
  const mediumLabel = mediumText || "Medium";
  const highLabel = highText || "High";

  return (
    <div className="flex flex-col gap-1 items-start">
      <FilterHeader
        title={text}
        infoText={infoText}
        onDelete={() => setPreference(undefined)}
        onAdd={() => setPreference([])}
        isActive={preference != null}
        viewOnly={viewOnly}
      />

      {preference && (
        <ToggleGroup
          variant="outline"
          type="multiple"
          size="sm"
          value={preference}
          className="gap-2"
          onValueChange={(value) => setPreference(value as FrontendDerivativeScoreBucket[])}
          disabled={viewOnly}>
          <ToggleGroupItem value="low">{lowLabel}</ToggleGroupItem>
          <ToggleGroupItem value="medium">{mediumLabel}</ToggleGroupItem>
          <ToggleGroupItem value="high">{highLabel}</ToggleGroupItem>
        </ToggleGroup>
      )}
    </div>
  );
};

export const HardMetricFilterInputToggleGroup = ({
  hardFilter,
  setHardFilter,
  metric,
  text,
  infoText,
  viewOnly,
  lowText,
  mediumText,
  highText
}: {
  hardFilter?: HardFilter;
  setHardFilter: (hardFilter?: HardFilter) => void;
  metric: HardMetric;
  text: string;
  infoText?: string;
  viewOnly?: boolean;
  lowText?: string;
  mediumText?: string;
  highText?: string;
}) => {
  const lowLabel = lowText || "Low";
  const mediumLabel = mediumText || "Medium";
  const highLabel = highText || "High";
  const METRIC_RANGES: Record<
    string,
    {
      high: [number, number];
      medium: [number, number];
      low: [number, number];
    }
  > = {
    [HardMetric.HypeScore]: {
      high: [3, 5],
      medium: [2, 4],
      low: [1, 3]
    },
    [HardMetric.GrowthScore]: {
      high: [1, 3],
      medium: [-1, 1],
      low: [-3, -1]
    },
    [HardMetric.ProductScore]: {
      high: [7, 10],
      medium: [4, 7],
      low: [1, 4]
    },
    [HardMetric.TechScore]: {
      high: [7, 10],
      medium: [4, 7],
      low: [1, 4]
    }
  } as const;

  const lowMin = METRIC_RANGES[metric].low[0];
  const lowMax = METRIC_RANGES[metric].low[1];
  const mediumMin = METRIC_RANGES[metric].medium[0];
  const mediumMax = METRIC_RANGES[metric].medium[1];
  const highMin = METRIC_RANGES[metric].high[0];
  const highMax = METRIC_RANGES[metric].high[1];

  const getSelectedRanges = () => {
    if (!hardFilter) return [];
    const ranges: string[] = [];
    // TODO: kinda hacky, but it works
    const hardFilterMin = hardFilter.min ?? 9999;
    const hardFilterMax = hardFilter.max ?? -9999;
    if (hardFilterMin <= lowMin && hardFilterMax >= lowMax) ranges.push("low");
    if (hardFilterMin <= mediumMin && hardFilterMax >= mediumMax) ranges.push("medium");
    if (hardFilterMin <= highMin && hardFilterMax >= highMax) ranges.push("high");
    return ranges;
  };

  const handleValueChange = (values: string[]) => {
    const newFilter: HardFilter = {
      hard_metric: metric,
      nones_allowed: false
    };
    if (values.length === 0) {
      setHardFilter(undefined);
      return;
    }

    if (values.includes("low")) {
      newFilter.min = lowMin;
      newFilter.max = lowMax;
    }
    if (values.includes("medium")) {
      // if low bucket not selected, then set the min
      if (newFilter.max !== lowMax) newFilter.min = mediumMin;
      newFilter.max = mediumMax;
    }
    if (values.includes("high")) {
      // if neither bucket selected, then set the min
      if (newFilter.max !== mediumMax && newFilter.max !== lowMax) newFilter.min = highMin;
      newFilter.max = highMax;
    }

    setHardFilter(newFilter);
  };

  return (
    <div className="flex flex-col gap-1 items-start">
      <FilterHeader
        title={text}
        infoText={infoText}
        onDelete={() => setHardFilter(undefined)}
        onAdd={() => setHardFilter({ hard_metric: metric, nones_allowed: false })}
        isActive={hardFilter != null}
        viewOnly={viewOnly}
      />

      {hardFilter && (
        <ToggleGroup
          variant="outline"
          type="multiple"
          size="sm"
          value={getSelectedRanges()}
          className="gap-2"
          onValueChange={handleValueChange}
          disabled={viewOnly}>
          <ToggleGroupItem value="low">{lowLabel}</ToggleGroupItem>
          <ToggleGroupItem value="medium">{mediumLabel}</ToggleGroupItem>
          <ToggleGroupItem value="high">{highLabel}</ToggleGroupItem>
        </ToggleGroup>
      )}
    </div>
  );
};
