/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { ArrowsIn, ArrowsOut, Notepad } from "@phosphor-icons/react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { ChevronDown, ChevronUp, ExternalLink, Plus, Trash2 } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl, formatDollarValueMillionsBillions, truncateText } from "@/lib/utils";
import { FrontendTaxonomy, FrontendTaxonomyEntry, FrontendTaxonomyNode } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { useRef } from "react";
import { CompanyTypeChip, HypeFlameSizeIcon } from "@/components/company-table/common/CellComponents";

export interface TaxonomyEntryRow {
  id: string;
  title?: string;
  entry?: FrontendTaxonomyEntry;
  subRows?: TaxonomyEntryRow[];
  depth: number;
  totalEntries?: number;
}

export function createTaxonomyNodeRow(node: FrontendTaxonomyNode, depth: number): TaxonomyEntryRow {
  if (node.subnodes.length === 0) {
    return { id: node.node_title ?? "", title: node.node_description ?? "", depth: depth, totalEntries: 0, subRows: [] };
  } else {
    return {
      id: node.node_title ?? "",
      title: node.node_description ?? "",
      depth: depth,
      subRows: node.subnodes.map((subnode) => createTaxonomyNodeRow(subnode, depth + 1)),
      totalEntries: 0
    };
  }
}

export const TaxonomyTable = ({ taxonomy, hypeMinimum }: { taxonomy: FrontendTaxonomy; hypeMinimum?: number }) => {
  const tableWindowRef = useRef<HTMLDivElement>(null);
  const tableRows: TaxonomyEntryRow[] = [];

  // TODO: support multi-select

  //  first add all of the "node rows"
  taxonomy.taxonomy_node.subnodes.forEach((node) => {
    tableRows.push(createTaxonomyNodeRow(node, 0));
  });

  // then go through the entries and add them as subrows to the appropriate rows
  taxonomy.entries.forEach((entry) => {
    if (entry.traversal_path.length === 0) {
      console.log("entry has no traversal path", entry.company.domain);
    } else if (hypeMinimum != null && entry.company.hype_rating < hypeMinimum) {
      // console.log("entry has hype rating below minimum", entry.company.domain, entry.company.hype_rating);
    } else {
      let pathIndex = 0;
      let subNodeIndex = entry.traversal_path[0].choice_number;
      if (subNodeIndex == null) {
        let otherSubRow = tableRows.find((row) => row.title === "Other");
        if (otherSubRow == null) {
          otherSubRow = { id: "Other", title: "Other", depth: 0, subRows: [], totalEntries: 0 };
          tableRows.push(otherSubRow);
        }
        otherSubRow.subRows?.push({ id: entry.company.domain, entry: entry, depth: otherSubRow.depth + 1 });
        otherSubRow.totalEntries = (otherSubRow.totalEntries ?? 0) + 1;
        return;
      }
      let currentRow = tableRows[subNodeIndex];
      pathIndex += 1;
      while (pathIndex < entry.traversal_path.length) {
        currentRow.totalEntries = (currentRow.totalEntries ?? 0) + 1;
        subNodeIndex = entry.traversal_path[pathIndex].choice_number;
        if (subNodeIndex == null) {
          let otherSubRow = currentRow.subRows?.find((row) => row.title === "Other");
          if (otherSubRow == null) {
            otherSubRow = { id: "Other", title: "Other", depth: currentRow.depth + 1, subRows: [], totalEntries: 0 };
            currentRow.subRows?.push(otherSubRow);
          }
          currentRow = otherSubRow;
          break;
        }
        if (currentRow.subRows == null) {
          console.log("currentRow has no subRows", currentRow.id);
        } else {
          currentRow = currentRow.subRows[subNodeIndex];
        }
        pathIndex += 1;
      }
      // console.log(tableRows);
      // console.log(subNodeIndex);
      // console.log(currentRow);
      // console.log("adding entry to row", currentRow, entry.company.domain);
      currentRow.subRows?.push({ id: entry.company.domain, entry: entry, depth: currentRow.depth + 1 });
      currentRow.totalEntries = (currentRow.totalEntries ?? 0) + 1;
    }
  });
  // console.log(tableRows);

  const basicColumns: ColumnDef<TaxonomyEntryRow>[] = [
    {
      accessorKey: "title",
      size: 25,
      enableResizing: false, //disable resizing for just this column
      header: ({ table }) => {
        return (
          <SimpleTooltip text="Click to expand/collapse all rows">
            <Button
              variant="ghost"
              className="p-0 pl-1 w-full"
              onClick={() => {
                if (table.getIsSomeRowsExpanded()) {
                  table.toggleAllRowsExpanded(false);
                } else {
                  table.toggleAllRowsExpanded(true);
                }
              }}>
              {table.getIsSomeRowsExpanded() ? <ArrowsIn size={22} /> : <ArrowsOut size={22} />}
            </Button>
          </SimpleTooltip>
        );
      },
      cell: ({ row }) => (
        <div className="flex items-center py-2 overflow-visible whitespace-nowrap ">
          {/* {row.original.subRows.length} */}
          <div style={{ width: `${row.original.depth * 20}px` }}></div>
          {row.original.title != null && (
            <div className={`flex flex-row items-center`}>
              {row.getIsExpanded() ? (
                <ChevronUp size={22} className="text-colors-text-text-tertiary-(600)" />
              ) : (
                <ChevronDown size={22} className="text-colors-text-text-tertiary-(600)" />
              )}
              <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible w-0 z-[999999]">
                <span className="text-colors-text-text-tertiary-(600) mr-1">({row.original.totalEntries})</span>
                {truncateText(row.getValue("title"), 150)}
              </h1>
            </div>
          )}
        </div>
        // <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible whitespace-nowrap">{row.getValue("category")}</h1>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "name",
      // size: 100,
      header: ({}) => {
        return (
          // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
          // <CaretUpDown className="ml-2 h-4 w-4" />
          // </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex flex-col items-start py-2 pl-2 z-40">
          {row.original.entry != null && (
            <div className="flex flex-row items-center">
              <CompanyLogo
                name={row.original.entry.company.name}
                website={row.original.entry.company.domain}
                logo={row.original.entry.company.logo_url}
                className="w-8 h-8 mr-2 opacity-100"
              />
              <div className="flex flex-col">
                <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                  {row.original.entry.company.name}
                </h1>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
                    {row.original.entry.company.stock_ticker ?? ""}
                  </span>
                  <a
                    href={ensureAbsoluteUrl(row.original.entry.company.domain ?? "")}
                    className="flex flex-row hover:cursor-pointer font-medium underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ExternalLink size={14} color="grey" />
                  </a>
                  {row.original.entry.company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.entry.company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
                    </a>
                  )}
                  <HypeFlameSizeIcon hype={row.original.entry.company.hype_rating} />
                </div>
              </div>
            </div>
          )}
        </div>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "companyType",
      header: () => (
        <div className="flex flex-row items-center">
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
        </div>
      ),
      size: 160,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
          {/* <CompanyTypeIcon type={row.original.company_type} /> */}
          {row.original.entry != null && <CompanyTypeChip type={row.original.entry.company.company_type} />}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "employee_count",
      // size: 25,
      size: 80,
      header: () => (
        <div>
          <span>Employees</span>
        </div>
      ),
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-center">
          {/* TODO: show the range */}
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {employeeRangeText(
              row.original.entry?.company.employee_min,
              row.original.entry?.company.employee_max,
              row.original.entry?.company.employee_guess
            )}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Employee Count"
      }
      // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
      // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
    },
    {
      accessorKey: "overview",
      header: () => "Overview",
      size: 180,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.entry?.company.summary.overview.text}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "business_model",
      header: () => "Business model",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.entry?.company.summary.business_model.text}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Business Model"
      }
    },
    {
      accessorKey: "main_offerings",
      header: () => "Main offerings",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.entry != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.entry?.company.summary.main_offerings.text}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Main Offerings"
      }
    },
    {
      accessorKey: "customer_groups",
      header: () => "Customer groups",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.entry != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.entry?.company.summary.customer_segments.text}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Customer groups"
      }
    },
    {
      accessorKey: "geographies",
      header: () => "Geographies",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.entry != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.entry?.company.summary.geographies.text}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Geographies"
      }
    },
    {
      accessorKey: "headquarters",
      size: 100,
      header: () => <div className="w-auto">HQ</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.entry != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.entry?.company.hq_str}</span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Headquarters"
      }
    },
    // DROPPING THIS FROM TABLE BECAUSE DATA IS SUPER SPARSE
    // {
    //   accessorKey: "investors",
    //   header: () => <div className="w-auto">Investors</div>,
    //   cell: ({ row }) => (
    //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
    //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.list_of_investors}</span>
    //     </div>
    //   ),
    //   meta: {
    //     group: "basic_info",
    //     displayName: "Investors"
    //   }
    // },
    {
      accessorKey: "funds_raised",
      size: 100,
      header: () => <div className="w-auto justify-end text-right">Known fundraising</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {/* TODO: format this properly */}
            {row.original.entry?.company.total_known_fundraising?.amount
              ? formatDollarValueMillionsBillions(row.original.entry?.company.total_known_fundraising.amount)
              : "-"}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Known Fundraising"
      }
    },
    {
      accessorKey: "founded",
      size: 80,
      header: () => <div className="justify-end text-right">Founded</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.entry?.company.year_founded}</span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Founding year"
      }
    },
    {
      accessorKey: "web_traffic",
      size: 80,
      header: () => <div className="justify-end text-right">Web traffic</div>,
      cell: ({ row }) => {
        const visitsLastMonth = row.original.entry?.company.web_traffic?.visits_last_month;
        const visitGrowthMonthly = row.original.entry?.company.web_traffic?.visit_growth_monthly;
        const formattedVisits = visitsLastMonth
          ? visitsLastMonth >= 1000000
            ? (visitsLastMonth / 1000000).toFixed(1) + "M"
            : (visitsLastMonth / 1000).toFixed(0) + "k"
          : visitsLastMonth;
        const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
        const formattedGrowth =
          visitGrowthMonthly !== undefined ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%` : "";

        return (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {formattedVisits}
              <br />
              <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
            </span>
          </div>
        );
      },
      meta: {
        group: "basic_info",
        displayName: "Web traffic"
      }
    }
  ];
  const newColumns = [...basicColumns];

  const getAllColumns = () => {
    newColumns.push({
      accessorKey: "trash",
      size: 50,
      header: ({ table }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                <Plus size={20} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
              <span className="flex items-center justify-start my-1 p-1">
                <Notepad className="mr-1" size={22} />
                <h1 className="font-semi">Basic info</h1>
              </span>
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                .map((column: any) => {
                  // TODO: stop using any
                  return (
                    <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                      <Checkbox
                        key={column.id}
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) => {
                          column.toggleVisibility(!!value);
                          setTimeout(() => {
                            if (tableWindowRef.current) {
                              tableWindowRef.current.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                            }
                          }, 50);
                        }}>
                        {column.id}
                      </Checkbox>

                      <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                    </div>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableHiding: false,
      cell: ({ row, table }) => {
        const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
        return isRowHovered && row.original.entry != null ? (
          <div className="flex justify-center items-center">
            <Button
              className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
              onClick={(e) => {
                // if (row.original.id && row.original._category) {
                //   onBusinessDelete(row.original.id, row.original._category, row.original._subcategory);
                // }
                e.stopPropagation();
              }}>
              <Trash2 size={16} className="trash-icon" strokeWidth={2} />
            </Button>
          </div>
        ) : (
          <div className="min-w-[22px] min-h-[39px]"></div>
        );
      }
    });
  };

  getAllColumns();

  return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} />;
};
