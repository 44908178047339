import * as React from "react";

import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarRail, SidebarTrigger } from "@/components/ui/sidebar";
import { MeticulateLogoRedirect } from "@/lib/utilComponents";
import { FooterContent } from "./footer";
import { CompanyLookup } from "./company-lookup";
import { NavLists } from "./nav-lists";
import { Button } from "../ui/button";
import { setActiveView, View } from "@/store/views";
import { useAppDispatch } from "@/store/hooks";
import { Home } from "lucide-react";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const dispatch = useAppDispatch();
  return (
    <Sidebar {...props} className="z-[9000]">
      <SidebarHeader>
        <div className="flex flex-row justify-between items-center">
          <MeticulateLogoRedirect />
          <Button variant="ghost" onClick={() => dispatch(setActiveView(View.Dashboard))}>
            <Home size={24} />
          </Button>
          <SidebarTrigger />
        </div>
      </SidebarHeader>
      <SidebarContent style={{ transformStyle: "preserve-3d" }}>
        <CompanyLookup />
        {/* <NavMain items={data.navMain} /> */}
        <NavLists />
      </SidebarContent>
      <SidebarFooter>
        <FooterContent />
      </SidebarFooter>

      <SidebarRail />
    </Sidebar>
  );
}
