/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { ArrowsIn, ArrowsOut, Notepad } from "@phosphor-icons/react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { ChevronDown, ChevronUp, ExternalLink, Plus, Trash2 } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl, formatDollarValueMillionsBillions } from "@/lib/utils";
import { CompLandscapeCompany, MarketMap } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { useRef } from "react";
import { CompanyTypeChip, HypeFlameSizeIcon } from "@/components/company-table/common/CellComponents";

export interface CompFinderRow {
  id: string;
  category?: string;
  subcategory?: string;
  compLandscapeCompany?: CompLandscapeCompany;
  subRows?: CompFinderRow[];
  _category?: string;
  _subcategory?: string;
  _categoryDescription?: string;
  _subcategoryDescription?: string;
  _total_businesses?: number;
  _hasSubcategories?: boolean;
}

export const CompFinderTable = ({
  marketMap,
  compLandscapeCompanies,
  onBusinessDelete
}: {
  marketMap: MarketMap;
  compLandscapeCompanies: CompLandscapeCompany[];
  onBusinessDelete: (businessId: string, categoryName: string, subCategoryName?: string) => void;
}) => {
  const tableWindowRef = useRef<HTMLDivElement>(null);
  const tableRows: CompFinderRow[] = [];
  marketMap.categories.forEach((category, categoryIndex) => {
    const subRows: CompFinderRow[] = [];
    let hasSubcategories = false;
    let categoryBusinessCount = 0;
    // directly add companies as subRows
    category.businesses.forEach((businessId: string) => {
      const businessIndex = compLandscapeCompanies.findIndex((b) => b.company.domain === businessId);
      if (businessIndex !== -1) {
        subRows.push({ id: businessId, compLandscapeCompany: compLandscapeCompanies[businessIndex], _category: category.name });
      }
      categoryBusinessCount += 1;
    });
    // add subcategories as subRows if present
    category.subcategories.forEach((subcategory, subcategoryIndex) => {
      let subcategoryBusinessCount = 0;
      const companyRows: CompFinderRow[] = [];
      subcategory.businesses.forEach((businessId: string) => {
        const businessIndex = compLandscapeCompanies.findIndex((b) => b.company.domain === businessId);
        if (businessIndex !== -1) {
          companyRows.push({
            id: businessId,
            compLandscapeCompany: compLandscapeCompanies[businessIndex],
            _category: category.name,
            _subcategory: subcategory.name
          });
        }
        subcategoryBusinessCount += 1;
        categoryBusinessCount += 1;
      });
      // add subcategory header row
      subRows.push({
        subcategory: subcategory.name,
        _subcategory: subcategory.name,
        _subcategoryDescription: subcategory.description ?? undefined,
        _category: category.name,
        _categoryDescription: category.description ?? undefined,
        subRows: companyRows,
        _total_businesses: subcategoryBusinessCount,
        id: "subcategory-" + subcategoryIndex
      });
      hasSubcategories = true;
    });

    // add category header row
    tableRows.push({
      category: category.name,
      _category: category.name,
      _categoryDescription: category.description ?? undefined,
      subRows: subRows,
      _total_businesses: categoryBusinessCount,
      id: "category-" + categoryIndex,
      _hasSubcategories: hasSubcategories
    });
  });

  const basicColumns: ColumnDef<CompFinderRow>[] = [
    {
      accessorKey: "category",
      size: 25,
      enableResizing: false, //disable resizing for just this column
      header: ({ table }) => {
        return (
          <SimpleTooltip text="Click to expand/collapse all rows">
            <Button
              variant="ghost"
              className="p-0 pl-1 w-full"
              onClick={() => {
                if (table.getIsSomeRowsExpanded()) {
                  table.toggleAllRowsExpanded(false);
                } else {
                  table.toggleAllRowsExpanded(true);
                }
              }}>
              {table.getIsSomeRowsExpanded() ? <ArrowsIn size={22} /> : <ArrowsOut size={22} />}
            </Button>
          </SimpleTooltip>
        );
      },
      cell: ({ row }) => (
        <div className="flex items-center py-2 overflow-visible whitespace-nowrap ">
          {/* {row.original.subRows.length} */}
          {row.original.category != null && (
            <>
              {row.getIsExpanded() ? (
                <ChevronUp size={22} className="text-colors-text-text-tertiary-(600)" />
              ) : (
                <ChevronDown size={22} className="text-colors-text-text-tertiary-(600)" />
              )}
              <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible w-0 z-[999999]">
                {row.getValue("category")} <span className="text-colors-text-text-tertiary-(600)">({row.original._total_businesses})</span>
              </h1>
            </>
          )}
        </div>
        // <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible whitespace-nowrap">{row.getValue("category")}</h1>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "subcategory",
      size: 20,
      header: ({}) => {
        return (
          // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          <h1 className="text-colors-text-text-tertiary-(600) w-0"></h1>
          // <CaretUpDown className="ml-2 h-4 w-4" />
          // </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex items-center overflow-visible whitespace-nowrap z-50">
          {row.original.subcategory != null && row.subRows.length > 0 && (
            <>
              {row.getIsExpanded() ? (
                <ChevronUp size={22} className="text-colors-text-text-tertiary-(600)" />
              ) : (
                <ChevronDown size={22} className="text-colors-text-text-tertiary-(600)" />
              )}
              <h1 className="font-medium text-colors-text-text-secondary-(700) w-0">
                {row.getValue("subcategory")}
                <span className="text-colors-text-text-tertiary-(600)">({row.original._total_businesses})</span>
              </h1>
            </>
          )}
        </div>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "name",
      // size: 100,
      header: ({}) => {
        return (
          // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
          // <CaretUpDown className="ml-2 h-4 w-4" />
          // </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex flex-col items-start py-2 pl-2 z-40">
          {row.original.compLandscapeCompany != null && (
            <div className="flex flex-row items-center">
              <CompanyLogo
                name={row.original.compLandscapeCompany?.company.name}
                website={row.original.compLandscapeCompany?.company.domain}
                logo={row.original.compLandscapeCompany?.company.logo_url}
                className="w-8 h-8 mr-2 opacity-100"
              />
              <div className="flex flex-col">
                <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                  {row.original.compLandscapeCompany?.company.name}
                </h1>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
                    {row.original.compLandscapeCompany?.company.stock_ticker ?? ""}
                  </span>
                  <a
                    href={ensureAbsoluteUrl(row.original.compLandscapeCompany?.company.domain ?? "")}
                    className="flex flex-row hover:cursor-pointer font-medium underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ExternalLink size={14} color="grey" />
                  </a>
                  {row.original.compLandscapeCompany?.company.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.compLandscapeCompany?.company.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
                    </a>
                  )}
                  <HypeFlameSizeIcon hype={row.original.compLandscapeCompany?.company.hype_rating} />
                </div>
              </div>
            </div>
          )}
        </div>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "companyType",
      header: () => (
        <div className="flex flex-row items-center">
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
        </div>
      ),
      size: 160,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
          {/* <CompanyTypeIcon type={row.original.company_type} /> */}
          {row.original.compLandscapeCompany != null && <CompanyTypeChip type={row.original.compLandscapeCompany?.company.company_type} />}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "employee_count",
      // size: 25,
      size: 80,
      header: () => (
        <div>
          <span>Employees</span>
        </div>
      ),
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-center">
          {/* TODO: show the range */}
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {employeeRangeText(
              row.original.compLandscapeCompany?.company.employee_min,
              row.original.compLandscapeCompany?.company.employee_max,
              row.original.compLandscapeCompany?.company.employee_guess
            )}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Employee Count"
      }
      // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
      // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
    },
    {
      accessorKey: "overview",
      header: () => "Overview",
      size: 180,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.compLandscapeCompany?.company.summary.overview.text}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "business_model",
      header: () => "Business model",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.compLandscapeCompany?.company.summary.business_model.text}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Business Model"
      }
    },
    {
      accessorKey: "main_offerings",
      header: () => "Main offerings",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.compLandscapeCompany != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.compLandscapeCompany?.company.summary.main_offerings.text}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Main Offerings"
      }
    },
    {
      accessorKey: "customer_groups",
      header: () => "Customer groups",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.compLandscapeCompany != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.compLandscapeCompany?.company.summary.customer_segments.text}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Customer groups"
      }
    },
    {
      accessorKey: "geographies",
      header: () => "Geographies",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.compLandscapeCompany != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.compLandscapeCompany?.company.summary.geographies.text}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Geographies"
      }
    },
    {
      accessorKey: "headquarters",
      size: 100,
      header: () => <div className="w-auto">HQ</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          {row.original.compLandscapeCompany != null && (
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {row.original.compLandscapeCompany?.company.hq_str}
            </span>
          )}
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Headquarters"
      }
    },
    // DROPPING THIS FROM TABLE BECAUSE DATA IS SUPER SPARSE
    // {
    //   accessorKey: "investors",
    //   header: () => <div className="w-auto">Investors</div>,
    //   cell: ({ row }) => (
    //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
    //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.list_of_investors}</span>
    //     </div>
    //   ),
    //   meta: {
    //     group: "basic_info",
    //     displayName: "Investors"
    //   }
    // },
    {
      accessorKey: "funds_raised",
      size: 100,
      header: () => <div className="w-auto justify-end text-right">Known fundraising</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {/* TODO: format this properly */}
            {row.original.compLandscapeCompany?.company.total_known_fundraising?.amount
              ? formatDollarValueMillionsBillions(row.original.compLandscapeCompany?.company.total_known_fundraising.amount)
              : "-"}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Known Fundraising"
      }
    },
    {
      accessorKey: "founded",
      size: 80,
      header: () => <div className="justify-end text-right">Founded</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.compLandscapeCompany?.company.year_founded}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Founding year"
      }
    },
    {
      accessorKey: "web_traffic",
      size: 80,
      header: () => <div className="justify-end text-right">Web traffic</div>,
      cell: ({ row }) => {
        const visitsLastMonth = row.original.compLandscapeCompany?.company.web_traffic?.visits_last_month;
        const visitGrowthMonthly = row.original.compLandscapeCompany?.company.web_traffic?.visit_growth_monthly;
        const formattedVisits = visitsLastMonth
          ? visitsLastMonth >= 1000000
            ? (visitsLastMonth / 1000000).toFixed(1) + "M"
            : (visitsLastMonth / 1000).toFixed(0) + "k"
          : visitsLastMonth;
        const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
        const formattedGrowth =
          visitGrowthMonthly !== undefined ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%` : "";

        return (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {formattedVisits}
              <br />
              <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
            </span>
          </div>
        );
      },
      meta: {
        group: "basic_info",
        displayName: "Web traffic"
      }
    }
  ];
  const newColumns = [...basicColumns];

  const getAllColumns = () => {
    newColumns.push({
      accessorKey: "trash",
      size: 50,
      header: ({ table }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                <Plus size={20} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
              <span className="flex items-center justify-start my-1 p-1">
                <Notepad className="mr-1" size={22} />
                <h1 className="font-semi">Basic info</h1>
              </span>
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                .map((column: any) => {
                  // TODO: stop using any
                  return (
                    <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                      <Checkbox
                        key={column.id}
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) => {
                          column.toggleVisibility(!!value);
                          setTimeout(() => {
                            if (tableWindowRef.current) {
                              tableWindowRef.current.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                            }
                          }, 50);
                        }}>
                        {column.id}
                      </Checkbox>

                      <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                    </div>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableHiding: false,
      cell: ({ row, table }) => {
        const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
        return isRowHovered && row.original.compLandscapeCompany != null ? (
          <div className="flex justify-center items-center">
            <Button
              className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
              onClick={(e) => {
                if (row.original.id && row.original._category) {
                  onBusinessDelete(row.original.id, row.original._category, row.original._subcategory);
                }
                e.stopPropagation();
              }}>
              <Trash2 size={16} className="trash-icon" strokeWidth={2} />
            </Button>
          </div>
        ) : (
          <div className="min-w-[22px] min-h-[39px]"></div>
        );
      }
    });
  };

  getAllColumns();

  return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} />;
};
