import { useAppSelector } from "@/store/hooks";
// import { removeBusinessFromOutputMarketMap } from "@/store/genericWorkflows";
import { useEffect } from "react";
import { ScoredTaxonomyCompanyTable } from "./CompanyTable/ScoredTaxonomyTable";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../ui/resizable";
import { ScoredTaxonomyTreeViewer } from "./ScoredTaxonomyTreeViewer";
import { ScoredTaxonomyProvider, useScoredTaxonomy } from "./ScoredTaxonomyContext";
import { Input } from "../ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { ScoredTaxonomyCategoryTable } from "./CategoryTable/ScoredTaxonomyCategoryTable";

export const BetaWeightsEditor = () => {
  const { betaWeights, setBetaWeight } = useScoredTaxonomy();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Beta Weights Editor</h3>
      <div className="flex flex-wrap gap-4">
        {Object.entries(betaWeights).map(([dimension, weight]) => (
          <div key={dimension} className="flex flex-col items-start">
            <label htmlFor={`beta-${dimension}`} className="text-sm font-medium mb-1">
              {dimension}
            </label>
            <Input
              id={`beta-${dimension}`}
              type="number"
              value={weight}
              onChange={(e) => setBetaWeight(dimension, parseFloat(e.target.value))}
              className="w-16 px-1 py-0.5 border rounded-md bg-white"
              step="1"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const AlphaWeightsEditor = () => {
  const { alphaWeights, setAlphaWeight } = useScoredTaxonomy();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Alpha Weights Editor</h3>
      <div className="flex flex-wrap gap-4">
        {Object.entries(alphaWeights).map(([dimension, weight]) => (
          <div key={dimension} className="flex flex-col items-start">
            <label htmlFor={`alpha-${dimension}`} className="text-sm font-medium mb-1">
              {dimension}
            </label>
            <Input
              id={`alpha-${dimension}`}
              type="number"
              value={weight}
              onChange={(e) => setAlphaWeight(dimension, parseFloat(e.target.value))}
              className="w-16 px-1 py-0.5 border rounded-md bg-white"
              step="1"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const OverallScoreWeightsEditor = () => {
  const { overallScoreBetaWeight, overallScoreAlphaWeight, setOverallScoreBetaWeight, setOverallScoreAlphaWeight } = useScoredTaxonomy();
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Overall Score Weights Editor</h3>
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col items-start">
          <label htmlFor="overall-score-beta" className="text-sm font-medium mb-1">
            Overall Score Beta
          </label>
          <Input
            id="overall-score-beta"
            type="number"
            value={overallScoreBetaWeight}
            onChange={(e) => setOverallScoreBetaWeight(parseFloat(e.target.value))}
            className="w-16 px-1 py-0.5 border rounded-md bg-white"
            step="1"
          />
        </div>
        <div className="flex flex-col items-start">
          <label htmlFor="overall-score-alpha" className="text-sm font-medium mb-1">
            Overall Score Alpha
          </label>
          <Input
            id="overall-score-alpha"
            type="number"
            value={overallScoreAlphaWeight}
            onChange={(e) => setOverallScoreAlphaWeight(parseFloat(e.target.value))}
            className="w-16 px-1 py-0.5 border rounded-md bg-white"
            step="1"
          />
        </div>
      </div>
    </div>
  );
};

export const ScoredTaxonomyViewer = ({
  // onOpenRightSection,
  onCloseRightSection
}: {
  hideRefine?: boolean;
  hideFeedback?: boolean;
  onOpenRightSection?: () => void;
  onCloseRightSection?: () => void;
}) => {
  // const dispatch = useAppDispatch();
  const activeScoredTaxonomy = useAppSelector((state) =>
    state.taxonomies.scoredTaxonomies.find((t) => t.taxonomy_id === state.taxonomies.activeScoredTaxonomyId)
  );

  useEffect(() => {
    onCloseRightSection && onCloseRightSection();
  }, [activeScoredTaxonomy]);

  if (activeScoredTaxonomy == null) {
    return <div>No active scored taxonomy</div>;
  }
  return (
    <ScoredTaxonomyProvider>
      <Tabs defaultValue="tree" className="h-full w-full">
        <TabsList>
          <TabsTrigger value="tree">Tree</TabsTrigger>
          <TabsTrigger value="categoryTable">Category Table</TabsTrigger>
          <TabsTrigger value="companyTable">Company Table</TabsTrigger>
        </TabsList>

        <TabsContent value="combinedView">
          <ResizablePanelGroup direction="horizontal" className="h-full w-full">
            <ResizablePanel defaultSize={50}>
              <BetaWeightsEditor />
              <div className={`flex flex-col px-10 items-center gap-5 justify-start h-full py-10 overflow-y-scroll w-full`}>
                <ScoredTaxonomyTreeViewer rootNode={activeScoredTaxonomy.taxonomy_node} />
              </div>
            </ResizablePanel>

            <ResizableHandle withHandle className="z-[100]" />
            <ResizablePanel defaultSize={50}>
              <AlphaWeightsEditor />
              <OverallScoreWeightsEditor />
              <ScoredTaxonomyCompanyTable taxonomy={activeScoredTaxonomy} />
            </ResizablePanel>
          </ResizablePanelGroup>
        </TabsContent>
        <TabsContent value="tree">
          <div className="flex flex-col h-full w-full gap-2">
            <BetaWeightsEditor />
            <ScoredTaxonomyTreeViewer rootNode={activeScoredTaxonomy.taxonomy_node} />
          </div>
        </TabsContent>
        <TabsContent value="companyTable">
          <div className="flex flex-col h-full w-full gap-2">
            <AlphaWeightsEditor />
            <OverallScoreWeightsEditor />
            <ScoredTaxonomyCompanyTable taxonomy={activeScoredTaxonomy} />
          </div>
        </TabsContent>
        <TabsContent value="categoryTable">
          <div className="flex flex-col h-full w-full gap-2">
            <BetaWeightsEditor />
            <ScoredTaxonomyCategoryTable taxonomy={activeScoredTaxonomy} />
          </div>
        </TabsContent>
      </Tabs>
    </ScoredTaxonomyProvider>
  );
};
