import {
  GenericWorkflow,
  HardFilter,
  HardMetric,
  SourceCompaniesInputs,
  SourceCompaniesWorkflow,
  TaskStatus,
  WorkflowType
} from "@/services/autogen";
import {
  addSimilarCompaniesToFindCompaniesWorkflowThunk,
  refineGenericWorkflowThunk,
  removeFindCompaniesV2Company
} from "@/store/genericWorkflows";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { WorkflowViewerFeedbackAndExport } from "@/components/GenericWorkflows/WorkflowViewer";

import { SourceCompaniesTable } from "./GiantTable/SourceCompaniesTable";
import { Binoculars, Eye, EyeSlash, Funnel, PaperPlaneRight, PlusSquare, Sparkle, Spinner } from "@phosphor-icons/react";
import { SourceCompaniesWorkflowCreator, SourcingCriterionEditor } from "./SourceCompaniesWorkflowCreator";
import { DeprHardMetricFilterAddButton, HardMetricFilterInput } from "@/components/workflow-inputs/HardFilters";
import { CompanyLogo } from "../ui/avatar";
import { Slider } from "@mui/material";
import { toast } from "sonner";
import posthog from "posthog-js";
import { useAuth } from "@clerk/clerk-react";
import React, { useCallback } from "react";
import { SimpleTooltip } from "../ui/tooltip";
import { hardMetricDisplayText } from "@/lib/utils";
import { COUNTRY_OPTIONS } from "@/components/workflow-inputs/CountryOptions";
import { Badge } from "../ui/badge";
import { DeprCompanyTypeFilterAddButton, CompanyTypeFilterInput } from "@/components/workflow-inputs/CompanyType";
import { HqCountryFilterInput } from "@/components/workflow-inputs/HqCountry";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { addCompaniesToList } from "@/store/companyLists";
import { Plus } from "lucide-react";

const RefineWorkflowBox = ({ workflow }: { workflow: GenericWorkflow }) => {
  const dispatch = useAppDispatch();
  const [employeeCountFilter, setEmployeeCountFilter] = useState<HardFilter>();
  const [foundedYearFilter, setFoundedYearFilter] = useState<HardFilter>();
  const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
  const [workflowInputs, setWorkflowInputs] = useState(sourceCompaniesWorkflow.inputs);
  const { getToken } = useAuth();
  const existingRequestedNumberOfCompanies = sourceCompaniesWorkflow.inputs.requested_number_of_companies ?? 40;
  const getNextMultipleOf20 = (num: number): number => {
    return Math.ceil(num / 20) * 20;
  };
  const numberToReturnMinValue = getNextMultipleOf20(
    Math.max(sourceCompaniesWorkflow.outputs?.companies.length ?? 0, existingRequestedNumberOfCompanies, 40)
  );

  useEffect(() => {
    setWorkflowInputs(sourceCompaniesWorkflow.inputs);
    if (sourceCompaniesWorkflow.inputs.filters) {
      const employeeCountHardFilter = sourceCompaniesWorkflow.inputs.filters.find(
        (filter) => filter.hard_metric === HardMetric.EmployeeCount
      );
      if (employeeCountHardFilter) {
        setEmployeeCountFilter(employeeCountHardFilter);
      } else {
        setEmployeeCountFilter(undefined);
      }
      const foundedYearHardFilter = sourceCompaniesWorkflow.inputs.filters.find((filter) => filter.hard_metric === HardMetric.FoundedYear);
      if (foundedYearHardFilter) {
        setFoundedYearFilter(foundedYearHardFilter);
      } else {
        setFoundedYearFilter(undefined);
      }
    } else {
      setEmployeeCountFilter(undefined);
      setFoundedYearFilter(undefined);
    }
  }, [sourceCompaniesWorkflow]);

  const updateFilters = () => {
    const filters = [employeeCountFilter, foundedYearFilter].filter((f) => f != null) as HardFilter[];
    setWorkflowInputs({
      ...workflowInputs,
      filters: filters
    });
  };

  useEffect(() => {
    updateFilters();
  }, [employeeCountFilter, foundedYearFilter]);

  const onRefine = async () => {
    const token = await getToken();
    if (token) {
      const newSourceCompaniesWorkflow = { ...sourceCompaniesWorkflow, inputs: workflowInputs };
      const newGenericWorkflow = { ...workflow, workflow: newSourceCompaniesWorkflow };
      dispatch(refineGenericWorkflowThunk({ token, workflow: newGenericWorkflow })).then((action) => {
        const workflowId = action.payload as string | undefined;
        posthog.capture("user_ran_workflow", {
          type: WorkflowType.FindCompanies,
          inputs: workflowInputs,
          workflow_id: workflowId
        });
      });
    } else {
      toast.error("Could not get user session. Try refreshing the page");
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-colors-gray-modern-50 border-colors-brand-600 border rounded-lg p-4">
      {existingRequestedNumberOfCompanies != null && existingRequestedNumberOfCompanies < 100 && (
        <>
          <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg">
            <PlusSquare size={20} /> Add more companies
          </div>
          <div className="flex flex-row items-center gap-2 text-colors-text-text-secondary-(700)">
            <div className="text-sm ">Total number of companies to bring back: </div>
            <div className="flex flex-row justify-between w-40 items-center">
              <div className="mr-3 text-sm font-semibold">{workflowInputs.requested_number_of_companies ?? numberToReturnMinValue}</div>
              <Slider
                value={workflowInputs.requested_number_of_companies ?? numberToReturnMinValue}
                max={100}
                min={numberToReturnMinValue}
                step={20}
                onChange={(_, newValue) => {
                  setWorkflowInputs({
                    ...workflowInputs,
                    requested_number_of_companies: newValue as number
                  });
                }}
                valueLabelDisplay="auto"
                sx={{
                  "& .MuiSlider-thumb": {
                    color: "#9f78ed"
                  },
                  "& .MuiSlider-track": {
                    color: "#9f78ed"
                  },
                  "& .MuiSlider-rail": {
                    color: "#9f78ed"
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <p className="text-colors-text-text-secondary-(700) text-sm">
              You can find similar companies to any of the companies in the table by clicking on the
            </p>
            <Sparkle className="text-colors-brand-600" />
          </div>
        </>
      )}

      <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg mt-4">
        <Funnel size={20} /> Filters
      </div>
      <div className="p-2 gap-2 flex flex-col">
        <div className="flex flex-col flex-wrap gap-2 w-80">
          <CompanyTypeFilterInput
            filter={workflowInputs.company_type_filter}
            setFilter={(filter) => {
              console.log("setFilter", filter);
              setWorkflowInputs({ ...workflowInputs, company_type_filter: filter });
            }}
          />
          <HardMetricFilterInput
            metric={HardMetric.EmployeeCount}
            filter={employeeCountFilter}
            setFilter={(filter) => {
              setEmployeeCountFilter(filter);
            }}
          />
          <HardMetricFilterInput
            metric={HardMetric.FoundedYear}
            filter={foundedYearFilter}
            setFilter={(filter) => {
              setFoundedYearFilter(filter);
            }}
          />
          <HqCountryFilterInput
            hqCountryCodes={workflowInputs.hq_country_filter}
            setHqCountryCodes={(hqCountryCodes) => {
              setWorkflowInputs({ ...workflowInputs, hq_country_filter: hqCountryCodes });
            }}
          />
        </div>
        <div className="flex flex-col flex-wrap gap-2 w-60">
          <DeprCompanyTypeFilterAddButton
            filter={workflowInputs.company_type_filter}
            setFilter={(filter) => {
              setWorkflowInputs({ ...workflowInputs, company_type_filter: filter });
            }}
          />
          <DeprHardMetricFilterAddButton
            metric={HardMetric.EmployeeCount}
            filter={employeeCountFilter}
            setFilter={(filter) => {
              setEmployeeCountFilter(filter);
            }}
          />
          <DeprHardMetricFilterAddButton
            metric={HardMetric.FoundedYear}
            filter={foundedYearFilter}
            setFilter={(filter) => {
              setFoundedYearFilter(filter);
            }}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg mt-4">
            <Funnel size={20} /> Scoring criteria
          </div>
          {/* <Button onClick={() => setWorkflowInputs({ ...workflowInputs, ...defaultScope })}>Use default scope</Button> */}
          {workflowInputs.scoring_criteria &&
            workflowInputs.scoring_criteria.map((scoringCriterion, index) => (
              <SourcingCriterionEditor
                title={`Scoring criteria ${index + 1}`}
                showBadExample={true}
                scoringCriterion={scoringCriterion}
                setScoringCriterion={(scoringCriterion) => {
                  if (scoringCriterion == null) {
                    setWorkflowInputs({
                      ...workflowInputs,
                      scoring_criteria: workflowInputs.scoring_criteria?.filter((_, i) => i !== index)
                    });
                  } else {
                    setWorkflowInputs({
                      ...workflowInputs,
                      scoring_criteria: workflowInputs.scoring_criteria?.map((criterion, i) => (i === index ? scoringCriterion : criterion))
                    });
                  }
                }}
              />
            ))}
          {(workflowInputs.scoring_criteria == null || workflowInputs.scoring_criteria.length < 3) && (
            <Button
              onClick={() => {
                setWorkflowInputs({
                  ...workflowInputs,
                  scoring_criteria: [
                    ...(workflowInputs.scoring_criteria || []),
                    { question: "", low_score_standard: "", high_score_standard: "", weight: 2 }
                  ]
                });
              }}>
              <Plus className="mr-2" /> Add scoring criteria
            </Button>
          )}
        </div>
      </div>

      {/* <p className="text-sm text-colors-text-text-secondary-(700)">Removed companies</p>
      <div className="flex flex-row items-center gap-2">
        {findCompaniesWorkflow.outputs?.removed_company_domains?.map((domain) => <CompanyLogo name={domain} website={domain} />)}
      </div> */}
      <Button
        variant="default"
        className="w-40"
        // className="rounded-lg border border-colors-brand-500 px-4 py-2 mr-2 bg-colors-brand-100 hover:bg-colors-brand-300"
        onClick={onRefine}>
        <PaperPlaneRight size={22} className="mr-2 " />
        <p>Refine</p>
      </Button>
    </div>
  );
};

const SourceCompaniesInputsViewer = ({ inputs }: { inputs: SourceCompaniesInputs }) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg text-sm text-colors-text-text-secondary-(700) mb-2 p-2 border border-colors-brand-400">
      <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg">
        <Binoculars size={20} /> Search request
      </div>
      {inputs.business_model != null && <div>Business model: {inputs.business_model}</div>}
      {inputs.main_offerings != null && <div>Main offerings: {inputs.main_offerings}</div>}
      {inputs.customer_segments != null && <div>Customer segments: {inputs.customer_segments}</div>}
      {inputs.geographies != null && <div>Geographies: {inputs.geographies}</div>}
      {inputs.technologies != null && <div>Technologies: {inputs.technologies}</div>}
      {inputs.exemplar_company_domains && inputs.exemplar_company_domains.length > 0 && (
        <div className="flex flex-row gap-2 items-center">
          <div>Exemplar companies</div>
          <div className="flex flex-row items-center gap-2">
            {inputs.exemplar_company_domains.map((domain) => (
              <SimpleTooltip text={domain}>
                <CompanyLogo name={domain} website={domain} className="w-6 h-6" />
              </SimpleTooltip>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center gap-2 text-colors-brand-600 text-lg">
        <Funnel size={20} /> Filters
      </div>
      {inputs.company_type_filter && <CompanyTypeFilterInput filter={inputs.company_type_filter} setFilter={() => {}} viewOnly={true} />}
      {inputs.hq_country_filter && (
        <div>
          <div className="flex flex-row items-center gap-2">
            HQ Countries
            {inputs.hq_country_filter.map((country) => {
              return <Badge>{COUNTRY_OPTIONS.find((c) => c.value === country)?.label}</Badge>;
            })}
          </div>
        </div>
      )}
      {inputs.filters &&
        inputs.filters.map((filter) => (
          <div className="flex flex-row items-center space-x-2 text-sm text-colors-text-text-secondary-(700)">
            <div>{hardMetricDisplayText(filter.hard_metric) + ":"}</div>
            <div>
              {filter.min ?? "Any"} - {filter.max ?? "Any"}
            </div>
          </div>
        ))}
    </div>
  );
};

export const SourceCompaniesWorkflowViewer = React.memo(function SourceCompaniesWorkflowViewer({
  workflow,
  onOpenRightSection,
  hideRefine
}: {
  workflow: GenericWorkflow;
  onOpenRightSection?: () => void;
  hideRefine?: boolean;
}) {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const workflowId = workflow.workflow_id;
  const sourceCompaniesWorkflow = workflow.workflow as SourceCompaniesWorkflow;
  const onBusinessDelete = useCallback(
    (companyDomain: string) => {
      dispatch(removeFindCompaniesV2Company({ workflowId, companyDomain }));
    },
    [dispatch, workflowId]
  );

  const findSimilarBusinessesCallback = async (businessDomain: string) => {
    const token = await getToken();
    posthog.capture("user_clicked_find_similar_companies", {
      workflow_id: workflowId,
      company_domain: businessDomain
    });
    if (token) {
      dispatch(
        addSimilarCompaniesToFindCompaniesWorkflowThunk({
          token: token,
          workflowId: workflowId,
          companyDomain: businessDomain
        })
      );
    }
  };
  const onDomainCheckedChange = (domain: string, checked: boolean) => {
    if (checked) {
      setCompanyDomainsToAddToList([...companyDomainsToAddToList, domain]);
    } else {
      setCompanyDomainsToAddToList(companyDomainsToAddToList.filter((d) => d !== domain));
    }
  };

  useEffect(() => {
    onOpenRightSection && onOpenRightSection();
  }, []);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [inputsOpen, setInputsOpen] = useState(false);
  const [companyDomainsToAddToList, setCompanyDomainsToAddToList] = useState<string[]>([]);

  const companyLists = useAppSelector((state) => state.companyLists.companyLists);

  if (workflow.status === TaskStatus.Created) {
    return (
      <div className="flex flex-col h-full w-full gap-4">
        <SourceCompaniesWorkflowCreator workflow={workflow} />
      </div>
    );
  }
  if (sourceCompaniesWorkflow.outputs == null) {
    return <div>No results. Something went wrong</div>;
  }
  return (
    <div className="flex flex-col h-full w-full gap-4">
      {workflow.status === TaskStatus.Running && (
        <div className="w-full bg-colors-blue-100 border border-colors-blue-600 p-2 rounded-lg text-colors-blue-800 flex flex-row items-center">
          <Spinner size={24} className="mr-2 animate-spin" />
          <p>
            This workflow is still running but initial results will show up here within ~30 seconds. More companies will continue to appear
            as the search continues. You can export or refine the search after it's finished.
          </p>
        </div>
      )}
      <WorkflowViewerFeedbackAndExport workflow={workflow} disableCopy disableExport={workflow.status === TaskStatus.Running}>
        <Popover>
          <PopoverTrigger asChild>
            <Button>Add to list</Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className="flex flex-col gap-2">
              {companyLists.map((list) => (
                <Button
                  variant="outline"
                  key={list.id}
                  onClick={() => {
                    const companies = companyDomainsToAddToList
                      .map((domain) => sourceCompaniesWorkflow.outputs?.companies.find((c) => c.company.domain === domain))
                      .filter((c): c is NonNullable<typeof c> => c != null) // Type guard to ensure c is not null
                      .map((c) => c.company);
                    dispatch(addCompaniesToList({ companies, companyListId: list.id }));
                    toast.success(`Added ${companyDomainsToAddToList.length} companies to ${list.title}`);
                  }}>
                  {list.title}
                </Button>
              ))}
            </div>
          </PopoverContent>
        </Popover>
        {!hideRefine ? (
          <Button
            variant="outline"
            onClick={() => {
              setFiltersOpen(!filtersOpen);
              if (inputsOpen && !filtersOpen) {
                setInputsOpen(false);
              }
            }}>
            <Sparkle size={20} className="mr-2" />
            {filtersOpen ? "Hide" : "Refine this search"}
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="outline"
          onClick={() => {
            setInputsOpen(!inputsOpen);
            if (filtersOpen && !inputsOpen) {
              setFiltersOpen(false);
            }
          }}>
          {inputsOpen ? <EyeSlash size={20} className="mr-2" /> : <Eye size={20} className="mr-2" />}
          {inputsOpen ? "Hide request" : "Show search request"}
        </Button>
      </WorkflowViewerFeedbackAndExport>
      {inputsOpen && <SourceCompaniesInputsViewer inputs={sourceCompaniesWorkflow.inputs} />}
      {filtersOpen && <RefineWorkflowBox workflow={workflow} />}
      <SourceCompaniesTable
        businesses={sourceCompaniesWorkflow.outputs?.companies}
        onBusinessDelete={onBusinessDelete}
        findSimilarBusinessesCallback={findSimilarBusinessesCallback}
        isRunning={workflow.status === TaskStatus.Running}
        scoringCriteria={sourceCompaniesWorkflow.inputs.scoring_criteria ?? []}
        checkedDomains={companyDomainsToAddToList}
        onDomainCheckedChange={onDomainCheckedChange}
      />
    </div>
  );
});
