/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { Notepad } from "@phosphor-icons/react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { Plus } from "lucide-react";
import { CompanyLogo } from "@/components/ui/avatar";
import { DocumentType, FrontendDocument } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import React, { useRef } from "react";
import { DocumentTypeIcon } from "../DocumentsViewer";
import { ScoreChip } from "@/components/SourceCompanies/GiantTable/SourceCompaniesTable";

export interface DocumentRow extends FrontendDocument {
  tableRank: number;
  checked: boolean;
  internalExternal: "internal" | "external";
}

export const DocumentTypeChip = ({ type }: { type: DocumentType }) => {
  const icon = DocumentTypeIcon({ type });
  switch (type) {
    case DocumentType.News:
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-full px-2 py-1 text-colors-blue-800 flex flex-row items-center gap-1`}>
          {icon}
          News
        </div>
      );
    case DocumentType.PressRelease:
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-full px-2 py-1 text-colors-blue-800 flex flex-row items-center gap-1`}>
          {icon} Press release
        </div>
      );
    case DocumentType.BlogPost:
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-full px-2 py-1 text-colors-yellow-800 flex flex-row items-center gap-1`}>
          {icon} Blog post
        </div>
      );
    case DocumentType.Forum:
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-full px-2 py-1 text-colors-yellow-800 flex flex-row items-center gap-1`}>
          {icon} Forum
        </div>
      );
    case DocumentType.CompanyProfile:
      return (
        <div
          className={`bg-colors-brand-100 border border-colors-brand-300 rounded-full px-2 py-1 text-colors-brand-800 flex flex-row items-center gap-1`}>
          {icon} Company profile
        </div>
      );
    case DocumentType.CompanyPage:
      return (
        <div
          className={`bg-colors-pink-100 border border-colors-pink-300 rounded-full px-2 py-1 text-colors-pink-800 flex flex-row items-center gap-1`}>
          {icon} Company page
        </div>
      );
    case DocumentType.Other:
      return (
        <div
          className={`bg-colors-error-100 border border-colors-error-300 rounded-full px-2 py-1 text-colors-error-800 flex flex-row items-center gap-1`}>
          {icon} Other
        </div>
      );
    case DocumentType.Cim:
      return (
        <div
          className={`bg-colors-orange-100 border border-colors-orange-300 rounded-full px-2 py-1 text-colors-orange-800 flex flex-row items-center gap-1`}>
          {icon} CIM
        </div>
      );
    case DocumentType.TermSheet:
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          {icon} Term sheet
        </div>
      );
    case DocumentType.IcMemo:
      return (
        <div
          className={`bg-colors-success-100 border border-colors-success-300 rounded-full px-2 py-1 text-colors-success-800 flex flex-row items-center gap-1`}>
          {icon} IC memo
        </div>
      );
    case DocumentType.CallNotes:
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          {icon} Call notes
        </div>
      );
    case DocumentType.ResearchReport:
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          {icon} Research report
        </div>
      );
    case DocumentType.CompanyPresentation:
      return (
        <div
          className={`bg-colors-success-100 border border-colors-success-300 rounded-full px-2 py-1 text-colors-success-800 flex flex-row items-center gap-1`}>
          {icon} Company presentation
        </div>
      );
    case DocumentType.TwoPager:
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          {icon} Two-pager
        </div>
      );
    // TODO: defunct and subsidiary
    default:
      return <div>{type}</div>;
  }
};

export const DocumentTable = React.memo(
  ({
    internalDocuments,
    externalDocuments,
    onDocumentClick,
    isRunning,
    showInternalExternal = false
  }: {
    internalDocuments: FrontendDocument[];
    externalDocuments: FrontendDocument[];
    onDocumentClick: (document: FrontendDocument) => void;
    isRunning: boolean;
    showInternalExternal?: boolean;
  }) => {
    const tableWindowRef = useRef<HTMLDivElement>(null);
    // TODO: type this properly
    const tableRows: DocumentRow[] = [];
    internalDocuments.forEach((document, index) => {
      tableRows.push({ ...document, tableRank: index, checked: false, internalExternal: "internal" });
    });
    externalDocuments.forEach((document, index) => {
      tableRows.push({ ...document, tableRank: index, checked: false, internalExternal: "external" });
    });

    const basicColumns: ColumnDef<DocumentRow>[] = [
      {
        accessorKey: "name",
        // size: 100,
        header: ({}) => {
          return (
            // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Document</h1>
            // <CaretUpDown className="ml-2 h-4 w-4" />
            // </Button>
          );
        },
        cell: ({ row }) => (
          <div className="flex flex-col items-start py-2 pl-2 z-40">
            {row.original.title != null && (
              <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                {row.original.title}
              </h1>
            )}
          </div>
        ),
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "documentType",
        header: () => (
          <div className="flex flex-row items-center">
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
          </div>
        ),
        size: 100,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] flex pl-2">
            {row.original.page_type && <DocumentTypeChip type={row.original.page_type} />}
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Type"
        }
      },
      {
        accessorKey: "date",
        // size: 25,
        size: 80,
        header: () => (
          <div>
            <span>Date</span>
          </div>
        ),
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-start text-left">
            {/* TODO: show the range */}
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-semibold">
              {row.original.date_published
                ? new Date(row.original.date_published)
                    .toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
                    .replace(",", "")
                : ""}
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Date"
        }
        // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
        // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
      },
      {
        accessorKey: "credibility",
        header: () => "Credibility",
        size: 10,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 text-center">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              <ScoreChip score={row.original.credibility_level} maxScore={5} />
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Credibility"
        }
      },
      {
        accessorKey: "bias",
        header: () => "Bias",
        size: 20,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 text-center">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              <ScoreChip score={row.original.bias_level} maxScore={5} />
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Bias"
        }
      },
      {
        accessorKey: "informationLevel",
        header: () => "Information content",
        size: 10,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 text-center">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              <ScoreChip score={row.original.information_level} maxScore={5} />
            </span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Information content"
        }
      },
      {
        accessorKey: "companiesMentioned",
        header: () => "Primary companies mentioned",
        cell: ({ row }) => (
          <div className="flex flex-row flex-wrap overflow-hidden ellipsis w-auto pr-5 gap-2">
            {row.original.company_prominences &&
              Object.entries(row.original.company_prominences)
                .slice(0, 5)
                .filter(([_, prominence]) => prominence > 3)
                .map(([company, prominence]) => (
                  <div key={company} className="flex items-center gap-1">
                    <div
                      className={`rounded-full ${
                        prominence === 5
                          ? "border-2 border-green-600/90"
                          : prominence === 4
                          ? "border-2 border-green-400/90"
                          : "border-2 border-green-400/90"
                      }`}
                      title={`Prominence: ${prominence}`}>
                      <CompanyLogo name={company} website={company} className="w-6 h-6" />
                    </div>
                  </div>
                ))}
            {/* <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div> */}
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Companies mentioned"
        }
      },
      {
        accessorKey: "otherCompaniesMentioned",
        header: () => "Other companies mentioned",
        cell: ({ row }) => (
          <div className="flex flex-row flex-wrap overflow-hidden ellipsis w-auto pr-5 gap-2">
            {row.original.company_prominences &&
              Object.entries(row.original.company_prominences)
                .slice(0, 5)
                .filter(([_, prominence]) => prominence <= 3)
                .map(([company, prominence]) => (
                  <div key={company} className="flex items-center gap-1">
                    <div
                      className={`rounded-full ${
                        prominence === 5
                          ? "border-2 border-green-600/90"
                          : prominence === 4
                          ? "border-2 border-green-400/90"
                          : "border-2 border-green-400/90"
                      }`}
                      title={`Prominence: ${prominence}`}>
                      <CompanyLogo name={company} website={company} className="w-6 h-6" />
                    </div>
                  </div>
                ))}
            {/* <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div> */}
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Companies mentioned"
        }
      },
      {
        accessorKey: "summary",
        header: () => "Summary",
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary}</span>
            <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Summary"
        }
      }
    ];
    if (showInternalExternal) {
      basicColumns.splice(3, 0, {
        accessorKey: "internalExternal",
        header: () => "Source",
        size: 80,
        cell: ({ row }) => (
          <>
            {row.original.internalExternal == "internal" ? (
              <div className="flex flex-col overflow-hidden ellipsis max-h-[64px] pr-5 text-center items-center">
                <div className="flex flex-col items-center justify-center bg-colors-brand-200 p-1 border border-colors-brand-400 rounded-md">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">Internal</span>
                </div>
              </div>
            ) : (
              <div className="flex flex-col overflow-hidden ellipsis w-auto max-h-[64px] pr-5 text-center items-center">
                <div className="flex flex-col items-center justify-center bg-colors-blue-200 p-1 border border-colors-blue-400 rounded-md">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">External</span>
                </div>
              </div>
            )}
          </>
        ),
        meta: {
          group: "basic_info",
          displayName: "Internal/External"
        }
      });
    }
    const newColumns = [...basicColumns];

    const getAllColumns = () => {
      newColumns.push({
        accessorKey: "trash",
        size: 50,
        header: ({ table }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                  <Plus size={20} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
                <span className="flex items-center justify-start my-1 p-1">
                  <Notepad className="mr-1" size={22} />
                  <h1 className="font-semi">Basic info</h1>
                </span>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                  .map((column: any) => {
                    // TODO: stop using any
                    return (
                      <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                        <Checkbox
                          key={column.id}
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) => {
                            column.toggleVisibility(!!value);

                            // sleep for 1 sec
                            setTimeout(() => {
                              console.log("trying to scroll");
                              // scroll to the right
                              tableWindowRef.current?.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                            }, 100);
                          }}>
                          {column.id}
                        </Checkbox>

                        <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                      </div>
                    );
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
        enableHiding: false,
        cell: ({}) => {
          // const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
          // return isRowHovered ? (
          //   <div className="flex justify-center items-center">
          //     <Button
          //       className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
          //       onClick={(e) => {
          //         onBusinessDelete(row.original.domain);
          //         e.stopPropagation();
          //       }}>
          //       <Trash2 size={16} className="trash-icon" strokeWidth={2} />
          //     </Button>
          //   </div>
          // ) : (
          //   <div className="min-w-[22px] min-h-[39px]"></div>
          // );
        }
      });
    };

    getAllColumns();

    return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} onDocumentClick={onDocumentClick} isRunning={isRunning} />;
    // return <SimpleDataTable columns={newColumns} data={tableRows} />;
  }
);
