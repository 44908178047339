import { FrontendCompany } from "@/services/autogen";
import { Users, Globe2, Boxes, BriefcaseBusiness } from "lucide-react";

export function SummaryBox({ company }: { company: FrontendCompany }) {
  return (
    <div className="flex flex-col border border-colors-border-border-primary bg-white rounded-xl p-3 items-start gap-2">
      <div className="flex flex-col gap-6 items-start text-sm">
        {company.summary.business_model && (
          <div className="flex flex-col w-full">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <BriefcaseBusiness size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Business model</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.business_model.text} </div>
            </div>
          </div>
        )}
        {company.summary.main_offerings && (
          <div className="flex flex-col w-full">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Boxes size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Offerings</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.main_offerings.text} </div>
            </div>
          </div>
        )}
        {company.summary.customer_segments && (
          <div className="flex flex-col w-full">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Users size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Customers</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1">{company.summary.customer_segments.text}</div>
            </div>
          </div>
        )}
        {company.summary.geographies && (
          <div className="flex flex-col w-full">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Globe2 size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Geographies</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.geographies.text} </div>
            </div>
          </div>
        )}
        {/* {company.summary.technologies && (
          <div className="flex flex-col w-full">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Cpu size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Technologies</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.technologies.text} </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
