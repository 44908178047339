/* eslint-disable @typescript-eslint/no-empty-function */
import { PeopleList } from "@/services/autogen";
import { useLoaderData } from "react-router-dom";
import { useAppDispatch } from "@/store/hooks";

import { useEffect } from "react";
import { Toaster } from "sonner";
import { PeopleListViewer, PeopleListHeader } from "./PeopleListViewer";
import { loadPeopleList, setActivePeopleList } from "@/store/peopleLists";

export function ShareablePeopleListViewer() {
  const dispatch = useAppDispatch();

  const peopleList = useLoaderData() as PeopleList | null;
  useEffect(() => {
    if (peopleList) {
      dispatch(loadPeopleList({ peopleList }));
      dispatch(setActivePeopleList({ peopleListId: peopleList.id }));
    }
  }, [peopleList]);
  if (peopleList == null) {
    return <div>404</div>;
  }

  return (
    <div className="flex app-background">
      <div className="flex flex-col w-full h-screen">
        {/*  TODO: show Logo and runYourOwnButton */}
        <div className="py-1">
          <PeopleListHeader shareableHeader />
        </div>
        <div className="flex flex-col flex-grow w-full p-10 overflow-hidden">
          <PeopleListViewer viewOnly />
        </div>
      </div>
      <Toaster richColors position="top-center" />
    </div>
  );
}
