import {
  ExportFormat,
  FrontendAllocationAnswer,
  FrontendCompanySearchScoringInstructions,
  FrontendFullCompanySearch,
  FrontendQualificationCriterion,
  FrontendQualifiedAndScoredCompany,
  FrontendQualifiedCompany,
  FrontendScoringDirection
} from "@/services/autogen";

import { useState, useRef, useEffect } from "react";

import { Funnel, Ranking, Sparkle } from "@phosphor-icons/react";
import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { COMPANY_SEARCH_RUNNING_STATUSES } from "@/lib/constants";
import { Drawer, DrawerContent } from "../ui/drawer";
import { ScoredCompanyTable } from "./ScoredCompanyTable";

import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import { deprExpandCompanySearchThunk, deprUpdateCompanySearchScoringInstructions } from "@/store/deprCompanySearches";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { toast } from "sonner";
import { addCompaniesToList, createCompanyList } from "@/store/companyLists";
import posthog from "posthog-js";
import { exportCompanySearchResults, deprPersistCompanySearchScoringInstructions } from "@/services/brain-api.service";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";
import { QualifiedCompanyTable } from "./QualifiedCompanyTable";
import { ExtensibleVerticalCompanyProfile } from "../company-profile-v2/vertical/ExtensibleVerticalCompanyProfile";
import { FindContactsDropdown } from "../company-lists/FindContactsDropdown";
import { ExportListButton } from "../company-table/ExportListButton";
import { JobListingDisplay } from "../company-profile-v2/TeamDive";
import _ from "lodash";
import { Plus, Rows4 } from "lucide-react";

const AddCompaniesToListDropdown = ({
  domainsSelected,
  companySearch,
  workflowTitle,
  disabled
}: {
  domainsSelected: string[];
  companySearch: FrontendFullCompanySearch;
  workflowTitle: string;
  disabled?: boolean;
}) => {
  const companyLists = useAppSelector((state) => state.companyLists.companyLists);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const companiesToUse = companySearch.results?.qualified_companies ?? [];
  const metaCompanies = domainsSelected.map((domain) => companiesToUse.find((c) => c.company.domain === domain)).filter((c) => c != null);
  const companies = metaCompanies.map((c) => c.company);

  const handleAddToList = async (listId?: string, listTitle?: string) => {
    if (domainsSelected.length === 0) {
      toast.error("Please select at least one company");
      return;
    }

    if (listId) {
      // Add to existing list
      dispatch(addCompaniesToList({ companies, companyListId: listId }));
      toast.success(`Added ${domainsSelected.length} companies to ${listTitle}`);
    } else {
      // Create new list
      dispatch(createCompanyList({ title: `Companies from ${workflowTitle}`, companies }));
      toast.success(`Added ${domainsSelected.length} companies to new list`);
    }
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {domainsSelected.length > 0 && (
        <PopoverTrigger asChild>
          <Button size="sm" disabled={disabled}>
            <Rows4 size={20} /> Add to list
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent className="">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-500">Add to list</div>
          {companyLists.map((list) => (
            <Button key={list.id} variant="outline" className="w-full justify-start" onClick={() => handleAddToList(list.id, list.title)}>
              {_.truncate(list.title, { length: 30 })}
            </Button>
          ))}
          <Button variant="default" className="w-full justify-start" onClick={() => handleAddToList()}>
            <div>New list</div>
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const ScoringWeightPlusMinus = ({
  label,
  weight,
  setWeight
}: {
  label: string;
  weight: number;
  setWeight: (newWeight: number) => void;
}) => {
  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <div className="text-sm truncate max-w-[200px]">{label}</div>
      <div className="flex flex-row items-center gap-2">
        <div className="text-sm font-medium w-8 text-center">{weight}</div>
        <Button
          variant="outline"
          disabled={weight <= 0}
          size="sm"
          className="h-8 w-8 p-0"
          onClick={() => {
            setWeight(weight - 1);
          }}>
          -
        </Button>
        <Button
          variant="outline"
          size="sm"
          disabled={weight >= 5}
          className="h-8 w-8 p-0"
          onClick={() => {
            setWeight(weight + 1);
          }}>
          +
        </Button>
      </div>
    </div>
  );
};

const EditScoringWeightsButton = ({
  searchId,
  scoringInstructions,
  setScoringInstructions,
  disablePersist
}: {
  searchId: string;
  scoringInstructions: FrontendCompanySearchScoringInstructions;
  setScoringInstructions: (newWeights: FrontendCompanySearchScoringInstructions) => void;
  disablePersist?: boolean;
}) => {
  const token = useAppSelector((state) => state.user.token);
  const saveTimeoutRef = useRef<NodeJS.Timeout>();

  const handleWeightChange = (newInstructions: FrontendCompanySearchScoringInstructions) => {
    setScoringInstructions(newInstructions);

    // Clear any existing timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Debounce the save operation
    saveTimeoutRef.current = setTimeout(async () => {
      posthog.capture("user_saved_scoring_weights", { search_id: searchId });
      if (token && !disablePersist) {
        await deprPersistCompanySearchScoringInstructions(token, searchId, newInstructions);
      }
    }, 1000); // Wait 1 second after last change before saving
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm">
          <Ranking size={20} className="" />
          Edit weights
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col">
        <div className="flex flex-col gap-2 p-2">
          <span className="text-sm font-semibold text-colors-brand-700">Edit scoring weights</span>
          {/* General scoring categories */}
          <ScoringWeightPlusMinus
            label="Hype Score"
            weight={scoringInstructions.hype_scoring?.weight ?? 0}
            setWeight={(newWeight) => {
              handleWeightChange({
                ...scoringInstructions,
                hype_scoring: {
                  ...(scoringInstructions.hype_scoring ?? { scoring_direction: FrontendScoringDirection.HigherIsBetter }),
                  weight: newWeight
                }
              });
            }}
          />
          <ScoringWeightPlusMinus
            label="Growth Score"
            weight={scoringInstructions.growth_scoring?.weight ?? 0}
            setWeight={(newWeight) => {
              handleWeightChange({
                ...scoringInstructions,
                growth_scoring: {
                  ...(scoringInstructions.growth_scoring ?? { scoring_direction: FrontendScoringDirection.HigherIsBetter }),
                  weight: newWeight
                }
              });
            }}
          />
          {scoringInstructions.tech_stack_scoring && (
            <ScoringWeightPlusMinus
              label="Tech stack"
              weight={scoringInstructions.tech_stack_scoring.weight}
              setWeight={(newWeight) => {
                handleWeightChange({
                  ...scoringInstructions,
                  tech_stack_scoring: { ...scoringInstructions.tech_stack_scoring!, weight: newWeight }
                });
              }}
            />
          )}
          {scoringInstructions.employee_roles_scoring && (
            <ScoringWeightPlusMinus
              label="Employee roles"
              weight={scoringInstructions.employee_roles_scoring?.weight ?? 0}
              setWeight={(newWeight) => {
                handleWeightChange({
                  ...scoringInstructions,
                  employee_roles_scoring: { ...scoringInstructions.employee_roles_scoring!, weight: newWeight }
                });
              }}
            />
          )}
          {scoringInstructions.hiring_activity_scoring && (
            <ScoringWeightPlusMinus
              label="Hiring activity"
              weight={scoringInstructions.hiring_activity_scoring?.weight ?? 0}
              setWeight={(newWeight) => {
                handleWeightChange({
                  ...scoringInstructions,
                  hiring_activity_scoring: { ...scoringInstructions.hiring_activity_scoring!, weight: newWeight }
                });
              }}
            />
          )}

          {/* Existing scoring questions */}
          {scoringInstructions.scoring_questions.map((question, index) => (
            <ScoringWeightPlusMinus
              key={index}
              label={question.question}
              weight={question.weight}
              setWeight={(newWeight) => {
                const newQuestions = [...scoringInstructions.scoring_questions];
                newQuestions[index] = {
                  ...question,
                  weight: newWeight
                };
                handleWeightChange({ ...scoringInstructions, scoring_questions: newQuestions });
              }}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

enum ResultsMode {
  Qualified = "qualified",
  Scored = "scored"
}

const CompanyProfileQualificationBox = ({
  answers,
  qualificationCriteria
}: {
  answers: FrontendAllocationAnswer[];
  qualificationCriteria: FrontendQualificationCriterion[];
}) => {
  return (
    <div className="flex flex-col gap-2 border border-colors-brand-200 rounded-lg p-3">
      <div className="text-colors-brand-700 font-semibold flex flex-row items-center">
        <Funnel size={20} className="mr-2" />
        Qualification
      </div>
      {answers.map((answer, index) => {
        return (
          <div key={index}>
            <div className="text-colors-brand-700 font-normal">
              <span className="text-colors-gray-modern-400">Company:</span> {qualificationCriteria[index].title}
            </div>
            <div className="flex flex-row gap-2 items-center">
              <ScoreChip score={answer.fit_level} numberOfDecimals={0} />
              <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{answer.explanation}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CompanyProfileScoringBox = ({
  scoredCompany,
  scoringInstructions,
  maxOverallScore,
  minOverallScore
}: {
  scoredCompany: FrontendQualifiedAndScoredCompany;
  scoringInstructions: FrontendCompanySearchScoringInstructions;
  maxOverallScore: number;
  minOverallScore: number;
}) => {
  const scoringResults = scoredCompany.scoring_results;
  return (
    <div className="flex flex-col gap-2 border border-colors-brand-200 rounded-lg p-3">
      <div className="text-colors-brand-700 font-semibold flex flex-row items-center gap-2">
        <Sparkle size={20} />
        Scoring
        {scoredCompany.overall_score != null && (
          <span className="font-normal text-sm">
            <ScoreChip score={scoredCompany.overall_score} numberOfDecimals={1} minScore={minOverallScore} maxScore={maxOverallScore} />
          </span>
        )}
      </div>
      {scoredCompany.tech_stack_score != null && scoringInstructions.tech_stack_scoring != null && (
        <div>
          <div className="text-colors-brand-700 font-normal">
            <span className="text-colors-gray-modern-400">Tech stack:</span>{" "}
            <span className="">{scoringInstructions.tech_stack_scoring.question}</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <ScoreChip score={scoredCompany.tech_stack_score.score} numberOfDecimals={0} minScore={0} maxScore={5} />
            <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{scoredCompany.tech_stack_score.explanation?.text}</div>
          </div>
        </div>
      )}
      {scoredCompany.employee_roles_score != null && scoringInstructions.employee_roles_scoring != null && (
        <div>
          <div className="text-colors-brand-700 font-normal">
            <span className="text-colors-gray-modern-400">Employee roles:</span>{" "}
            <span className="">{scoringInstructions.employee_roles_scoring.question}</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <ScoreChip score={scoredCompany.employee_roles_score.score} numberOfDecimals={0} minScore={0} maxScore={5} />
            <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{scoredCompany.employee_roles_score.explanation?.text}</div>
          </div>
        </div>
      )}
      {scoredCompany.hiring_activity_score != null && scoringInstructions.hiring_activity_scoring != null && (
        <div>
          <div className="text-colors-brand-700 font-normal">
            <span className="text-colors-gray-modern-400">Hiring activity:</span>{" "}
            <span className="">{scoringInstructions.hiring_activity_scoring.question}</span>
          </div>
          <div className="flex flex-row gap-2 items-center pt-2">
            <ScoreChip score={scoredCompany.hiring_activity_score.score} numberOfDecimals={0} minScore={0} maxScore={5} />
            {scoredCompany.hiring_activity_score.matching_jobs.length > 0 ? (
              <div className="text-colors-brand-500 font-light text-sm pl-2 space-y-2">
                {scoredCompany.hiring_activity_score.matching_jobs.map((job, index) => (
                  <JobListingDisplay key={index} linkedinJob={job} />
                ))}
              </div>
            ) : (
              <div className="text-colors-brand-500 font-light text-sm pl-2 ">No matching jobs found</div>
            )}
          </div>
        </div>
      )}
      {scoringResults.map((answer, index) => {
        if (answer.explanation != null) {
          return (
            <div key={index}>
              <div className="text-colors-brand-700 font-normal">{answer.question}</div>
              <div className="flex flex-row gap-2 items-center">
                <ScoreChip score={answer.score} numberOfDecimals={0} minScore={0} maxScore={5} />
                <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{answer.explanation?.text}</div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

const ExpandSearchButton = ({ search, disabled }: { search: FrontendFullCompanySearch; disabled?: boolean }) => {
  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          disabled={
            disabled
            // search.results?.pipeline_status === FrontendCompanySearchPipelineStatus.ExhaustedFreshUniverse ||
            // search.results?.pipeline_status === FrontendCompanySearchPipelineStatus.NoUniverseAvailable
          }>
          <Plus size={20} /> Get more companies
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-2 p-2">
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            posthog.capture("user_expanded_company_search_results", { search_id: search.search_id, amount: 50 });
            if (token) {
              dispatch(deprExpandCompanySearchThunk({ token, search_id: search.search_id, expand_by: 50 }));
            }
          }}>
          + 50 more
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            posthog.capture("user_expanded_company_search_results", { search_id: search.search_id, amount: 100 });
            if (token) {
              dispatch(deprExpandCompanySearchThunk({ token, search_id: search.search_id, expand_by: 100 }));
            }
          }}>
          + 100 more
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            posthog.capture("user_expanded_company_search_results", { search_id: search.search_id, amount: 200 });
            if (token) {
              dispatch(deprExpandCompanySearchThunk({ token, search_id: search.search_id, expand_by: 200 }));
            }
          }}>
          + 200 more
        </Button>
      </PopoverContent>
    </Popover>
  );
};

const computeOverallScore = (
  scoredCompany: FrontendQualifiedAndScoredCompany,
  scoringInstructions: FrontendCompanySearchScoringInstructions
): number => {
  let totalWeight = 0;
  let totalScore = 0;
  if (scoringInstructions.hype_scoring != null) {
    totalWeight += scoringInstructions.hype_scoring.weight;
    totalScore += scoredCompany.company.hype_rating * scoringInstructions.hype_scoring.weight;
  }
  if (scoringInstructions.growth_scoring != null) {
    totalWeight += scoringInstructions.growth_scoring.weight;
    totalScore += (scoredCompany.company.growth_score ?? 0) * scoringInstructions.growth_scoring.weight;
  }
  if (scoringInstructions.product_scoring != null) {
    totalWeight += scoringInstructions.product_scoring.weight;
    totalScore += (scoredCompany.company.product_score ?? 0) * scoringInstructions.product_scoring.weight;
  }
  if (scoringInstructions.tech_scoring != null) {
    totalWeight += scoringInstructions.tech_scoring.weight;
    totalScore += (scoredCompany.company.technology_score ?? 0) * scoringInstructions.tech_scoring.weight;
  }
  if (scoringInstructions.tech_stack_scoring != null) {
    totalWeight += scoringInstructions.tech_stack_scoring.weight;
    totalScore += (scoredCompany.tech_stack_score?.score ?? 0) * scoringInstructions.tech_stack_scoring.weight;
  }
  if (scoringInstructions.employee_roles_scoring != null) {
    totalWeight += scoringInstructions.employee_roles_scoring.weight;
    totalScore += (scoredCompany.employee_roles_score?.score ?? 0) * scoringInstructions.employee_roles_scoring.weight;
  }
  if (scoringInstructions.hiring_activity_scoring != null) {
    totalWeight += scoringInstructions.hiring_activity_scoring.weight;
    totalScore += (scoredCompany.hiring_activity_score?.score ?? 0) * scoringInstructions.hiring_activity_scoring.weight;
  }
  let totalCustomWeight = 0;
  let totalCustomScore = 0;
  const scoringQuestions = scoringInstructions.scoring_questions;
  // TODO: pretty bad way we're indexing to find corresponding answer
  scoringQuestions.forEach((question) => {
    const answer = scoredCompany.scoring_results.find((result) => result.question === question.question);
    totalCustomScore += (answer?.score ?? 0) * question.weight;
    totalCustomWeight += question.weight;
  });
  return (totalScore + totalCustomScore) / (totalWeight + totalCustomWeight);
};

export const DisqualifiedCompanyViewer = ({
  companies,
  qualificationCriteria,
  viewOnly
}: {
  companies: FrontendQualifiedCompany[];
  qualificationCriteria: FrontendQualificationCriterion[];
  viewOnly?: boolean;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerCompany, setDrawerCompany] = useState<FrontendQualifiedCompany | undefined>(undefined);
  const handleCompanyClick = (domain: string) => {
    setIsDrawerOpen(true);
    const company = companies.find((c) => c.company.domain === domain);
    setDrawerCompany(company);
  };
  return (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
      <QualifiedCompanyTable
        companies={companies}
        qualificationCriteria={qualificationCriteria}
        selectedDomains={[]}
        onSelectedDomainsChange={() => {}}
        onCompanyClick={handleCompanyClick}
        onRowDelete={() => {}}
        disableRowSelection={true}
      />
      <DrawerContent direction="right" className="max-w-[500px] py-8">
        <ScrollArea orientation="vertical" className="w-full h-full px-8">
          <ExtensibleVerticalCompanyProfile company={drawerCompany?.company} shareableView={viewOnly}>
            <CompanyProfileQualificationBox
              answers={drawerCompany?.allocation_results ?? []}
              qualificationCriteria={qualificationCriteria}
            />
          </ExtensibleVerticalCompanyProfile>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
};

export const CompanySearchResultsViewer = React.memo(function CompanySearchResultsViewer({
  search,
  viewOnly
}: {
  search: FrontendFullCompanySearch;
  viewOnly?: boolean;
}) {
  const token = useAppSelector((state) => state.user.token);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);

  const searchStartingToRunHack = useAppSelector((state) => state.deprCompanySearches.searchesStartingToRunHack.includes(search.search_id));
  const isRunning = COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status) || searchStartingToRunHack;
  const rawScoredCompanies = search.results?.scored_companies;
  // Overwriting the overall score with any new weights
  const scoredCompanies = rawScoredCompanies?.map((company) => ({
    ...company,
    overall_score:
      search.instructions.scoring_instructions != null
        ? computeOverallScore(company, search.instructions.scoring_instructions)
        : company.overall_score
  }));
  const maxOverallScore = Math.max(...(scoredCompanies?.map((company) => company.overall_score ?? 0) ?? [0]));
  const minOverallScore = Math.min(...(scoredCompanies?.map((company) => company.overall_score ?? 0) ?? [0]));
  const qualifiedCompanies = search.results?.qualified_companies;
  const dispatch = useAppDispatch();
  let resultsMode: ResultsMode = ResultsMode.Qualified;
  if (scoredCompanies != null && scoredCompanies.length > 0) {
    resultsMode = ResultsMode.Scored;
  }
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [qualifiedDrawerCompany, setQualifiedDrawerCompany] = useState<FrontendQualifiedCompany | undefined>(undefined);
  const [scoredDrawerCompany, setScoredDrawerCompany] = useState<FrontendQualifiedAndScoredCompany | undefined>(undefined);
  const handleCompanyClick = (domain: string) => {
    setIsDrawerOpen(true);
    if (resultsMode === ResultsMode.Scored) {
      const company = scoredCompanies?.find((c) => c.company.domain === domain);
      setScoredDrawerCompany(company);
    } else {
      const company = qualifiedCompanies?.find((c) => c.company.domain === domain);
      setQualifiedDrawerCompany(company);
    }
  };
  useEffect(() => {
    setSelectedDomains([]);
  }, [search.search_id]);
  return (
    <div className="flex flex-col h-full w-full gap-4">
      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
        <div className="flex flex-col h-full w-full gap-4">
          {!isRunning && (
            <div className="flex flex-row items-center w-full gap-2 text-sm">
              {selectedDomains.length > 0 && (
                <div className="flex flex-row items-center gap-2 bg-colors-gray-modern-50 rounded-lg px-3 py-1.5 text-sm">
                  <div className="flex flex-row items-center gap-1">
                    <div className="text-colors-brand-600 font-medium">{selectedDomains.length}</div>
                    <div className="text-colors-gray-modern-600">selected</div>
                  </div>
                </div>
              )}
              <ExpandSearchButton search={search} disabled={true} />
              {resultsMode === ResultsMode.Scored && search.instructions.scoring_instructions != null && (
                <EditScoringWeightsButton
                  searchId={search.search_id}
                  scoringInstructions={search.instructions.scoring_instructions}
                  setScoringInstructions={(newWeights) => {
                    dispatch(deprUpdateCompanySearchScoringInstructions({ search_id: search.search_id, scoring_instructions: newWeights }));
                  }}
                  disablePersist={viewOnly}
                />
              )}
              <AddCompaniesToListDropdown
                domainsSelected={selectedDomains}
                companySearch={search}
                workflowTitle={search.title}
                disabled={viewOnly}
              />
              <FindContactsDropdown domainsSelected={selectedDomains} disabled={viewOnly} />
              <div className="flex-1" />
              <ExportListButton<string>
                selection={selectedDomains}
                disabled={viewOnly}
                formatsEnabled={[ExportFormat.Excel, ExportFormat.Csv]}
                exportCallback={async (selection, format) => {
                  if (token && search.results != null) {
                    toast.info("Your download is starting!");
                    posthog.capture("user_exported_company_search_results", { search_id: search.search_id, type: format });
                    return exportCompanySearchResults(token, search.results, format, selection);
                  }
                }}
              />
            </div>
          )}
          {scoredCompanies != null && scoredCompanies.length > 0 && search.instructions.scoring_instructions != null ? (
            <ScoredCompanyTable
              companies={scoredCompanies}
              scoringInstructions={search.instructions.scoring_instructions}
              qualificationCriteria={search.instructions.qualification_criteria ?? []}
              selectedDomains={selectedDomains}
              onSelectedDomainsChange={setSelectedDomains}
              onCompanyClick={handleCompanyClick}
            />
          ) : (
            <QualifiedCompanyTable
              companies={qualifiedCompanies ?? []}
              qualificationCriteria={search.instructions.qualification_criteria ?? []}
              selectedDomains={selectedDomains}
              onSelectedDomainsChange={setSelectedDomains}
              onCompanyClick={handleCompanyClick}
            />
          )}
        </div>
        <DrawerContent direction="right" className="max-w-[500px] py-4">
          <ScrollArea orientation="vertical" className="w-full h-full px-5">
            {resultsMode === ResultsMode.Qualified ? (
              <ExtensibleVerticalCompanyProfile company={qualifiedDrawerCompany?.company} shareableView={viewOnly}>
                {search.instructions.qualification_criteria != null && search.instructions.qualification_criteria.length > 0 && (
                  <CompanyProfileQualificationBox
                    answers={qualifiedDrawerCompany?.allocation_results ?? []}
                    qualificationCriteria={search.instructions.qualification_criteria ?? []}
                  />
                )}
              </ExtensibleVerticalCompanyProfile>
            ) : (
              <ExtensibleVerticalCompanyProfile company={scoredDrawerCompany?.company} shareableView={viewOnly}>
                {search.instructions.scoring_instructions != null && scoredDrawerCompany != null && (
                  <CompanyProfileScoringBox
                    scoredCompany={scoredDrawerCompany}
                    scoringInstructions={search.instructions.scoring_instructions}
                    maxOverallScore={maxOverallScore}
                    minOverallScore={minOverallScore}
                  />
                )}
                {search.instructions.qualification_criteria != null && search.instructions.qualification_criteria.length > 0 && (
                  <CompanyProfileQualificationBox
                    answers={scoredDrawerCompany?.allocation_results ?? []}
                    qualificationCriteria={search.instructions.qualification_criteria ?? []}
                  />
                )}
              </ExtensibleVerticalCompanyProfile>
            )}
          </ScrollArea>
        </DrawerContent>
      </Drawer>
    </div>
  );
});
