import { formatDollarValueMillionsBillions, formatPercentValue, hardMetricDisplayText } from "@/lib/utils";
import {
  EmployeeCountRangeOption,
  HardFilter,
  HardMetric,
  LastFundraiseRangeMonthsAgoOption,
  RevenueRangeOption
} from "@/services/autogen";
import { Slider } from "@mui/material";
import { Button } from "../ui/button";
import { Plus } from "lucide-react";
import { FilterHeader } from "./FilterHeader";
import { Checkbox } from "../ui/checkbox";

const EmployeeCountAllowedValues = [-1, ...Object.values(EmployeeCountRangeOption).map((x) => x.valueOf()), -2];
const FoundedYearAllowedValues = [-1, 1980, 1985, 1990, 1995, 2000, 2005, 2010, 2015, 2020, 2022, 2024, -2];
const LastFundraiseDateAllowedValues = [-1, ...Object.values(LastFundraiseRangeMonthsAgoOption).map((x) => x.valueOf()), -2];
const RevenueAllowedValues = [-1, ...Object.values(RevenueRangeOption).map((x) => x.valueOf()), -2];

const scaledToDisplay = (value: number | undefined, displayFormattingFn: (value: number) => string, allowedValues: number[]) => {
  if (value == undefined) {
    return "Any";
  } else if (value == -1) {
    return "Any";
  } else if (value == -2) {
    return "Any";
  } else if (value == -3) {
    return allowedValues[0];
  } else {
    return displayFormattingFn(value);
  }
};

const filterText = (filter: HardFilter, displayFormattingFn: (value: number) => string, allowedValues: number[]) => {
  if (filter.min == null && filter.max == null) {
    return "Any";
  } else if (filter.min == null) {
    return "Less than " + scaledToDisplay(filter.max, displayFormattingFn, allowedValues);
  } else if (filter.max == null) {
    return "Greater than " + scaledToDisplay(filter.min, displayFormattingFn, allowedValues);
  } else {
    return (
      scaledToDisplay(filter.min, displayFormattingFn, allowedValues) +
      " - " +
      scaledToDisplay(filter.max, displayFormattingFn, allowedValues)
    );
  }
};

export const DeprHardMetricFilterAddButton = ({
  metric,
  filter,
  setFilter
}: {
  metric: HardMetric;
  filter?: HardFilter;
  setFilter: (filter: HardFilter | undefined) => void;
}) => {
  if (filter == null) {
    return (
      <Button variant="outline" onClick={() => setFilter({ hard_metric: metric, nones_allowed: false })}>
        <Plus className="mr-2" size={16} /> {hardMetricDisplayText(metric)}
      </Button>
    );
  } else {
    return <></>;
  }
};

export const HardMetricFilterInput = ({
  metric,
  filter,
  setFilter,
  viewOnly,
  nonesAllowedOptionVisible,
  nonesAllowedCheckboxText
}: {
  metric: HardMetric;
  filter?: HardFilter;
  setFilter: (filter: HardFilter | undefined) => void;
  viewOnly?: boolean;
  nonesAllowedOptionVisible?: boolean;
  nonesAllowedCheckboxText?: string;
}) => {
  if (filter == null) {
    return (
      <FilterHeader
        title={hardMetricDisplayText(metric) ?? "Unknown"}
        onDelete={() => setFilter(undefined)}
        onAdd={() => setFilter({ hard_metric: metric, nones_allowed: false })}
        isActive={filter != null}
        viewOnly={viewOnly}
      />
    );
  } else {
    let allowedValues: number[] = [];
    let displayFormattingFn: (value: number) => string;
    if (metric == HardMetric.EmployeeCount) {
      allowedValues = EmployeeCountAllowedValues;
      displayFormattingFn = (value: number) => {
        return `${value}`;
      };
    } else if (metric == HardMetric.GrossMargin) {
      allowedValues = [-1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, -2];
      displayFormattingFn = formatPercentValue;
    } else if (metric === HardMetric.RevenueGrowth) {
      allowedValues = [-1, 0, 0.2, 0.5, 1, 2, 5, 10, -2];
      displayFormattingFn = formatPercentValue;
    } else if (metric === HardMetric.FoundedYear) {
      allowedValues = FoundedYearAllowedValues;
      displayFormattingFn = (value: number) => {
        return `${value}`;
      };
    } else if (metric === HardMetric.LastFundraiseDate) {
      allowedValues = LastFundraiseDateAllowedValues;
      displayFormattingFn = (value: number) => {
        return `${value} mo ago`;
      };
    } else if (metric === HardMetric.Revenue) {
      allowedValues = RevenueAllowedValues;
      displayFormattingFn = formatDollarValueMillionsBillions;
    } else {
      allowedValues = [
        -1, 0, 1000000, 5000000, 10000000, 50000000, 100000000, 500000000, 1000000000, 10000000000, 100000000000, 1000000000000,
        10000000000000, -2
      ];
      displayFormattingFn = formatDollarValueMillionsBillions;
    }
    // TODO: percent values...
    // TODO: handle negatives and no min/max some how

    const calculateValue = (value: number) => {
      return allowedValues[value];
    };
    const reverseCalculateValue = (value: number) => {
      return allowedValues.indexOf(value);
    };
    return (
      <div className="flex flex-col w-full relative">
        <FilterHeader
          title={hardMetricDisplayText(metric) + ": " + filterText(filter, displayFormattingFn, allowedValues)}
          onDelete={() => setFilter(undefined)}
          onAdd={() => setFilter({ hard_metric: metric, nones_allowed: false })}
          isActive={filter != null}
          viewOnly={viewOnly}
        />

        <div className="w-full px-2">
          <Slider
            value={
              filter.min == -3
                ? [0, reverseCalculateValue(filter.max ?? -2)]
                : [reverseCalculateValue(filter.min ?? -1), reverseCalculateValue(filter.max ?? -2)]
            }
            max={allowedValues.length - 1}
            scale={(value) => calculateValue(value)}
            onChange={(_, newValue) => {
              setFilter({
                hard_metric: metric,
                min:
                  filter.min == -3
                    ? -3
                    : calculateValue((newValue as number[])[0]) >= 0
                    ? calculateValue((newValue as number[])[0])
                    : undefined,
                max: calculateValue((newValue as number[])[1]) >= 0 ? calculateValue((newValue as number[])[1]) : undefined,
                nones_allowed: false
              });
            }}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => {
              return scaledToDisplay(value, displayFormattingFn, allowedValues);
            }}
            sx={{
              "& .MuiSlider-thumb": {
                color: "#F42352"
              },
              "& .MuiSlider-track": {
                color: "#F42352"
              },
              "& .MuiSlider-rail": {
                color: "#F42352"
              }
            }}
            disabled={viewOnly}
          />

          {nonesAllowedOptionVisible && (
            <div className="flex items-center space-x-2 mt-2">
              <Checkbox
                id={`nones-allowed-${metric}`}
                checked={filter.nones_allowed}
                onCheckedChange={(checked) => {
                  setFilter({
                    ...filter,
                    nones_allowed: checked === true
                  });
                }}
                disabled={viewOnly}
              />
              <label htmlFor={`nones-allowed-${metric}`} className="text-sm text-gray-700 cursor-pointer">
                {nonesAllowedCheckboxText ?? "Include companies with no data on this"}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
};
