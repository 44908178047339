import { CompanyLogo } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { CompLandscapeCompany, FrontendCompany, MarketMap } from "@/services/autogen";
import { removeBusinessFromOutputMarketMap } from "@/store/genericWorkflows";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setRightbarBusiness } from "@/store/sidebars";
import { X } from "lucide-react";

export const MarketMapSimpleViewer = ({ marketMap, businesses }: { marketMap: MarketMap; businesses: CompLandscapeCompany[] }) => {
  return (
    <div className="flex flex-row gap-5 flex-wrap">
      {marketMap.categories.map((category, categoryIndex) => (
        <div key={categoryIndex} className="p-5 pt-0 border-b-2 border-l-2 border-colors-brand-700 min-w-[300px] w-[45%]">
          <div className="text-lg font-semibold text-colors-brand-700">{category.name}</div>
          <div className="flex flex-col gap-6 mt-6">
            {category.subcategories.map((subcategory, subcategoryIndex) => (
              <div key={subcategoryIndex} className="p-5 pt-1 border-b border-l border-gray-400">
                <div className="">{subcategory.name}</div>
                <div className="flex flex-row gap-5 mt-8 flex-wrap">
                  {subcategory.businesses.map((business, businessIndex) => {
                    return (
                      <div key={businessIndex}>
                        <FrontendCompanyLogo
                          company={businesses.find((b) => b.company.domain === business)?.company}
                          categoryName={category.name}
                          subcategoryName={subcategory.name}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
            <div className="flex flex-row gap-5 mt-8 flex-wrap">
              {category.businesses.map((business, businessIndex) => {
                return (
                  <div key={businessIndex}>
                    <FrontendCompanyLogo
                      company={businesses.find((b) => b.company.domain === business)?.company}
                      categoryName={category.name}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const FrontendCompanyLogo = ({
  company,
  categoryName,
  subcategoryName
}: {
  company?: FrontendCompany;
  categoryName: string;
  subcategoryName?: string;
}) => {
  const activeWorkflowId = useAppSelector((state) => state.genericWorkflows.activeWorkflowId);
  const dispatch = useAppDispatch();
  if (company === undefined) {
    return null;
  }

  return (
    <SimpleTooltip text={company.name}>
      <div
        className="relative group cursor-pointer hover:opacity-50"
        onClick={() => {
          dispatch(setRightbarBusiness({ businessId: company.domain }));
        }}>
        {/* <img
          src={business.logo}
          alt={business.name}
          className="max-h-20 rounded-xl cursor-pointer hover:opacity-50"
          onClick={() => {
            dispatch(setRightbarBusiness({ businessId: business.id }));
          }}
        /> */}
        <CompanyLogo name={company.name} website={company.domain} logo={company.logo_url} />
        <Button
          className="invisible absolute top-0 right-0 group-hover:visible"
          variant="light_destructive"
          size="xs"
          onClick={() => {
            dispatch(
              removeBusinessFromOutputMarketMap({
                businessId: company.domain,
                categoryName,
                subCategoryName: subcategoryName,
                workflowId: activeWorkflowId ?? ""
              })
            );
          }}>
          <X />
        </Button>
      </div>
    </SimpleTooltip>
  );
};
