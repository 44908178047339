import { FrontendTaxonomy } from "@/services/autogen";
import { getScoredTaxonomy, getTaxonomy } from "@/services/brain-api.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export type TaxonomyState = {
  taxonomies: FrontendTaxonomy[];
  scoredTaxonomies: FrontendTaxonomy[];
  activeTaxonomyId: string | null;
  activeScoredTaxonomyId: string | null;
};

const initialState: TaxonomyState = {
  taxonomies: [],
  scoredTaxonomies: [],
  activeTaxonomyId: null,
  activeScoredTaxonomyId: null
};

export const fetchTaxonomyThunk = createAsyncThunk("fetchTaxonomy", async (thunkPayload: { taxonomy_id: string }, thunkAPI) => {
  const { taxonomy_id } = thunkPayload;
  console.log("Fetching taxonomy", taxonomy_id);
  getTaxonomy(taxonomy_id).then((response) => {
    console.log("Fetched taxonomy", taxonomy_id);
    const taxonomyData = response.data;
    thunkAPI.dispatch(taxonomySlice.actions.loadTaxonomy({ taxonomy: taxonomyData }));
  });
});

export const fetchScoredTaxonomyThunk = createAsyncThunk("fetchScoredTaxonomy", async (thunkPayload: { taxonomy_id: string }, thunkAPI) => {
  const { taxonomy_id } = thunkPayload;
  console.log("Fetching scored taxonomy", taxonomy_id);
  getScoredTaxonomy(taxonomy_id).then((response) => {
    console.log("Fetched scored taxonomy", taxonomy_id);
    const scoredTaxonomyData = response.data;
    thunkAPI.dispatch(taxonomySlice.actions.loadScoredTaxonomy({ taxonomy: scoredTaxonomyData }));
  });
});

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState,
  reducers: {
    loadTaxonomy: (state, action: PayloadAction<{ taxonomy: FrontendTaxonomy }>) => {
      const { taxonomy } = action.payload;
      const index = state.taxonomies.findIndex((t) => t.taxonomy_id === taxonomy.taxonomy_id);
      if (index !== -1) {
        state.taxonomies[index] = taxonomy;
      } else {
        state.taxonomies.push(taxonomy);
      }
    },
    loadScoredTaxonomy: (state, action: PayloadAction<{ taxonomy: FrontendTaxonomy }>) => {
      const { taxonomy } = action.payload;
      const index = state.scoredTaxonomies.findIndex((t) => t.taxonomy_id === taxonomy.taxonomy_id);
      if (index !== -1) {
        state.scoredTaxonomies[index] = taxonomy;
      } else {
        state.scoredTaxonomies.push(taxonomy);
      }
    },
    setActiveTaxonomyId: (state, action: PayloadAction<{ taxonomy_id: string }>) => {
      state.activeTaxonomyId = action.payload.taxonomy_id;
    },
    setActiveScoredTaxonomyId: (state, action: PayloadAction<{ taxonomy_id: string }>) => {
      state.activeScoredTaxonomyId = action.payload.taxonomy_id;
    }
  }
});

// Action creators are generated for each case reducer function
export const { loadScoredTaxonomy, loadTaxonomy, setActiveTaxonomyId, setActiveScoredTaxonomyId } = taxonomySlice.actions;

export default taxonomySlice.reducer;
