import { CompanyType } from "@/services/autogen";
import { Button } from "../ui/button";
import { Handshake, LucideBuilding2, Network, Plus, Store } from "lucide-react";
import { Acorn, City, Coins, TrendDown, TrendUp } from "@phosphor-icons/react";
import { FilterHeader } from "./FilterHeader";

export const DeprCompanyTypeFilterAddButton = ({
  filter,
  setFilter
}: {
  filter?: CompanyType[];
  setFilter: (filter: CompanyType[] | undefined) => void;
}) => {
  if (filter == null) {
    return (
      <Button
        variant="outline"
        onClick={() =>
          setFilter([
            CompanyType.SmallPrivate,
            CompanyType.EarlyStageStartup,
            CompanyType.GrowthStageStartup,
            CompanyType.EstablishedPrivate
          ])
        }>
        <Plus className="mr-2" size={16} /> Ownership
      </Button>
    );
  } else {
    return <></>;
  }
};

const CompanyTypeChipToggle = ({
  type,
  enabled,
  onClick,
  viewOnly
}: {
  type: CompanyType | "all_private";
  enabled: boolean;
  onClick: () => void;
  viewOnly?: boolean;
}) => {
  let icon = null;
  let styling = "";
  let text = "";
  switch (type) {
    case "SmallPrivate":
      icon = <Store size={14} />;
      text = "Small private";
      styling = "bg-colors-yellow-100 border-colors-yellow-300 text-colors-yellow-800";
      break;
    case "EarlyStageStartup":
      icon = <Acorn size={14} />;
      text = "Early stage startup";
      styling = "bg-colors-brand-100 border-colors-brand-300 text-colors-brand-800";
      break;
    case "GrowthStageStartup":
      icon = <TrendUp size={14} />;
      text = "Growth stage startup";
      styling = "bg-colors-success-100 border-colors-success-300 text-colors-success-800";
      break;
    case "EstablishedPrivate":
      icon = <LucideBuilding2 size={14} />;
      text = "Established private";
      styling = "bg-colors-blue-100 border-colors-blue-300 text-colors-blue-800";
      break;
    case "Public":
      icon = <City size={14} />;
      text = "Public";
      styling = "bg-colors-gray-modern-100 border-colors-gray-modern-300 text-colors-gray-modern-800";
      break;
    case "NotForProfit":
      icon = <Handshake size={14} />;
      text = "Not for profit";
      styling = "bg-colors-pink-100 border-colors-pink-300 text-colors-pink-800";
      break;
    case "all_private":
      icon = <TrendUp size={14} />;
      text = "Private";
      styling = "bg-colors-brand-100 border-colors-brand-300 text-colors-brand-800";
      break;
    case "Defunct":
      icon = <TrendDown size={14} />;
      text = "Defunct";
      styling = "bg-colors-error-100 border-colors-error-300 text-colors-error-800";
      break;
    case "Subsidiary":
      icon = <Network size={14} />;
      text = "Subsidiary";
      styling = "bg-colors-orange-100 border-colors-orange-300 text-colors-orange-800";
      break;
    case "InvestmentFund":
      icon = <Coins size={14} />;
      text = "Investment fund";
      styling = "bg-colors-gray-modern-100 border-colors-gray-modern-300 text-colors-gray-modern-800";
      break;
  }
  if (!enabled) {
    styling = "bg-white border-colors-gray-modern-200 text-colors-gray-modern-400";
    if (viewOnly) {
      return null;
    }
  }
  return (
    <div
      className={`${styling} rounded-full px-2 py-1 flex flex-row items-center gap-1 border ${
        viewOnly ? "cursor-default" : "cursor-pointer"
      } text-xs`}
      onClick={() => !viewOnly && onClick()}>
      {icon}
      {text}
    </div>
  );
};

export const CompanyTypeFilterInput = ({
  filter,
  setFilter,
  viewOnly
}: {
  filter?: CompanyType[];
  setFilter: (filter: CompanyType[] | undefined) => void;
  viewOnly?: boolean;
}) => {
  const addorRemoveTypeToFilter = (types: CompanyType[]) => {
    if (filter == null) {
      setFilter(types);
    } else {
      let newFilter = [...filter];
      for (const type of types) {
        if (filter.includes(type)) {
          newFilter = newFilter.filter((t) => t !== type);
        } else {
          newFilter.push(type);
        }
      }
      if (newFilter.length === 0) {
        setFilter(undefined);
      } else {
        setFilter(newFilter);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <FilterHeader
        title="Company types"
        onDelete={() => setFilter(undefined)}
        onAdd={() => setFilter([])}
        isActive={filter != null}
        viewOnly={viewOnly}
      />
      {filter && (
        <div className="flex flex-row w-full flex-wrap gap-2 mb-2">
          <CompanyTypeChipToggle
            type={"all_private"}
            enabled={filter.includes(CompanyType.SmallPrivate)}
            onClick={() => {
              addorRemoveTypeToFilter([
                CompanyType.SmallPrivate,
                CompanyType.EarlyStageStartup,
                CompanyType.GrowthStageStartup,
                CompanyType.EstablishedPrivate
              ]);
            }}
            viewOnly={viewOnly}
          />
          <CompanyTypeChipToggle
            type={CompanyType.Public}
            enabled={filter.includes(CompanyType.Public)}
            onClick={() => {
              addorRemoveTypeToFilter([CompanyType.Public]);
            }}
            viewOnly={viewOnly}
          />
          <CompanyTypeChipToggle
            type={CompanyType.Subsidiary}
            enabled={filter.includes(CompanyType.Subsidiary)}
            onClick={() => {
              addorRemoveTypeToFilter([CompanyType.Subsidiary]);
            }}
            viewOnly={viewOnly}
          />
          <CompanyTypeChipToggle
            type={CompanyType.InvestmentFund}
            enabled={filter.includes(CompanyType.InvestmentFund)}
            onClick={() => {
              addorRemoveTypeToFilter([CompanyType.InvestmentFund]);
            }}
            viewOnly={viewOnly}
          />
          <CompanyTypeChipToggle
            type={CompanyType.NotForProfit}
            enabled={filter.includes(CompanyType.NotForProfit)}
            onClick={() => {
              addorRemoveTypeToFilter([CompanyType.NotForProfit]);
            }}
            viewOnly={viewOnly}
          />
          <CompanyTypeChipToggle
            type={CompanyType.Defunct}
            enabled={filter.includes(CompanyType.Defunct)}
            onClick={() => {
              addorRemoveTypeToFilter([CompanyType.Defunct]);
            }}
            viewOnly={viewOnly}
          />
        </div>
      )}
    </div>
  );
};
