/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button } from "../ui/button";
import { FrontendCompany, TextWithSources } from "@/services/autogen";
import { ExternalLink, Users, Globe2, Boxes, X, Cpu } from "lucide-react";
import { CompanyLogo } from "../ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl } from "@/lib/utils";
import { InlineTextAndSources, SourceCitations } from "@/lib/utilComponents";

function SummaryBox({ business }: { business: FrontendCompany }) {
  if (
    business.summary.main_offerings == null &&
    business.summary.customer_segments == null &&
    business.summary.geographies == null &&
    business.summary.business_model == null &&
    business.summary.technologies == null
  ) {
    return <div />;
  }
  return (
    <div className="flex flex-col space-y-6">
      {business.summary.business_model && (
        <InlineTextAndSources
          title={
            <div className="flex flex-row">
              <Globe2 size={20} className="text-colors-foreground-fg-tertiary-(600)" />
              <div className="text-colors-foreground-fg-secondary-(700) ml-3">Business model</div>
            </div>
          }
          text={business.summary.business_model.text}
          sources={<SourceCitations objectsWithSources={[business.summary.business_model]} />}
        />
      )}
      {business.summary.main_offerings && (
        <InlineTextAndSources
          title={
            <div className="flex flex-row">
              <Boxes size={20} className="text-colors-foreground-fg-tertiary-(600)" />
              <div className="text-colors-foreground-fg-secondary-(700) ml-3">Offerings</div>
            </div>
          }
          text={business.summary.main_offerings.text}
          sources={<SourceCitations objectsWithSources={[business.summary.main_offerings]} />}
        />
      )}
      {business.summary.customer_segments && (
        <InlineTextAndSources
          title={
            <div className="flex flex-row">
              <Users size={20} className="text-colors-foreground-fg-tertiary-(600)" />
              <div className="text-colors-foreground-fg-secondary-(700) ml-3">Customers</div>
            </div>
          }
          text={business.summary.customer_segments.text}
          sources={<SourceCitations objectsWithSources={[business.summary.customer_segments]} />}
        />
      )}
      {business.summary.geographies && (
        <InlineTextAndSources
          title={
            <div className="flex flex-row">
              <Globe2 size={20} className="text-colors-foreground-fg-tertiary-(600)" />
              <div className="text-colors-foreground-fg-secondary-(700) ml-3">Geographies</div>
            </div>
          }
          text={business.summary.geographies.text}
          sources={<SourceCitations objectsWithSources={[business.summary.geographies]} />}
        />
      )}
      {business.summary.technologies && (
        <InlineTextAndSources
          title={
            <div className="flex flex-row">
              <Cpu size={20} className="text-colors-foreground-fg-tertiary-(600)" />
              <div className="text-colors-foreground-fg-secondary-(700) ml-3">Technologies</div>
            </div>
          }
          text={business.summary.technologies.text}
          sources={<SourceCitations objectsWithSources={[business.summary.technologies]} />}
        />
      )}
    </div>
  );
}

function ProviderDataBox({ business }: { business: FrontendCompany }) {
  return (
    <div className="flex flex-col space-y-6">
      {/* {business.employee_min ||
        business.employee_max ||
        (business.employee_guess && )} */}
      <InlineTextAndSources
        title="Employees"
        text={employeeRangeText(business.employee_min, business.employee_max, business.employee_guess)}
        sources={null}
      />
      {business.year_founded && <InlineTextAndSources title="Founded" text={business.year_founded.toString()} sources={null} />}
      {business.hq_str && <InlineTextAndSources title="Headquarters" text={business.hq_str} sources={null} />}
      {/* {business.funds_raised && (
        <InlineTextAndSources
          title="Funds Raised"
          text={business.funds_raised.text}
          sources={<ProviderSourceCitations objectsWithSources={[business.funds_raised]} />}
        />
      )} */}
      {business.fundraises && business.fundraises.length > 0 && (
        <InlineTextAndSources
          title="Fundraising"
          text={
            business.fundraises
              ?.map((fundraise) => {
                let details = [];
                if (fundraise.round_name) details.push(`${fundraise.round_name} round`);
                if (fundraise.amount)
                  details.push(`${(fundraise.amount / 1_000_000).toFixed(1)}M ${fundraise.currency ? " " + fundraise.currency : ""}`);
                if (fundraise.date) details.push(fundraise.date);
                if (fundraise.investors && fundraise.investors.length > 0) details.push(fundraise.investors.join(", "));
                return details.join(", ");
              })
              .join(" | ") || ""
          }
          sources={null}
        />
      )}
    </div>
  );
}

function BespokeResearchBox({ business }: { business: FrontendCompany }) {
  const researchItems: [string, TextWithSources][] = [];
  // Iterate over each key in the research object
  for (const key in business.custom_research) {
    if (business.custom_research.hasOwnProperty(key)) {
      const researchItem = business.custom_research[key];
      researchItems.push([key, researchItem]);
    }
  }
  if (researchItems.length === 0) {
    return <div />;
  }

  return (
    <div className="flex flex-col space-y-6">
      {researchItems.map(([topic, researchItem], idx) => (
        <InlineTextAndSources
          title={topic}
          text={researchItem.text}
          sources={SourceCitations({ objectsWithSources: [researchItem], maxSources: 10 })}
          key={idx}
        />
      ))}
    </div>
  );
}

export const CompanyHeader = ({ business }: { business: FrontendCompany }) => {
  return (
    <div className="flex flex-row w-full">
      <CompanyLogo name={business.name} website={business.domain} logo={business.logo_url} className="mr-2" />
      <div className="flex flex-col">
        <div className="text-3xl text-colors-brand-900 font-bold">{business.name}</div>
        <div className="flex flex-row items-center gap-4 mt-1">
          {business.stock_ticker && <div className="text-colors-text-text-secondary-(700) font-bold">{business.stock_ticker}</div>}
          {business.domain && (
            <a
              href={ensureAbsoluteUrl(business.domain)}
              className="flex flex-row hover:cursor-pointer underline text-blue-800"
              target="_blank"
              rel="noopener noreferrer">
              <ExternalLink size={20} />
            </a>
          )}
          {business.linkedin_aid && (
            <a
              href={ensureAbsoluteUrl("linkedin.com/company/" + business.linkedin_aid)}
              className="self-center"
              target="_blank"
              rel="noopener noreferrer">
              <img src="/linkedin.png" className="h-6 w-6" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export const CompanyProfile = ({
  company,
  onClose,
  children
}: {
  company?: FrontendCompany;
  onClose: () => void;
  children?: React.ReactNode;
}) => {
  if (company === undefined) {
    return (
      <div className="text-lg self-center text-colors-brand-900 animate__animated animate__pulse animate__infinite animate__delay-5s">
        Click on a company to see more details!
      </div>
    );
  }
  const { name, domain, summary, logo_url } = company;

  return (
    <div className="flex flex-col bg-transparent px-2 relative">
      <div className="flex flex-row mb-2 items-start justify-between">
        <div className="flex flex-row w-4/5">
          <CompanyLogo name={name} website={domain} logo={logo_url} className="mr-2" />
          <div className="flex flex-col">
            <div className="text-3xl text-colors-brand-900 font-bold">{name}</div>
            <div className="flex flex-row items-center gap-4 mt-1">
              {company.stock_ticker && <div className="text-colors-text-text-secondary-(700) font-bold">{company.stock_ticker}</div>}
              {domain && (
                <a
                  href={ensureAbsoluteUrl(domain)}
                  className="flex flex-row hover:cursor-pointer underline text-blue-800"
                  target="_blank"
                  rel="noopener noreferrer">
                  <ExternalLink size={20} />
                </a>
              )}
              {company.linkedin_aid && (
                <a
                  href={ensureAbsoluteUrl("linkedin.com/company/" + company.linkedin_aid)}
                  className="self-center"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="/linkedin.png" className="h-6 w-6" />
                </a>
              )}
            </div>
          </div>
        </div>
        <Button
          variant="destructive"
          onClick={() => {
            // dispatch(setRightbarBusiness({ businessId: undefined }));
            onClose();
          }}>
          <X size={20} />
        </Button>
      </div>
      <div className="flex flex-row w-full space-x-5 mb-5">
        <div className="space-y-6">
          {summary && (
            <div>
              <InlineTextAndSources
                title=""
                text={summary.overview.text}
                sources={<SourceCitations objectsWithSources={[summary.overview]} />}></InlineTextAndSources>
            </div>
          )}
          <SummaryBox business={company} />
          <ProviderDataBox business={company} />
          <BespokeResearchBox business={company} />
          {children}
        </div>
      </div>
    </div>
  );
};
