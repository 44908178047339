import { UserButton } from "@clerk/clerk-react";

import { SignInButton } from "@clerk/clerk-react";

import { useAuth } from "@clerk/clerk-react";

import { useAppSelector } from "@/store/hooks";
import { useUser } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";
import { createCustomerPortalSession, getNangoConnectSessionToken } from "@/services/brain-api.service";
import { UpgradeSubscriptionDialogContent } from "../dashboard/UpgradeSubscription";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import posthog from "posthog-js";
import { MeticulateSubscriptionType } from "@/services/autogen";
import { Info, ReceiptText, Unplug } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { useState } from "react";
import { toast } from "sonner";
import Nango from "@nangohq/frontend";

const creditsRemainingText = (creditsAvailable?: number) => {
  if (creditsAvailable == null) {
    return "Unlimited";
  }
  return `${creditsAvailable}`;
};

export const FooterContent = () => {
  const { getToken } = useAuth();
  const { user } = useUser();
  const runsAvailable = useAppSelector((state) => state.user.runsAvailable);
  const subscriptionType = useAppSelector((state) => state.user.subscriptionType);
  const contactCreditsAvailable = useAppSelector((state) => state.user.contactCreditsAvailable);
  const companyCreditsAvailable = useAppSelector((state) => state.user.companyCreditsAvailable);

  const [dialogOpen, setDialogOpen] = useState(false);
  const paidSubscription =
    subscriptionType === MeticulateSubscriptionType.Pro ||
    subscriptionType === MeticulateSubscriptionType.Basic ||
    subscriptionType === MeticulateSubscriptionType.Unlimited;

  // TODO: why doesn't this work?
  const onManageBillingClick = async () => {
    if (paidSubscription) {
      toast.info("Redirecting to billing page...");
      const token = await getToken();
      if (token !== null) {
        const checkoutUrl = await createCustomerPortalSession(token);
        window.location.href = checkoutUrl.data;
      }
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <div className="mt-2 flex flex-row items-center justify-self-end pb-2">
      {user == null ? (
        <Button className="w-full">
          <SignInButton />
        </Button>
      ) : (
        <>
          {/* Adding key here to force re-mount on subscription type change */}
          <UserButton key={subscriptionType} afterSwitchSessionUrl="/">
            <UserButton.MenuItems>
              <UserButton.Action label="Manage billing" labelIcon={<ReceiptText size={16} />} onClick={onManageBillingClick} />
              <UserButton.Action
                label="Manage integrations"
                labelIcon={<Unplug size={16} />}
                onClick={async () => {
                  const nango = new Nango();
                  const connect = nango.openConnectUI({
                    onEvent: (event) => {
                      if (event.type === "close") {
                        // Handle modal closed
                      } else if (event.type === "connect") {
                        // Handle auth flow successful
                      }
                    }
                  });
                  const token = await getToken();
                  if (token === null) {
                    return;
                  }
                  try {
                    const res = await getNangoConnectSessionToken(token);
                    if (res.status !== 200) {
                      connect.close();
                      toast.error("Contact us to get integrations");
                      return;
                    }
                    const data = res.data;
                    connect.setSessionToken(data);
                  } catch {
                    connect.close();
                    toast.error("Contact us to get integrations");
                  }
                }}
              />
            </UserButton.MenuItems>
          </UserButton>
          <div className="items-center flex flex-col text-sm text-colors-text-text-secondary-(700) ml-3 overflow-hidden gap-1 w-full">
            <div className="flex flex-col gap-1">
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-row gap-1 font-semibold items-center text-colors-text-text-tertiary-(600)">
                    Credits remaining <Info size={16} />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="bg-white">
                  <div className="flex flex-col gap-2 bg-white text-sm">
                    <div className="flex flex-row gap-10 justify-between">
                      <div className="text-colors-text-text-tertiary-(600)">Searches</div>
                      <div className="font-semibold text-colors-text-text-primary-(900)">{creditsRemainingText(runsAvailable)}</div>
                    </div>
                    <div className="flex flex-row gap-10 justify-between">
                      <div className="text-colors-text-text-tertiary-(600)">Companies</div>
                      <div className="font-semibold text-colors-text-text-primary-(900)">
                        {creditsRemainingText(companyCreditsAvailable)}
                      </div>
                    </div>
                    <div className="flex flex-row gap-10 justify-between">
                      <div className="text-colors-text-text-tertiary-(600)">Contacts</div>
                      <div className="font-semibold text-colors-text-text-primary-(900)">
                        {creditsRemainingText(contactCreditsAvailable)}
                      </div>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </div>
            {subscriptionType === MeticulateSubscriptionType.Free && (
              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogTrigger asChild>
                  <Button
                    size="sm"
                    className="w-full"
                    onClick={async () => posthog.capture("user_clicked_get_more_reports", { variant: "sidebar" })}>
                    Upgrade
                  </Button>
                </DialogTrigger>
                <UpgradeSubscriptionDialogContent />
              </Dialog>
            )}
            {/* 2024-11-22 CLERK manage billing should work now - leaving this here for quick rollback if needed  */}
            {/* {paidSubscription && (
              <Button
                size="sm"
                className="w-full"
                onClick={async () => {
                  posthog.capture("user_clicked_manage_billing");
                  const token = await getToken();
                  toast.info("Redirecting to billing page...");
                  if (token !== null) {
                    const checkoutUrl = await createCustomerPortalSession(token);
                    window.location.href = checkoutUrl.data;
                  }
                }}>
                Manage billing
              </Button>
            )} */}
          </div>
        </>
      )}
    </div>
  );
};
