import { FrontendTaxonomyNode, ScoringTopicResult } from "@/services/autogen";
import { useScoredTaxonomy } from "./ScoredTaxonomyContext";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";
import { Button } from "@mui/material";
import { ChevronsDownUp, ChevronsUpDown, Eye, EyeOff } from "lucide-react";

export const calculateWeightedScore = (scoringResults: ScoringTopicResult[], weights: Record<string, number>) => {
  if (!scoringResults || scoringResults.length === 0) {
    return 0;
  }

  let weightedSum = 0;
  let totalWeight = 0;

  scoringResults.forEach((result) => {
    if (result.topic && result.topic in weights) {
      const weight = weights[result.topic];
      weightedSum += result.score * weight;
      totalWeight += weight;
    } else {
      console.warn(`Beta weight for ${result.topic} not found`);
    }
  });

  // Normalize the score by dividing by the total weight
  return totalWeight > 0 ? weightedSum / totalWeight : 0;
};

export const ScoredTaxonomyNodeDisplay = ({ node, excludedUpstream }: { node: FrontendTaxonomyNode; excludedUpstream: boolean }) => {
  const { minimizedCategoryIds, excludedCategoryIds, setMinimized, setExcluded, betaWeights } = useScoredTaxonomy();
  const isMinimized = minimizedCategoryIds.includes(node.node_id);
  const isExcluded = excludedCategoryIds.includes(node.node_id);
  const hasSubnodes = node.subnodes.length > 0;
  // TODO: hack
  const hasScore = !hasSubnodes;
  const isGrayedOut = isExcluded || excludedUpstream;

  const calculatedOverallScore = node.category_scoring?.topic_results
    ? calculateWeightedScore(node.category_scoring.topic_results, betaWeights)
    : 0;

  return (
    <div className={`flex flex-col ${isGrayedOut ? "opacity-50" : ""}`}>
      <div className="flex items-center group">
        <div className="mr-2">{node.node_title}</div>
        {hasScore && <ScoreChip numberOfDecimals={1} score={calculatedOverallScore} maxScore={10} />}
        <div className="flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
          {hasSubnodes && (
            <Button size="small" onClick={() => setMinimized(node.node_id, !isMinimized)}>
              {isMinimized ? <ChevronsUpDown /> : <ChevronsDownUp />}
            </Button>
          )}
          <Button onClick={() => setExcluded(node.node_id, !isExcluded)}>{isExcluded ? <EyeOff /> : <Eye />}</Button>
        </div>
      </div>

      {!isMinimized && node.subnodes.length > 0 && (
        <div className="ml-4 mt-2">
          {node.subnodes
            .filter((subnode) => subnode.number_of_companies > 0)
            .map((subnode, index, filteredSubnodes) => (
              <div key={subnode.node_id} className="relative">
                {index !== filteredSubnodes.length - 1 && <div className="absolute top-1 left-0 w-4 h-full border-l-2 border-black"></div>}
                <div className="relative pl-4 pt-2">
                  <div className={`absolute top-1 left-0 w-4 h-6 border-b-2 border-l-2 ${"border-black"}`}></div>
                  <ScoredTaxonomyNodeDisplay node={subnode} excludedUpstream={excludedUpstream || isExcluded} />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export const ScoredTaxonomyTreeViewer = ({ rootNode }: { rootNode: FrontendTaxonomyNode }) => {
  // const tableRows: TaxonomyEntryRow[] = [];
  return (
    <div className="flex flex-col w-full h-full">
      <ScoredTaxonomyNodeDisplay node={rootNode} excludedUpstream={false} />
    </div>
  );
};
