import { Trash2 } from "lucide-react";
import { Button } from "../ui/button";
import { SimpleTooltip } from "../ui/tooltip";
import { CompanyLogo } from "../ui/avatar";

export const ExemplarChip = ({ domain, onDelete }: { domain: string; onDelete: () => void }) => {
  return (
    <SimpleTooltip text={domain}>
      <div className="relative group/exemplar">
        <Button
          onClick={onDelete}
          className="absolute top-0 right-0 z-50 invisible group-hover/exemplar:visible"
          size="xs"
          variant="destructive">
          <Trash2 />
        </Button>
        <CompanyLogo name={domain} website={domain} className="mr-2" />
      </div>
    </SimpleTooltip>
  );
};
