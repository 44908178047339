/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { Notepad } from "@phosphor-icons/react";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "../ui/button";
import { MoveDown, MoveUp, Plus } from "lucide-react";
import { Rubric, ScoredDealcloudCompany } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import React, { useRef } from "react";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";
import { SimpleTooltip } from "../ui/tooltip";

export interface ScoredCompanyRow extends ScoredDealcloudCompany {
  tableRank: number;
  overallScore: number;
  overallScoreDiff: number;
}

const SortingDirectionIndicator = ({ column }: { column: Column<ScoredCompanyRow> }) => {
  if (column.getIsSorted() === "asc") {
    return <MoveDown size={14} />;
  } else if (column.getIsSorted() === "desc") {
    return <MoveUp size={14} />;
  } else {
    return null;
  }
};

const scoringCriterionSorting = (rowA: Row<ScoredCompanyRow>, rowB: Row<ScoredCompanyRow>, scoringQuestion: string): number => {
  const a = rowA.original.scored_dimensions.find((dimension) => dimension.type === scoringQuestion)?.score;
  const b = rowB.original.scored_dimensions.find((dimension) => dimension.type === scoringQuestion)?.score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

const overallScoreSorting = (rowA: Row<ScoredCompanyRow>, rowB: Row<ScoredCompanyRow>): number => {
  const a = rowA.original.overallScore;
  const b = rowB.original.overallScore;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

export const ScoredCompaniesListTable = React.memo(
  ({ rubric, scoredCompanies }: { rubric: Rubric; scoredCompanies: ScoredDealcloudCompany[] }) => {
    const tableWindowRef = useRef<HTMLDivElement>(null);
    // TODO: type this properly
    const tableRows: ScoredCompanyRow[] = [];
    scoredCompanies.forEach((scoredCompany, index) => {
      const totalWeight = rubric.dimensions.reduce((sum, dimension) => sum + (dimension.weight || 1), 0);
      const overallScore = scoredCompany.scored_dimensions.reduce((acc, dimension) => {
        const weight = rubric.dimensions.find((d) => d.type === dimension.type)?.weight || 1;
        return acc + (dimension.score * weight) / totalWeight;
      }, 0);
      const overallScoreDiff = Math.random() * 1.5 - 0.5;
      tableRows.push({ ...scoredCompany, tableRank: index, overallScore, overallScoreDiff });
    });

    const basicColumns: ColumnDef<ScoredCompanyRow>[] = [
      {
        accessorKey: "tableRank",
        // header: () => "#",
        header: (header) => (
          <div className="flex flex-row hover:cursor-pointer px-2 w-12" onClick={header.column.getToggleSortingHandler()}>
            #
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        size: 15,
        cell: ({ row }) => {
          return (
            <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center">
              <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.tableRank + 1}</span>
            </div>
          );
        },
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "name",
        // size: 100,
        header: ({}) => {
          return (
            // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
            // <CaretUpDown className="ml-2 h-4 w-4" />
            // </Button>
          );
        },
        cell: ({ row }) => <div className="flex flex-col items-start py-2 pl-2 z-40">{row.original.company_domain}</div>,
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "added_by",
        // size: 25,
        size: 90,
        header: () => (
          <div>
            <span>Owner</span>
          </div>
        ),
        cell: ({ row }) => (
          <span className="bg-colors-blue-100 rounded-lg px-2 py-1 text-colors-blue-dark-900 flex-row items-center gap-1 text-xs font-semibold w-auto">
            {row.original.individual}
          </span>
        ),
        meta: {
          group: "basic_info",
          displayName: "Owner"
        }
        // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
        // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
      },
      {
        accessorKey: "pod",
        // size: 25,
        size: 90,
        header: () => (
          <div>
            <span>Pod</span>
          </div>
        ),
        cell: ({ row }) => <span>{row.original.pod}</span>,
        meta: {
          group: "basic_info",
          displayName: "Pod"
        }
        // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
        // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
      }
      // {
      //   accessorKey: "evaluation_score",
      //   header: () => "Auto score",
      //   size: 80,
      //   cell: ({ row }) => (
      //     <div className="bg-colors-gray-modern-4000 rounded-sm text-center self-center px-1">
      //       <ScoreChip score={row.original.evaluation_score} numberOfDecimals={1} />
      //     </div>
      //   ),
      //   meta: {
      //     group: "basic_info",
      //     displayName: "Eval score"
      //   }
      // }
    ];
    const newColumns = [...basicColumns];

    const getAllColumns = () => {
      if (rubric && rubric.dimensions.length > 0) {
        newColumns.push({
          accessorKey: "overallScore",
          header: ({ column }) => (
            <div className="flex flex-row items-center" onClick={column.getToggleSortingHandler()}>
              <span>Overall score</span>
              <SortingDirectionIndicator column={column} />
            </div>
          ),
          size: 100,
          sortingFn: (rowA, rowB, _): number => {
            return overallScoreSorting(rowA, rowB);
          },
          cell: ({ row }) => {
            return (
              <div className="flex flex-row items-center">
                <ScoreChip score={row.original.overallScore} numberOfDecimals={1} maxScore={5} />
                {row.original.overallScoreDiff !== undefined && (
                  <span className={row.original.overallScoreDiff >= 0 ? " ml-2 text-green-500" : "ml-2 text-red-500"}>
                    {row.original.overallScoreDiff >= 0 ? "▲" : "▼"} {Math.abs(row.original.overallScoreDiff).toFixed(1)}
                  </span>
                )}
              </div>
            );
          }
        });
        rubric.dimensions.forEach((dimension) => {
          newColumns.push({
            accessorKey: `dimension_${dimension.type}`,
            header: ({ column }) => (
              <div className="flex flex-row items-center" onClick={column.getToggleSortingHandler()}>
                <span>{dimension.type.charAt(0).toUpperCase() + dimension.type.slice(1).toLowerCase().replace(/_/g, " ")}</span>
                <SortingDirectionIndicator column={column} />
              </div>
            ),
            size: 100,
            cell: ({ row }) => {
              const scoredDimension = row.original.scored_dimensions.find((sd) => sd.type === dimension.type);
              return (
                <div className="bg-colors-gray-modern-4000 rounded-sm text-center self-center px-1">
                  <SimpleTooltip text={scoredDimension?.explanation || ""}>
                    <ScoreChip score={scoredDimension?.score} numberOfDecimals={0} maxScore={5} />
                  </SimpleTooltip>
                </div>
              );
            },
            sortingFn: (rowA, rowB, _): number => {
              return scoringCriterionSorting(rowA, rowB, dimension.type);
            },
            meta: {
              group: "rubric_dimensions",
              displayName: dimension.type
            }
          });
        });
      }

      newColumns.push({
        accessorKey: "trash",
        size: 50,
        header: ({ table }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                  <Plus size={20} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
                <span className="flex items-center justify-start my-1 p-1">
                  <Notepad className="mr-1" size={22} />
                  <h1 className="font-semi">Basic info</h1>
                </span>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                  .map((column: any) => {
                    // TODO: stop using any
                    return (
                      <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                        <Checkbox
                          key={column.id}
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) => {
                            column.toggleVisibility(!!value);

                            // sleep for 1 sec
                            setTimeout(() => {
                              console.log("trying to scroll");
                              // scroll to the right
                              tableWindowRef.current?.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                            }, 100);
                          }}>
                          {column.id}
                        </Checkbox>

                        <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                      </div>
                    );
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
        enableHiding: false,
        cell: ({}) => {
          return <div className="min-w-[22px] min-h-[39px]"></div>;
        }
      });
    };

    getAllColumns();

    return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} isRunning={false} />;
  }
);
