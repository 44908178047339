import { useMemo, useRef } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { PersonRow } from "./common/PersonRow";
import { FrontendPerson } from "@/services/autogen";
import { basicColumns, crmStatusColumn, finalColumn } from "./common/Columns";
import { AbstractPersonTable } from "./common/AbstractPersonTable";

export function SimplePersonTable({
  people,
  selectedPeople,
  onSelectedPeopleChange,
  onPersonClick,
  onRowDelete,
  enableCrmStatus
}: {
  people: FrontendPerson[];
  selectedPeople: number[];
  onSelectedPeopleChange: (selectedPeople: number[]) => void;
  onPersonClick: (email: string) => void;
  onRowDelete?: (email: string) => void;
  enableCrmStatus?: boolean;
}) {
  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useMemo<ColumnDef<PersonRow>[]>(() => {
    const cols = [...basicColumns];
    if (enableCrmStatus) {
      cols.push(crmStatusColumn());
    }
    return [...cols, finalColumn(tableRef, onRowDelete)];
  }, [enableCrmStatus]);

  const onRowClick = (row: PersonRow) => {
    onPersonClick(row.email ?? "");
  };

  const rows = people.map((person, index) => ({ ...person, tableRank: index }));
  return (
    <AbstractPersonTable
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      tableRef={tableRef}
      selectedPeople={selectedPeople}
      onSelectedPeopleChange={onSelectedPeopleChange}
    />
  );
}
