/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./store/store.ts";
import { Provider } from "react-redux";
import posthog from "posthog-js";
import { MeticulateRouter } from "./pages/router.tsx";
import { ClerkProvider } from "@clerk/clerk-react";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";

const environment = import.meta.env.VITE_APP_ENVIRONMENT;

// TODO: disable posthog in dev
posthog.init("phc_2waOurt3IjUnSPLKvdcqxymzSKe1nIfs2swIjpivb6Y", {
  api_host: "https://ph.meticulate.ai",
  ui_host: "https://app.posthog.com",
  session_recording: {
    maskAllInputs: false
  }
});

Sentry.init({
  dsn: "https://1a36ba4ae29b65e5ce3b008439fc0e77@o4506907337424896.ingest.us.sentry.io/4507710255529984",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  environment: environment,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/brain\.meticulate\.ai\/brain/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error("Missing Publishable Key");
}

function ClerkRoutedProvider({ children }: { children: React.ReactNode }) {
  // const navigate = useNavigate();
  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      // navigate={(to) => navigate(to)}
      appearance={{
        elements: {
          backgroundColor: "#0F1729",
          userButtonAvatarBox: {
            "&:hover": {
              boxShadow: "0px 0px 5px #fff"
            }
          }
        }
      }}>
      {children}
    </ClerkProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ClerkRoutedProvider>
        <RouterProvider router={MeticulateRouter}></RouterProvider>
      </ClerkRoutedProvider>
    </Provider>
  </React.StrictMode>
);
