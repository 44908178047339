/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextWithSourcesAndHeadline } from "@/services/autogen";
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export type CategoryDetails = {
  categoryName: string;
  subcategoryName?: string;
  categoryDescription?: string;
  subcategoryDescription?: string;
};

export type CustomResearchAnswer = {
  businessName: string;
  topic: string;
  answer: TextWithSourcesAndHeadline;
};

export type rightbarContent = {
  businessId?: string;
  categoryDetails?: CategoryDetails;
  customResearchAnswer?: CustomResearchAnswer;
  companyDomain?: string;
};

export type SidebarsState = {
  rightBarOpen: boolean;
  leftBarOpen: boolean;
  rightbarContent: rightbarContent;
};

const initialState: SidebarsState = {
  rightBarOpen: true,
  leftBarOpen: true,
  rightbarContent: {}
};

// TODO: clean up the unused functions
export const sidebarSlice = createSlice({
  name: "sidebars",
  initialState,
  reducers: {
    getSidebars: (state) => {
      return state;
    },
    updateRightbar: (state, action: PayloadAction<{ setIsOpen: boolean }>) => {
      const { setIsOpen } = action.payload;
      state.rightBarOpen = setIsOpen;
    },
    updateLeftbar: (state, action: PayloadAction<{ setIsOpen: boolean }>) => {
      const { setIsOpen } = action.payload;
      state.leftBarOpen = setIsOpen;
    },
    setRightbarBusiness: (state, action: PayloadAction<{ businessId?: string; showOnlyBusiness?: boolean }>) => {
      const { showOnlyBusiness, businessId } = action.payload;
      if (showOnlyBusiness) {
        state.rightbarContent = { businessId, categoryDetails: undefined, customResearchAnswer: undefined };
      } else {
        state.rightbarContent = { ...state.rightbarContent, businessId, categoryDetails: undefined };
      }
      state.rightBarOpen = true;
    },
    setRightbarCategoryDetails: (state, action: PayloadAction<{ categoryDetails: CategoryDetails }>) => {
      const { categoryDetails } = action.payload;
      state.rightbarContent = { ...state.rightbarContent, categoryDetails, businessId: undefined, companyDomain: undefined };
      state.rightBarOpen = true;
    },
    setRightbarCustomResearchAnswer: (state, action: PayloadAction<{ customResearchAnswer: CustomResearchAnswer }>) => {
      const { customResearchAnswer } = action.payload;
      state.rightbarContent = { ...state.rightbarContent, customResearchAnswer, businessId: undefined, companyDomain: undefined };
      state.rightBarOpen = true;
    },
    setRightbarCompanyDomain: (state, action: PayloadAction<{ companyDomain?: string }>) => {
      const { companyDomain } = action.payload;
      state.rightbarContent = { ...state.rightbarContent, companyDomain, businessId: undefined, categoryDetails: undefined };
      state.rightBarOpen = true;
    },
    clearRightbarContent: (state) => {
      state.rightbarContent = initialState.rightbarContent;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  getSidebars,
  updateRightbar,
  updateLeftbar,
  setRightbarBusiness,
  setRightbarCategoryDetails,
  setRightbarCustomResearchAnswer,
  setRightbarCompanyDomain,
  clearRightbarContent
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
