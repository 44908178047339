import { useAppSelector } from "@/store/hooks";
import { IngestedCompany } from "@/services/autogen";
import { IngestedCompanyListTable } from "./CompanyInboxListTable";
import { Button } from "../ui/button";
import { useState } from "react";
import { EyeSlash } from "@phosphor-icons/react";
import { Eye } from "lucide-react";

export const CompanyInboxScrollDisplay = ({ ingestedCompanies }: { ingestedCompanies: IngestedCompany[] }) => {
  return (
    <div className="flex-col">
      <IngestedCompanyListTable ingestedCompanies={ingestedCompanies} />
    </div>
  );
};

export const CompanyInboxViewer = () => {
  const ingestedCompanies = useAppSelector((state) => state.ingestedCompanies.ingestedCompanies);
  const framework = useAppSelector((state) => state.ingestedCompanies.assessmentFramework);
  const [frameworkOpen, setFrameworkOpen] = useState(false);
  return (
    <div className="flex flex-col w-full h-full space-y-4">
      <div className="flex flex-row w-full space-x-4 items-center">
        <div className="text-2xl font-semibold text-colors-brand-8000">Company inbox</div>
        <div className="flex flex-row h-full">
          <Button
            variant="outline"
            onClick={() => {
              setFrameworkOpen(!frameworkOpen);
            }}>
            {frameworkOpen ? <EyeSlash size={20} className="mr-2" /> : <Eye size={20} className="mr-2" />}
            {frameworkOpen ? "Hide framework" : "Show framework"}
          </Button>
        </div>
      </div>
      <div>
        {frameworkOpen && (
          <div className="text-colors-brand-8000">
            <table className="rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-colors-brand-700 text-colors-text-text-white">
                  <th className="px-4 py-2 text-left">Key</th>
                  <th className="px-4 py-2 text-left">Weight</th>
                  <th className="px-4 py-2 text-left">Ideal answer</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(framework).map(([key, value]) => (
                  <tr key={key} className="border-t border-gray-300 bg-colors-brand-50">
                    <td className="px-4 py-2">{key}</td>
                    <td className="px-4 py-2">{(value as { weight: number }).weight}</td>
                    <td className="px-4 py-2">{(value as { ideal: string }).ideal}</td>
                    {/* TODO-WL: seems hacky to do the "as" cast here */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="flex flex-row w-full h-full">
        <div className="overflow-y-scroll pr-10">
          <CompanyInboxScrollDisplay ingestedCompanies={ingestedCompanies} />
        </div>
      </div>
    </div>
  );
};
