import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export enum View {
  Workflow = "workflow",
  CompanyList = "company-list",
  Company = "company",
  Dashboard = "dashboard",
  Documents = "documents",
  IngestedCompanies = "ingested-companies",
  Taxonomy = "taxonomy",
  EnrichmentsAdmin = "enrichments-admin",
  RubricScoring = "rubric-scoring",
  ScoredTaxonomy = "scored-taxonomy",
  PeopleList = "people-list",
  DeprCompanySearch = "depr-company-search",
  CompanySearch = "company-search"
}

export type ViewsState = {
  activeView: View;
};

const initialState: ViewsState = {
  activeView: View.Dashboard
};

// TODO: replace this whole thing with pages and routing so that normal browser navigation works?
// e.g. meticulate.ai/workflow/1234532
//  meticulate.ai/company-list/1234532
//  meticulate.ai/company/1234532
// actually determine if we actually want to do this. single page applications
export const viewsSlice = createSlice({
  name: "views",
  initialState,
  reducers: {
    setActiveView: (state, action: PayloadAction<View>) => {
      state.activeView = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setActiveView } = viewsSlice.actions;

export default viewsSlice.reducer;
