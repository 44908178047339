import { useEffect } from "react";
import { FilterHeader } from "@/components/workflow-inputs/FilterHeader";
import { SEMANTIC_FIELD_TYPE_DISPLAY_TEXT, SEMANTIC_FIELD_TYPE_PLACEHOLDER_TEXT } from "@/lib/constants";
import { FrontendCompanySearchInstructions, SemanticFieldType } from "@/services/autogen";
import { SemanticFieldSearchQuery } from "@/services/autogen";
import { useState } from "react";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { Sparkle } from "@phosphor-icons/react";

const CompanySearchSemanticQueryEditor = ({
  fieldType,
  semanticQuery,
  setSemanticQuery,
  disableAdd,
  viewOnly
}: {
  fieldType: SemanticFieldType;
  semanticQuery?: SemanticFieldSearchQuery;
  setSemanticQuery: (query?: SemanticFieldSearchQuery) => void;
  disableAdd?: boolean;
  viewOnly?: boolean;
}) => {
  const [localQuery, setLocalQuery] = useState(semanticQuery?.query ?? "");
  useEffect(() => {
    setLocalQuery(semanticQuery?.query ?? "");
  }, [semanticQuery]);
  return (
    <>
      <FilterHeader
        title={SEMANTIC_FIELD_TYPE_DISPLAY_TEXT[fieldType]}
        onDelete={() => {
          setSemanticQuery(undefined);
        }}
        onAdd={() => {
          setSemanticQuery({ field: fieldType, query: "" });
        }}
        isActive={semanticQuery != null}
        viewOnly={viewOnly || fieldType === SemanticFieldType.BusinessModel}
        disableAdd={disableAdd}
      />
      {semanticQuery != null && (
        <AutosizedTextarea
          // className="border border-black"
          // TODO: placeholder map for each field type
          placeholder={SEMANTIC_FIELD_TYPE_PLACEHOLDER_TEXT[fieldType]}
          value={localQuery}
          disabled={viewOnly}
          onChange={(e) => {
            setLocalQuery(e.target.value);
          }}
          onBlur={() => {
            setSemanticQuery({
              field: fieldType,
              query: localQuery
            });
          }}
        />
      )}
    </>
  );
};

export const CompanySearchSemanticSearchBox = ({
  companySearchInstructions,
  setCompanySearchInstructions,
  viewOnly
}: {
  companySearchInstructions: FrontendCompanySearchInstructions;
  setCompanySearchInstructions: (instructions: FrontendCompanySearchInstructions) => void;
  viewOnly?: boolean;
}) => {
  // Count non-null properties
  const nonNullCount = companySearchInstructions.semantic_search_instructions.filter((query) => query != null).length;

  const semanticFieldTypes = [
    SemanticFieldType.BusinessModel,
    SemanticFieldType.MainOfferings,
    SemanticFieldType.CustomerSegments,
    // FrontendSemanticFieldType.Geographies,
    SemanticFieldType.Technologies
  ];
  const handleSemanticQueryUpdate = (fieldType: SemanticFieldType, semanticQuery?: SemanticFieldSearchQuery) => {
    if (semanticQuery == null) {
      setCompanySearchInstructions({
        ...companySearchInstructions,
        semantic_search_instructions: companySearchInstructions.semantic_search_instructions.filter((q) => q.field !== fieldType)
      });
    } else {
      const existingIndex = companySearchInstructions.semantic_search_instructions.findIndex((q) => q.field === fieldType);
      const updatedInstructions = [...companySearchInstructions.semantic_search_instructions];

      if (existingIndex >= 0) {
        updatedInstructions[existingIndex] = semanticQuery;
      } else {
        updatedInstructions.push(semanticQuery);
      }

      setCompanySearchInstructions({
        ...companySearchInstructions,
        semantic_search_instructions: updatedInstructions
      });
    }
  };
  return (
    <div className="flex flex-col gap-2 border-colors-brand-200 border p-2 rounded-lg w-[400px]">
      <div className="text-lg font-medium flex flex-row items-center gap-2">
        <Sparkle size={24} />
        <span>Search</span>
      </div>
      <div className="text-md mb-1 text-colors-text-text-primary-(900) self-start">Search criteria (only 3 allowed at a time)</div>
      {/* TODO: enforce 3 max */}
      {semanticFieldTypes.map((fieldType) => (
        <CompanySearchSemanticQueryEditor
          key={fieldType}
          fieldType={fieldType}
          semanticQuery={companySearchInstructions.semantic_search_instructions.find((q) => q.field === fieldType)}
          setSemanticQuery={(query) => handleSemanticQueryUpdate(fieldType, query)}
          disableAdd={nonNullCount >= 3}
          viewOnly={viewOnly}
        />
      ))}
    </div>
  );
};
