import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  VisibilityState,
  Column,
  getExpandedRowModel
} from "@tanstack/react-table";
import { CSSProperties, forwardRef, useState } from "react";
import { setRightbarCompanyDomain } from "@/store/sidebars";
import { useAppDispatch } from "@/store/hooks";
import { ScoredTaxonomyEntryRow } from "./ScoredTaxonomyTable";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

const getCommonPinningStyles = (column: Column<ScoredTaxonomyEntryRow>, header: boolean = false): CSSProperties => {
  const colIsPinned = column.id === "name";
  let z;
  if (colIsPinned) {
    if (header) {
      z = 40;
    } else {
      z = 30;
    }
  } else {
    if (header) {
      z = 30;
    } else {
      z = 0;
    }
  }
  return {
    left: colIsPinned ? 0 : undefined,
    position: colIsPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: z,
    backgroundColor: "white",
    borderWidth: colIsPinned ? "0px 2px 0px 0px" : undefined
  };
};

// I don't think I'm handling these generics properly
export const DataTable = forwardRef<HTMLDivElement, DataTableProps<ScoredTaxonomyEntryRow, any>>(({ columns, data }, ref) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [hoveredRow, setHoveredRow] = useState<string>("");
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    employee_count: true,
    main_offerings: false,
    business_model: false,
    customer_groups: false,
    geographies: false,
    headquarters: false,
    maturity: false,
    status: false,
    founded: false,
    revenue: false,
    funds_raised: false,
    valuation: false,
    investors: false,
    market_cap: false,
    enterprise_value: false,
    earnings: false,
    ebitda: false,
    reported_revenue: false,
    revenue_growth: false,
    gross_margin: false,
    free_cash_flow: false,
    overview: false
  });
  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnVisibility
    },
    meta: {
      hoveredRow
    }
  });
  const dispatch = useAppDispatch();

  return (
    <div className="w-full flex flex-grow min-h-1 max-h-full">
      <div ref={ref} className="max-w-full rounded-md border border-colors-border-border-primary bg-transparent max-h-full overflow-auto">
        <table className="max-h-full border-collapse w-max">
          <thead className="bg-white sticky top-0 z-[1000] shadow group">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border-b border-colors-border-border-primary text-left font-medium text-colors-text-text-tertiary-(600) items-start text-xs bg-colors-gray-modern-50">
                {headerGroup.headers.map((header, _) => {
                  return (
                    <th
                      key={header.id}
                      className="font-medium group pr-1 bg-white"
                      colSpan={header.colSpan}
                      style={{ ...getCommonPinningStyles(header.column, true) }}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.id !== "name" && header.id !== "trash" && header.id !== "category" && header.id !== "subcategory" && (
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `w-1 h-full group-hover:bg-colors-gray-modern-300 absolute top-0 right-0 cursor-col-resize ${
                              table.options.columnResizeDirection
                            } ${header.column.getIsResizing() ? "bg-colors-gray-modern-300" : ""}`
                          }}
                        />
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="max-h-full text-xs">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    className={`max-h-10 border-b border-colors-border-border-primary hover:cursor-pointer bg-white`}
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => {
                      dispatch(setRightbarCompanyDomain({ companyDomain: row.original.company.domain }));
                    }}
                    onMouseEnter={() => setHoveredRow(row.id)}
                    onMouseLeave={() => setHoveredRow("")}>
                    {row.getVisibleCells().map((cell, _) => (
                      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column), width: cell.column.getSize() }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="h-24 text-center">
                  Your filters yielded no results.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});
