// Note: Feature flags and other configuration
const websocketPath = import.meta.env.VITE_BRAIN_WEBSOCKET_PATH;
export const websocketBaseUrl = websocketPath + "/ws/brain";

export const brainServerPath = import.meta.env.VITE_BRAIN_SERVER_PATH;

export const enableDevWorkflows = import.meta.env.VITE_ENABLE_DEV_WORKFLOWS === "true";

export const signInRequired = import.meta.env.VITE_SIGN_IN_REQUIRED === "true";

export const demoFeaturesOn = import.meta.env.VITE_DEMO_FEATURES_ON === "true";
