/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FrontendCompany, TearsheetEnrichmentType } from "@/services/autogen";
import { Users, Globe2, RefreshCw, AlertTriangle, Boxes, BriefcaseBusiness } from "lucide-react";
import { formatDollarValueMillionsBillions } from "@/lib/utils";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { WebTrafficFull } from "./WebTraffic";
import { OfferingsDiveFullDisplay, OfferingsDiveSummaryDisplay } from "./OfferingsDive";
import { CommercialDiveFullDisplay, CommercialDiveSummaryDisplay } from "./CommercialDive";
import { TeamDiveDisplay, TeamDiveSummaryDisplay } from "./TeamDive";
import { RecentDevelopmentsDisplay, RecentDevelopmentsSummaryDisplay } from "./RecentDevelopments";
import { RelatedCompaniesDisplay } from "./RelatedCompanies";
import { SourcesLogoViewer } from "./SourceViewer";
import { UnderlinedTabs, UnderlinedTabsContent, UnderlinedTabsList, UnderlinedTabsTrigger } from "../ui/tabs";
import { TrendUp } from "@phosphor-icons/react";
import { AssessmentSummaryDisplay } from "./Assessment";
import { TalkingPointsSummaryDisplay } from "./TalkingPoints";
import { FeaturedDocumentsDisplay } from "./FeaturedDocuments";
import { CrmLogSummaryDisplay } from "./CrmLog";
import { demoFeaturesOn } from "@/utils/config";
import { PitchbookEnrichmentDisplay } from "./PitchbookEnrichment";
import { fetchCompanyTearsheetStreamingThunk } from "@/store/companyProfiles";

function SummaryBox({ company }: { company: FrontendCompany }) {
  return (
    <div className="flex flex-col border border-colors-border-border-primary bg-white rounded-xl p-3 items-start gap-2">
      <div className="flex flex-row space-x-6 items-start text-sm">
        {company.summary.business_model && (
          <div className="flex flex-col w-1/4">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <BriefcaseBusiness size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Business model</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.business_model.text} </div>
            </div>
          </div>
        )}
        {company.summary.main_offerings && (
          <div className="flex flex-col w-1/4">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Boxes size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Offerings</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.main_offerings.text} </div>
            </div>
          </div>
        )}
        {company.summary.customer_segments && (
          <div className="flex flex-col w-1/4">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Users size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Customers</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1">{company.summary.customer_segments.text}</div>
            </div>
          </div>
        )}
        {company.summary.geographies && (
          <div className="flex flex-col w-1/4">
            <div className="text-colors-text-text-primary-(900) text-md font-semibold mb-1">
              <div className="flex flex-row items-center">
                <Globe2 size={20} className="text-colors-foreground-fg-tertiary-(600)" />
                <div className="text-colors-foreground-fg-secondary-(700) ml-3">Geographies</div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-colors-text-text-secondary-(700) text-md flex-1"> {company.summary.geographies.text} </div>
            </div>
          </div>
        )}
      </div>
      <SourcesLogoViewer sources={company.summary.business_model.sources} label={true} />
    </div>
  );
}

function FinancialsAndTractionBox({ company }: { company: FrontendCompany }) {
  const formattedFollowers = company.linkedin_followers
    ? company.linkedin_followers >= 1000000
      ? (company.linkedin_followers / 1000000).toFixed(1) + "M"
      : (company.linkedin_followers / 1000).toFixed(1) + "k"
    : company.linkedin_followers;
  const linkedinFollowerGrowthMonthly = company.linkedin_followers_90day_growth;
  const linkedinFollowerGrowthColor = linkedinFollowerGrowthMonthly !== undefined && linkedinFollowerGrowthMonthly > 0 ? "green" : "red";
  const formattedLinkedinFollowerGrowth =
    linkedinFollowerGrowthMonthly !== undefined
      ? `${linkedinFollowerGrowthMonthly > 0 ? "+" : "-"}${Math.abs(linkedinFollowerGrowthMonthly * 100).toFixed(1)}%`
      : "";
  return (
    <div className="flex flex-col border border-colors-border-border-primary bg-white rounded-xl p-3 w-full  min-w-60">
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <TrendUp size={20} />
        <div>Funding and traction</div>
      </div>
      <div className="flex flex-col space-y-2">
        {company.web_traffic && (
          <div>
            <div className="text-colors-text-text-secondary-(700) font-semibold mb-1">Web Traffic</div>
            <WebTrafficFull webTraffic={company.web_traffic} />
          </div>
        )}
        {company.linkedin_followers && (
          <div className="flex flex-row items-baseline space-x-2">
            <div className="text-colors-text-text-secondary-(700) font-semibold">LinkedIn followers</div>
            <div className="text-colors-text-text-secondary-(700) items-baseline">{formattedFollowers}</div>
            {company.linkedin_followers_90day_growth !== null && (
              <div className="flex flex-row space-x-1 text-sm font-light items-baseline">
                <span className="flex items-baseline" style={{ color: linkedinFollowerGrowthColor }}>
                  {formattedLinkedinFollowerGrowth}
                </span>
                <span className="text-sm">(90-day)</span>
              </div>
            )}
          </div>
        )}
        {company.list_of_investors != null && company.list_of_investors.length > 0 && (
          <div>
            <div className="text-colors-text-text-secondary-(700) font-semibold mb-1">Investors</div>
            <div className="flex flex-row gap-1 text-colors-text-text-tertiary-(600) font-light text-sm w-full flex-wrap">
              {Array.from(new Set(company.list_of_investors)).join(", ")}
            </div>
          </div>
        )}
        {company.fundraises != null && company.fundraises.length > 0 && (
          <div>
            <div className="text-colors-text-text-secondary-(700) font-semibold mb-1">Fundraises</div>
            <div className="flex flex-col space-y-1 text-sm">
              {company.fundraises?.map((fundraise, index) => (
                <div className="flex flex-row text-colors-text-text-secondary-(700) justify-between w-full" key={index}>
                  {/* <div className="text-colors-text-text-tertiary-(600) font-light">{fundraise.round_name}</div> */}
                  <div className="text-colors-text-text-tertiary-(600) font-light">
                    {fundraise.date} - {formatDollarValueMillionsBillions(fundraise.amount)}
                  </div>
                  <div className="text-colors-text-text-tertiary-(600) font-light">{"(" + fundraise.round_name + ")"}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const CompanyProfileViewer = ({ onCloseRightSection }: { onCloseRightSection?: () => void }) => {
  const activeCompanyDomain = useAppSelector((state) => state.companyProfiles.activeCompanyDomain);
  const tearsheet = useAppSelector((state) => state.companyProfiles.tearsheets.find((c) => c.company.domain === activeCompanyDomain));

  const isLoading = useAppSelector((state) => state.companyProfiles.profilesLoading.includes(activeCompanyDomain ?? ""));
  const [tabValue, setTabValue] = useState<string>("overview");
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.token);
  useEffect(() => {
    onCloseRightSection && onCloseRightSection();
  }, [tearsheet]);

  useEffect(() => {
    if (tearsheet == null && token != null && activeCompanyDomain != null) {
      dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: activeCompanyDomain }));
    }
  }, [activeCompanyDomain]);

  if (tearsheet == null) {
    if (isLoading) {
      return (
        <div className="self-center border bg-colors-gray-modern-100 p-10 rounded-xl flex flex-row items-center text-colors-text-text-secondary-(700)">
          <RefreshCw size={24} className="mr-4 animate-spin" />
          <div className="flex flex-col">
            <div>Loading company profile...</div>
            <div>Give us a minute, we're fetching the latest data.</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="self-center border bg-colors-background-bg-error-primary p-10 rounded-xl flex flex-row items-center text-colors-text-text-secondary-(700)">
          <AlertTriangle size={24} className="mr-4" />
          <div className="flex flex-col">
            <div>Uh-oh, we couldn't find this company profile.</div>
            <div>Sorry about that! We'll try to get this fixed as soon as possible.</div>
          </div>
        </div>
      );
    }
  }
  const company = tearsheet.company;
  return (
    <div className="flex flex-col w-full bg-white">
      <UnderlinedTabs value={tabValue} onValueChange={setTabValue}>
        <UnderlinedTabsList>
          <UnderlinedTabsTrigger value="overview">Overview</UnderlinedTabsTrigger>
          <UnderlinedTabsTrigger value="offerings" disabled={tearsheet.offerings_dive == null}>
            Offerings
          </UnderlinedTabsTrigger>
          <UnderlinedTabsTrigger value="commercial" disabled={tearsheet.commercial_dive == null}>
            Commercial
          </UnderlinedTabsTrigger>
          <UnderlinedTabsTrigger value="team" disabled={tearsheet.team_dive == null}>
            Team
          </UnderlinedTabsTrigger>
          <UnderlinedTabsTrigger value="news" disabled={tearsheet.recent_developments == null}>
            Developments
          </UnderlinedTabsTrigger>
          <UnderlinedTabsTrigger value="documents" disabled={tearsheet.featured_documents == null}>
            Documents
          </UnderlinedTabsTrigger>
        </UnderlinedTabsList>
        <UnderlinedTabsContent value="overview">
          <div className="space-y-6">
            <SummaryBox company={company} />

            <div className="flex flex-row gap-4 items-start">
              <div className="flex flex-col w-2/3 min-w-80 gap-4">
                <OfferingsDiveSummaryDisplay
                  offeringsDive={tearsheet.offerings_dive}
                  onDoubleClick={() => setTabValue("offerings")}
                  loading={isLoading}
                />

                <CommercialDiveSummaryDisplay
                  commercialDive={tearsheet.commercial_dive}
                  onDoubleClick={() => setTabValue("commercial")}
                  loading={isLoading}
                />

                <RecentDevelopmentsSummaryDisplay
                  recentDevelopments={tearsheet.recent_developments}
                  onDoubleClick={() => setTabValue("news")}
                  loading={isLoading}
                />
                {demoFeaturesOn && tearsheet.dealcloud_company_info != null && (
                  <CrmLogSummaryDisplay dealcloudCompanyInfo={tearsheet.dealcloud_company_info} />
                )}
                {/* {tearsheet.featured_documents != null && <FeaturedDocumentsDisplay featuredDocuments={tearsheet.featured_documents} />} */}
              </div>
              <div className="flex flex-col w-1/3 gap-4">
                <TeamDiveSummaryDisplay teamDive={tearsheet.team_dive} onDoubleClick={() => setTabValue("team")} loading={isLoading} />
                <FinancialsAndTractionBox company={company} />
                {demoFeaturesOn && (
                  <PitchbookEnrichmentDisplay
                    enrichment={tearsheet.enrichments?.find((e) => e.enrichment_type === TearsheetEnrichmentType.Pitchbook)}
                  />
                )}
                <RelatedCompaniesDisplay relatedCompanies={tearsheet.related_companies} loading={isLoading} />
              </div>
            </div>
            {demoFeaturesOn && tearsheet.commentary?.assessment != null && (
              <AssessmentSummaryDisplay assessment={tearsheet.commentary.assessment} />
            )}
            {demoFeaturesOn && tearsheet.commentary?.talking_points != null && (
              <TalkingPointsSummaryDisplay talkingPoints={tearsheet.commentary.talking_points} />
            )}
          </div>
        </UnderlinedTabsContent>
        <UnderlinedTabsContent value="offerings">
          {tearsheet.offerings_dive != null && <OfferingsDiveFullDisplay offeringsDive={tearsheet.offerings_dive} />}
        </UnderlinedTabsContent>

        <UnderlinedTabsContent value="commercial">
          {tearsheet.commercial_dive != null && <CommercialDiveFullDisplay commercialDive={tearsheet.commercial_dive} />}
        </UnderlinedTabsContent>
        <UnderlinedTabsContent value="team">
          {tearsheet.team_dive != null && <TeamDiveDisplay teamDive={tearsheet.team_dive} />}
        </UnderlinedTabsContent>
        <UnderlinedTabsContent value="news">
          {tearsheet.recent_developments != null && <RecentDevelopmentsDisplay recentDevelopments={tearsheet.recent_developments} />}
        </UnderlinedTabsContent>
        <UnderlinedTabsContent value="documents">
          {tearsheet.featured_documents != null && <FeaturedDocumentsDisplay featuredDocuments={tearsheet.featured_documents} />}
        </UnderlinedTabsContent>
      </UnderlinedTabs>
    </div>
  );
};
