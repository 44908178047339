import { useMemo, useRef } from "react";

import { ColumnDef, HeaderContext, Row } from "@tanstack/react-table";
import { CompanyRow } from "@/components/company-table/common/CompanyRow";
import { FrontendAllocationAnswer, FrontendQualificationCriterion, FrontendQualifiedCompany } from "@/services/autogen";
import { basicColumns, finalColumn } from "@/components/company-table/common/Columns";
import { AbstractCompanyTable } from "@/components/company-table/common/AbstractCompanyTable";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";

import { SortingDirectionIndicator } from "../company-table/common/CellComponents";
import _ from "lodash";
import { Funnel } from "@phosphor-icons/react";

export interface QualifiedCompanyRow extends CompanyRow {
  allocation_results: FrontendAllocationAnswer[];
}

const allocationCriterionSorting = (rowA: Row<QualifiedCompanyRow>, rowB: Row<QualifiedCompanyRow>, index: number): number => {
  const a = rowA.original.allocation_results[index]?.fit_level;
  const b = rowB.original.allocation_results[index]?.fit_level;
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }
  const returnVal = a > b ? 1 : -1;
  return returnVal;
};

export function getQualificationColumns(qualificationCriteria: FrontendQualificationCriterion[]): ColumnDef<QualifiedCompanyRow>[] {
  return qualificationCriteria.map((criterion, index) => ({
    accessorKey: criterion.title,
    header: (header: HeaderContext<QualifiedCompanyRow, unknown>) => (
      <div className="flex flex-row items-center w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
        <Funnel size={16} className="mr-1 shrink-0" />
        {_.truncate(criterion.title, { length: 40 })}
        <SortingDirectionIndicator column={header.column} />
      </div>
    ),
    cell: ({ row }: { row: Row<QualifiedCompanyRow> }) => {
      return (
        row.original.allocation_results.length > index && (
          <div className="overflow-hidden ellipsis w-auto text-right flex flex-row gap-2 p-2">
            <div className="bg-colors-gray-modern-4000 rounded-sm text-center self-center px-1">
              <ScoreChip score={row.original.allocation_results[index].fit_level} />
            </div>
            <span className="text-colors-text-text-secondary-(700) max-h-10">{row.original.allocation_results[index].explanation}</span>
            <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
          </div>
        )
      );
    },
    sortingFn: (rowA: Row<QualifiedCompanyRow>, rowB: Row<QualifiedCompanyRow>, _: any): number => {
      return allocationCriterionSorting(rowA, rowB, index);
    },
    meta: {
      group: "scoring",
      displayName: criterion.title.slice(0, 20)
    }
  }));
}

export function QualifiedCompanyTable({
  companies,
  qualificationCriteria,
  selectedDomains,
  onSelectedDomainsChange,
  onCompanyClick,
  onRowDelete,
  disableRowSelection
}: {
  companies: FrontendQualifiedCompany[];
  qualificationCriteria: FrontendQualificationCriterion[];
  selectedDomains: string[];
  onSelectedDomainsChange: (selectedDomains: string[]) => void;
  onCompanyClick: (domain: string) => void;
  onRowDelete?: (domain: string) => void;
  disableRowSelection?: boolean;
}) {
  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useMemo<ColumnDef<QualifiedCompanyRow>[]>(() => {
    const cols = [...basicColumns] as ColumnDef<QualifiedCompanyRow>[];
    if (disableRowSelection) {
      cols.shift();
    }
    cols.splice(2, 0, ...getQualificationColumns(qualificationCriteria));
    return [...cols, finalColumn(tableRef, onRowDelete, ["scoring"])] as ColumnDef<QualifiedCompanyRow>[];
  }, [qualificationCriteria, disableRowSelection]);

  const onRowClick = (row: CompanyRow) => {
    onCompanyClick(row.domain);
  };

  const rows: QualifiedCompanyRow[] = companies.map((company, index) => ({
    ...company.company,
    tableRank: index,
    allocation_results: company.allocation_results
  }));

  return (
    <AbstractCompanyTable
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      tableRef={tableRef}
      selectedDomains={selectedDomains}
      onSelectedDomainsChange={onSelectedDomainsChange}
    />
  );
}
