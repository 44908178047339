import { Plus } from "lucide-react";

import MultipleSelector, { Option } from "@/components/ui/multiselect";
import { COUNTRY_OPTIONS } from "@/components/workflow-inputs/CountryOptions";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { FilterHeader } from "./FilterHeader";

export const HqCountryFilterAddButton = ({
  hqCountryCodes,
  setHqCountryCodes
}: {
  hqCountryCodes?: string[];
  setHqCountryCodes: (hqCountryCodes: string[] | undefined) => void;
}) => {
  if (hqCountryCodes == null) {
    return (
      <Button variant="outline" onClick={() => setHqCountryCodes([])}>
        <Plus className="mr-2" size={16} /> HQ Country
      </Button>
    );
  } else {
    return <></>;
  }
};

const HqOnSearchByCountryLabel = async (searchTerm: string) => {
  const filteredOptions = COUNTRY_OPTIONS.filter((option: Option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));
  return filteredOptions;
};

export const HqCountryFilterInput = ({
  hqCountryCodes: countryCodes,
  setHqCountryCodes: setCountryCodes,
  viewOnly
}: {
  hqCountryCodes?: string[];
  setHqCountryCodes: (filter: string[] | undefined) => void;
  viewOnly?: boolean;
}) => {
  if (countryCodes == null) {
    return (
      <FilterHeader
        title="HQ country"
        infoText="Not all countries are supported yet"
        onDelete={() => setCountryCodes(undefined)}
        onAdd={() => setCountryCodes([])}
        isActive={countryCodes != null}
        viewOnly={viewOnly}
      />
    );
  }
  const options: Option[] = countryCodes
    .map((code) => COUNTRY_OPTIONS.find((option) => option.value === code))
    .filter((option): option is Option => option !== undefined);
  const [hqCountryOptions, setHqCountryOptions] = useState<Option[]>(options);
  useEffect(() => {
    setCountryCodes(hqCountryOptions.map((option) => option.value));
  }, [hqCountryOptions]);
  useEffect(() => {
    const options: Option[] = countryCodes
      .map((code) => COUNTRY_OPTIONS.find((option) => option.value === code))
      .filter((option): option is Option => option !== undefined);
    setHqCountryOptions(options);
  }, [countryCodes]);
  return (
    <div className="flex flex-col">
      <FilterHeader
        title="HQ country"
        infoText="Not all countries are supported yet"
        onDelete={() => setCountryCodes(undefined)}
        onAdd={() => setCountryCodes([])}
        isActive={countryCodes != null}
        viewOnly={viewOnly}
      />

      <div className="flex w-full flex-col">
        <MultipleSelector
          onSearch={HqOnSearchByCountryLabel}
          hidePlaceholderWhenSelected={true}
          value={hqCountryOptions}
          onChange={setHqCountryOptions}
          defaultOptions={COUNTRY_OPTIONS}
          placeholder="Select allowed HQ countries..."
          emptyIndicator={<p className="text-center text-sm leading-10 text-gray-600 dark:text-gray-400">No results found.</p>}
          disabled={viewOnly}
          showGroupToggle={true}
          groupBy="group"
        />
      </div>
    </div>
  );
};
