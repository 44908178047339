import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useEffect, useState } from "react";
import React, { useCallback } from "react";
import { Input } from "../ui/input";
import {
  removePersonFromList,
  editPeopleListTitle,
  addToPeopleListThunkByLinkedinUrls,
  getPhoneNumbersAndAddToPeopleListThunk,
  fetchPeopleListThunk
} from "@/store/peopleLists";
import { posthog } from "posthog-js";
import { useAuth } from "@clerk/clerk-react";
import { Button } from "../ui/button";
import { ExportFormat, FrontendPerson, PeopleListStatus } from "@/services/autogen/api";
import { toast } from "sonner";
import { exportPeopleList, pushContactsToCrm } from "@/services/brain-api.service";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import {
  UnderlinedTabs,
  UnderlinedTabsList,
  UnderlinedTabsTrigger,
  UnderlinedTabsContent,
  TabsContent,
  TabsList,
  TabsTrigger
} from "../ui/tabs";
import { Textarea } from "../ui/textarea";
import { CloudUpload, Import } from "lucide-react";
import { SimpleCompanyTable } from "../company-table/SimpleCompanyTable";
import { Tabs } from "@radix-ui/react-tabs";
import { GenericHeader } from "../workspace/GenericHeader";
import { SimpleTooltip } from "../ui/tooltip";
import { ExportListButton } from "../company-table/ExportListButton";
import { PeopleList } from "@/services/autogen";
import { SimplePersonTable } from "../person-table/SimplePersonTable";
import { Spinner } from "@phosphor-icons/react";
import { Combobox } from "../ui/combobox";

export const ImportPeopleListDialog = ({ peopleList, disabled }: { peopleList: PeopleList; disabled?: boolean }) => {
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();
  const [linkedinUrlsToUpload, setLinkedinUrlsToUpload] = useState<string[]>([]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" disabled={disabled} variant="outline">
          <Import size={24} /> Import people list
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[400px]">
        <Tabs defaultValue="text">
          <TabsList className="grid w-full grid-cols-1">
            <TabsTrigger value="text">LinkedIn URLs</TabsTrigger>
          </TabsList>
          <TabsContent value="text" className="flex flex-col gap-4">
            <Textarea
              placeholder="Paste people LinkedIn URLs (comma-separated, tab-separated, or one per line)"
              className="min-h-[200px]"
              onChange={(e) => {
                const text = e.target.value;
                let linkedin_urls: string[] = [];

                if (text.includes(",")) {
                  linkedin_urls = text.split(",").map((s) => s.trim());
                } else if (text.includes("\t")) {
                  linkedin_urls = text.split("\t").map((s) => s.trim());
                } else {
                  linkedin_urls = text.split("\n").map((s) => s.trim());
                }

                linkedin_urls = linkedin_urls.filter((c) => c.length > 0);
                setLinkedinUrlsToUpload(linkedin_urls);
              }}
            />
            <DialogClose asChild>
              <Button
                onClick={async () => {
                  const token = await getToken();
                  if (token == null || linkedinUrlsToUpload.length == 0) {
                    return;
                  }
                  toast.info("Adding people to people list...");
                  posthog.capture("user_added_people_to_people_list", { people_list_id: peopleList.id, source: "linkedin_urls" });
                  dispatch(
                    addToPeopleListThunkByLinkedinUrls({
                      token: token,
                      people_list_id: peopleList.id,
                      linkedin_urls: linkedinUrlsToUpload
                    })
                  );
                }}>
                Add people
              </Button>
            </DialogClose>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

export const PushContactsToCrmDialog = ({
  indicesSelected,
  allContacts,
  disabled
}: {
  indicesSelected: number[];
  allContacts: FrontendPerson[];
  disabled?: boolean;
}) => {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const contactCreditsAvailable = useAppSelector((state) => state.user.contactCreditsAvailable);
  const crmOwners = useAppSelector((state) => state.user.crmOwners);
  const [selectedOwnerId, setSelectedOwnerId] = useState<string | undefined>(undefined);

  const handlePushToCrm = useCallback(async () => {
    if (indicesSelected.length == 0) {
      toast.error("Please select at least one contact");
      return;
    }
    const contactsSelected = indicesSelected.map((idx) => allContacts[idx]);

    const token = await getToken();
    if (token != null) {
      posthog.capture("user_pushed_contacts_to_crm", {
        contacts_selected: contactsSelected,
        owner_id: selectedOwnerId
      });
      await pushContactsToCrm(token, contactsSelected, selectedOwnerId);
    }
  }, [indicesSelected, allContacts, getToken, dispatch, selectedOwnerId]);

  return (
    <Dialog>
      {indicesSelected.length > 0 && (
        <DialogTrigger asChild>
          <Button size="sm" disabled={disabled || (contactCreditsAvailable != null && contactCreditsAvailable == 0)}>
            <CloudUpload size={20} /> Push to CRM
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className="w-80">
        <DialogTitle>Push to CRM</DialogTitle>
        <Combobox
          placeholderText="Owner (optional)"
          options={crmOwners.map((owner) => ({ label: owner.name, value: owner.id }))}
          value={selectedOwnerId}
          setValue={setSelectedOwnerId}
          disableSearch={true}
          // disabling search because values are ids and labels are names but you'd want to search by name
        />
        <div className="flex flex-col gap-2">Push {indicesSelected.length} contacts to CRM?</div>
        <span className="text-xs text-gray-500">(Only new records will be created. Existing records will not be updated)</span>
        <div className="flex flex-row gap-2 w-full">
          <DialogClose asChild>
            <Button className="w-1/2" variant="outline">
              No
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button className="w-1/2" onClick={handlePushToCrm}>
              Yes
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const PeopleListHeader = ({ shareableHeader }: { shareableHeader?: boolean }) => {
  const peopleList = useAppSelector((state) =>
    state.peopleLists.peopleLists.find((list) => list.id === state.peopleLists.activePeopleListId)
  );
  const peopleListStub = useAppSelector((state) =>
    state.peopleLists.stubs.find((stub) => stub.id === state.peopleLists.activePeopleListId)
  );

  const [title, setTitle] = useState(peopleListStub?.title ?? "");
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (peopleListStub) {
      setTitle(peopleListStub.title);
    }
  }, [peopleListStub?.id]);

  // if (peopleListStub == null) {
  //   dispatch(setActiveView(View.Dashboard));
  //   return null;
  // }

  return (
    <GenericHeader
      shareableHeader={shareableHeader}
      shareableLink={`https://app.meticulate.ai/people_list/${peopleListStub?.id}`}
      type="people_list">
      {shareableHeader || peopleList == null ? (
        <div className="w-full border-0 text-xl font-normal mr-2">{title}</div>
      ) : (
        <Input
          className="w-full border-0 text-2xl font-bold mr-2"
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={(e) => {
            dispatch(editPeopleListTitle({ peopleListId: peopleList.id, title: e.target.value }));
          }}
        />
      )}
    </GenericHeader>
  );
};

export const EnrichPhoneNumbersButton = ({
  selectedPeople,
  peopleList,
  disabled
}: {
  selectedPeople: number[];
  peopleList: PeopleList;
  disabled?: boolean;
}) => {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  return (
    <SimpleTooltip text="Costs 10 contact credits per successful enrichment">
      {/* TODO: make this dynamic */}
      {!disabled && selectedPeople.length > 0 && (
        <Button
          className="w-fit"
          size="sm"
          // variant="outline"
          key={peopleList.id}
          onClick={async () => {
            const token = await getToken();
            console.log(selectedPeople);
            if (token != null) {
              // Filter out people who already have phone numbers
              const peopleToEnrich = selectedPeople.filter((idx) => {
                const person = peopleList.people[idx];
                return !person?.phone_number;
              });

              if (peopleToEnrich.length === 0) {
                toast.error("All selected people have already been enriched");
                return;
              }
              toast.info(`Getting phone numbers for ${peopleToEnrich.length} people...`);
              posthog.capture("user_requested_phone_numbers", {
                people_list_id: peopleList.id,
                selected_count: peopleToEnrich.length
              });
              const peopleToEnrichData = peopleList.people.filter((_, idx) => peopleToEnrich.includes(idx));

              dispatch(
                getPhoneNumbersAndAddToPeopleListThunk({
                  token: token,
                  peopleListId: peopleList.id,
                  people: peopleToEnrichData
                })
              );
            }
          }}>
          Enrich with phone numbers
        </Button>
      )}
    </SimpleTooltip>
  );
};

export const PeopleListViewer = React.memo(function PeopleListViewer({
  viewOnly
}: {
  onOpenRightSection?: () => void;
  viewOnly?: boolean;
}) {
  const activePeopleListId = useAppSelector((state) => state.peopleLists.activePeopleListId);
  const peopleList = useAppSelector((state) => state.peopleLists.peopleLists.find((list) => list.id === activePeopleListId));
  const token = useAppSelector((state) => state.user.token);

  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const onPersonDelete = useCallback(
    (id: string) => {
      const index = Number(id);
      console.log("onPersonDelete", index, peopleList?.id);
      dispatch(removePersonFromList({ peopleListId: peopleList?.id ?? "", index }));
    },
    [dispatch, peopleList?.id]
  );
  // Add selected people state
  const [selectedPeople, setSelectedPeople] = useState<number[]>([]); // Array of selected person indices

  useEffect(() => {
    setSelectedPeople([]);
  }, [peopleList]);

  const autoFetchList = async () => {
    if (peopleList && peopleList.status === PeopleListStatus.AddingContacts) {
      if (token != null) {
        dispatch(fetchPeopleListThunk({ token, peopleListId: peopleList.id }));
      }
    }
  };

  useEffect(() => {
    const refreshInterval = 5000;
    // Set up an interval to call fetchWorkflow every {refreshInterval}
    const intervalId = setInterval(autoFetchList, refreshInterval);

    // Clear the interval if workflow_id or status or tokenchanges
    return () => clearInterval(intervalId);
  }, [activePeopleListId, peopleList?.status, token]);

  //  fetch workflow on active workflow id change
  useEffect(() => {
    // Call fetchWorkflow immediately on component mount
    if (activePeopleListId != null && (peopleList == null || peopleList.status === PeopleListStatus.AddingContacts)) {
      if (token != null) {
        dispatch(fetchPeopleListThunk({ token, peopleListId: activePeopleListId }));
      }
    }
  }, [activePeopleListId]);

  return (
    <div className="flex flex-col h-full w-full gap-4">
      {peopleList == null && <div>Loading...</div>}
      {peopleList?.status === PeopleListStatus.AddingContacts && (
        <div className="w-full bg-colors-blue-100 border border-colors-blue-600 p-2 rounded-lg text-colors-blue-800 flex flex-row items-center">
          <Spinner size={24} className="mr-2 animate-spin" />
          <p>Adding contacts from the companies requested. This can take a few minutes.</p>
        </div>
      )}
      {peopleList != null && (
        <UnderlinedTabs defaultValue="people" className="w-full h-full flex flex-col">
          <UnderlinedTabsList>
            <UnderlinedTabsTrigger value="people">
              People
              <span className="ml-2 bg-colors-gray-200 px-2 py-0.5 rounded-full text-sm">{peopleList.people.length}</span>
            </UnderlinedTabsTrigger>
            <UnderlinedTabsTrigger value="companies_found">
              Companies with people found
              <span className="ml-2 bg-colors-gray-200 px-2 py-0.5 rounded-full text-sm">{peopleList.companies_found.length}</span>
            </UnderlinedTabsTrigger>
            <UnderlinedTabsTrigger value="companies_not_found">
              Companies without people found
              <span className="ml-2 bg-colors-gray-200 px-2 py-0.5 rounded-full text-sm">{peopleList.companies_not_found.length}</span>
            </UnderlinedTabsTrigger>
          </UnderlinedTabsList>
          <div className="flex-1 overflow-hidden">
            <UnderlinedTabsContent value="people" className="h-full">
              {/* wrapper div to deal with height padding from Tabs*/}
              <div className="flex flex-col h-full gap-4">
                <div className="flex flex-row gap-2 items-center">
                  {selectedPeople.length > 0 && (
                    <div className="flex flex-row items-center gap-2 bg-colors-gray-modern-50 rounded-lg px-3 py-1.5 text-sm">
                      <div className="flex flex-row items-center gap-1">
                        <div className="text-colors-brand-600 font-medium">{selectedPeople.length}</div>
                        <div className="text-colors-gray-modern-600">selected</div>
                      </div>
                    </div>
                  )}
                  <ImportPeopleListDialog peopleList={peopleList} disabled={viewOnly} />
                  <EnrichPhoneNumbersButton selectedPeople={selectedPeople} peopleList={peopleList} disabled={viewOnly} />
                  {/* TODO: disabled better */}
                  {peopleList.people.length > 0 && peopleList.people[0].crm_status != null && (
                    <PushContactsToCrmDialog indicesSelected={selectedPeople} allContacts={peopleList.people} disabled={viewOnly} />
                  )}
                  <div className="flex-grow" />
                  <ExportListButton<string>
                    selection={selectedPeople.map((idx) => {
                      if (idx >= peopleList.people.length) {
                        return "";
                      }
                      return peopleList.people[idx].email ?? "";
                    })}
                    disabled={viewOnly}
                    formatsEnabled={[ExportFormat.Excel, ExportFormat.Csv]}
                    exportCallback={async (selection, format) => {
                      const token = await getToken();
                      if (token) {
                        toast.info("Your download is starting!");
                        posthog.capture("user_exported_people_list", { list_id: peopleList.id, type: format });
                        return exportPeopleList(token, peopleList, format, selection);
                      }
                    }}
                  />
                </div>
                <SimplePersonTable
                  people={peopleList.people}
                  onPersonClick={() => {}}
                  onRowDelete={onPersonDelete}
                  selectedPeople={selectedPeople}
                  onSelectedPeopleChange={setSelectedPeople}
                  // TODO: dynamic enable
                  enableCrmStatus={peopleList.people.length > 0 && peopleList.people[0].crm_status != null}
                />
              </div>
            </UnderlinedTabsContent>
            <UnderlinedTabsContent value="companies_found" className="h-full">
              {/* wrapper div to deal with height padding from Tabs*/}
              <div className="flex flex-col h-full">
                <SimpleCompanyTable
                  companies={peopleList.companies_found}
                  selectedDomains={[]}
                  onSelectedDomainsChange={() => {}}
                  onCompanyClick={() => {}}
                  disableRowSelection={true}
                />
              </div>
            </UnderlinedTabsContent>
            <UnderlinedTabsContent value="companies_not_found" className="h-full">
              {/* wrapper div to deal with height padding from Tabs*/}
              <div className="flex flex-col h-full">
                <SimpleCompanyTable
                  companies={peopleList.companies_not_found}
                  selectedDomains={[]}
                  onSelectedDomainsChange={() => {}}
                  onCompanyClick={() => {}}
                  disableRowSelection={true}
                />
              </div>
            </UnderlinedTabsContent>
          </div>
        </UnderlinedTabs>
      )}
    </div>
  );
});
