/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataTable } from "./data-table";
import { Notepad } from "@phosphor-icons/react";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { MoveDown, MoveUp, Plus, Trash2 } from "lucide-react";
import { truncateText } from "@/lib/utils";
import { FrontendTaxonomy, FrontendTaxonomyNode } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { useMemo, useRef } from "react";
import { useScoredTaxonomy } from "@/components/scored-taxonomy/ScoredTaxonomyContext";
import { calculateWeightedScore } from "@/components/scored-taxonomy/ScoredTaxonomyTreeViewer";
import { ScoreChip } from "@/components/SourceCompanies/GiantTable/SourceCompaniesTable";

export type ScoredTaxonomyNodeRow = FrontendTaxonomyNode & {
  beta_score?: number;
  number_of_companies?: number;
};

// TODO: remove all of these repeated codes.
const SortingDirectionIndicator = ({ column }: { column: Column<ScoredTaxonomyNodeRow> }) => {
  if (column.getIsSorted() === "asc") {
    return <MoveDown size={14} />;
  } else if (column.getIsSorted() === "desc") {
    return <MoveUp size={14} />;
  } else {
    return null;
  }
};

const betaScoreSorting = (rowA: Row<ScoredTaxonomyNodeRow>, rowB: Row<ScoredTaxonomyNodeRow>): number => {
  const a = rowA.original.beta_score;
  const b = rowB.original.beta_score;
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const returnVal = a < b ? 1 : -1;
  return returnVal;
};

const topicScoreSorting = (rowA: Row<ScoredTaxonomyNodeRow>, rowB: Row<ScoredTaxonomyNodeRow>, topic: string): number => {
  const scoreA = rowA.original.category_scoring?.topic_results.find((result) => result.topic === topic)?.score ?? 0;
  const scoreB = rowB.original.category_scoring?.topic_results.find((result) => result.topic === topic)?.score ?? 0;
  return scoreB - scoreA;
};

// TODO:

export const ScoredTaxonomyCategoryTable = ({ taxonomy }: { taxonomy: FrontendTaxonomy }) => {
  const { betaWeights, excludedCategoryIds } = useScoredTaxonomy();
  const tableWindowRef = useRef<HTMLDivElement>(null);

  // Memoize the table rows
  const tableRows = useMemo(() => {
    const rows: ScoredTaxonomyNodeRow[] = [];
    console.log("starting at time ", new Date().toISOString());

    const addRows = (node: FrontendTaxonomyNode, depth: number) => {
      if (excludedCategoryIds.includes(node.node_id)) {
        return;
      }
      if (node.subnodes.length === 0 && node.number_of_companies > 0) {
        const row: ScoredTaxonomyNodeRow = {
          ...node,
          beta_score: node.category_scoring ? calculateWeightedScore(node.category_scoring.topic_results, betaWeights) : undefined
        };

        rows.push(row);
      }

      node.subnodes.forEach((subnode) => {
        addRows(subnode, depth + 1);
      });
    };

    addRows(taxonomy.taxonomy_node, 0);

    return rows;
  }, [taxonomy, betaWeights]);
  // console.log(tableRows);

  const columns = useMemo(() => {
    const basicColumns: ColumnDef<ScoredTaxonomyNodeRow>[] = [
      {
        accessorKey: "title",
        size: 35,
        header: () => (
          <div className="flex flex-row items-center">
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Category</h1>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center py-2 overflow-visible whitespace-nowrap ">
            {/* {row.original.subRows.length} */}
            {/* <div style={{ width: `${row.original.depth * 20}px` }}></div> */}
            {row.original.node_title != null && (
              <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
                <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
                  {/* <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible w-0"> */}
                  {/* <span className="text-colors-text-text-tertiary-(600) mr-1">({row.original.totalEntries})</span> */}
                  {truncateText(row.original.node_title, 150)}
                  {/* </h1> */}
                </span>
              </div>
            )}
          </div>
          // <h1 className="font-medium text-colors-text-text-primary-(900) overflow-visible whitespace-nowrap">{row.getValue("category")}</h1>
        ),
        meta: {
          group: "meta"
        }
      },
      {
        accessorKey: "beta_score",
        header: (header) => (
          <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Beta Score</h1>
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }) => <ScoreChip numberOfDecimals={1} score={row.original.beta_score} maxScore={10} minScore={0} />,
        sortingFn: betaScoreSorting
      },
      {
        accessorKey: "description",
        header: () => "Description",
        size: 180,
        cell: ({ row }) => (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.node_description}</span>
          </div>
        ),
        meta: {
          group: "basic_info",
          displayName: "Description"
        }
      },
      {
        accessorKey: "number_of_companies",
        header: (header) => (
          <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            <h1 className="text-colors-text-text-tertiary-(600) pl-2">Number of Companies</h1>
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }) => <p>{row.original.number_of_companies}</p>,
        sortingFn: betaScoreSorting
      }
    ];
    const newColumns = [...basicColumns];

    const getAllColumns = () => {
      Object.entries(betaWeights).forEach(([topic, _]) => {
        newColumns.push({
          accessorKey: topic,
          header: (header) => (
            <div className="flex flex-row items-center cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
              <h1 className="text-colors-text-text-tertiary-(600) pl-2">{topic}</h1>
              <SortingDirectionIndicator column={header.column} />
            </div>
          ),
          cell: ({ row }) => (
            <ScoreChip
              numberOfDecimals={1}
              score={row.original.category_scoring?.topic_results.find((result) => result.topic === topic)?.score ?? 0}
              maxScore={10}
              minScore={0}
            />
          ),
          sortingFn: (rowA, rowB) => topicScoreSorting(rowA, rowB, topic)
        });
      });
      newColumns.push({
        accessorKey: "trash",
        size: 50,
        header: ({ table }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                  <Plus size={20} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
                <span className="flex items-center justify-start my-1 p-1">
                  <Notepad className="mr-1" size={22} />
                  <h1 className="font-semi">Basic info</h1>
                </span>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                  .map((column: any) => {
                    // TODO: stop using any
                    return (
                      <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                        <Checkbox
                          key={column.id}
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) => {
                            column.toggleVisibility(!!value);
                            setTimeout(() => {
                              if (tableWindowRef.current) {
                                tableWindowRef.current.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                              }
                            }, 50);
                          }}>
                          {column.id}
                        </Checkbox>

                        <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                      </div>
                    );
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
        enableHiding: false,
        cell: ({ row, table }) => {
          const isRowHovered = table?.options?.meta?.hoveredRow === row.id;
          return isRowHovered ? (
            <div className="flex justify-center items-center">
              <Button
                className="bg-transparent hover:bg-transparent hover:text-red-700 p-0 text-colors-foreground-fg-secondary-(700) "
                onClick={(e) => {
                  // if (row.original.id && row.original._category) {
                  //   onBusinessDelete(row.original.id, row.original._category, row.original._subcategory);
                  // }
                  e.stopPropagation();
                }}>
                <Trash2 size={16} className="trash-icon" strokeWidth={2} />
              </Button>
            </div>
          ) : (
            <div className="min-w-[22px] min-h-[39px]"></div>
          );
        }
      });
    };

    getAllColumns();
    return newColumns;
  }, []);

  return <DataTable columns={columns} data={tableRows} ref={tableWindowRef} />;
};
