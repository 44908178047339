import { CommercialDive, CustomerClass, CustomerGroup, Partner, SalesMotion } from "@/services/autogen";
import { SourcesLogoViewer } from "./SourceViewer";
import { ArrowDownFromLine, ArrowUpFromLine, Building2, Store } from "lucide-react";
import { Acorn, ArrowsLeftRight, Scales, TrendUp, Handshake } from "@phosphor-icons/react";
import { LoadingNullContentsWrapper } from "./LoadingNullContentsWrapper";

export const CustomerClassChip = ({ customerClass }: { customerClass: CustomerClass }) => {
  // TODO: better icons
  switch (customerClass) {
    case CustomerClass.Consumer:
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-lg px-2 py-1 text-colors-yellow-800 flex flex-row items-center gap-1 text-xs`}>
          <Acorn size={14} />
          Consumer
        </div>
      );
    case CustomerClass.Smb:
      return (
        <div
          className={`bg-colors-orange-100 border border-colors-orange-300 rounded-lg px-2 py-1 text-colors-orange-800 flex flex-row items-center gap-1 text-xs`}>
          <Store size={14} /> SMB
        </div>
      );
    case CustomerClass.MidMarket:
      return (
        <div
          className={`bg-colors-teal-100 border border-colors-teal-300 rounded-lg px-2 py-1 text-colors-teal-800 flex flex-row items-center gap-1 text-xs`}>
          <TrendUp size={14} />
          Mid-market
        </div>
      );
    case CustomerClass.Marketplace:
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-lg px-2 py-1 text-colors-blue-800 flex flex-row items-center gap-1 text-xs`}>
          <ArrowsLeftRight size={14} />
          Marketplace
        </div>
      );
    case CustomerClass.LargeEnterprise:
      return (
        <div
          className={`bg-colors-gray-warm-100 border border-colors-gray-warm-300 rounded-lg px-2 py-1 text-colors-gray-warm-800 flex flex-row items-center gap-1 text-xs`}>
          <Building2 size={14} />
          Large Enterprise
        </div>
      );
    case CustomerClass.GovernmentPublicSector:
      return (
        <div
          className={`bg-colors-gray-blue-100 border border-colors-gray-blue-300 rounded-lg px-2 py-1 text-colors-gray-blue-800 flex flex-row items-center gap-1 text-xs`}>
          <Scales size={14} />
          Government/Public Sector
        </div>
      );
    default:
      return null;
  }
};

export const SalesMotionChip = ({ salesMotion }: { salesMotion: SalesMotion }) => {
  // TODO: better icons
  switch (salesMotion) {
    case SalesMotion.BottomUp:
      return (
        <div
          className={`bg-colors-moss-100 border border-colors-moss-300 rounded-lg px-2 py-1 text-colors-moss-800 flex flex-row items-center gap-1 text-xs`}>
          <ArrowUpFromLine size={14} />
          Bottom-up
        </div>
      );
    case SalesMotion.TopDown:
      return (
        <div
          className={`bg-colors-cyan-100 border border-colors-cyan-300 rounded-lg px-2 py-1 text-colors-cyan-800 flex flex-row items-center gap-1 text-xs`}>
          <ArrowDownFromLine size={14} />
          Top-down
        </div>
      );
    case SalesMotion.ChannelSales:
      return (
        <div
          className={`bg-colors-purple-100 border border-colors-purple-300 rounded-lg px-2 py-1 text-colors-purple-800 flex flex-row items-center gap-1 text-xs`}>
          <Handshake size={14} />
          Channel Sales
        </div>
      );
    case SalesMotion.DistributorRetailer:
      return (
        <div
          className={`bg-colors-rosé-100 border border-colors-rosé-300 rounded-lg px-2 py-1 text-colors-rosé-800 flex flex-row items-center gap-1 text-xs`}>
          <Store size={14} />
          Distributor/Retailer
        </div>
      );

    default:
      return null;
  }
};

export const CustomerGroupDisplay = ({ customerGroup, limited }: { customerGroup: CustomerGroup; limited?: boolean }) => {
  return (
    <div className="flex flex-col border p-2 rounded-lg bg-colors-brand-25 border-colors-border-border-brand">
      <div className="text-sm font-semibold text-colors-brand-800">{customerGroup.name}</div>

      {!limited && (
        <>
          <div className="text-sm  text-colors-gray-modern-600">Buyer Personas: </div>
          <ul className="text-sm list-disc pl-5">
            {customerGroup.buyer_personas.map((buyerPersona, index) => (
              <li key={index}>{buyerPersona}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export const PartnerDisplay = ({ partner, limited }: { partner: Partner; limited?: boolean }) => {
  return (
    <div className="flex flex-col gap-2 items-start border border-colors-blue-dark-200 p-1 bg-colors-blue-dark-50 rounded-lg">
      <div className="text-sm text-colors-blue-dark-800">{partner.name}</div>
      {!limited && <div className="text-xs text-colors-gray-modern-500">{partner.relationship}</div>}
    </div>
  );
};

export const CommercialDiveFullDisplay = ({ commercialDive }: { commercialDive: CommercialDive }) => {
  return (
    <div className="flex flex-col bg-white border p-3 rounded-lg space-y-2">
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium gap-2">
        <Handshake size={20} />
        <div>Commercial</div>
      </div>
      <div className="flex flex-row gap-2 flex-wrap items-center">
        <div className="text-sm text-colors-gray-modern-500">Customer Classes: </div>
        {commercialDive.customer_classes.map((customerClass, index) => (
          <CustomerClassChip key={index} customerClass={customerClass} />
        ))}
      </div>

      <div className="flex flex-row gap-2 flex-wrap items-center">
        <div className="text-sm text-colors-gray-modern-500">Sales Motions: </div>
        {commercialDive.sales_motions.map((salesMotion, index) => (
          <SalesMotionChip key={index} salesMotion={salesMotion} />
        ))}
      </div>
      <div className="text-sm text-colors-gray-modern-500">Customer groups: </div>
      <div className="flex flex-row gap-2">
        {commercialDive.customer_groups.map((customerGroup) => (
          <CustomerGroupDisplay key={customerGroup.name} customerGroup={customerGroup} />
        ))}
      </div>
      {commercialDive.customers.length > 0 && (
        <div className="flex flex-row gap-2">
          <div className="text-sm text-colors-gray-modern-500">Known customers: </div>
          <div className="flex flex-row gap-2 items-center flex-wrap font-bold">
            {commercialDive.customers.map((customer, index) => (
              <div key={index} className="text-sm text-colors-gray-modern-500 bg-colors-gray-modern-200 rounded-sm px-1">
                {customer}
              </div>
            ))}
          </div>
        </div>
      )}
      {commercialDive.partners.length > 0 && (
        <div>
          <div className="text-sm text-colors-gray-modern-500">Commercial partners: </div>
          <div className="flex flex-row gap-4">
            {commercialDive.partners.map((partner, index) => (
              <PartnerDisplay key={index} partner={partner} />
            ))}
          </div>
        </div>
      )}

      <SourcesLogoViewer sources={commercialDive.sources} label={true} />
    </div>
  );
};

export const CommercialDiveSummaryContents = ({ commercialDive }: { commercialDive?: CommercialDive }) => {
  if (commercialDive == null) {
    return null;
  }
  return (
    <>
      <div className="flex flex-row gap-2 flex-wrap items-center mb-2">
        <div className="text-sm text-colors-gray-modern-500">Customer Classes: </div>
        {commercialDive.customer_classes.map((customerClass, index) => (
          <CustomerClassChip key={index} customerClass={customerClass} />
        ))}
      </div>

      <div className="flex flex-row gap-2 flex-wrap items-center mb-2">
        <div className="text-sm text-colors-gray-modern-500">Sales Motions: </div>
        {commercialDive.sales_motions.map((salesMotion, index) => (
          <SalesMotionChip key={index} salesMotion={salesMotion} />
        ))}
      </div>

      {commercialDive.customer_groups.length > 0 && (
        <div>
          <div className="text-sm text-colors-gray-modern-500">Customer groups: </div>
          <div className="flex flex-row items-start gap-2 mb-2">
            {commercialDive.customer_groups.map((customerGroup) => (
              <CustomerGroupDisplay key={customerGroup.name} customerGroup={customerGroup} limited={true} />
            ))}
          </div>
        </div>
      )}
      {commercialDive.customers.length > 0 && (
        <div className="flex flex-row gap-2 mb-2">
          <div className="text-sm text-colors-gray-modern-500">Known customers: </div>
          <div className="flex flex-row gap-2 items-center flex-wrap font-bold">
            {commercialDive.customers.map((customer, index) => (
              <div key={index} className="text-sm text-colors-gray-modern-500 bg-colors-gray-modern-200 rounded-sm px-1">
                {customer}
              </div>
            ))}
          </div>
        </div>
      )}
      {commercialDive.partners.length > 0 && (
        <div>
          <div className="text-sm text-colors-gray-modern-500">Commercial partners: </div>
          <div className="flex flex-row gap-2 items-center flex-wrap">
            {commercialDive.partners.map((partner, index) => (
              <PartnerDisplay key={index} partner={partner} limited={true} />
            ))}
          </div>
        </div>
      )}
      {/* <div>
        {commercialDive.review_quotes.map((reviewQuote, index) => (
          <div key={index} className="text-colors-gray-modern-500 text-xs italic border rounded-md p-2 px-3 bg-colors-gray-modern-50">
            {`"${reviewQuote.text}"`}{" "}
            <a href={reviewQuote.source.link} className="text-blue-600 underline">
              [Source]
            </a>
          </div>
        ))}
      </div> */}
    </>
  );
};

export const CommercialDiveSummaryDisplay = ({
  commercialDive,
  onDoubleClick,
  loading
}: {
  commercialDive?: CommercialDive;
  onDoubleClick: () => void;
  loading: boolean;
}) => {
  return (
    <div className="flex flex-col bg-white border p-3 rounded-lg cursor-pointer" onDoubleClick={onDoubleClick}>
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <Handshake size={20} />
        <div>Commercial</div>
      </div>
      <LoadingNullContentsWrapper loading={loading} objectIsNull={commercialDive == null}>
        <CommercialDiveSummaryContents commercialDive={commercialDive} />
      </LoadingNullContentsWrapper>
    </div>
  );
};
