import { SearchRunningStatus, SemanticFieldType } from "@/services/autogen";

export const COMPANY_SEARCH_RUNNING_STATUSES: (SearchRunningStatus | undefined)[] = [
  SearchRunningStatus.NotStarted,
  SearchRunningStatus.Searching,
  SearchRunningStatus.Qualifying,
  SearchRunningStatus.Scoring
];

export const SEMANTIC_FIELD_TYPE_DISPLAY_TEXT: Record<SemanticFieldType, string> = {
  [SemanticFieldType.BusinessModel]: "Business model (What is it?)",
  [SemanticFieldType.MainOfferings]: "Main offerings (What does it sell?)",
  [SemanticFieldType.CustomerSegments]: "Customer segments (Who is it for?)",
  [SemanticFieldType.Geographies]: "Geographies (Where is it sold?)",
  [SemanticFieldType.Technologies]: "Tech innovations (What does it innovate on?)"
};

export const SEMANTIC_FIELD_TYPE_PLACEHOLDER_TEXT: Record<SemanticFieldType, string> = {
  [SemanticFieldType.BusinessModel]: "e.g. 'peer-to-peer furniture marketplace' or 'applicant tracking system'",
  [SemanticFieldType.MainOfferings]: "e.g. 'lightweight CRM software', 'voice AI API'",
  [SemanticFieldType.CustomerSegments]: "e.g. 'financial services firms', or 'e-commerce companies'",
  [SemanticFieldType.Geographies]: "e.g. 'focus in Americas and Europe'",
  [SemanticFieldType.Technologies]: "e.g. 'generative AI', 'flow batteries', or 'real-time collaboration'"
};

export enum TimeFrame {
  OneWeek = "1 week",
  TwoWeeks = "2 weeks",
  OneMonth = "1 month",
  Quarter = "quarter"
}
