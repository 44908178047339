import { ExportFormat, FrontendFullSearch, FrontendScoredCompany, FrontendSearchInstructions } from "@/services/autogen";

import { useState, useRef, useEffect } from "react";

import { Ranking, Sparkle } from "@phosphor-icons/react";
import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { COMPANY_SEARCH_RUNNING_STATUSES } from "@/lib/constants";
import { Drawer, DrawerContent } from "../ui/drawer";
import { ScoredCompanyTable } from "./ScoredCompanyTable";

import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import { expandCompanySearchThunk, updateCompanySearchInstructions } from "@/store/companySearches";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { toast } from "sonner";
import posthog from "posthog-js";
import { exportCompanySearch } from "@/services/brain-api.service";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";
import { ExtensibleVerticalCompanyProfile } from "../company-profile-v2/vertical/ExtensibleVerticalCompanyProfile";
import { FindContactsDropdown } from "../company-lists/FindContactsDropdown";
import { PushToCrmDialog } from "../company-lists/PushToCrmDialog";
import { ExportListButton } from "../company-table/ExportListButton";
import { Plus } from "lucide-react";
import { AddCompaniesToListDropdown } from "./AddCompaniesToListDropdown";
import { demoFeaturesOn } from "@/utils/config";
const ScoringWeightPlusMinus = ({
  label,
  weight,
  setWeight
}: {
  label: string;
  weight: number;
  setWeight: (newWeight: number) => void;
}) => {
  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <div className="text-sm truncate max-w-[200px]">{label}</div>
      <div className="flex flex-row items-center gap-2">
        <div className="text-sm font-medium w-8 text-center">{weight}</div>
        <Button
          variant="outline"
          disabled={weight <= 0}
          size="sm"
          className="h-8 w-8 p-0"
          onClick={() => {
            setWeight(weight - 1);
          }}>
          -
        </Button>
        <Button
          variant="outline"
          size="sm"
          disabled={weight >= 5}
          className="h-8 w-8 p-0"
          onClick={() => {
            setWeight(weight + 1);
          }}>
          +
        </Button>
      </div>
    </div>
  );
};

const EditScoringWeightsButton = ({
  searchId,
  instructions,
  setInstructions,
  disablePersist
}: {
  searchId: string;
  instructions: FrontendSearchInstructions;
  setInstructions: (newWeights: FrontendSearchInstructions) => void;
  disablePersist?: boolean;
}) => {
  const token = useAppSelector((state) => state.user.token);
  const saveTimeoutRef = useRef<NodeJS.Timeout>();

  const handleWeightChange = (newInstructions: FrontendSearchInstructions) => {
    setInstructions(newInstructions);

    // Clear any existing timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Debounce the save operation
    saveTimeoutRef.current = setTimeout(async () => {
      posthog.capture("user_saved_scoring_weights", { search_id: searchId });
      // TODO: re-enable persisting weights
      if (token && !disablePersist) {
        // await deprPersistCompanySearchScoringInstructions(token, searchId, newInstructions);
      }
    }, 1000); // Wait 1 second after last change before saving
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm">
          <Ranking size={20} className="" />
          Edit weights
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col">
        <div className="flex flex-col gap-2 p-2">
          <span className="text-sm font-semibold text-colors-brand-700">Edit scoring weights</span>
          {/* General scoring categories */}
          <ScoringWeightPlusMinus
            label="Hype Score"
            weight={instructions.hype_score_weight ?? 0}
            setWeight={(newWeight) => {
              handleWeightChange({
                ...instructions,
                hype_score_weight: newWeight
              });
            }}
          />
          <ScoringWeightPlusMinus
            label="Growth Score"
            weight={instructions.growth_score_weight ?? 0}
            setWeight={(newWeight) => {
              handleWeightChange({
                ...instructions,
                growth_score_weight: newWeight
              });
            }}
          />
          {instructions.tech_detections && (
            <ScoringWeightPlusMinus
              label="Tech stack"
              weight={instructions.tech_detections.weight}
              setWeight={(newWeight) => {
                handleWeightChange({
                  ...instructions,
                  tech_detections: { ...instructions.tech_detections!, weight: newWeight }
                });
              }}
            />
          )}
          {instructions.employee_roles && (
            <ScoringWeightPlusMinus
              label="Employee roles"
              weight={instructions.employee_roles.weight}
              setWeight={(newWeight) => {
                handleWeightChange({
                  ...instructions,
                  employee_roles: { ...instructions.employee_roles!, weight: newWeight }
                });
              }}
            />
          )}
          {instructions.job_listings && (
            <ScoringWeightPlusMinus
              label="Job listings"
              weight={instructions.job_listings.weight}
              setWeight={(newWeight) => {
                handleWeightChange({
                  ...instructions,
                  job_listings: { ...instructions.job_listings!, weight: newWeight }
                });
              }}
            />
          )}

          {/* Existing scoring questions */}
          {instructions.scoring_questions.map((question, index) => (
            <ScoringWeightPlusMinus
              key={index}
              label={question.question}
              weight={question.preference.weight}
              setWeight={(newWeight) => {
                const newQuestions = [...instructions.scoring_questions];
                newQuestions[index] = {
                  ...question,
                  preference: { ...question.preference, weight: newWeight }
                };
                handleWeightChange({ ...instructions, scoring_questions: newQuestions });
              }}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const ExampleChip = ({ title, subtitle, description, link }: { title: string; subtitle?: string; description?: string; link?: string }) => {
  return (
    <div className="inline-flex gap-2 items-center bg-colors-blue-dark-50 border-colors-blue-dark-400 text-sm p-1 border rounded-lg">
      <div>
        <div className="flex flex-row justify-between">
          <div>
            {link ? (
              <a href={link} className="text-colors-blue-dark-800 font-semibold text-sm hover:underline">
                {title}
              </a>
            ) : (
              <span className="text-colors-blue-dark-800 font-semibold text-sm">{title}</span>
            )}
            <span className="text-colors-blue-dark-600 font-light text-sm">{subtitle}</span>
          </div>
        </div>
        <div className="text-colors-text-text-tertiary-(600)">{description}</div>
      </div>
    </div>
  );
};

const CompanyProfileScoringBox = ({
  scoredCompany,
  instructions,
  maxOverallScore,
  minOverallScore
}: {
  scoredCompany: FrontendScoredCompany;
  instructions: FrontendSearchInstructions;
  maxOverallScore: number;
  minOverallScore: number;
}) => {
  const scoringResults = scoredCompany.scoring_answers;
  return (
    <div className="flex flex-col gap-2 border border-colors-brand-200 rounded-lg p-3">
      <div className="text-colors-brand-700 font-semibold flex flex-row items-center gap-2">
        <Sparkle size={20} />
        Scoring
        {scoredCompany.overall_score != null && (
          <span className="font-normal text-sm">
            <ScoreChip score={scoredCompany.overall_score} numberOfDecimals={1} minScore={minOverallScore} maxScore={maxOverallScore} />
          </span>
        )}
      </div>
      {scoredCompany.tech_stack_answer != null && instructions.tech_detections != null && (
        <div>
          <div className="text-colors-brand-700 font-normal">
            <span className="text-colors-gray-modern-400">Tech stack:</span>{" "}
            <span className="">{instructions.tech_detections.high_standard}</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <ScoreChip score={scoredCompany.tech_stack_answer.score} numberOfDecimals={0} minScore={0} maxScore={5} />
            <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{scoredCompany.tech_stack_answer.explanation?.text}</div>
          </div>
          <div className="flex flex-row gap-2 flex-wrap mt-2">
            {scoredCompany.tech_stack_answer.technologies_detected?.map((detection, index) => (
              <ExampleChip key={index} title={detection} />
            ))}
          </div>
        </div>
      )}
      {scoredCompany.employee_roles_answer != null && instructions.employee_roles != null && (
        <div>
          <div className="text-colors-brand-700 font-normal">
            <span className="text-colors-gray-modern-400">Employee roles:</span>{" "}
            <span className="">{instructions.employee_roles.high_standard}</span>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <ScoreChip score={scoredCompany.employee_roles_answer.score} numberOfDecimals={0} minScore={0} maxScore={5} />
              <div className="text-colors-brand-500 font-light text-sm pl-2">{scoredCompany.employee_roles_answer.explanation?.text}</div>
            </div>
            <div className="flex flex-row gap-2 flex-wrap mt-2">
              {scoredCompany.employee_roles_answer.roles_detected?.map((role, index) => {
                return role.title && <ExampleChip key={index} title={role.title} link={role.linkedin_url} />;
              })}
            </div>
          </div>
        </div>
      )}
      {scoredCompany.hiring_activity_answer != null && instructions.job_listings != null && (
        <div>
          <div className="text-colors-brand-700 font-normal">
            <span className="text-colors-gray-modern-400">Job listings:</span>{" "}
            <span className="">{instructions.job_listings.high_standard}</span>
          </div>
          <div className="flex flex-row gap-2 items-center pt-2">
            <ScoreChip score={scoredCompany.hiring_activity_answer.score} numberOfDecimals={0} minScore={0} maxScore={5} />
            {scoredCompany.hiring_activity_answer.matching_jobs.length > 0 ? (
              <div className="text-colors-brand-500 font-light text-sm pl-2 space-y-2">
                {scoredCompany.hiring_activity_answer.matching_jobs.slice(0, 4).map((job, index) => (
                  <ExampleChip key={index} title={job.title} subtitle={job.type} description={job.description} link={job.listing_link} />
                ))}
              </div>
            ) : (
              <div className="text-colors-brand-500 font-light text-sm pl-2 ">No matching jobs found</div>
            )}
          </div>
        </div>
      )}
      {scoringResults.map((answer, index) => {
        if (answer.explanation != null && answer.question.length > 0) {
          return (
            <div key={index}>
              <div className="text-colors-brand-700 font-normal">{answer.question}</div>
              <div className="flex flex-row gap-2 items-center">
                <ScoreChip score={answer.score} numberOfDecimals={0} minScore={0} maxScore={5} />
                <div className="text-colors-brand-500 font-light text-sm pl-2 pt-2">{answer.explanation?.text}</div>
                <div className="text-colors-brand-500 font-light space-y-1 text-sm pl-2 pt-2">
                  {answer.explanation?.sources.map((source, i) => (
                    <a
                      key={i}
                      href={"https://" + source.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center justify-center w-6 h-6 text-xs font-medium rounded-full bg-colors-brand-100 text-colors-brand-700 hover:bg-colors-brand-200 mr-3">
                      {i + 1}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

const ExpandSearchButton = ({ search, disabled }: { search: FrontendFullSearch; disabled?: boolean }) => {
  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();

  const handleExpand = (volume: number) => {
    posthog.capture("user_expanded_company_search_results", { search_id: search.search_id, amount: volume });
    if (token) {
      dispatch(expandCompanySearchThunk({ token, search_id: search.search_id, expand_by: volume }));
    }
  };
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          disabled={
            disabled
            // search.results?.pipeline_status === FrontendCompanySearchPipelineStatus.ExhaustedFreshUniverse ||
            // search.results?.pipeline_status === FrontendCompanySearchPipelineStatus.NoUniverseAvailable
          }>
          <Plus size={20} /> Get more companies
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-2 p-2">
        {demoFeaturesOn && (
          <Button
            variant="outline"
            size="sm"
            className="w-full"
            onClick={() => {
              handleExpand(10);
            }}>
            + 10 more
          </Button>
        )}
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            handleExpand(50);
          }}>
          + 50 more
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            handleExpand(100);
          }}>
          + 100 more
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            handleExpand(200);
          }}>
          + 200 more
        </Button>
      </PopoverContent>
    </Popover>
  );
};

const computeOverallScore = (scoredCompany: FrontendScoredCompany, instructions: FrontendSearchInstructions): number => {
  let totalWeight = 0;
  let totalScore = 0;
  if (instructions.hype_score_weight != null) {
    totalWeight += instructions.hype_score_weight;
    totalScore += scoredCompany.company.hype_rating * instructions.hype_score_weight;
  }
  if (instructions.growth_score_weight != null) {
    totalWeight += instructions.growth_score_weight;
    totalScore += (scoredCompany.company.growth_score ?? 0) * instructions.growth_score_weight;
  }
  if (instructions.tech_detections?.weight != null) {
    totalWeight += instructions.tech_detections.weight;
    totalScore += (scoredCompany.tech_stack_answer?.score ?? 0) * instructions.tech_detections.weight;
  }
  if (instructions.employee_roles?.weight != null) {
    totalWeight += instructions.employee_roles.weight;
    totalScore += (scoredCompany.employee_roles_answer?.score ?? 0) * instructions.employee_roles.weight;
  }
  if (instructions.job_listings?.weight != null) {
    totalWeight += instructions.job_listings.weight;
    totalScore += (scoredCompany.hiring_activity_answer?.score ?? 0) * instructions.job_listings.weight;
  }
  let totalCustomWeight = 0;
  let totalCustomScore = 0;
  const scoringQuestions = instructions.scoring_questions;
  // TODO: pretty bad way we're indexing to find corresponding answer
  scoringQuestions.forEach((question) => {
    const answer = scoredCompany.scoring_answers.find((result) => result.question === question.question);
    totalCustomScore += (answer?.score ?? 0) * question.preference.weight;
    totalCustomWeight += question.preference.weight;
  });
  return (totalScore + totalCustomScore) / (totalWeight + totalCustomWeight);
};

export const DisqualifiedCompanyViewer = ({
  companies,
  instructions,
  viewOnly
}: {
  companies: FrontendScoredCompany[];
  instructions: FrontendSearchInstructions;
  viewOnly?: boolean;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerCompany, setDrawerCompany] = useState<FrontendScoredCompany | undefined>(undefined);
  const handleCompanyClick = (domain: string) => {
    setIsDrawerOpen(true);
    const company = companies.find((c) => c.company.domain === domain);
    setDrawerCompany(company);
  };
  return (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
      <ScoredCompanyTable
        companies={companies}
        instructions={instructions}
        selectedDomains={[]}
        onSelectedDomainsChange={() => {}}
        onCompanyClick={handleCompanyClick}
        onRowDelete={() => {}}
        disableRowSelection={true}
        enableCrmStatusColumn={true}
      />
      <DrawerContent direction="right" className="max-w-[600px] py-8">
        <ScrollArea orientation="vertical" className="w-full h-full px-8">
          <ExtensibleVerticalCompanyProfile company={drawerCompany?.company} shareableView={viewOnly}>
            {drawerCompany != null && (
              // TODO: disable the overall score because we don't have it here
              <CompanyProfileScoringBox scoredCompany={drawerCompany} instructions={instructions} maxOverallScore={0} minOverallScore={0} />
            )}
          </ExtensibleVerticalCompanyProfile>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
};

export const CompanySearchResultsViewer = React.memo(function CompanySearchResultsViewer({
  search,
  viewOnly
}: {
  search: FrontendFullSearch;
  viewOnly?: boolean;
}) {
  const token = useAppSelector((state) => state.user.token);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);

  const searchStartingToRunHack = useAppSelector((state) => state.deprCompanySearches.searchesStartingToRunHack.includes(search.search_id));
  const isRunning = COMPANY_SEARCH_RUNNING_STATUSES.includes(search.running_status) || searchStartingToRunHack;
  // Overwriting the overall score with any new weights
  const qualifiedCompanies = search.qualified_companies?.map((company) => ({
    ...company,
    overall_score: computeOverallScore(company, search.instructions)
  }));
  const maxOverallScore = Math.max(...(qualifiedCompanies?.map((company) => company.overall_score ?? 0) ?? [0]));
  const minOverallScore = Math.min(...(qualifiedCompanies?.map((company) => company.overall_score ?? 0) ?? [0]));
  const dispatch = useAppDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerCompany, setDrawerCompany] = useState<FrontendScoredCompany | undefined>(undefined);
  useEffect(() => {
    setSelectedDomains([]);
  }, [search.search_id]);

  const handleCompanyClick = (domain: string) => {
    setIsDrawerOpen(true);
    const company = qualifiedCompanies?.find((c) => c.company.domain === domain);
    setDrawerCompany(company);
  };
  const enableCrmStatusColumn =
    qualifiedCompanies != null && qualifiedCompanies.length > 0 && qualifiedCompanies[0].company.crm_status != null;
  return (
    <div className="flex flex-col h-full w-full gap-4">
      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
        <div className="flex flex-col h-full w-full gap-4">
          {!isRunning && (
            <div className="flex flex-row items-center w-full gap-2 text-sm">
              {selectedDomains.length > 0 && (
                <div className="flex flex-row items-center gap-2 bg-colors-gray-modern-50 rounded-lg px-3 py-1.5 text-sm">
                  <div className="flex flex-row items-center gap-1">
                    <div className="text-colors-brand-600 font-medium">{selectedDomains.length}</div>
                    <div className="text-colors-gray-modern-600">selected</div>
                  </div>
                </div>
              )}
              <ExpandSearchButton search={search} disabled={viewOnly} />

              <EditScoringWeightsButton
                searchId={search.search_id}
                instructions={search.instructions}
                setInstructions={(newInstructions) => {
                  dispatch(updateCompanySearchInstructions({ search_id: search.search_id, instructions: newInstructions }));
                }}
                disablePersist={viewOnly}
              />

              <AddCompaniesToListDropdown
                domainsSelected={selectedDomains}
                companySearch={search}
                workflowTitle={search.title}
                disabled={viewOnly}
              />
              <FindContactsDropdown domainsSelected={selectedDomains} disabled={viewOnly} />
              {enableCrmStatusColumn && <PushToCrmDialog domainsSelected={selectedDomains} disabled={viewOnly} />}
              <div className="flex-1" />
              <ExportListButton<string>
                selection={selectedDomains}
                disabled={viewOnly}
                formatsEnabled={[ExportFormat.Csv, ExportFormat.Excel]} // ExportFormat.Excel,
                exportCallback={async (selection, format) => {
                  if (token) {
                    toast.info("Your download is starting!");
                    posthog.capture("user_exported_company_search_results", { search_id: search.search_id, type: format });
                    return exportCompanySearch(token, search.search_id, format, selection);
                  }
                }}
              />
            </div>
          )}

          {qualifiedCompanies != null && (
            <ScoredCompanyTable
              companies={qualifiedCompanies}
              instructions={search.instructions}
              selectedDomains={selectedDomains}
              onSelectedDomainsChange={setSelectedDomains}
              onCompanyClick={handleCompanyClick}
              enableCrmStatusColumn={enableCrmStatusColumn}
            />
          )}
        </div>
        <DrawerContent direction="right" className="max-w-[500px] py-4">
          <ScrollArea orientation="vertical" className="w-full h-full px-5">
            <ExtensibleVerticalCompanyProfile company={drawerCompany?.company} shareableView={viewOnly}>
              {drawerCompany != null && (
                <CompanyProfileScoringBox
                  scoredCompany={drawerCompany}
                  instructions={search.instructions}
                  maxOverallScore={maxOverallScore}
                  minOverallScore={minOverallScore}
                />
              )}
            </ExtensibleVerticalCompanyProfile>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
    </div>
  );
});
