import {
  Configuration,
  WorkflowFeedback,
  GenericV1ApiFactory,
  GenericWorkflow,
  FindCompaniesV2InitialInputs,
  FindCompaniesV2ApiFactory,
  SourceCompaniesInputs,
  SourceCompaniesApiFactory,
  CompanyApiFactory,
  UserApiFactory,
  ImportableSearchScope,
  CompanyListsApiFactory,
  CompanyList,
  DemoApiFactory,
  UsageApiFactory,
  BillingApiFactory,
  DocumentsApiFactory,
  IngestedCompaniesApiFactory,
  TaxonomyApiFactory,
  TearsheetEnrichmentType,
  EnrichmentApiFactory,
  RubricApiFactory,
  Rubric,
  ScoredCompaniesRequest,
  ScoringApiFactory,
  PeopleListsApiFactory,
  PeopleList,
  CompanySearchesApiFactory,
  FrontendCompanySearchInstructions,
  FrontendCompanySearchInputs,
  MeticulateSubscriptionType,
  FrontendCompanySearchResults,
  FrontendCompanySearchParameters,
  FrontendCompanySearchScoringInstructions,
  FrontendPerson,
  ExportFormat,
  CrmApiFactory,
  FrontendSearchInstructions
} from "./autogen";

import { brainServerPath } from "@/utils/config";

export const config: Configuration = new Configuration({
  basePath: brainServerPath,
  baseOptions: {
    headers: {
      "Content-Type": "application/json"
    }
  }
});

export function runGenericWorkflow(token: string, workflow: GenericWorkflow) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1RunGenericWorkflowPost(workflow);
}

export function refineGenericWorkflow(token: string, workflow: GenericWorkflow) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1RefineGenericWorkflowPost(workflow);
}

export function fetchAllWorkflowStubs(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchWorkflowStubsPost();
}

export function fetchAllGenericWorkflows(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchAllGenericWorkflowsPost();
}

export function deleteGenericWorkflow(token: string, workflow_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1DeleteGenericWorkflowPost({ workflow_id });
}

export function fetchGenericWorkflow(workflow_id: string) {
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchGenericWorkflowPost({ workflow_id });
}

export function giveGenericWorkflowFeedback(token: string, workflow_id: string, feedback: WorkflowFeedback) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1GiveGenericWorkflowFeedbackPost({ workflow_id, feedback });
}

export function fetchLibraryGenericWorkflows() {
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchLibraryGenericWorkflowsPost();
}

export function fetchLibraryGenericWorkflowStubs() {
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchLibraryGenericWorkflowStubsPost();
}

export function fetchUserRunsAvailable(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UsageApiFactory(config);
  return brainApi.brainV1UserRunsAvailablePost();
}

export function createCheckoutSession(token: string, subscriptionType: MeticulateSubscriptionType, isYearly: boolean) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = BillingApiFactory(config);
  return brainApi.brainV1CreateCheckoutSessionPost({ subscription_type: subscriptionType, is_yearly: isYearly });
}

export function checkActiveSubscription(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = BillingApiFactory(config);
  return brainApi.brainV1CheckActiveSubscriptionPost();
}

export function createCustomerPortalSession(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = BillingApiFactory(config);
  return brainApi.brainV1CreateCustomerPortalSessionPost();
}

export function exportWorkflowToExcel(token: string, workflow: GenericWorkflow) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(newConfig);
  return brainApi.brainV1ExportWorkflowToExcelPost(workflow);
}

export function exportWorkflowToPpt(token: string, workflow: GenericWorkflow) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(newConfig);
  return brainApi.brainV1ExportWorkflowToPptPost(workflow);
}

export function updateGenericWorkflow(token: string, workflow: GenericWorkflow) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1UpdateGenericWorkflowPost(workflow);
}

export function markSearchViewed(token: string, search_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1MarkSearchViewedPost({ search_id: search_id });
}

export function findCompaniesInitialInputsToInputs(token: string, initial_inputs: FindCompaniesV2InitialInputs) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = FindCompaniesV2ApiFactory(config);
  return brainApi.brainV1FindCompaniesInitialInputsToInputsPost(initial_inputs);
}

export function addSimilarCompaniesToFindCompaniesWorkflow(token: string, workflow_id: string, company_domain: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = FindCompaniesV2ApiFactory(config);
  return brainApi.brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost({ workflow_id, company_domain });
}

export function sourceCompaniesInitialInputsToInputs(token: string, initial_inputs: SourceCompaniesInputs) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = SourceCompaniesApiFactory(config);
  return brainApi.brainV1SourceCompaniesInitialInputsToInputsPost(initial_inputs);
}

export function getCompanyProfile(token: string, company_domain: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyApiFactory(config);
  return brainApi.brainV1GetCompanyProfilePost({ company_domain });
}

export function getUserDefaultScope(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1GetUserDefaultScopePost();
}

export function setUserDefaultScope(token: string, scope: ImportableSearchScope) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1SetUserDefaultScopePost({ scope });
}

// company lists
export function getCompanyLists(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1GetCompanyListsPost();
}

export function getShareableCompanyList(company_list_id: string) {
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1GetShareableCompanyListPost({ company_list_id });
}

export function updateCompanyList(token: string, companyList: CompanyList) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1UpdateCompanyListPost(companyList);
}

export function addCompaniesFromMarketMapToCompanyList(token: string, company_list_id: string, market_map_image_base64_encoded: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1AddCompaniesFromMarketMapToCompanyListPost({ company_list_id, market_map_image_base64_encoded });
}

export function deleteCompanyList(token: string, company_list_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1DeleteCompanyListPost({ company_list_id });
}

// people lists

export function addPeopleToPeopleListByLinkedinUrls(token: string, people_list_id: string, linkedin_urls: string[]) {
  console.log("adding people to people list", people_list_id, linkedin_urls);
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1AddToPeopleListByLinkedinUrlsPost({ people_list_id, linkedin_urls });
}

export function getPeopleLists(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1GetPeopleListsPost();
}

export function getPeopleListsStubs(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1GetPeopleListStubsPost();
}

export function getPeopleList(token: string, people_list_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1GetPeopleListPost({ people_list_id });
}

export function getShareablePeopleList(people_list_id: string) {
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1GetShareablePeopleListPost({ people_list_id });
}

export function deletePeopleList(token: string, people_list_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1DeletePeopleListPost({ people_list_id });
}

export function updatePeopleList(token: string, people_list: PeopleList) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1UpdatePeopleListPost(people_list);
}

export function exportPeopleList(token: string, people_list: PeopleList, export_format: ExportFormat, selected_ids?: string[]) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(newConfig);
  return brainApi.brainV1ExportPeopleListPost({ people_list, export_format, selected_ids });
}

export function duplicatePeopleList(token: string, people_list_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1DuplicatePeopleListPost({ people_list_id });
}

export function findSimilarCompaniesLight(company_domain: string) {
  const brainApi = DemoApiFactory(config);
  return brainApi.brainV1FindSimilarCompaniesLightPost({ company_domain });
}

export function getInternalDocuments(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = DocumentsApiFactory(config);
  return brainApi.brainV1GetInternalDocumentsPost();
}

export function getIngestedCompanies(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = IngestedCompaniesApiFactory(config);
  return brainApi.brainV1GetIngestedCompaniesPost();
}

export function getDocumentPdf(token: string, document_id: string) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = DocumentsApiFactory(newConfig);
  return brainApi.brainV1GetDocumentPdfPost({ document_id });
}

export function ingestDocument(token: string, document_src: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = DocumentsApiFactory(config);
  return brainApi.brainV1IngestDocumentPost({ document_base64_encoded: document_src });
}

export function updateLastViewedCompanyIds(token: string, company_ids: string[]) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1UpdateLastViewedCompanyIdsPost({ company_ids });
}

export function getLastViewedCompanyIds(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1GetLastViewedCompanyIdsPost();
}

export function getTaxonomy(taxonomy_id: string) {
  // config.baseOptions.headers = {
  //   ...config.baseOptions.headers,
  //   Authorization: `Bearer ${token}`
  // };
  const brainApi = TaxonomyApiFactory(config);
  return brainApi.brainV1GetTaxonomyPost({ taxonomy_id });
}

export function getScoredTaxonomy(taxonomy_id: string) {
  const brainApi = TaxonomyApiFactory(config);
  return brainApi.brainV1GetScoredTaxonomyPost({ taxonomy_id });
}

export function getTearsheetEnrichment(token: string, company_domain: string, enrichment_type: TearsheetEnrichmentType) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = EnrichmentApiFactory(config);
  return brainApi.brainV1GetTearsheetEnrichmentPost({ company_domain, enrichment_type });
}

export function getEnrichmentsAdminInfo(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = EnrichmentApiFactory(config);
  return brainApi.brainV1GetEnrichmentsAdminInfoPost();
}

export function ingestCompany(token: string, company_domain: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = IngestedCompaniesApiFactory(config);
  return brainApi.brainV1IngestCompanyPost({ company_domain });
}

export function getRubric(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = RubricApiFactory(config);
  return brainApi.brainV1GetRubricPost();
}

export function updateRubric(token: string, rubric: Rubric) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = RubricApiFactory(config);
  return brainApi.brainV1UpdateRubricPost(rubric);
}

export function getScoredCompanies(token: string, request: ScoredCompaniesRequest) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = ScoringApiFactory(config);
  return brainApi.brainV1GetScoredCompaniesPost({ request });
}

export function getCompanyContacts(
  token: string,
  company_domains: string[],
  persona_description: string,
  limit_contacts_per_company?: number
) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1GetCompanyContactsPost({ company_domains, persona_description, limit_contacts_per_company });
}

export function enrichPhoneNumbers(token: string, people_list_id: string, people: FrontendPerson[]) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1GetPhoneNumbersPost({ people_list_id, people });
}

export function exportCompanyList(token: string, companyList: CompanyList, export_format: "excel" | "csv", selected_domains?: string[]) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(newConfig);
  return brainApi.brainV1ExportCompanyListPost({ company_list: companyList, export_format, selected_domains });
}

export function exportCompanySearchResults(
  token: string,
  search_results: FrontendCompanySearchResults,
  export_format: ExportFormat,
  selected_domains?: string[]
) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(newConfig);
  return brainApi.brainV1ExportCompanySearchResultsPost({ search_results, export_format, selected_domains });
}

export function getUserContactCreditsAvailable(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UsageApiFactory(config);
  return brainApi.brainV1UserContactCreditsAvailablePost();
}

export function addCompaniesToCompanyList(token: string, company_list_id: string, company_domains: string[]) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1AddCompaniesToCompanyListPost({ company_list_id, company_domains });
}

export function deprGetCompanySearchStubs(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesGetStubsPost();
}

export function deprDeleteCompanySearch(token: string, search_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesDeleteSearchPost({ search_id });
}

export function deprGetCompanySearch(token: string, search_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesGetSearchPost({ search_id });
}

// No token needed
export function deprGetShareableCompanySearch(search_id: string) {
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesGetShareableSearchPost({ search_id });
}

export function deprGetCompanySearches(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesGetAllSearchesPost();
}

export function deprRunCompanySearch(token: string, instructions: FrontendCompanySearchInstructions) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesRunSearchPost(instructions);
}

export function deprRunCompanySearchFromInputs(token: string, inputs: FrontendCompanySearchInputs) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesRunSearchFromInputsPost(inputs);
}

export function deprRerunCompanySearch(token: string, search_id: string, instructions: FrontendCompanySearchInstructions) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesRerunSearchPost({ search_id, instructions });
}

export function deprExpandCompanySearch(token: string, search_id: string, expand_by: number) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesExpandSearchPost({ search_id, expand_by });
}

export function deprCompanySearchInputsToInstructions(token: string, inputs: FrontendCompanySearchInputs) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesInputsToInstructionsPost(inputs);
}

export function deprUpdateCompanySearchTitle(token: string, search_id: string, title: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesUpdateTitlePost({ search_id, title });
}

export function deprPersistCompanySearchScoringInstructions(
  token: string,
  search_id: string,
  scoring_instructions: FrontendCompanySearchScoringInstructions
) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesUpdateScoringInstructionsPost({ search_id, scoring_instructions });
}

export function getAllCreditsRemaining(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UsageApiFactory(config);
  return brainApi.brainV1GetAllCreditsRemainingPost();
}

export function getUserDefaultCompanySearchParameters(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1GetUserDefaultCompanySearchParametersPost();
}

export function setUserDefaultCompanySearchParameters(token: string, parameters: FrontendCompanySearchParameters) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1SetUserDefaultCompanySearchParametersPost({ parameters });
}

export function createPeopleListWithCompanyContacts(
  token: string,
  company_domains: string[],
  persona_description: string,
  limit_contacts_per_company?: number
) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1CreatePeopleListWithCompanyContactsPost({ company_domains, persona_description, limit_contacts_per_company });
}

export function addToPeopleListWithCompanyContacts(
  token: string,
  people_list_id: string,
  company_domains: string[],
  persona_description: string,
  limit_contacts_per_company?: number
) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = PeopleListsApiFactory(config);
  return brainApi.brainV1AddToPeopleListWithCompanyContactsPost({
    people_list_id,
    company_domains,
    persona_description,
    limit_contacts_per_company
  });
}

export function pushCompaniesToCrm(token: string, company_domains: string[], owner_id?: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CrmApiFactory(config);
  return brainApi.brainV1PushCompaniesToCrmPost({ company_domains, owner_id });
}

export function pushContactsToCrm(token: string, contacts: FrontendPerson[], owner_id?: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CrmApiFactory(config);
  return brainApi.brainV1PushContactsToCrmPost({ contacts, owner_id });
}

export function getNangoConnectSessionToken(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CrmApiFactory(config);
  return brainApi.brainV1GetNangoConnectSessionTokenPost();
}
// SEARCHES V2
export function descriptionToInstructions(token: string, description: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2DescriptionSearchInputsToInstructionsPost({ description });
}

export function findSimilarToInstructions(token: string, domain: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2FindSimilarSearchInputsToInstructionsPost({ domain });
}

export function runCompanySearch(token: string, instructions: FrontendSearchInstructions) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2RunSearchPost(instructions);
}

// export function rerunCompanySearch(token: string, search_id: string, instructions: FrontendSearchInstructions) {
//   config.baseOptions.headers = {
//     ...config.baseOptions.headers,
//     Authorization: `Bearer ${token}`
//   };
//   const brainApi = CompanySearchesApiFactory(config);
//   return brainApi.brainV1CompanySearchesV2RerunSearchPost({ search_id, instructions });
// }

export function getCompanySearch(token: string, search_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2GetSearchPost({ search_id });
}

export function getCompanySearchStubs(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2GetStubsPost();
}

export function getCompanySearches(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2GetAllSearchesPost();
}

export function updateCompanySearchTitle(token: string, search_id: string, title: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2UpdateTitlePost({ search_id, title });
}

export function expandCompanySearch(token: string, search_id: string, expand_by: number) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2ExpandSearchPost({ search_id, expand_by });
}

export function exportCompanySearch(token: string, search_id: string, export_format: string, selected_domains?: string[]) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(newConfig);
  return brainApi.brainV1CompanySearchesV2ExportSearchPost({ search_id, export_format, selected_domains });
}

export function getShareableCompanySearch(search_id: string) {
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2GetShareableSearchPost({ search_id });
}

export function deleteCompanySearch(token: string, search_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1CompanySearchesV2DeleteSearchPost({ search_id });
}

export function getCrmOwners(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CrmApiFactory(config);
  return brainApi.brainV1CrmGetCrmOwnersPost();
}

export function duplicateCompanySearchForUser(token: string, search_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanySearchesApiFactory(config);
  return brainApi.brainV1DuplicateSearchPost({ search_id });
}
