import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { SimpleTooltip } from "@/components/ui/tooltip";
import { CompLandscapeV7Workflow, MarketMap, MarketMapWorkflow, WorkflowType } from "@/services/autogen";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import { PaperPlaneRight } from "@phosphor-icons/react";
import _ from "lodash";
import { Info, Plus, Trash2 } from "lucide-react";
import posthog from "posthog-js";
import { useState } from "react";
import { toast } from "sonner";
import "animate.css";
import { isValidUrl } from "@/lib/utils";
import { refineGenericWorkflowThunk, runGenericWorkflowThunk, updateInputMarketMap } from "@/store/genericWorkflows";
import { ExemplarChip } from "../workflow-inputs/ExemplarChip";

const marketMapErrorString = (userMarketMap?: MarketMap) => {
  if (userMarketMap == null) {
    return "No market map";
  }
  if (userMarketMap.categories.length < 2) {
    return "You need at least 2 categories";
  }
  // check if all categories have names
  if (userMarketMap.categories.some((category) => category.name === "")) {
    return "All categories need names";
  }
  // check if all subcategories have names
  if (userMarketMap.categories.some((category) => category.subcategories.some((subcategory) => subcategory.name === ""))) {
    return "All subcategories need names";
  }
  return null;
};

export const MarketMapRefine = () => {
  const activeWorkflow = useAppSelector((state) =>
    state.genericWorkflows.workflows.find((wf) => wf.workflow_id === state.genericWorkflows.activeWorkflowId)
  );
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  if (
    activeWorkflow == null ||
    (activeWorkflow.workflow_type !== WorkflowType.MarketMapV2 && activeWorkflow.workflow_type !== WorkflowType.CompLandscapeV7)
  ) {
    return null;
  }
  const compFinderWorkflow = activeWorkflow.workflow as CompLandscapeV7Workflow | MarketMapWorkflow;
  if (compFinderWorkflow.inputs.market_map == null) {
    return null;
  }
  const [notEnoughCategories, setNotEnoughCategories] = useState<boolean>(false);
  const onRun = _.debounce(async () => {
    const errorString = marketMapErrorString(compFinderWorkflow.inputs.market_map);
    if (errorString) {
      setNotEnoughCategories(true);
      toast.error(errorString);
      setTimeout(() => {
        setNotEnoughCategories(false);
      }, 1000);
      return;
    }
    const token = await getToken();
    posthog.capture("user_reran_workflow", {
      type: activeWorkflow.workflow_type,
      inputs: compFinderWorkflow.inputs
    });
    if (token) {
      dispatch(refineGenericWorkflowThunk({ token, workflow: activeWorkflow }));
    } else {
      // const distinctId = posthog.get_distinct_id();
      // dispatch(runGenericWorkflowThunk({ token: distinctId, workflow: activeWorkflow }));
    }
  }, 400);
  return (
    <div>
      <div className="w-full bg-colors-brand-100 p-4 rounded-xl my-4 flex flex-row items-center text-colors-brand-800">
        <Info className="mr-4" size={40} />
        <div>
          <div className="font-semibold text-colors-brand-900">Re-run this search with better categories.</div>
          <div>
            Edit the categories in this market map and we will fill it out for you! You can also add example companies by domain to help us
            find better results.
          </div>
        </div>
        <Button className={`w-40 ml-2 ${notEnoughCategories ? "animate__animated animate__headShake" : ""}`} onClick={onRun}>
          <PaperPlaneRight className="mr-2" size={24} />
          Re-run
        </Button>
      </div>
      <MarketMapBuilder userMarketMap={compFinderWorkflow.inputs.market_map} workflowId={activeWorkflow.workflow_id} />
    </div>
  );
};

export const MarketMapNew = () => {
  console.log("here1");
  const activeWorkflow = useAppSelector((state) =>
    state.genericWorkflows.workflows.find((wf) => wf.workflow_id === state.genericWorkflows.activeWorkflowId)
  );
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const [notEnoughCategories, setNotEnoughCategories] = useState<boolean>(false);
  // TODO: Re-enable this
  // const runsAvailable = useAppSelector((state) => state.user.runsAvailable);
  // const activeSubscription = useAppSelector((state) => state.user.activeSubscription);
  if (activeWorkflow == null || activeWorkflow.workflow_type !== WorkflowType.MarketMapV2) {
    return null;
  }
  const compFinderWorkflow = activeWorkflow.workflow as MarketMapWorkflow;
  const onRun = _.debounce(async () => {
    const errorString = marketMapErrorString(compFinderWorkflow.inputs.market_map);
    if (errorString) {
      setNotEnoughCategories(true);
      toast.error(errorString);
      setTimeout(() => {
        setNotEnoughCategories(false);
      }, 1000);
      return;
    }
    // if (runsAvailable <= 0 && !activeSubscription) {
    //   setNotEnoughCategories(true);
    //   toast.error("You have no runs left this week");
    //   setTimeout(() => {
    //     setNotEnoughCategories(false);
    //   }, 1000);
    //   return;
    // }
    const token = await getToken();
    posthog.capture("user_ran_workflow", {
      type: WorkflowType.MarketMap,
      inputs: compFinderWorkflow.inputs,
      workflow_id: activeWorkflow.workflow_id
    });
    if (token) {
      dispatch(runGenericWorkflowThunk({ token, workflow: activeWorkflow }));
    } else {
      // const distinctId = posthog.get_distinct_id();
      // dispatch(runGenericWorkflowThunk({ token: distinctId, workflow: activeWorkflow }));
    }
  }, 400);
  return (
    <div>
      <div className="w-full bg-colors-brand-100 p-4 rounded-xl my-4 flex flex-row items-center text-colors-brand-800">
        <Info className="mr-4" size={40} />
        <div className="mr-2 flex flex-col">
          <div className="font-semibold text-colors-brand-900">
            Create a market map skeleton and Meticulate will fill it with the right companies!
          </div>
          <div>
            Just add categories and subcategories and hit run. You can also add example companies in each category by domain to help us find
            better results.
          </div>
        </div>
        <Button className={`w-40 ml-2 ${notEnoughCategories ? "animate__animated animate__headShake" : ""}`} onClick={onRun}>
          <PaperPlaneRight className="mr-2" size={24} />
          Run
        </Button>
      </div>
      <MarketMapBuilder userMarketMap={compFinderWorkflow.inputs.market_map} workflowId={activeWorkflow.workflow_id} />
    </div>
  );
};

const MarketMapBuilder = ({ userMarketMap, workflowId }: { userMarketMap: MarketMap | undefined; workflowId: string }) => {
  const dispatch = useAppDispatch();
  const [exemplarInput, setExemplarInput] = useState("");
  const [invalidDomain, setInvalidDomain] = useState(false);
  if (userMarketMap == null) {
    return null;
  }
  const onCategoryNameChange = (categoryIndex: number, newName: string) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    tempObj.categories[categoryIndex].name = newName;
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };
  // const onCategoryDescriptionChange = (categoryIndex: number, newDescription: string) => {
  //   const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
  //   tempObj.categories[categoryIndex].description = newDescription;
  //   dispatch(
  //     updateInputMarketMap({
  //       workflowId: workflowId,
  //       marketMap: tempObj
  //     })
  //   );
  // };
  const addCategory = () => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    tempObj.categories.push({
      name: "",
      description: "",
      subcategories: [],
      businesses: [],
      bespoke_research_topics: [],
      exemplars: []
    });
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };
  const deleteCategory = (categoryIndex: number) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    tempObj.categories.splice(categoryIndex, 1);
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };

  const onSubcategoryNameChange = (categoryIndex: number, subcategoryIndex: number, newName: string) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    tempObj.categories[categoryIndex].subcategories[subcategoryIndex].name = newName;
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };
  // const onSubcategoryDescriptionChange = (categoryIndex: number, subcategoryIndex: number, newDescription: string) => {
  //   const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
  //   tempObj.categories[categoryIndex].subcategories[subcategoryIndex].description = newDescription;
  //   dispatch(
  //     updateInputMarketMap({
  //       workflowId: workflowId,
  //       marketMap: tempObj
  //     })
  //   );
  // };
  const addSubcategory = (categoryIndex: number) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    tempObj.categories[categoryIndex].subcategories.push({
      name: "",
      description: "",
      businesses: [],
      bespoke_research_topics: [],
      exemplars: []
    });
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };
  const deleteSubcategory = (categoryIndex: number, subcategoryIndex: number) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    tempObj.categories[categoryIndex].subcategories.splice(subcategoryIndex, 1);
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };

  const addExemplar = (categoryIndex: number, exemplarDomain: string, subcategoryIndex?: number) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    if (subcategoryIndex !== undefined) {
      tempObj.categories[categoryIndex].subcategories[subcategoryIndex].exemplars.push(exemplarDomain);
    } else {
      tempObj.categories[categoryIndex].exemplars.push(exemplarDomain);
    }
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
    setExemplarInput("");
  };
  const deleteExemplar = (categoryIndex: number, exemplarIndex: number, subcategoryIndex?: number) => {
    const tempObj: MarketMap = JSON.parse(JSON.stringify(userMarketMap));
    if (subcategoryIndex !== undefined) {
      tempObj.categories[categoryIndex].subcategories[subcategoryIndex].exemplars.splice(exemplarIndex, 1);
    } else {
      tempObj.categories[categoryIndex].exemplars.splice(exemplarIndex, 1);
    }
    dispatch(
      updateInputMarketMap({
        workflowId: workflowId,
        marketMap: tempObj
      })
    );
  };
  const addCategoryDisabled = userMarketMap.categories.length >= 4;
  return (
    <div className="flex flex-row gap-5 flex-wrap">
      {userMarketMap.categories.map((category, categoryIndex) => {
        // const addSubcategoryDisabled = category.subcategories.length >= 4 || category.exemplars.length > 0;
        const addSubcategoryDisabled = true;
        const addExemplarDisabled = category.exemplars.length >= 3 || category.subcategories.length > 0;
        return (
          <div key={categoryIndex} className="p-4 pt-4 border-b-2 border-l-2 border-colors-brand-700 min-w-[300px] w-[45%] relative group">
            <Button
              onClick={() => deleteCategory(categoryIndex)}
              className="invisible absolute top-0 right-0 group-hover:visible"
              variant="destructive">
              <Trash2 />
            </Button>
            <AutosizedTextarea
              placeholder="Category Name"
              className="text-lg font-semibold text-colors-brand-700"
              value={category.name}
              onChange={(e) => {
                onCategoryNameChange(categoryIndex, e.target.value);
              }}
            />
            {/* <AutosizedTextarea
              className="pl-8 text-colors-brand-400"
              value={category.description ?? ""}
              placeholder="Category description"
              onChange={(e) => {
                onCategoryDescriptionChange(categoryIndex, e.target.value);
              }}
            /> */}
            <div className="flex flex-col gap-6 mt-6">
              {category.subcategories.map((subcategory, subcategoryIndex) => {
                const addSubcategoryExemplarDisabled = subcategory.exemplars.length >= 3;
                return (
                  <div key={subcategoryIndex} className="p-2 pt-1 border-b border-l border-gray-400 group/subcategory relative">
                    <Button
                      onClick={() => deleteSubcategory(categoryIndex, subcategoryIndex)}
                      className="invisible absolute top-0 right-0 group-hover/subcategory:visible"
                      size="xs"
                      variant="destructive">
                      <Trash2 />
                    </Button>
                    <AutosizedTextarea
                      className="text-colors-gray-modern-700"
                      placeholder="Subcategory Name"
                      value={subcategory.name}
                      onChange={(e) => {
                        onSubcategoryNameChange(categoryIndex, subcategoryIndex, e.target.value);
                      }}
                    />
                    {/* <AutosizedTextarea
                      className="pl-8 text-colors-brand-400"
                      value={subcategory.description ?? ""}
                      placeholder="Subcategory description"
                      onChange={(e) => {
                        onSubcategoryDescriptionChange(categoryIndex, subcategoryIndex, e.target.value);
                      }}
                    /> */}
                    <div className="flex flex-row gap-5 mt-3 flex-wrap">
                      {subcategory.exemplars.map((business, businessIndex) => {
                        return (
                          <div key={businessIndex}>
                            <ExemplarChip
                              domain={business}
                              onDelete={() => deleteExemplar(categoryIndex, businessIndex, subcategoryIndex)}
                            />
                          </div>
                        );
                      })}
                      {!addSubcategoryExemplarDisabled && (
                        <Popover>
                          <SimpleTooltip text="Add an example company by domain">
                            <PopoverTrigger asChild className="invisible group-hover/subcategory:visible">
                              <Button variant="ghost" className="p-2">
                                <Plus className="mr-2" /> Add example company
                              </Button>
                            </PopoverTrigger>
                          </SimpleTooltip>
                          <PopoverContent>
                            <div className={`w-72 flex flex-row ${invalidDomain ? "animate__animated animate__headShake" : ""}`}>
                              <Input
                                placeholder="Company domain"
                                value={exemplarInput}
                                onChange={(e) => setExemplarInput(e.target.value)}
                                className="w-40 mr-5"
                              />
                              <Button
                                onClick={() => {
                                  if (!isValidUrl(exemplarInput)) {
                                    setInvalidDomain(true);
                                    toast.error(
                                      "Not a valid company domain (website). Please enter a domain like 'google.com' or 'facebook.com'"
                                    );
                                    setTimeout(() => {
                                      setInvalidDomain(false);
                                    }, 1000);
                                    return;
                                  }
                                  addExemplar(categoryIndex, exemplarInput, subcategoryIndex);
                                }}>
                                Add
                              </Button>
                            </div>
                          </PopoverContent>
                        </Popover>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row gap-5 mt-3 flex-wrap">
              {category.exemplars.map((business, businessIndex) => {
                return (
                  <div key={businessIndex}>
                    <ExemplarChip domain={business} onDelete={() => deleteExemplar(categoryIndex, businessIndex)} />
                  </div>
                );
              })}
              {!addExemplarDisabled && (
                <Popover>
                  <SimpleTooltip text="Add an example company by domain">
                    <PopoverTrigger asChild className="invisible group-hover:visible self-start">
                      <Button variant="ghost" className="p-2">
                        <Plus className="mr-2" /> Add example company
                      </Button>
                    </PopoverTrigger>
                  </SimpleTooltip>
                  <PopoverContent>
                    <div className={`w-72 flex flex-row ${invalidDomain ? "animate__animated animate__headShake" : ""}`}>
                      <Input
                        placeholder="Company domain"
                        value={exemplarInput}
                        onChange={(e) => setExemplarInput(e.target.value)}
                        className="w-40 mr-5"
                      />
                      <Button
                        onClick={() => {
                          if (!isValidUrl(exemplarInput)) {
                            setInvalidDomain(true);
                            toast.error("Not a valid company domain (website). Please enter a domain like 'google.com' or 'facebook.com'");
                            setTimeout(() => {
                              setInvalidDomain(false);
                            }, 1000);
                            return;
                          }
                          addExemplar(categoryIndex, exemplarInput);
                        }}>
                        Add
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              )}
            </div>
            {!addSubcategoryDisabled && (
              <Button
                onClick={() => addSubcategory(categoryIndex)}
                className="invisible group-hover:visible border-b border-l rounded-none border-gray-400 self-start mt-2"
                variant="ghost">
                <Plus size={24} className="mr-2" /> Add Subcategory
              </Button>
            )}
          </div>
        );
      })}
      {!addCategoryDisabled && (
        <Button onClick={() => addCategory()} className="border-b-2 border-l-2 rounded-none border-colors-brand-700" variant="ghost">
          <Plus className="mr-2" />
          Add category
        </Button>
      )}
    </div>
  );
};
