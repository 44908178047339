import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Button } from "../ui/button";
import { useEffect, useState, useCallback } from "react";
import { useAuth } from "@clerk/clerk-react";
import React from "react";
import {
  addCompaniesFromMarketMapToCompanyListThunk,
  addCompaniesToCompanyListThunk,
  editCompanyListTitle,
  removeCompanyFromList
} from "@/store/companyLists";
import { Import } from "lucide-react";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { ImageUploader } from "./ImageUploader";
import { toast } from "sonner";
import { Input } from "../ui/input";
import { setActiveView, View } from "@/store/views";
import { posthog } from "posthog-js";
import { exportCompanyList } from "@/services/brain-api.service";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { SimpleCompanyTable } from "../company-table/SimpleCompanyTable";
import { CompanyList, ExportFormat, FrontendCompany } from "@/services/autogen";
import { Drawer, DrawerContent } from "../ui/drawer";
import { ScrollArea } from "../ui/scroll-area";
import { ExtensibleVerticalCompanyProfile } from "../company-profile-v2/vertical/ExtensibleVerticalCompanyProfile";
import { GenericHeader } from "../workspace/GenericHeader";
import { ExportListButton } from "../company-table/ExportListButton";
import { FindContactsDropdown } from "./FindContactsDropdown";
import { PushToCrmDialog } from "./PushToCrmDialog";

export const ImportCompanyListDialog = ({ companyList, disabled }: { companyList: CompanyList; disabled?: boolean }) => {
  const [image, setImage] = useState<string | null>(null);
  const [domainsToUpload, setDomainsToUpload] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { getToken } = useAuth();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="" disabled={disabled} size="sm" variant="outline">
          <Import size={24} /> Import companies
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[400px]">
        <DialogTitle>Import companies</DialogTitle>
        <Tabs defaultValue="text">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="text">Text Input</TabsTrigger>
            <TabsTrigger value="image">Image Upload</TabsTrigger>
          </TabsList>
          <TabsContent value="image" className="flex flex-col gap-4">
            <ImageUploader onImageUpload={setImage} />
            <DialogClose asChild>
              <Button
                onClick={async () => {
                  const token = await getToken();
                  if (token == null || image == null) {
                    return;
                  }
                  toast.info("Adding companies to company list...");
                  posthog.capture("user_imported_companies_to_list", { list_id: companyList.id, source: "image" });
                  dispatch(
                    addCompaniesFromMarketMapToCompanyListThunk({
                      token: token,
                      company_list_id: companyList.id,
                      market_map_image_base64_encoded: image
                    })
                  );
                }}>
                Add companies
              </Button>
            </DialogClose>
          </TabsContent>
          <TabsContent value="text" className="flex flex-col gap-4">
            <Textarea
              placeholder="Paste company websites (comma-separated, tab-separated, or one per line)"
              className="min-h-[200px]"
              onChange={(e) => {
                const text = e.target.value;
                // Try to detect the format and parse accordingly
                let companies: string[] = [];

                if (text.includes(",")) {
                  // CSV format
                  companies = text.split(",").map((s) => s.trim());
                } else if (text.includes("\t")) {
                  // TSV format
                  companies = text.split("\t").map((s) => s.trim());
                } else {
                  // New line format
                  companies = text.split("\n").map((s) => s.trim());
                }

                // Filter out empty strings
                companies = companies.filter((c) => c.length > 0);
                setDomainsToUpload(companies);
              }}
            />
            <DialogClose asChild>
              <Button
                onClick={async () => {
                  const token = await getToken();
                  if (token == null || domainsToUpload.length == 0) {
                    return;
                  }
                  toast.info("Adding companies to company list...");
                  posthog.capture("user_imported_companies_to_list", { list_id: companyList.id, source: "domainList" });
                  dispatch(
                    addCompaniesToCompanyListThunk({
                      token: token,
                      company_list_id: companyList.id,
                      company_domains: domainsToUpload
                    })
                  );
                }}>
                Add companies
              </Button>
            </DialogClose>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

export const CompanyListHeader = ({ shareableHeader }: { shareableHeader?: boolean }) => {
  const companyList = useAppSelector((state) =>
    state.companyLists.companyLists.find((list) => list.id === state.companyLists.activeCompanyListId)
  );
  const [title, setTitle] = useState(companyList?.title ?? "");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyList) {
      setTitle(companyList.title);
    }
  }, [companyList?.id]);

  if (companyList == null) {
    dispatch(setActiveView(View.Dashboard));
    return null;
  }

  return (
    <GenericHeader shareableHeader={shareableHeader} shareableLink={`https://app.meticulate.ai/company_list/${companyList.id}`}>
      <Input
        className="w-full border-0 text-2xl font-bold mr-2"
        type="text"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        onBlur={(e) => {
          dispatch(editCompanyListTitle({ companyListId: companyList.id, title: e.target.value }));
        }}
        // className=" px-2 py-1 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </GenericHeader>
  );
};

export const CompanyListViewer = React.memo(function CompanyListViewer({
  viewOnly
}: {
  onOpenRightSection?: () => void;
  viewOnly?: boolean;
}) {
  const companyList = useAppSelector((state) =>
    state.companyLists.companyLists.find((list) => list.id === state.companyLists.activeCompanyListId)
  );
  const token = useAppSelector((state) => state.user.token);
  const [domainsSelected, setDomainsSelected] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const onBusinessDelete = useCallback(
    (companyDomain: string) => {
      dispatch(removeCompanyFromList({ companyListId: companyList?.id ?? "", companyDomain }));
    },
    [dispatch, companyList?.id]
  );

  // Reset selected domains when the company list changes
  useEffect(() => {
    setDomainsSelected([]);
  }, [companyList?.id]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerCompany, setDrawerCompany] = useState<FrontendCompany | undefined>(undefined);
  if (companyList == null) {
    dispatch(setActiveView(View.Dashboard));
    return null;
  }
  const handleCompanyClick = (domain: string) => {
    setIsDrawerOpen(true);
    const company = companyList.companies.find((c) => c.domain === domain);
    setDrawerCompany(company);
  };
  return (
    <div className="flex flex-col h-full w-full gap-4">
      {/* <div className="flex flex-row items-center bg-colors-blue-200 border border-colors-blue-400 text-colors-blue-700 rounded-lg p-2">
        <Info size={20} className="mr-2" /> {`Add companies from the results of a "Find similar" or "Thematic search"`}
      </div> */}
      <div className="flex flex-row gap-2 items-center">
        <div className="flex flex-col items-center gap-0 text-sm">
          <span>{companyList.companies.length} companies</span>
          {domainsSelected.length > 0 && <span className="text-colors-gray-modern-600 text-xs">({domainsSelected.length} selected)</span>}
        </div>
        <ImportCompanyListDialog companyList={companyList} disabled={viewOnly} />
        <FindContactsDropdown domainsSelected={domainsSelected} disabled={viewOnly} />
        {companyList.companies.length > 0 && companyList.companies[0].crm_status != null && (
          <PushToCrmDialog domainsSelected={domainsSelected} disabled={viewOnly} />
        )}
        <div className="flex-grow" /> {/* Empty div to push the export button to the right */}
        <ExportListButton<string>
          selection={domainsSelected}
          disabled={viewOnly}
          formatsEnabled={[ExportFormat.Excel, ExportFormat.Csv]}
          exportCallback={async (selection, format) => {
            posthog.capture("user_exported_company_list", { list_id: companyList.id, type: format });
            if (token) {
              toast.info("Your download is starting!");
              return exportCompanyList(token, companyList, format, selection);
            }
          }}
        />
      </div>

      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
        <SimpleCompanyTable
          companies={companyList.companies}
          selectedDomains={domainsSelected}
          onSelectedDomainsChange={setDomainsSelected}
          onCompanyClick={handleCompanyClick}
          onRowDelete={onBusinessDelete}
          // TODO: dynamic enable this based on if the list that comes back from the server has crm_status
          enableCrmStatusColumn={companyList.companies.length > 0 && companyList.companies[0].crm_status != null}
        />
        <DrawerContent direction="right" className="max-w-[600px] py-8">
          <ScrollArea orientation="vertical" className="w-full h-full px-8">
            <ExtensibleVerticalCompanyProfile company={drawerCompany} shareableView={viewOnly} />
          </ScrollArea>
        </DrawerContent>
      </Drawer>
    </div>
  );
});
