import { useMemo, useRef } from "react";

import { ColumnDef, HeaderContext, Row } from "@tanstack/react-table";
import { CompanyRow } from "@/components/company-table/common/CompanyRow";
import {
  EmployeeRolesScoringAnswer,
  FrontendScoredCompany,
  FrontendSearchInstructions,
  JobListingsScoringAnswer,
  ScoringAnswer,
  TechDetectionsScoringAnswer
} from "@/services/autogen";
import { basicColumns, crmStatusColumn, finalColumn } from "@/components/company-table/common/Columns";
import { AbstractCompanyTable } from "@/components/company-table/common/AbstractCompanyTable";
import { ScoreChip } from "../SourceCompanies/GiantTable/SourceCompaniesTable";
import { SortingDirectionIndicator } from "../company-table/common/CellComponents";
import { Cpu, IdentificationBadge, Ranking, UsersThree } from "@phosphor-icons/react";
import _ from "lodash";

export interface ScoredCompanyRow extends CompanyRow {
  scoring_results: ScoringAnswer[];
  overall_score: number;
  employee_roles_score?: EmployeeRolesScoringAnswer;
  hiring_activity_score?: JobListingsScoringAnswer;
  tech_stack_score?: TechDetectionsScoringAnswer;
}

const scoringCriterionSorting = (rowA: Row<ScoredCompanyRow>, rowB: Row<ScoredCompanyRow>, scoringQuestion: string): number => {
  const a = rowA.original.scoring_results?.find((answer) => answer.question === scoringQuestion)?.score;
  const b = rowB.original.scoring_results?.find((answer) => answer.question === scoringQuestion)?.score;
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }
  const returnVal = a > b ? 1 : -1;
  return returnVal;
};

const overallScoreSorting = (rowA: Row<ScoredCompanyRow>, rowB: Row<ScoredCompanyRow>): number => {
  return rowA.original.overall_score - rowB.original.overall_score;
};

export function getScoringColumns(
  instructions: FrontendSearchInstructions,
  minOverallScore: number,
  maxOverallScore: number
): ColumnDef<ScoredCompanyRow>[] {
  const scoringQuestions = instructions.scoring_questions;
  const overallScoreColumn = {
    accessorKey: "overall_score",
    size: 80,
    header: (header: HeaderContext<ScoredCompanyRow, unknown>) => (
      <div className="flex flex-row items-center w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
        <Ranking size={16} className="mr-1 shrink-0" />
        Overall score
        <SortingDirectionIndicator column={header.column} />
      </div>
    ),
    cell: ({ row }: { row: Row<ScoredCompanyRow> }) => {
      return (
        <div className="flex flex-row items-center w-full justify-center">
          <ScoreChip score={row.original.overall_score} numberOfDecimals={1} minScore={minOverallScore} maxScore={maxOverallScore} />
        </div>
      );
    },
    sortingFn: overallScoreSorting,
    meta: {
      group: "scoring",
      displayName: "Overall score"
    }
  };
  const finalColumns: ColumnDef<ScoredCompanyRow>[] = [overallScoreColumn];
  if (instructions.tech_detections != null) {
    finalColumns.push({
      accessorKey: "tech_stack_score",
      size: 80,
      header: (header: HeaderContext<ScoredCompanyRow, unknown>) => (
        <div className="flex flex-row items-center w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
          <Cpu size={16} className="mr-1 shrink-0" />
          {"Tech: "}
          {_.truncate(instructions.tech_detections?.high_standard, { length: 15 })}
          <SortingDirectionIndicator column={header.column} />
        </div>
      ),
      cell: ({ row }: { row: Row<ScoredCompanyRow> }) => {
        return (
          <div className="flex flex-row items-center w-full justify-center">
            <ScoreChip score={row.original.tech_stack_score?.score ?? 0} minScore={0} maxScore={5} />
          </div>
        );
      },
      sortingFn: (rowA, rowB) => {
        return (rowA.original.tech_stack_score?.score ?? 0) - (rowB.original.tech_stack_score?.score ?? 0);
      },
      meta: {
        group: "scoring",
        displayName: "Tech: " + instructions.tech_detections?.high_standard
      }
    });
  }
  if (instructions.employee_roles != null) {
    finalColumns.push({
      accessorKey: "employee_roles_score",
      size: 80,
      header: (header: HeaderContext<ScoredCompanyRow, unknown>) => (
        <div className="flex flex-row items-center w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
          <IdentificationBadge size={16} className="mr-1 shrink-0" />
          {"Employees: "}
          {_.truncate(instructions.employee_roles?.high_standard, { length: 15 })}
          <SortingDirectionIndicator column={header.column} />
        </div>
      ),
      cell: ({ row }: { row: Row<ScoredCompanyRow> }) => {
        return (
          <div className="flex flex-row items-center w-full justify-center">
            <ScoreChip score={row.original.employee_roles_score?.score ?? 0} minScore={0} maxScore={5} />
          </div>
        );
      },
      sortingFn: (rowA, rowB) => {
        return (rowA.original.employee_roles_score?.score ?? 0) - (rowB.original.employee_roles_score?.score ?? 0);
      },
      meta: {
        group: "scoring",
        displayName: "Employees: " + instructions.employee_roles?.high_standard
      }
    });
  }
  if (instructions.job_listings != null) {
    finalColumns.push({
      accessorKey: "hiring_activity_score",
      size: 80,
      header: (header: HeaderContext<ScoredCompanyRow, unknown>) => (
        <div className="flex flex-row items-center w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
          <UsersThree size={16} className="mr-1 shrink-0" />
          {"Hiring: "}
          {_.truncate(instructions.job_listings?.high_standard, { length: 15 })}
          <SortingDirectionIndicator column={header.column} />
        </div>
      ),
      cell: ({ row }: { row: Row<ScoredCompanyRow> }) => {
        return (
          <div className="flex flex-row items-center w-full justify-center">
            <ScoreChip score={row.original.hiring_activity_score?.score ?? 0} minScore={0} maxScore={5} />
          </div>
        );
      },
      sortingFn: (rowA, rowB) => {
        return (rowA.original.hiring_activity_score?.score ?? 0) - (rowB.original.hiring_activity_score?.score ?? 0);
      },
      meta: {
        group: "scoring",
        displayName: "Hiring: " + instructions.job_listings?.high_standard
      }
    });
  }
  finalColumns.push(
    ...scoringQuestions
      .filter((result) => result.question.length > 0)
      .map((result) => ({
        accessorKey: result.question,
        header: (header: HeaderContext<ScoredCompanyRow, unknown>) => (
          <div className="flex flex-row items-center w-auto cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
            <Ranking size={16} className="mr-1 shrink-0" />
            {_.truncate(result.question, { length: 20 })}
            <SortingDirectionIndicator column={header.column} />
          </div>
        ),
        cell: ({ row }: { row: Row<ScoredCompanyRow> }) => {
          return (
            <div className="overflow-hidden ellipsis w-auto text-right flex flex-row gap-2 p-2">
              <div className="bg-colors-gray-modern-4000 rounded-sm text-center self-center px-1">
                <ScoreChip
                  score={row.original.scoring_results.find((answer) => answer.question === result.question)?.score}
                  minScore={0}
                  maxScore={5}
                />
              </div>
              <span className="text-colors-text-text-secondary-(700) max-h-10">
                {row.original.scoring_results.find((answer) => answer.question === result.question)?.explanation?.text}
              </span>
              <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
            </div>
          );
        },
        sortingFn: (rowA: Row<ScoredCompanyRow>, rowB: Row<ScoredCompanyRow>): number => {
          return scoringCriterionSorting(rowA, rowB, result.question);
        },
        meta: {
          group: "scoring",
          displayName: result.question.slice(0, 20)
        }
      }))
  );
  return finalColumns;
}

export function ScoredCompanyTable({
  companies,
  instructions,
  selectedDomains,
  onSelectedDomainsChange,
  onCompanyClick,
  onRowDelete,
  disableRowSelection,
  enableCrmStatusColumn
}: {
  companies: FrontendScoredCompany[];
  instructions: FrontendSearchInstructions;
  selectedDomains: string[];
  onSelectedDomainsChange: (selectedDomains: string[]) => void;
  onCompanyClick: (domain: string) => void;
  onRowDelete?: (domain: string) => void;
  disableRowSelection?: boolean;
  enableCrmStatusColumn?: boolean;
}) {
  const tableRef = useRef<HTMLDivElement>(null);
  const scoringQuestions = instructions.scoring_questions;
  // TODO: how to do these types properly?

  const onRowClick = (row: CompanyRow) => {
    onCompanyClick(row.domain);
  };

  const rows: ScoredCompanyRow[] = companies
    .map((company) => ({
      ...company.company,
      scoring_results: company.scoring_answers,
      overall_score: company.overall_score ?? 0,
      employee_roles_score: company.employee_roles_answer,
      hiring_activity_score: company.hiring_activity_answer,
      tech_stack_score: company.tech_stack_answer
    }))
    .sort((a, b) => b.overall_score - a.overall_score)
    .map((row, index) => ({
      ...row,
      tableRank: index
    }));

  const maxOverallScore = Math.max(...rows.map((row) => row.overall_score));
  const minOverallScore = Math.min(...rows.map((row) => row.overall_score));
  const columns = useMemo<ColumnDef<ScoredCompanyRow>[]>(() => {
    const cols = [...basicColumns] as ColumnDef<ScoredCompanyRow>[];
    if (disableRowSelection) {
      cols.shift();
    }
    if (enableCrmStatusColumn) {
      cols.push(crmStatusColumn());
    }
    // cols.splice(2, 0, ...(getQualificationColumns(qualificationCriteria) as ColumnDef<ScoredCompanyRow>[]));
    cols.splice(2, 0, ...getScoringColumns(instructions, minOverallScore, maxOverallScore));

    return [...cols, finalColumn(tableRef, onRowDelete, ["scoring"])] as ColumnDef<ScoredCompanyRow>[];
  }, [scoringQuestions, instructions]);
  return (
    <AbstractCompanyTable
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      tableRef={tableRef}
      selectedDomains={selectedDomains}
      onSelectedDomainsChange={onSelectedDomainsChange}
    />
  );
}
