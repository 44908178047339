import { OfferingsDive, OfferingSummary, ReviewSet } from "@/services/autogen";
import { SourcesLogoViewer } from "./SourceViewer";
import { Boxes, Star } from "lucide-react";
import { LoadingNullContentsWrapper } from "./LoadingNullContentsWrapper";
import { ensureAbsoluteUrl } from "@/lib/utils";

export const OfferingSummaryDisplay = ({ offering, limited }: { offering: OfferingSummary; limited?: boolean }) => {
  return (
    <div className="flex flex-col border p-2 rounded-lg bg-colors-brand-25 border-colors-border-border-brand">
      <div className="text-sm font-semibold text-colors-brand-800">{offering.name}</div>

      <div className="text-sm text-colors-gray-modern-600">{offering.use_case_value_prop}</div>
      {!limited && (
        <div>
          <ul className="text-sm list-disc pl-5 text-colors-gray-modern-600">
            {offering.differentiators.map((differentiator, index) => (
              <li key={index}>{differentiator}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const ReviewSetDisplay = ({ image_url, reviewSet }: { image_url: string; reviewSet: ReviewSet }) => {
  return (
    <div
      className="flex flex-col gap-1 items-center border border-colors-gray-modern-200 p-2 bg-colors-gray-modern-50 rounded-lg cursor-pointer"
      onClick={() => {
        if (reviewSet.company_profile_link != null) {
          window.open(ensureAbsoluteUrl(reviewSet.company_profile_link), "_blank");
        }
      }}>
      <div className="flex flex-row gap-2 w-full items-center justify-between">
        <img className="w-5 h-5" src={image_url} />
        <div className="text-sm flex flex-row items-center gap-[2px] font-medium">
          {reviewSet.aggregate_rating} <Star className="w-4 h-4 fill-current text-colors-yellow-400" />
        </div>
      </div>
      <div className="text-xs">{reviewSet.num_reviews} Reviews</div>
    </div>
  );
};

export const OfferingsDiveFullDisplay = ({ offeringsDive }: { offeringsDive: OfferingsDive }) => {
  return (
    <div className="flex flex-col bg-white border p-3 rounded-lg">
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <Boxes size={20} />
        <div>Offerings</div>
      </div>

      <div className="flex flex-col gap-2">
        {offeringsDive.offerings.map((offering) => (
          <OfferingSummaryDisplay key={offering.name} offering={offering} />
        ))}
      </div>
      <div className="flex flex-row gap-4 my-4">
        {offeringsDive.g2_review_set && <ReviewSetDisplay image_url="/g2_logo.png" reviewSet={offeringsDive.g2_review_set} />}
        {offeringsDive.trustpilot_review_set && (
          <ReviewSetDisplay image_url="/trustpilot_logo.png" reviewSet={offeringsDive.trustpilot_review_set} />
        )}
      </div>
      <div className="flex flex-row gap-2 items-start">
        {offeringsDive.review_quotes.map((reviewQuote, index) => (
          <div key={index} className="text-colors-gray-modern-700 text-xs italic border rounded-md p-2 px-3 bg-colors-gray-modern-25">
            {`"${reviewQuote.text}"`}{" "}
            <a href={ensureAbsoluteUrl(reviewQuote.source.link ?? "")} className="text-blue-600 underline">
              [Source]
            </a>
          </div>
        ))}
      </div>
      <SourcesLogoViewer sources={offeringsDive.sources} label={true} />
    </div>
  );
};

export const OfferingsDiveSummaryContents = ({ offeringsDive }: { offeringsDive?: OfferingsDive }) => {
  if (offeringsDive == null) {
    return null;
  }
  return (
    <>
      <div className="flex flex-col gap-2">
        {offeringsDive.offerings.map((offering) => (
          <OfferingSummaryDisplay key={offering.name} offering={offering} limited={true} />
        ))}
      </div>
      <div className="flex flex-row gap-4 mt-4">
        {offeringsDive.g2_review_set && <ReviewSetDisplay image_url="/g2_logo.png" reviewSet={offeringsDive.g2_review_set} />}
        {offeringsDive.trustpilot_review_set && (
          <ReviewSetDisplay image_url="/trustpilot_logo.png" reviewSet={offeringsDive.trustpilot_review_set} />
        )}
      </div>
    </>
  );
};

export const OfferingsDiveSummaryDisplay = ({
  offeringsDive,
  onDoubleClick: onDoubleClick,
  loading
}: {
  offeringsDive?: OfferingsDive;
  onDoubleClick?: () => void;
  loading: boolean;
}) => {
  return (
    <div className="flex flex-col bg-white border p-3 rounded-lg cursor-pointer" onDoubleClick={onDoubleClick}>
      <div className="flex flex-row items-center text-colors-gray-modern-500 text-md font-medium mb-4 gap-2">
        <Boxes size={20} />
        <div>Offerings</div>
      </div>
      <LoadingNullContentsWrapper loading={loading} objectIsNull={offeringsDive == null}>
        <OfferingsDiveSummaryContents offeringsDive={offeringsDive} />
      </LoadingNullContentsWrapper>
    </div>
  );
};
