import { PercentValueText } from "@/lib/utilComponents";
import { WebTrafficSnapshot } from "@/services/autogen";

export const WebTrafficDisplay = ({ webTraffic }: { webTraffic?: WebTrafficSnapshot }) => {
  const visitsLastMonth = webTraffic?.visits_last_month;
  const visitGrowthMonthly = webTraffic?.visit_growth_monthly;
  const formattedVisits = visitsLastMonth
    ? visitsLastMonth >= 1000000
      ? (visitsLastMonth / 1000000).toFixed(1) + "M"
      : (visitsLastMonth / 1000).toFixed(0) + "k"
    : visitsLastMonth;
  const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";

  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{formattedVisits}</span>
      <span className="text-xs" style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>
        <PercentValueText value={visitGrowthMonthly} positiveGreen includePlusSign />
      </span>
    </div>
  );
};

export const WebTrafficFull = ({ webTraffic }: { webTraffic?: WebTrafficSnapshot }) => {
  const visitsLastMonth = webTraffic?.visits_last_month;
  const visitGrowthMonthly = webTraffic?.visit_growth_monthly;
  const formattedVisits = visitsLastMonth
    ? visitsLastMonth >= 1000000
      ? (visitsLastMonth / 1000000).toFixed(1) + "M"
      : (visitsLastMonth / 1000).toFixed(0) + "k"
    : visitsLastMonth;
  const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
  const formattedGrowth =
    visitGrowthMonthly !== undefined ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%` : "";

  return (
    <div className="flex flex-col gap-1 text-sm font-light">
      <div className="flex flex-row gap-2">
        <div className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">Visits last month: </div>
        <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{formattedVisits}</span>
      </div>
      <div className="flex flex-row gap-2">
        <div className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">Monthly growth: </div>
        <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
      </div>
    </div>
  );
};
