import { SidebarGroup, SidebarGroupLabel } from "@/components/ui/sidebar";

import { CompanyDomainAutocompleteForLeftbar } from "../workflow-inputs/companyWebsiteAutocomplete";
import { isValidUrl } from "@/lib/utils";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { toast } from "sonner";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { fetchUserRunsAvailableThunk } from "@/store/user";
import { setActiveCompanyProfile } from "@/store/companyProfiles";
import { useAuth } from "@clerk/clerk-react";
import { Dialog } from "@radix-ui/react-dialog";
import { Button } from "../ui/button";
import posthog from "posthog-js";
import { UpgradeSubscriptionDialogContent } from "../dashboard/UpgradeSubscription";
import { fetchCompanyTearsheetStreamingThunk } from "@/store/companyProfiles";
import { setActiveView, View } from "@/store/views";

export function CompanyLookup() {
  const { getToken } = useAuth();
  const companyCreditsAvailable = useAppSelector((state) => state.user.companyCreditsAvailable);
  const dispatch = useAppDispatch();
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Company lookup</SidebarGroupLabel>
      <div className="w-full mt-2">
        <CompanyDomainAutocompleteForLeftbar
          placeholder="Domain e.g. meticulate.ai"
          onChange={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
          onSubmit={async ({ value }) => {
            if (!isValidUrl(value)) {
              toast.error("Please enter a valid URL");
              return;
            }
            if (companyCreditsAvailable != null && companyCreditsAvailable <= 0) {
              toast.error(
                <div className="flex flex-row gap-4 items-center">
                  You have no credits left.
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button size="sm" onClick={() => posthog.capture("user_clicked_get_more_reports", { variant: "sidebar" })}>
                        Get more credits
                      </Button>
                    </DialogTrigger>
                    <UpgradeSubscriptionDialogContent />
                  </Dialog>
                </div>
              );
              return;
            }
            const token = await getToken();
            if (token !== null) {
              posthog.capture("user_fetched_company_tearsheet", { domain: value });
              dispatch(fetchCompanyTearsheetStreamingThunk({ token, company_domain: value }));
              dispatch(setActiveCompanyProfile({ companyProfileId: value }));
              dispatch(setActiveView(View.Company));
              dispatch(fetchUserRunsAvailableThunk(token));
            }
          }}
        />
      </div>
    </SidebarGroup>
  );
}
